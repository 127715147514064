export const TRANSLATIONS_HR = {
	LandingPage_Title: "Dobro došli na portal Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Odaberite državu/regiju",
	Header_ContactUs: "Obratite nam se",
	Header_Search: "Pretraži",
	Header_Menu: "Izbornik",
	Header_LogInOrCreateYourAccount: "Prijavite se ili izradite korisnički račun",
	Header_LogIn: "Prijava",
	Header_Cart: "Košarica",
	Header_ShoppingCart: "Košarica",
	Header_LogOut: "Odjava",
	Header_ChangeDetails: "Promjena pojedinosti",
	Footer_ExploreOurTraining: "Istražite naše obuke",
	Footer_TrainingAtYourFingertips: "Obuka nadohvat ruke",
	Footer_DontMissAnyStory: "Nemojte propustiti nijednu priču",
	Footer_ExploreOurStories: "Istražite naše priče",
	Footer_GetInTouchWithUs: "Javite nam se",
	Footer_SendUsAMessage: "Pošaljite nam poruku",
	Footer_ToContactForm: "Na obrazac za kontakt",
	Footer_InventedForLife: "Tehnologija za život",
	Footer_GeneralContactInfo: "Općeniti podaci za kontakt",
	Footer_ProductSecurity_Psirt: "Sigurnost proizvoda (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Inovacije, patenti i licencije",
	Footer_PurchasingLogistics: "Nabava i logistika",
	Footer_CorporateInformation: "Informacije o korporaciji",
	Footer_LegalNotice: "Pravna napomena",
	Footer_DataProtectionNotice: "Obavijest o zaštiti podataka",
	Footer_PrivacySettings: "Postavke privatnosti",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch, sva prava pridržana",
	Footer_StillLookingForSomething: "I dalje tražite nešto?",
	Footer_TrainingServices: "Usluge obuke",
	Footer_Courses: "Tečajevi",
	Footer_Programs: "Programi",
	Footer_EventsCalendar: "Kalendar događaja",
	Footer_TrainingCenters: "Centri za obuku",
	Footer_Dashboard: "Nadzorna ploča",
	Footer_MyDashboard: "Moja nadzorna ploča",
	Footer_ShoppingCart: "Košarica",
	Footer_YourShoppingCart: "Vaša košarica",
	Footer_Administration: "Administracija",
	Footer_Employees: "Zaposlenici",
	Footer_Wholesalers: "Veletrgovci",
	Footer_Transactions: "Transakcije",
	Footer_Workshops: "Radionice",
	Footer_WorkLikeABoschApplyNow: "Radite  – Prijavite se odmah.",
	Footer_BoschGlobalCareers: "Globalne karijere u Boschu",
	Footer_PurchasingAndLogistics: "Nabava i logistika",
	Footer_BecomeABusinessPartner: "Postanite poslovni partner.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Kako se naša obuka razvija da bi pomogla vašem poduzeću",
	Footer_Language: "Jezik",
	MenuPopup_MyCart: "Moja košarica",
	Stories_OurTrainingStories: "Naše priče o obuci",
	Stories_ConnectingExperts: "Povezujemo stručnjake i entuzijaste, sanjare i realizatore, inovatore i utjecajne osobe – istražite i iskusite",
	Stories_TopStories: "Najbolje priče",
	Stories_AtHome: "Kod kuće",
	Stories_Careers: "Karijere",
	Stories_History: "Povijest",
	Stories_IndustryAndTrades: "Industrija i struke",
	Stories_Mobility: "Mobilnost",
	Stories_Research: "Istraživanje",
	Stories_Sustainability: "Održivost",
	Stories_AllStories: "Sve priče",
	Stories_BlogATrainersDay: "BLOG | Dan u životu voditelja obuke",
	Stories_ADayinLifeOfATrainer: "Dan u životu voditelja obuke",
	Stories_BoschServiceTraining: "Obuka za servis društva Bosch",
	Stories_StoryWhatDrivesYouDrivesUs: "PRIČA | Pokreće nas zajednička snaga",
	Stories_StoryHowCanWeBenefitYourBusiness: "PRIČA | Kako možemo pomoći vašem poduzeću",
	Stories_HowOurTrainingEvolves: "Kako se naša obuka razvija",
	Stories_AModernTrainingApproach: "Svijet mrežne obuke",
	Stories_StoryAVirtualOnlineTrainingWorld: "PRIČA | Vaše prilike za mrežnu obuku",
	Stories_SubscribeToRSSFeed: "Pretplati se na RSS sadržaj",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "O nama",
	Homepage_AutomotiveAftermarket: "Odjel autoopreme",
	Homepage_GetInTouch: "Javite nam se",
	Homepage_LatestNews: "Otkrijte zašto nas vodi želja za znanjem",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Naša otvorena radna mjesta",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Pokreće nas zajednička snaga",
	BoschServiceTraining_BoschServiceTraining: "Obuka za servis društva Bosch",
	BoschServiceTraining_ShareThisOn: "Podijeli na",
	BoschServiceTraining_TheChallenge: "Izazov: savladati nove tehnologije",
	BoschServiceTraining_WorldwidePresence: "Globalna prisutnost",
	BoschServiceTraining_TechnicalTraining: "Tečajevi za tehničku obuku društva Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Ponude i prilike koje nudimo u društvu Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Nudimo tečajeve za obuku koji su prikladni za sve ciljne skupine: od početnika do profesionalaca. Ponude uključuju jednodnevne i višednevne tečajeve za obuku. Tečajevi za obuku dostupni su za različita tehnička područja i kao serijske obuke (kao što su ubrizgavanje benzina, ubrizgavanje dizela, električni sustavi). U okviru tečajeva za tehničku obuku društvo Bosch prenosi sveobuhvatno znanje i iskustvo o svim standardnim sustavima vozila društva Bosch i ostalih proizvođača (također se obrađuje na tečajevima za obuku). Sadržaj je posebno usklađen sa zahtjevima zaposlenika radionica.",
	BoschServiceTraining_TrainedEmployeesDesc: "Obučeni zaposlenici očita su prednost za radionice za motorna vozila. Zato što današnja vozila postaju sve složenija, a nove tehnologije stalno predstavljaju nove izazove. Zaposlenici radionica mogu učinkovito i isplativo obavljati dijagnostičke radove, održavanje i popravke na aktualnim modelima vozila samo zahvaljujući potrebnoj stručnosti.",
	BoschServiceTraining_RegardlessOfHow: "Bez obzira na sposobnost i iskustvo stručnjaka za motorna vozila uvijek postoje situacije u kojima će se susresti sa sustavima nove tehnologije vozila ili nepoznatim kvarovima. U tom smislu uklanjanje smetnji i rješavanje problema može biti posebno dugotrajno i ne mora nužno dovesti do uspješnih rezultata. U najgorem se slučaju kupci moraju snalaziti bez svojih vozila znatno duže nego što su očekivali.",
	BoschServiceTraining_ItIsPrecisely: "Upravo ovdje Bosch uskače sa svojom obukom za servis kojom se poboljšava kvaliteta servisa i ubrzavaju postupci. Trenutačne obuke društva Bosch pružaju podršku za brže pronalaženje problema, istodobno popravljanje većeg broja vozila i skraćivanje vremena čekanja za vaše klijente. Stoga vam sustavni postupci koje možete usvojiti od društva Bosch jamče brži i produktivniji rad.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Već više od 125 godina Bosch je cijenjeni dobavljač originalnih dijelova opreme, komponenata i sustava za brojne vodeće proizvođače automobila iz cijeloga svijeta. To iskustvo prati jedinstveno znanje o najnovijim tehnologijama na kojem se temelji potpora društva Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Centri za obuku za servis društva Bosch nalaze se u svim dijelovima svijeta: od Španjolske do Turske, Južnoafričke Republike, Australije i Brazila. Bosch svojim zaposlenicima nudi obuku usmjerenu na praksu koja je uvijek ukorak s najnovijim tehnologijama.",
	BoschServiceTraining_AgileCompany: "Agilno društvo",
	BoschServiceTraining_Collaboration: "Suradnja",
	BoschServiceTraining_ConnectedMobility: "Povezana mobilnost",
	BoschServiceTraining_ArtificialIntelligence: "Umjetna inteligencija",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Razgovor mislioca",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Dijagnostička i ispitna oprema",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Osnove tehnologije za motorna vozila",
	BoschServiceTraining_Icon_Gasoline: "Benzin",
	BoschServiceTraining_Icon_Diesel: "Dizel",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternativni pogoni",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektrotehnika i elektronika",
	BoschServiceTraining_Icon_BodyAndComfort: "Karoserija i udobnost",
	BoschServiceTraining_Icon_ChassisAndBraking: "Šasija i kočenje",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Prijenos i pogonski sklop",
	BoschServiceTraining_Icon_CommercialVehicles: "Gospodarska vozila",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Internetski tečajevi za obuku",
	BoschServiceTraining_Icon_BusinessSkills: "Poslovne vještine",
	BoschServiceTraining_Icon_Accreditations: "Akreditacije",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programi tečajeva za obuku",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "ImePrezimeVoditeljaObuke",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Dan u životu voditelja obuke",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Svaki je dan pustolovina",
	ADayInTheLifeOfATrainer_Summary: "Sažetak",
	ADayInTheLifeOfATrainer_TheDayJourney: "Tijek dana",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Svakog se dana mnogo radi, ali to je uvijek vrijedno iskustvo. Obuka polaznika sa sobom nosi veliku odgovornost jer znate da drže našu budućnost u svojim rukama, ali istodobno pruža zadovoljstvo i uzbuđenje. Kada vidimo kako polaznici napreduju sa svakom lekcijom iz dana u dan i iz tjedna u tjedan, znamo da imamo zajednički cilj: proširiti njihovo znanje predstavljanjem moderne tehnologije koja vodi u budućnost!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Na kraju svakog dana sjednemo zajedno i osvrnemo se na to što smo taj dan radili. Dani brzo prolaze, ali to je dokaz da to što postižemo nije samo vrijedno iskustvo za polaznike, nego i za nas voditelje obuka.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Na kraju dana možemo otići kući zadovoljni jer smo odradili odličan posao!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Početak",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Priprema i posljednje provjere materijala za obuku. Provjera važne pošte",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Počinje obuka",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Dobro došli, sudioniče. Predstavljanje dnevnog rasporeda. Upoznajte skupinu razumijevanjem dinamike svakog pojedinca.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teorija",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Potrebna je određena količina interakcije sa skupinom kako bi obuka bila što zanimljivija. Pobrinite se da postoje zadaci povezani s teorijom kako biste dodatno uključili skupinu",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Pauza za ručak",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktični dio",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Obuka se nastavlja u radionici uz nekoliko praktičnih zadataka. Kao voditelj obuke uvijek morate biti prisutni i dostupni kao i uvijek za sve slučajeve i smjernice",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Ponavljanje",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Vratite se u učionicu i izvedite sažetak kako biste razgovarali o obuci toga dana. Odgovorite na sva postavljena pitanja. Počnite pripremu za sljedeće sesije obuke.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Posljednji dan",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Sudionicima se uručuju potvrde o sudjelovanju.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Doma,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Kako možemo pomoći vašem poduzeću",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Kako se naša obuka razvija",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Neprestano se razvijamo kako bismo bili sigurni da pružamo kvalitetnu i najsuvremeniju obuku",
	BoschTrainingBenefits_TheBoschServiceTraining: "Centar za obuku za servis društva Bosch želi vam pomoći u izgradnji vašeg poduzeća s pomoću svog opsežnog i iznimno prikladnog portfelja tehničkih/poslovnih obuka i tečajeva daljnjeg školovanja s certificiranim kvalifikacijama. Na sljedećoj se stranici nalazi pregled naših programa obuke i trenutačnih prilika za daljnje školovanje. Radujemo se susretu s vama i vašim zaposlenicima na našim tečajevima za obuku i seminarima.",
	BoschTrainingBenefits_LongStandingExperience: "Dugogodišnje iskustvo s opsežnim znanjem o svim sustavima na području automobilske tehnologije.",
	BoschTrainingBenefits_QuickAndEfficient: "Brza i učinkovita rješenja za brojne probleme.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Prednosti i koristi",
	BoschTrainingBenefits_LMS: "Sustav za upravljanje učenjem – Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Sustav Bosch Training Solutions pokriva cjelokupni asortiman automobilskih područja. Specijalizirajte se za određena područja ili proširite svoje vještine uz ponude obuke društva Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Usvojeno znanje omogućuje pružanje usluga popravaka, održavanja i servisiranja.",
	BoschTrainingBenefits_TheHighDegree: "Velik udio praktičnog sadržaja i rad u malim skupinama jamči učenje usmjereno ostvarivanju ciljeva",
	BoschTrainingBenefits_ParticipationInTraining: "Sudjelovanje u obuci povećava motivaciju i zadovoljstvo zaposlenika, a dodatne kvalifikacije privlače kupce",
	BoschTrainingBenefits_IncreasedSales: "Povećana prodaja zahvaljujući znanju o sustavima različitih marki vozila",
	BoschTrainingBenefits_MethodicalProcedures: "Metodički postupci smanjuju vrijeme čekanja za kupce",
	BoschTrainingBenefits_HighlyQualified: "Visokokvalificirani predavači prenose sustavne postupke",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Utvrđivanje rješenja za obuku koje odgovara vama ili vašem poduzeću",
	BoschTrainingBenefits_DirectOnlineBooking: "Izravna mrežna rezervacija i upravljanje tečajevima za vas ili vaše zaposlenike",
	BoschTrainingBenefits_OnlineAccess: "Mrežni pristup obrazovnim materijalima",
	BoschTrainingBenefits_AccessToYourOwn: "Pristup osobnoj povijesti obuke i certifikatima",
	BoschTrainingBenefits_ToolsForManagement: "Alati za upravljanje vlastitim razvojem karijere",
	BoschTrainingBenefits_ManagerHasFull: "Upravitelj ima potpun pregled nad tečajevima svojih zaposlenika unutar sustava",
	BoschTrainingBenefits_Access24hours: "Cjelodnevni pristup svih sedam dana u tjednu",
	ModernTrainingApproach_KnowledgeIsKey: "Znanje je ključni čimbenik uspjeha za svaku radionicu. Zbog toga Bosch nudi sveobuhvatni program stručnog osposobljavanja i razvoja. Njime omogućuje polaznicima da steknu pravo znanje na pravom mjestu u pravo vrijeme, odnosno znanje koje im je potrebno za profesionalno održavanje i popravak vozila kupaca. Bosch je dodao prijenos internetskog medijskog videosadržaja (engl. webcasting) kao novi dio modula paketa za e-učenje koji je već u ponudi. Sastoji se od sesija obuka za servis osmišljenih za internetski prijenos.",
	ModernTrainingApproach_ANewLearning: "Nova prilika za učenje",
	ModernTrainingApproach_YourOnlineTraining: "Vaše prilike za mrežnu obuku",
	ModernTrainingApproach_RecentlyTheWorld: "Svijet se nedavno promijenio; pogledajte kako mi pomažemo",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Svijet mrežne obuke",
	ModernTrainingApproach_NewTeachingMethod: "Nova metoda učenja",
	ModernTrainingApproach_InAdditionTo: "Osim postojećih obuka uživo dodali smo obuke s prijenosom internetskog medijskog videosadržaja uživo u program obuke. Naš voditelj obuke stoji vam na raspolaganju s opsežnim teorijskim znanjem u virtualnoj učionici ili s praktičnim primjerima. Kao i uvijek, dobit ćete mnogo informacija o sustavima vozila i odgovarajućim proizvodima društva Bosch. Naravno da možete i postavljati pitanja. Dobit ćete izravne odgovore na pitanja tijekom prijenosa internetskog medijskog videosadržaja uživo. Prijenosi internetskog medijskog videosadržaja uživo traju između 60 i 120 minuta. Potrebna je stabilna internetska veza. Potražite prijenos internetskog medijskog videosadržaja koji vam odgovara izravno u našem katalogu za obuke tako da unesete „Webcast” (Internetski medijski videosadržaj) polje za pretraživanje.",
	ModernTrainingApproach_BoschWebcastingHeader: "Prijenos internetskog medijskog videosadržaja društva Bosch: Inovativan i praktičan",
	ModernTrainingApproach_BoschWebcastingText: "Da bi polaznik sudjelovao u prijenosu internetskog medijskog videosadržaja društva Bosch, prvo se treba uključiti biranjem broja. Individualni pristupni kod šalje se prije sesije da bi se omogućila prijava. Do 200 sudionika može istodobno sudjelovati s raznih lokacija. Obuku drži voditelj obuke, a pitanja se mogu postavljati u bilo kojem trenutku putem funkcije dopisivanja uživo.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Digitalni dodatak za obuku u učionici",
	ModernTrainingApproach_TheDigitalSupplementText: "Prijenos internetskog medijskog videosadržaja i obuka u učionici savršeno se nadopunjavaju. U obama slučajevima obuku drži kvalificirani voditelj obuke. Prijenosi internetskog medijskog videosadržaja prikladni su za teme koje se mogu prenositi digitalno i ne zahtijevaju rad na vozilu. To je, na primjer, upoznavanje određenih značajki softvera ESI[tronic] novih sustava pomoći vozaču. Obuke uživo još uvijek su prvi izbor kad je riječ o učinkovitom timskom radu u radionici ili praktičnom radu uživo na tehnologiji vozila.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Internetski prijenos može biti jeftiniji",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Troškovi po sudioniku obično su manji kod prijenosa internetskog medijskog videosadržaja nego kod slične obuke u učionici. Razlog se krije u tome što više sudionika može imati koristi od obuke, dok je napor povezan s obukom znatno manji (ne morate putovati na mjesto održavanja obuke pa kući i trošite manje vremena).",
	ModernTrainingApproach_BookAndProfitHeading: "Rezervirajte i profitirajte",
	ModernTrainingApproach_BookAndProfitText: "Prijenosi internetskog medijskog videosadržaja mogu se rezervirati na našem mrežnom mjestu na isti način kao i naši ostali tečajevi za obuku i prilike za učenje. Možete plaćati svaki prijenos internetskog medijskog videosadržaja zasebno ili uzeti godišnju pretplatu za određeni broj prijenosa internetskog medijskog videosadržaja.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Usporedba prijenosa internetskog medijskog videosadržaja i obuke u učionici društva Bosch",
	ModernTrainingApproach_Webcasting: "Prijenos internetskog medijskog videosadržaja",
	ModernTrainingApproach_Classroom: "Učionica",
	ModernTrainingApproach_WebcastingP1: "Može istodobno sudjelovati do 200 sudionika s bilo kojeg mjesta na svijetu",
	ModernTrainingApproach_WebcastingP2: "Niska razina složenosti i relativno niski troškovi te putovanje nije potrebno",
	ModernTrainingApproach_WebcastingP3: "Idealno za specifične i sažete pojedinačne teme",
	ModernTrainingApproach_WebcastingP4: "Radionice mogu držati prijenos internetskog medijskog videosadržaja za svoje zaposlenike bilo gdje i bilo kad",
	ModernTrainingApproach_WebcastingP5: "Između 50 i 70 % manje vremena potrebno nego za obuke u učionici",
	ModernTrainingApproach_ClassroomTrainingP1: "Može se istodobno sastati do 16 sudionika",
	ModernTrainingApproach_ClassroomTrainingP2: "Putni troškovi i općenito veći troškovi po osobi",
	ModernTrainingApproach_ClassroomTrainingP3: "Idealno za optimizaciju suradnje ili učenja izravno na vozilu",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktične vježbe i interakcija s voditeljem obuke",
	ModernTrainingApproach_ClassroomTrainingP5: "Voditelj obuke može bolje prilagoditi gradivo koje će prenijeti sudionicima prema stvarnim potrebama sudionika",
	ModernTrainingApproach_OnlineTrainingOfferings: "Ponude mrežnih obuka (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Danas je samostalno učenje za računalom sastavni dio prijenosa znanja. Sloboda pri odabiru vremena i mjesta, mogućnost neograničenog ponavljanja gradiva, individualna brzina učenja i interaktivni obrazovni sadržaji čine ovu metodu nezaobilaznim obrazovnim alatom.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Svaki element mrežne obuke (engl. web-based training, WBT) pažljivo je strukturiran. Osim što pružaju široku bazu znanja, ti tečajevi mogu poslužiti kao učinkovita priprema za programe obuke u centrima za obuku društva Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Virtualna simulacija dijagnostike nudi korisniku mogućnosti igranja uloga za svakodnevne situacije u radionici kao što je tijek rada kod postupka rješavanja problema s pomoću dijagnostičkih uređaja društva Bosch u virtualnoj radionici.",
	ModernTrainingApproach_Advantages: "Prednosti",
	ModernTrainingApproach_AdvantagesP1: "Brza i učinkovita metoda prijenosa znanja",
	ModernTrainingApproach_AdvantagesP2: "Raznovrsno učenje",
	ModernTrainingApproach_AdvantagesP3: "Možete upotrebljavati koliko god često želite",
	ModernTrainingApproach_AdvantagesP4: "Uložena minimalna količina vremena i novca",
	ModernTrainingApproach_AdvantagesP5: "Individualna brzina učenja",
	ModernTrainingApproach_AdvantagesP6: "Fleksibilno vrijeme učenja",
	ModernTrainingApproach_AdvantagesP7: "Mogućnost ponavljanja",
	ModernTrainingApproach_AdvantagesP8: "Nema putnih troškova",
	ModernTrainingApproach_AdvantagesP9: "Nema problema s kapacitetom ili kolizija u rasporedu",
	Dashboard_CoursesViewAndManageYourCourses: "TEČAJEVI | Prikaži i upravljaj svojim tečajevima",
	Dashboard_MyCourses: "Moji tečajevi",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAM OBUKE | Prikaži svoje programe obuke",
	Dashboard_MyTrainingProgram: "Moj program obuke",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "e-UČENJE | Prikaži i upravljaj svojim portfeljom e-učenja",
	Dashboard_MyELearning: "Moje e-učenje",
	Dashboard_AccountManageYourAccount: "KORISNIČKI RAČUN | Upravljaj svojim korisničkim računom",
	Dashboard_MyAccount: "Moj korisnički račun",
	Dashboard_MyDashboard: "Moja nadzorna ploča",
	Dashboard_ManageYourAccount: "Upravljaj svojim korisničkim računom",
	Dashboard_Workshops: "Radionice",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRACIJA | Upravljaj svojim radionicama",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRACIJA | Upravljaj svojim timovima",
	Dashboard_Administration: "Administracija",
	Dashboard_ViewYourCourses: "Prikaži svoje tečajeve",
	Dashboard_ViewYourTrainingPrograms: "Prikaži svoje programe obuke",
	Dashboard_TrainingPrograms: "Programi obuke",
	Dashboard_ViewAndEditYourDetails: "Prikaži i uredi svoje pojedinosti",
	Dashboard_ViewYourELearningCourses: "Prikaži svoje tečajeve e-učenja",
	Dashboard_Pending: "Na čekanju",
	Dashboard_RequiresAdministratorSignOff: "(potrebna je potvrda administratora)",
	Dashboard_HistoricalRecords: "Povijesni zapisnik",
	Dashboard_Registered: "Registrirano",
	Dashboard_WaitingList: "Lista čekanja",
	Dashboard_RecommendedForYou: "Preporučeno za vas",
	Dashboard_AccountDetails: "Pojedinosti o korisničkom računu",
	Dashboard_ChangeProfileImage: "promijeni profilnu sliku",
	Dashboard_Title: "Titula",
	Dashboard_Role: "Uloga",
	Dashboard_FirstName: "Ime",
	Dashboard_LastName: "Prezime",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobilni telefon",
	Dashboard_Email: "E-adresa",
	Dashboard_Language: "Jezik",
	Dashboard_Save: "Spremi",
	Dashboard_Student: "Polaznik",
	Dashboard_WorkshopTrainingAdministrator: "Administrator obuke za radionicu",
	Dashboard_WholesalerTrainingAdministrator: "Administrator obuke za veletrgovca",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administracija društva",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Upravljaj radionicama...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Upravljaj zaposlenicima...",
	Dashboard_CompanyAdministration_Workshop: "Radionica",
	Dashboard_CompanyAdministration_Address: "Adresa",
	Dashboard_CompanyAdministration_Zip: "Poštanski broj",
	Dashboard_CompanyAdministration_City: "Grad",
	Dashboard_CompanyAdministration_Manage: "Upravljaj",
	Dashboard_AdministrationSection_AdministrationTitle: "Administracija",
	Dashboard_AdministrationSection_ManageTheTeam: "Upravljaj timom",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRACIJA | Upravljaj zaposlenicima",
	Dashboard_AdministrationSection_Employees: "Zaposlenici",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRACIJA | Upravljaj veletrgovcima",
	Dashboard_AdministrationSection_Wholesalers: "Veletrgovci",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRACIJA | Upravljaj transakcijama",
	Dashboard_AdministrationSection_Transactions: "Transakcije",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administracija zaposlenika",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Upravljaj zaposlenicima...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registriraj",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Zapisnik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Uredi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Zaposlenik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Dodaj",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Izradi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrirano",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Na čekanju",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(potrebna je potvrda administratora)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Povijesni zapisnik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Lista čekanja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Popis trenutačnih zaposlenika",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Upravljanje zaposlenicima",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Ime",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Korisnički račun zaposlenika",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Prikaži i uredi pojedinosti zaposlenika...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Postavke",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Dopusti osobni korisnički račun",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Nadogradnja administracije radionica za zaposlenike posredničkog poslužitelja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Dopusti zaposleniku registraciju na događaje obuke",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Pošalji pozivnicu putem e-pošte",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Deaktiviraj korisnički račun",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Spremi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Pošalji",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Uredi",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Upravljanje veletrgovcima",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Upravljaj svojim veletrgovcima...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Popis odobrenih veletrgovaca",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Ime",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Izbriši",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Izradi",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Dodaj",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Izrada novog korisničkog računa za zaposlenika",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Izradi pojedinosti za nove zaposlenike...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Dodaj novog administratora odobrenih veletrgovaca",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Izradi pojedinosti za nove veletrgovce...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Odaberi odobrene veletrgovce iz padajućeg izbornika...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transakcije",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Prikaži i upravljaj svojim transakcijama...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Natrag",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Broj računa",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Opis",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Način plaćanja",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Datum",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Iznos",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Preuzmi",
	TrainingCentres_TrainingCentres: "Centri za obuku",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Naši centri za obuku nalaze se diljem svijeta",
	TrainingCentres_CountryCategory: "Kategorija države",
	TrainingCentres_CityCategory: "Kategorija grada",
	TrainingCentres_Country: "Država",
	TrainingCentres_City: "Grad",
	TrainingCatalogue_TrainingCatalogue: "Katalog tečajeva za obuku",
	TrainingCatalogue_LearningAtTheHighestLevel: "Usvajanje znanja na najvišoj razini",
	TrainingCatalogue_StartDate: "Početni datum",
	TrainingCatalogue_EndDate: "Završni datum",
	TrainingCatalogue_TrainingName: "Naziv obuke",
	TrainingCatalogue_Language: "Jezik",
	TrainingCatalogue_Days: "Broj dana",
	TrainingCatalogue_Seats: "Broj mjesta",
	TrainingCatalogue_Price: "Cijena",
	TrainingCatalogue_Location: "Lokacija",
	TrainingCatalogue_PartNumber: "Broj dijela",
	TrainingCatalogue_TrainingCourse: "Tečaj za obuku",
	TrainingCatalogue_Category: "Kategorija",
	TrainingCatalogue_TrainingCategory: "Kategorija obuke",
	TrainingCatalogue_CourseName: "Naziv tečaja",
	TrainingCatalogue_TotalDays: "Ukupni broj dana",
	TrainingCatalogue_Venue: "Mjesto",
	TrainingCatalogue_Details: "Pojedinosti",
	TrainingCatalogue_Prerequisites: "Preduvjeti",
	TrainingCatalogue_CourseType: "Vrsta tečaja",
	TrainingCatalogue_LearningMethod: "Metoda učenja",
	TrainingCatalogue_AddToWaitingList: "Dodaj na listu čekanja",
	ShoppingCart_ShoppingCart: "Košarica",
	ShoppingCart_ReviewAndManage: "Pregledaj i upravljaj svojim pretplatama",
	ShoppingCart_YouHaveAddedItem: "U košaricu ste dodali sljedeće artikle",
	ShoppingCart_Item: "Artikl",
	ShoppingCart_Quantity: "Količina",
	ShoppingCart_PricePerItem: "Cijena po artiklu",
	ShoppingCart_TotalPrice: "Ukupna cijena",
	ShoppingCart_VatIsIncluded: "bez PDV-a",
	ShoppingCart_Redeem: "Ako nemate kredita, kliknite ovdje za otkup",
	ShoppingCart_Purchase: "Ako nemate kredita, kliknite ovdje za kupnju",
	ShoppingCart_Checkout: "Potvrda kupnje",
	ShoppingCart_ContinueShopping: "Nastavi s kupnjom",
	ShoppingCart_TotalPriceInclVat: "Ukupna cijena s PDV-om",
	ShoppingCart_Apply: "Primijeni",
	ShoppingCart_ShoppingCartEmpty: "Vaša je košarica prazna",
	ShoppingCart_VatIncluded: "S PDV-om",
	ShoppingCart_ManageYourShopping: "Upravljaj svojom kupnjom",
	ShoppingCart_Participant: "Sudionik",
	ShoppingCart_Complete: "Dovrši",
	ShoppingCart_NetPrice: "Neto cijena",
	ShoppingCart_Workshop: "Radionica",
	CheckOut_PreviewYourOrder: "Pregledaj svoju narudžbu",
	CheckOut_YourShoppingCart: "Košarica",
	CheckOut_Preview: "Pregled",
	CheckOut_Payment: "Plaćanje",
	CheckOut_Summary: "Sažetak",
	CheckOut_Back: "Natrag",
	CheckOut_PleaseReadAndAgreeOurTerms: "Pročitajte i prihvatite naše odredbe i uvjete",
	CheckOut_IHaveReadTheTermsAndCondition: "Pročitao/pročitala sam [uvjete] te ih razumijem i prihvaćam",
	CheckOut_YesIAgree: "Da, slažem se s odredbama i uvjetima",
	CheckOut_Continue: "Nastavi",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Čestitamo! Primili smo vašu narudžbu",
	CheckOut_OrderConfirmation: "Primit ćete konačnu potvrdu narudžbe kad se vaš zahtjev uspješno obradi",
	CheckOut_WeWillContactYouDirectly: "Obratit ćemo vam se izravno ako imamo pitanja u vezi s vašim zahtjevom",
	CheckOut_AddToCart: "Dodaj u košaricu",
	CheckOut_CreditCart: "Kreditna košarica",
	CheckOut_DirectDebit: "Izravno zaduženje",
	CheckOut_TrainingCredits: "Krediti za obuku",
	CheckOut_PreferredWholesaler: "Preferirani veletrgovac",
	EventCalendar_EventCalendar: "Kalendar događaja",
	EventCalendar_TrainingEventsForAllYourNeeds: "Događaji za obuku za sve vaše potrebe",
	TrainingCourse_TrainingCourse: "Katalog tečajeva za obuku",
	TrainingCourse_LearningAtTheHighestLevel: "Usvajanje znanja na najvišoj razini",
	TrainingProgram_Title: "Katalog programa obuke",
	TrainingProgram_SubTitle: "Usvajanje znanja na najvišoj razini",
	AccountDeactivationModal_Title: "Jeste li sigurni da želite deaktivirati ovaj korisnički račun?",
	AccountDeactivationModal_Line1: "Deaktivacija ovog korisničkog računa trajna je i njome će se ukloniti sav sadržaj, uključujući usluge, zahtjeve, transakcije i stanja računa, članove tima i postavke profila.",
	AccountDeactivationModal_Line2Part1: "Ako je unutar aktivnog razdoblja pretplate, pogledajte naša pravila otkazivanja u našim",
	AccountDeactivationModal_Line2Part2: "odredbama i uvjetima",
	AccountDeactivationModal_Line2Part3: "prije podnošenja zahtjeva.",
	AccountDeactivationModal_Line3: "Jeste li sigurni da želite podnijeti zahtjev za deaktivaciju korisničkog računa?",
	AccountDeactivationModal_Cancel: "Odustani",
	AccountDeactivationModal_Send: "Pošalji",
	CurrentUserAccountDeactivationModal_Title: "Jeste li sigurni da želite deaktivirati svoj korisnički račun?",
	CurrentUserAccountDeactivationModal_Line1: "Deaktivacija vašeg korisničkog računa trajna je i njome će se ukloniti sav sadržaj, uključujući usluge, zahtjeve, transakcije i stanja računa, članove tima i postavke profila.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Ako ste unutar aktivnog razdoblja pretplate, pogledajte naša pravila otkazivanja u našim",
	CurrentUserAccountDeactivationModal_Line3: "Jeste li sigurni da želite podnijeti zahtjev za deaktivaciju svojeg korisničkog računa?",
	CurrentUserAccountDeactivationModal_Yes: "Da",
	CurrentUserAccountDeactivationModal_No: "Ne",
	CourseWithdrawalModal_Title: "Otkazivanje tečaja",
	CourseWithdrawalModal_Line1: "Jeste li sigurni da se želite povući iz tečaja?",
	CourseWithdrawalModal_Line2Part1: "Povlačenja podliježu naknadama za otkazivanje i naplaćuju se u skladu s",
	CourseWithdrawalModal_Line2Part2Link: "odredbama i uvjetima",
	CourseWithdrawalModal_Line2Part3: "naše usluge.",
	CourseWithdrawalModal_Yes: "Da, povuci se",
	CourseWithdrawalModal_No: "Ne, otkaži",
	Settings_Settings: "Postavke",
	Settings_ManageYourSettings: "Upravljaj postavkama",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Moji identifikacijski podaci za prijavu u sustav Bosch Global",
	Settings_Edit: "Uredi",
	Settings_CompanyDetails: "Pojedinosti o društvu",
	Settings_RequestAccountDeletion: "Podnesi zahtjev za brisanje korisničkog računa",
	Settings_AccountDeletionText: "Ako izbrišete korisnički račun, izbrisat će se svi vaši podaci (uključujući adresu i podatke o plaćanju) i više neće biti dostupni.",
	Settings_DeleteAccountRequestConfirmation: "Potvrda zahtjeva za brisanje korisničkog računa",
	Settings_DeleteAccountRequestConfirmationText: "Primili smo vaš zahtjev za brisanje korisničkog računa, stoga ćemo vam se obratiti putem e-pošte ili telefona za konačnu potvrdu",
	RecommendedHotels_RecommendedHotels: "Preporučeni hoteli",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Naši centri za obuku nalaze se diljem svijeta",
	Error_Common: "Došlo je do pogreške!",
	Error_404: "Stranica koju ste posjetili ne postoji",
	Error_401: "Nemate pristup ovoj stranici",
	Error_500: "Poslužitelj javlja pogrešku",
	Dashboard_Courses_Table_Course: "Tečaj",
	Dashboard_Courses_Table_Date: "Datum [Dani]",
	Dashboard_Courses_Table_Venue: "Mjesto",
	Dashboard_Courses_Table_TrainingType: "Vrsta obuke",
	Dashboard_Courses_Table_Action: "Radnja",
	Dashboard_Courses_Table_TasksToComplete: "Morate izvršiti sljedeće zadatke",
	Dashboard_Courses_Table_Withdraw: "Povuci se",
	Dashboard_Courses_Table_Remove: "Ukloni",
	Dashboard_Courses_Table_View: "Prikaži",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Nema zapisa za prikaz",
	Dashboard_Courses_Table_CompletionDate: "Datum završetka",
	Dashboard_Courses_Table_Actions_Assignments: "Zadaci",
	Dashboard_Courses_Table_Actions_Certification: "Certifikati",
	Dashboard_Courses_Table_Actions_Evaluations: "Ocjenjivanja",
	Dashboard_Courses_Table_Actions_Assessments: "Procjene",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Dodaj u kalendar",
	Dashboard_Courses_Table_CourseDetails_Task: "Zadatak",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Rok",
	ContactUs_Headline: "Obratite nam se",
	ContactUs_HowCanWeHelp: "Kako vam možemo pomoći?",
	ContactUs_EmailTab: "E-adresa",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Pismo",
	ContactUs_Phone_Headline: "Nazovite nas!",
	ContactUs_Phone_Description: "Radujemo se vašem pozivu i rado ćemo vam pomoći. Dostupni smo od ponedjeljka do petka od 09:00 do 17:00",
	ContactUs_Phone_PhoneNumber: "Upotrijebite kontaktni obrazac kako biste nam poslali poruku.",
	ContactUs_Phone_ChargeNotice: "Naknade se razlikuju ovisno o vašem pružatelju usluga i državi.",
	ContactUs_Letter_Headline: "Pošaljite pismo!",
	ContactUs_Letter_Description: "Imate li pitanja, zahtjeve ili prijedloge? Radujemo se vašem upitu.",
	ContactUs_Email_Headline: "Pošaljite e-poštu!",
	ContactUs_Email_Description: "Pošaljite nam poruku tako da ispunite obrazac u nastavku. Jedan od naših savjetnika rado će vam se javiti.",
	ContactUs_Email_InputName: "Ime*",
	ContactUs_Email_InputCompany: "Društvo",
	ContactUs_Email_InputCountry: "Država*",
	ContactUs_Email_InputEmail: "E-adresa*",
	ContactUs_Email_InputMessage: "Poruka*",
	ContactUs_Email_DataProtection: "Obavijest o zaštiti podataka*",
	ContactUs_Email_DataProtectionConfirm: "Pročitao/la sam pravila o zaštiti privatnosti.*",
	ContactUs_Email_SecurityCheck: "Sigurnosna provjera",
	ContactUs_Email_SecurityCodeConfirm: "Unesite ispravne znakove koje vidite u gornjem okviru.",
	ContactUs_Email_SecurityCodeInvalid: "Odgovor na tekst „captcha” koji ste unijeli nije ispravan.",
	ContactUs_Email_MandatoryNotice: "Ispunite obvezna polja.",
	ContactUs_Email_SuccessMessage: "Vaša je poruka poslana",
	ContactUs_Email_TakenNoteDataProtection1: "Pročitao/la sam",
	ContactUs_Email_TakenNoteDataProtection2: "Pravila o zaštiti privatnosti.*",
	ContactUs_Email_SubmitButton: "Predaj",
	ContactUs_Email_FieldErrorMessage: "Ispunite obvezna polja.",
	ContactUs_Email_SuccessModal_OK: "U redu",
	AccountInReviewModal_Title: "Vaš korisnički račun trenutačno se pregledava!",
	AccountInReviewModal_Paragraph1: "Hvala vam za registraciju osobnog računa na sustavu Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Naše predano osoblje za obuku trenutačno pregledava i konfigurira vaš korisnički račun. Konačnu ćete potvrdu primiti poštom nakon što vaš korisnički račun bude uspješno konfiguriran.",
	AccountInReviewModal_Paragraph3: "U međuvremenu možete slobodno pregledavati našu mrežnu stranicu uz ograničen pristup.",
	AccountInReviewModal_Paragraph4: "Ako imate bilo kakvih pitanja ili ako se vaš korisnički račun ne obradi u roku od 48 sati, ne računajući vikende i praznike, možete nam se obratiti",
	AccountInReviewModal_ContactUsLink: "ovdje",
	AccountInReviewModal_OKButton: "U redu, pusti me unutra!",
	Header_YesButton: "Da",
	Header_NoButton: "Ne",
	Header_LogOutText: "Želite li se odjaviti?",
	Footer_EnquiryText: "Radujemo se vašem upitu,",
	Footer_Telephone: "Telefon,",
	Footer_TermsAndConditions: "Odredbe i uvjeti",
	Footer_HowOurTrainingEvolves: "Kako se naša obuka razvija",
	FooterSearch_SearchFieldPlaceholder: "Pretraživanje",
	TrainingCatalogue_Action: "Radnja",
	TrainingCatalogue_Duration: "Trajanje",
	TrainingCatalogue_Information: "Informacije",
	Settings_PersonalDetails: "Osobni podaci",
	Reset_All_Filters: "Ponovno postavi sve filtre",
	EventTable_Days: "Broj dana",
	SearchField_AllResults: "Svi rezultati",
	TrainingCentresLayout_ViewDetails: "Prikaz pojedinosti",
	Dropdown_Placeholder: "Odaberite...",
	WaitlistPopup_SelectButtonText: "Odaberi",
	WaitlistPopup_StartDate: "Početni datum",
	WaitlistPopup_EndDate: "Završni datum",
	WaitlistPopup_Language: "Jezik",
	WaitlistPopup_City: "Grad",
	WaitlistPopup_Workshop: "Radionica",
	WaitlistPopup_Student: "Polaznik",
	WaitlistPopup_PreferredLocation: "Preferirana lokacija",
	WaitlistPopup_SignUpCourseWaitingList: "Prijavi se na listu čekanja za tečaj",
	WaitlistPopup_SignUpWithoutSelectingDate: "Prijavi se na listu čekanja bez odabira datuma",
	WaitlistPopup_GeneralWaitList: "Opća lista čekanja",
	WatinglistPopup_CourseLabel: "Tečaj",
	Common_ContinueButton: "Nastavi",
	UserAccountDeactivationModal_ConfirmLabel: "Potvrdi",
	Filter_FilterLabel: "Filtar",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Već ste na listi čekanja, želite li ukloniti postojeću registraciju i izraditi novu?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Da, ukloni",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Ne, otkaži",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Otkazivanje tečaja",
	WaitinglistPopup_SignUpButtonText: "Prijava",
	MyAccount_FirstNameRequied: "Potrebno je ime",
	MyAccount_LastNameRequired: "Potrebno je prezime",
	MyAccount_RoleRequired: "Potrebna je uloga",
	MyAccount_MobileRequired: "Potreban je mobilni telefon",
	MyAccount_EmailRequired: "Potrebna je adresa e-pošte",
	MyAccount_LanguageRequired: "Potreban je jezik",
	MyAccount_UpdateSuccessMessage: "Pojedinosti o vašem korisničkom računu uspješno su ažurirane!",
	MyAccount_UpdateSuccessModal_ButtonText: "Da, zatvori",
	Infor_CorporateInformation_Header: "Korporacijske informacije",
	Infor_LegalNotice_Header: "Pravna napomena",
	Infor_PrivacyPolicy_Header: "Obavijest o zaštiti podataka (Pravila o zaštiti privatnosti)",
	CreateEmployeeAccountModification_FormValidation_Role: "Potrebna je uloga",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Potrebno je ime",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Potrebno je prezime",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Potreban je mobilni telefon",
	CreateEmployeeAccountModification_FormValidation_Email: "Potrebna je adresa e-pošte",
	CreateEmployeeAccountModification_FormValidation_Language: "Potreban je jezik",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Poziv za pridruživanje sustavu Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Uspješno ste dodali novog člana u svoj tim!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Želite li da s vašim novim članom tima podijelimo pozivnicu putem e-pošte s uputama kako mogu izraditi vlastiti osobni račun za sustav Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Ne",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Da, pošalji pozivnicu",
	ShoppingCart_Venue_Fee: "Naknada za mjesto",
	ShoppingCart_Trainning_Name: "Naziv obuke",
	ShoppingCart_Available_Credits: "Dostupni bodovi",
	ConfirmItsMeModal_Hi: "Bok!",
	ConfirmItsMeModal_Description1: "Uspješno ste ispunili pozivnicu koju je podijelio administrator vašeg društva za izradu vlastitog osobnog računa za sustav Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Prije nastavka potvrdite ili ažurirajte podatke o svojem korisničkom računu.",
	ConfirmItsMeModal_Description3: "Nakon potvrde morat ćete se ponovno prijaviti kako biste dovršili izradu korisničkog računa.",
	ConfirmItsMeModal_CompanyDetails: "Pojedinosti o društvu",
	ConfirmItsMeModal_YourDetails: "Vaše pojedinosti",
	ConfirmItsMeModal_YourDetails_Title: "Naslov",
	ConfirmItsMeModal_YourDetails_Function: "Funkcija",
	ConfirmItsMeModal_YourDetails_Name: "Ime",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobilni telefon",
	ConfirmItsMeModal_YourDetails_Email: "E-adresa",
	ConfirmItsMeModal_YourDetails_Language: "Jezik",
	ConfirmItsMeModal_ConfirmButton: "Potvrdi",
	SelectWholesalerModal_WholesalerGroupLabel: "Veleprodajni trgovac",
	SelectWholesalerModal_WholesalerBranchLabel: "Trgovina",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Odaberite",
	ApplyButton: "Primijeni",
	SelectWholesalerModal_Title: "Izaberite svojeg veleprodajnog trgovca",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Želite li ukloniti svoju registraciju?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Vaša je registracija uspješno uklonjena!",
	WaitlistPopup_AddToWaitListSuccess: "Vaša je registracija uspješno dodana!",
	ErrorCommon_Header: "GREŠKA",
	CommonButton_Close: "Zatvori",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Za nastavak zatvorite ovaj prozor!",
	EmbeddedVideoPlayer_StartButtonText: "Početak",
	EmbeddedVideoPlayer_RefresherButtonText: "Obnavljanje naučenog",
	DeactivateWholesalerModal_ConfirmMessage: "Jeste li sigurni da želite ukloniti ovog veleprodajnog trgovca?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Uspješno slanje pozivnice e-poštom!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Ponovno pozovi",
	ContactUs_Mobile_HR: "+3851 2958054",
	ContactUs_Address_HR: "Robert Bosch d.o.o., Auto oprema, 10040 Zagreb, Hrvatska",
	ConfirmItsMeModal_CompanyDetails_Name: "Naziv društva",
	ConfirmItsMeModal_CompanyDetails_Address: "Adresa",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Poštanski broj društva",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-adresa",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Uspješno povlačenje iz tečaja!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Izrada korisničkog računa nije uspjela. Korisnički račun već postoji!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Ažuriranje korisničkog računa nije uspjelo. Korisnički račun već postoji!",
	Footer_ExploreTrainingStories: "Istražite sve naše priče o obuci",
	Breadcrumb_Home: "Početna stranica",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Priče",
	Breadcrumb_Stories_BoschServiceTraining: "Boscheva obuka za servis",
	Breadcrumb_Stories_ADayInLife: "Dan u životu voditelja obuke",
	Breadcrumb_Stories_BoschTrainingBenefits: "Prednosti Boscheve obuke",
	Breadcrumb_Stories_TrainingApproach: "Moderan pristup obuci",
	Breadcrumb_Settings: "Postavke",
	Breadcrumb_ShoppingCart: "Košarica",
	Breadcrumb_Checkout: "Naplata",
	Breadcrumb_OrderSummary: "Sažetak narudžbe",
	Breadcrumb_Exception: "Iznimka",
	Breadcrumb_Dashboard: "Nadzorna ploča",
	Breadcrumb_MyCourses: "Moji tečajevi",
	Breadcrumb_MyELearning: "Moje e-učenje",
	Breadcrumb_CompanyAdministration: "Administracija društva",
	Breadcrumb_EmployeeManagement: "Upravljanje zaposlenicima",
	Breadcrumb_EmployeeAccount: "Korisnički račun zaposlenika",
	Breadcrumb_EmployeeCreation: "Izrada zaposlenika",
	Breadcrumb_Administration: "Administracija",
	Breadcrumb_EmployeeAdministration: "Administracija zaposlenika",
	Breadcrumb_WholesalerManagement: "Upravljanje veletrgovcima",
	Breadcrumb_NewWholesalerAccountCreation: "Izrada novog korisničkog računa veletrgovca",
	Breadcrumb_Transactions: "Transakcije",
	Breadcrumb_MyAccount: "Moj korisnički račun",
	Breadcrumb_TrainingCentres: "Centri za obuku",
	Breadcrumb_EventCalendar: "Kalendar događaja",
	Breadcrumb_TrainingCourseCatalogue: "Katalog tečajeva za obuku",
	Breadcrumb_RecommendedHotels: "Preporučeni hoteli",
	Breadcrumb_CorporateInformation: "Informacije o korporaciji",
	Breadcrumb_LegalNotice: "Pravna napomena",
	Breadcrumb_DataProtectionNotice: "Obavijest o zaštiti podataka",
	Breadcrumb_PrivacyPolicy: "Pravila privatnosti",
	Breadcrumb_TermCondition: "Odredbe i uvjeti",
	Breadcrumb_Terms: "Uvjeti",
	Breadcrumb_PrivacyStatement: "Izjava o privatnosti",
	Breadcrumb_Cookies: "Kolačići",
	Breadcrumb_Provider: "Pružatelj",
	Breadcrumb_RegisterAccount: "Registriraj korisnički račun",
	Stories_ExploreMore: "Istraži više",
	CheckOutLayout_ShoppingCartHeader: "Košarica",
	CheckOutLayout_AddedItemCartMessage: "U košaricu ste dodali sljedeće artikle",
	CheckOutLayout_CheckYourCartMessage: "Provjerite košaricu",
	ModernTrainingApproach_DateText: "28. 2. 2022.",
	ADayInLifeofATrainer_DateText: "28. 2. 2022.",
	BoschTrainingBenefits_DateText: "28. 2. 2022.",
	BoschServiceTraining_DateText: "28. 2. 2022.",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Pretraži zaposlenike",
	CheckOutLayout_OrderWasNotPlaced_Message: "Žao nam je! Vaša narudžba nije izvršena",
	CheckOutLayout_RefusalReason: "Razlog odbijanja",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Plaćanje nije uspjelo. Pokušajte ponovno s drugim načinom plaćanja",
	CheckOutLayout_SubmittingApiFailed_Message: "Podnošenje API-ja nije uspjelo",
	CartItem_ChooseWorkshop_Message: "Odaberite radionicu kako biste odabrali sudionika",
	CartItem_ChooseParticipant_Message: "Odaberite sudionika za nastavak",
	CompanyAdministrationTable_SearchBox_Placeholder: "Pretraži radionice",
	CartItem_RemoveCartItem_ConfirmText: "Želite li ukloniti ovaj artikl iz košarice?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funkcija",
	AddToCartSuccessModal_Message: "Vaša je košarica ažurirana!",
	AddToCartSuccessModal_ContinueButtonText: "Nastavi s kupnjom",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Moja košarica",
	AddVoucher_Header: "Dodaj kupon",
	AddVoucher_InputPlaceholder: "Unesi kôd i primijeni ga",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Nažalost, trenutačno nemamo zakazanih događaja. Prijavite se na listu čekanja pa ćemo vas obavijestiti kada događaj postane dostupan",
	ADYEN_ERR_2_: "Transakcija je odbijena.",
	ADYEN_ERR_3_: "Pogreška u upućivanju",
	ADYEN_ERR_4_: "Transakcija nije obrađena zbog greške na strani primatelja.",
	ADYEN_ERR_5_: "Kartica koja je upotrijebljena za transakciju je blokirana, dakle nije upotrebljiva.",
	ADYEN_ERR_6_: "Kartica koja je upotrijebljena za transakciju je istekla. Dakle, nije upotrebljiva.",
	ADYEN_ERR_7_: "Tijekom postupka transakcije došlo je do neusklađenosti iznosa.",
	ADYEN_ERR_8_: "Navedeni broj kartice netočan je ili nevaljan.",
	ADYEN_ERR_9_: "Nije moguće stupiti u kontakt s bankom kupca radi autorizacije transakcije.",
	ADYEN_ERR_10_: "Banka kupca ne podržava ili ne dopušta ovu vrstu transakcije.",
	ADYEN_ERR_11_: "Provjera autentičnosti s pomoću protokola 3D Secure nije izvršena ili nije izvršena uspješno.",
	ADYEN_ERR_12_: "Na kartici nema dovoljno novca za pokriće iznosa koji treba platiti. Taj problem može nastati zbog ograničenja mjesečnog iznosa za plaćanje na kartici. Pokušajte upotrijebiti drugu karticu, npr. MasterCard, ili se obratite svojoj banci kako biste ponovno postavili mjesečno ograničenje na kartici.",
	ADYEN_ERR_14_: "Moguća prijevara.",
	ADYEN_ERR_15_: "Transakcija je otkazana.",
	ADYEN_ERR_16_: "Kupac je otkazao transakciju prije nego što se izvršila.",
	ADYEN_ERR_17_: "Navedeni PIN netočan je ili nevaljan.",
	ADYEN_ERR_18_: "Kupac je unio netočan PIN više od tri puta zaredom.",
	ADYEN_ERR_19_: "Nije moguće potvrditi navedeni PIN.",
	ADYEN_ERR_20_: "Moguća prijevara.",
	ADYEN_ERR_21_: "Transakcija nije ispravno predana na obradu.",
	ADYEN_ERR_22_: "Provjera rizika označila je transakciju kao prijevaru (ocjena rizika >= 100); operacija je stoga otkazana.",
	ADYEN_ERR_23_: "Sljedeći kodovi odbijanja povezani su s ovim razlogom odbijanja:  Transakcija nije dopuštena izdavatelju / vlasniku kartice",
	ADYEN_ERR_24_: "Navedeni CVC (sigurnosni kȏd kartice) nije valjan.",
	ADYEN_ERR_25_: "Sljedeći kodovi odbijanja povezani su s ovim razlogom odbijanja:  Kartica nije valjana u ovoj državi",
	ADYEN_ERR_26_: "R1: Opoziv naloga za autorizaciju / „R3: Opoziv svih naloga za autorizaciju” / „R0: Zaustavi nalog za plaćanje”",
	ADYEN_ERR_27_: "Ovaj je odgovor povezan sa svim kodovima odgovora koji se ne mogu pouzdano povezati. Na taj je način lakše razlikovati opće odbijanje (naprimjer, Mastercard „05: Nemoj prihvatiti” odgovor) od konkretnijih odbijanja.",
	ADYEN_ERR_28_: "Prekoračen je dopušteni iznos za isplatu na kartici kupca.",
	ADYEN_ERR_29_: "Prekoračen je broj dopuštenih isplata na kartici kupca.",
	ADYEN_ERR_31_: "Izdavatelj je prijavio da za transakciju postoji sumnja na prijevaru.",
	ADYEN_ERR_32_: "Podaci o adresi koje je kupac unio su netočni.",
	ADYEN_ERR_33_: "Banka kupca zahtijeva da kupac unese mrežni PIN.",
	ADYEN_ERR_34_: "Banka kupca zahtijeva čekovni račun za dovršetak kupnje.",
	ADYEN_ERR_35_: "Banka kupca zahtijeva štedni račun za dovršetak kupnje.",
	ADYEN_ERR_36_: "Banka kupca zahtijeva da kupac unese mobilni PIN.",
	ADYEN_ERR_37_: "Kupac je odustao od transakcije nakon što je pokušao beskontaktno platiti i od njega se tražilo da isproba drugi način unosa kartice (PIN ili provlačenje).",
	ADYEN_ERR_38_: "Izdavatelj je odbio zahtjev za izuzeće od provjere autentičnosti i zahtijeva provjeru autentičnosti za transakciju. Pokušajte ponovno s protokolom 3D Secure.",
	ADYEN_ERR_39_: "Izdavatelj ili shema nisu mogli priopćiti ishod putem paketa RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Ime ne smije sadržavati posebne znakove (npr. @, #, $...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Ime mora biti kraće od 75 znakova",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Prezime ne smije sadržavati posebne znakove (npr. @, #, $...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Prezime mora biti kraće od 75 znakova",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Broj mobitela ne smije sadržavati posebne znakove (npr. @, #, $...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Broj mobitela mora biti kraći od 45 znakova",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telefonski broj ne smije sadržavati posebne znakove (npr. @, #, $...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telefonski broj mora biti kraći od 45 znakova",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Navedena e-adresa nije valjana",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-adresa mora biti kraća od 100 znakova",
	ConfirmGeolocationModal_ConfirmButton: "Nastaviti",
	ConfirmGeolocationModal_CancelButton: "Promijeniti",
	ConfirmGeolocationModal_CurrentCountrySelection: "Vaš trenutni odabir zemlje je:",
	ConfirmGeolocationModal_AskChangeCountry: "Želite li ostati na ovoj stranici ili promijeniti svoju državu?",
	Addtocart_Modal_AddToCartFailTitle: "Dodavanje sudionika nije uspjelo",
	Addtocart_Modal_AlreadyInCart: "Sudionik je već registriran za ovaj događaj",
	TrainingCatalogue_DateRange: "Raspon datuma",
	TrainingCatalogue_DateRange_placeholder: "Odaberi filtar raspona datuma",
	Dashboard_MyPendingCourses: "Moji tečajevi na čekanju",
	Dashboard_MyWaitingList: "Moja lista čekanja",
	Dashboard_LatestNews: "Najnovije vijesti",
	SettingsMainPage_EditAccountButton: "Uredi korisnički račun",
	SettingsMainPage_EditBoschId: "Uredi Bosch ID",
	EventInfoView_EventDetailTab: "Pojedinosti o događaju",
	EventInfoView_AssignmentsTab: "Zadaci",
	EventInfoView_EvaluationTab: "Procjena",
	EventInfoView_AttachmentTab: "Prilozi",
	EventInfoView_CourseDescriptionTab: "Opis",
	EventInfoView_DescriptionTab_General: "Općenito",
	EventInfoView_DescriptionTab_PartLabel: "Dio",
	AssignmentTab_Description: "Ovdje ćete pronaći sve informacije koje će vam trebati za dovršetak tečaja.",
	AssignmentTab_Columns_Download: "Preuzmi",
	AssignmentTab_Columns_Upload: "Učitaj",
	AssignmentTab_Columns_TaskComplete: "Zadatak dovršen",
	EventDetailTab_Location_Message_Video: "Ovo je događaj na mreži kojemu se može pristupiti putem poveznice \„Pokreni videozapis\”.",
	EventDetailTab_Location_Message_WebBased: "Ovo je događaj na mreži kojemu se može pristupiti putem poveznice \„Pokreni WBT\”.",
	EventDetailTab_Location_Message_WebCast: "Ovo je događaj na mreži kojemu se može pristupiti putem poveznice \„Odmah se pridruži\”.",
	EventDetailTab_Link_Video: "Pokreni videozapis",
	EventDetailTab_Link_Video_Remark: "* Pokretanjem videozapisa otvorit će se novi prozor. Bit ćete preusmjereni natrag na ovu stranicu kada se prozor zatvori.",
	EventDetailTab_Link_WebBased: "Pokreni WBT",
	EventDetailTab_Link_WebBased_Remark: "* Pokretanjem WBT-a otvorit će se novi prozor. Bit ćete preusmjereni natrag na ovu stranicu kada se prozor zatvori.",
	EventDetailTab_Link_WebCast: "Odmah se pridruži",
	EventDetailTab_Link_WebCast_Remark: "* Poveznica „Odmah se pridruži” bit će aktivna 30 minuta prije početka događaja.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Trajanje (sati)",
	EventDetailTab_Title_Trainers: "Voditelj(i) obuke",
	EventDetailTab_Title_Language: "Jezik",
	EventDetailTab_Title_Score: "Rezultat (%)",
	EventDetailTab_Title_Calendar: "Dodaj u kalendar",
	EventDetailTab_Title_Certificate: "Preuzmi certifikat",
	EventDetailTab_Title_Messages: "Poruke",
	EventDetailTab_Title_StartDate: "Datum početka",
	EventDetailTab_Title_AvailableFrom: "Dostupno od",
	EventDetailTab_Title_Time: "Vrijeme",
	EventDetailTab_Title_EndDate: "Datum završetka",
	EventDetailTab_Title_ExpiresOn: "Istječe",
	EventDetailTab_Title_Room: "Prostorija",
	EventDetailTab_Title_Method: "Način",
	EventDetailTab_Title_Link: "Poveznica*",
	EventDetailTab_Title_Location: "Mjesto",
	AttachmentTab_EmptyScreenDescription: "Nema priloga za ovaj događaj.",
	AttachmentTab_Columns_Download: "Preuzmi",
	AttachmentTab_Description: "Ovdje ćete pronaći neke važne informacije koje će vam pomoći s ovim tečajem",
	EvaluationTab_Annotation: "Cijenimo vaše povratne informacije i htjeli bismo čuti vaše mišljenje kako nam ide. Odvojite vrijeme za ispunjavanje sljedeće procjene kako biste nam pomogli u neprestanom poboljšavanju naše uslugu za vas. Hvala!",
	EvaluationTab_Notice: "Ocjene su sljedeće",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Odgovorite na sva obvezatna pitanja prije podnošenja svoje procjene događaja",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Vaše su povratne informacije uspješno podnesene",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Podnošenje procjene nije uspjelo",
	TrainingCatalogueLayout_ExportButtonText: "Izvezi",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Procjena je dovršena ili je istekla.",
	EventDetailTab_Link_CopiedTooltip: "Kopirano u međuspremnik",
	EventDetailTab_QRCode_ExplanationText_WBT: "Skeniraj radi pokretanja WBT-a",
	EventDetailTab_QRCode_ExplanationText_Video: "Skeniraj radi pokretanja videozapisa",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Skeniraj radi pokretanja prijenosa internetskog medijskog videosadržaja",
	Footer_TrainingCoure_Headline: "Tečajeve za obuku pruža društvo Bosch",
	Footer_TrainingCourse_Content: "Društvo Bosch, kao svjetski poznata megamarka s više od stoljeća iskustva u inovacijama, postavilo je standarde za cijeli svijet kao dobavljač automobilskih sustava, dijelova i tehničkih rješenja. Velik dio snage crpimo iz bliskog partnerstva s proizvođačima vozila, za koje projektiramo, razvijamo i proizvodimo vrhunske sustave i komponente diljem svijeta. Na taj je način društvo Bosch izgradilo snažnu tradiciju u području autoopreme i podrške uz raznolik izbor dijelova, dijagnostičkih rješenja i servisnih usluga. Učenje i razvoj sigurni su ključevi uspjeha, a ta je filozofija poduprta širokim rasponom tečajeva u svim područjima automobilske tehnologije, sustava vozila i dijagnostičke stručnosti.",
	SearchField_NoRecordsFound: "Nije pronađen nijedan zapis",
	TrainingCatalogue_FreeTextSearch: "Slobodan tekst",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Pretraži",
	CheckoutLayout_WholeSalerGroup: "Odaberi skupinu trgovaca na veliko",
	CreditInsufficientModal_Message: "Nemate dovoljno sredstava za uporabu tog načina plaćanja. Pokušajte ponovno s drugim načinom plaćanja.",
	ShoppingCart_Required_Credits: "Ukupna cijena",
	MaintainingInfo_Message1: "Mrežno je mjesto u načinu održavanja.",
	MaintainingInfo_Message2: "Uskoro ćemo se vratiti.",
	CountryValidationModal_Message: "Niste se mogli prijaviti u [CountryA] jer je vaš korisnički račun izrađen u [CountryB].",
	CountryValidationModal_ButtonText: "Nastavite u [Country]",
	EventTable_LoginRequired: "Prijavite se kako biste se registrirali",
	TrainingPartTableLayout_Date: "Datum",
	TrainingPartTableLayout_StartTime: "Vrijeme početka",
	TrainingPartTableLayout_EndTime: "Vrijeme završetka",
	TrainingPartTableLayout_Duration: "Trajanje (sati)",
	TrainingPartTableLayout_TrainingMethod: "Način obuke",
	MyCoursesTableContents_ActiveHeader: "Aktivni tečajevi",
	MyCoursesTableContents_ActiveTabHeader: "Aktivno",
	MyCoursesTableContents_ScheduledHeader: "Zakazani tečajevi",
	MyCoursesTableContents_ScheduledTabHeader: "Zakazano",
	MyCoursesTableContents_OnlineHeader: "Mrežni tečajevi",
	MyCoursesTableContents_OnlineTabHeader: "Mrežno",
	MyCoursesTableContents_CancelledHeader: "Otkazani tečajevi",
	MyCoursesTableContents_CancelledTabHeader: "Otkazano",
	OnlineCoursesTable_Title_Progress: "Napredak",
	ScheduledCoursesTable_Title_Date: "Datum [dani]",
	CoursesTable_Title_Completed: "Dovršeno",
	Footer_TrainingProgram: "Programi obuke",
	Breadcrumb_TrainingProgram: "Programi obuke",
	TrainingProgram_Title_Table: "Naziv",
	TrainingProgram_TrainingProgram: "Programi obuke",
	TrainingProgram_TargetGroup: "Ciljna skupina",
	TrainingProgram_MaxLength: "Maks. duljina",
	TrainingProgram_Information: "Informacije",
	TrainingProgram_Detail: "Pojedinosti",
	TrainingProgram_Modules: "Moduli",
	TrainingProgram_Course: "Tečajevi",
	TrainingProgram_PartNumber: "Broj dijela",
	TrainingProgram_Max_Length: "Maksimalna duljina",
	TrainingProgram_Duration: "Trajanje",
	TrainingProgram_Exam: "Ispitivanje",
	TrainingProgram_Assign_Popup: "Registracija za program obuke",
	TrainingProgram_Student: "Polaznik",
	TrainingProgram_Assign: "Dodijeli",
	TrainingProgram_Popup_Remove_Title: "Otkazati obuku?",
	TrainingProgram_Description: "Opis",
	TrainingProgram_Days: "Dani",
	TrainingProgram_Type: "Vrsta",
	TrainingProgram_Action: "Radnja",
	TrainingProgram_Enrolled: "već je upisan(a) na taj program obuke",
	TrainingProgram_SelfAssign_Enrolled: "Već ste dodijeljeni tom programu obuke",
	TrainingProgram_Warning: "Registrirajte još jednog polaznika ili odaberite neki drugi program obuke.",
	TrainingProgram_Workshop: "Radionica",
	TrainingProgram_Durations: "godina",
	TrainingProgram_Duration_DaysLabel: "dana",
	TrainingProgram_HasExam_Yes: "Da",
	TrainingProgram_HasExam_No: "Ne",
	AssignModal_SuccessModal_Header: "Čestitamo!",
	AssignModal_SuccessModal_Message: "uspješno je upisan(a) na taj program obuke.",
	AssignModal_SelfAssign_SuccessModal_Message: "Uspješno ste dodijeljeni programu obuke.",
	Dashboard_MyTraningProgram: "Moj program obuke",
	Dashboard_MyTraningProgram_Title: "Naziv",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Prikaz",
	Dashboard_MyTraningProgram_Register: "Registracija",
	Dashboard_MyTraningProgram_Records: "Povijesni zapisnik",
	Dashboard_MyTrainingProgram_In_Progress: "U tijeku",
	Dashboard_MyTrainingProgram_Accredited: "Akreditirano",
	Dashboard_Program_Modal_Withdraw: "Povuci se",
	Dashboard_Program_Modal_Grade: "Ocjena",
	Dashboard_MyTrainingProgram_Registered: "Registrirano",
	Dashboard_MyTrainingProgram_Completedd: "Dovršeno",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Da",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Ne",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Imajte na umu da povlačenje znači da ćete se povući iz cijelog programa obuke, a ne samo iz pojedinačnog tečaja obuke.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Želite li se i dalje povući iz programa obuke?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Na čekanju",
	WithdrawProgramModal_Withdraw_Success_Message: "Uspješno ste se povukli iz programa obuke!",
	Common_Warning_Title: "Upozorenje!",
	Common_Error_Title: "Došlo je do pogreške!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Dodaj u košaricu",
	AddEventFromCatalogToCartModal_HoursText: "sati",
	AddElearningToCartSuccessModal_Message: "je dodan/dodana/dodano u vašu košaricu!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Odabrani je sudionik registriran na ovaj tečaj ili je tečaj već dodan u košaricu.",
	EventDetailTab_SubjectForChange_Message: "Podložno promjenama",
	Dashboard_MyTrainingProgram_Status_Failed: "Pad",
	Dashboard_MyTrainingProgram_Status_Passed: "Prolaz",
	Dashboard_MyTrainingProgram_Status_Merit: "Zasluga",
	Dashboard_MyTrainingProgram_Status_Distinction: "Priznanje",
	Dashboard_Employee_Courses: "Tečajevi",
	Dashboard_Employee_Program: "Program školovanja",
	Dashboard_Employee_WaitList: "Lista čekanja",
	Dashboard_Employee_Account: "Račun",
	Course_Price_Free: "Slobodno",
	Dashboard_Employee_Account_Update_Success_Message: "Uspješno ste ažurirali svoje podatke.",
	Dashboard_Supplier: "Dobavljač",
	Event_Detail_Comment: "Trenutačno nema poruka",
	DownLoad_PDF: "Preuzimanje PDF datoteke",
	TrainingCatalogue_Currency: "Valuta",
	My_Wallet: "Moj novčanik",
	Wallet: "Novčanik",
	Credits_Balance: "Stanje bodova",
	MyFullCalendar_Today: "Danas",
	Header_Available_Languages: "Dostupni jezici",
	Header_CurrentRegion: "Trenutačno se nalazite u",
	Header_ChangeRegions: "Želite li promijeniti državu?",
	Header_Anonymous: "Obuka za servis",
	Header_Anonymous_Login: "Prijava/registracija",
	Header_RegisterCourse: "Registriraj tečaj",
	Homepage_Start: "Započni",
	Homepage_Login: "Prijava",
	MainPage_Title: "Vrhunsko rješenje za radionice",
	MainPage_Content: "Vaša radionica, vaš izbor.",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Modernoj radionici omogućuje stručan, pouzdan i učinkovit rad u vezi s dijagnostičkim postupcima, otklanjanjem poteškoća, popravcima i servisiranjem svih vrsta vozila.",
	BoschNews_TrainingNews: "Vijesti o obuci",
	Service_Assist_Workshop: "Unaprijedite svoje iskustvo uz Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Što mogu učiniti u aplikaciji?",
	Enhance_Body_CDM3_Item_1: "Rezervirati tečajeve i pratiti svoje obrazovno putovanje",
	Enhance_Body_CDM3_Item_2: "Preuzeti materijal za obuku i certifikate za tečajeve",
	Enhance_Body_CDM3_Item_3: "Pokrenuti tečajeve e-učenja",
	Enhance_Body_CDM3_Item_4: "Pristupiti raznim automobilskim uslugama putem mobilne aplikacije",
	Enhance_Body_CDM3_Item_5: "Prijenos videozapisa uživo s pomoću aplikacije Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Besplatni alat za automatsko skeniranje vozila",
	Training_Stories: "Priče o obuci",
	SettingsMyAccount_JoinCompanyButton: "Pristupi društvu",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Pristupi društvu",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "U nastavku unesite svoj aktivacijski kôd",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Napomena! Morate se obratiti administratoru svojeg društva kako biste pribavili aktivacijski kôd.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Vaša je aktivacija uspješno obrađena i vaš je korisnički račun sad povezan s vašim društvom.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Kliknite „Nastavi” kako biste otvorili mrežno mjesto.",
	ContinueButton: "Nastavi",
	WelcomeBatsModal_Title: "Vaš korisnički račun trenutačno se pregledava!",
	WelcomeBatsModal_ActivationLink: "Kliknite ovdje ako imate aktivacijski kôd za pristupanje društvu!",
	WelcomeBatsModal_ActivationLinkNotes: "Napomena! Morate se obratiti administratoru svojeg društva kako biste pribavili aktivacijski kôd.",
	AccountInReviewModal_Description_Paragraph1: "Hvala vam na registraciji osobnog računa u sustavu Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Naše predano osoblje za obuku trenutačno pregledava i konfigurira vaš korisnički račun. Konačnu ćete potvrdu primiti poštom nakon što vaš korisnički račun bude uspješno konfiguriran.",
	AccountInReviewModal_CloseButton: "U redu, pusti me unutra!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Nismo uspjeli potvrditi vaš aktivacijski kôd i e-adresu.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Pokušajte ponovno ili se obratite administratoru svojeg društva za pomoć.",
	TryAgain_Button: "Pokušajte ponovno",
	Error_Title: "Ups, došlo je do pogreške!",
	SettingsMyProfileActivationSuccessModal_Title: "Uspjeh!",
	AccountInReviewModal_Description_Paragraph3: "U međuvremenu možete slobodno pregledavati naše mrežno mjesto uz ograničen pristup.",
	AccountInReviewModal_Description_Paragraph4: "Ako imate bilo kakvih pitanja ili ako se vaš korisnički račun ne obradi u roku od 48 sati, ne računajući vikende i praznike, možete nam se obratiti [ovdje]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Smjernice za pomoć...",
	Support_FAQ_Headline: "Često postavljana pitanja",
	Support_FAQs_section1_title: "Općenito",
	Support_FAQs_section1_question1: "Što je Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions jest platforma na kojoj se pruža sveobuhvatan pregled tehničkih obuka i omogućuju rezervacija obuka i upravljanje njima.",
	Support_FAQs_section1_question2: "Mogu li se prijaviti u sustav Bosch Training Solutions s pomoću svojeg postojećeg korisničkog računa za Bosch (singleKey ID)?",
	Support_FAQs_section1_question2_answer1: "Da, pristup je moguć sa svim korisničkim računima izrađenima s pomoću \„My Bosch ID-ja\”.",
	Support_FAQs_section1_question3: "Mogu li upotrebljavati korisnički račun koji je izrađen za Bosch Training Solutions s drugim Boschevim uslugama ili aplikacijama?",
	Support_FAQs_section1_question3_answer1: "Da, svoj osobni Bosch ID možete upotrebljavati za razne Boscheve aplikacije – i poslovno i privatno, poput e-bicikla ili pametnog doma.",
	Support_FAQs_section2_title: "Obuka",
	Support_FAQs_section2_question1: "Kako mogu rezervirati obuku?",
	Support_FAQs_section2_question1_answer1: "Prijavite se svojim postojećim korisničkim računom ILI registrirajte korisnički račun na portalu. Idite u \„Usluge obuke\” ILI \„Kalendar događaja\”, odaberite obuku i kliknite gumb „Dodaj u karticu”.",
	Support_FAQs_section2_question2: "Nema dostupnih odgovarajućih datuma obuke. Što mogu učiniti?",
	Support_FAQs_section2_question2_answer1: "Možete se upisati na \„općeniti\” popis čekanja (bez odabira datuma), a naš će vas tim obavijestiti čim bude dostupna nova obuka.",
	Support_FAQs_section2_question3: "Gdje mogu pronaći pregled svojih rezerviranih tečajeva?",
	Support_FAQs_section2_question3_answer1: "Možete pronaći potpuni pregled svih rezerviranih i završenih tečajeva nakon prijave na svojoj nadzornoj ploči.",
	Support_FAQs_section3_title: "Postavke",
	Support_FAQs_section3_question1: "Kako mogu promijeniti svoju e-adresu i lozinku?",
	Support_FAQs_section3_question1_answer1: "Morate se prijaviti na portal i promijeniti svoj osobni profil. (Svoje profilne informacije možete pronaći na svojoj nadzornoj ploči ili ako kliknete na sljedeći gumb u zaglavlju",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Tamo možete promijeniti pojedinosti svojeg Bosch ID-ja",
	Support_FAQs_section3_question2: "Što mogu učiniti ako zaboravim lozinku?",
	Support_FAQs_section3_question2_answer1: "Kliknite na gumb „Prijava/registracija” i unesite svoju e-adresu",
	Support_FAQs_section3_question2_answer2: "Ako kliknete „Nastavi”, možete kliknuti na gumb \„Zaboravljena lozinka\”",
	Support_FAQs_section3_question3: "Kako postaviti korisnički račun za radionicu?",
	Support_FAQs_section3_question3_answer1: "Ako želite mogućnost dodjele uloge radionice, obratite se svojoj lokalnoj administraciji za obuke putem, primjerice, kontaktnog obrasca na mrežnom mjestu.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Odaberite državu/regiju",
	MaintenanceBanner_Message_1: "Portal za Bosch Automotive Training Solutions ažurirat će se [maintenance_schedule]. Očekivano trajanje ažuriranja jest 30 minuta. Portal neće biti dostupan tijekom ažuriranja.",
	MaintenanceBanner_Message_2: "\nZa daljnju podršku obratite nam se na e-adresu [support_email]",
	MaintenancePage_Message_1: "Portal za Bosch Automotive Training Solutions trenutačno se ažurira",
	MaintenancePage_Message_2: "Očekujemo da će Portal ponovno biti dostupan [maintenance_online_date] u [maintenance_online_time]",
	MenuHeader_Support: "Podrška",
	Course_Detail_No_Prerequisites: "Nema preduvjeta",
	TableCell_Hours: "Sati",
	Dashboard_MenuItem_Dashboard: "Nadzorna ploča",
	Dashboard_MenuItem_Curricula: "Nastavni programi",
	Dashboard_MenuItem_Certificates: "Certifikati",
	Dashboard_MenuItem_Team: "Tim",
	Dashboard_MenuItem_Workshops: "Radionice",
	Dashboard_MenuItem_External: "Vanjski",
	Dashboard_MenuItem_Wallet: "Novčanik",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Vaši nadolazeći tečajevi",
	Dashboard_Headline_YourELearningCourses: "Vaši tečajevi e-učenja",
	Dashboard_Headline_TeamOverview: "Pregled tima",
	Dashboard_Headline_YourOverview: "Vaš pregled",
	Dashboard_Headline_YourWishlist: "Vaš popis želja",
	Dashboard_Headline_ActivePrograms: "Aktivni programi",
	Dashboard_Headline_CompletedPrograms: "Završeni programi",
	Dashboard_Headline_YourCertificates: "Vaši certifikati",
	Dashboard_Headline_Transactions: "Transakcije",
	Dashboard_Table_NoRecordsToDisplay: "Nema zapisa za prikaz",
	Dashboard_TableColumn_Location_Online: "Mrežno",
	Dashboard_TableColumn_Duration_Hours: "sati",
	Dashboard_ELearningCourses_TableHeader_Title: "Naziv",
	Dashboard_ELearningCourses_TableHeader_Duration: "Trajanje (h)",
	Dashboard_ELearningCourses_ActionButton_View: "Prikaz",
	Dashboard_UpcomingCourses_TableHeader_Title: "Naziv",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Datum [dani]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Lokacija",
	Dashboard_UpcomingCourses_ActionButton_View: "Prikaz",
	Dashboard_YourOverview_TableHeader_Title: "Naziv",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Datum/trajanje",
	Dashboard_YourOverview_TableHeader_Location: "Lokacija",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Prikaz",
	Dashboard_YourWishlist_TableHeader_Title: "Naziv",
	Dashboard_YourWishlist_TableHeader_Action: "Radnja",
	Dashboard_YourWishlist_ViewAllRecords: "Prikaži sve zapise",
	Dashboard_TeamOverview_TableHeader_Employee: "Zaposlenik",
	Dashboard_TeamOverview_TableHeader_Title: "Naziv",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Datum/trajanje",
	Dashboard_TeamOverview_TableHeader_Location: "Lokacija",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Prikaz",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Pretraži",
	Dashboard_ActiveProgram_TableHeader_Title: "Naziv",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Prikaz",
	Dashboard_CompletedProgram_TableHeader_Title: "Naziv",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Prikaz",
	Dashboard_Certificates_TableHeader_Title: "Naziv",
	Dashboard_Certificates_TableHeader_Date: "Datum",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Radnja",
	Dashboard_Certificates_ActionButton_Download: "Preuzmi",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Broj računa",
	Dashboard_Transactions_TableHeader_Description: "Opis",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Način plaćanja",
	Dashboard_Transactions_TableHeader_Date: "Datum",
	Dashboard_Transactions_TableHeader_Amount: "Iznos",
	Dashboard_Transactions_ActionButton_Download: "Preuzmi",
	Breadcrumb_MyTraining: "MyTraining",
	Breadcrumb_MyTraining_CourseDetails: "Pojedinosti o tečaju",
	Breadcrumb_MyTraining_Curricula: "Nastavni programi",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Pojedinosti o programu obuke",
	Breadcrumb_MyTraining_Team: "Tim",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Prikaži zaposlenika",
	MyTraining_Headerbar_CourseDetails: "Pojedinosti o tečaju",
	MyTraining_Headerbar_TrainingProgramDetails: "Pojedinosti o programu obuke",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Ukupni napredak",
	TrainingProgramDetails_ProgramItems_Headline: "Stavke programa",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Naziv",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Ocjena",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Datum",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Prikaz",
	TrainingProgramDetails_Status_InProgress: "U tijeku",
	TrainingProgramDetails_Status_Completed: "Završeno",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Trajanje",
	CourseDetails_Label_Trainer: "Voditelj obuke",
	CourseDetails_Label_Language: "Jezik",
	CourseDetails_Label_Score: "Rezultat:",
	CourseDetails_Label_StartDate: "Datum početka",
	CourseDetails_Label_EndDate: "Datum završetka",
	CourseDetails_Label_Venue: "Lokacija",
	CourseDetails_Label_Address: "Adresa",
	CourseDetails_Label_Grade: "Ocjena",
	CourseDetails_Action_Withdraw: "Ispiši se",
	CourseDetails_Action_Evaluate: "Procijeni tečaj",
	CourseDetails_Action_Evaluate_Description: "Procijenite ovaj tečaj kako bi se izradio vaš certifikat!",
	CourseDetails_Action_DownloadCertificate: "Preuzmi certifikat",
	CourseDetails_Action_JoinWebcast: "Pristupi prijenosu internetskog medijskog videosadržaja",
	CourseDetails_Action_StartCourse: "Započni tečaj",
	CourseDetails_Action_RestartCourse: "Ponovno započni tečaj",
	CourseDetails_Action_RepeatCourse_Description: "Ponovite ovaj tečaj i položite ga kako biste preuzeli svoj certifikat!",
	CourseDetails_PartSpecificDetails_Headline: "Pojedinosti o specifičnom dijelu",
	CourseDetails_PartSpecificDetails_Tab_Part: "Dio",
	CourseDetails_PartSpecificDetails_Description: "Opis",
	CourseDetails_PartSpecificDetails_StartDate: "Datum početka",
	CourseDetails_PartSpecificDetails_EndDate: "Datum završetka",
	CourseDetails_PartSpecificDetails_StartTime: "Vrijeme početka",
	CourseDetails_PartSpecificDetails_EndTime: "Vrijeme završetka",
	CourseDetails_PartSpecificDetails_Method: "Način",
	Dashboard_Team_TableHeader_Name: "Ime i prezime",
	Dashboard_Team_TableHeader_Role: "Uloga",
	Dashboard_Team_TableHeader_Email: "E-adresa",
	Dashboard_Team_TableHeader_PersonalAccount: "Osobni račun",
	Dashboard_Team_ActionButton_Select: "Odaberi",
	Dashboard_Team_PersonalAccount_Activated: "Aktivirano",
	Dashboard_Team_PersonalAccount_NotActivated: "Nije aktivirano",
	Dashboard_Team_ActionButton_AddMember: "Dodaj člana",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Pretraži",
	Dashboard_External_TableHeader_Name: "Ime i prezime",
	Dashboard_External_ActionButton_AddNew: "Dodaj novi",
	Dashboard_External_Headline: "Odobreni vanjski pristup",
	Dashboard_Workshops_Headline: "Radionice",
	Dashboard_Workshops_TableHeader_Company: "Društvo",
	Dashboard_Workshops_TableHeader_Address: "Adresa",
	Dashboard_Workshops_TableHeader_Postcode: "Pošt. br.",
	Dashboard_Workshops_TableHeader_City: "Grad",
	Dashboard_Workshops_ActionButton_Select: "Odaberi",
	Wallet_ServiceCredits: "Bodovi za uslugu",
	Wallet_Headline_CreditBalance: "Stanje bodova",
	Wallet_Headline_CreditActivity: "Aktivnost bodova",
	Wallet_Headline_BillingTransactions: "Transakcije s izdavanjem računa",
	Wallet_BillingTransaction_TableHeader_Order: "Narudžba",
	Wallet_BillingTransaction_TableHeader_Date: "Datum",
	Wallet_BillingTransaction_TableHeader_Amount: "Iznos",
	Wallet_BillingTransaction_TableHeader_Action: "Radnja",
	Wallet_CreditActivity_TableHeader_Date: "Datum",
	Wallet_CreditActivity_TableHeader_Description: "Opis",
	Wallet_CreditActivity_TableHeader_User: "Korisnik",
	Wallet_CreditActivity_TableHeader_Amount: "Iznos",
	FooterAdditionalLinks_Copyright: "© Robert Bosch Ltd, sva prava pridržana",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Sigurnost proizvoda (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovacije, patenti i licencije",
	FooterAdditionalLinks_Navigation_Logistics: "Nabava i logistika",
	MenuHeader_MyTraining: "Moje obuke",
	MenuHeader_TrainingServices: "Usluge obuke",
	MenuHeader_OurLocations: "Naše lokacije",
	MenuHeader_ContactsUs: "Obratite nam se",
	MenuHeader_Logout: "Odjava",
	MenuHeader_FAQ: "Često postavljana pitanja",
	MyProfile_MyBoschId: "Moj Bosch ID",
	MyProfile_AccountDeletion: "Brisanje korisničkog računa",
	MyProfile_Edit: "Promijeni e-adresu ili uredi lozinku",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Uredi korisnički profil",
	BackButton: "Natrag",
	ContactUs_Email_DataProtection1_kr: "Nije dostupno",
	ContactUs_Email_DataProtection2_kr: "Nije dostupno",
	ContactUs_Email_DataProtection3_kr: "Nije dostupno",
	ContactUs_Email_DataProtection4_kr: "Nije dostupno",
	ContactUs_Email_DataProtection5_kr: "Nije dostupno",
	ContactUs_Email_DataProtection6_kr: "Nije dostupno",
	ContactUs_Email_DataProtection7_kr: "Nije dostupno",
	ContactUs_Email_DataProtection_kr: "Nije dostupno",
	ContactUs_Phone_Description_V3: "Radujemo se vašem pozivu i rado ćemo vam pomoći. Dostupni smo vam 24 sata dnevno, sedam dana tjedno.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Unesite točne znakove, navedene u gornjem okviru.",
	ContactUs_Email_MandatoryNotice_V3: "Ispunite sva obvezna polja.",
	ContactUs_Email_SendButton: "Pošalji",
	Breadcrumb_TrainingCenters: "Centri za obuku",
	Training_Centres_Book_Btn: "Registriraj tečaj",
	Training_centres_booking_code: "Kôd za registraciju",
	Training_centers_header_bar_title: "Naši centri za obuku",
	Training_centers_filter_item: "Centar",
	TeamEdit_PersonalAccountToolTop: "Ako omogućite tu značajku, zaposlenik će moći pristupiti tom mrežnom mjestu. Korisnik će moći izraditi osobni račun u sklopu vašeg korporativnog računa. Nakon što se omogući, zaposleniku možete poslati pozivnicu s aktivacijskim kodom. Pozivnica će se poslati na e-adresu.",
	TeamEdit_Update: "Omogućavanjem značajke administratora društva zaposlenik automatski dobiva potpuni pristup vašem korporativnom računu. Kad se ta značajka omogući, korisnik se, ako je već prijavljen, mora odjaviti i ponovno prijaviti na mrežno mjesto.",
	TeamEdit_PersonalAccount: "Dopusti osobni mrežni račun za Bosch Training Solutions",
	TeamEdit_SendInvitation: "Pošalji pozivnicu",
	TeamEdit_Deactivate: "Deaktiviraj",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Za mobitel se moraju unijeti brojke",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Za telefon se moraju unijeti brojke",
	TeamEdit_Activate: "Aktivno",
	TeamEdit_NotActivated: "Nije aktivirano",
	TeamEdit_StatusPopup: "Status će se prikazivati kao „Aktivno” ako je korisnik uspješno izradio osobni račun. Ako je status postavljen na „Nije aktivno”, to znači da korisnik nije iskoristio vašu pozivnicu.",
	Training_Catalog_Courses: "Tečajevi",
	Training_Catalog_Course: "Tečaj",
	Reset_Filter: "Ponovno postavi filtar",
	TrainingCatalogue_CourseCatalogue: "Katalog tečajeva",
	CheckoutHeader_CartItemCount_Text: "Imate [count] stavku u svojoj košarici",
	CheckoutHeader_CartItemsCount_Text: "Imate [count] stavki u svojoj košarici",
	ShoppingCart_PriceItem: "Cijena artikla",
	ShoppingCartItemParticipants_AddParticipant_Button: "Dodaj sudionike",
	ShoppingCartTotalPrice_SubTotalLabel: "Međuzbroj",
	ShoppingCartTotalPrice_TaxesLabel: "Porezi",
	ShoppingCartTotalPrice_TotalLabel: "Ukupno",
	ShoppingCartTotalPrice_IncludedVATLabel: "Uključuje PDV od [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Dostupni načini mrežnog plaćanja",
	ShoppingCartItemRow_VenueFeeLabel: "Naknada za lokaciju od [fee_amount] po sudioniku",
	ShoppingCartItemRow_OnlineLabel: "Mrežno",
	AddParticipantsModal_Title: "Dodaj sudionika",
	AddParticipantsModal_SelectEmployeeLabel: "Dodaj sudionika",
	AddParticipantsModal_SelectWorkshopLabel: "Odaberi radionicu",
	ShoppingCartTotalPrice_ContinueButtonText: "Nastavi na plaćanje",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Sudionik nije dodan!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "U košarici imate jedan ili više tečajeva bez sudionika.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Dodajte sudionika kako biste nastavili na plaćanje.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Uredi sudionike ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Ime i prezime",
	AddParticipantsModal_ParticipantTable_Email: "E-adresa",
	AddParticipantsModal_ParticipantTable_Action: "Radnja",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Nema sudionika...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "E-adresa nije konfigurirana za odabranog sudionika",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Odabrani sudionik na svojem profilu nema konfiguriranu e-adresu. E-adresa je obvezna za registraciju na tečaj za obuku.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Dodajte e-adresu kako biste nastavili ili odustanite kako biste odabrali drugog sudionika.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Dodaj e-adresu",
	CancelButton: "Odustani",
	AddParticipantsModal_AddEmployeeButtonText: "Dodaj zaposlenika",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Izbrisat ćete tečaj iz svoje košarice",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Pokušavate ukloniti tečaj s dodanim sudionicima iz svoje košarice.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Kliknite \„Nastavi”\ ako želite nastaviti ili kliknite \„Odustani\”.",
	ShoppingCartItemHeader_Participants: "Sudionici",
	ShoppingCartItemHeader_ParticipantPrice: "Cijena po mjestu",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Prikaži u [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Prikaži u bodovima",
	ShoppingCart_CreditsPriceUnit: "Bodovi",
	CheckOutHeader_Step1_Question: "Kako želite platiti?",
	CheckOutHeader_Step2_Question: "Pregledajte narudžbu",
	CheckOutHeader_Step3_Question: "Dovršite narudžbu",
	CheckOutHeader_Step4_Question: "Vaša je rezervacija uspješna!",
	CheckOutHeader_Step_Payment: "Plaćanje",
	CheckOutHeader_Step_Review: "Pregled",
	CheckOutHeader_Step_Place_Order: "Pošalji narudžbu",
	CheckOutRightPanel_Review_Order_Btn: "Pregledaj narudžbu",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Pošalji narudžbu",
	CheckoutRightPanelContent_OrderSummary_Title: "Sažetak narudžbe",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Uredi košaricu",
	ShoppingCart_Participants: "Sudionici",
	CheckOutStepPayment_Payment_Methods_Title: "Odaberite način plaćanja",
	CheckOutStepPayment_Credit_Title: "Kreditna ili debitna kartica",
	CheckOutStepPayment_Wholesaler_Title: "Veletrgovac",
	CheckOutStepPayment_ServiceCredit_Title: "Bodovi za uslugu",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Imate [available_credit] bodova",
	CheckOutStepPayment_BoschAccount_Title: "Korisnički račun za Bosch",
	Checkout_Second_PaymentMethod_Headline: "Način plaćanja",
	CheckOutStepReview_Edit: "Uredi",
	CheckOutStepReview_Terms_Label: "Uvjeti, odredbe i pravila otkazivanja",
	CheckOutStepReview_Terms_Text: "Pročitao sam i shvatio [uvjete, odredbe i pravila otkazivanja] prodaje te se slažem s njima.",
	SelectSecondPaymentMethodModal_Title: "Odaberi sekundarni način plaćanja",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Plati [amount]",
	StepPaymentWholesaler_Title: "Odaberi veletrgovca",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Prihvati Uvjete i odredbe",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Pročitajte i prihvatite naše uvjete, odredbe i pravila otkazivanja prije nego što nastavite.",
	CheckoutDescription_ThankYou: "Hvala vam na narudžbi putem sustava Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Naši bitovi i bajtovi već obrađuju vašu narudžbu. U međuvremenu držite motor u mirovanju.",
	CheckoutDesctiption_Text: "Putem e-pošte poslat ćemo vam konačnu potvrdu koja će sadržavati sve pojedinosti vaše rezervacije.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Uklonit ćete odabranu radionicu",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Ako uklonite odabranu radionicu, svi će se dodani sudionici ponovno postaviti. Ta će promjena utjecati na sve tečajeve u vašoj košarici.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Kliknite \„Nastavi\” ako želite nastaviti ili kliknite \„Odustani\” kako biste zaustavili tu radnju.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Nemate dovoljno bodova. Stanje vaših bodova jest [available_credit] bodova, a za nastavak na plaćanje potrebno vam je [cart_total_credits] bodova. Odaberite drugi način plaćanja ili uklonite stavke iz svoje košarice.",
	Training_centers_filter_items: "Centri",
	Training_Programs_Filter_Item: "Programi",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "Godina",
	Training_Program_Detail_Title: "Programi tečajeva za obuku",
	AccountButton_Workshops_MenuItem: "Radionice",
	AccountButton_Wallet_MenuItem: "Novčanik",
	Header_MyBoschProfile_Header: "Profil za MyBosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Dostupno",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Dodaj/uredi sudionika",
	ShoppingCart_CreditPriceUnit: "Bodovi",
	Common_IncludedVAT: "Uklj. PDV",
	TrainingCatalogue_Length: "Trajanje",
	TrainingCatalogue_Method: "Način",
	Course_Price_Credits: "Bodovi",
	Catalogue_Grid_Register_Button: "Registracija",
	Catalogue_Credit_Tilte: "Kliknite ovdje za prikaz cijena u bodovima",
	Catalogue_Price_Tilte: "Kliknite ovdje za prikaz cijena u eurima",
	Catalogue_Grid_Vat: "Bez PDV-a",
	EventCalendar_Seats: "Broj mjesta",
	MenuHeader_Calendar: "Kalendar",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Minute",
	ListFilter_NoResult: "Nema rezultata",
	Course_Description: "Opis tečaja",
	Training_course_detail: "Social Media Basics​",
	Training_course_detail_add_waiting: "Dodaj na opću listu čekanja",
	Register_now_btn: "Prijavite se odmah!",
	Register_now_btn_add_event_to_cart: "Prijavite se odmah!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Prikaži sve zapise",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Prikaži manje",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Prikaži sve zapise",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Prikaži manje",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Prikaži sve zapise",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Prikaži manje",
	TeamCreate_TitleError: "Potreban je naziv",
	Dashboard_Headerbar_BookCourse: "Rezerviraj tečaj",
	Dashboard_Headerbar_ExitProxy: "Izađi iz posredničke uloge",
	Dashboard_Headerbar_EvaluateCourse: "Procijeni tečaj",
	Dashboard_Headerbar_EvaluateCourse_Note: "Procijenite ovaj tečaj kako bi se izradio vaš certifikat!",
	Dashboard_Headerbar_DownloadCertificate: "Preuzmi certifikat",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Ispiši se",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Ispiši se",
	Dashboard_Headerbar_WelcomeMessage: "Dobrodošli na svoju nadzornu ploču za učenje",
	Dashboard_Headerbar_ProxingAs: "U posredničkoj ulozi",
	Dashboard_TrainingProgram_Status_Completed: "Završeno",
	Dashboard_TrainingProgram_Status_InProgress: "U tijeku",
	External_SelectWholesalerModal_Title: "Dodaj vanjski korisnički pristup",
	External_SelectWholesalerModal_Company_Label: "Društvo",
	External_SelectWholesalerModal_Outlet_Label: "Trgovina",
	External_SelectWholesalerModal_Company_Placeholder: "Odaberite",
	External_SelectWholesalerModal_Outlet_Placeholder: "Odaberite",
	External_SelectWholesalerModal_ApplyButton: "Primijeni",
	External_SelectWholesalerModal_CancelButton: "Odustani",
	External_RemoveWholesalerModal_ConfirmLabel: "Potvrda",
	External_SelectWholesalerModal_ConfirmMessage: "Jeste li sigurni da želite ukloniti ovog veletrgovca?",
	External_SelectWholesalerModal_YesButton: "Da",
	External_SelectWholesalerModal_NoButton: "Ne",
	Training_program_team_member: "Član tima",
	Training_program_assign_training_program: "Dodijeli program obuke",
	Assign_modal_student_confirmation_text1: "Dodijelit ćete [Training Program Name] u svoje obrazovno putovanje.",
	Assign_modal_student_confirmation_text2: "Želite li nastaviti?",
	MyFullCalendar_Jan: "Siječanj",
	MyFullCalendar_Feb: "Veljača",
	MyFullCalendar_Mar: "Ožujak",
	MyFullCalendar_Apr: "Travanj",
	MyFullCalendar_May: "Svibanj",
	MyFullCalendar_Jun: "Lipanj",
	MyFullCalendar_Jul: "Srpanj",
	MyFullCalendar_Aug: "Kolovoz",
	MyFullCalendar_Sep: "Rujan",
	MyFullCalendar_Oct: "Listopad",
	MyFullCalendar_Nov: "Studeni",
	MyFullCalendar_Dec: "Prosinac",
	MenuHeader_CourseCatalog: "Katalog tečajeva",
	MenuHeader_Event: "Kalendar događaja",
	MenuHeader_Program: "Program obuke",
	MenuHeader_Services: "Usluge obuke",
	MenuHeader_Log_out: "Odjava",
	Filter_Location: "Lokacija",
	TeamEdit_UpgradeToCompanyAdministrator: "Unaprijedi u administratora društva",
	TeamEdit_PersonalWebAccountStatusIs: "Status osobnog mrežnog računa jest",
	Header_CreateNewEmployee: "Izradi novog zaposlenika",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Nema slobodnih mjesta!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Nema slobodnih mjesta na odabranom događaju.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Odaberite drugi događaj ili uklonite već dodanog sudionika kako biste nastavili.",
	Common_SuccessModal_Title: "Čestitamo!",
	TrainingProgramDetails_Merit_StatusText: "Zasluga",
	TrainingProgramDetails_Resit_StatusText: "Ponovno polaganje",
	EmployeeProfile_UserExistErrorMessage: "Korisnički račun već postoji!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Plaćanje nije uspjelo. Pokušajte ponovno ili upotrijebite drugi način plaćanja.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Uspješno ste deaktivirali odabranog zaposlenika!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Spremljeno!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Vaše su izmjene spremljene.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Ispis nije moguć u tečajevima e-učenja. Obratite nam se ako su vam potrebne daljnje pojedinosti.",
	Dashboard_Team_PersonalAccount_Pending: "Na čekanju",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Zaposlenik izrađen!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Vaša izrada zaposlenika uspješno je dovršena.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Ta vam postavka omogućuje da ograničite zaposlenikovu registraciju za događaje obuke. Samo će administratori društva moći registrirati zaposlenika za događaje obuke ako se ova postavka odznači.",
	Training_catalog_sort_by: "Razvrstaj po",
	CourseDetail_EvaluationRestriction_Title: "Nije moguće procijeniti obuku!",
	CourseDetail_EvaluationRestriction_Message: "Procjene obuka može predati samo polaznik. \nNaložite polazniku da se prijavi u sustav Bosch Automotive Training Solutions i dovrši procjenu obuke.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Ako polaznik još nema korisnički račun, pozivnica se može podijeliti u postavkama korisničkog profila člana tima.",
	Breadcrumb_Download_Page: "preuzimanje",
	Download_Table_Category_Cell: "Kategorija",
	Download_Table_Title_Cell: "Titula",
	Download_File_Filter_Item: "File",
	Download_File_Filter_Items: "Datoteke",
	Download_Header: "Područje preuzimanja",
	DownloadArea_Button_Download: "preuzimanje",
	Popup_Title_Error: "Greška",
	Popup_Title_Success: "Uspjeh",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Upozorenje!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Sljedeće potencijalne prijave za događaje koje već postoje neće biti otkazane i morat će se otkazati pojedinačno.",
	TrainingProgram_PDF_Export: "PDF izvoz",
	TrainingProgram_PDF_Export_Participant: "sudionik:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Sveukupni napredak:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Naslov obuke",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Razred",
	TrainingProgram_PDF_Export_TableHeader_Date: "Datum",
	AccountButton_CompanyChange_MenuItem: "Promjena tvrtke",
	SelectCompanyModal_Title: "Odaberite Tvrtka",
	CloseButton: "Zatvoriti",
	Change_Button: "Promijeniti",
	ChangeManagerModal_Content: "Odaberite Korisnik",
	ChangeCompanyModal_HeadOffice: "(Sjedište)",
	SelectCompanyModal_Content: "Odaberite tvrtku za prijavu…",
	AlertSuccess_Title: "Uspjeh",
	CheckOutStepPayment_SecondaryPayment_Title: "Odaberite sekundarni način plaćanja",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Dodaj sekundarni način plaćanja",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Dodajte sekundarni način plaćanja u nastavku ili odaberite drugi način plaćanja iznad.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Nemate dovoljno kredita za uslugu na svom računu za plaćanje stavki u košarici. Za nastavak korištenja odabranog načina plaćanja potrebno je doplatiti razliku. Razlika je [iznos_razlike] (bez PDV-a).",
	ShoppingCartTotalPrice_TotalInCredit: "Ukupna cijena u kreditima",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Vaši dostupni krediti",
	ShoppingCartTotalPrice_YourDifference: "Razlika",
	ShoppingCartTotalPrice_SubtotalExTax: "Međuzbroj bez Porez",
	ShoppingCartTotalPrice_SubtotalInTax: "Međuzbroj uklj. porez",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Sekundarni način plaćanja",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Uredi način plaćanja",
	Checkout_Second_PaymentMethod: "Drugi način plaćanja",
	Breadcrumb_TrainingCourseDetails: "Detalji tečaja obuke",
	DownloadArea_FailedToDownload_ErrorMessage: "Nije uspjelo preuzimanje dokumenta. Molimo pokušajte ponovo ili kontaktirajte svog administratora tvrtke za pomoć.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Datoteka za preuzimanje nije dostupna. Molimo pokušajte ponovo ili kontaktirajte svog administratora tvrtke za pomoć.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Preuzimanje datoteke je zabranjeno. Molimo pokušajte ponovo ili kontaktirajte svog administratora tvrtke za pomoć.",
	SSO_Redirecting_Message: "Preusmjeravate na Bosch portal za obuku...",
	SSO_Redirecting_ParameterErrorMessage: "Država i/ili jezik nisu navedeni. Molimo pokušajte ponovo ili kontaktirajte svog administratora tvrtke za pomoć.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Zahtjevi za upis nisu prihvaćeni.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "To može biti zbog toga što ste unutar dana praga za registraciju tečaja za jedan ili više tečajeva. Koristite područje 'Kontaktirajte nas' ako imate pitanja ili zahtjeva.",
	TimeSlotPicker_DateFormat_Title: "Format datuma",
	UpdateAccountSettingsConfirmationModal_Title: "Spremate se promijeniti format datuma!",
	UpdateAccountSettingsConfirmationModal_Message1: "Format datuma bit će prikazan prema vašem odabiru i može se promijeniti u bilo kojem trenutku.",
	UpdateAccountSettingsConfirmationModal_Message2: "Želite li nastaviti?",
	ProfileSummary_Settings_Title: "postavke",
	UpdateAccountSettings_DateFormat_Title: "Postavke formata datuma",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Nije uspjelo ažuriranje formata datuma.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Postavka formata datuma uspješno je ažurirana.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Preuzmi certifikat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Certifikat o programu obuke",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Preuzimanje nije uspjelo. Molimo pokušajte ponovo. Ako problem potraje, obratite nam se putem kontakt obrasca.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Prag povlačenja premašen",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Prag za povlačenje s ovog tečaja je premašen i zahtijeva ručnu obradu od strane naše administracije za obuku.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Konačna potvrda bit će poslana e-poštom.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Više pojedinosti potražite u našim [pravilima otkazivanja].",
	TrainingCourse_Course_NotAvailable_Title: "Tečaj obuke nije dostupan",
	TrainingCourse_Course_NotAvailable_Message: "Tečaj obuke nije dostupan. Molimo pokušajte ponovno ili kontaktirajte svog administratora tvrtke za dodatnu pomoć.",
	TrainingCourse_NotAvailable_Message_1: "Pokušavate pogledati tečaj obuke koji je nedostupan.",
	TrainingCourse_NotAvailable_Message_2: "Vratite se na naš [katalog obuke] kako biste pronašli sve dostupne tečajeve.",
	LanguageSelection_SelectALanguage: "Odaberite jezik",
	LanguageSelection_SelectYourCountry: "Odaberite svoju zemlju",
	Checkout_PaymentMethod_Headline: "Način plaćanja",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalendar",
	Download_Categories_General: "General",
	Download_Categories_Information: "Informacija",
	Download_Categories_Userguides: "Korisnički vodiči",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "pravila otkazivanja",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Odustani",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Nastavi",
	Duration_Seconds: "sekundi",
	Duration_Day: "Dan",
	Duration_Hour: "Sat",
	Duration_Minute: "Minuta",
	Duration_Second: "Drugi",
	EventCalendar_ExternalLink_Tooltips: "Kupite putem [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Otkazati",
	EventCalendar_ExternalLink_Continue: "Nastaviti",
	EventCalendar_ExternalLinkModal_Title: "Bit ćete preusmjereni...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Odabrani događaj prodaje se putem [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Nastavak će vas preusmjeriti na vanjsku web stranicu.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Želite li nastaviti?",
	Team_ContactPerson_FirstName_Tooltips: "Imena učenika može mijenjati samo Bosch administrativni tim za obuku. Kontaktirajte nas putem obrasca za kontakt na ovoj web stranici za daljnju podršku.",
	Team_ContactPerson_LastName_Tooltips: "Imena učenika može mijenjati samo Bosch administrativni tim za obuku. Kontaktirajte nas putem obrasca za kontakt na ovoj web stranici za daljnju podršku.",
	Withdraw_ExternalLink_Cancel: "Otkazati",
	Withdraw_ExternalLink_Confirm: "Potvrdi",
	Withdraw_ExternalLinkModal_Title: "Bit ćete preusmjereni",
	Withdraw_ExternalLinkModal_Message: "Isplatama za ovaj tečaj upravlja vanjski pružatelj.",
	Withdraw_ExternalLinkModal_Confirmation: "Klikom na \"Potvrdi\" otvorit će se nova kartica i preusmjeriti vas na vanjsku web stranicu.",
	Button_AddToWaitingList_Tooltips: "Dodaj na listu čekanja",
	Button_AddToCart_Tooltips: "Dodaj u košaricu",
	Button_TrainingCourseDetail_Tooltips: "Prikaži pojedinosti",
	Button_TrainingProgramDetail_Tooltips: "Prikaži pojedinosti",
	Button_AssignTrainingProgram_Tooltips: "Dodijelite program obuke",
	Button_DeleteFromCart_Tooltips: "Izbriši iz košarice",
	Button_RemoveParticipant_Tooltips: "Ukloni sudionika",
	Button_DownloadBillingTransaction_Tooltips: "preuzimanje",
	Button_RemoveExternal_Tooltips: "Ukloniti",
	Breadcrumb_TrainingServices: "Usluge obuke",
	Breadcrumb_Support: "podrška",
	Breadcrumb_FAQs: "FAQ",
	Breadcrumb_TrainingProgramDetail: "Detalj programa obuke",
	Breadcrumb_MyTraining_MyTrainingEvents: "Moji treninzi",
	Breadcrumb_MyTraining_External: "Vanjski",
	Breadcrumb_MyTraining_Wallet: "Novčanik",
	Breadcrumb_MyTraining_Workshop: "Radionica",
	Breadcrumb_MyTraining_Workshop_Team: "Momčad",
	Breadcrumb_MyTraining_Team_Profile: "Profil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Uredi profil",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Stvaranje zaposlenika",
	SideNavigation_Title: "Rješenja za obuku",
	SideNavigation_AccountButton_RegisterOrLogin: "Registracija / Prijava",
	SideNavigation_Language: "Jezik",
	SideNavigation_SelectLanguage: "Odaberite jezik",
	SideNavigation_AccountButton_Logout: "Odjava",
	SideNavigation_ConntactUs: "Kontaktirajte nas",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Informacije o tvrtki",
	FooterAdditionalLinks_Navigation_LegalNotice: "Pravna obavijest",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Obavijest o zaštiti podataka",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Postavke privatnosti",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Odredbe i uvjeti",
	Common_Version_Text: "Verzija: {{version}}",
	Content_Header_Dashboard_MyTrainingEvents: "Moji treninzi",
	Content_Header_Dashboard_MyTrainingPrograms: "Moji programi obuke",
	Content_Header_Dashboard_Team: "Momčad",
	Content_Header_Dashboard_Workshops: "Radionice",
	Content_Header_Dashboard_External: "Vanjski",
	Content_Header_Dashboard_Wallet: "Novčanik",
	Content_Header_Dashboard_MyProfile: "Moj profil",
	RedeemVoucher_RemoveButton: "Ukloniti",
	RedeemVoucher_ApplyButton: "primijeniti",
	Checkout_VoucherApplied_Label: "Vaš kod",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Nevažeći kod vaučera",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Kod vaučera koji je primijenjen je nevažeći.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Pokušajte ponovo ili nastavite bez koda vaučera.",
	RedeemVoucherModal_CancelButton: "Otkazati",
	RedeemVoucherModal_TryAgainButton: "Pokušajte ponovno",
	CreditsConfirmationModal_ContinueButton: "Nastaviti",
	CreditsConfirmationModal_CancelButton: "Otkazati",
	CreditsConfirmationModal_Title: "Vaučer je primijenjen",
	CreditsConfirmationModal_Message_1: "Plaćanje kreditima za usluge nije moguće ako je vaučer s popustom primijenjen na vašu košaricu.",
	CreditsConfirmationModal_Message_2: "Kliknite \"Odustani\" za povratak ili kliknite \"Nastavi\" za uklanjanje vaučera iz košarice."
};