export const TRANSLATIONS_KR = {
	LandingPage_Title: "Bosch Automotive Training Solutions에 오신 것을 환영합니다",
	LandingPage_SubTitle_ChooseCountry: "국가 / 지역을 선택하십시오",
	Header_ContactUs: "문의하기",
	Header_Search: "검색",
	Header_Menu: "메뉴",
	Header_LogInOrCreateYourAccount: "로그인하거나 계정을 만드십시오",
	Header_LogIn: "로그인",
	Header_Cart: "카트",
	Header_ShoppingCart: "장바구니",
	Header_LogOut: "로그 아웃하십시오",
	Header_ChangeDetails: "세부 사항을 변경하십시오",
	Footer_ExploreOurTraining: "교육 살펴보기",
	Footer_TrainingAtYourFingertips: "손끝에서 교육",
	Footer_DontMissAnyStory: "어떤 이야기도 놓치지 마십시오",
	Footer_ExploreOurStories: "스토리 살펴보기",
	Footer_GetInTouchWithUs: "유선문의",
	Footer_SendUsAMessage: "문의하기",
	Footer_ToContactForm: "연락 양식",
	Footer_InventedForLife: "생활 속의 기술",
	Footer_GeneralContactInfo: "일반 연락처 정보",
	Footer_ProductSecurity_Psirt: "제품 보안 (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "혁신 특허 및 라이센스",
	Footer_PurchasingLogistics: "구매 및 물류",
	Footer_CorporateInformation: "회사 정보",
	Footer_LegalNotice: "법적 고지",
	Footer_DataProtectionNotice: "데이터 보호 통지",
	Footer_PrivacySettings: "개인 정보 설정",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch 판권 소유",
	Footer_StillLookingForSomething: "아직도 무언가를 찾고 있습니다",
	Footer_TrainingServices: "교육 서비스",
	Footer_Courses: "강좌",
	Footer_Programs: "프로그램들",
	Footer_EventsCalendar: "이벤트 일정",
	Footer_TrainingCenters: "교육 센터",
	Footer_Dashboard: "대시 보드",
	Footer_MyDashboard: "나의 대시 보드",
	Footer_ShoppingCart: "쇼핑 카트",
	Footer_YourShoppingCart: "장바구니",
	Footer_Administration: "관리",
	Footer_Employees: "직원",
	Footer_Wholesalers: "도매업체",
	Footer_Transactions: "거래",
	Footer_Workshops: "워크샵",
	Footer_WorkLikeABoschApplyNow: "작업 -지금 신청하십시오.",
	Footer_BoschGlobalCareers: "보쉬 글로벌 커리어",
	Footer_PurchasingAndLogistics: "구매 및 물류",
	Footer_BecomeABusinessPartner: "비즈니스 파트너가 되십시오.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "귀하의 비즈니스에 도움이되는 교육이 어떻게 발전하는 방식",
	Footer_Language: "국가 선택",
	MenuPopup_MyCart: "내 장바구니",
	Stories_OurTrainingStories: "우리의 교육 이야기",
	Stories_ConnectingExperts: "전문가와 애호가, 몽상가와 행동가, 혁신가와 영향력 있는 사람의 연결하는 -탐색과 경험",
	Stories_TopStories: "주요 기사",
	Stories_AtHome: "홈페이지에서",
	Stories_Careers: "채용 정보",
	Stories_History: "연혁",
	Stories_IndustryAndTrades: "산업과 무역",
	Stories_Mobility: "유동성",
	Stories_Research: "연구",
	Stories_Sustainability: "지속 가능성",
	Stories_AllStories: "모든 이야기",
	Stories_BlogATrainersDay: "블로그 | 트레이너의 날",
	Stories_ADayinLifeOfATrainer: "트레이너의 삶에서 하루",
	Stories_BoschServiceTraining: "보쉬 서비스 교육",
	Stories_StoryWhatDrivesYouDrivesUs: "이야기 | 당신이 우리를 이끄는 원동력",
	Stories_StoryHowCanWeBenefitYourBusiness: "이야기 | 우리가 당신의 사업에 도움을 주는 방법",
	Stories_HowOurTrainingEvolves: "우리의 교육이 발전하는 방법",
	Stories_AModernTrainingApproach: "온라인 교육 세계",
	Stories_StoryAVirtualOnlineTrainingWorld: "이야기 | 당신을 위한 온라인 교육 기회",
	Stories_SubscribeToRSSFeed: "RSS 피드 구독하기",
	Title_Main: "보쉬 자동차 교육 솔루션",
	Homepage_AboutUs: "소개",
	Homepage_AutomotiveAftermarket: "자동차 애프터마켓 홈페이지",
	Homepage_GetInTouch: "문의하기",
	Homepage_LatestNews: "우리가 지식을 갈망하는 이유 알아보기",
	Homepage_MainTitle: "보쉬 자동차 교육 솔루션",
	Homepage_OurOpenPositions: "채용 관련",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "당신이 우리를 이끄는 원동력",
	BoschServiceTraining_BoschServiceTraining: "보쉬 서비스 교육",
	BoschServiceTraining_ShareThisOn: "공유하기",
	BoschServiceTraining_TheChallenge: "과제 : 새로운 기술을 습득하기",
	BoschServiceTraining_WorldwidePresence: "세계적인 존재",
	BoschServiceTraining_TechnicalTraining: "Bosch의 기술 교육 과정",
	BoschServiceTraining_TheOffersAndOpportunities: "Bosch가 제공하는 제안과 기회",
	BoschServiceTraining_TechnicalCoursesDesc: "초보자에서 전문가까지 모든 대상 그룹에 적합한 교육 과정이 있습니다. 제안과정에는 1일 교육 과정과 며칠에 걸친 교육 과정이 포함됩니다. 교육 과정은 다양한 기술 분야 및 교육 시리즈(예: 가솔린 분사 디젤 분사 전기 시스템)에서 제공됩니다. 기술 교육 과정에서 Bosch는 모든 표준 차량 시스템에 대한 광범위한 노하우를 제공합니다. 다른 제조업체의 Bosch 시스템도 교육 과정에서 논의됩니다. 내용은 특히 작업장 직원의 요구 사항과 일치합니다.",
	BoschServiceTraining_TrainedEmployeesDesc: "숙련된 직원은 자동차 워크샵에 명백한 이점입니다. 오늘날의 차량은 점점 더 복잡해지고 새로운 기술은 끊임없이 새로운 과제를 제시합니다. 작업장 직원은 필요한 전문 지식을 통해서만 현재 차량 모델에 대한 진단 유지 보수 및 수리 작업을 효율적이고 비용 효율적으로 수행 할 수 있습니다.",
	BoschServiceTraining_RegardlessOfHow: "아무리 훌륭하고 경험이 풍부하고 자동차 전문가도,  언제나 새로운 차량 기술 시스템 또는 알려지지 않은 결함과 접촉하는 상황이 있습니다. 이와 관련하여 문제 해결은 특히 시간이 많이 걸리거나 성공적인 결과를 얻지 못할 수 있습니다. 최악의 경우 고객은 예상보다 훨씬 오랜시간을 차량없이 지내야 합니다.",
	BoschServiceTraining_ItIsPrecisely: "바로 이 시점에 Bosch는 서비스 품질을 개선하고 프로세스 속도를 높이는 서비스 교육을 제공하니다. Bosch의 현재 교육은 문제를 파악하고, 동시에 더 많은 차량을 보다 빠르게 수리하고 고객의 대기 시간을 단축 할 수 있도록  지원합니다. 따라서, Bosch에서 배우는 체계적인 절차는 더 빠르고 생산적인 작업을 보장합니다.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Bosch는 125 년 이상 전 세계 많은 주요 자동차 제조업체에 독창적인 장비 부품 구성 요소 및 시스템을 공급해 왔습니다. 이 경험은 Bosch 지원체계의 기초가 되는 최신 기술에 대한 고유한 지식과 함께 제공됩니다.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Bosch Service Training Centers는 스페인에서 터키, 남아프리카, 호주에서 브라질에 이르기까지 전 세계 어디에서나 찾을 수 있습니다-Bosch는 직원들에게 항상 최신 기술과 일치하는 실습 중심의 교육을 제공합니다.",
	BoschServiceTraining_AgileCompany: "민첩한 회사",
	BoschServiceTraining_Collaboration: "협력",
	BoschServiceTraining_ConnectedMobility: "컨넥티드 모비러티",
	BoschServiceTraining_ArtificialIntelligence: "인공 지능",
	BoschServiceTraining_ThoughtLeadersInDialogue: "대화의 지도자들에 대한 생각",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "진단 및 테스트 장비",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "자동차 기술 기초",
	BoschServiceTraining_Icon_Gasoline: "가솔린",
	BoschServiceTraining_Icon_Diesel: "디젤",
	BoschServiceTraining_Icon_AlternativeDrives: "대체 드라이브",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "전기 및 전자",
	BoschServiceTraining_Icon_BodyAndComfort: "몸과 편안함",
	BoschServiceTraining_Icon_ChassisAndBraking: "섀시 및 제동",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "변속기 및 구동계",
	BoschServiceTraining_Icon_CommercialVehicles: "상업용 차량",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "온라인 교육 과정",
	BoschServiceTraining_Icon_BusinessSkills: "비즈니스 기술",
	BoschServiceTraining_Icon_Accreditations: "인증",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "교육 과정 프로그램",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerFirstNameLastName",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "트레이너의 삶에서 하루",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "하루하루가 모험입니다",
	ADayInTheLifeOfATrainer_Summary: "요약",
	ADayInTheLifeOfATrainer_TheDayJourney: "오늘의 여행",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "매일 바쁜 하루이지만 항상 보람있는 경험입니다. 학생들이 미래의 열쇠를 가지고 있다는 것을 알고 훈련하는 즐거움에 책임감도 있지만, 동시에 흥미진진합니다. 매일 그리고 매주 각 수업으로 성장하는 학생들을 보는 것은 미래를 이끄는 최신기술을 통합하면서 지식을 향상시키려는 공동의 목표를 향해 노력하고 있음을 증명합니다!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "하루의 일과가 끝나면 우리는 앉아서 하루의 일들을 반성합니다. 시간은 너무 빨리 지나 갔지만, 이것은 우리가 성취하는 것이 학생들뿐만 아니라 트레이너에게도 보람이 있다는 증거 자체입니다.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "우리는 훌륭하게 일을 했다고 만족하면서 하루가 끝나면 집에 갈 수 있습니다!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "출발",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "교육 자료의 준비 및 최종 확인. 중요한 메일 확인",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "훈련 시작",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "참가자를 환영합니다. 일일 일정 발표. 각 개인의 역학을 이해함으로써 그룹을 알아가십시오.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "이론",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "교육을 가능한 한 흥미롭게 하려면 그룹과 어느 정도의 상호 작용이 필요합니다. 그룹을 더 많이 참여시키기 위해 이론과 얽힌 작업이 있는지 확인합니다.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "점심 시간",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "현실적인",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "교육은 몇 가지 실용적인 작업과 함께 워크샵에서 계속됩니다. 트레이너로서 어떤 상황과 안내에도 항상 존재하고 이용할 수 있는 것이 요구 사항입니다.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "요약",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "하루의 훈련에 대해 논의하기 위해 잠시 교실로 돌아갑니다. 발생할 수 있는 모든 질문에 답하십시오. 다음 교육 세션을 위한 준비를 시작합니다.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "마지막 날",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "참가자들에게는 출석 증명서가 제공됩니다.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "집,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "귀하의 비즈니스에 도움이되는 방법",
	BoschTrainingBenefits_HowOurTrainingEvolves: "우리의 교육 발전 방향",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "우리는 최신 교육을 제공하기 위해 끊임없이 발전하고 있습니다",
	BoschTrainingBenefits_TheBoschServiceTraining: "Bosch Service Training Center는 광범위하고 관련성이 높은 기술 / 비즈니스 교육 포트폴리오와 인증된 자격을 갖춘 추가 교육 과정을 통해 비즈니스 개발을 지원합니다. 다음 페이지는 현재의 추가 교육 기회 뿐만 아니라 교육 프로그램에 대한 개요를 제공합니다. 교육 과정 및 세미나에서 귀하와 귀하의 직원을 만나기를 기대합니다.",
	BoschTrainingBenefits_LongStandingExperience: "모든 시스템과 자동차 기술 분야에 대한 오랜 경험과 광범위한 노하우.",
	BoschTrainingBenefits_QuickAndEfficient: "많은 문제에 대한 빠르고 효율적인 솔루션.",
	BoschTrainingBenefits_TheAdvantagesandBenefits: "장점과 이점",
	BoschTrainingBenefits_LMS: "학습 관리 시스템-Bosch Training 솔루션",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch 교육 솔루션은 자동차 분야의 전체 범위를 다룹니다. Bosch의 교육 제안을 통해 특정 영역을 전문화하거나 역량을 넓힐 수 있습니다.",
	BoschTrainingBenefits_AcquiredKnowledge: "획득한 지식은 유지보수 및 서비스 작업을 용이하게 합니다.",
	BoschTrainingBenefits_TheHighDegree: "실용성이 높은 컨텐츠와 소그룹 학습방식은 목표지향적 학습을 보장합니다",
	BoschTrainingBenefits_ParticipationInTraining: "교육에 참여하면 직원 동기 부여와 만족도가 높아지고, 추가적인 기술 자격을 통해 고객의 신뢰를 얻을 수 있습니다.",
	BoschTrainingBenefits_IncreasedSales: "교차 시스템 지식을 통한 판매 증가",
	BoschTrainingBenefits_MethodicalProcedures: "체계적인 절차는 고객의 대기 시간을 줄입니다",
	BoschTrainingBenefits_HighlyQualified: "자격을 갖춘 강사는 체계적인 절차를 전달합니다",
	BoschTrainingBenefits_IdentificationOftheTraining: "귀하 또는 귀하의 비즈니스에 적합한 교육 솔루션의 확인",
	BoschTrainingBenefits_DirectOnlineBooking: "본인 또는 직원을 위한 직접 온라인 예약 및 코스 관리",
	BoschTrainingBenefits_OnlineAccess: "학습 문서에 대한 온라인 액세스",
	BoschTrainingBenefits_AccessToYourOwn: "자신의 개인 교육 기록 및 인증서에 액세스",
	BoschTrainingBenefits_ToolsForManagement: "자신의 경력 개발 관리 도구",
	BoschTrainingBenefits_ManagerHasFull: "관리자는 시스템 내부의 귀사 직원 교육 과정에 대한 전체 개요를 제공합니다.",
	BoschTrainingBenefits_Access24hours: "주 7 일, 24시간 상시 접속",
	ModernTrainingApproach_KnowledgeIsKey: "지식은 모든 워크샵의 핵심 성공 요소입니다. 이것이 Bosch가 포괄적 인 전문 교육 개발 프로그램을 제공하는 이유입니다. 이를 통해 학생들은 고객의 차량을 전문적으로 유지보수하고, 수리하는데 필요한 지식을 적시에 습득 할 수 있습니다. Bosch는 이미 제공된 e- 러닝 패키지의 새로운 모듈 부분으로 웹 캐스팅을 추가했습니다. 스트리밍을 위해 특별히 설계된 서비스 교육 세션으로 구성됩니다.",
	ModernTrainingApproach_ANewLearning: "새로운 학습 기회",
	ModernTrainingApproach_YourOnlineTraining: "온라인 교육 기회",
	ModernTrainingApproach_RecentlyTheWorld: "최근 세상은 게속 변화해 왔습니다. 우리가 여러분을 돕기 위해 무엇을 하고 있는지 보세요.",
	ModernTrainingApproach_TheOnlineTrainingWorld: "온라인 교육 세계",
	ModernTrainingApproach_NewTeachingMethod: "새로운 교수법",
	ModernTrainingApproach_InAdditionTo: "기존의 대면 교육 외에도 교육 프로그램에 라이브 웹 캐스트 교육이 포함되었습니다. 트레이너는 가상 강의실에서 광범위한 이론적 지식 또는 실제 사례들을 전달합니다. 평소와 같이 차량 시스템 및 해당 Bosch 제품에 대한 많은 정보를 받게됩니다. 물론 질문 할 기회도 있습니다. 이 질문들은 라이브 웹 캐스트에서 직접 답변될 것입니다. 라이브 웹 캐스트는 60 분에서 120 분 사이입니다. 안정적인 인터넷 연결이 필요합니다. 검색 필드에 \ Webcast \를 입력하여 교육 카탈로그에서 직접 적절한 웹 캐스트를 검색하십시오.",
	ModernTrainingApproach_BoschWebcastingHeader: "보쉬 웹 캐스팅 : 혁신적이고 실용적입니다",
	ModernTrainingApproach_BoschWebcastingText: "Bosch 웹 캐스트에 먼저 참여하려면 참가자가 전화를 걸어야 합니다. 로그인을 가능하게 하기 위해 개별 액세스 코드가 세션 전에 전송됩니다. 최대 200 명의 참가자가 여러 위치에서 동시에 참여할 수 있습니다. 교육은 트레이너가 이끌며, 실시간 채팅 기능을 통해 언제든지 질문을 제출할 수 있습니다.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "강의실 교육에 대한 디지털 보충 자료",
	ModernTrainingApproach_TheDigitalSupplementText: "웹 캐스팅과 강의실 교육은 서로를 완벽하게 보완합니다. 두 경우 모두 자격을 갖춘 트레이너가 교육을 이끌고 있습니다. 웹 캐스트는 차량 작업 없이 디지털 방식으로 전송할 수있는 주제에 적합합니다. 예를 들어 새로운 운전자 보조 시스템의 특정 ESI [tronic] 기능에 대한 학습이 있습니다. 작업장에서 효율적인 팀워크 또는 차량 기술에 대한 실제 작업에 있어서는 대면 교육이 여전히 첫 번째 선택입니다.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "스트리밍이 더 저렴할 수 있습니다",
	ModernTrainingApproach_StreamingCanBeCheaperText: "참가자 당 비용은 일반적으로 비슷한 강의실 교육 과정보다 웹 캐스팅 비용이 저렴합니다. 그 이유는 더 많은 참가자가 혜택을 볼 수 있고 교육과 관련된 노력이 상당히 낮기 때문입니다 (장소 이동이 없고 시간이 덜 소요됨).",
	ModernTrainingApproach_BookAndProfitHeading: "책과 이익",
	ModernTrainingApproach_BookAndProfitText: "웹 캐스트는 다른 교육 과정 및 학습 제안과 마찬가지로 웹 사이트에서 예약 할 수 있습니다. 각 웹 캐스트에 대해 개별 요금을 지불하거나 특정 수의 웹 캐스트에 대해 연간 구독을 선택할 수 있습니다.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "보쉬 웹 캐스팅과 교실 교육의 비교",
	ModernTrainingApproach_Webcasting: "웹 캐스팅",
	ModernTrainingApproach_Classroom: "강의실",
	ModernTrainingApproach_WebcastingP1: "전 세계 어디서나 최대 200 명의 참가자가 동시에 참여할 수 있습니다",
	ModernTrainingApproach_WebcastingP2: "복잡성이 낮고 상대적으로 저렴한 비용의 장점과 함께, 여행할 필요가 없습니다",
	ModernTrainingApproach_WebcastingP3: "구체적이고 간결한 개별 주제들에 대해 이상적입니다",
	ModernTrainingApproach_WebcastingP4: "워크샵은 언제 어디서나 직원을 위해 웹 캐스트를 실행할 수 있습니다",
	ModernTrainingApproach_WebcastingP5: "강의실 현장 교육보다 50-70 % 적은 시간이 필요합니다",
	ModernTrainingApproach_ClassroomTrainingP1: "동시에 최대 16 명이 참가합니다.",
	ModernTrainingApproach_ClassroomTrainingP2: "여행 경비 및 일반적으로 1 인당 더 높은 비용",
	ModernTrainingApproach_ClassroomTrainingP3: "협업을 최적화하거나 차량에서 직접 학습할 때 이상적입니다",
	ModernTrainingApproach_ClassroomTrainingP4: "실습 및 트레이너와의 상호 작용",
	ModernTrainingApproach_ClassroomTrainingP5: "트레이너는 참가자의 실제 요구에 따라 가르칠 지식을 더 효율적으로 조정할 수 있습니다",
	ModernTrainingApproach_OnlineTrainingOfferings: "온라인 교육 제공 (WBT / VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "오늘날 컴퓨터에서의 독립적인 학습은 지식 전달의 필수적인 부분입니다. 시간과 장소를 자유롭게 선택할 수 있을 수 있는 교육시스템은 무한 반복과 개별 학습속도에 맞추어진 양방향 학습콘텐츠를 제공하는 필수적인 학습도구입니다.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "각 WBT (Web-Based Training) 요소는 신중하게 구성되었습니다. 광범위한 지식 기반을 제공 할뿐만 아니라, 이 과정을 통해 Bosch 교육 센터의 교육 프로그램을 효과적인 준비를 할 수 있습니다.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "가상 진단 시뮬레이션은 가상 작업장에서 Bosch 진단 장치를 사용하여 문제 해결하는 작업 절차와 같은 일상적인 작업장 상황을 사용자 역할극 형식으로 가능하게 해 줍니다.",
	ModernTrainingApproach_Advantages: "장점",
	ModernTrainingApproach_AdvantagesP1: "지식을 제공하는 빠르고 효율적인 방법",
	ModernTrainingApproach_AdvantagesP2: "다양한 학습",
	ModernTrainingApproach_AdvantagesP3: "원하는 만큼 자주 사용할 수 있습니다",
	ModernTrainingApproach_AdvantagesP4: "최소한의 시간과 돈",
	ModernTrainingApproach_AdvantagesP5: "개별 학습 속도",
	ModernTrainingApproach_AdvantagesP6: "유연한 학습 시간",
	ModernTrainingApproach_AdvantagesP7: "반복성",
	ModernTrainingApproach_AdvantagesP8: "여행 비용이 없습니다",
	ModernTrainingApproach_AdvantagesP9: "용량 또는 일정 충돌이 없습니다",
	Dashboard_CoursesViewAndManageYourCourses: "코스 | 귀하의 코스를 확인 관리합니다",
	Dashboard_MyCourses: "내 코스",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "교육 프로그램 | 교육 프로그램을 확인하세요",
	Dashboard_MyTrainingProgram: "내 교육 프로그램",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | eLearning 포트폴리오를 확인 및 관리합니다",
	Dashboard_MyELearning: "내 e 러닝",
	Dashboard_AccountManageYourAccount: "계정 | 계정을 관리하십시오",
	Dashboard_MyAccount: "내 계정",
	Dashboard_MyDashboard: "내 대시 보드",
	Dashboard_ManageYourAccount: "계정을 관리하십시오",
	Dashboard_Workshops: "작업장",
	Dashboard_AdministrationManageYourWorkshop: "관리 | 작업장을 관리하십시오",
	Dashboard_AdministrationManageYourTeams: "관리 | 팀을 관리하십시오",
	Dashboard_Administration: "관리",
	Dashboard_ViewYourCourses: "코스를보십시오",
	Dashboard_ViewYourTrainingPrograms: "교육 프로그램을 보십시오",
	Dashboard_TrainingPrograms: "교육 프로그램",
	Dashboard_ViewAndEditYourDetails: "세부 사항을 확인 후 편집하십시오",
	Dashboard_ViewYourELearningCourses: "e 러닝 과정을 확인하세요.",
	Dashboard_Pending: "보류 중입니다",
	Dashboard_RequiresAdministratorSignOff: "(관리자 사인 오프 필요)",
	Dashboard_HistoricalRecords: "이력 기록",
	Dashboard_Registered: "등록되었습니다",
	Dashboard_WaitingList: "대기자 명단",
	Dashboard_RecommendedForYou: "당신에게 추천됩니다",
	Dashboard_AccountDetails: "계정 세부 정보",
	Dashboard_ChangeProfileImage: "프로필 이미지 변경",
	Dashboard_Title: "직위",
	Dashboard_Role: "역할",
	Dashboard_FirstName: "이름",
	Dashboard_LastName: "성",
	Dashboard_Telephone: "전화",
	Dashboard_Mobile: "휴대전화",
	Dashboard_Email: "이메일",
	Dashboard_Language: "언어",
	Dashboard_Save: "저장",
	Dashboard_Student: "학생",
	Dashboard_WorkshopTrainingAdministrator: "워크샵 교육 관리자",
	Dashboard_WholesalerTrainingAdministrator: "도매업체 교육 관리자",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "회사 관리",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "작업장을 관리하십시오...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "직원을 관리하십시오...",
	Dashboard_CompanyAdministration_Workshop: "워크샵",
	Dashboard_CompanyAdministration_Address: "주소",
	Dashboard_CompanyAdministration_Zip: "우편번호",
	Dashboard_CompanyAdministration_City: "도시",
	Dashboard_CompanyAdministration_Manage: "관리",
	Dashboard_AdministrationSection_AdministrationTitle: "관리",
	Dashboard_AdministrationSection_ManageTheTeam: "팀을 관리하십시오",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "관리 | 직원을 관리하십시오",
	Dashboard_AdministrationSection_Employees: "직원",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "관리 | 도매업체를 관리하십시오",
	Dashboard_AdministrationSection_Wholesalers: "도매업체",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "관리 | 거래 관리",
	Dashboard_AdministrationSection_Transactions: "거래",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "직원 관리",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "직원을 관리하십시오...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "등록하기",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "기록",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "편집하기",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "직원",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "추가하기",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "생성하기",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "등록되었습니다",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "보류 중입니다",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(관리자 사인 오프 필요)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "이력 기록",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "대기자 명단",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "현재 직원 목록",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "직원 관리",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "이름",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "직원 계정",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "직원 세부사항을 확인 및 편집하십시오...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "설정",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "개인 계정을 허용하십시오",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "직원 워크샵 관리 프록시 업그레이드",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "직원이 교육 행사에 등록할 수 있도록 합니다",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "이메일 초대장을 보내십시오",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "계정을 비활성화하십시오",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "저장",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "보내기",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "편집",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "도매 업체 관리",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "도매 업체를 관리하십시오...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "승인된 도매 업체 목록",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "이름",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "삭제",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "생성하기",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "추가하기",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "새로운 직원 계정 생성",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "신입 사원에 대한 세부 정보를 작성하십시오...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "승인된 도매업체 관리자를 새로 추가하십시오",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "새 도매업자를 위한 세부사항을 작성하십시오...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "드롭 다운 메뉴에서 승인된 도매업체를 선택하십시오...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "거래",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "거래내역 검토 관리",
	Dashboard_AdministrationSection_TransactionsSection_Back: "뒤",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "송장 번호",
	Dashboard_AdministrationSection_TransactionsSection_Description: "기술",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "지불 방법",
	Dashboard_AdministrationSection_TransactionsSection_Date: "데이트",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "금액",
	Dashboard_AdministrationSection_TransactionsSection_Download: "다운로드",
	TrainingCentres_TrainingCentres: "교육 센터",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "우리의 교육 센터는 전세계에 위치하고 있습니다",
	TrainingCentres_CountryCategory: "국가 카테고리",
	TrainingCentres_CityCategory: "도시 카테고리",
	TrainingCentres_Country: "국가",
	TrainingCentres_City: "도시",
	TrainingCatalogue_TrainingCatalogue: "교육 과정 카탈로그",
	TrainingCatalogue_LearningAtTheHighestLevel: "최고 수준에서 학습",
	TrainingCatalogue_StartDate: "시작일",
	TrainingCatalogue_EndDate: "종료일",
	TrainingCatalogue_TrainingName: "교육 이름",
	TrainingCatalogue_Language: "언어",
	TrainingCatalogue_Days: "일수",
	TrainingCatalogue_Seats: "좌석",
	TrainingCatalogue_Price: "가격",
	TrainingCatalogue_Location: "위치",
	TrainingCatalogue_PartNumber: "부품 번호",
	TrainingCatalogue_TrainingCourse: "교육 과정",
	TrainingCatalogue_Category: "교육 과정",
	TrainingCatalogue_CourseName: "교육 프로그램",
	TrainingCatalogue_TotalDays: "총 일수",
	TrainingCatalogue_Venue: "장소",
	TrainingCatalogue_Details: "세부",
	TrainingCatalogue_Prerequisites: "전제 조건",
	TrainingCatalogue_CourseType: "코스 유형",
	TrainingCatalogue_LearningMethod: "학습 방법",
	TrainingCatalogue_AddToWaitingList: "대기 명단에 추가하십시오",
	ShoppingCart_ShoppingCart: "쇼핑 장바구니",
	ShoppingCart_ReviewAndManage: "구독을 검토하고 관리하십시오",
	ShoppingCart_YouHaveAddedItem: "장바구니에 다음 항목을 추가했습니다",
	ShoppingCart_Item: "항목",
	ShoppingCart_Quantity: "수량",
	ShoppingCart_PricePerItem: "품목 당 가격",
	ShoppingCart_TotalPrice: "총 가격",
	ShoppingCart_VatIsIncluded: "부가세 제외",
	ShoppingCart_Redeem: "크레딧이 없으면 여기를 클릭하여 교환하십시오",
	ShoppingCart_Purchase: "크레딧이 없으면 여기를 클릭하여 구매하십시오",
	ShoppingCart_Checkout: "체크 아웃",
	ShoppingCart_ContinueShopping: "쇼핑을 계속하십시오",
	ShoppingCart_TotalPriceInclVat: "총 가격(부가세 포함)",
	ShoppingCart_Apply: "적용",
	ShoppingCart_ShoppingCartEmpty: "장바구니가 비어 있습니다",
	ShoppingCart_VatIncluded: "부가가치세 (포함)",
	ShoppingCart_ManageYourShopping: "쇼핑을 관리하십시오",
	ShoppingCart_Participant: "참가자",
	ShoppingCart_Complete: "완료",
	ShoppingCart_NetPrice: "순 가격",
	ShoppingCart_Workshop: "워크샵",
	CheckOut_PreviewYourOrder: "주문을 미리보십시오",
	CheckOut_YourShoppingCart: "장바구니",
	CheckOut_Preview: "미리보기",
	CheckOut_Payment: "지불",
	CheckOut_Summary: "요약",
	CheckOut_Back: "뒤",
	CheckOut_PleaseReadAndAgreeOurTerms: "이용 약관을 읽고 동의해 주세요.",
	CheckOut_IHaveReadTheTermsAndCondition: "이용 약관을 읽었으며 이를 이해하고 수락합니다",
	CheckOut_YesIAgree: "예-약관에 동의합니다",
	CheckOut_Continue: "계속하기",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "축하합니다! 주문을 받았습니다",
	CheckOut_OrderConfirmation: "요청이 성공적으로 처리되면 최종 주문 확인서를 받게됩니다",
	CheckOut_WeWillContactYouDirectly: "귀하의 요청과 관련하여 궁금한 점이 있으면 직접 연락 드리겠습니다",
	CheckOut_AddtoCart: "장바구니에 추가하십시오",
	CheckOut_CreditCart: "신용 카트",
	CheckOut_DirectDebit: "직불 결제",
	CheckOut_TrainingCredits: "교육 크레딧",
	CheckOut_PreferredWholesaler: "선호하는 도매상",
	EventCalendar_EventCalendar: "이벤트 캘린더",
	EventCalendar_TrainingEventsForAllYourNeeds: "모든 요구에 맞추어진 교육 행사",
	TrainingCourse_TrainingCourse: "교육 과정 카탈로그",
	TrainingCourse_LearningAtTheHighestLevel: "최고 수준에서 학습",
	TrainingProgram_Title: "교육 프로그램 카탈로그",
	TrainingProgram_SubTitle: "최고 수준에서 학습",
	AccountDeactivationModal_Title: "계정을 비활성화 하시겠습니까?",
	AccountDeactivationModal_Line1: "계정을 비활성화하는 것은 영구적이며 서비스 요청 처리사항을 포함한 모든 내역을 제거하며, 팀원 및 프로필 설정을 조정하게 됩니다.",
	AccountDeactivationModal_Line2Part1: "가입 기간이 유효한 경우 당사의 취소 정책을 참조하십시오",
	AccountDeactivationModal_Line2Part2: "이용 약관",
	AccountDeactivationModal_Line2Part3: "요청을 제출하기 전에.",
	AccountDeactivationModal_Line3: "이 계정에 대한 비활성화 요청을 제출하시겠습니까?",
	AccountDeactivationModal_Cancel: "취소",
	AccountDeactivationModal_Send: "보내기",
	CurrentUserAccountDeactivationModal_Title: "계정을 비활성화 하시겠습니까?",
	CurrentUserAccountDeactivationModal_Line1: "계정을 비활성화하는 것은 영구적이며 서비스 요청 처리사항을 포함한 모든 내역을 제거하며, 팀원 및 프로필 설정을 조정하게 됩니다.",
	CurrentUserAccountDeactivationModal_Line2Part1: "가입 기간이 유효한 경우 당사의 취소 정책을 참조하십시오",
	CurrentUserAccountDeactivationModal_Line3: "계정 비활성화 요청을 제출 하시겠습니까?",
	CurrentUserAccountDeactivationModal_Yes: "예",
	CurrentUserAccountDeactivationModal_No: "아니요",
	CourseWithdrawalModal_Title: "귀하의 취소는 교육 관리자의 승인을 받기 전에는 최종적이지 않습니다.",
	CourseWithdrawalModal_Line1: "승인되면 이메일로 최종 확인을 받습니다. 궁금한 점이 있으면 직접 연락 드리겠습니다. 철회 요청을 제출 하시겠습니까?",
	CourseWithdrawalModal_Line2Part1: "우리의 것을 읽으십시오",
	CourseWithdrawalModal_Line2Part2Link: "취소 정책",
	CourseWithdrawalModal_Line2Part3: "철회 요청을 계속하시겠습니까?",
	CourseWithdrawalModal_Yes: "예",
	CourseWithdrawalModal_No: "아니요",
	Settings_Settings: "설정",
	Settings_ManageYourSettings: "설정을 관리하기",
	Settings_Profile: "프로필",
	Settings_MyBoschGlobalIdLoginInformation: "내 Bosch 글로벌 ID 로그인 정보",
	Settings_Edit: "편집",
	Settings_CompanyDetails: "회사 세부 사항",
	Settings_RequestAccountDeletion: "계정 삭제 요청",
	Settings_AccountDeletionText: "계정을 삭제하면 모든 데이터 (주소 및 지불 데이터 포함)가 지워지고 더 이상 사용할 수 없게됩니다.",
	Settings_DeleteAccountRequestConfirmation: "계정 요청 확인을 삭제하십시오",
	Settings_DeleteAccountRequestConfirmationText: "귀하의 계정 삭제 요청을 받았으며 최종 확인을 위해 이메일 또는 전화로 연락을 드릴 것입니다",
	RecommendedHotels_RecommendedHotels: "추천 호텔",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "우리의 교육 센터는 전세계에 위치하고 있습니다",
	Error_Common: "문제가 발생했습니다!",
	Error_404: "방문한 페이지가 존재하지 않습니다.",
	Error_401: "이 페이지에 대한 액세스 권한이 없습니다.",
	Error_500: "서버에서 오류를 보고하고 있습니다.",
	Dashboard_Courses_Table_Course: "연설",
	Dashboard_Courses_Table_Date: "날짜 [일]",
	Dashboard_Courses_Table_Venue: "장소",
	Dashboard_Courses_Table_TrainingType: "교육형태",
	Dashboard_Courses_Table_Action: "Action",
	Dashboard_Courses_Table_TasksToComplete: "다음 작업을 완료해야 합니다.",
	Dashboard_Courses_Table_Withdraw: "철회",
	Dashboard_Courses_Table_Remove: "삭제",
	Dashboard_Courses_Table_View: "보기",
	Dashboard_Courses_Table_NoRecordsToDisplay: "표시할 기록이 없습니다.",
	Dashboard_Courses_Table_CompletionDate: "완성 날짜",
	Dashboard_Courses_Table_Actions_Assignments: "할당",
	Dashboard_Courses_Table_Actions_Certification: "인증",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluations",
	Dashboard_Courses_Table_Actions_Assessments: "Assessments",
	Dashboard_Courses_Table_Actions_AddToCalendar: "캘린더에 추가하기",
	Dashboard_Courses_Table_CourseDetails_Task: "과제",
	Dashboard_Courses_Table_CourseDetails_DueDate: "마감 시간",
	ContactUs_Headline: "문의하기",
	ContactUs_HowCanWeHelp: "무엇을 도와 드릴까요?",
	ContactUs_EmailTab: "이메일",
	ContactUs_PhoneTab: "전화",
	ContactUs_LetterTab: "우편",
	ContactUs_Phone_Headline: "문의 전화 주십시오.",
	ContactUs_Phone_Description: "귀하의 전화를 기다리고 있으며 기꺼이 도와드리겠습니다.",
	ContactUs_Phone_PhoneNumber: "문의 양식을 사용하여 메시지를 보내주십시오.",
	ContactUs_Phone_ChargeNotice: "요금은 서비스 제공업체 및 국가에 따라 다릅니다.",
	ContactUs_Letter_Headline: "우편 문의 주십시오.",
	ContactUs_Letter_Description: "질문, 요청 또는 제안 사항이 있습니까? 여러분의 연락을 기다립니다.",
	ContactUs_Email_Headline: "이메일을 보내주세요",
	ContactUs_Email_Description: "아래 양식을 작성하여 메시지를 보내주세요. 저희 담당자 중 한 명이 연락을 드릴 것입니다.",
	ContactUs_Email_InputName: "이름*",
	ContactUs_Email_InputCompany: "회사",
	ContactUs_Email_InputCountry: "국가*",
	ContactUs_Email_InputEmail: "이메일*",
	ContactUs_Email_InputMessage: "문의내용*",
	ContactUs_Email_DataProtection: "데이터 보호 고지*",
	ContactUs_Email_DataProtectionConfirm: "개인정보 보호정책*을 읽었습니다.",
	ContactUs_Email_SecurityCheck: "보안 검색",
	ContactUs_Email_SecurityCodeConfirm: "위의 상자에 표시된 올바른 문자를 입력하십시오.",
	ContactUs_Email_SecurityCodeInvalid: "입력한 보안문자 텍스트 답변이 올바르지 않습니다.",
	ContactUs_Email_MandatoryNotice: "필수 필드를 작성하십시오.",
	ContactUs_Email_SuccessMessage: "귀하의 메시지가 제출되었습니다",
	ContactUs_Email_TakenNoteDataProtection1: "개인 정보 보호 정책을 읽고 확인하였습니다",
	ContactUs_Email_TakenNoteDataProtection2: "개인 정보 정책. *",
	ContactUs_Email_SubmitButton: "제출하기",
	ContactUs_Email_FieldErrorMessage: "필수 필드를 작성하십시오.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "귀하의 계정은 현재 검토 중입니다!",
	AccountInReviewModal_Paragraph1: "Bosch Automotive Training Solutions에 개인 계정을 등록해 주셔서 감사합니다.",
	AccountInReviewModal_Paragraph2: "현재 전담 트레이너가 귀하의 계정을 검토하고 구성하고 있습니다. 계정이 성공적으로 구성되면 이메일을 통해 최종 확인을 받게 됩니다.",
	AccountInReviewModal_Paragraph3: "그동안 액세스가 제한된 당사 웹 사이트를 자유롭게 탐색하십시오.",
	AccountInReviewModal_Paragraph4: "문의 사항이 있거나 주말 및 공휴일을 제외하고 48시간 이내에 계정이 처리되지 않은 경우 당사에 문의할 수 있습니다.",
	AccountInReviewModal_ContactUsLink: "여기",
	AccountInReviewModal_OKButton: "좋아요, 들어가게 해주세요.",
	Header_YesButton: "네",
	Header_NoButton: "아니오",
	Header_LogOutText: "종료하시겠습니까?",
	Footer_EnquiryText: "귀하의 문의를 기다리고 있습니다,",
	Footer_Telephone: "전화,",
	Footer_TermsAndConditions: "이용약관",
	Footer_HowOurTrainingEvolves: "교육은 어떻게 발전하는가",
	FooterSearch_SearchFieldPlaceholder: "검색",
	TrainingCatalogue_Action: "이동하다",
	TrainingCatalogue_Duration: "계속하다",
	TrainingCatalogue_Information: "정보",
	Settings_PersonalDetails: "은둔",
	Reset_All_Filters: "모든 필터 재설정",
	EventTable_Days: "일수",
	SearchField_AllResults: "모든 결과",
	TrainingCentresLayout_ViewDetails: "세부 사항을 확인",
	Dropdown_Placeholder: "선택하다...",
	WaitlistPopup_SelectButtonText: "선택하다",
	WaitlistPopup_StartDate: "시작일",
	WaitlistPopup_EndDate: "종료일",
	WaitlistPopup_Language: "언어",
	WaitlistPopup_City: "도시",
	WaitlistPopup_Workshop: "직장",
	WaitlistPopup_Student: "학생",
	WaitlistPopup_PreferredLocation: "선호 위치",
	WaitlistPopup_SignUpCourseWaitingList: "코스 대기자 명단에 등록",
	WaitlistPopup_SignUpWithoutSelectingDate: "날짜를 선택하지 않고 대기자 명단에 등록",
	WaitlistPopup_GeneralWaitList: "일반 대기자 명단",
	WatinglistPopup_CourseLabel: "연설",
	Common_ContinueButton: "계속하기",
	UserAccountDeactivationModal_ConfirmLabel: "확인하기",
	Filter_FilterLabel: "필터",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "당신은 이미 대기자 명단에 있습니다. 기존 등록을 삭제하고 새 등록을 하시겠습니까? ",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "네 삭제",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "아니오, 취소",
	WaitinglistPopup_RemoveWaitlistItem_Title: "코스 취소",
	WaitinglistPopup_SignUpButtonText: "가입하기",
	MyAccount_FirstNameRequied: "이름은 필수 항목입니다.",
	MyAccount_LastNameRequired: "성은 필수 항목입니다.",
	MyAccount_RoleRequired: "역할은 필수 항목입니다.",
	MyAccount_MobileRequired: "휴대폰은 필수 항목입니다.",
	MyAccount_EmailRequired: "이메일은 필수 항목입니다.",
	MyAccount_LanguageRequired: "언어는 필수 항목입니다.",
	MyAccount_UpdateSuccessMessage: "귀하의 계정 정보가 성공적으로 업데이트되었습니다!",
	MyAccount_UpdateSuccessModal_ButtonText: "예 닫기",
	Infor_CorporateInformation_Header: "회사 정보",
	Infor_LegalNotice_Header: "법적 고지",
	Infor_PrivacyPolicy_Header: "개인정보 보호정책",
	CreateEmployeeAccountModification_FormValidation_Role: "역할은 필수 항목입니다.",
	CreateEmployeeAccountModification_FormValidation_Firstname: "이름은 필수 항목입니다.",
	CreateEmployeeAccountModification_FormValidation_Lastname: "성은 필수 항목입니다.",
	CreateEmployeeAccountModification_FormValidation_Mobile: "휴대폰은 필수 항목입니다.",
	CreateEmployeeAccountModification_FormValidation_Email: "이메일은 필수 항목입니다.",
	CreateEmployeeAccountModification_FormValidation_Language: "언어는 필수 항목입니다.",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Bosch Automotive Training Solutions 가입 초대",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "팀에 새 구성원을 성공적으로 추가했습니다!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Bosch Automotive Training Solutions의 개인 계정을 만드는 방법에 대한 지침이 포함된 이메일 초대장을 새 팀원과 공유하시겠습니까?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "아니오",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "예, 초대장을 보내세요.",
	ShoppingCart_Venue_Fee: "현지 수수료",
	ShoppingCart_Trainning_Name: "교육명",
	ShoppingCart_Available_Credits: "사용가능한 크레딧",
	ConfirmItsMeModal_Hi: "안녕하세요",
	ConfirmItsMeModal_Description1: "Bosch Automotive Training Solutions에서 개인 계정을 생성하기 위해 회사 관리자가 공유한 초대를 성공적으로 완료했습니다.",
	ConfirmItsMeModal_Description2: "계속하기 전에 계정 세부 정보를 확인하거나 업데이트하세요.",
	ConfirmItsMeModal_Description3: "확인 후 계정 생성을 완료하려면 다시 로그인해야 합니다.",
	ConfirmItsMeModal_CompanyDetails: "회사 상세사항",
	ConfirmItsMeModal_YourDetails: "고객 상세사항",
	ConfirmItsMeModal_YourDetails_Title: "직책",
	ConfirmItsMeModal_YourDetails_Function: "기능",
	ConfirmItsMeModal_YourDetails_Name: "이름",
	ConfirmItsMeModal_YourDetails_Mobile: "휴대폰",
	ConfirmItsMeModal_YourDetails_Email: "이메일",
	ConfirmItsMeModal_YourDetails_Language: "언어",
	ConfirmItsMeModal_ConfirmButton: "확인하다",
	SelectWholesalerModal_WholesalerGroupLabel: "도매상(대리점)",
	SelectWholesalerModal_WholesalerBranchLabel: "아웃렛",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "선택해주세요",
	ApplyButton: "신청하기",
	SelectWholesalerModal_Title: "도매업자(대리점)를 선택하십시오",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "등록을 삭제하시겠습니까?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "등록이 성공적으로 삭제되었습니다!",
	WaitlistPopup_AddToWaitListSuccess: "등록이 성공적으로 추가되었습니다!",
	ErrorCommon_Header: "애러",
	CommonButton_Close: "닫기",
	MyELearning_OpeningVideoPlayerTitle: "보쉬 자동차 온라인 학습",
	MyELearning_VideoPlayerClosingMessage: "계속하려면 이 창을 닫아주세요!",
	EmbeddedVideoPlayer_StartButtonText: "시작",
	EmbeddedVideoPlayer_RefresherButtonText: "리프레쉬",
	DeactivateWholesalerModal_ConfirmMessage: "이 도매업체를 삭제하시겠습니까?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "초대 이메일 보내기 성공!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "다시 초대",
	ContactUs_Mobile_KR: "+82 2 1599-3567",
	ContactUs_Address_KR: "로버트보쉬코리아(유)",
	ContactUs_Address_LV: "경기도 용인시 기흥구 신수로 567",
	ContactUs_Address_LT: "16923",
	Footer_ExploreTrainingStories: "모든 교육 예시 보기",
	Breadcrumb_Home: "방",
	Breadcrumb_Contact: "접촉",
	Breadcrumb_Stories: "이야기",
	Breadcrumb_Stories_BoschServiceTraining: "보쉬 서비스 교육",
	Breadcrumb_Stories_ADayInLife: "트레이너의 날",
	Breadcrumb_Stories_BoschTrainingBenefits: "보쉬 교육 혜택",
	Breadcrumb_Stories_TrainingApproach: "현대적인 훈련 방법",
	Breadcrumb_Settings: "놓다",
	Breadcrumb_ShoppingCart: "쇼핑 카트",
	Breadcrumb_Checkout: "체크 아웃",
	Breadcrumb_OrderSummary: "주문 요약",
	Breadcrumb_Exception: "예외",
	Breadcrumb_Dashboard: "계기반",
	Breadcrumb_MyCourses: "내 과정",
	Breadcrumb_MyELearning: "내 전자 학습",
	Breadcrumb_CompanyAdministration: "회사 경영",
	Breadcrumb_EmployeeManagement: "직원 관리",
	Breadcrumb_EmployeeAccount: "직원 계정",
	Breadcrumb_EmployeeCreation: "직원 생성",
	Breadcrumb_Administration: "관리하다",
	Breadcrumb_EmployeeAdministration: "직원 관리",
	Breadcrumb_WholesalerManagement: "도매상 관리",
	Breadcrumb_NewWholesalerAccountCreation: "새 도매상 계정 만들기",
	Breadcrumb_Transactions: "일",
	Breadcrumb_MyAccount: "내 계정",
	Breadcrumb_TrainingCentres: "교육 센터",
	Breadcrumb_EventCalendar: "이벤트 캘린더",
	Breadcrumb_TrainingCourseCatalogue: "강좌 카탈로그",
	Breadcrumb_RecommendedHotels: "추천 호텔",
	Breadcrumb_CorporateInformation: "회사 정보",
	Breadcrumb_LegalNotice: "법적 고지",
	Breadcrumb_DataProtectionNotice: "개인정보 보호정책",
	Breadcrumb_PrivacyPolicy: "개인 정보 정책",
	Breadcrumb_TermCondition: "이용약관",
	Breadcrumb_Terms: "도끼",
	Breadcrumb_PrivacyStatement: "개인 정보 정책",
	Breadcrumb_Cookies: "쿠키",
	Breadcrumb_Provider: "생산자",
	Breadcrumb_RegisterAccount: "계정 등록",
	Stories_ExploreMore: "더 이해",
	CheckOutLayout_ShoppingCartHeader: "쇼핑 카트",
	CheckOutLayout_AddedItemCartMessage: "장바구니에 다음을 추가했습니다.",
	CheckOutLayout_CheckYourCartMessage: "장바구니를 확인",
	ModernTrainingApproach_DateText: "2021년 1월 10일",
	ADayInLifeofATrainer_DateText: "2021년 9월 29일",
	BoschTrainingBenefits_DateText: "2021년 9월 30일",
	BoschServiceTraining_DateText: "2021년 9월 28일",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "직원 검색",
	CheckOutLayout_OrderWasNotPlaced_Message: "죄송합니다! 주문이 완료되지 않았습니다.",
	CheckOutLayout_RefusalReason: "거부 이유",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "결제 실패. 다른 결제 수단으로 다시 시도하세요",
	CheckOutLayout_SubmittingApiFailed_Message: "API 제출 실패",
	CartItem_ChooseWorkshop_Message: "참가자를 선택하려면 워크샵을 선택하십시오",
	CartItem_ChooseParticipant_Message: "계속하려면 참가자를 선택하세요.",
	CompanyAdministrationTable_SearchBox_Placeholder: "워크샵 검색",
	CartItem_RemoveCartItem_ConfirmText: "장바구니에서 이 항목을 제거하시겠습니까?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "함수",
	AddToCartSuccessModal_Message: "장바구니가 업데이트되었습니다!",
	AddToCartSuccessModal_ContinueButtonText: "쇼핑을 계속",
	AddToCartSuccessModal_MyShoppingCartButtonText: "내 장바구니",
	AddVoucher_Header: "바우처 추가",
	AddVoucher_InputPlaceholder: "코드를 입력하고 적용",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "유감스럽게도 현재 이 교육 과정의 예정된 이벤트가 없습니다. 대기자 명단에 등록해 주시면 이벤트가 시작되는 대로 알려드리겠습니다.",
	ADYEN_ERR_2_: "거래가 거부되었습니다.",
	ADYEN_ERR_3_: "추천 오류",
	ADYEN_ERR_4_: "취득자 측에서 발생한 오류로 인해 거래가 진행되지 않았습니다.",
	ADYEN_ERR_5_: "거래에 사용된 카드가 차단되어 사용할 수 없습니다.",
	ADYEN_ERR_6_: "거래에 사용된 카드의 유효기간이 만료되었습니다. 따라서 사용할 수 없습니다.",
	ADYEN_ERR_7_: "거래 과정에서 금액 불일치가 발생했습니다.",
	ADYEN_ERR_8_: "지정된 카드 번호가 올바르지 않거나 유효하지 않습니다.",
	ADYEN_ERR_9_: "거래 승인을 위해 구매자의 은행에 연락할 수 없습니다.",
	ADYEN_ERR_10_: "구매자의 은행은 이러한 유형의 거래를 지원하지 않거나 허용하지 않습니다.",
	ADYEN_ERR_11_: "3D Secure 인증이 실행되지 않았거나 성공적으로 실행되지 않았습니다.",
	ADYEN_ERR_12_: "카드에 지불 가능한 금액을 충당하기에 충분한 금액이 없습니다. 이 문제는 카드의 월별 지불 금액 한도 때문에 발생할 수 있습니다. MasterCard와 같은 다른 카드를 사용하거나 은행에 문의하여 카드의 월 한도를 재설정하십시오.",
	ADYEN_ERR_14_: "사기일 가능성",
	ADYEN_ERR_15_: "거래가 취소되었습니다.",
	ADYEN_ERR_16_: "구매자가 거래를 완료하기 전에 취소했습니다.",
	ADYEN_ERR_17_: "지정된 PIN이 올바르지 않거나 유효하지 않습니다.",
	ADYEN_ERR_18_: "구매자가 연속해서 세 번 이상 잘못된 PIN을 지정했습니다.",
	ADYEN_ERR_19_: "지정된 PIN 번호를 확인할 수 없습니다.",
	ADYEN_ERR_20_: "사기일 가능성",
	ADYEN_ERR_21_: "거래가 처리를 위해 올바르게 제출되지 않았습니다.",
	ADYEN_ERR_22_: "위험 확인에서 거래를 사기로 표시했습니다(위험 점수 >= 100). 따라서 작업이 취소됩니다.",
	ADYEN_ERR_23_: "다음 거부 코드는 이 거부 사유에 매핑됩니다. 발급자/카드 소지자에게 허용되지 않는 거래",
	ADYEN_ERR_24_: "지정된 CVC(카드 보안 코드)가 잘못되었습니다.",
	ADYEN_ERR_25_: "다음 거부 코드는 이 거부 사유에 매핑됩니다. 이 국가에서 유효하지 않은 카드",
	ADYEN_ERR_26_: "R1: 승인 명령 취소 / \"R3: 모든 승인 명령 취소\" / \"R0: 지불 중지 명령\"",
	ADYEN_ERR_27_: "이 응답은 안정적으로 매핑할 수 없는 모든 응답 코드를 매핑합니다. 이렇게 하면 일반적인 거부(예: Mastercard \"05: Do not honor\" 응답)와 보다 구체적인 거부를 더 쉽게 구분할 수 있습니다.",
	ADYEN_ERR_28_: "구매자 카드에 허용된 출금 금액이 초과되었습니다.",
	ADYEN_ERR_29_: "구매자 카드에 허용된 인출 횟수를 초과했습니다.",
	ADYEN_ERR_31_: "발행인은 거래를 의심되는 사기로 보고했습니다.",
	ADYEN_ERR_32_: "구매자가 입력한 주소 데이터가 올바르지 않습니다.",
	ADYEN_ERR_33_: "구매자의 은행은 구매자가 온라인 PIN을 입력하도록 요구합니다.",
	ADYEN_ERR_34_: "구매자의 은행에서 구매를 완료하려면 당좌예금 계좌가 필요합니다.",
	ADYEN_ERR_35_: "구매자의 은행에서 구매를 완료하려면 저축 계좌가 필요합니다.",
	ADYEN_ERR_36_: "구매자의 은행은 구매자가 모바일 PIN을 입력하도록 요구합니다.",
	ADYEN_ERR_37_: "구매자가 비접촉식 결제를 시도한 후 거래를 포기했으며 다른 카드 입력 방법(PIN 또는 스와이프)을 시도하라는 메시지가 표시되었습니다.",
	ADYEN_ERR_38_: "발급사가 인증 면제 요청을 거부하고 거래에 대한 인증을 요구합니다. 3D Secure로 다시 시도하십시오.",
	ADYEN_ERR_39_: "발급자 또는 체계가 RReq를 통해 결과를 전달할 수 없습니다.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "이름에는 특수 문자가 포함되지 않아야 합니다(예: @#$...).",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "이름은 75자 미만이어야 합니다.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "성에는 특수 문자가 포함되지 않아야 합니다(예: @#$...).",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "성은 75자 미만이어야 합니다.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "휴대폰 번호는 특수 문자를 포함할 수 없습니다(예: @#$...).",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "휴대폰 번호는 45자 미만이어야 합니다.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "전화번호는 특수 문자를 포함할 수 없습니다(예: @#$...).",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "전화번호는 45자 미만이어야 합니다.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "이메일 주소가 잘못되었습니다.",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "이메일은 100자 미만이어야 합니다.",
	ConfirmGeolocationModal_ConfirmButton: "계속하기",
	ConfirmGeolocationModal_CancelButton: "변경하기",
	ConfirmGeolocationModal_CurrentCountrySelection: "현재 국가 선택은 다음과 같습니다.",
	ConfirmGeolocationModal_AskChangeCountry: "이 사이트를 계속 사용하시겠습니까, 아니면 국가 기본 설정을 변경하시겠습니까?",
	Addtocart_Modal_AddToCartFailTitle: "이 코스에 이미 등록된 참가자",
	Addtocart_Modal_AlreadyInCart: "이 이벤트에 이미 등록된 참가자",
	TrainingCatalogue_DateRange: "날짜 범위",
	TrainingCatalogue_DateRange_placeholder: "날짜 범위 필터 선택",
	Dashboard_MyPendingCourses: "내 보류 과정",
	Dashboard_MyWaitingList: "내 대기자 명단",
	Dashboard_LatestNews: "최근 뉴스",
	SettingsMainPage_EditAccountButton: "계정 수정",
	SettingsMainPage_EditBoschId: "Bosch ID 편집",
	EventInfoView_EventDetailTab: "이벤트 내용",
	EventInfoView_AssignmentsTab: "과제",
	EventInfoView_EvaluationTab: "평가",
	EventInfoView_AttachmentTab: "첨부파일",
	EventInfoView_CourseDescriptionTab: "설명",
	EventInfoView_DescriptionTab_General: "일반사항",
	EventInfoView_DescriptionTab_PartLabel: "파트",
	AssignmentTab_Description: "여기에서 이 과정을 완료하는 데 필요한 모든 정보를 찾을 수 있습니다.",
	AssignmentTab_Columns_Download: "다운로드",
	AssignmentTab_Columns_Upload: "업로드",
	AssignmentTab_Columns_TaskComplete: "작업 완료",
	EventDetailTab_Location_Message_Video: "이것은 \"비디오 실행\" 링크를 사용하여 액세스할 수 있는 온라인 이벤트입니다.",
	EventDetailTab_Location_Message_WebBased: "이것은 \"WBT 실행\" 링크를 사용하여 액세스할 수 있는 온라인 이벤트입니다.",
	EventDetailTab_Location_Message_WebCast: "이것은 \"지금 가입하기\" 링크를 사용하여 액세스할 수 있는 온라인 이벤트입니다.",
	EventDetailTab_Link_Video: "비디오 실행",
	EventDetailTab_Link_Video_Remark: "*동영상을 실행하면 새창이 열립니다. 창이 닫히면 이 페이지로 다시 리디렉션됩니다.",
	EventDetailTab_Link_WebBased: "WBT 실행",
	EventDetailTab_Link_WebBased_Remark: "*WBT를 실행하면 새 창이 열립니다. 창이 닫히면 이 페이지로 다시 리디렉션됩니다.",
	EventDetailTab_Link_WebCast: "지금 가입하기",
	EventDetailTab_Link_WebCast_Remark: "*지금 참여하기 링크는 이벤트 시작 30분 전에 활성화됩니다.",
	EventDetailTab_Title_Status: "상태",
	EventDetailTab_Title_Duration: "기간(시간)",
	EventDetailTab_Title_Trainers: "강사",
	EventDetailTab_Title_Language: "언어",
	EventDetailTab_Title_Score: "점수(%)",
	EventDetailTab_Title_Calendar: "캘린더에 추가",
	EventDetailTab_Title_Certificate: "인증서 다운로드",
	EventDetailTab_Title_Messages: "메시지",
	EventDetailTab_Title_StartDate: "시작일",
	EventDetailTab_Title_AvailableFrom: "가용일(From)",
	EventDetailTab_Title_Time: "시간",
	EventDetailTab_Title_EndDate: "종료일",
	EventDetailTab_Title_ExpiresOn: "만료일",
	EventDetailTab_Title_Room: "룸",
	EventDetailTab_Title_Method: "방법",
	EventDetailTab_Title_Link: "링크*",
	EventDetailTab_Title_Location: "위치",
	AttachmentTab_EmptyScreenDescription: "이 이벤트에 대한 첨부 파일이 없습니다.",
	AttachmentTab_Columns_Download: "다운로드",
	AttachmentTab_Description: "여기에서 이 과정에 도움이 되는 몇 가지 중요한 정보를 찾을 수 있습니다.",
	EvaluationTab_Annotation: "우리는 귀하의 피드백을 소중히 여기며 귀하의 의견을 듣고 싶습니다. 귀하에 대한 서비스를 지속적으로 개선할 수 있도록 시간을 내어 다음 평가를 작성해 주십시오. 감사합니다.",
	EvaluationTab_Notice: "등급은 다음과 같습니다",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "이벤트 평가를 제출하기 전에 모든 필수 질문에 답하십시오.",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "피드백이 성공적으로 제출되었습니다.",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "평가 제출 실패",
	TrainingCatalogueLayout_ExportButtonText: "내보내다",
	EvaluationTab_FeedbackHasBeenGiven_Message: "평가가 완료되었거나 만료되었습니다.",
	EventDetailTab_Link_CopiedTooltip: "클립보드에 복사",
	EventDetailTab_QRCode_ExplanationText_WBT: "스캔하여 WBT 실행",
	EventDetailTab_QRCode_ExplanationText_Video: "스캔하여 비디오 실행",
	EventDetailTab_QRCode_ExplanationText_Webcast: "스캔하여 웹캐스트 시작",
	Footer_TrainingCoure_Headline: "보쉬와 함께 하는 교육과정들",
	Footer_TrainingCourse_Content: "Bosch는 세계적으로 유명한 Superbrand로서 100년 이상의 혁신적인 경험을 통해, 자동차 시스템, 부품 및 기술 솔루션 제공업체로서 전 세계적인 표준의 위상을 차지하고 있습니다. 우리의 강점 중 많은 부분은 전 세계의 최첨단 시스템 및 구성 요소를 설계, 개발 및 생산하는 차량 제조업체와의 긴밀한 파트너십에서 비롯됩니다. 결과적으로 Bosch는 다양한 부품 포트폴리오, 진단 솔루션 및 정비소 서비스를 통해 자동차 애프터마켓 제품 및 지원 분야에서 강력한 역사를 공고히 했습니다. 학습과 개발은 성공의 확실한 열쇠이며 이 철학은 자동차 기술, 차량 시스템 및 진단 전문 지식의 모든 영역에서 광범위한 교육 과정에 의해 뒷받침됩니다.",
	SearchField_NoRecordsFound: "기록 없음",
	TrainingCatalogue_FreeTextSearch: "무료 문자",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "검색",
	CheckoutLayout_WholeSalerGroup: "도매상 그룹 선택",
	CreditInsufficientModal_Message: "이 결제 수단을 사용하기에 충분한 크레딧이 없습니다. 다른 결제 수단으로 다시 시도해 주세요.",
	ShoppingCart_Required_Credits: "총 가격",
	MaintainingInfo_Message1: "웹사이트가 유지 관리 모드에 있습니다.",
	MaintainingInfo_Message2: "우리는 곧 돌아오겠습니다.",
	CountryValidationModal_Message: "[CountryB]에서 계정이 생성되었기 때문에 [CountryA]에 로그인할 수 없습니다.",
	CountryValidationModal_ButtonText: "[국가]로 계속",
	EventTable_LoginRequired: "등록하려면 로그인하세요.",
	TrainingPartTableLayout_Date: "날짜",
	TrainingPartTableLayout_StartTime: "시작 시간",
	TrainingPartTableLayout_EndTime: "종료 시간",
	TrainingPartTableLayout_Duration: "기간(시간)",
	TrainingPartTableLayout_TrainingMethod: "교육 방법",
	MyCoursesTableContents_ActiveHeader: "활성 과정",
	MyCoursesTableContents_ActiveTabHeader: "활성화",
	MyCoursesTableContents_ScheduledHeader: "예정된 코스",
	MyCoursesTableContents_ScheduledTabHeader: "예정",
	MyCoursesTableContents_OnlineHeader: "온라인 코스",
	MyCoursesTableContents_OnlineTabHeader: "온라인",
	MyCoursesTableContents_CancelledHeader: "취소된 과정",
	MyCoursesTableContents_CancelledTabHeader: "취소됨",
	OnlineCoursesTable_Title_Progress: "진행",
	ScheduledCoursesTable_Title_Date: "날짜[일]",
	CoursesTable_Title_Completed: "완료됨",
	Footer_TrainingProgram: "교육 프로그램",
	Breadcrumb_TrainingProgram: "교육 프로그램",
	TrainingProgram_Title_Table: "제목",
	TrainingProgram_TrainingProgram: "교육 프로그램",
	TrainingProgram_TargetGroup: "대상그룹",
	TrainingProgram_MaxLength: "최대길이",
	TrainingProgram_Information: "정보",
	TrainingProgram_Detail: "세부사항",
	TrainingProgram_Modules: "모듈",
	TrainingProgram_Course: "과정",
	TrainingProgram_PartNumber: "품목번호",
	TrainingProgram_Max_Length: "최대길이",
	TrainingProgram_Duration: "기간",
	TrainingProgram_Exam: "시험",
	TrainingProgram_Assign_Popup: "교육 프로그램 등록",
	TrainingProgram_Student: "학생",
	TrainingProgram_Assign: "배정",
	TrainingProgram_Popup_Remove_Title: "교육 취소?",
	TrainingProgram_Description: "설명",
	TrainingProgram_Days: "일수",
	TrainingProgram_Type: "유형",
	TrainingProgram_Action: "행위",
	TrainingProgram_Enrolled: "이 교육 프로그램에 이미 등록되었습니다.",
	TrainingProgram_SelfAssign_Enrolled: "이 교육 프로그램에 이미 지정되었습니다.",
	TrainingProgram_Warning: "다른 학생을 등록하거나 다른 교육 프로그램을 선택하십시오.",
	TrainingProgram_Workshop: "작업장",
	TrainingProgram_Durations: "연령",
	TrainingProgram_Duration_DaysLabel: "일수",
	TrainingProgram_HasExam_Yes: "예",
	TrainingProgram_HasExam_No: "아니오",
	AssignModal_SuccessModal_Header: "축하합니다!",
	AssignModal_SuccessModal_Message: "이 교육 프로그램에 성공적으로 등록되었습니다.",
	AssignModal_SelfAssign_SuccessModal_Message: "교육 프로그램에 성공적으로 배정되었습니다.",
	Dashboard_MyTraningProgram: "내 교육 프로그램",
	Dashboard_MyTraningProgram_Title: "제목",
	Dashboard_MyTraningProgram_Status: "상태",
	Dashboard_MyTraningProgram_Table_View: "보기",
	Dashboard_MyTraningProgram_Register: "등록하기",
	Dashboard_MyTraningProgram_Records: "이력 기록",
	Dashboard_MyTrainingProgram_In_Progress: "진행 중",
	Dashboard_MyTrainingProgram_Accredited: "공인됨",
	Dashboard_Program_Modal_Withdraw: "철회",
	Dashboard_Program_Modal_Grade: "등급",
	Dashboard_MyTrainingProgram_Registered: "등록됨",
	Dashboard_MyTrainingProgram_Completedd: "완료됨",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "예",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "아니오",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "철회는 개별 교육 과정뿐만 아니라 전체 교육 프로그램을 철회한다는 것을 의미합니다.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "여전히 교육 프로그램에서 탈퇴하시겠습니까?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "보류중",
	WithdrawProgramModal_Withdraw_Success_Message: "교육 프로그램에서 성공적으로 탈퇴했습니다!",
	Common_Warning_Title: "경고!",
	Common_Error_Title: "문제가 발생했습니다.",
	AddEventFromCatalogToCartModal_AddToCartButton: "장바구니에 담기",
	AddEventFromCatalogToCartModal_HoursText: "시간",
	AddElearningToCartSuccessModal_Message: "장바구니에 담았습니다!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "선택한 참가자가 이 과정에 등록되었거나 과정이 이미 카트에 추가되었습니다.",
	EventDetailTab_SubjectForChange_Message: "변경될 수 있음",
	Dashboard_MyTrainingProgram_Status_Failed: "불합격",
	Dashboard_MyTrainingProgram_Status_Passed: "합격",
	Dashboard_MyTrainingProgram_Status_Merit: "장점",
	Dashboard_MyTrainingProgram_Status_Distinction: "구별",
	Dashboard_Employee_Courses: "과정",
	Dashboard_Employee_Program: "교육 프로그램",
	Dashboard_Employee_WaitList: "대기 명단",
	Dashboard_Employee_Account: "계정",
	Course_Price_Free: "무료",
	Dashboard_Employee_Account_Update_Success_Message: "직원 정보를 성공적으로 업데이트했습니다!",
	Dashboard_Supplier: "공급자",
	Event_Detail_Comment: "현재 메시지가 없습니다",
	DownLoad_PDF: "PDF 다운로드",
	TrainingCatalogue_Currency: "통화",
	My_Wallet: "내 지갑",
	Wallet: "지갑",
	Credits_Balance: "크레딧 잔액",
	MyFullCalendar_Today: "오늘",
	Header_Available_Languages: "사용 가능한 언어",
	Header_CurrentRegion: "당신은 현재 이곳에 있습니다.",
	Header_ChangeRegions: "국가를 변경하시겠습니까?",
	Header_Anonymous: "정비 교육",
	Header_Anonymous_Login: "로그인/회원가입",
	Header_RegisterCourse: "과정 등록",
	Homepage_Start: "시작하기",
	Homepage_Login: "로그인",
	MainPage_Title: "궁극의 작업장 솔루션",
	MainPage_Content: "당신의 워크샵, 당신의 선택.",
	MainPage_ImageTitle: "Bosch Automotive \n 교육 솔루션",
	MainPage_IntroContent: "모든 유형의 차량에 대한 진단 절차, 문제 해결, 수리 및 서비스 내에서 현대적인 정비소가 전문적이고 안정적이며 효율적으로 작업할 수 있도록 합니다.",
	BoschNews_TrainingNews: "교육 소식",
	Service_Assist_Workshop: "정비소 서비스 지원으로 경험 향상",
	Enhance_Title_CDM3_v2: "앱으로 무엇을 할 수 있나요?",
	Enhance_Body_CDM3_Item_1: "코스 예약 및 학습 여정 유지",
	Enhance_Body_CDM3_Item_2: "교육 자료 및 과정 인증서 다운로드",
	Enhance_Body_CDM3_Item_3: "eLearning 과정 시작",
	Enhance_Body_CDM3_Item_4: "모바일 애플리케이션 내에서 다양한 자동차 서비스에 액세",
	Enhance_Body_CDM3_Item_5: "Visual Connect Pro를 통한 라이브 비디오 전송",
	Enhance_Body_CDM3_Item_6: "자동 차량 스캔을 위한 무료 도구",
	Training_Stories: "트레이닝 스토리",
	SettingsMyAccount_JoinCompanyButton: "회사 가입",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "회사 가입",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "아래에 활성화 코드를 입력하십시오",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "주의! 활성화 코드를 받으려면 회사 관리자에게 문의해야 합니다.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "활성화가 성공적으로 처리되었으며 이제 계정이 회사에 연결되었습니다.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "계속하기를 클릭하여 웹 사이트를 입력하십시오.",
	ContinueButton: "계속하기",
	WelcomeBatsModal_Title: "귀하의 계정은 현재 검토 중입니다!",
	WelcomeBatsModal_ActivationLink: "회사에 가입하기 위한 활성화 코드가 있으면 여기를 클릭하십시오!",
	WelcomeBatsModal_ActivationLinkNotes: "주의! 활성화 코드를 얻으려면 회사 관리자에게 문의해야 합니다.",
	AccountInReviewModal_Description_Paragraph1: "Bosch Automotive Training Solutions에 개인 계정을 등록해 주셔서 감사합니다.",
	AccountInReviewModal_Description_Paragraph2: "전담 교육 직원이 현재 귀하의 계정을 검토하고 구성하고 있습니다. 계정이 성공적으로 구성되면 메일로 최종 확인을 받게 됩니다.",
	AccountInReviewModal_CloseButton: "좋아요, 들어가게 해주세요!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "활성화 코드와 이메일 주소를 확인할 수 없습니다.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "다시 시도하거나 회사 관리자에게 도움을 요청하십시오.",
	TryAgain_Button: "다시 시도하기",
	Error_Title: "아차! 문제가 발생했습니다!",
	SettingsMyProfileActivationSuccessModal_Title: "성공!",
	AccountInReviewModal_Description_Paragraph3: "그동안 제한된 액세스로 저희 웹 사이트를 자유롭게 탐색하십시오.",
	AccountInReviewModal_Description_Paragraph4: "질문이 있거나 주말 및 공휴일을 제외한 48시간 이내에 계정이 처리되지 않으면 [여기]로 문의하십시오.",
	Support_Headline: "Bosch 자동차 교육 솔루션",
	Support_SubHeadline: "당신을 도울 몇 가지 지침들",
	Support_FAQ_Headline: "자주 묻는 질문",
	Support_FAQs_section1_title: "일반사항",
	Support_FAQs_section1_question1: "Bosch 교육 솔루션이란 무엇입니까?",
	Support_FAQs_section1_question1_answer1: "Bosch 교육 솔루션은 기술 교육에 대한 포괄적인 개요를 제공하고 교육을 예약 및 관리할 수 있는 플랫폼입니다.",
	Support_FAQs_section1_question2: "기존 Bosch 계정(singleKey ID)을 사용하여 Bosch Training Solutions에 로그인할 수 있습니까?",
	Support_FAQs_section1_question2_answer1: "예, \"My Bosch ID\"로 만든 모든 계정으로 액세스할 수 있습니다.",
	Support_FAQs_section1_question3: "Bosch 교육 솔루션용으로 만든 계정을 다른 Bosch 서비스 또는 애플리케이션과 함께 사용할 수 있습니까",
	Support_FAQs_section1_question3_answer1: "예, 전자 자전거 또는 스마트 홈과 같이 전문적으로나 개인적으로 다양한 다른 Bosch 애플리케이션에도 개인 Bosch ID를 사용할 수 있습니다.",
	Support_FAQs_section2_title: "교육",
	Support_FAQs_section2_question1: "어떻게 교육을 예약할 수 있습니까?",
	Support_FAQs_section2_question1_answer1: "기존 계정으로 로그인하거나 포털에 계정을 등록하십시오. \"교육 서비스\" 또는 \"이벤트 캘린더\"로 이동하여 교육을 선택하고 카드에 추가 버튼을 클릭하십시오.",
	Support_FAQs_section2_question2: "적합한 교육 날짜가 없습니다. 어떻게 하면 되는지요?",
	Support_FAQs_section2_question2_answer1: "날짜를 선택하지 않고 \"일반\" 대기자 명단에 이름을 올릴 수 있으며 새 교육이 제공되는 즉시 저희 팀에서 알려드리겠습니다.",
	Support_FAQs_section2_question3: "예약한 과정의 개요는 어디에서 찾을 수 있습니까?",
	Support_FAQs_section2_question3_answer1: "대시보드에 로그인하면 예약 및 완료한 모든 코스의 전체 개요를 확인할 수 있습니다.",
	Support_FAQs_section3_title: "설정",
	Support_FAQs_section3_question1: "이메일과 비밀번호는 어떻게 변경하나요?",
	Support_FAQs_section3_question1_answer1: "포털에 로그인하고 개인 프로필로 변경해야 합니다. (대시보드 내에서 프로필 정보를 찾거나 머리글에서 다음 버튼을 클릭하면",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "여기에서 Bosch ID의 세부 정보를 변경할 수 있습니다.",
	Support_FAQs_section3_question2: "비밀번호를 잊어버렸을 때 어떻게 해야 하나요?",
	Support_FAQs_section3_question2_answer1: "로그인/등록 버튼을 이용하여 이메일 주소를 입력해주세요.",
	Support_FAQs_section3_question2_answer2: "계속을 클릭하면 \"비밀번호 찾기\"를 클릭할 수 있습니다.",
	Support_FAQs_section3_question3: "워크숍 계정을 설정하는 방법은 무엇입니까?",
	Support_FAQs_section3_question3_answer1: "워크샵 역할을 수행하려면 지역 교육 관리 부서에 문의하십시오. 웹 사이트의 문의 양식을 사용할 수 있습니다.",
	LandingPage_Header_Title: "Bosch 자동차 교육 솔루션",
	LandingPage_ChooseCountry_Title: "국가/지역 선택",
	MaintenanceBanner_Message_1: "Bosch 자동차 교육 솔루션 포털이 [maintenance_schedule]에 업데이트됩니다. 업데이트는 30분 정도 소요될 것으로 예상됩니다. 업데이트 중에는 Portal을 사용할 수 없습니다.",
	MaintenanceBanner_Message_2: "\n추가 지원이 필요한 경우 이메일[support_email]로 문의하십시오.",
	MaintenancePage_Message_1: "Bosch 자동차 교육 솔루션 포털은 현재 업데이트 중입니다.",
	MaintenancePage_Message_2: "포털은 [maintenance_online_date] [maintenance_online_time]에 다시 사용할 수 있습니다.",
	MenuHeader_Support: "지원",
	Course_Detail_No_Prerequisites: "전제 조건 없음",
	TableCell_Hours: "시간",
	Dashboard_MenuItem_Dashboard: "데쉬보드",
	Dashboard_MenuItem_Curricula: "커리큘럼",
	Dashboard_MenuItem_Certificates: "인증서",
	Dashboard_MenuItem_Team: "팀",
	Dashboard_MenuItem_Workshops: "워크숍",
	Dashboard_MenuItem_External: "외부",
	Dashboard_MenuItem_Wallet: "지갑",
	Dashboard_MenuItem_Profile: "프로필",
	Dashboard_Headline_YourUpcomingCourses: "예정된 코스",
	Dashboard_Headline_YourELearningCourses: "eLearning 과정",
	Dashboard_Headline_TeamOverview: "팀 개요",
	Dashboard_Headline_YourOverview: "개요",
	Dashboard_Headline_YourWishlist: "당신의 위시리스트",
	Dashboard_Headline_ActivePrograms: "활성 프로그램",
	Dashboard_Headline_CompletedPrograms: "완료된 프로그램",
	Dashboard_Headline_YourCertificates: "귀하의 인증서",
	Dashboard_Headline_Transactions: "거래",
	Dashboard_Table_NoRecordsToDisplay: "표시할 레코드가 없습니다.",
	Dashboard_TableColumn_Location_Online: "온라인",
	Dashboard_TableColumn_Duration_Hours: "시간",
	Dashboard_ELearningCourses_TableHeader_Title: "제목",
	Dashboard_ELearningCourses_TableHeader_Duration: "기간(시간)",
	Dashboard_ELearningCourses_ActionButton_View: "보기",
	Dashboard_UpcomingCourses_TableHeader_Title: "제목",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "날짜[일]",
	Dashboard_UpcomingCourses_TableHeader_Location: "위치",
	Dashboard_UpcomingCourses_ActionButton_View: "보기",
	Dashboard_YourOverview_TableHeader_Title: "제목",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "날짜/기간",
	Dashboard_YourOverview_TableHeader_Location: "위치",
	Dashboard_YourOverview_TableHeader_Status: "상태",
	Dashboard_YourOverview_ActionButton_View: "보기",
	Dashboard_YourWishlist_TableHeader_Title: "제목",
	Dashboard_YourWishlist_TableHeader_Action: "활동",
	Dashboard_YourWishlist_ViewAllRecords: "모든기록보기",
	Dashboard_TeamOverview_TableHeader_Employee: "직원",
	Dashboard_TeamOverview_TableHeader_Title: "제목",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "날짜/기간",
	Dashboard_TeamOverview_TableHeader_Location: "위치",
	Dashboard_TeamOverview_TableHeader_Status: "상태",
	Dashboard_TeamOverview_ActionButton_View: "보기",
	Dashboard_TeamOverview_SearchBox_Placeholder: "검색하기",
	Dashboard_ActiveProgram_TableHeader_Title: "제목",
	Dashboard_ActiveProgram_TableHeader_Status: "상태",
	Dashboard_ActiveProgram_ActionButton_View: "보기",
	Dashboard_CompletedProgram_TableHeader_Title: "제목",
	Dashboard_CompletedProgram_TableHeader_Status: "상태",
	Dashboard_CompletedProgram_ActionButton_View: "보기",
	Dashboard_Certificates_TableHeader_Title: "제목",
	Dashboard_Certificates_TableHeader_Date: "날짜",
	Dashboard_Certificates_TableHeader_Status: "상태",
	Dashboard_Certificates_TableHeader_Action: "활동",
	Dashboard_Certificates_ActionButton_Download: "다운로드",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "영수증 번호",
	Dashboard_Transactions_TableHeader_Description: "설명",
	Dashboard_Transactions_TableHeader_PaymentMethod: "지불방법",
	Dashboard_Transactions_TableHeader_Date: "날짜",
	Dashboard_Transactions_TableHeader_Amount: "금액",
	Dashboard_Transactions_ActionButton_Download: "다운로드",
	Breadcrumb_MyTraining: "나의 교육",
	Breadcrumb_MyTraining_CourseDetails: "코스 상세 사항",
	Breadcrumb_MyTraining_Curricula: "커리큘럼",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "교육 프로그램 세부 사항",
	Breadcrumb_MyTraining_Team: "팀",
	Breadcrumb_MyTraining_Team_ViewEmployee: "직원 보기",
	MyTraining_Headerbar_CourseDetails: "코스 상세 사항",
	MyTraining_Headerbar_TrainingProgramDetails: "교육 프로그램 세부 사항",
	TrainingProgramDetails_Label_Status: "상태",
	TrainingProgramDetails_Label_OverallProgress: "전반적인 진행 상황",
	TrainingProgramDetails_ProgramItems_Headline: "프로그램 항목",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "제목",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "상태",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "등급",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "날짜",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "보기",
	TrainingProgramDetails_Status_InProgress: "진행중",
	TrainingProgramDetails_Status_Completed: "완료",
	CourseDetails_Label_Status: "상태",
	CourseDetails_Label_Duration: "기간",
	CourseDetails_Label_Trainer: "트레이너",
	CourseDetails_Label_Language: "언어",
	CourseDetails_Label_Score: "점수",
	CourseDetails_Label_StartDate: "시작일",
	CourseDetails_Label_EndDate: "종료일",
	CourseDetails_Label_Venue: "장소",
	CourseDetails_Label_Address: "주소",
	CourseDetails_Label_Grade: "등급",
	CourseDetails_Action_Withdraw: "철회하기",
	CourseDetails_Action_Evaluate: "코스 평가",
	CourseDetails_Action_Evaluate_Description: "인증서를 생성하려면 이 과정을 평가하십시오!",
	CourseDetails_Action_DownloadCertificate: "인증서 다운로드",
	CourseDetails_Action_JoinWebcast: "웹캐스트 참여",
	CourseDetails_Action_StartCourse: "코스 시작",
	CourseDetails_Action_RestartCourse: "과정 다시 시작",
	CourseDetails_Action_RepeatCourse_Description: "이 과정을 반복하고 통과하여 인증서를 다운로드하십시오!",
	CourseDetails_PartSpecificDetails_Headline: "품목별 세부 정보",
	CourseDetails_PartSpecificDetails_Tab_Part: "품목",
	CourseDetails_PartSpecificDetails_Description: "설명",
	CourseDetails_PartSpecificDetails_StartDate: "시작일",
	CourseDetails_PartSpecificDetails_EndDate: "종료일",
	CourseDetails_PartSpecificDetails_StartTime: "시작시간",
	CourseDetails_PartSpecificDetails_EndTime: "종료시간",
	CourseDetails_PartSpecificDetails_Method: "방법",
	Dashboard_Team_TableHeader_Name: "이름",
	Dashboard_Team_TableHeader_Role: "역할",
	Dashboard_Team_TableHeader_Email: "이메일",
	Dashboard_Team_TableHeader_PersonalAccount: "개인계정",
	Dashboard_Team_ActionButton_Select: "선택",
	Dashboard_Team_PersonalAccount_Activated: "활성화됨",
	Dashboard_Team_PersonalAccount_NotActivated: "활성화되지 않음",
	Dashboard_Team_ActionButton_AddMember: "구성원 추가",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "검색하기",
	Dashboard_External_TableHeader_Name: "이름",
	Dashboard_External_ActionButton_AddNew: "새로 더하다",
	Dashboard_External_Headline: "승인된 외부 액세스",
	Dashboard_Workshops_Headline: "워크숍",
	Dashboard_Workshops_TableHeader_Company: "회사",
	Dashboard_Workshops_TableHeader_Address: "주소",
	Dashboard_Workshops_TableHeader_Postcode: "우편번호",
	Dashboard_Workshops_TableHeader_City: "도시",
	Dashboard_Workshops_ActionButton_Select: "선택하기",
	Wallet_ServiceCredits: "서비스 크레딧",
	Wallet_Headline_CreditBalance: "크레딧 잔액",
	Wallet_Headline_CreditActivity: "크레딧 활동",
	Wallet_Headline_BillingTransactions: "결제 거래",
	Wallet_BillingTransaction_TableHeader_Order: "주문",
	Wallet_BillingTransaction_TableHeader_Date: "날짜",
	Wallet_BillingTransaction_TableHeader_Amount: "금액",
	Wallet_BillingTransaction_TableHeader_Action: "활동",
	Wallet_CreditActivity_TableHeader_Date: "날짜",
	Wallet_CreditActivity_TableHeader_Description: "설명",
	Wallet_CreditActivity_TableHeader_User: "사용자",
	Wallet_CreditActivity_TableHeader_Amount: "금액",
	FooterAdditionalLinks_Copyright: "© 로버트 보쉬, 판권 소유",
	FooterAdditionalLinks_Navigation_ProductSecurity: "제품 보안(PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "혁신, 특허 및 라이선스",
	FooterAdditionalLinks_Navigation_Logistics: "구매 및 물류",
	MenuHeader_MyTraining: "내 훈련",
	MenuHeader_TrainingServices: "교육 서비스",
	MenuHeader_OurLocations: "위치정보",
	MenuHeader_ContactsUs: "연락처",
	MenuHeader_Logout: "로그 아웃",
	MenuHeader_FAQ: "자주하는 질문",
	MyProfile_MyBoschId: "내 Bosch ID",
	MyProfile_AccountDeletion: "계정 삭제",
	MyProfile_Edit: "이메일 변경 또는 비밀번호 수정",
	MyProfile_Profile: "나의 보쉬",
	MyProfile_EditUserProfile: "사용자 프로필 수정",
	BackButton: "뒤로",
	ContactUs_Email_DataProtection1_kr: "개인정보 수집 및 이용에 동의합니다.",
	ContactUs_Email_DataProtection2_kr: "로버트보쉬코리아는 아래와 같이 귀하의 개인정보를 수집 및 이용하며, 원활한 응대를 위하여 담당자(로버트보쉬코리아 담당부서 또는 국내/외 보쉬 계열 법인)에게 제공 됩니다.",
	ContactUs_Email_DataProtection3_kr: "1. 수집 및 이용 목적: 문의 사항 응대",
	ContactUs_Email_DataProtection4_kr: "2. 처리하는 개인정보 항목: \n [필수] 이름, 국가, 이메일 주소, 문의 내용 \n [선택] 회사명",
	ContactUs_Email_DataProtection5_kr: "3. 개인정보 보유 및 이용 기간: 6개월",
	ContactUs_Email_DataProtection6_kr: "4. 제공받는자: 보쉬 독일 본사 (Robert Bosch GmbH) 및 국내/외 보쉬 계열 법인 [(https://www.bosch.com/websites-worldwide/)]",
	ContactUs_Email_DataProtection7_kr: "귀하는 위와 같은 개인정보의 수집, 이용을 거부할 수 있습니다. 그러나 필수 개인정보의 수집,이용에 동의하지 않을 경우, 이메일 문의 접수 및 응대가 불가능합니다.",
	ContactUs_Email_DataProtection_kr: "이용 약관을 확인하세요. *",
	ContactUs_Phone_Description_V3: "귀하의 전화를 기다리며 기꺼이 도와드리겠습니다. 연중무휴 24시간 대기하고 있습니다.",
	ContactUs_Email_SecurityCodeConfirm_V3: "위의 상자에 표시된 대로 올바른 문자를 입력하십시오.",
	ContactUs_Email_MandatoryNotice_V3: "필수 필드를 모두 채우십시오",
	ContactUs_Email_SendButton: "보내기",
	Breadcrumb_TrainingCenters: "교육 센터",
	Training_Centres_Book_Btn: "코스 예약",
	Training_centres_booking_code: "예약 코드",
	Training_centers_header_bar_title: "교육 센터",
	Training_centers_filter_item: "센터",
	TeamEdit_PersonalAccountToolTop: "이 기능을 활성화하면 직원이 이 웹 사이트에 액세스할 수 있습니다. 사용자는 개인 계정을 만들고 회사 계정의 일부가 될 수 있습니다. 활성화되면 활성화 코드가 포함된 초대를 직원에게 보낼 수 있습니다. 초대는 이메일 주소로 전송됩니다.",
	TeamEdit_Update: "회사 관리자 기능을 활성화하면 이 직원에게 회사 계정에 대한 전체 액세스 권한이 자동으로 부여됩니다. 이 기능을 활성화하려면 사용자가 이미 로그인한 경우 로그아웃했다가 웹 사이트에 다시 로그인해야 합니다.",
	TeamEdit_PersonalAccount: "개인 Bosch Training Solutions 웹 계정 허용",
	TeamEdit_SendInvitation: "초대장 보내기",
	TeamEdit_Deactivate: "비활성화",
	TeamEdit_Telephone: "전화번호",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "모바일번호는 숫자여야 합니다.",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "전화번호는 숫자여야 합니다.",
	TeamEdit_Activate: "활동적인",
	TeamEdit_NotActivated: "활성화하지 않음",
	TeamEdit_StatusPopup: "사용자가 개인 계정을 성공적으로 생성한 경우 상태가 '활성'으로 표시됩니다. 상태가 '활성화되지 않음'으로 설정되어 있으면 사용자가 초대를 완료하지 않았음을 의미합니다.",
	Training_Catalog_Courses: "과정",
	Training_Catalog_Course: "과정",
	Reset_Filter: "필터 재설정",
	TrainingCatalogue_CourseCatalogue: "강좌 카탈로그",
	CheckoutHeader_CartItemCount_Text: "장바구니에 [count]개의 항목이 있습니다.",
	CheckoutHeader_CartItemsCount_Text: "장바구니에 [count]개의 항목이 있습니다.",
	ShoppingCart_PriceItem: "항목 가격",
	ShoppingCartItemParticipants_AddParticipant_Button: "참가자 추가",
	ShoppingCartTotalPrice_SubTotalLabel: "소계",
	ShoppingCartTotalPrice_TaxesLabel: "세금",
	ShoppingCartTotalPrice_TotalLabel: "총액",
	ShoppingCartTotalPrice_IncludedVATLabel: "[vat_amount]의 VAT 포함",
	AddVoucher_AvailablePaymentMethods: "사용 가능한 온라인 결제 수단",
	ShoppingCartItemRow_VenueFeeLabel: "참가자당 행사장 수수료[fee_amount]",
	ShoppingCartItemRow_OnlineLabel: "온라인",
	AddParticipantsModal_Title: "참가자 추가",
	AddParticipantsModal_SelectEmployeeLabel: "참가자 추가",
	AddParticipantsModal_SelectWorkshopLabel: "워크숍 선택",
	ShoppingCartTotalPrice_ContinueButtonText: "체크아웃 계속",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "참가자가 추가되지 않았습니다!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "장바구니에 참가자가 없는 코스가 하나 이상 있습니다.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "결제를 계속하려면 참가자를 추가하세요.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "참가자 편집 ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "이름",
	AddParticipantsModal_ParticipantTable_Email: "이메일",
	AddParticipantsModal_ParticipantTable_Action: "활동",
	AddParticipantsModal_ParticipantTable_NoParticipant: "참가자 없음…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "선택한 참가자에 대해 구성되지 않은 이메일 주소",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "선택한 참가자의 프로필에 전자 메일 주소가 구성되어 있지 않습니다. 이메일 주소는 교육 과정 등록을 위해 필수입니다.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "계속하려면 이메일 주소를 추가하고 다른 참가자를 선택하려면 취소하십시오.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "이메일 추가",
	CancelButton: "취소",
	AddParticipantsModal_AddEmployeeButtonText: "직원 추가",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "귀하는 장바구니에서 과정을 삭제하려고 합니다.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "귀하는 카트에서 참가자가 추가된 코스를 제거하려고 합니다.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "계속하려면 \"계속\"을 클릭하고 \"취소\"를 클릭하십시오.",
	ShoppingCartItemHeader_Participants: "참가자들",
	ShoppingCartItemHeader_ParticipantPrice: "좌석당 가격",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "[currency]로 표시",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "크레딧 표시",
	ShoppingCart_CreditsPriceUnit: "크레딧",
	CheckOutHeader_Step1_Question: "어떻게 지불 하시겠습니까?",
	CheckOutHeader_Step2_Question: "당신의 주문을 검토하세요",
	CheckOutHeader_Step3_Question: "주문 완료",
	CheckOutHeader_Step4_Question: "예약이 완료되었습니다!",
	CheckOutHeader_Step_Payment: "지불",
	CheckOutHeader_Step_Review: "검토",
	CheckOutHeader_Step_Place_Order: "주문하기",
	CheckOutRightPanel_Review_Order_Btn: "주문검토",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "주문하기",
	CheckoutRightPanelContent_OrderSummary_Title: "주문 요약",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "장바구니 수정",
	ShoppingCart_Participants: "참가자들",
	CheckOutStepPayment_Payment_Methods_Title: "결제 수단 선택",
	CheckOutStepPayment_Credit_Title: "대변 ​​또는 직불",
	CheckOutStepPayment_Wholesaler_Title: "도매상",
	CheckOutStepPayment_ServiceCredit_Title: "서비스 크레딧",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "귀하는 [available_credit] 크레딧이 있습니다.",
	CheckOutStepPayment_BoschAccount_Title: "보쉬 계정",
	Checkout_Second_PaymentMethod_Headline: "지불 방법",
	CheckOutStepReview_Edit: "수정",
	CheckOutStepReview_Terms_Label: "이용약관 및 취소 정책",
	CheckOutStepReview_Terms_Text: "본인은 판매 [약관 및 취소 정책]을 읽고 이해했으며 이에 동의합니다.",
	SelectSecondPaymentMethodModal_Title: "보조 결제 수단 선택",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "지불[amount]",
	StepPaymentWholesaler_Title: "도매업체 선택",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "이용약관 동의",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "계속하기 전에 이용 약관 및 취소 정책을 읽고 동의하십시오.",
	CheckoutDescription_ThankYou: "Bosch 자동차 교육 솔루션을 주문해 주셔서 감사합니다!",
	CheckoutDescription_Message: "우리는 이미 귀하의 주문을 처리하기 시작했습니다. 그동안 잠시 기다려 주시기 바랍니다.",
	CheckoutDesctiption_Text: "예약에 대한 모든 세부 정보가 포함된 최종 확인서를 메일로 보내드립니다.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "귀하는 선택한 워크샵을 제거하려고 합니다.",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "선택한 워크샵을 제거하면 추가된 모든 참가자가 재설정됩니다. 이 변경 사항은 장바구니에 있는 모든 과정에 영향을 미칩니다.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "계속하려면 \"계속\"을 클릭하고 이 작업을 중지하려면 \"취소\"를 클릭하십시오.",
	CheckOutStepPayment_Insufficient_Credit_Message: "크레딧이 부족합니다. 귀하의 크레딧 잔액은 [available_credit] 크레딧이지만 결제를 계속하려면 [cart_total_credits] 크레딧이 필요합니다. 다른 결제 방법을 선택하거나 장바구니에서 항목을 제거하십시오.",
	Training_centers_filter_items: "센터",
	Training_Programs_Filter_Item: "프로그램",
	Training_Program_Filter_Item: "프로그램",
	Training_Program_Year: "연령",
	Training_Program_Detail_Title: "교육 과정 프로그램",
	AccountButton_Workshops_MenuItem: "워크숍",
	AccountButton_Wallet_MenuItem: "지갑",
	Header_MyBoschProfile_Header: "마이보쉬 프로필",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "사용 가능",
	AddParticipantsModal_AddOrEditParticipantsTitle: "참가자 추가/수정",
	ShoppingCart_CreditPriceUnit: "크레딧",
	Common_IncludedVAT: "부가세 포함",
	TrainingCatalogue_Length: "길이",
	TrainingCatalogue_Method: "방법",
	Course_Price_Credits: "크레딧",
	Catalogue_Grid_Register_Button: "등록",
	Catalogue_Credit_Tilte: "크레딧 기준 가격을 원하시면 여기를 클릭하세요",
	Catalogue_Price_Tilte: "유로 기준 가격을 원하시면 여기를 클릭하세요",
	Catalogue_Grid_Vat: "부가세 별도",
	EventCalendar_Seats: "좌석",
	MenuHeader_Calendar: "달력",
	MenuHeader_Catalog: "카탈로그",
	TableCell_Minutes: "분",
	ListFilter_NoResult: "결과없음",
	Course_Description: "과정설명",
	Training_course_detail: "소셜 미디어 기본 사항",
	Training_course_detail_add_waiting: "전체 대기목록에 추가",
	Register_now_btn: "지금 등록",
	Register_now_btn_add_event_to_cart: "지금 등록",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "모든 기록 보기",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "적게 보기",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "모든 기록 보기",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "적게 보기",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "모든 기록 보기",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "적게 보기",
	TeamCreate_TitleError: "제목은 필수 항목입니다.",
	Dashboard_Headerbar_BookCourse: "코스 예약",
	Dashboard_Headerbar_ExitProxy: "프록시 종료",
	Dashboard_Headerbar_EvaluateCourse: "코스 평가",
	Dashboard_Headerbar_EvaluateCourse_Note: "인증서를 생성하려면 이 과정을 평가하십시오!",
	Dashboard_Headerbar_DownloadCertificate: "인증서 다운로드",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "철회",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "철회",
	Dashboard_Headerbar_WelcomeMessage: "학습 대시보드에 오신 것을 환영합니다",
	Dashboard_Headerbar_ProxingAs: "프록시",
	Dashboard_TrainingProgram_Status_Completed: "완료",
	Dashboard_TrainingProgram_Status_InProgress: "진행중",
	External_SelectWholesalerModal_Title: "외부 사용자 액세스 추가",
	External_SelectWholesalerModal_Company_Label: "회사",
	External_SelectWholesalerModal_Outlet_Label: "아웃렛",
	External_SelectWholesalerModal_Company_Placeholder: "선택해주세요",
	External_SelectWholesalerModal_Outlet_Placeholder: "선택해주세요",
	External_SelectWholesalerModal_ApplyButton: "적용",
	External_SelectWholesalerModal_CancelButton: "취소",
	External_RemoveWholesalerModal_ConfirmLabel: "확인",
	External_SelectWholesalerModal_ConfirmMessage: "이 업체를 삭제하시겠습니까?",
	External_SelectWholesalerModal_YesButton: "예",
	External_SelectWholesalerModal_NoButton: "아니오",
	Training_program_team_member: "팀 구성원",
	Training_program_assign_training_program: "교육 프로그램 지정",
	Assign_modal_student_confirmation_text1: "당신의 학습 일정에 [교육 프로그램 이름]을(를) 할당하려고 합니다.",
	Assign_modal_student_confirmation_text2: "계속하시겠습니까?",
	MyFullCalendar_Jan: "1월",
	MyFullCalendar_Feb: "2월",
	MyFullCalendar_Mar: "3월",
	MyFullCalendar_Apr: "4월",
	MyFullCalendar_May: "5월",
	MyFullCalendar_Jun: "6월",
	MyFullCalendar_Jul: "7월",
	MyFullCalendar_Aug: "8월",
	MyFullCalendar_Sep: "9월",
	MyFullCalendar_Oct: "10월",
	MyFullCalendar_Nov: "11월",
	MyFullCalendar_Dec: "12월",
	MenuHeader_CourseCatalog: "교육과정 카탈로그",
	MenuHeader_Event: "교육일정",
	MenuHeader_Program: "교육프로그램",
	MenuHeader_Services: "교육서비스",
	MenuHeader_Log_out: "로그아웃",
	Filter_Location: "위치",
	TeamEdit_UpgradeToCompanyAdministrator: "회사 관리자로 업그레이드",
	TeamEdit_PersonalWebAccountStatusIs: "개인 웹 계정 상태는",
	Header_CreateNewEmployee: "새 직원 생성하기",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "가용한 좌석이 없습니다!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "선택한 이벤트에 사용 가능한 좌석이 없습니다.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "계속하려면 다른 이벤트를 선택하거나 이미 추가된 참가자를 제거하십시오.",
	Common_SuccessModal_Title: "축하합니다!",
	TrainingProgramDetails_Merit_StatusText: "장점",
	TrainingProgramDetails_Resit_StatusText: "반복하기",
	EmployeeProfile_UserExistErrorMessage: "계정이 이미 존재합니다!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "결제 실패. 다시 시도하거나 다른 결제 수단을 사용하세요.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "선택한 직원을 성공적으로 비활성화했습니다!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "저장완료!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "변경 사항이 저장되었습니다.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "eLearning 과정은 철회할 수 없습니다. 자세한 내용이 필요한 경우 당사에 문의하십시오.",
	Dashboard_Team_PersonalAccount_Pending: "보류중",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "직원이 생성되었습니다!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "직원 생성이 성공적으로 완료되었습니다.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "이 설정을 사용하면 직원이 교육 이벤트에 등록하는 것을 제한할 수 있습니다. 이 설정을 선택하지 않으면 회사 관리자만 직원을 교육 이벤트에 등록할 수 있습니다.",
	Training_catalog_sort_by: "정렬 기준",
	CourseDetail_EvaluationRestriction_Title: "교육 평가가 불가능합니다!",
	CourseDetail_EvaluationRestriction_Message: "교육 평가는 학생 본인만 제출할 수 있습니다. \n학생에게 Bosch Automotive Training Solutions에 로그인하여 교육 평가를 완료하도록 지시하십시오.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "학생에게 아직 계정이 없는 경우 팀 구성원의 사용자 프로필 설정에서 초대를 공유할 수 있습니다.",
	Breadcrumb_Download_Page: "다운로드",
	Download_Table_Category_Cell: "카테고리",
	Download_Table_Title_Cell: "제목",
	Download_File_Filter_Item: "파일",
	Download_File_Filter_Items: "파일",
	Download_Header: "다운로드 영역",
	DownloadArea_Button_Download: "다운로드",
	Popup_Title_Error: "애러",
	Popup_Title_Success: "성공",
	Popup_Title_Info: "정보",
	Popup_Title_Warning: "경고!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "다음 이벤트 등록은 이미 존재하며 교육 프로그램을 탈퇴해도 자동으로 취소되지 않습니다. 개별적으로 취소해 주시기 바랍니다.",
	TrainingProgram_PDF_Export: "PDF 내보내기",
	TrainingProgram_PDF_Export_Participant: "참가자:",
	TrainingProgram_PDF_Export_Status: "상태:",
	TrainingProgram_PDF_Export_Progress: "전체 진행 상황:",
	TrainingProgram_PDF_Export_TableHeader_Title: "교육명",
	TrainingProgram_PDF_Export_TableHeader_Status: "상태",
	TrainingProgram_PDF_Export_TableHeader_Grade: "등급",
	TrainingProgram_PDF_Export_TableHeader_Date: "날짜",
	AccountButton_CompanyChange_MenuItem: "회사 변경",
	SelectCompanyModal_Title: "회사 선택",
	CloseButton: "닫기",
	Change_Button: "변경",
	ChangeManagerModal_Content: "사용자 선택",
	ChangeCompanyModal_HeadOffice: "(본점)",
	SelectCompanyModal_Content: "로그인하려면 회사를 선택하세요…",
	AlertSuccess_Title: "성공",
	CheckOutStepPayment_SecondaryPayment_Title: "보조 결제 수단 선택",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "보조 결제 수단 추가",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "아래에 보조 결제 수단을 추가하거나 위에서 다른 결제 수단을 선택하세요.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "귀하의 계정에 장바구니 품목 비용을 지불할 수 있는 서비스 크레딧이 부족합니다. 선택한 결제 방법을 계속 사용하려면 추가 서비스 크레딧을 획득하거나 추가 결제 방법을 사용하여 차액을 결제해야 합니다. 차액은 [difference_amount](gst 제외)입니다.",
	ShoppingCartTotalPrice_TotalInCredit: "총 크레딧 가격",
	ShoppingCartTotalPrice_YourAvaliableCredit: "사용 가능한 크레딧",
	ShoppingCartTotalPrice_YourDifference: "차이",
	ShoppingCartTotalPrice_SubtotalExTax: "소계, GST 제외",
	ShoppingCartTotalPrice_SubtotalInTax: "소계, GST 포함",
	CheckOutStepPayment_Second_Payment_Methods_Title: "보조 결제 수단",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "결제 수단 수정",
	Checkout_Second_PaymentMethod: "보조 결제 수단",
	Breadcrumb_TrainingCourseDetails: "교육과정 상세",
	DownloadArea_FailedToDownload_ErrorMessage: "문서 다운로드에 실패했습니다. 다시 시도하거나 회사 관리자에게 도움을 요청하십시오.",
	DownloadArea_FileNotAvailable_ErrorMessage: "회사 관리자에게 도움을 요청하십시오.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "파일 다운로드가 금지되어 있습니다. 다시 시도하거나 회사 관리자에게 도움을 요청하십시오.",
	SSO_Redirecting_Message: "Bosch 포털로 리디렉션 중입니다..",
	SSO_Redirecting_ParameterErrorMessage: "국가 및/또는 언어가 지정되지 않았습니다. 다시 시도하거나 회사 관리자에게 도움을 요청하십시오.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "등록 요청이 수락되지 않았습니다.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "이는 하나 이상의 강좌에 대한 강좌 등록 기준일 내에 있기 때문일 수 있습니다. 문의사항이나 요청사항이 있으시면 '문의하기'를 이용해 주시기 바랍니다.",
	TimeSlotPicker_DateFormat_Title: "날짜 형식",
	UpdateAccountSettingsConfirmationModal_Title: "날짜 형식을 변경하려고 합니다!",
	UpdateAccountSettingsConfirmationModal_Message1: "날짜 형식은 선택 사항에 따라 표시되며 언제든지 변경할 수 있습니다.",
	UpdateAccountSettingsConfirmationModal_Message2: "계속하시겠습니까?",
	ProfileSummary_Settings_Title: "설정",
	UpdateAccountSettings_DateFormat_Title: "날짜 형식 설정",
	UpdateAccountSettings_DateFormat_ErrorMessage: "날짜 형식을 업데이트하지 못했습니다.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "날짜 형식 설정이 성공적으로 업데이트되었습니다.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "인증서 다운로드",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "교육 프로그램 인증서",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "다운로드에 실패했습니다. 다시 시도해 주세요. 문제가 지속되면 문의 양식을 통해 문의해 주세요.",
	CourseWithdrawalModal_PendingWithdraw_Title: "철회 기준을 초과했습니다.",
	CourseWithdrawalModal_PendingWithdraw_Message1: "이 과정에서 철회할 수 있는 임계값이 초과되었으며 교육 관리국에서 수동으로 처리해야 합니다.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "최종 확인서는 이메일로 발송됩니다.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "자세한 내용은 [취소 정책]을 참조하시기 바랍니다.",
	TrainingCourse_Course_NotAvailable_Title: "교육 과정을 이용할 수 없습니다.",
	TrainingCourse_Course_NotAvailable_Message: "교육과정을 이용할 수 없습니다. 다시 시도하거나 회사 관리자에게 문의하여 추가 지원을 받으십시오.",
	TrainingCourse_NotAvailable_Message_1: "귀하는 사용할 수 없는 교육 과정을 보려고 합니다.",
	TrainingCourse_NotAvailable_Message_2: "사용 가능한 모든 과정을 찾으려면 [교육 카탈로그]로 돌아가세요.",
	LanguageSelection_SelectALanguage: "언어 선택",
	LanguageSelection_SelectYourCountry: "국가를 선택하세요",
	Checkout_PaymentMethod_Headline: "결제수단",
	Download_Categories_Catalogue: "목록",
	Download_Categories_Calendar: "달력",
	Download_Categories_General: "일반",
	Download_Categories_Information: "정보",
	Download_Categories_Userguides: "사용자 가이드",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "취소 정책",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "취소",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "계속하기",
	Duration_Seconds: "초",
	Duration_Day: "일",
	Duration_Hour: "시간",
	Duration_Minute: "분",
	Duration_Second: "초",
	EventCalendar_ExternalLink_Tooltips: "[externalRegistrationName]을(를) 통해 구매",
	EventCalendar_ExternalLink_Seat_NA: "해당 없음",
	EventCalendar_ExternalLink_Cancel: "취소",
	EventCalendar_ExternalLink_Continue: "계속하다",
	EventCalendar_ExternalLinkModal_Title: "귀하는 리디렉션되려고 합니다…",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "선택한 이벤트는 [externalRegistrationName]을(를) 통해 판매되고 있습니다.",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "계속하면 외부 웹사이트로 리디렉션됩니다.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "계속하시겠습니까?",
	Team_ContactPerson_FirstName_Tooltips: "학생 이름은 Bosch 교육 관리팀에서만 수정할 수 있습니다. 추가 지원을 받으려면 이 웹사이트의 문의 양식을 통해 문의해 주세요.",
	Team_ContactPerson_LastName_Tooltips: "학생 이름은 Bosch 교육 관리팀에서만 수정할 수 있습니다. 추가 지원을 받으려면 이 웹사이트의 문의 양식을 통해 문의해 주세요.",
	Withdraw_ExternalLink_Cancel: "취소",
	Withdraw_ExternalLink_Confirm: "확인",
	Withdraw_ExternalLinkModal_Title: "귀하는 리디렉션되려고 합니다…",
	Withdraw_ExternalLinkModal_Message: "이 과정의 철회는 외부 제공업체에 의해 처리됩니다",
	Withdraw_ExternalLinkModal_Confirmation: "\"확인\"을 클릭하면 새 탭이 열리고 외부 웹사이트로 리디렉션됩니다.",
	Button_AddToWaitingList_Tooltips: "대기자 명단에 추가",
	Button_AddToCart_Tooltips: "장바구니에 추가",
	Button_TrainingCourseDetail_Tooltips: "세부정보 보기",
	Button_TrainingProgramDetail_Tooltips: "세부정보 보기",
	Button_AssignTrainingProgram_Tooltips: "교육 프로그램 지정",
	Button_DeleteFromCart_Tooltips: "장바구니에서 삭제",
	Button_RemoveParticipant_Tooltips: "참가자 제거",
	Button_DownloadBillingTransaction_Tooltips: "다운로드",
	Button_RemoveExternal_Tooltips: "삭제",
	Breadcrumb_TrainingServices: "교육 과정",
	Breadcrumb_Support: "지원",
	Breadcrumb_FAQs: "자주 묻는 질문",
	Breadcrumb_TrainingProgramDetail: "교육 프로그램 세부정보",
	Breadcrumb_MyTraining_MyTrainingEvents: "내 교육 과정",
	Breadcrumb_MyTraining_External: "외부",
	Breadcrumb_MyTraining_Wallet: "지갑",
	Breadcrumb_MyTraining_Workshop: "워크숍",
	Breadcrumb_MyTraining_Workshop_Team: "팀",
	Breadcrumb_MyTraining_Team_Profile: "프로필",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "프로필 편집",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "직원 생성",
	SideNavigation_Title: "교육 솔루션",
	SideNavigation_AccountButton_RegisterOrLogin: "등록 / 로그인",
	SideNavigation_Language: "언어",
	SideNavigation_SelectLanguage: "언어 선택",
	SideNavigation_AccountButton_Logout: "로그아웃",
	SideNavigation_ConntactUs: "문의하기",
	FooterAdditionalLinks_Navigation_CorporateInformation: "기업정보",
	FooterAdditionalLinks_Navigation_LegalNotice: "법적 고지",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "데이터 보호 고지",
	FooterAdditionalLinks_Navigation_PrivacySettings: "개인정보 보호 설정",
	FooterAdditionalLinks_Navigation_TermAndCondition: "이용약관",
	Common_Version_Text: "버전: {{버전}}",
	Content_Header_Dashboard_MyTrainingEvents: "내 교육 과정",
	Content_Header_Dashboard_MyTrainingPrograms: "내 교육 프로그램",
	Content_Header_Dashboard_Team: "팀",
	Content_Header_Dashboard_Workshops: "워크숍",
	Content_Header_Dashboard_External: "외부",
	Content_Header_Dashboard_Wallet: "지갑",
	Content_Header_Dashboard_MyProfile: "내 프로필",
	RedeemVoucher_RemoveButton: "제거하다",
	RedeemVoucher_ApplyButton: "적용하다",
	Checkout_VoucherApplied_Label: "귀하의 코드",
	RedeemVoucherModal_InvalidVoucherModal_Title: "유효하지 않은 바우처 코드",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "적용된 바우처 코드가 유효하지 않습니다.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "다시 시도하거나 바우처 코드 없이 계속하세요.",
	RedeemVoucherModal_CancelButton: "취소",
	RedeemVoucherModal_TryAgainButton: "다시 시도하세요",
	CreditsConfirmationModal_ContinueButton: "계속하다",
	CreditsConfirmationModal_CancelButton: "취소",
	CreditsConfirmationModal_Title: "바우처가 적용되었습니다",
	CreditsConfirmationModal_Message_1: "장바구니에 할인 바우처를 적용한 경우 서비스 크레딧으로 결제할 수 없습니다.",
	CreditsConfirmationModal_Message_2: "돌아가려면 \"취소\"를 클릭하고 장바구니에서 바우처를 제거하려면 \"계속\"을 클릭하세요."
};