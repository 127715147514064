import { ContactPersonModel } from "../models/ContactPerson.Model";

export type EmployeeStatusuType = "active" | "inactive" | "pending";

export const isElearning = (courseId: number, eventId: number) => {
  return courseId === eventId;
};

export const useIsELearning = () => {
  return isElearning;
};
