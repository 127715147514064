import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAccount,
  selectUserRoleId,
} from "../redux-toolkit/accountReducer";
import { Constants } from "../helpers/Constants";
import {
  selectFullEmployeeName,
  selectProxyMode,
  selectSelectedWorkshop,
  selectWholesalerProxyMode,
} from "../redux-toolkit/dashboardEmployeeReducer";
import { useTranslation } from "react-i18next";

export const useMyDashboard = () => {
  const account = useSelector(selectAccount);

  const proxyMode = useSelector(selectProxyMode);
  const wholesalerProxyMode = useSelector(selectWholesalerProxyMode);
  const selectedEmployee = useSelector(selectFullEmployeeName);
  const selectedWorkshop = useSelector(selectSelectedWorkshop);
  const role = useSelector(selectUserRoleId);
  const { t } = useTranslation();
  

  const [headerBarMessage, setHeaderBarMessage] = useState("");
  const [isProxyMode, setIsProxyMode] = useState(false);

  useEffect(() => {
    
    if (role === Constants.USER_ROLE_MANAGER_ID) {
      if (proxyMode) {
        setHeaderBarMessage(
          `${t("Dashboard_Headerbar_ProxingAs")} ${selectedEmployee ?? ""}`
        );
      } else {
        setHeaderBarMessage(
          `${t("Dashboard_Headerbar_WelcomeMessage")} ${
            account?.employee?.firstName
              ? account?.employee?.firstName + "!"
              : ""
          }`
        );
      }
    }

    if (role === Constants.USER_ROLE_WHOLESALER_ID) {
      if (wholesalerProxyMode) {
        if (proxyMode) {
          setHeaderBarMessage(
            `${t("Dashboard_Headerbar_ProxingAs")} ${selectedEmployee ?? ""}`
          );
        } else {
          setHeaderBarMessage(
            `${t("Dashboard_Headerbar_ProxingAs")} ${
              selectedWorkshop ? selectedWorkshop?.workshopName : ""
            }`
          );
        }
      } else {
        setHeaderBarMessage(
          `${t("Dashboard_Headerbar_WelcomeMessage")} ${
            account?.employee?.firstName
              ? account?.employee?.firstName + "!"
              : ""
          }`
        );
      }
    }

    if (role === Constants.USER_ROLE_INDIVIDUAL_ID) {
      setHeaderBarMessage(
        `${t("Dashboard_Headerbar_WelcomeMessage")} ${
          account?.employee?.firstName ? account?.employee?.firstName + "!" : ""
        }`
      );
    }
  }, [account, proxyMode, role, selectedEmployee, selectedWorkshop, t, wholesalerProxyMode]);

  useEffect(() => {
    setIsProxyMode(proxyMode || wholesalerProxyMode);
  }, [proxyMode, wholesalerProxyMode]);

  return { headerBarMessage, isProxyMode };
};
