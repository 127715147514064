import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RequestGetBoschNews } from "../models/news/request.GetNews";
// import { http } from "../services/HttpService";
import { httpNoneSecure } from "../services/HttpServiceNoneSecure";
import { AppDispatch, RootState } from "./store";

interface newState {
  newsContent: string | null;
}

const initialState: newState = {
  newsContent: null,
};

export const getNewsAsync = createAsyncThunk<
  null,
  RequestGetBoschNews,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("News", async (req: RequestGetBoschNews, thunkApi) => {
  try {
    const model = {
      ciamId: req.ciamId,
      ciamEmail: req.ciamEmail,
      customerId: req.customerId,
      country: req.country,
      salesRegion: req.salesRegion,
      language: req.language,
      timeZoneId: req.timeZoneId,
    };
    let response = await httpNoneSecure.post(`/api/v1/News`, model);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const newsSlice = createSlice({
  name: "news-reducer",
  initialState,
  reducers: {
    setNews: (state: any, action: any) => {
      return { ...state, newsContent: action.payload };
    },
    getNews: (state) => {
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsAsync.fulfilled, (state, { payload }) => {
      if ((payload as any).success) {
        return {
          ...state,
          newsContent: (payload as any).dataObject.htmlContent,
        };
      } else {
        return {
          ...state,
          newsContent: null,
        };
      }
    });
  },
});

export const selectNews = (rootState: RootState) =>
  rootState.homeReducer.newsContent;

export const { setNews, getNews } = newsSlice.actions;
export default newsSlice.reducer;
