export default function formatShortDate(date: string | undefined) {
    if (date)
        return date.substring(0, 10).split("-").reverse().join("/");
    return "";
}

export function formatShortTime(date: string | undefined) {
    if (date && date.indexOf("T") >= 0){
        const startIndex = date.indexOf("T") + 1;
        return date.substring(startIndex, startIndex + 5);
    }
    return "";
}