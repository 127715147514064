import axios, { AxiosError } from "axios";
import { AuthService } from "./AuthService";
const qs = require("qs");

let isRefreshingEditProfiles = false;
let failedQueueEditProfiles: any[] = [];

const processQueue = (error: AxiosError | null, token: string) => {
  failedQueueEditProfiles.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueueEditProfiles = [];
};

export const httpEditProfile = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

httpEditProfile.interceptors.request.use(
  (config) => {
    const authService = AuthService.getInstance();
    return authService.getUserEditProfile().then((user) => {
      if (user && user.access_token) {
        config.headers.common["Authorization"] = "Bearer " + user.access_token;
      } else {
        authService.loginAsync();
      }
      return config;
    });
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpEditProfile.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const authService = AuthService.getInstance();
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshingEditProfiles) {
        return new Promise((resolve, reject) => {
          failedQueueEditProfiles.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return httpEditProfile(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshingEditProfiles = true;

      return new Promise((resolve, reject) => {
        authService
          .renewTokenEditProfile()
          .then((newUser) => {
            originalRequest.headers["Authorization"] =
              "Bearer " + newUser?.access_token;
            processQueue(null, newUser?.access_token as string);
            resolve(httpEditProfile(originalRequest));
          })
          .catch((err) => {
            processQueue(err, "");
            reject(err);
          })
          .finally(() => {
            isRefreshingEditProfiles = false;
            authService.loginAsync();
          });
      });
    }
    return Promise.reject(error);
  }
);

/*
httpEditProfile.interceptors.response.use(
  response => {
    const authService = AuthService.getInstance();   
    if(response.status === 403 || response.status === 401) {
      return authService.renewTokenEditProfile().then(reNewUser => {             
        response.config.headers.common['Authorization'] = 'Bearer ' + reNewUser.access_token;
        return httpEditProfile(response.config);
      });   
    }
    return response;
  },
  error => {
    const authService = AuthService.getInstance();
    const originalRequest = error.config;
    if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;      
      try {      
        return authService.renewTokenEditProfile().then(renewUser => {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + renewUser.access_token;
          return httpEditProfile(originalRequest);
        });        
      }
      catch (e) {        
        window.location.href = "/sessiontimeout";
      }      
    }
    return Promise.reject(error);
  }
);
*/

// export default httpEditProfile;
