export const TRANSLATIONS_EN_ES = {
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch España, S.L.U, all rights reserved",
	ContactUs_Mobile_ES: "formacion.automovil@bosch.com",
	ContactUs_Address_ES: "Robert Bosch Espana S.L.U.,Calle de los Hermanos Garcia Noblejas 19,28037 Madrid, Spain",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A"
};