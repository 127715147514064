import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";
import { RequestGetTrainingCourseAttachment } from "../models/trainingCourse/request.getTrainingCourseAttachment.model";
import { RequestGetTrainingCourseAttachments } from "../models/trainingCourse/request.getTrainingCourseAttachments.model";
import { RequestGetTrainingCourses } from "../models/trainingCourse/request.getTrainingCourses.model";
import { RequestCsiAnsweredSheet } from "../models/trainingEvent/RequestCsiAnsweredSheet";
import { RequestCsiQuestions } from "../models/trainingEvent/RequestCsiQuestions";
import { RequestPostCsiAnswers } from "../models/trainingEvent/RequestPostCsiAnswers";
import {
  CsiQuestion,
  CsiQuestionAnswers,
  TrainingEvent,
  TrainingEventAttachment,
} from "../models/TrainingEvent";
import { http } from "../services/HttpService";
import { httpNoneSecure } from "../services/HttpServiceNoneSecure";
import { AppDispatch, RootState } from "./store";
import { RequestGetTrainingEventsSimple } from "../models/trainingEvent/request.getTrainingEventsSimple.model";

export const getTrainingEventsAsync = createAsyncThunk<
  TrainingEvent[] | null,
  RequestGetTrainingCourses,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingEvents/getTrainingEvents",
  async (requestModel: RequestGetTrainingCourses, thunkApi) => {
    try {
      const model = {
        country: requestModel.countryCode,
        ciamId: requestModel.ciamId,
        customerId: requestModel.customerId,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
      };
      let response = await httpNoneSecure.post<
        ResponseResultBase<TrainingEvent[]>
      >(`/api/v1/TrainingEvents/country`, model);
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingEventsSimpleAsync = createAsyncThunk<
  ResponseResultBase<TrainingEvent[]> | null,
  RequestGetTrainingEventsSimple,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingEvents/getTrainingEventsSimple",
  async (requestModel: RequestGetTrainingEventsSimple, thunkApi) => {
    try {
      const model = {
        ciamId: requestModel.ciamId,
        ciamEmail: requestModel.ciamEmail,
        country: requestModel.country,
        customerId: requestModel.customerId,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
        salesRegion: requestModel.salesRegion,
      };
      let response = await httpNoneSecure.post<
        ResponseResultBase<TrainingEvent[]>
      >(`/api/v1/TrainingEvents/getTrainingEventsSimple`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getEventAttachmentsAsync = createAsyncThunk<
  ResponseResultBase<TrainingEventAttachment[]> | null,
  RequestGetTrainingCourseAttachments,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingEvents/getEventAttachments",
  async (requestModel: RequestGetTrainingCourseAttachments, thunkApi) => {
    try {
      const model = {
        ciamId: requestModel.ciamId,
        customerId: requestModel.customerId,
        country: requestModel.country,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
        trainingEventId: requestModel.trainingEventId,
      };
      let response = await http.post<
        ResponseResultBase<TrainingEventAttachment[]>
      >(`/api/v1/TrainingEvents/GetEventAttachments`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getEventAttachmentAsync = createAsyncThunk<
  ResponseResultBase<TrainingEventAttachment> | null,
  RequestGetTrainingCourseAttachment,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingEvents/getEventAttachment",
  async (requestModel: RequestGetTrainingCourseAttachment, thunkApi) => {
    try {
      const model = {
        ciamId: requestModel.ciamId,
        customerId: requestModel.customerId,
        country: requestModel.country,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
        attachmentId: requestModel.attachmentId,
      };
      let response = await http.post<
        ResponseResultBase<TrainingEventAttachment>
      >(`/api/v1/TrainingEvents/GetEventAttachment`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getCsiAnsweredSheetAsync = createAsyncThunk<
  ResponseResultBase<CsiQuestionAnswers> | null,
  RequestCsiAnsweredSheet,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingEvents/getCsiAnsweredSheet",
  async (requestModel: RequestCsiAnsweredSheet, thunkApi) => {
    try {
      const model = {
        ciamId: requestModel.ciamId,
        customerId: requestModel.customerId,
        country: requestModel.country,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
        eventId: requestModel.eventId,
        studentId: requestModel.studentId,
      };

      let response = await http.post<ResponseResultBase<CsiQuestionAnswers>>(
        `/api/v1/TrainingEvents/GetCsiAnsweredSheet`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getCsiQuestionsAsync = createAsyncThunk<
  CsiQuestion[] | null,
  RequestCsiQuestions,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingEvents/getCsiQuestions",
  async (requestModel: RequestCsiQuestions, thunkApi) => {
    try {
      const model = {
        ciamId: requestModel.ciamId,
        customerId: requestModel.customerId,
        country: requestModel.country,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
        eventId: requestModel.eventId,
      };

      let response = await http.post<ResponseResultBase<CsiQuestion[]>>(
        `/api/v1/TrainingEvents/GetCsiQuestions`,
        model
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const postCsiAnsweredSheet = createAsyncThunk<
  ResponseResultBase<number> | null,
  RequestPostCsiAnswers,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingEvents/postCsiAnswers",
  async (requestModel: RequestPostCsiAnswers, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `/api/v1/TrainingEvents/PostCsiAnswers`,
        requestModel
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

interface TrainingEventState {
  event: TrainingEvent | null;
  trainingEventId: number;
  trainingEventName: string;
  trainingEvent: TrainingEvent | null;
  simpleTrainingEvents: TrainingEvent[] | null;
  isForceReloadTrainingEvents: boolean;
  isTrainingEventsLoaded: boolean;
}

const initialState: TrainingEventState = {
  event: null,
  trainingEventId: 0,
  trainingEventName: "",
  trainingEvent: null,
  simpleTrainingEvents: null,
  isForceReloadTrainingEvents: true,
  isTrainingEventsLoaded: false,
};

export const eventSlice = createSlice({
  name: "trainingEventReducer",
  initialState,
  reducers: {
    setTrainingEvent: (state, action) => {
      return {
        ...state,
        event: action.payload,
      };
    },
    setIsForceReloadTrainingEvents: (state, action) => {
      return {
        ...state,
        isForceReloadTrainingEvents: action?.payload || false,
      };
    },
    setIsTrainingEventsLoaded: (state, action) => {
      return {
        ...state,
        isTrainingEventsLoaded: action?.payload || false,
      };
    },
    setSimpleTrainingEvents: (state, action: any) => {
      return {
        ...state,
        simpleTrainingEvents: action?.payload || null,
      };
    },
    resetTrainingEvents: (state, action: any) => {
      return {
        ...state,
        simpleTrainingEvents: null,
        isForceReloadTrainingEvents: true,
      };
    },
    setTrainingEventId: (state, action) => {
      return {
        ...state,
        trainingEventId: action?.payload || null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getTrainingEventsSimpleAsync.fulfilled,
      (state, { payload }) => {
        if (payload?.dataObject !== null && payload?.httpStatusCode === 200) {
          const trainingEvents = payload?.dataObject;
          return {
            ...state,
            simpleTrainingEvents: trainingEvents,
            isForceReloadTrainingEvents: false,
            isTrainingEventsLoaded: true,
          };
        } else {
          return {
            ...state,
            simpleTrainingEvents: null,
          };
        }
      }
    );
  },
});

export const selectEvent = (rootState: RootState) =>
  rootState.trainingEventReducer.event;

export const selectTrainingEvent = (rootState: RootState) =>
  rootState.trainingEventReducer.trainingEvent;

export const selectTrainingEventId = (rootState: RootState) =>
  rootState.trainingEventReducer.trainingEventId;

export const selectSimpleTrainingEvents = (rootState: RootState) =>
  rootState.trainingEventReducer.simpleTrainingEvents;

export const selectIsForceReloadTrainingEvents = (rootState: RootState) =>
  rootState.trainingEventReducer.isForceReloadTrainingEvents;

export const selectIsTrainingEventsLoaded = (rootState: RootState) =>
  rootState.trainingEventReducer.isTrainingEventsLoaded;

export const {
  setTrainingEvent,
  setTrainingEventId,
  setSimpleTrainingEvents,
  setIsForceReloadTrainingEvents,
  setIsTrainingEventsLoaded,
  resetTrainingEvents,
} = eventSlice.actions;

export default eventSlice.reducer;
