import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAccount } from "../redux-toolkit/accountReducer";
import {
  selectBatsVersion,
  selectCountryCode,
  selectIsSignIn,
} from "../redux-toolkit/authReducer";
import { applicationRouters } from "../router/bats.router.config";
import { BATSVersion } from "../models/pageConfiguration/BATSVersion";
import { RouteConfigure } from "../models/Route";
import { DASHBOARD_SHARED_ROUTE } from "../constants/routes/sharedRoutes.constants";
import { REQUEST_ID_PARAM } from "../constants/commonParams.constant";

export const useRouting = () => {
  const history = useHistory();

  const countryCode = useSelector(selectCountryCode);
  const currentAccount = useSelector(selectAccount);
  const batsVersion = useSelector(selectBatsVersion);
  const isLoggedIn = useSelector(selectIsSignIn);

  /**
   * @param pathname ex: /regions
   * @param state insideProps.state params of render route function
   * @returns
   */
  const redirectToRoute = (pathname: string, state: any) => {
    return (
      <Redirect
        to={{
          pathname: pathname,
          state,
        }}
      />
    );
  };

  const redirectTo401Page = (from: any) => {
    return redirectToRoute("/exception?type=401", {
      state: {
        from,
      },
    });
  };

  const isAbleToShowSwitchCountryModal = () => {
    if (!currentAccount || !!currentAccount.companyCountry) return false;

    return countryCode.toLowerCase() !== currentAccount?.country?.toLowerCase();
  };

  const isAbleToShowRedirectCountryModal = () => {
    const targetCountry =
      currentAccount?.companyCountry || currentAccount?.countryCode;

    return targetCountry?.toLowerCase() !== countryCode.toLowerCase();
  };

  const getSupportedRoutes = (): RouteConfigure[] => {
    const originalRoutes = applicationRouters[batsVersion as BATSVersion] || [];
    const supportedRoutes: RouteConfigure[] = [];

    for (const route of originalRoutes) {
      if (!!route.isProtected && !isLoggedIn) continue;

      supportedRoutes.push(route);
    }

    return supportedRoutes;
  };

  const getHomeRoute = (): RouteConfigure => {
    const originalRoutes = applicationRouters[batsVersion as BATSVersion] || [];

    return originalRoutes.find((route) => route.path === "/") as RouteConfigure;
  };

  const getCurrentRoute = (): RouteConfigure | undefined => {
    const homeRoute = getHomeRoute();
    const originalRoutes = applicationRouters[batsVersion as BATSVersion] || [];
    const browserPathName = window.location.pathname;

    if (browserPathName === "/") return homeRoute;

    for (const route of originalRoutes) {
      if (!route.path.includes(":")) {
        if (route.path === browserPathName) return route;

        continue;
      } else {

        let convertedPathName = route.path.split(":")[0];
        convertedPathName = convertedPathName.slice(
          0,
          convertedPathName.length - 1
        );

        const pathNameAsArray = browserPathName.split("/");
        const convertedPathNameAsArray = convertedPathName.split("/");

        const referencePathNameAsArray: string[] = [];

        for (let i = 0; i < convertedPathNameAsArray.length; ++i)
          referencePathNameAsArray.push(pathNameAsArray[i]);

        if (referencePathNameAsArray.join("/") === convertedPathName)
          return route;
      }
    }

    return undefined;
  };

  const goToDashboardPage = (requestId: number) => {
    history.push(
      DASHBOARD_SHARED_ROUTE.path.replace(
        `:${REQUEST_ID_PARAM}`,
        requestId.toString()
      )
    );
  };

  return {
    redirectToRoute,
    redirectTo401Page,
    isAbleToShowSwitchCountryModal,
    isAbleToShowRedirectCountryModal,
    getSupportedRoutes,
    getCurrentRoute,
    goToDashboardPage,
  };
};
