import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Grid, Modal, Table } from "semantic-ui-react";

import { Constants } from "../../../helpers/Constants";
import { TrainingEventHelper } from "../../../helpers/TrainingEventHelper";
import { CartEventItemAdded } from "../../../models/CartItem.Model";
import { TrainingCourse } from "../../../models/TrainingEvent";
import {
  selectAccount,
  selectAllowSelfEnroll,
  selectUserRole,
} from "../../../redux-toolkit/accountReducer";
import {
  selectCountryCode,
  selectLanguage,
} from "../../../redux-toolkit/authReducer";
import {
  addEventIdToCart,
  addRegisterdEmployee,
  RegisteredEmployee,
  selectCurrentRegisteredEmployee,
} from "../../../redux-toolkit/shoppingCartReducer";
import { addToCartAsync } from "../../../redux-toolkit/shoppingCartUserReducer";
import { AppDispatch, useAppDispatch } from "../../../redux-toolkit/store";
import Boschicon from "../../BoschIcon/Boschicon";
import InformationModal from "../../InformationModal/InformationModal";
import PriceTag from "../../TrainingCatalogue/PriceTag";
import { ParticipantAddedInfoProps } from "../../TrainingCatalogue/TrainingCatalogueLayout";
import "./AddElearningEventFromCatalogModal.css";
import AddElearningToCartSuccessModal from "./AddElearningToCartSuccessModal";

interface AddedEventProps {
  eventId: number;
  courseId: number;
}

interface AddElearningEventFromCatalogModalProps {
  onDisplayModal: boolean;
  courseData: TrainingCourse;
  onCloseModalTrigger: Function;
}

const AddElearningEventFromCatalogModal: FC<
  AddElearningEventFromCatalogModalProps
> = (props) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const userCountry = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);
  const allowSelfEnroll = useSelector(selectAllowSelfEnroll);
  const registeredEmp = useSelector(selectCurrentRegisteredEmployee);
  const userRole = useSelector(selectUserRole);
  const userInfoState = useSelector(selectAccount);
  const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false);
  const [informationMessage, setInformationMessage] = useState<string>("");
  const [popupTitle, setPopupTitle] = useState<string>("");
  const [showInformationMessage, setShowInformationMessage] =
    useState<boolean>(false);

  useEffect(() => {
    setOpenInformationModal(props.onDisplayModal);
  }, [props.onDisplayModal]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    if (props.onCloseModalTrigger) props.onCloseModalTrigger();
  };

  const convertItemToEventCartItem = (
    item: AddedEventProps,
    participantInfo: ParticipantAddedInfoProps
  ): CartEventItemAdded => {
    let userCiamId =
      userInfoState && userInfoState.ciamId ? userInfoState.ciamId : "";
    let userCustomerId =
      userInfoState && userInfoState.customerId ? userInfoState.customerId : 0;
    return {
      ciamId: userCiamId,
      customerId: Number(userCustomerId),
      country: userCountry,
      language: language,
      timeZoneId: "",
      cartItems: [
        {
          customerId:
            participantInfo.workshopId != null && participantInfo.workshopId > 0
              ? Number(participantInfo.workshopId)
              : 0,
          participantId:
            participantInfo.participantId != null &&
            participantInfo.participantId > 0
              ? Number(participantInfo.participantId)
              : 0,
          eventId: item.eventId,
          courseId: item.courseId,
          note: "",
        },
      ],
    };
  };

  const handleDisplayAddtoCartMessage = (errorMessage: string) => {
    let tempError = errorMessage.replace(/ /g, "");
    if (tempError.toLowerCase() === "alreadyincart") {
      errorMessage = t(
        "Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage"
      );
    }
    setInformationMessage(errorMessage);
    setPopupTitle(t("Addtocart_Modal_AddToCartFailTitle"));
    setShowInformationMessage(true);
  };

  const addItemAndRedirect = (item: AddedEventProps) => {
    let partifipantInfo: ParticipantAddedInfoProps = {
      workshopId: registeredEmp.workshopId,
      participantId: registeredEmp.employeeId,
    };
    if (
      userRole?.toLowerCase() === Constants.USER_ROLE_INDIVIDUAL.toLowerCase()
    ) {
      partifipantInfo.participantId =
        userInfoState && userInfoState.employeeId
          ? userInfoState.employeeId
          : 0;
      partifipantInfo.workshopId =
        userInfoState && userInfoState.customerId
          ? userInfoState.customerId
          : 0;
    }
    let cartEventItem = convertItemToEventCartItem(item, partifipantInfo);
    if (
      partifipantInfo == null ||
      partifipantInfo.participantId === undefined ||
      partifipantInfo.participantId == null ||
      partifipantInfo.participantId === 0
    ) {
      setDisplayInfoModal(true);
      dispatchReduxToolkit(addEventIdToCart(item.eventId));
    } else {
      let addToCartRequest = dispatchReduxToolkit(
        addToCartAsync(cartEventItem)
      ).unwrap();
      addToCartRequest.then((res: any) => {
        if (res?.success) {
          ///IMPORTANT: THIS IS ONLY APPLY FOR ADDING ONE PARTICIPANT
          let cartId: number = 0;
          let addToCartErrorMes = "Error when adding to cart";
          if (
            res?.dataObject != null &&
            res.dataObject.constructor.name === "Array"
          ) {
            if (res.dataObject[0] != null) {
              cartId = Number(res.dataObject[0].id);
              addToCartErrorMes =
                res.dataObject[0].enrollResponse?.explananationLocalized ||
                res.dataObject[0].enrollResponse?.explananation;
            }
          } else {
            if (
              res?.dataObject == null &&
              res?.errorMessages != null &&
              res?.errorMessages.constructor.name === "Array"
            ) {
              addToCartErrorMes = res.errorMessages.join(", ");
            }
            cartId = Number(res?.dataObject);
          }
          if (cartId === 0) {
            handleDisplayAddtoCartMessage(addToCartErrorMes);
          } else {
            setDisplayInfoModal(true);
            let registeredEmployee: RegisteredEmployee = {
              employeeId: 0,
              workshopId: registeredEmp.workshopId,
            };
            dispatchReduxToolkit(addRegisterdEmployee(registeredEmployee));
          }
        } else {
          let errorMes = t("Error_Common");
          if (
            res?.errorMessages != null &&
            res.errorMessages.constructor.name === "Array"
          ) {
            errorMes = res.errorMessages.join(", ");
          }
          handleDisplayAddtoCartMessage(errorMes);
        }
      });
    }
  };

  const handleRegisterClick = () => {
    let addedEventModel: AddedEventProps;
    if (props.courseData) {
      addedEventModel = {
        eventId: props.courseData?.id || 0,
        courseId: props.courseData?.id || 0,
      };
      addItemAndRedirect(addedEventModel);
    }
  };

  const setCloseAddToCartModalFunc = () => {
    setDisplayInfoModal(false);
    setOpenInformationModal(false);
    if (props.onCloseModalTrigger) props.onCloseModalTrigger();
  };

  return (
    <Fragment>
      <Modal
        onClose={closeInformationModal}
        open={openInformationModal}
        dimmer={{ className: "test-dimmer addtocart-elearningcoursemodal" }}
        size={"small"}
      >
        <Modal.Header>
          <span>
            {TrainingEventHelper.getTrainingNameFromCourse(
              props.courseData,
              language,
              "-"
            )}
          </span>
          <Boschicon
            name="bosch-close"
            className="Boschicon close-icon"
            onClick={closeInformationModal}
          />
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row className="row-info">
              <Table
                basic="very"
                className="table-layout flex-table"
                unstackable
              >
                <Table.Header className="register-table-layout">
                  <Table.Row>
                    <Table.HeaderCell className="mobile tablet">
                      {t("TrainingCatalogue_PartNumber")}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="mobile tablet">
                      {t("TrainingCatalogue_Duration")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("TrainingCatalogue_Language")}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="align-right-add-event mobile tablet">
                      {t("TrainingCatalogue_Price")}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row key={props.courseData?.id || 0}>
                    <Table.Cell className="mobile tablet">
                      {props.courseData?.partNumber || "-"}
                    </Table.Cell>
                    <Table.Cell className="mobile tablet">
                      {props.courseData?.duration
                        ? `${props.courseData?.duration.totalHours} (${t(
                            "AddEventFromCatalogToCartModal_HoursText"
                          )})`
                        : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {props.courseData?.localizationInfo?.localized || "-"}
                    </Table.Cell>
                    <Table.Cell className="align-right-add-event mobile tablet">
                      <PriceTag
                        price={props.courseData?.batsPrice}
                        creditPrice={props.courseData?.batsCreditPrice}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        {allowSelfEnroll && (
          <Modal.Actions>
            <Button primary onClick={handleRegisterClick}>
              {t("AddEventFromCatalogToCartModal_AddToCartButton")}
            </Button>
          </Modal.Actions>
        )}
      </Modal>
      <AddElearningToCartSuccessModal
        onDisplayModal={displayInfoModal}
        onCloseModalTrigger={setCloseAddToCartModalFunc}
        displayEventSuccessModel={{
          trainingName: TrainingEventHelper.getTrainingNameFromCourse(
            props.courseData,
            language,
            "-"
          ),
        }}
      />
      {showInformationMessage && (
        <InformationModal
          type="error"
          isDisplayModal={showInformationMessage}
          informationMessage={informationMessage}
          popupTitle={popupTitle}
          setCloseModalTrigger={() => setShowInformationMessage(false)}
        />
      )}
    </Fragment>
  );
};

export default AddElearningEventFromCatalogModal;
