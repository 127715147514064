import { FC, useEffect, useState } from "react";
import { Accordion, Divider } from "semantic-ui-react";
import "./AccordionPanel.css";
import Boschicon from "../BoschIcon/Boschicon";
interface AccordionPanelprops {
  items: any[];
  activeindex?: number;
  divider?: boolean;
  titleClass?: string
}

const AccordionPanel: FC<AccordionPanelprops> = (props) => {
  const [activeIndex, setActiveIndex] = useState<number>(
    props.activeindex || -1
  );
  const handleTitleClick = (
    _event: React.MouseEvent<HTMLDivElement>,
    data: any
  ) => {
    const newIndex = activeIndex === data.index ? -1 : data.index;
    setActiveIndex(newIndex);
  };  

  useEffect(() => {
    if(props.items?.length === 1)
    {
      setActiveIndex(0);
    }
  }, [props.items.length]);

  const panels = (
    <>
      {props.items.map((item, index) => {
        return (
          <>
            { index!==0 && props.divider && <Divider></Divider>}
            <Accordion.Title
              key={item.title}
              active={(activeIndex === index)}
              index={index}
              onClick={handleTitleClick}
              className={props.titleClass || ''}
            >
              <h4>{item.title}</h4>
              <Boschicon
                    name={ (activeIndex === index) ? "up" : "down"}
                  />
            </Accordion.Title>
            <Accordion.Content key={index} active={(activeIndex === index) }>
              <>{item.content}</>
            </Accordion.Content>
          </>
        );
      })}    
    </>
  );

  return (
    <Accordion className="accordion-panel">{panels}</Accordion>
  );
};

export default AccordionPanel;
