import LoadableComponent from "../../Common-components/loadable/index";
import { RouteConfigure } from "../../models/Route";
import { Constants } from "../../helpers/Constants";

/**
 * DEFAULT ROUTES (USED FOR ALL CDM VERSIONS)
 */


export const EXCEPTION_ROUTE: RouteConfigure = {
  path: "/exception?:type",
    exact: true,
    name: "exception",
    title: "exception",
    crumb: "Breadcrumb_Exception",
    icon: "info-circle",
    isProtected: false,
    permission: "",
  component: LoadableComponent(() => import("../../Common-components/Exceptions/Exceptions")),
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Exception",
    },
  ],
};


export const TERM_AND_CONDITION_ROUTE: RouteConfigure = {
  path: "/term-condition/:lang",
  exact: true,
  name: "Term & Condition",
  title: "Term & Condition",
  crumb: "Breadcrumb_TermCondition",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../components/views/TermAndCondition/TermAndCondition")),
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TermCondition",
    },
  ],
};

export const CORPORATION_INFO_ROUTE: RouteConfigure = {
  path: "/corporate-information",
    exact: true,
    name: "Corporate Information",
    title: "Corporate Information",
    crumb: "Breadcrumb_CorporateInformation",
    isProtected: false,
    permission: "",
  component: LoadableComponent(() => import("../../components/views/Info/Info")),
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_CorporateInformation",
    },
  ],
};

export const LEGAL_NOTICE_ROUTE: RouteConfigure = {
  path: "/legal-notice",
  exact: true,
  name: "Legal Notice",
  title: "Legal Notice",
  crumb: "Breadcrumb_LegalNotice",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../components/views/Info/Info")),
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_LegalNotice",
    },
  ],
};

export const DATA_PROTECTION_NOTICE_ROUTE: RouteConfigure = {
  path: "/data-protection-notice/:tabindexparam",
  exact: true,
  name: "Data Protection Notice",
  title: "Data Protection Notice",
  crumb: "Breadcrumb_DataProtectionNotice",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../components/views/Info/Info")),
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_DataProtectionNotice",
    },
  ],
};


export const EDIT_PROFILE_ROUTE: RouteConfigure = {
  path: "editprofilecallback",
  name: "Edit profile",
  title: "EditButton",
  crumb: "EditButton",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../scenes/EditProfileCallback")),
};


