export const TRANSLATIONS_FI = {
	LandingPage_Title: "Tervetuloa Bosch Automotiven koulutusratkaisuihin",
	LandingPage_SubTitle_ChooseCountry: "Valitse alueesi / maasi",
	Header_ContactUs: "Ota yhteyttä",
	Header_Search: "Haku",
	Header_Menu: "Valikko",
	Header_LogInOrCreateYourAccount: "Kirjaudu sisään tai luo tili",
	Header_LogIn: "Kirjaudu sisään",
	Header_Cart: "Ostoskori",
	Header_ShoppingCart: "Ostoskori",
	Header_LogOut: "Kirjaudu ulos",
	Header_ChangeDetails: "Muuta tietoja",
	Footer_ExploreOurTraining: "Tutustu koulutukseemme",
	Footer_TrainingAtYourFingertips: "Harjoittelua käden ulottuvilla",
	Footer_DontMissAnyStory: "Älä missaa yhtään tarinaa",
	Footer_ExploreOurStories: "Tutustu tarinoihimme",
	Footer_GetInTouchWithUs: "Ota yhteyttä meihin",
	Footer_SendUsAMessage: "Lähetä meille viesti",
	Footer_ToContactForm: "Yhteydenottolomake",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "Yleiset yhteystiedot",
	Footer_ProductSecurity_Psirt: "Tuoteturvallisuus (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innovaatioiden patentit ja lisenssit",
	Footer_PurchasingLogistics: "Ostot & logistiikka",
	Footer_CorporateInformation: "Yritystiedot",
	Footer_LegalNotice: "Juridinen ilmoitus",
	Footer_DataProtectionNotice: "Tietosuojailmoitus",
	Footer_PrivacySettings: "Tietosuoja-asetukset",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch Oy, kaikki oikeudet pidätetään",
	Footer_StillLookingForSomething: "Etsin vielä jotakin",
	Footer_TrainingServices: "Service Training",
	Footer_Courses: "Kurssit",
	Footer_Programs: "Harjoitus ohjelmat",
	Footer_EventsCalendar: "Aikataulutetut kurssit",
	Footer_TrainingCenters: "Koulutuskeskukset",
	Footer_Dashboard: "Koontinäyttö",
	Footer_MyDashboard: "Oma koontinäyttö",
	Footer_ShoppingCart: "Ostoskori",
	Footer_YourShoppingCart: "Ostoskorisi",
	Footer_Administration: "Hallinto",
	Footer_Employees: "Työntekijät",
	Footer_Wholesalers: "Tukkumyyjät",
	Footer_Transactions: "Tapahtumat",
	Footer_Workshops: "Korjaamot",
	Footer_WorkLikeABoschApplyNow: "Työskentele  - Hae nyt.",
	Footer_BoschGlobalCareers: "Kansainvälinen ura Boschilla",
	Footer_PurchasingAndLogistics: "Hankinta ja logistiikka",
	Footer_BecomeABusinessPartner: "Ryhdy liikekumppaniksi.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Miten koulutuksemme auttaa yritystäsi",
	Footer_Language: "Valitse maa",
	MenuPopup_MyCart: "Oma ostoskori",
	Stories_OurTrainingStories: "Koulutustarinamme",
	Stories_ConnectingExperts: "Yhdistää asiantuntijat ja harrastajat, unelmoijat ja tekijät, keksijät ja vaikuttajat – tutustu ja koe",
	Stories_TopStories: "Suosituimmat tarinat",
	Stories_AtHome: "Kotona",
	Stories_Careers: "Työpaikat",
	Stories_History: "Historia",
	Stories_IndustryAndTrades: "Teollisuus ja kauppa",
	Stories_Mobility: "Liikkuvuus",
	Stories_Research: "Tutkimus",
	Stories_Sustainability: "Kestävyys",
	Stories_AllStories: "Kaikki tarinat",
	Stories_BlogATrainersDay: "BLOGI | Kouluttajan päivä",
	Stories_ADayinLifeOfATrainer: "Päivä kouluttajan elämässä",
	Stories_BoschServiceTraining: "Boschin huoltokoulutus",
	Stories_StoryWhatDrivesYouDrivesUs: "TARINA | Tarpeenne ohjaavat meitä",
	Stories_StoryHowCanWeBenefitYourBusiness: "TARINA | Miten voimme hyödyttää yritystäsi",
	Stories_HowOurTrainingEvolves: "Miten koulutuksemme kehittyy",
	Stories_AModernTrainingApproach: "Verkkokoulutusmaailma",
	Stories_StoryAVirtualOnlineTrainingWorld: "TARINA | Verkkokoulutusmahdollisuutesi",
	Stories_SubscribeToRSSFeed: "Tilaa RSS-syöte",
	Title_Main: "Bosch Automotiven koulutusratkaisut",
	Homepage_AboutUs: "Tietoja meistä",
	Homepage_AutomotiveAftermarket: "Mobility Aftermarket",
	Homepage_GetInTouch: "Ota yhteyttä",
	Homepage_LatestNews: "Selvitä, miksi tiedonjano ajaa meitä",
	Homepage_MainTitle: "Bosch Automotiven koulutusratkaisut",
	Homepage_OurOpenPositions: "Avoimet työpaikat",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Tarpeenne ohjaavat meitä",
	BoschServiceTraining_BoschServiceTraining: "Boschin huoltokoulutus",
	BoschServiceTraining_ShareThisOn: "Jaa tämä",
	BoschServiceTraining_TheChallenge: "Haaste: Uusien teknologioiden hallinta",
	BoschServiceTraining_WorldwidePresence: "Maailmanlaajuinen läsnäolo",
	BoschServiceTraining_TechnicalTraining: "Boschin tekniset kurssit",
	BoschServiceTraining_TheOffersAndOpportunities: "Boschin tarjonta ja mahdollisuudet",
	BoschServiceTraining_TechnicalCoursesDesc: "Sopivia koulutuskursseja löytyy jokaiselle kohderyhmälle - niin aloittelijalle kuin ammattilaisellekin. Tarjonta sisältää yhden että usean päivän koulutuskursseja. Koulutuksia tarjotaan eri teknisillä osa-alueilla ja koulutussarjoissa (kuten bensiinin ja dieselin ruiskutus, sähköjärjestelmät). Teknisillä koulutuskursseilla Bosch välittää laajaa tietotaitoa Boschin kaikista ajoneuvojen vakiojärjestelmistä, ja kursseilla keskustellaan myös muiden valmistajien järjestelmistä. Sisältö on sovitettu erityisesti korjaamotyöntekijöiden tarpeisiin.",
	BoschServiceTraining_TrainedEmployeesDesc: "Koulutetut työntekijät ovat ilmeinen etu autokorjaamoille. Koska nykyajan ajoneuvot muuttuvat yhä monimutkaisemmiksi ja uudet teknologiat tuovat jatkuvasti uusia haasteita. Vain tarvittava asiantuntemus antaa korjaamotyöntekijöille mahdollisuuden suorittaa diagnostisia huolto- ja korjaustöitä nykyisille ajoneuvomalleille tehokkaasti ja kustannustehokkaasti.",
	BoschServiceTraining_RegardlessOfHow: "Riippumatta siitä, kuinka hyviä ja kokeneita ajoneuvoalan asiantuntijat ovat, eteen tulee aina tilanteita, joissa he joutuvat tekemisiin uusien ajoneuvoteknologiajärjestelmien tai tuntemattomien vikojen kanssa. Vianetsintä ja ongelmanratkaisu voivat silloin viedä paljon aikaa tai ne eivät välttämättä johda onnistuneeseen lopputulokseen. Pahimmassa tapauksessa asiakkaiden on tultava toimeen ilman autoaan huomattavasti odotettua kauemmin.",
	BoschServiceTraining_ItIsPrecisely: "Tässä Boschin toteuttama huoltokoulutus parantaa huollon laatua ja nopeuttaa prosesseja. Boschin nykyinen koulutus auttaa paikantamaan ongelmat nopeammin, korjaamaan enemmän autoja samassa ajassa sekä lyhentämään asiakkaiden odotusaikoja. Boschin opettama järjestelmällisyys saa työn sujumaan nopeammin ja parantaa sen tuottavuutta.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Bosch on ollut yli 125 vuoden ajan johtavien ajoneuvovalmistajien ensisijainen alkuperäisosien komponenttien ja järjestelmien toimittaja maailmanlaajuisesti. Tähän kokemukseen sisältyy uusimpien teknologioiden ainutlaatuinen osaaminen, joka toimii Boschin tuen perustana.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Boschin huoltokoulutuskeskuksia löytyy kaikkialta maailmasta: Espanjasta Turkkiin ja Etelä-Afrikasta ja Australiasta Brasiliaan – Bosch tarjoaa työntekijöilleen käytännönläheistä koulutusta, joka on aina linjassa uusimpien teknologioiden kanssa.",
	BoschServiceTraining_AgileCompany: "Ketterä yritys",
	BoschServiceTraining_Collaboration: "Yhteistyö",
	BoschServiceTraining_ConnectedMobility: "Yhdistetty liikkuvuus",
	BoschServiceTraining_ArtificialIntelligence: "Tekoäly",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Ajatusjohtajat vuoropuhelussa",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostiikka ja testilaitteet",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Ajoneuvotekniikan perusteet",
	BoschServiceTraining_Icon_Gasoline: "Bensiini",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Vaihtoehtoiset käyttövoimat",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Sähkö ja elektroniikka",
	BoschServiceTraining_Icon_BodyAndComfort: "Mukavuuselektroniikka",
	BoschServiceTraining_Icon_ChassisAndBraking: "Alusta ja jarrujärjestelmät",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Vaihteisto ja voimansiirto",
	BoschServiceTraining_Icon_CommercialVehicles: "Hyötyajoneuvot",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Onlinekoulutukset",
	BoschServiceTraining_Icon_BusinessSkills: "Liiketalouden taidot",
	BoschServiceTraining_Icon_Accreditations: "Todistukset",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Koulutusohjelmat",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerFirstNameLastName",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "A day in the life of a trainer",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Everyday is an adventure",
	ADayInTheLifeOfATrainer_Summary: "Summary",
	ADayInTheLifeOfATrainer_TheDayJourney: "The day's journey",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Everyday is a busy day but it is always a rewarding experience. The pleasure of training students knowing that they hold the key to our future is a responsibilty but at the same time so exciting. Seeing the students growing with each lesson on a daily and weekly basis proves to us that we are working towards a comon goal to improve their knowledge whilst incorporating the mordern technology that drives the future!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "At the end of each day we have a sit down and reflect on the days work. The days go so quickly but this is proof itself that what we are achieving is not only rewarding for the students but for us as trainers too.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "We can go home at the end of day satisfied that we have done a great job!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Aloita",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Materiaalin viimeiset tarkistukset ja muut valmistelut.",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Koulutuksen ensimmäinen päivä",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Osallistujien vastaanotto. Päivän agendan ja aikataulun läpikäyminen. Osallistujiin tutustuminen kurssin eri aiheiden osalta.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teoria",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Osallistujien aktioiviminen erilaisilla pienillä haasteilla lisää koulutuksen mielekkyyttä. Varmista että teoriaosuuteen sisältyy myös tällaisia hetkiä ja mahdollisia tehtäviä.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Lounastauko",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Käytäntö",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Koulutus jatkuu käytännön osuudella. Kouluttajan on tärkeä olla aina läsnä auttamassa ja neuvomassa laitteiden ja tehtävien kanssa.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Kertaus",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Kooste päivän tapahtumista ja opituista asioista. Pyritään saamaan osallistujilta kysymyksiä aiheista ja niistä heränneistä ajatuksista. Seuraavaan päivään valmistautuminen",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Viimeinen koulutuspäivä",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Läsnäolotodistukset esitetään osallistujille.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Koti",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "How can we help benefit your business",
	BoschTrainingBenefits_HowOurTrainingEvolves: "How our training evolves",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "We are constantly evolving to ensure we deliver quality up-to-date-training",
	BoschTrainingBenefits_TheBoschServiceTraining: "The Bosch Service Training Center would like to assist you in developing your business through its extensive and highly relevant portfolio of technical/business training and further education courses with certified qualifications. The following pages provide you with an overview of our training program as well asthe current further education opportunities. We look forward to meeting you and your employees at our training courses and seminars.",
	BoschTrainingBenefits_LongStandingExperience: "Long-standing experience with and extensive know-how of all systems and in thea area of automobile technology.",
	BoschTrainingBenefits_QuickAndEfficient: "Quick and efficient solutions for many problems.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "The Advantages and benefits",
	BoschTrainingBenefits_LMS: "Learning Management System - Bosch Training solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Soltions kattaa kaikki ajoneuvotekniikan alueet. Erikoistu haluamaasi alueeseen tai laajenna osaamistasi erilaisilla koulutuksilla.",
	BoschTrainingBenefits_AcquiredKnowledge: "Acquired knowledge facilities repair maintenance and service work.",
	BoschTrainingBenefits_TheHighDegree: "The high degree of practical content and small groups guarantee target-oriented learning",
	BoschTrainingBenefits_ParticipationInTraining: "Participation in training increases employee motivation and satisfaction and supplemental qualifications convince customers",
	BoschTrainingBenefits_IncreasedSales: "Increased sales through cross-make system knowledge",
	BoschTrainingBenefits_MethodicalProcedures: "Methodical procedures reduce the wait time for customers",
	BoschTrainingBenefits_HighlyQualified: "Highly qualified instructors convey systematic procedures",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Sinulle tai yrityksellesi sopivan koulutusratkaisun tunnistaminen",
	BoschTrainingBenefits_DirectOnlineBooking: "Suora online-varaus ja kurssien hallinta joko itsellesi tai työntekijöillesi",
	BoschTrainingBenefits_OnlineAccess: "Pääsy oppimisdokumentaatioon verkossa",
	BoschTrainingBenefits_AccessToYourOwn: "Pääsy omaan henkilökohtaiseen koulutushistoriaasi ja todistuksiin",
	BoschTrainingBenefits_ToolsForManagement: "Työkalut oman urakehityksen hallintaan",
	BoschTrainingBenefits_ManagerHasFull: "Esimiehellä on täydellinen yleiskuva työntekijöidensä kursseista järjestelmän sisällä",
	BoschTrainingBenefits_Access24hours: "Käytettävissä 24 tuntia 7 päivänä viikossa",
	ModernTrainingApproach_KnowledgeIsKey: "Tieto on jokaisen korjaamon keskeinen menestystekijä. Siksi Bosch tarjoaa kattavan ammatillisen koulutuksen kehittämisohjelman. Näin opiskelijat voivat hankkia täsmälleen oikeat tiedot oikeaan aikaan ja oikeassa paikassa - tiedot, jotka tarvitaan asiakkaan ajoneuvon ammattimaiseen huoltoon ja korjaamiseen. Bosch on lisännyt webcastingin uutena moduulina jo tarjottuun verkko-oppimispakettiin. Ne koostuvat huoltokoulutuksista, jotka on suunniteltu erityisesti suoratoistoa varten.",
	ModernTrainingApproach_ANewLearning: "Uusi oppimismahdollisuus",
	ModernTrainingApproach_YourOnlineTraining: "Verkkokoulutusmahdollisuutesi",
	ModernTrainingApproach_RecentlyTheWorld: "Maailma on muuttunut viime aikoina. Katso, mitä me teemme auttaaksemme",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Verkkokoulutusmaailma",
	ModernTrainingApproach_NewTeachingMethod: "Uusi opetusmenetelmä",
	ModernTrainingApproach_InAdditionTo: "Nykyisen kasvokkain tapahtuvan koulutuksen lisäksi olemme sisällyttäneet koulutusohjelmaan suorana lähetettävän Webcast-koulutuksen. Laajat teoriatiedot omaava kouluttajamme on käytettävissäsi virtuaaliluokkahuoneessa tai käytännön esimerkeillä. Kuten yleensäkin, saat paljon tietoa ajoneuvojärjestelmistä ja vastaavista Bosch-tuotteista. Tietysti sinulla on myös mahdollisuus esittää kysymyksiä. Näihin kysymyksiin vastataan suoraan suorassa Webcast-lähetyksessä. Suorat Webcast-lähetykset kestävät 60–120 minuuttia. Tätä varten tarvitaan vakaa Internet-yhteys. Etsi sopiva Webcast-lähetys suoraan koulutusluettelostamme kirjoittamalla hakukenttään \Webcast\.",
	ModernTrainingApproach_BoschWebcastingHeader: "Boschin Webcast-lähetys: Innovatiivinen ja käytännöllinen",
	ModernTrainingApproach_BoschWebcastingText: "Osallistuakseen Boschin Webcast-lähetykseen, osallistujan on ensin soitettava siihen. Kirjautumista varten osallistujalle lähetetään henkilökohtainen pääsykoodi ennen istuntoa. Jopa 200 osallistujaa voi osallistua samanaikaisesti mistä tahansa paikasta. Koulutuksen vetää kouluttaja ja kysymyksiä voi lähettää koko ajan live-chat-toiminnon kautta.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Digitaalinen lisä luokkahuonekoulutukseen",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasting ja luokkahuonekoulutus täydentävät toisiaan täydellisesti. Niissä molemmissa koulutusta johtaa pätevä kouluttaja. Webcast-lähetykset soveltuvat aiheille, jotka voidaan lähettää digitaalisesti ilman ajoneuvossa tehtäviä töitä. Esimerkiksi uuden kuljettajaa avustavan järjestelmän ESI[tronic]-ominaisuuksiin tutustuminen. Tehokkaan korjaamotyöskentelyn tai ajoneuvotekniikan käytännön töiden osalta kasvokkain tapahtuva koulutus on edelleen ensimmäinen valinta.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Suoratoisto voi olla halvempaa",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Osallistujakohtaiset kustannukset ovat yleensä alhaisemmat webcasting-lähetyksellä kuin vastaavalla luokkahuonekurssilla. Syynä on, että useammat osallistujat voivat hyötyä siitä ja koulutukseen liittyvä vaiva on huomattavasti pienempi (ei matkustamista koulutuspaikalle ja takaisin, ja aikaa tarvitaan vähemmän).",
	ModernTrainingApproach_BookAndProfitHeading: "Varaa ja hyödy",
	ModernTrainingApproach_BookAndProfitText: "Webcast-lähetyksiä voi varata verkkosivuiltamme kuten muitakin koulutuskurssejamme ja oppimistarjontaamme. Voit joko maksaa erikseen jokaisesta Webcast-lähetyksestä tai tehdä vuositilauksen tietylle määrälle Webcast-lähetyksiä.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Bosch-webcastingin ja luokkahuonekoulutuksen vertailu",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Luokkahuone",
	ModernTrainingApproach_WebcastingP1: "Jopa 200 osallistujaa voi osallistua samanaikaisesti mistä päin maailmaa tahansa",
	ModernTrainingApproach_WebcastingP2: "Matala monimutkaisuus ja suhteellisen alhaiset kustannukset eivät vaadi matkustamista",
	ModernTrainingApproach_WebcastingP3: "Ihanteellinen erityisiin ja ytimekkäisiin yksittäisiin aiheisiin",
	ModernTrainingApproach_WebcastingP4: "Korjaamot voivat pitää webcast-lähetyksen työntekijöilleen missä ja milloin tahansa",
	ModernTrainingApproach_WebcastingP5: "Aikaa kuluu 50-70 % vähemmän kuin luokkahuonekoulutukseen",
	ModernTrainingApproach_ClassroomTrainingP1: "Jopa 16 osallistujaa kokoontuu samanaikaisesti",
	ModernTrainingApproach_ClassroomTrainingP2: "Matkakulut ja yleensä korkeammat kulut henkilöä kohden",
	ModernTrainingApproach_ClassroomTrainingP3: "Ihanteellinen yhteistyön optimointiin tai oppimiseen suoraan ajoneuvossa",
	ModernTrainingApproach_ClassroomTrainingP4: "Käytännön harjoituksia ja vuorovaikutusta valmentajan kanssa",
	ModernTrainingApproach_ClassroomTrainingP5: "Kouluttaja pystyy mukauttamaan opetettavan tiedon paremmin osallistujien todellisten tarpeiden mukaan",
	ModernTrainingApproach_OnlineTrainingOfferings: "Verkkokoulutustarjonta (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Nykyään itsenäinen tietokoneella oppiminen on olennainen osa tiedon siirtämistä. Vapaus valita aika ja paikka, rajoittamaton toistettavuus, yksilöllinen oppimisnopeus ja interaktiiviset oppimissisällöt tekevät tästä menetelmästä korvaamattoman opiskeluvälineen.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Jokainen WBT (Web-Based Training) -elementti on jäsennelty huolellisesti. Laajan tietopohjan tarjoamisen lisäksi nämä kurssit toimivat tehokkaana valmistautumisena Boschin koulutuskeskusten koulutusohjelmiin.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Virtuaalinen diagnostiikkasimulaatio tarjoaa käyttäjälle roolipelimahdollisuuksia päivittäisiin korjaamotilanteisiin, esim. Bosch-diagnostiikkalaitteita käyttävän vianetsintäprosessin työnkulkuun virtuaalisessa korjaamossa.",
	ModernTrainingApproach_Advantages: "Edut",
	ModernTrainingApproach_AdvantagesP1: "Nopea ja tehokas tapa tarjota tietoa",
	ModernTrainingApproach_AdvantagesP2: "Monipuolinen oppiminen",
	ModernTrainingApproach_AdvantagesP3: "Voidaan käyttää niin usein kuin halutaan",
	ModernTrainingApproach_AdvantagesP4: "Minimaalinen aika ja raha",
	ModernTrainingApproach_AdvantagesP5: "Yksilöllinen oppimisnopeus",
	ModernTrainingApproach_AdvantagesP6: "Joustava opiskeluaika",
	ModernTrainingApproach_AdvantagesP7: "Toistettavuus",
	ModernTrainingApproach_AdvantagesP8: "Ei matkakuluja",
	ModernTrainingApproach_AdvantagesP9: "Ei kapasiteetti- tai aikatauluristiriitoja",
	Dashboard_CoursesViewAndManageYourCourses: "KURSSIT | Kurssien tarkastelu ja hallinta",
	Dashboard_MyCourses: "Omat kurssit",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "KOULUTUSOHJELMA | Koulutusohjelmien tarkastelu",
	Dashboard_MyTrainingProgram: "Oma koulutusohjelma",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING| eLearning-portfolion tarkastelu ja hallinta",
	Dashboard_MyELearning: "Oma eLearning",
	Dashboard_AccountManageYourAccount: "TILIN | Tilin hallinta",
	Dashboard_MyAccount: "Oma tili",
	Dashboard_MyDashboard: "Koontinäyttö",
	Dashboard_ManageYourAccount: "Hallitse tiliäsi",
	Dashboard_Workshops: "Korjaamot",
	Dashboard_AdministrationManageYourWorkshop: "HALLINTO | Korjaamon hallinta",
	Dashboard_AdministrationManageYourTeams: "HALLINTO | Tiimien hallinta",
	Dashboard_Administration: "Hallinta",
	Dashboard_ViewYourCourses: "Näytä omat kurssit",
	Dashboard_ViewYourTrainingPrograms: "Näytä omat koulutusohjelmat",
	Dashboard_TrainingPrograms: "Koulutusohjelmat",
	Dashboard_ViewAndEditYourDetails: "Näytä ja muokkaa tietojasi",
	Dashboard_ViewYourELearningCourses: "Näytä eLearning-kurssisi",
	Dashboard_Pending: "Odottaa",
	Dashboard_RequiresAdministratorSignOff: "(vaatii järjestelmänvalvojan kirjautumisen)",
	Dashboard_HistoricalRecords: "Historiatietueet",
	Dashboard_Registered: "Rekisteröity",
	Dashboard_WaitingList: "Odotuslista",
	Dashboard_RecommendedForYou: "Sinulle suositeltua",
	Dashboard_AccountDetails: "Tilin tiedot",
	Dashboard_ChangeProfileImage: "vaihda profiilikuva",
	Dashboard_Title: "Titteli",
	Dashboard_Role: "Rooli",
	Dashboard_FirstName: "Etunimi",
	Dashboard_LastName: "Sukunimi",
	Dashboard_Telephone: "Puhelin",
	Dashboard_Mobile: "Matkapuhelin",
	Dashboard_Email: "Sähköposti",
	Dashboard_Language: "Kieli",
	Dashboard_Save: "Tallenna",
	Dashboard_Student: "Opiskelija",
	Dashboard_WorkshopTrainingAdministrator: "Korjaamon koulutuspäällikkö",
	Dashboard_WholesalerTrainingAdministrator: "Tukkumyyjän koulutuspäällikkö",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Yrityksen hallinto",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Hallitse korjaamoja...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Hallitse työntekijöitä...",
	Dashboard_CompanyAdministration_Workshop: "Korjaamo",
	Dashboard_CompanyAdministration_Address: "Osoite",
	Dashboard_CompanyAdministration_Zip: "Postinumero",
	Dashboard_CompanyAdministration_City: "Paikkakunta",
	Dashboard_CompanyAdministration_Manage: "Hallitse",
	Dashboard_AdministrationSection_AdministrationTitle: "Hallinta",
	Dashboard_AdministrationSection_ManageTheTeam: "Hallitse tiimiä",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "HALLINTO | Tiimien hallinta",
	Dashboard_AdministrationSection_Employees: "Työntekijät",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "HALLINTO | Tukkumyyjien hallinta",
	Dashboard_AdministrationSection_Wholesalers: "Tukkumyyjät",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "HALLINTO | Tapahtumien hallinta",
	Dashboard_AdministrationSection_Transactions: "Tapahtumat",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Työntekijöiden hallinta",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Hallitse työntekijöitä...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Rekisteröidy",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Tietueet",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Muokkaa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Työntekijä",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Lisää",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Luo",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Rekisteröity",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Odottaa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(vaatii järjestelmänvalvojan kirjautumisen)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historiatietueet",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Odotuslista",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Nykyinen työntekijäluettelo",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Työntekijöiden hallinta",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nimi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Työntekijän tili",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Näytä ja muokkaa työntekijän tietoja...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Asetukset",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Salli henkilökohtainen tili",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Koulutushallinnoinnin käyttöoikeudet (voi hallinnoida muiden työntekijöiden ilmoittautumisia)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Salli työntekijän ilmoittautua koulutustapahtumiin",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Lähetä sähköpostikutsu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Poista tili käytöstä",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Tallenna",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Lähetä",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Muokkaa",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Tukkumyyjien hallinta",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Hallitse tukkumyyjiäsi...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Hyväksytty tukkumyyjäluettelo",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nimi",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Poista",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Luo",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Lisää",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Uuden työntekijätilin luominen",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Luo tiedot uusille työntekijöille...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Lisää uusi hyväksytty tukkumyyjän edustaja",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Luo tiedot uusille tukkumyyjille...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Valitse hyväksytty tukkumyyjä pudotusvalikosta...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Tapahtumat",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Näytä ja hallitse tapahtumiasi...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Edellinen",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Laskun numero",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Kuvaus",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Maksutapa",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Päivämäärä",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Määrä",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Lataa",
	TrainingCentres_TrainingCentres: "Koulutuskeskukset",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Koulutuskeskuksemme sijaitsevat ympäri maailmaa",
	TrainingCentres_CountryCategory: "Maaluokka",
	TrainingCentres_CityCategory: "Paikkakuntaluokka",
	TrainingCentres_Country: "Maa",
	TrainingCentres_City: "Paikkakunta",
	TrainingCatalogue_TrainingCatalogue: "Kurssiluettelo",
	TrainingCatalogue_LearningAtTheHighestLevel: "Korkeimman tason oppimista",
	TrainingCatalogue_StartDate: "Aloituspäivä",
	TrainingCatalogue_EndDate: "Lopetuspäivä",
	TrainingCatalogue_TrainingName: "Koulutuksen nimi",
	TrainingCatalogue_Language: "Kieli",
	TrainingCatalogue_Days: "Päivää",
	TrainingCatalogue_Seats: "Vapaat paikat",
	TrainingCatalogue_Price: "Hinta",
	TrainingCatalogue_Location: "Paikka",
	TrainingCatalogue_PartNumber: "Osanumero",
	TrainingCatalogue_TrainingCourse: "Kurssi",
	TrainingCatalogue_Category: "Kategoria",
	TrainingCatalogue_TrainingCategory: "Koulutuskategoria",
	TrainingCatalogue_CourseName: "Koulutusohjelma",
	TrainingCatalogue_TotalDays: "Päiviä yhteensä",
	TrainingCatalogue_Venue: "Paikka",
	TrainingCatalogue_Details: "Lisätiedot",
	TrainingCatalogue_Prerequisites: "Edellytykset",
	TrainingCatalogue_CourseType: "Kurssin tyyppi",
	TrainingCatalogue_LearningMethod: "Oppimismenetelmä",
	TrainingCatalogue_AddToWaitingList: "Lisää odotuslistalle",
	ShoppingCart_ShoppingCart: "Ostoskori",
	ShoppingCart_ReviewAndManage: "Tarkista ja hallitse tilauksiasi",
	ShoppingCart_YouHaveAddedItem: "Olet lisännyt seuraavat tuotteet ostoskoriisi",
	ShoppingCart_Item: "Tuote",
	ShoppingCart_Quantity: "Määrä",
	ShoppingCart_PricePerItem: "Hinta/tuote",
	ShoppingCart_TotalPrice: "Kokonaishinta",
	ShoppingCart_VatIsIncluded: "Ilman alv:tä",
	ShoppingCart_Redeem: "Jos sinulla ei ole luottoa, lunasta se klikkaamalla tästä",
	ShoppingCart_Purchase: "Jos sinulla ei ole luottoa, osta klikkaamalla tästä",
	ShoppingCart_Checkout: "Kassa",
	ShoppingCart_ContinueShopping: "Jatka ostoksia",
	ShoppingCart_TotalPriceInclVat: "Kokonaishinta sis. alv",
	ShoppingCart_Apply: "Käytä",
	ShoppingCart_ShoppingCartEmpty: "Ostoskorisi on tyhjä",
	ShoppingCart_VatIncluded: "ALV",
	ShoppingCart_ManageYourShopping: "Hallitse ostoksiasi",
	ShoppingCart_Participant: "Osallistuja",
	ShoppingCart_Complete: "Viimeistele",
	ShoppingCart_NetPrice: "Nettohinta",
	ShoppingCart_Workshop: "Korjaamo",
	CheckOut_PreviewYourOrder: "Tilauksen yleiskatsaus",
	CheckOut_YourShoppingCart: "Ostoskori",
	CheckOut_Preview: "Esikatselu",
	CheckOut_Payment: "Maksu",
	CheckOut_Summary: "Yhteenveto",
	CheckOut_Back: "Edellinen",
	CheckOut_PleaseReadAndAgreeOurTerms: "Lue ja hyväksy käyttöehtomme",
	CheckOut_IHaveReadTheTermsAndCondition: "Olen lukenut [käyttöehdot] ja ymmärrän ja hyväksyn ne",
	CheckOut_YesIAgree: "Kyllä - Hyväksyn ehdot",
	CheckOut_Continue: "Jatka",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Onnittelut! Olemme vastaanottaneet tilauksesi",
	CheckOut_OrderConfirmation: "Saat lopullisen tilausvahvistuksen, kun pyyntösi on käsitelty onnistuneesti",
	CheckOut_WeWillContactYouDirectly: "Otamme sinuun suoraan yhteyttä, jos meillä on kysyttävää pyyntöösi liittyen",
	CheckOut_AddToCart: "Lisää ostoskoriin",
	CheckOut_CreditCart: "Luottokori",
	CheckOut_DirectDebit: "Suoraveloitus",
	CheckOut_TrainingCredits: "Koulutuspisteet",
	CheckOut_PreferredWholesaler: "Ensisijainen tukkumyyjä",
	EventCalendar_EventCalendar: "Tapahtumakalenteri",
	EventCalendar_TrainingEventsForAllYourNeeds: "Koulutustapahtumat kaikkiin tarpeisiisi",
	TrainingCourse_TrainingCourse: "Kurssiluettelo",
	TrainingCourse_LearningAtTheHighestLevel: "Korkeimman tason oppimista",
	TrainingProgram_Title: "Koulutusohjelmaluettelo",
	TrainingProgram_SubTitle: "Korkeimman tason oppimista",
	AccountDeactivationModal_Title: "Haluatko varmasti poistaa tämän tilin käytöstä?",
	AccountDeactivationModal_Line1: "Tämän tilin poistaminen käytöstä on pysyvää ja poistaa kaiken sisällön, mukaan lukien mukaan lukien palvelupyynnöt, tapahtumat ja saldot, tiimin jäsenet ja profiiliasetukset.",
	AccountDeactivationModal_Line2Part1: "Jos tilin tilausjakso on aktiivinen, tutustu peruutusehtoihimme lukemalla",
	AccountDeactivationModal_Line2Part2: "käyttöehdot",
	AccountDeactivationModal_Line2Part3: "ennen pyynnön lähettämistä.",
	AccountDeactivationModal_Line3: "Haluatko varmasti lähettää pyynnön tämän tilin poistamiseksi käytöstä?",
	AccountDeactivationModal_Cancel: "Peruuta",
	AccountDeactivationModal_Send: "Lähetä",
	CurrentUserAccountDeactivationModal_Title: "Haluatko varmasti poistaa tilisi käytöstä?",
	CurrentUserAccountDeactivationModal_Line1: "Tilisi poistaminen käytöstä on pysyvää ja poistaa kaiken sisällön, mukaan lukien mukaan lukien palvelupyynnöt, tapahtumat ja saldot, tiimin jäsenet ja profiiliasetukset.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Jos tilisi tilausjakso on aktiivinen, tutustu peruutusehtoihimme lukemalla",
	CurrentUserAccountDeactivationModal_Line3: "Haluatko varmasti lähettää pyynnön tilisi poistamiseksi käytöstä?",
	CurrentUserAccountDeactivationModal_Yes: "Kyllä",
	CurrentUserAccountDeactivationModal_No: "Ei",
	CourseWithdrawalModal_Title: "Peruutuksesi ei ole lopullinen, ennen kuin koulutushallintomme on hyväksynyt sen.",
	CourseWithdrawalModal_Line1: "Saat lopullisen vahvistuksen sähköpostitse, kun se on hyväksytty. Jos meillä on kysyttävää, otamme sinuun yhteyttä suoraan. Oletko varma, että haluat lähettää peruutuspyyntösi?",
	CourseWithdrawalModal_Line2Part1: "Ole hyvä ja lue",
	CourseWithdrawalModal_Line2Part2Link: "Peruutuskäytäntö",
	CourseWithdrawalModal_Line2Part3: "Jatketaanko peruutuspyyntöä?",
	CourseWithdrawalModal_Yes: "Kyllä",
	CourseWithdrawalModal_No: "Ei",
	Settings_Settings: "Asetukset",
	Settings_ManageYourSettings: "Hallitse asetuksiasi",
	Settings_Profile: "Profiili",
	Settings_MyBoschGlobalIdLoginInformation: "Bosch Global ID -kirjautumistietoni",
	Settings_Edit: "Muokkaa",
	Settings_CompanyDetails: "Yrityksen tiedot",
	Settings_RequestAccountDeletion: "Pyydä tilin poistamista",
	Settings_AccountDeletionText: "Jos poistat tilisi, kaikki tietosi (mukaan lukien osoitteesi ja maksutietosi) poistetaan eivätkä ne ole enää käytettävissä.",
	Settings_DeleteAccountRequestConfirmation: "Tilin poistopyynnön vahvistus",
	Settings_DeleteAccountRequestConfirmationText: "Olemme vastaanottaneet tilisi poistopyynnön ja otamme sinuun yhteyttä sähköpostitse tai puhelimitse lopullista vahvistusta varten",
	RecommendedHotels_RecommendedHotels: "Suositellut hotellit",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Koulutuskeskuksemme sijaitsevat ympäri maailmaa",
	Error_Common: "Jotain meni pieleen!",
	Error_404: "Sivua, jolla kävit, ei ole olemassa",
	Error_401: "Sinulla ei ole pääsyä tälle sivulle",
	Error_500: "Palvelin ilmoittaa virheestä",
	Dashboard_Courses_Table_Course: "Koulutus",
	Dashboard_Courses_Table_Date: "Päivämäärä (päivät)",
	Dashboard_Courses_Table_Venue: "Sijainti",
	Dashboard_Courses_Table_TrainingType: "Koulutuksen tyyppi",
	Dashboard_Courses_Table_TasksToComplete: "Sinulla on seuraavia tehtäviä suoritettavana",
	Dashboard_Courses_Table_Withdraw: "Peruuta",
	Dashboard_Courses_Table_Remove: "Poista",
	Dashboard_Courses_Table_View: "Näytä",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Ei tuloksia",
	Dashboard_Courses_Table_CompletionDate: "Suorituspäivä",
	Dashboard_Courses_Table_Actions_Assignments: "Ilmoittautumiset",
	Dashboard_Courses_Table_Actions_Certification: "Todistus",
	Dashboard_Courses_Table_Actions_Evaluations: "Arvioinnit",
	Dashboard_Courses_Table_Actions_Assessments: "Arviot",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Lisää kalenteriin",
	Dashboard_Courses_Table_CourseDetails_Task: "Tehtävä",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Viimeinen päivämäärä",
	ContactUs_Headline: "Ota yhteyttä",
	ContactUs_HowCanWeHelp: "Kuinka voimme auttaa?",
	ContactUs_EmailTab: "Sähköposti",
	ContactUs_PhoneTab: "Puhelin",
	ContactUs_LetterTab: "Posti",
	ContactUs_Phone_Headline: "Soita meille!",
	ContactUs_Phone_Description: "Odotamme soittoasi ja autamme mielellämme!",
	ContactUs_Phone_PhoneNumber: "Lähetä yhteydenottopyyntösi oheisella lomakkeella.",
	ContactUs_Phone_ChargeNotice: "Mahdolliset veloitukset riippuvat palveluntarjoajastasi ja sijannistasi.",
	ContactUs_Letter_Headline: "Lähetä kirje!",
	ContactUs_Letter_Description: "Onko sinulla kysyttävää, toiveita, ehdotuksia? Otamme mielellämme niitä vastaan!",
	ContactUs_Email_Headline: "Lähetä sähköposti!",
	ContactUs_Email_Description: "Lähetä sähköposti täyttämällä alla oleva kaavake. Olemme yhteydessä mahdollisimman pian!",
	ContactUs_Email_InputName: "Nimi*",
	ContactUs_Email_InputCompany: "Maa",
	ContactUs_Email_InputCountry: "Yritys",
	ContactUs_Email_InputEmail: "Sähköposti*",
	ContactUs_Email_InputMessage: "Viesti*",
	ContactUs_Email_DataProtection: "Tietosuojailmoitus",
	ContactUs_Email_DataProtectionConfirm: "Olen lukenut tietojenkäsittelyn ehdot",
	ContactUs_Email_SecurityCheck: "Varmennus",
	ContactUs_Email_SecurityCodeConfirm: "Syötä laatikossa näkyvät merkit oheiseen kenttään",
	ContactUs_Email_SecurityCodeInvalid: "Syöttämäsi merkit eivät täsmää",
	ContactUs_Email_MandatoryNotice: "Täydennä pakolliset kentät",
	ContactUs_Email_SuccessMessage: "Viestisi on lähetetty",
	ContactUs_Email_TakenNoteDataProtection1: "Olen lukenut",
	ContactUs_Email_TakenNoteDataProtection2: "Tietosuoja",
	ContactUs_Email_SubmitButton: "Lähetä",
	ContactUs_Email_FieldErrorMessage: "Täydennä pakolliset kentät",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Tilisi on käsittelyssä!",
	AccountInReviewModal_Paragraph1: "Kiitos rekisteröitymisestä Bosch Automotive Training Solutions portaaliin.",
	AccountInReviewModal_Paragraph2: "Koulutushallintomme käsittelee hakemustasi parhaillaan. Saat ilmoituksen kun tilisi on tarkistettu ja aktivoitu.",
	AccountInReviewModal_Paragraph3: "Tällä välin voit selailla sivustoamme vierailijanäkymässä.",
	AccountInReviewModal_Paragraph4: "Mikäli sinulla on kysyttävää tai tiliäsi ei ole aktivoitu 48 tunnin sisiällä (arkena) ole hyvä ja ota yhteyttä",
	AccountInReviewModal_ContactUsLink: "tästä",
	AccountInReviewModal_OKButton: "OK",
	Header_YesButton: "Kyllä",
	Header_NoButton: "Ei",
	Header_LogOutText: "Haluatko kirjautua ulos?",
	Footer_EnquiryText: "Odotamme innolla yhteydenottoasi",
	Footer_Telephone: "Puhelin",
	Footer_TermsAndConditions: "Käyttöehdot",
	Footer_HowOurTrainingEvolves: "Kuinka koulutuksemme kehittyy",
	FooterSearch_SearchFieldPlaceholder: "Haku",
	TrainingCatalogue_Duration: "Kesto",
	TrainingCatalogue_Information: "Tietoja",
	Settings_PersonalDetails: "Henkilökohtaiset tiedot",
	Reset_All_Filters: "Palauta suodatukset",
	SearchField_AllResults: "Kaikki tulokset",
	TrainingCentresLayout_ViewDetails: "Näytä lisätiedot",
	Dropdown_Placeholder: "Valitse…",
	WaitlistPopup_SelectButtonText: "Valitse",
	WaitlistPopup_StartDate: "Aloituspäivä",
	WaitlistPopup_EndDate: "Lopetuspäivä",
	WaitlistPopup_Language: "Kieli",
	WaitlistPopup_City: "Kaupunki",
	WaitlistPopup_Workshop: "Korjaamo",
	WaitlistPopup_Student: "Osallistuja",
	WaitlistPopup_PreferredLocation: "Toivottu sijainti",
	WaitlistPopup_SignUpCourseWaitingList: "Asetu jonotuslistalle",
	WaitlistPopup_SignUpWithoutSelectingDate: "Asetu jonotuslistalle ilman päivämäärää",
	WaitlistPopup_GeneralWaitList: "Yleinen jonotuslista",
	WatinglistPopup_CourseLabel: "Koulutus",
	Common_ContinueButton: "Jatka",
	UserAccountDeactivationModal_ConfirmLabel: "Vahvista",
	Filter_FilterLabel: "Suodata",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Olet jo jonotuslistalla, haluatko poistaa nykyisen merkinnän ja tehdä uuden?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Kyllä, poista",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Ei, peruuta",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Koulutuksen peruutus",
	WaitinglistPopup_SignUpButtonText: "Ilmoittaudu",
	MyAccount_FirstNameRequied: "Etunimi vaaditaan",
	MyAccount_LastNameRequired: "Sukunimi vaaditaan",
	MyAccount_RoleRequired: "Asema vaaditaan",
	MyAccount_MobileRequired: "Matkapuhelin vaaditaan",
	MyAccount_EmailRequired: "Sähköposti vaaditaan",
	MyAccount_LanguageRequired: "Kieli vaaditaan",
	MyAccount_UpdateSuccessMessage: "Tilisi tiedot on päivitetty",
	MyAccount_UpdateSuccessModal_ButtonText: "Kyllä, sulje",
	Infor_CorporateInformation_Header: "Yrityksen tiedot",
	Infor_LegalNotice_Header: "Oikeudellinen huomautus",
	Infor_PrivacyPolicy_Header: "Tietosuojailmoitus",
	CreateEmployeeAccountModification_FormValidation_Role: "Asema vaaditaan",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Etunimi vaaditaan",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Sukunimi vaaditaan",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Matkapuhelin vaaditaan",
	CreateEmployeeAccountModification_FormValidation_Email: "Sähköposti vaaditaan",
	CreateEmployeeAccountModification_FormValidation_Language: "Kieli vaaditaan",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Kutsu liittyä Bosch Automotive Training Solutions -portaaliin",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Olet onnistuneesti lisännyt henkilön yrityksen tietoihin.",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Haluatko että toimitamme uudelle jäsenelle sähköpostiohjeet tilin luomiseen?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Ei",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Kyllä, lähetä ohjeet",
	ShoppingCart_Venue_Fee: "Toimipaikkamaksu",
	ShoppingCart_Trainning_Name: "Koulutuksen nimi",
	ShoppingCart_Available_Credits: "Jäljellä oleva saldo",
	ConfirmItsMeModal_Hi: "Hei",
	ConfirmItsMeModal_Description1: "Olet onnistuneesti vastannut saamaasi kutsuun, luomalla itsellesi henkilökohtaiset tunnukset.",
	ConfirmItsMeModal_Description2: "Ennen jatkamista pyydämme tarkistamaan tilisi tiedot.",
	ConfirmItsMeModal_Description3: "Vahvistuksen jälkeen sinun tulee kirjautua uudelleen sisään vahvistaaksesi tili.",
	ConfirmItsMeModal_CompanyDetails: "Yrityksen tiedot",
	ConfirmItsMeModal_YourDetails: "Sinun tietosi",
	ConfirmItsMeModal_YourDetails_Title: "Titteli",
	ConfirmItsMeModal_YourDetails_Function: "Asema",
	ConfirmItsMeModal_YourDetails_Name: "Nimi",
	ConfirmItsMeModal_YourDetails_Mobile: "Matkapuhelin",
	ConfirmItsMeModal_YourDetails_Email: "Sähköposti",
	ConfirmItsMeModal_YourDetails_Language: "Kieli",
	ConfirmItsMeModal_ConfirmButton: "Vahvista",
	SelectWholesalerModal_WholesalerGroupLabel: "Tukkumyyjä",
	SelectWholesalerModal_WholesalerBranchLabel: "Outlet",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Ole hyvä ja valitse",
	ApplyButton: "Vahvista",
	SelectWholesalerModal_Title: "Valitse tukkumyyjäsi",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Haluatko poistaa rekisteröitymisen?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Rekisteröityminen on nyt peruutettu!",
	WaitlistPopup_AddToWaitListSuccess: "Rekisteröityminen on onnistunut!",
	ErrorCommon_Header: "VIRHE",
	CommonButton_Close: "Sulje",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Sulje tämä ikkuna jatkaaksesi!",
	EmbeddedVideoPlayer_StartButtonText: "Aloita",
	EmbeddedVideoPlayer_RefresherButtonText: "Päivitä",
	DeactivateWholesalerModal_ConfirmMessage: "Oletko varma että haluat poistaa tämän tukkurin?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Kutsusähköpostin lähetys onnistui!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Kutsu uudestaan",
	ContactUs_Mobile_FI: "+358 10 480 80",
	ContactUs_Address_FI: "Robert Bosch Oy,Robert Huberin tie 16 A,01510 Vantaa, Suomi",
	ConfirmItsMeModal_CompanyDetails_Name: "Yrityksen nimi",
	ConfirmItsMeModal_CompanyDetails_Address: "Osoite",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Postinumero",
	ConfirmItsMeModal_CompanyDetails_Phone: "Puhelinnumero",
	ConfirmItsMeModal_CompanyDetails_Email: "Sähköposti",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Kotiuttaminen onnistui",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Tilin luominen epäonnistui. Tili on jo olemassa!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Tilin päivitys epäonnistui. Tili on jo olemassa!",
	Footer_ExploreTrainingStories: "Tutustu kaikkiin koulutustarinoihimme",
	Breadcrumb_Home: "Koti",
	Breadcrumb_Contact: "Yhteystiedot",
	Breadcrumb_Stories: "Tarinoita",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "Päivä kouluttajan elämässä",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch-koulutuksen edut",
	Breadcrumb_Stories_TrainingApproach: "Nykyaikainen koulutustapa",
	Breadcrumb_Settings: "Asetukset",
	Breadcrumb_ShoppingCart: "Ostoskori",
	Breadcrumb_Checkout: "Tarkista",
	Breadcrumb_OrderSummary: "Tilauksen yhteenveto",
	Breadcrumb_Exception: "Poikkeus",
	Breadcrumb_Dashboard: "Kojelauta",
	Breadcrumb_MyCourses: "Minun kurssini",
	Breadcrumb_MyELearning: "Oma eLearning",
	Breadcrumb_CompanyAdministration: "Yrityksen hallinto",
	Breadcrumb_EmployeeManagement: "Työntekijöiden hallinta",
	Breadcrumb_EmployeeAccount: "Työntekijän tili",
	Breadcrumb_EmployeeCreation: "Työntekijöiden luominen",
	Breadcrumb_Administration: "Hallinto",
	Breadcrumb_EmployeeAdministration: "Työntekijöiden hallinto",
	Breadcrumb_WholesalerManagement: "Uuden toimittajatilin luominen",
	Breadcrumb_NewWholesalerAccountCreation: "Uuden toimittajatilin luominen",
	Breadcrumb_Transactions: "Tapahtumat",
	Breadcrumb_MyAccount: "Tilini",
	Breadcrumb_TrainingCentres: "Kuntosalit",
	Breadcrumb_EventCalendar: "Kurssikalenteri",
	Breadcrumb_TrainingCourseCatalogue: "Kurssiluettelo",
	Breadcrumb_RecommendedHotels: "Suositellut hotellit",
	Breadcrumb_CorporateInformation: "Toimittaja",
	Breadcrumb_LegalNotice: "Lailliset oikeudet",
	Breadcrumb_DataProtectionNotice: "Tietosuojailmoitus",
	Breadcrumb_PrivacyPolicy: "Tietosuojailmoitus",
	Breadcrumb_TermCondition: "Käyttöehdot",
	Breadcrumb_Terms: "Käyttöehdot",
	Breadcrumb_PrivacyStatement: "Tietosuojailmoitus",
	Breadcrumb_Cookies: "Evästeet",
	Breadcrumb_Provider: "Toimittaja",
	Breadcrumb_RegisterAccount: "Rekisteröi tili",
	Stories_ExploreMore: "Tutustu lisää",
	CheckOutLayout_ShoppingCartHeader: "Ostoskori",
	CheckOutLayout_AddedItemCartMessage: "Olet lisännyt seuraavat tuotteet ostoskoriin",
	CheckOutLayout_CheckYourCartMessage: "Tarkista ostoskorisi",
	ModernTrainingApproach_DateText: "2/28/2022",
	ADayInLifeofATrainer_DateText: "2/28/2022",
	BoschTrainingBenefits_DateText: "2/28/2022",
	BoschServiceTraining_DateText: "2/28/2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Etsi työntekijöitä",
	CheckOutLayout_OrderWasNotPlaced_Message: "Anteeksi! Tilaustasi ei tehty",
	CheckOutLayout_RefusalReason: "Syy kieltäytymiseen",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Maksu epäonnistui. Yritä uudelleen toisella maksutavalla",
	CheckOutLayout_SubmittingApiFailed_Message: "API:n lähettäminen epäonnistui",
	CartItem_ChooseWorkshop_Message: "Aloita valitsemalla työpaja",
	CartItem_ChooseParticipant_Message: "Valitse osallistuja",
	CompanyAdministrationTable_SearchBox_Placeholder: "Hae korjaamoita",
	CartItem_RemoveCartItem_ConfirmText: "Haluatko poistaa tämän tuotteen ostoskorista?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Asema",
	AddToCartSuccessModal_Message: "Ostoskorisi on päivitetty!",
	AddToCartSuccessModal_ContinueButtonText: "Jatka ostoksia",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Ostoskorini",
	AddVoucher_Header: "Vahvista",
	AddVoucher_InputPlaceholder: "Syötä koodisi ja vahvista",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Valitettavasti meillä ei ole tällä hetkellä suunniteltuja tämän koulutuksen tapahtumia. Ilmoittaudu jonotuslistalle, niin ilmoitamme sinulle, kun tapahtuma vapautuu.",
	ADYEN_ERR_2_: "Tapahtuma hylättiin.",
	ADYEN_ERR_3_: "Viitevirhe",
	ADYEN_ERR_4_: "Tapahtuma ei toteutunut ostajan päässä tapahtuneen virheen vuoksi.",
	ADYEN_ERR_5_: "Tapahtumaan käytetty kortti on lukittu, joten sitä ei voi käyttää.",
	ADYEN_ERR_6_: "Tapahtumaan käytetty kortti on vanhentunut. Siksi se on käyttökelvoton.",
	ADYEN_ERR_7_: "Tapahtuman prosessin aikana ilmentyi summaero.",
	ADYEN_ERR_8_: "Syötetyn kortin numero on virheellinen.",
	ADYEN_ERR_9_: "Ostajan pankkiin ei ole mahdollista ottaa yhteyttä tapahtuman hyväksymiseen.",
	ADYEN_ERR_10_: "Ostajan pankki ei tue tai salli tällaisia tapahtumia.",
	ADYEN_ERR_11_: "3D Secure -todennusta ei suoritettu tai se ei onnistunut.",
	ADYEN_ERR_12_: "Kortilla ei ole tarpeeksi rahaa maksettavan summan kattamiseen. Tämä ongelma johtuu kortin kuukausittaisesta maksurajasta. Yritä toisella kortilla, tai ota yhteyttä pankkiisi kuukausittaisen maksurajan muuttamiseksi.",
	ADYEN_ERR_14_: "Mahdollinen petos.",
	ADYEN_ERR_15_: "Tapahtuma peruutettiin.",
	ADYEN_ERR_16_: "Ostaja peruutti tapahtuman ennen sen suorittamista.",
	ADYEN_ERR_17_: "PIN -koodi on virheellinen tai virheellinen.",
	ADYEN_ERR_18_: "Ostaja syötti väärän PIN -koodin yli kolme kertaa peräkkäin.",
	ADYEN_ERR_19_: "Syötettyä PIN -koodia ei voi vahvistaa.",
	ADYEN_ERR_20_: "Mahdollinen petos.",
	ADYEN_ERR_21_: "Tapahtumaa ei lähetetty käsiteltäväksi oikein.",
	ADYEN_ERR_22_: "Riskitarkastus merkitsi tapahtuman petolliseksi (riskipisteet> = 100); siksi toiminto peruutetaan.",
	ADYEN_ERR_23_: "Seuraavat virheilmoitukset johtuvat syystä: Tapahtuma ei ole sallittu maksutapahtuman käsittelijälle/kortinhaltijalle",
	ADYEN_ERR_24_: "Syötetty CVC (kortin turvakoodi) on virheellinen.",
	ADYEN_ERR_25_: "Seuraavat virheilmoitukset johtuvat syystä: Virheellinen kortti tässä maassa",
	ADYEN_ERR_26_: "R1: Valtuutusmääräyksen kumoaminen / \"R3: Kaikkien valtuutusmääräysten kumoaminen\" / \"R0: Lopeta maksumääräys\"",
	ADYEN_ERR_27_: "Tämä ilmoitus käsittää kaikki sellaiset virheilmoitukset, joita ei voi yksiselitteisesti selittää. Näin on helpompi erottaa yleiset hylkäykset (esimerkiksi Mastercard \"05: Älä kunnioita\") tarkemmista.",
	ADYEN_ERR_28_: "Kortin sallittu nostoraja on ylitetty.",
	ADYEN_ERR_29_: "Kortilla sallittujen nostojen määrä on ylittynyt.",
	ADYEN_ERR_31_: "Maksutapahtuman käsittelijä ilmoitti tapahtuman epäillyksi petokseksi.",
	ADYEN_ERR_32_: "Ostajan syöttämät osoitetiedot ovat virheellisiä.",
	ADYEN_ERR_33_: "Ostajan pankki vaatii ostajaa syöttämään online PIN-koodin.",
	ADYEN_ERR_34_: "Ostajan pankki vaatii käyttötilin ostoksen loppuunsaattamiseksi.",
	ADYEN_ERR_35_: "Ostajan pankki vaatii säästötilin oston loppuunsaattamiseksi.",
	ADYEN_ERR_36_: "Ostajan pankki vaatii ostajaa syöttämään mobiililaitteen PIN-koodin.",
	ADYEN_ERR_37_: "Ostaja keskeytti tapahtuman, kun ostajaa kehotettiin kokeilemaan toista kortin syöttötapaa (PIN -koodi tai pyyhkäisy), ostajan yrittäessä lähimaksua.",
	ADYEN_ERR_38_: "Maksutapahtuman käsittelijä hylkäsi todennuspoikkeuspyynnön ja vaatii tapahtuman todennuksen. Yritä uudelleen 3D Securen avulla.",
	ADYEN_ERR_39_: "Maksutapahtuman käsittelijä tai järjestelmä ei kyennyt ilmoittamaan tuloksesta Rreq:n kautta",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Etunimi ei saa sisältää erikoismerkkejä (esim. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Etunimen on oltava alle 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Sukunimi ei saa sisältää erikoismerkkejä (esim. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Sukunimen on oltava pienempi kuin 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Matkapuhelinnumero ei saa sisältää erikoismerkkejä (esim. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Matkapuhelinnumeron on oltava alle 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Puhelinnumero ei saa sisältää erikoismerkkejä (esim. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Puhelinnumeron on oltava alle 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Sähköpostiosoite on virheellinen",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Sähköpostiosoitteen on oltava alle 100",
	ConfirmGeolocationModal_ConfirmButton: "Jatkaa",
	ConfirmGeolocationModal_CancelButton: "Muuttaa",
	ConfirmGeolocationModal_CurrentCountrySelection: "Nykyinen maavalintasi on:",
	ConfirmGeolocationModal_AskChangeCountry: "Haluatko pysyä tällä sivustolla vai muuttaa maavalintaasi?",
	Addtocart_Modal_AddToCartFailTitle: "Osallistujan lisääminen epäonnistui",
	Addtocart_Modal_AlreadyInCart: "Osallistuja on jo rekisteröitynyt tälle kurssille",
	TrainingCatalogue_DateRange: "Ajanjakso",
	TrainingCatalogue_DateRange_placeholder: "Valitse ajanjakso",
	Dashboard_MyPendingCourses: "Odottavat kurssini",
	Dashboard_MyWaitingList: "Jonotuslistani",
	Dashboard_LatestNews: "Uutiset",
	SettingsMainPage_EditAccountButton: "Muokkaa käyttäjätiliä",
	SettingsMainPage_EditBoschId: "Muokkaa Bosch ID:tä",
	EventInfoView_EventDetailTab: "Tapahtuman tiedot",
	EventInfoView_AssignmentsTab: "Tehtävät",
	EventInfoView_EvaluationTab: "Arviointi",
	EventInfoView_AttachmentTab: "Liitteet",
	EventInfoView_CourseDescriptionTab: "Kuvaus",
	EventInfoView_DescriptionTab_General: "Yleistä",
	EventInfoView_DescriptionTab_PartLabel: "Osa",
	AssignmentTab_Description: "Täältä löydät tarvitsemasi tiedot kurssin läpäisemiseksi.",
	AssignmentTab_Columns_Download: "Lataa",
	AssignmentTab_Columns_Upload: "Lataa",
	AssignmentTab_Columns_TaskComplete: "Tehtävä suoritettu",
	EventDetailTab_Location_Message_Video: "Tämä on online-tapahtuma, mihin pääsee \"Käynnistä video\" -linkillä.",
	EventDetailTab_Location_Message_WebBased: "Tämä on online-tapahtuma, mihin pääsee \"Käynnistä WBT\" -linkillä.",
	EventDetailTab_Location_Message_WebCast: "Tämä on online-tapahtuma, mihin pääsee \"Liity nyt\" -linkillä.",
	EventDetailTab_Link_Video: "Käynnistä video",
	EventDetailTab_Link_Video_Remark: "*Videon käynnistäminen avaa uuden ikkunan. Teidät ohjataan takaisin tälle sivulle videon päätyttyä.",
	EventDetailTab_Link_WebBased: "Käynnistä WBT",
	EventDetailTab_Link_WebBased_Remark: "*WBT:n käynnistäminen avaa uuden ikkunan. Teidät ohjataan takaisin tälle sivulle kun ikkuna sulkeutuu.",
	EventDetailTab_Link_WebCast: "Liity nyt",
	EventDetailTab_Link_WebCast_Remark: "*Liity nyt -linkki tulee aktiiviseksi 30 minuuttia ennen tapahtuman alkua.",
	EventDetailTab_Title_Status: "Tila",
	EventDetailTab_Title_Duration: "Kesto (tunteina)",
	EventDetailTab_Title_Trainers: "Kouluttaja(t)",
	EventDetailTab_Title_Language: "Kieli",
	EventDetailTab_Title_Score: "Pisteet (%)",
	EventDetailTab_Title_Calendar: "Lisää kalenteriin",
	EventDetailTab_Title_Certificate: "Lataa todistus",
	EventDetailTab_Title_Messages: "Viestit",
	EventDetailTab_Title_StartDate: "Aloituspäivämäärä",
	EventDetailTab_Title_AvailableFrom: "Saatavilla alkaen",
	EventDetailTab_Title_Time: "Aika",
	EventDetailTab_Title_EndDate: "Päättymispäivämäärä",
	EventDetailTab_Title_ExpiresOn: "Päättyy",
	EventDetailTab_Title_Room: "Huone",
	EventDetailTab_Title_Method: "Tapa",
	EventDetailTab_Title_Link: "Linkki*",
	EventDetailTab_Title_Location: "Sijainti",
	AttachmentTab_EmptyScreenDescription: "Tälle tapahtumalle ei ole liitteitä.",
	AttachmentTab_Columns_Download: "Lataa",
	AttachmentTab_Description: "Täältä löydät tärkeitä tietoja avuksi kurssille.",
	EvaluationTab_Annotation: "Arvostamme palautettasi ja haluaisimme kuulla mitä mieltä olet. Pyydämme täyttämään seuraavan arvioinnin kehittääksemme palveluamme. Kiitos avustasi!",
	EvaluationTab_Notice: "Arvioinnit ovat seuraavanlaiset",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Vastaa kaikkiin pakollisiin kysymyksiin ennen arvioinnin lähettämistä",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Palautteesi on lähetetty onnistuneesti",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Arvioinnin lähetys epäonnistui",
	TrainingCatalogueLayout_ExportButtonText: "Vie",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Arviointi on suoritettu tai se on vanhentunut.",
	EventDetailTab_Link_CopiedTooltip: "Kopioitu leikepöydälle",
	EventDetailTab_QRCode_ExplanationText_WBT: "Skannaa käynnistääksesi WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Skannaa käynnistääksesi videon",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Skannaa käynnistääksesi Webcastin",
	Footer_TrainingCoure_Headline: "Boschin järjestämät koulutukset",
	Footer_TrainingCourse_Content: "Maailmankuuluna Superbrandina ja yli vuosisadan kokemuksella innovaatioista Bosch on asettanut standardit maailmanlaajuisesti autoteollisuuden järjestelmien, osien ja teknisten ratkaisujen toimittajana. Suurin osa vahvuudestamme tulee läheisistä kumppanuuksistamme ajoneuvovalmistajien kanssa, joille suunnittelemme, kehitämme ja valmistamme huippuluokan järjestelmiä ja komponentteja ympäri maailmaa. Näin ollen Bosch on lujittanut vahvan historian autojen jälkimarkkinatuotteissa ja -tuessa monipuolisella varaosien, diagnostiikkaratkaisujen ja korjaamopalveluiden valikoimalla. Oppiminen ja kehittyminen ovat varmoja avaimia menestykseen, ja tätä filosofiaa tukee laaja valikoima koulutuskursseja kaikilla autotekniikan, ajoneuvojärjestelmien ja diagnostiikkaosaamisen aloilla.",
	SearchField_NoRecordsFound: "Merkintöjä ei löydy",
	TrainingCatalogue_FreeTextSearch: "Vapaa teksti",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Hae",
	CheckoutLayout_WholeSalerGroup: "Valitse Tukkukauppiasryhmä",
	CreditInsufficientModal_Message: "Sinulla ei ole tarpeeksi kredittejä tämän maksutavan käyttämiseen. Yritä uudelleen toisella maksutavalla.",
	ShoppingCart_Required_Credits: "Kokonaishinta",
	MaintainingInfo_Message1: "Sivusto on ylläpitotilassa.",
	MaintainingInfo_Message2: "Palaamme pian.",
	CountryValidationModal_Message: "Et voinut kirjautua sisään maahan [CountryA], koska tilisi on luotu maahan [CountryB].",
	CountryValidationModal_ButtonText: "Jatka maahan [Country]",
	EventTable_LoginRequired: "Kirjaudu sisään rekisteröityäksesi",
	TrainingPartTableLayout_Date: "Päivämäärä",
	TrainingPartTableLayout_StartTime: "Aloitusaika",
	TrainingPartTableLayout_EndTime: "Lopetusaika",
	TrainingPartTableLayout_Duration: "Kesto (tuntia)",
	TrainingPartTableLayout_TrainingMethod: "Koulutusmenetelmä",
	MyCoursesTableContents_ActiveHeader: "Aktiiviset kurssit",
	MyCoursesTableContents_ActiveTabHeader: "Aktiivinen",
	MyCoursesTableContents_ScheduledHeader: "Suunnitellut kurssit",
	MyCoursesTableContents_ScheduledTabHeader: "Suunniteltu",
	MyCoursesTableContents_OnlineHeader: "Online-kurssit",
	MyCoursesTableContents_OnlineTabHeader: "verkossa",
	MyCoursesTableContents_CancelledHeader: "Peruutetut kurssit",
	MyCoursesTableContents_CancelledTabHeader: "Peruutettu",
	OnlineCoursesTable_Title_Progress: "Edistyminen",
	ScheduledCoursesTable_Title_Date: "Päivämäärä [päivää]",
	CoursesTable_Title_Completed: "Valmis",
	Footer_TrainingProgram: "Koulutusohjelmat",
	Breadcrumb_TrainingProgram: "Koulutusohjelmat",
	TrainingProgram_Title_Table: "Otsikko",
	TrainingProgram_TrainingProgram: "Koulutusohjelmat",
	TrainingProgram_TargetGroup: "Kohderyhmä",
	TrainingProgram_MaxLength: "Pituus",
	TrainingProgram_Information: "Tiedot",
	TrainingProgram_Detail: "Lisätietoja",
	TrainingProgram_Modules: "Moduulit",
	TrainingProgram_Course: "Kurssit",
	TrainingProgram_PartNumber: "Tuotenumero",
	TrainingProgram_Max_Length: "Maksimi pituus",
	TrainingProgram_Duration: "Kesto",
	TrainingProgram_Exam: "Koe",
	TrainingProgram_Assign_Popup: "Ilmoittaudu koulutusohjelmaan",
	TrainingProgram_Student: "Osallistuja",
	TrainingProgram_Assign: "Määritä",
	TrainingProgram_Popup_Remove_Title: "Koulutuksen peruutus?",
	TrainingProgram_Description: "Kuvaus",
	TrainingProgram_Days: "päivää",
	TrainingProgram_Type: "Tyyppi",
	TrainingProgram_Action: "Toiminta",
	TrainingProgram_Enrolled: "on jo ilmoittautunut tähän koulutusohjelmaan",
	TrainingProgram_SelfAssign_Enrolled: "Sinut on jo määritetty tähän koulutusohjelmaan",
	TrainingProgram_Warning: "Ole hyvä ja rekisteröi toinen osallistuja tai valitse toinen koulutusohjelma.",
	TrainingProgram_Workshop: "Korjaamo",
	TrainingProgram_Durations: "vuotta",
	TrainingProgram_Duration_DaysLabel: "päivää",
	TrainingProgram_HasExam_Yes: "Kyllä",
	TrainingProgram_HasExam_No: "Ei",
	AssignModal_SuccessModal_Header: "Onnittelut!",
	AssignModal_SuccessModal_Message: "on ilmoittautunut tähän koulutusohjelmaan onnistuneesti.",
	AssignModal_SelfAssign_SuccessModal_Message: "Sinut on määritetty onnistuneesti koulutusohjelmaan.",
	Dashboard_MyTraningProgram: "Oma koulutusohjelmani",
	Dashboard_MyTraningProgram_Title: "Otsikko",
	Dashboard_MyTraningProgram_Status: "Tila",
	Dashboard_MyTraningProgram_Table_View: "Näytä",
	Dashboard_MyTraningProgram_Register: "Rekisteröidy",
	Dashboard_MyTraningProgram_Records: "Arkisto",
	Dashboard_MyTrainingProgram_In_Progress: "Meneillään",
	Dashboard_MyTrainingProgram_Accredited: "Akkreditoitu",
	Dashboard_Program_Modal_Withdraw: "Lopeta",
	Dashboard_Program_Modal_Grade: "Arvosana",
	Dashboard_MyTrainingProgram_Registered: "Rekisteröity",
	Dashboard_MyTrainingProgram_Completedd: "Valmis",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Joo",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Ei",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Huomaa, että peruuttamalla päätät koulutusohjelma kokonaisuuden, etkä vain yksittäistä koulutusta.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Haluatko silti lopettaa koulutusohjelman?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Odottaa",
	WithdrawProgramModal_Withdraw_Success_Message: "Olet onnistuneesti päättänyt koulutusohjelmasi!",
	Common_Warning_Title: "Varoitus!",
	Common_Error_Title: "Hups, jotain meni pieleen!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Lisää ostoskoriin",
	AddEventFromCatalogToCartModal_HoursText: "tuntia",
	AddElearningToCartSuccessModal_Message: "on lisätty ostoskoriin!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Valittu osallistuja on rekisteröitynyt tälle kurssille tai kurssi on jo lisätty ostoskoriin.",
	EventDetailTab_SubjectForChange_Message: "Muutoksen aihe",
	Dashboard_MyTrainingProgram_Status_Failed: "Ei läpäissyt",
	Dashboard_MyTrainingProgram_Status_Passed: "Läpäisty",
	Dashboard_MyTrainingProgram_Status_Merit: "Ansio",
	Dashboard_MyTrainingProgram_Status_Distinction: "Excellence",
	Dashboard_Employee_Courses: "Kurssit",
	Dashboard_Employee_Program: "Koulutusohjelma",
	Dashboard_Employee_WaitList: "Odotuslista",
	Dashboard_Employee_Account: "Tili",
	Course_Price_Free: "Vapaa",
	Dashboard_Employee_Account_Update_Success_Message: "Olet onnistuneesti päivittänyt työntekijätietosi!",
	Dashboard_Supplier: "Toimittaja",
	Event_Detail_Comment: "Tällä hetkellä ei ole viestejä",
	DownLoad_PDF: "Lataa PDF",
	TrainingCatalogue_Currency: "Valuutta",
	My_Wallet: "Lompakkoni",
	Wallet: "Lompakko",
	Credits_Balance: "Kredittisaldo",
	MyFullCalendar_Today: "Tänään",
	Header_Available_Languages: "Kielivaihtoehdot",
	Header_CurrentRegion: "Olet tällä hetkellä",
	Header_ChangeRegions: "Haluatko vaihtaa maa valintasi",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Kirjaudu / Rekisteröidy",
	Header_RegisterCourse: "Rekisteröidy kurssille",
	Homepage_Start: "Aloita",
	Homepage_Login: "Kirjaudu",
	MainPage_Title: "Ylivoimainen ratkaisu korjaamolle",
	MainPage_Content: "Sinun korjaamosi, sinun valintasi",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Mahdollistaa nykyaikaiselle korjaamolle ammattimaisen, luotettavan sekä tehokkaan tavan tehdä diagnosointia, vianhakua, korjausta sekä huoltotöitä kaikenlaisille ajoneuvoille.",
	BoschNews_TrainingNews: "Koulutusuutiset",
	Service_Assist_Workshop: "Laajenna käyttökokemusta Workshop Service Assist sovelluksen avulla",
	Enhance_Title_CDM3_v2: "Mitä sovellus minulle tarjoaa?",
	Enhance_Body_CDM3_Item_1: "Ilmoittaudu kursseille ja pysy ajantasalla",
	Enhance_Body_CDM3_Item_2: "Lataa koulutusmateriaaleja ja koulutustodistuksiasi",
	Enhance_Body_CDM3_Item_3: "Osallistu eLearning kursseille",
	Enhance_Body_CDM3_Item_4: "Hyödynnä sovelluksen monimuotoisia autoalan apuohjelmia",
	Enhance_Body_CDM3_Item_5: "Videoyhteys Visual Connect Pron avulla",
	Enhance_Body_CDM3_Item_6: "Tarkastele ajoneuvon teknisiä tietoja skannaamalla",
	Training_Stories: "Koulutuskokemuksia",
	SettingsMyAccount_JoinCompanyButton: "Liitä tilisi yritykseen",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Liitä tilisi yritykseen",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Syötä aktivointikoodisi alla olevaan kenttään",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Huomio! Saat aktivointikoodin yrityksen tilin ylläpitäjältä.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Aktivointi suoritettu ja tilisi on nyt liitetty yritykseen",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Paina \"Jatka\" siirtyäksesi eteenpäin",
	ContinueButton: "Jatka",
	WelcomeBatsModal_Title: "Tilisi on käsittelyssä",
	WelcomeBatsModal_ActivationLink: "Klikkaa tästä jos sinulla on aktivointikoodi ja haluat liittää tilisi yritykseen",
	WelcomeBatsModal_ActivationLinkNotes: "Huomio! Saat aktivointikoodin yrityksen tilin ylläpitäjältä.",
	AccountInReviewModal_Description_Paragraph1: "Kiitos, olet rekisteröinyt henkilökohtaisen tilin BATS -järjestelmään",
	AccountInReviewModal_Description_Paragraph2: "Käsittelemme tiliäsi parhaillaan. Saat vahvistuksen sähköpostiisi kun käyttäjätili on valmis.",
	AccountInReviewModal_CloseButton: "Ok, aloitetaan!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Emme pysty vahvistamaan aktivointikoodiasi ja sähköpostiosoitettasi",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Ole hyvä ja yritä uudelleen tai ota yhteys yrityksen tilin ylläpitäjään",
	TryAgain_Button: "Yritä uudelleen",
	Error_Title: "Hupsista, jokin meni pieleen!",
	SettingsMyProfileActivationSuccessModal_Title: "Onnistui!",
	AccountInReviewModal_Description_Paragraph3: "Voit tällä välin tutusta sivumme avoimeen sisältöön.",
	AccountInReviewModal_Description_Paragraph4: "Mikäli sinulla on kysymyksiä tai et ole saanut sähköpostia 48h sisään (arkisin), ole hyvä ja ota yhteyttä meihin [tästä]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Tästä apuja alkuun...",
	Support_FAQ_Headline: "Usein kysytyt kysymykset",
	Support_FAQs_section1_title: "Yleistä",
	Support_FAQs_section1_question1: "Mikä on Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions on alusta joka tarjoaa kattavan yleiskuvan teknisistä koulutuksista ja mahdollistaa koulutuksiin ilmoittautumisen sekä hallinnoimisen",
	Support_FAQs_section1_question2: "Minulla on jo Bosch tili (singleKey ID) voinko käyttää sitä kirjautumiseen?",
	Support_FAQs_section1_question2_answer1: "Kyllä voit, sivuille voi kirjautua kyseisillä tunnuksilla kohdasta \"Minun Bosch ID\".",
	Support_FAQs_section1_question3: "Loin tilin Bosch Training Solutions sivulle, voinko käyttää samaa tunnusta muissa Bosch sivustoissa?",
	Support_FAQs_section1_question3_answer1: "Kyllä, voit käyttää omaa Bosch ID tiliä useaan eri Bosch sivustoon - sekä yritys- että yksityiskäyttöön.",
	Support_FAQs_section2_title: "Koulutus",
	Support_FAQs_section2_question1: "Kuinka ilmoittaudun koulutukseen?",
	Support_FAQs_section2_question1_answer1: "Kirjaudu sisään tai rekisteröidy sivustolle. Valitse \"Tekniset koulutukset\" tai \"Tapahtumakalenteri\", valitse haluamasi koulutus ja lisää se ostoskoriin.",
	Support_FAQs_section2_question2: "Haluamaani koulutusta ei ole valittavissa. Mitä tehdä?",
	Support_FAQs_section2_question2_answer1: "Sinulla on mahdollisuus lisätä itsesi jonotuslistalle. Otamme yhteyttä heti kun toivomasi koulutus on tarjolla.",
	Support_FAQs_section2_question3: "Mistä löydän yleiskatsauksen kursseista joille olen ilmoittautunut?",
	Support_FAQs_section2_question3_answer1: "Löydät yleiskatsauksen kaikista varauksista ja suoritetuista kursseista heti kirjautumisen jälkeen aloitussivulta.",
	Support_FAQs_section3_title: "Asetukset",
	Support_FAQs_section3_question1: "Kuinka voin vaihtaa sähköpostiosoitteni ja salasanani?",
	Support_FAQs_section3_question1_answer1: "Kirjaudu portaaliin tehdäksesi muutoksia käyttäjätiliisi. Löydät kotisivulta käyttäjätiliisi liittyvät tiedot sekä yläreunan profiilipainikkeesta.",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Täältä voit muuttaa Bosch ID tilisi tietoja",
	Support_FAQs_section3_question2: "Mitä tehdä jos unohdin salasanani?",
	Support_FAQs_section3_question2_answer1: "Paina kirjaudu/rekisteröidy painiketta ja täytä sähköpostiosoitteesi",
	Support_FAQs_section3_question2_answer2: "Kun painat jatka saat vaihtoehdoksi klikata \"Unohdin salasanani\"",
	Support_FAQs_section3_question3: "Kuinka luon tilin korjaamoyritykselleni?",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Valitse maa / alue",
	MaintenanceBanner_Message_1: "Bosch Automotive Training Solutions -portaali päivitetään [maintenance_schedule]. Päivitys kestää noin 30 minuuttia. Pääsy portaaliin tällä välin ei ole mahdollista.",
	MaintenanceBanner_Message_2: "\Mikäli kaipaat lisää apua, voit ottaa yhteyttä lähettämällä sähköpostia osoitteeseen [support_email]",
	MaintenancePage_Message_1: "Bosch Automotive Training Solutions -portaalia päivitetään",
	MaintenancePage_Message_2: "Portaalin tulisi olla käytettävissä taas [maintenance_online_date] klo [maintenance_online_time]",
	MenuHeader_Support: "Tuki",
	Course_Detail_No_Prerequisites: "Ei esivaatimuksia",
	TableCell_Hours: "Tuntia",
	Dashboard_MenuItem_Dashboard: "Aloitussivu",
	Dashboard_MenuItem_Curricula: "Koulutussuunnitelma",
	Dashboard_MenuItem_Certificates: "Todistukset",
	Dashboard_MenuItem_Team: "Jäsenet",
	Dashboard_MenuItem_Workshops: "Korjaamot",
	Dashboard_MenuItem_External: "Ulkoinen",
	Dashboard_MenuItem_Wallet: "Lompakko",
	Dashboard_MenuItem_Profile: "Profiili",
	Dashboard_Headline_YourUpcomingCourses: "Tulevat kurssisi",
	Dashboard_Headline_YourELearningCourses: "Tulevat eLearning -kurssisi",
	Dashboard_Headline_TeamOverview: "Jäsenten yleiskatsaus",
	Dashboard_Headline_YourOverview: "Sinun yleiskatsaus",
	Dashboard_Headline_YourWishlist: "Sinun jonotuslista",
	Dashboard_Headline_ActivePrograms: "Aktiivinen koulutustutkinto",
	Dashboard_Headline_CompletedPrograms: "Suoritetut koulutusohjelmat",
	Dashboard_Headline_YourCertificates: "Todistuksesi",
	Dashboard_Table_NoRecordsToDisplay: "Ei tuloksia",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "tuntia",
	Dashboard_ELearningCourses_TableHeader_Title: "Otsikko",
	Dashboard_ELearningCourses_TableHeader_Duration: "Kesto (t)",
	Dashboard_ELearningCourses_ActionButton_View: "Näytä",
	Dashboard_UpcomingCourses_TableHeader_Title: "Otsikko",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Päivämäärä (päivät)",
	Dashboard_UpcomingCourses_TableHeader_Location: "Sijainti",
	Dashboard_UpcomingCourses_ActionButton_View: "Näytä",
	Dashboard_YourOverview_TableHeader_Title: "Otsikko",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Päiväys / Kesto",
	Dashboard_YourOverview_TableHeader_Location: "Sijainti",
	Dashboard_YourOverview_TableHeader_Status: "Tila",
	Dashboard_YourOverview_ActionButton_View: "Näytä",
	Dashboard_YourWishlist_TableHeader_Title: "Otsikko",
	Dashboard_YourWishlist_TableHeader_Action: "Tila",
	Dashboard_YourWishlist_ViewAllRecords: "Näytä kaikki merkinnät",
	Dashboard_TeamOverview_TableHeader_Employee: "Työntekijä",
	Dashboard_TeamOverview_TableHeader_Title: "Otsikko",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Päiväys / Kesto",
	Dashboard_TeamOverview_TableHeader_Location: "Sijainti",
	Dashboard_TeamOverview_TableHeader_Status: "Tila",
	Dashboard_TeamOverview_ActionButton_View: "Näytä",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Hae",
	Dashboard_ActiveProgram_TableHeader_Title: "Otsikko",
	Dashboard_ActiveProgram_TableHeader_Status: "Tila",
	Dashboard_ActiveProgram_ActionButton_View: "Näytä",
	Dashboard_CompletedProgram_TableHeader_Title: "Otsikko",
	Dashboard_CompletedProgram_TableHeader_Status: "Tila",
	Dashboard_CompletedProgram_ActionButton_View: "Näytä",
	Dashboard_Certificates_TableHeader_Title: "Otsikko",
	Dashboard_Certificates_TableHeader_Date: "Päiväys",
	Dashboard_Certificates_TableHeader_Status: "Tila",
	Dashboard_Certificates_TableHeader_Action: "Tila",
	Dashboard_Certificates_ActionButton_Download: "Lataa",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Laskun numero",
	Dashboard_Transactions_TableHeader_Description: "Seloste",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Maksutapa",
	Dashboard_Transactions_TableHeader_Date: "Päiväys",
	Dashboard_Transactions_TableHeader_Amount: "Summa",
	Dashboard_Transactions_ActionButton_Download: "Lataa",
	Breadcrumb_MyTraining: "Koulutukseni",
	Breadcrumb_MyTraining_CourseDetails: "Kurssin lisätiedot",
	Breadcrumb_MyTraining_Curricula: "Koulutussuunnitelma",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Koulutustutkinnon lisätiedot",
	Breadcrumb_MyTraining_Team: "Jäsenet",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Näytä työntekijä",
	MyTraining_Headerbar_CourseDetails: "Koulutuksen lisätiedot",
	MyTraining_Headerbar_TrainingProgramDetails: "Koulutustutkinnon lisätiedot",
	TrainingProgramDetails_Label_Status: "Tila",
	TrainingProgramDetails_Label_OverallProgress: "Kokonaistila",
	TrainingProgramDetails_ProgramItems_Headline: "Tutkinnon osiot",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Otsikko",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Tila",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Arvosana",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Päiväys",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Näytä",
	TrainingProgramDetails_Status_InProgress: "Käynnissä",
	TrainingProgramDetails_Status_Completed: "Valmis",
	CourseDetails_Label_Status: "Tila",
	CourseDetails_Label_Duration: "Kesto",
	CourseDetails_Label_Trainer: "Kouluttaja",
	CourseDetails_Label_Language: "Kieli",
	CourseDetails_Label_Score: "Tulos:",
	CourseDetails_Label_StartDate: "Aloituspäivä",
	CourseDetails_Label_EndDate: "Lopetuspäivä",
	CourseDetails_Label_Venue: "Sijainti",
	CourseDetails_Label_Address: "Osoite",
	CourseDetails_Label_Grade: "Arvosana",
	CourseDetails_Action_Withdraw: "Peruuta",
	CourseDetails_Action_Evaluate: "Arvioi koulutus",
	CourseDetails_Action_Evaluate_Description: "Arvioi koulutus jotta saat todistuksesi",
	CourseDetails_Action_DownloadCertificate: "Lataa todistus",
	CourseDetails_Action_JoinWebcast: "Liity Webcast -tilaisuuteen",
	CourseDetails_Action_StartCourse: "Aloita kurssi",
	CourseDetails_Action_RestartCourse: "Aloita kurssi uudelleen",
	CourseDetails_Action_RepeatCourse_Description: "Tarvitset hyväksytyn tuloksen, ole hyvä ja yritä uudelleen, jotta voit ladata todistuksen.",
	CourseDetails_PartSpecificDetails_Tab_Part: "Osa",
	CourseDetails_PartSpecificDetails_Description: "Seloste",
	CourseDetails_PartSpecificDetails_StartDate: "Aloituspäivä",
	CourseDetails_PartSpecificDetails_EndDate: "Lopetuspäivä",
	CourseDetails_PartSpecificDetails_StartTime: "Aloitusaika",
	CourseDetails_PartSpecificDetails_EndTime: "Lopetusaika",
	CourseDetails_PartSpecificDetails_Method: "Tyyppi",
	Dashboard_Team_TableHeader_Name: "Nimi",
	Dashboard_Team_TableHeader_Role: "Rooli",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Henkilökohtainen tili",
	Dashboard_Team_ActionButton_Select: "Valitse",
	Dashboard_Team_PersonalAccount_Activated: "Aktiivinen",
	Dashboard_Team_PersonalAccount_NotActivated: "Ei aktiivinen",
	Dashboard_Team_ActionButton_AddMember: "Lisää jäsen",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Hae",
	Dashboard_External_TableHeader_Name: "Nimi",
	Dashboard_External_ActionButton_AddNew: "Lisää uusi",
	Dashboard_External_Headline: "Ulkoinen pääsy hyväksytty",
	Dashboard_Workshops_Headline: "Korjaamot",
	Dashboard_Workshops_TableHeader_Company: "Yritys",
	Dashboard_Workshops_TableHeader_Address: "Osoite",
	Dashboard_Workshops_TableHeader_Postcode: "Postinumero",
	Dashboard_Workshops_TableHeader_City: "Postitoimipaikka",
	Dashboard_Workshops_ActionButton_Select: "Valitse",
	Wallet_ServiceCredits: "Kreditit",
	Wallet_Headline_CreditBalance: "Kreditit",
	Wallet_Headline_CreditActivity: "Kredittien käyttö",
	Wallet_Headline_BillingTransactions: "Laskutustapahtumat",
	Wallet_BillingTransaction_TableHeader_Order: "Tilaus",
	Wallet_BillingTransaction_TableHeader_Date: "Päiväys",
	Wallet_BillingTransaction_TableHeader_Amount: "Summa",
	Wallet_BillingTransaction_TableHeader_Action: "Tapahtuma",
	Wallet_CreditActivity_TableHeader_Date: "Päiväys",
	Wallet_CreditActivity_TableHeader_Description: "Seloste",
	Wallet_CreditActivity_TableHeader_User: "Käyttäjä",
	Wallet_CreditActivity_TableHeader_Amount: "Summa",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, all rights reserved",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Tuotesuoja (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovaatiot, patentit ja lisenssit",
	FooterAdditionalLinks_Navigation_Logistics: "Hankinta ja logistiikka",
	MenuHeader_MyTraining: "Koulutukseni",
	MenuHeader_TrainingServices: "Koulutuspalvelut",
	MenuHeader_OurLocations: "Toimipisteemme",
	MenuHeader_ContactsUs: "Ota yhteyttä",
	MenuHeader_Logout: "Kirjaudu ulos",
	MenuHeader_FAQ: "UKK",
	MyProfile_MyBoschId: "Minun Bosch ID",
	MyProfile_AccountDeletion: "Poista tili",
	MyProfile_Edit: "Vaihda sähköposti tai salasana",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Muuta käyttäjtiliä",
	BackButton: "Takaisin",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Olemme tavoitettavissa 24/7, odotamme yhteydenottoasi.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Syötä merkit yllä olevasta laatikosta",
	ContactUs_Email_MandatoryNotice_V3: "Täytä puuttuvat kentät",
	ContactUs_Email_SendButton: "Lähetä",
	Breadcrumb_TrainingCenters: "Koulutuskeskukset",
	Training_Centres_Book_Btn: "Varaa kurssi",
	Training_centres_booking_code: "Varauskoodi",
	Training_centers_header_bar_title: "Koulutuskeskuksemme",
	Training_centers_filter_item: "Koulutuskeskus",
	TeamEdit_PersonalAccountToolTop: "Tämän toiminnon aktivoiminen antaa työntekijälle mahdollisuude kirjautua sivulle. Käyttäjä voi luoda itselleen oman käyttäjätilin. Kun tämä toiminto on aktivoitu, voit kutsua työntekijän muodostamalla aktivointikoodin. Tarvittavat tiedot lähetetään kutsussa sähköpostilla.",
	TeamEdit_Update: "Yrityksen järjestelmänvalvojana työntekijälle annetaan kaikki oikeudet portaaliin. Toiminnon aktivoitumiseksi käyttäjän tulee kirjautua ulos ja uudelleen sisään.",
	TeamEdit_PersonalAccount: "Salli henkilökohtainen Bosch Training Solutions käyttäjätili",
	TeamEdit_SendInvitation: "Lähetä kutsu",
	TeamEdit_Deactivate: "Deaktivoi",
	TeamEdit_Telephone: "Puhelin",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Vain numeroita",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Vain numeroita",
	TeamEdit_Activate: "Aktiivinen",
	TeamEdit_NotActivated: "Ei aktiivinen",
	TeamEdit_StatusPopup: "Tila näkyy 'aktiivisena' kun käyttäjä on onnistuneesti luonut iselleen tilin. Jos tila on edelleen 'Ei aktiivinen' ei käyttäjä ole vielä viimeistellyt rekisteröitymistään.",
	Training_Catalog_Courses: "Kurssit",
	Training_Catalog_Course: "Kurssi",
	Reset_Filter: "Nollaa suodattimet",
	TrainingCatalogue_CourseCatalogue: "Koulutusluettelo",
	CheckoutHeader_CartItemCount_Text: "Sinulla on [count] tuote ostoskorissasi",
	CheckoutHeader_CartItemsCount_Text: "Sinulla on [count] tuotetta ostoskorissasi",
	ShoppingCart_PriceItem: "Hinta",
	ShoppingCartItemParticipants_AddParticipant_Button: "Lisää osallistujia",
	ShoppingCartTotalPrice_SubTotalLabel: "Välisumma",
	ShoppingCartTotalPrice_TaxesLabel: "Verot",
	ShoppingCartTotalPrice_TotalLabel: "Yhteensä",
	ShoppingCartTotalPrice_IncludedVATLabel: "Sisältää [vat_amount] ALV",
	AddVoucher_AvailablePaymentMethods: "Online maksutavat",
	ShoppingCartItemRow_VenueFeeLabel: "Hinta per osallistuja [fee_amount]",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Lisää osallistuja",
	AddParticipantsModal_SelectEmployeeLabel: "Lisää osallistuja",
	AddParticipantsModal_SelectWorkshopLabel: "Valitse korjaamo",
	ShoppingCartTotalPrice_ContinueButtonText: "Siirry maksamaan",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Osallistujaa ei ole lisätty!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Sinulla on yksi tai useampi koulutus ostoskorissa ilman osallistujaa",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Lisää osallistuja ja jatka maksamaan",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Muokkaa osallistujia ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nimi",
	AddParticipantsModal_ParticipantTable_Email: "Email",
	AddParticipantsModal_ParticipantTable_Action: "Tila",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Ei osallistujia",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Osallistujalle ei ole määritetty sähköpostiosoitetta",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Valitulla osallistujalla ei ole määritettyä sähköpostiosoitetta. Sähköpostiosoite on pakollinen koulutukseen ilmoittautuessa.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Lisää sähköpostiosoite jatkaaksesi, peruuta tai valitse toinen osallistuja",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Lisää sähköposti",
	CancelButton: "Peruuta",
	AddParticipantsModal_AddEmployeeButtonText: "Lisää työntekijä",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Olet poistamassa koulutuksen ostoskoristasi",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Olet poistamassa koulutusta ostoskoristasi johon on määritetty osallistujia",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Klikkaa \"Jatka\" jos haluat eteenpäin tai klikkaa \"Peruuta\"",
	ShoppingCartItemHeader_Participants: "Osallistuja(t)",
	ShoppingCartItemHeader_ParticipantPrice: "Hinta per osallistuja",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Näytä [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Näytä krediitteinä",
	ShoppingCart_CreditsPriceUnit: "Kreditit",
	CheckOutHeader_Step1_Question: "Kuinka haluaisit maksaa?",
	CheckOutHeader_Step2_Question: "Tarkista tilauksesi",
	CheckOutHeader_Step3_Question: "Viimeistele tilauksesi",
	CheckOutHeader_Step4_Question: "Varauksesi onnistui!",
	CheckOutHeader_Step_Payment: "Maksu",
	CheckOutHeader_Step_Review: "Tarkastele",
	CheckOutHeader_Step_Place_Order: "Tee varaus",
	CheckOutRightPanel_Review_Order_Btn: "Tarkastele tilausta",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Tee varaus",
	CheckoutRightPanelContent_OrderSummary_Title: "Tilaushistoria",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Muokkaa ostoskoria",
	ShoppingCart_Participants: "Osallistujat",
	CheckOutStepPayment_Payment_Methods_Title: "Valitse maksutapa",
	CheckOutStepPayment_Credit_Title: "Credit vai Debit",
	CheckOutStepPayment_Wholesaler_Title: "Tukkumyyjä",
	CheckOutStepPayment_ServiceCredit_Title: "Kreditit",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Sinulla on [available_credit] kredittiä",
	CheckOutStepPayment_BoschAccount_Title: "Bosch tili",
	Checkout_Second_PaymentMethod_Headline: "Maksutapa",
	CheckOutStepReview_Edit: "Muokkaa",
	CheckOutStepReview_Terms_Label: "Käyttö- ja peruutusehdot",
	CheckOutStepReview_Terms_Text: "Olen lukenut, ymmärtänyt ja hyväksyn [terms, conditions and cancellation policy)",
	SelectSecondPaymentMethodModal_Title: "Valitse toissijainen maksutapa",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Maksa [amount]",
	StepPaymentWholesaler_Title: "Valitse tukkumyyjä",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Hyväksy käyttöehdot",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Ole hyvä ja lue käyttö- ja peruutusehdot ennen jatkamista",
	CheckoutDescription_ThankYou: "Kiitos tilauksesta!",
	CheckoutDescription_Message: "Varauksesi on työn alla. Saat vahvistuksen kun olemme käsitelleet tilauksen.",
	CheckoutDesctiption_Text: "Lähetämme tilausvahvistuksen sähköpostiin.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Olet poistamassa valitun korjaamon",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Poistamalla kyseisen korjaamon kaikki työntekijät poistetaan. Tämä vaikuttaa kaikkiin ostoskorissa oleviin koulutuksiin.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klikkaa \"Jatka\" jatkaaksesi tai paina \"Peruuta\" lopettaaksesi.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Sinulla ei ole riittävästi kredittejä. Sinulla on [available_credit] kredittiä, mutta tarvitset [cart_total_credits] kredittiä jatkaaksesi. Ole hyvä ja valitse toinen maksutapa tai tyhjennä ostoskoriasi.",
	Training_Programs_Filter_Item: "Koulutustutkinnot",
	Training_Program_Filter_Item: "Tutkinto",
	Training_Program_Year: "Vuotta",
	Training_Program_Detail_Title: "Koulutustutkinnot",
	AccountButton_Workshops_MenuItem: "Korjaamot",
	AccountButton_Wallet_MenuItem: "Lompakko",
	Header_MyBoschProfile_Header: "MyBosch -profiilini",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Käytettävissä",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Lisää tai muuta osallistujia",
	ShoppingCart_CreditPriceUnit: "Kredittiä",
	Common_IncludedVAT: "Sis. ALV",
	TrainingCatalogue_Length: "Kesto",
	TrainingCatalogue_Method: "Tyyppi",
	Course_Price_Credits: "Kredittiä",
	Catalogue_Grid_Register_Button: "Rekisteröidy",
	Catalogue_Credit_Tilte: "Klikkaa tästä nähdäksesi hinta kreditteinä",
	Catalogue_Price_Tilte: "Klikkaa tästä nähdäksesi hinta euroissa",
	Catalogue_Grid_Vat: "Ei sis. ALV",
	EventCalendar_Seats: "Paikkoja",
	MenuHeader_Calendar: "Kalenteri",
	MenuHeader_Catalog: "Luettelo",
	TableCell_Minutes: "Minuttia",
	ListFilter_NoResult: "Ei tuloksia",
	Course_Description: "Koulutuksen seloste",
	Training_course_detail: "Sosiaalisen media perusteet",
	Training_course_detail_add_waiting: "Lisää jonotuslistalle",
	Register_now_btn: "Rekisteröidy nyt!",
	Register_now_btn_add_event_to_cart: "Rekisteröidy nyt!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Näytä kaikki tapahtumat",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Näytä vähemmän",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Näytä kaikki tapahtumat",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Näytä vähemmän",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Näytä kaikki tapahtumat",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Näytä vähemmän",
	TeamCreate_TitleError: "Titteli vaaditaan",
	Dashboard_Headerbar_BookCourse: "Ilmoittaudu koulutukseen",
	Dashboard_Headerbar_EvaluateCourse: "Arvioi koulutus",
	Dashboard_Headerbar_EvaluateCourse_Note: "Arvioi koulutus saadaksesi todistus",
	Dashboard_Headerbar_DownloadCertificate: "Lataa todistus",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Peruuta",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Peruuta",
	Dashboard_Headerbar_WelcomeMessage: "Tervetuloa aloitussivulle",
	Dashboard_TrainingProgram_Status_Completed: "Suoritettu",
	Dashboard_TrainingProgram_Status_InProgress: "Käynnissä",
	External_SelectWholesalerModal_Title: "Lisää ulkoinen pääsy",
	External_SelectWholesalerModal_Company_Label: "Yritys",
	External_SelectWholesalerModal_Company_Placeholder: "Valitse",
	External_SelectWholesalerModal_Outlet_Placeholder: "Valitse",
	External_SelectWholesalerModal_ApplyButton: "Vahvista",
	External_SelectWholesalerModal_CancelButton: "Peruuta",
	External_RemoveWholesalerModal_ConfirmLabel: "Vahvistus",
	External_SelectWholesalerModal_ConfirmMessage: "Oletko varma että haluat poistaa tukkurin?",
	External_SelectWholesalerModal_YesButton: "Kyllä",
	External_SelectWholesalerModal_NoButton: "Ei",
	Training_program_team_member: "Jäsen",
	Training_program_assign_training_program: "Määritä koulutustutkinto",
	Assign_modal_student_confirmation_text1: "Olet lisäämässä [Training Program Name] -tutkinnon koulutussuunnitelmaasi",
	Assign_modal_student_confirmation_text2: "Haluatko jatkaa?",
	MyFullCalendar_Jan: "Tam",
	MyFullCalendar_Feb: "Hel",
	MyFullCalendar_Mar: "Maa",
	MyFullCalendar_Apr: "Huh",
	MyFullCalendar_May: "Tou",
	MyFullCalendar_Jun: "Kes",
	MyFullCalendar_Jul: "Hei",
	MyFullCalendar_Aug: "Elo",
	MyFullCalendar_Sep: "Syy",
	MyFullCalendar_Oct: "Lok",
	MyFullCalendar_Nov: "Mar",
	MyFullCalendar_Dec: "Jou",
	MenuHeader_CourseCatalog: "Koulutusluettelo",
	MenuHeader_Event: "Tapahtumakalenteri",
	MenuHeader_Program: "Koulutusohjelma",
	MenuHeader_Services: "Koulutuspalvelut",
	MenuHeader_Log_out: "Kirjaudu ulos",
	Filter_Location: "Sijainti",
	TeamEdit_UpgradeToCompanyAdministrator: "Aseta yrityksen järjestelmänvalvojaksi",
	TeamEdit_PersonalWebAccountStatusIs: "Henkilökohtaisen tilin tila",
	Header_CreateNewEmployee: "Lisää uusi työntekijä",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Ei vapaita paikkoja!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Tähän koulutukseen ei ole enää vapaita paikkoja",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Ole hyvä ja valitse toinen koulutus tai poista lisäämäsi osallistuja jatkaaksesi",
	Common_SuccessModal_Title: "Onnittelut!",
	TrainingProgramDetails_Merit_StatusText: "Läpäisty",
	TrainingProgramDetails_Resit_StatusText: "Hylätty",
	EmployeeProfile_UserExistErrorMessage: "Tili on jo olemassa!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Maksu epäonnistui. Ole hyvä ja yritä uudelleen tai valitse toinen maksutapa",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Olet deaktivoinut valitsemasi työntekijän!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Tallennettu!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Muutokset tallennettu!",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "eLearning kurssiln peruminen ei ole mahdollista. Ole hyvä ja ota yhteyttä meihin.",
	Dashboard_Team_PersonalAccount_Pending: "Odottaa",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Työntekijä luotu!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Työntekijän henkilökohtainen tili luotu!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Tämä asetus antaa sinulle mahdollisuden rajoittaa työntekijöiden omatoimisen ilmoittautumisen koulutuksiin. Vain yrityksen järjestelmänvalvojat voivat ilmoittaa osallistujia kursseille kun tämä valinta ei ole aktiivinen.",
	Training_catalog_sort_by: "Lajittele",
	CourseDetail_EvaluationRestriction_Title: "Koulutusarviointi ei mahdollinen",
	CourseDetail_EvaluationRestriction_Message: "Koulutusarvioinnin voi tehdä vain itse osallistuja. \n Ohjeista osallistujaa kirjautumaan portaaliin ja täyttämään palautelomake.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Jos osallistujalla ei ole jo tiliä, voi kutsua hänet osaksi yrityksen tiliä.",
	Breadcrumb_Download_Page: "Lataukset",
	Download_Table_Category_Cell: "Kategoria",
	Download_Table_Title_Cell: "Otsikko",
	Download_File_Filter_Item: "Tiedosto",
	Download_File_Filter_Items: "Tiedostot",
	Download_Header: "Latausalue",
	DownloadArea_Button_Download: "Lataa",
	Popup_Title_Error: "Virhe",
	Popup_Title_Success: "Valmis",
	Popup_Title_Info: "Tiedot",
	Popup_Title_Warning: "Varoitus!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Seuraavien mahdollisten tapahtumien jo olemassa olevia ilmoittautumisia ei peruuteta, ja ne on peruutettava yksitellen.",
	TrainingProgram_PDF_Export: "PDF -vienti",
	TrainingProgram_PDF_Export_Participant: "Osallistujat:",
	TrainingProgram_PDF_Export_Status: "Tila:",
	TrainingProgram_PDF_Export_Progress: "Kokonaisedistyminen:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Koulutuksen otsikko",
	TrainingProgram_PDF_Export_TableHeader_Status: "Tila",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Arvosana",
	TrainingProgram_PDF_Export_TableHeader_Date: "Päivämäärä",
	AccountButton_CompanyChange_MenuItem: "Yrityksen vaihto",
	SelectCompanyModal_Title: "Valitse yritys",
	CloseButton: "Sulje",
	Change_Button: "Muuta",
	ChangeManagerModal_Content: "Valitse käyttäjä",
	ChangeCompanyModal_HeadOffice: "(Pääkonttori)",
	SelectCompanyModal_Content: "Kirjaudu sisään valitsemalla yritys...",
	AlertSuccess_Title: "Valmis",
	CheckOutStepPayment_SecondaryPayment_Title: "Valitse toissijainen maksutapa",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Lisää toissijainen maksutapa",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Lisää toissijainen maksutapa alle tai valitse toinen maksutapa yläpuolelta.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Tililläsi ei ole tarpeeksi kredittejä ostoskorin tuotteiden maksamiseen. Jos haluat jatkaa valitun maksutavan käyttöä, sinun on maksettava erotus. Erotus on [difference_amount] (ilman alv).",
	ShoppingCartTotalPrice_TotalInCredit: "Kokonaishinta kreditteinä",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Käytettävissä olevat kreditit",
	ShoppingCartTotalPrice_YourDifference: "Erotus",
	ShoppingCartTotalPrice_SubtotalExTax: "Välisumma ilman ALV",
	ShoppingCartTotalPrice_SubtotalInTax: "Välisumma sis. ALV",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Toissijainen maksutapa",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Muokkaa maksutapaa",
	Checkout_Second_PaymentMethod: "Toinen maksutapa",
	Breadcrumb_TrainingCourseDetails: "Koulutuskurssin yksityiskohdat",
	DownloadArea_FailedToDownload_ErrorMessage: "Asiakirjan lataaminen epäonnistui. Yritä uudelleen tai ota yhteyttä yrityksesi järjestelmänvalvojaan saadaksesi apua.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Ladattava tiedosto ei ole saatavilla. Yritä uudelleen tai ota yhteyttä yrityksesi järjestelmänvalvojaan saadaksesi apua.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Tiedoston lataaminen on kielletty. Yritä uudelleen tai ota yhteyttä yrityksesi järjestelmänvalvojaan saadaksesi apua.",
	SSO_Redirecting_Message: "Sinut uudelleenohjataan Bosch-koulutusportaaliin…",
	SSO_Redirecting_ParameterErrorMessage: "Maa ja/tai kieli ei ole määritelty. Ole hyvä ja yritä uudelleen tai ota yhteyttä yrityksesi pääkäyttäjään ongelman ratkaisemiseksi.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Ilmoittautumista ei hyväksytty.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Tämä saattaa johtua kurssille määritellyn ilmoittautumisperiodin päättymisestä. Voit kysyä meiltä lisätietoja yhteydenotto-sivun kautta.",
	TimeSlotPicker_DateFormat_Title: "Päivämäärän esitysmuoto",
	UpdateAccountSettingsConfirmationModal_Title: "Olet muuttamassa päivämäärän esitysmuotoa.",
	UpdateAccountSettingsConfirmationModal_Message1: "Päivämäärän esitysmuoto näytetään valitsemallasi tavalla ja voit vaihtaa sitä tarvittaessa.",
	UpdateAccountSettingsConfirmationModal_Message2: "Haluatko jatkaa?",
	ProfileSummary_Settings_Title: "Asetukset",
	UpdateAccountSettings_DateFormat_Title: "Päivämäärän esitysmuodon asetukset",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Päivämäärän esitysmuodon päivitys epäonnistui.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Päivämäärän esitysmuoto on päivitetty.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Lataa todistus",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Tutkintotodistus",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Dokumentin lataus ei onnistunut. Ole hyvä ja yritä uudelleen tai ota yhteyttä yrityksen järjestelmän valvojaan.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Peruutuskiintiö ylittynyt",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Pyyntöä käsitellään. Olemme yhteydessä kun pyyntö on käsitelty.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Vahvistus lähtetään sähköpostiin",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Saat lisätietoja [peruutusehdoistamme]",
	TrainingCourse_Course_NotAvailable_Title: "Kurssia ei ole tarjolla.",
	TrainingCourse_Course_NotAvailable_Message: "Koulutusta ei ole tarjolla. Ole hyvä ja ota yhteys yrityksen järjestelmän valvojaan.",
	TrainingCourse_NotAvailable_Message_1: "Olet siirtymässä koulutuksen tietoihin joka ei ole saatavilla.",
	TrainingCourse_NotAvailable_Message_2: "Ole hyvä ja yritä uudelleen tai siirry [training courses] kohtaan tarkastellaksesi muita kursseja.",
	LanguageSelection_SelectALanguage: "Valitse kieli",
	LanguageSelection_SelectYourCountry: "Valitse maa",
	Checkout_PaymentMethod_Headline: "Maksutapa",
	Download_Categories_Catalogue: "Luettelo",
	Download_Categories_Calendar: "Kalenteri",
	Download_Categories_General: "Yleistä",
	Download_Categories_Information: "Tietoa",
	Download_Categories_Userguides: "Käyttöohje",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Käyttö- ja peruutusehdot",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Peruuta",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Jatka",
	Duration_Seconds: "Sekuntia",
	Duration_Day: "Päivä(ä)",
	Duration_Hour: "Tunti(a)",
	Duration_Minute: "Minuutti(a)",
	Duration_Second: "Sekunti",
	EventCalendar_ExternalLink_Tooltips: "Suorita maksu [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Peruuta",
	EventCalendar_ExternalLink_Continue: "Jatka",
	EventCalendar_ExternalLinkModal_Title: "Uudelleenohjataan…",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Kyseinen tilaisuus on myynnissä externalRegistrationName] kautta",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Jatkamalla uudelleenohjaamme sinut ulkopuoliselle sivulle",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Haluatko jataka?",
	Team_ContactPerson_FirstName_Tooltips: "Osallistujan nimen voi muuttaa vain Bosch koulutushallinnointi. Ole hyvä ja ota yhteyttä oheisella yhteydenottolomakkeella.",
	Team_ContactPerson_LastName_Tooltips: "Osallistujan nimen voi muuttaa vain Bosch koulutushallinnointi. Ole hyvä ja ota yhteyttä oheisella yhteydenottolomakkeella.",
	Withdraw_ExternalLink_Cancel: "Peruuta",
	Withdraw_ExternalLink_Confirm: "Vahvista",
	Withdraw_ExternalLinkModal_Title: "Uudelleenohjataan",
	Withdraw_ExternalLinkModal_Message: "Peruutukset käsittelee ulkopuolinen koulutustarjoaja",
	Withdraw_ExternalLinkModal_Confirmation: "Klikkaamalla \”Vahvista\” avautuu uusi välilehti, joka ohjaa sinut ulkopuoliselle sivustolle",
	Button_AddToWaitingList_Tooltips: "Lisää jonotuslistaan",
	Button_AddToCart_Tooltips: "Lisää ostoskoriin",
	Button_TrainingCourseDetail_Tooltips: "Katso lisätietoja",
	Button_TrainingProgramDetail_Tooltips: "Katso lisätietoja",
	Button_AssignTrainingProgram_Tooltips: "Lisää koulutusohjelmaan",
	Button_DeleteFromCart_Tooltips: "Poista ostoskorista",
	Button_RemoveParticipant_Tooltips: "Poista osallistuja",
	Button_DownloadBillingTransaction_Tooltips: "Lataa",
	Button_RemoveExternal_Tooltips: "Poista",
	Breadcrumb_TrainingServices: "Koulutukset",
	Breadcrumb_Support: "Tuki",
	Breadcrumb_FAQs: "UKK",
	Breadcrumb_TrainingProgramDetail: "Koulutusohjelmat",
	Breadcrumb_MyTraining_MyTrainingEvents: "Koulutukseni",
	Breadcrumb_MyTraining_External: "Ulkoinen",
	Breadcrumb_MyTraining_Wallet: "Lompakko",
	Breadcrumb_MyTraining_Workshop: "Yritys",
	Breadcrumb_MyTraining_Workshop_Team: "Jäsenet",
	Breadcrumb_MyTraining_Team_Profile: "Profiili",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Muokkaa profiilia",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Lisää työntekijä",
	SideNavigation_Title: "Koulutukset",
	SideNavigation_AccountButton_RegisterOrLogin: "Rekisteröidy / kirjaudu sisään",
	SideNavigation_Language: "Kieli",
	SideNavigation_SelectLanguage: "Valitse kieli",
	SideNavigation_AccountButton_Logout: "Kirjaudu ulos",
	SideNavigation_ConntactUs: "Ota yhteyttä",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Yrityksen tiedot",
	FooterAdditionalLinks_Navigation_LegalNotice: "Oikeudellinen huomautus",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Tietosuojalausunto",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Tietosuoja-asetukset",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Käyttöehdot",
	Common_Version_Text: "Versio: {{version}}",
	Content_Header_Dashboard_MyTrainingEvents: "Koulutukseni",
	Content_Header_Dashboard_MyTrainingPrograms: "Koulutusohjelmani",
	Content_Header_Dashboard_Team: "Jäsenet",
	Content_Header_Dashboard_Workshops: "Yritykset",
	Content_Header_Dashboard_External: "Ulkoinen",
	Content_Header_Dashboard_Wallet: "Lompakko",
	Content_Header_Dashboard_MyProfile: "Profiilini",
	RedeemVoucher_RemoveButton: "Poista",
	RedeemVoucher_ApplyButton: "Vahvista",
	Checkout_VoucherApplied_Label: "Koodisi",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Tarkista voucher ¬-koodi",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Syötetty voucher -koodi on virheellinen",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Ole hyvä ja yritä uudelleen tai jatka ilman voucher -koodia",
	RedeemVoucherModal_CancelButton: "Peruuta",
	RedeemVoucherModal_TryAgainButton: "Yritä uudelleen",
	CreditsConfirmationModal_ContinueButton: "Jatka",
	CreditsConfirmationModal_CancelButton: "Peruuta",
	CreditsConfirmationModal_Title: "Voucher -koodi lisätty",
	CreditsConfirmationModal_Message_1: "Ostoskorissa on tuotteita voucher -koodilla, ole hyvä ja valitse jokin muu maksutapa krediittien sijaan",
	CreditsConfirmationModal_Message_2: "Klikkaa \”Peruuta\” palataksesi tai \”Jatka\” jos haluat poistaa voucher -koodin ostoskoristasi"
};