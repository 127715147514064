import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { http } from "../services/HttpService";
import { getPaymentMethodsAsync } from "./shoppingCartUserReducer";
import {
  GetPaymentMethodsResponse,
  ViaWholesaler,
} from "../models/ShoppingCartUser.Model";
import { RootState } from "./store";

export const getClientconfig =
  () => async (dispatch: (arg0: { payload: any; type: string }) => any) => {
    await http.get("/getClientConfig").then(
      (res) => {
        return dispatch(getconfigsuccess({ data: res.data }));
      },
      (error) => {
        return dispatch(getconfigsuccess({ error: error }));
      }
    );
  };
export const clearClientconfig =
  () => async (dispatch: (arg0: { payload: any; type: string }) => any) => {
    await dispatch(getconfigsuccess(null));
  };

export const initiatePayment =
  (paymentData: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => any) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API_KEY}/initiatePayment`, paymentData)
        .then((res) => dispatch(postinitiatePayment(res.data)))
        .catch((error) => {
          throw error;
        });
    } catch (e) {
      throw e;
    }
  };
export const clearinitiatePayment =
  () => async (dispatch: (arg0: { payload: any; type: string }) => any) => {
    await dispatch(postinitiatePayment(null));
  };
export const submitAdditionalPaymentData =
  (paymentDetailsData: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => any) => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_KEY}/submitAdditionalDetails`,
          paymentDetailsData
        )
        .then((res) => dispatch(postinitiatePayment(res.data)))
        .catch((error) => {
          throw error;
        });
    } catch (e) {
      throw e;
    }
  };

export type CheckoutStep = "Payment" | "Review" | "PlaceOrder" | "Complete";

interface ListStepState {
  id: CheckoutStep;
  label: string;
  iconName: string;
  index: number;
}

interface CheckOutState {
  listStepState: ListStepState[];
  currentStep: ListStepState;
  isBuySubscription: boolean;
  adyenErrorCode: number | null;
  selectedPaymentMethodId: number | null;
  selectedSecondPaymentMethodId: number | null;
  selectedWholesaler: ViaWholesaler | null;
  isAcceptPaymentTerms: boolean;
  isPerformStepValidation: boolean;
  configdetails: any;
  paymentMethods: GetPaymentMethodsResponse[];
  initiatePayment: any;
  additionalPaymentData: any;
  onHybridPayment: boolean;
}

const listStep: ListStepState[] = [
  {
    id: "Payment",
    label: `CheckOutHeader_Step_Payment`,
    iconName: "bosch-ic-payment",
    index: 1,
  },
  {
    id: "Review",
    label: `CheckOutHeader_Step_Review`,
    iconName: "bosch-ic-search",
    index: 2,
  },
  {
    id: "PlaceOrder",
    label: `CheckOutHeader_Step_Place_Order`,
    iconName: "bosch-ic-paperplane",
    index: 3,
  },
  {
    id: "Complete",
    label: `CheckOutHeader_Step_Place_Order`,
    iconName: "bosch-ic-paperplane",
    index: 4,
  },
];

const initialState: CheckOutState = {
  configdetails: null,
  paymentMethods: [],
  initiatePayment: null,
  additionalPaymentData: null,
  listStepState: listStep,
  currentStep: listStep[0],
  isBuySubscription: false,
  adyenErrorCode: null,
  selectedPaymentMethodId: null,
  selectedSecondPaymentMethodId: null,
  isAcceptPaymentTerms: false,
  isPerformStepValidation: false,
  selectedWholesaler: null,
  onHybridPayment: false,
};

export const CheckoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    getconfigsuccess: (state, action) => {
      state.configdetails = action.payload;
    },
    postinitiatePayment: (state, action) => {
      state.initiatePayment = action.payload;
    },
    submitAdditionalPaymentDetails: (state, action) => {
      state.additionalPaymentData = action.payload;
    },
    setListStepModal: (state, action: PayloadAction<ListStepState[]>) => {
      return {
        ...state,
        listStepState: action.payload,
      };
    },
    setCurrentStepModal: (state, action: PayloadAction<CheckoutStep>) => {
      const step = listStep.find((x) => x.id === action.payload);
      if (step) {
        return {
          ...state,
          currentStep: step,
        };
      }

      return {
        ...state,
      };
    },
    updateAdyenErrorCode: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        adyenErrorCode: action.payload,
      };
    },
    updateSelectedPaymentMethodId: (
      state,
      action: PayloadAction<number | null>
    ) => {
      return {
        ...state,
        selectedPaymentMethodId: action.payload,
      };
    },
    updateSelectedSecondPaymentMethodId: (
      state,
      action: PayloadAction<number | null>
    ) => {
      return {
        ...state,
        selectedSecondPaymentMethodId: action.payload,
      };
    },
    updateIsAcceptPaymentTerms: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAcceptPaymentTerms: action.payload,
      };
    },
    updateSelectedWholesaler: (
      state,
      action: PayloadAction<ViaWholesaler | null>
    ) => {
      return {
        ...state,
        selectedWholesaler: action.payload,
      };
    },
    updateIsPerformStepValidation: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isPerformStepValidation: action.payload,
      };
    },
    resetCheckoutState: (state) => {
      return {
        ...initialState,
      };
    },
    setOnHybridPayment: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        onHybridPayment: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethodsAsync.fulfilled, (state, { payload }) => {
      if (!!payload?.dataObject) {
        return {
          ...state,
          paymentMethods: payload.dataObject,
        };
      }
      return {
        ...state,
        paymentMethods: [],
      };
    });
  },
});

export const selectCheckoutState = (rootState: RootState) => rootState.checkout;
export const selectListStep = (rootState: RootState) =>
  rootState.checkout.listStepState;
export const selectCheckoutCurrentStep = (rootState: RootState) =>
  rootState.checkout.currentStep;
export const selectCheckoutFirstStep = (rootState: RootState) =>
  rootState.checkout.listStepState &&
  rootState.checkout.listStepState.length > 0
    ? rootState.checkout.listStepState[0]
    : null;
export const selectIsCheckoutLastStep = (rootState: RootState) =>
  rootState.checkout.currentStep.index ===
  rootState.checkout.listStepState[rootState.checkout.listStepState.length - 1]
    .index;
export const selectAdyenErrorCode = (rootState: RootState) =>
  rootState.checkout.adyenErrorCode;
export const selectSelectedPaymentMethodId = (rootState: RootState) =>
  rootState.checkout.selectedPaymentMethodId;
export const selectSelectedSecondPaymentMethodId = (rootState: RootState) =>
  rootState.checkout.selectedSecondPaymentMethodId;
export const selectIsAcceptPaymentTerms = (rootState: RootState) =>
  rootState.checkout.isAcceptPaymentTerms;
export const selectPaymentMethods = (rootState: RootState) =>
  rootState.checkout.paymentMethods;
export const selectSelectedWholesaler = (rootState: RootState) =>
  rootState.checkout.selectedWholesaler;
export const selectIsPerformStepValidation = (rootState: RootState) =>
  rootState.checkout.isPerformStepValidation;
export const selectOnHybridPayment = (rootState: RootState) =>
  rootState.checkout.onHybridPayment;
export const {
  getconfigsuccess,
  // getpaymentMethods,
  postinitiatePayment,
  submitAdditionalPaymentDetails,
  setListStepModal,
  setCurrentStepModal,
  updateAdyenErrorCode,
  updateSelectedPaymentMethodId,
  updateSelectedSecondPaymentMethodId,
  updateIsAcceptPaymentTerms,
  updateSelectedWholesaler,
  updateIsPerformStepValidation,
  resetCheckoutState,
  setOnHybridPayment,
} = CheckoutSlice.actions;
export default CheckoutSlice.reducer;
