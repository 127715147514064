import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const NotFoundRouter = () => {
    return(
        <Route
          render={props => {
            return (
              <Redirect
                to={{
                  pathname: '/exception?type=404',
                  state: { from: props.location },
                }}
              />
            );
          }}
        />
    );
}
export default NotFoundRouter;