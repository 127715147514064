import { HOME_BATS_V4_ROUTE } from "../constants/routes/batsv4Routes.constants";
import { TRAINING_COURSE_DETAIL_SHARED_ROUTE } from "../constants/routes/sharedRoutes.constants";
import { RouteConfigure } from "../models/Route";
import { MinimalSideNavItem } from "../types/sideNavigation.type";

// Convert path from: /dashboard/:activeTab? -> /dashboard/:activeTab
export const cleanSystemRoutePath = (inputPath: string) => {
  const splitUrl = inputPath.split("/");
  const splitUrlLength = splitUrl.length;
  const lastItemOfUrl = splitUrl[splitUrlLength - 1];

  if (lastItemOfUrl.startsWith(":") && lastItemOfUrl.endsWith("?")) {
    splitUrl[splitUrlLength - 1] = lastItemOfUrl.slice(
      0,
      lastItemOfUrl.length - 1
    );
  }

  return splitUrl.join("/");
};

export const mapCurrentPathToSystemPath = (countryCode: string) => {
  const currentPath = window.location.pathname;

  if (currentPath.startsWith(`/course/${countryCode.toLowerCase()}`))
    return TRAINING_COURSE_DETAIL_SHARED_ROUTE.path;

  if (currentPath.startsWith(`/course-detail`))
    return TRAINING_COURSE_DETAIL_SHARED_ROUTE.path;

  return currentPath;
};

export const getCurrentRoutePath = (
  allRoutes: RouteConfigure[],
  countryCode: string
): string | undefined => {
  const homeRoutePath = HOME_BATS_V4_ROUTE.path;
  const browserPathName = mapCurrentPathToSystemPath(countryCode);

  if (browserPathName === "/") return homeRoutePath;

  for (const route of allRoutes) {
    const cleanedRoutePath = cleanSystemRoutePath(route.path);

    if (!!route.parentRoute) {
      const cleanedParentRoutePath = cleanSystemRoutePath(
        route.parentRoute.path
      );
      let referencePathName = "";

      for (let i = 0; i < cleanedParentRoutePath.length; ++i) {
        if (!!browserPathName[i]) referencePathName += browserPathName[i];
      }

      if (referencePathName === cleanedParentRoutePath)
        return cleanedParentRoutePath;
    }

    if (cleanedRoutePath === browserPathName) return cleanedRoutePath;
  }

  return undefined;
};

export const getParentSideNavItem = (
  sideNavItems: MinimalSideNavItem[],
  countryCode: string
): MinimalSideNavItem | undefined => {
  const browserPathName = mapCurrentPathToSystemPath(countryCode);

  for (const item of sideNavItems) {
    if (!item.childItems || item.childItems.length === 0) continue;

    for (const subItem of item.childItems) {
      if (subItem.path === browserPathName) return item;

      if (!!subItem.childItems) {
        let referencePathName = "";

        for (let i = 0; i < subItem.path.length; ++i)
          if (!!browserPathName[i]) referencePathName += browserPathName[i];

        if (referencePathName === subItem.path) return item;
      }
    }
  }
  return undefined;
};
