import { FC, useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, Modal, Table } from "semantic-ui-react";
import { RequestWithdrawStudentFromProgram } from "../../models/trainingProgram/request.getTrainingPrograms.model";
import {
  selectAccount,
  selectCiamEmail,
  selectCiamId,
  selectCustomerId,
} from "../../redux-toolkit/accountReducer";
import {
  selectCountryCode,
  selectLanguage,
  selectTimeZoneId,
} from "../../redux-toolkit/authReducer";
import "./WithdrawProgramModal.css";
import { withdrawStudentFromProgramAsync } from "../../redux-toolkit/trainingProgramReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
//import { useAppDispatch } from "../../redux/hooks";
import Boschicon from "../BoschIcon/Boschicon";
import {
  selectActiveModule,
  selectSelectedTrainingProgram,
} from "../../redux-toolkit/dashboardReducer";
import {
  selectEmployeeId,
  selectProxyMode,
  selectSelectedWorkshop,
  selectWholesalerProxyMode,
} from "../../redux-toolkit/dashboardEmployeeReducer";
import {
  TrainingCourse,
  TrainingModule,
  TrainingProgramEnrollmentStatus,
} from "../../models/TrainingEvent";
import style from "./WithdrawProgramModal.module.css";
import { Constants } from "../../helpers/Constants";
import { TrainingEventHelper } from "../../helpers/TrainingEventHelper";
import { EventEnrollmentHelper } from "../../helpers/EventEnrollmentHelper";
import RDButton from "../RDComponents/RDButton";
import RDAlert from "../RDComponents/RDAlert";

interface WithdrawProgramModalProps {
  withdrawSucessAction: Function;
  closeAction: Function;
}

const WithdrawProgramModal: FC<WithdrawProgramModalProps> = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isWithdrawSuccess, setIsWithdrawSuccess] = useState(false);
  const [isWithdrawFailed, setIsWithdrawFailed] = useState(false);

  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const selectedTrainingProgram = useSelector(selectSelectedTrainingProgram);
  const ciamId = useSelector(selectCiamId);
  const ciamEmail = useSelector(selectCiamEmail);
  const customerId = useSelector(selectCustomerId);
  const country = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);
  const timezoneId = useSelector(selectTimeZoneId);
  const account = useSelector(selectAccount);

  const proxyMode = useSelector(selectProxyMode);
  const wholesalerProxyMode = useSelector(selectWholesalerProxyMode);
  const selectedWorkshop = useSelector(selectSelectedWorkshop);
  const studentId = useSelector(selectEmployeeId);

  const [trainingCourses, setTrainingCourses] = useState<TrainingCourse[]>([]);
  const meritStatusClass = "merit";
  const failedStatusClass = "failed";
  const activeModule = useSelector(selectActiveModule);
  const [isRegistrationExisting, setIsRegistrationExisting] = useState(false);

  useEffect(() => {
    if (
      selectedTrainingProgram &&
      selectedTrainingProgram.trainingModules &&
      selectedTrainingProgram.trainingModules.length > 0
    ) {
      let courses: TrainingCourse[] = [];

      selectedTrainingProgram.trainingModules.map((module: TrainingModule) => {
        courses = courses.concat(module.trainingCourses);
      });

      setTrainingCourses(courses);
    }
  }, []);

  useEffect(() => {
    let trainingProgram = selectedTrainingProgram;
    if (
      trainingProgram &&
      trainingProgram?.trainingModules &&
      trainingProgram?.trainingModules?.length > 0
    ) {
      trainingProgram?.trainingModules.forEach((trainingModule) => {
        let trainingCourses = trainingModule?.trainingCourses;

        if (trainingCourses && trainingCourses?.length > 0) {
          trainingCourses.forEach((course) => {
            let enrollmentStatus = getValidEnrollmentStatus(course);

            if (
              enrollmentStatus &&
              enrollmentStatus?.registrationStatus?.id ===
                Constants.TRAININGEVENTSTATUSES_REGISTER
            ) {
              setIsRegistrationExisting(true);
            }
          });
        }
      });
    }
  }, []);

  const removeConfirmed = () => {
    if (!!selectedTrainingProgram) {
      setIsSubmitting(true);

      let userCustomerID = customerId;
      let userEmployeeID = Number(account?.employeeId);

      if (proxyMode || wholesalerProxyMode) {
        userEmployeeID = studentId || 0;
        if (wholesalerProxyMode) {
          userCustomerID = selectedWorkshop?.customerId || 0;
        }
      }

      const withdrawStudentFromProgram: RequestWithdrawStudentFromProgram = {
        ciamId: ciamId ?? "",
        ciamEmail: ciamEmail ?? "",
        customerId: userCustomerID ?? 0,
        country: country,
        language: language,
        timezoneId: timezoneId,
        salesRegions: "",
        membership: {
          trainingProgramId: selectedTrainingProgram?.id,
          studentId: Number(userEmployeeID),
          requester: ciamId ?? "",
          trainingProgram: selectedTrainingProgram,
        },
      };

      let dispatchResult = dispatchReduxToolkit(
        withdrawStudentFromProgramAsync(withdrawStudentFromProgram)
      ).unwrap();
      dispatchResult
        .then((resp: any) => {
          if (resp?.success) {
            setIsOpen(false);
            setIsWithdrawSuccess(true);
          } else {
            setIsWithdrawFailed(true);
          }
        })
        .catch(() => setIsWithdrawFailed(true))
        .finally();
    }
  };

  const closeModal = () => {
    props.closeAction();
  };

  const closeSuccessModal = () => {
    setIsWithdrawSuccess(false);
    props.withdrawSucessAction();
  };

  const closeFailedModal = () => {
    setIsWithdrawFailed(false);
    setIsSubmitting(false);
  };

  const GetCourseRows = (module: TrainingModule) => {
    return module.trainingCourses.map((course) => {
      return <>{GetCourseRow(course, module)}</>;
    });
  };

  const getValidEnrollmentStatus = (course: TrainingCourse) => {
    if (
      !course ||
      !course?.trainingProgramEnrollmentStatus ||
      course.trainingProgramEnrollmentStatus.length <= 0
    )
      return null;

    const validStatuses = course.trainingProgramEnrollmentStatus.filter(
      (x) =>
        x.registrationStatus &&
        !Constants.COURSETABLE_CANCELLEDTAB_EVENTSTATUS.includes(
          x.registrationStatus.id
        )
    );
    if (validStatuses.length <= 0) return null;
    return validStatuses[validStatuses.length - 1];
  };

  const getStatusClass = (
    enrollmentStatus: TrainingProgramEnrollmentStatus | null,
    course: TrainingCourse,
    selectedModule: TrainingModule
  ) => {
    if (
      selectedModule &&
      activeModule &&
      selectedModule.properties &&
      activeModule.properties
    ) {
      if (
        !selectedModule.properties.isModuleComplete &&
        selectedModule.id !== activeModule.id
      )
        return {
          className: "disabled",
          icon: "bosch-ic-lock-closed",
          isdisabledRegister: true,
        };
    }
    if (!enrollmentStatus) {
      if (
        selectedModule &&
        selectedModule.trainingCourses &&
        course &&
        !!course.parentId
      ) {
        const parentCourse = selectedModule.trainingCourses.find(
          (x) => !!x && x.id === course.parentId
        );
        if (parentCourse) {
          const parentStatus = getValidEnrollmentStatus(parentCourse);
          const isParentPassed =
            parentStatus &&
            parentStatus.registrationStatus &&
            parentStatus.registrationStatus.id ===
              Constants.TRAININGEVENTSTATUSES_COMPLETED &&
            parentStatus.isPassed;
          if (!isParentPassed) {
            return {
              className: "disabled",
              icon: "bosch-ic-lock-closed",
              isdisabledRegister: true,
            };
          }
        }
      }

      return {
        className: "available",
        icon: "bosch-ic-lock-open",
        isdisabledRegister: false,
      };
    }

    const isPassed =
      enrollmentStatus &&
      enrollmentStatus.registrationStatus &&
      enrollmentStatus.registrationStatus.id ===
        Constants.TRAININGEVENTSTATUSES_COMPLETED &&
      enrollmentStatus.isPassed;

    const isFailed =
      enrollmentStatus &&
      enrollmentStatus.registrationStatus &&
      enrollmentStatus.registrationStatus.id ===
        Constants.TRAININGEVENTSTATUSES_COMPLETED &&
      (!enrollmentStatus.isPassed || enrollmentStatus.grade?.id === "F");

    const isRegister =
      enrollmentStatus &&
      (enrollmentStatus.registrationStatus.id ===
        Constants.TRAININGEVENTSTATUSES_REGISTER ||
        enrollmentStatus.registrationStatus.id ===
          Constants.TRAININGEVENTSTATUSES_INPROGRESS);
    let statusClass = {
      className: "pending",
      icon: "bosch-ic-lock-closed",
      isdisabledRegister: true,
    };
    if (isRegister) {
      statusClass = {
        className: "registered",
        icon: "bosch-ic-lock-open",
        isdisabledRegister: true,
      };
    }
    if (isPassed) {
      if (
        enrollmentStatus.eventStartDate?.toString()?.startsWith("0001-01-01") &&
        enrollmentStatus.eventEndDate?.toString()?.startsWith("0001-01-01")
      ) {
        statusClass = {
          className: meritStatusClass,
          icon: "bosch-ic-badge-checkmark",
          isdisabledRegister: true,
        };
      } else {
        statusClass = {
          className: "completed",
          icon: "bosch-ic-badge-checkmark",
          isdisabledRegister: true,
        };
      }
    }
    if (isFailed)
      statusClass = {
        className: failedStatusClass,
        icon: "bosch-ic-alert-warning",
        isdisabledRegister: false,
      };
    return statusClass;
  };
  const GetCourseRow = (course: TrainingCourse, module: TrainingModule) => {
    if (course) {
      const enrollmentStatus = getValidEnrollmentStatus(course);

      if (
        enrollmentStatus &&
        enrollmentStatus?.registrationStatus?.id ===
          Constants.TRAININGEVENTSTATUSES_REGISTER
      ) {
        const statusClass = getStatusClass(enrollmentStatus, course, module);
        const isMeritStatus = statusClass.className === meritStatusClass;
        const isFailed = statusClass.className === failedStatusClass;
        return (
          <Table.Row>
            <Table.Cell className={style.program_title}>
              <label className={`${style.mobile_header}`}>
                {t("TrainingProgramDetails_ProgramItems_TableHeader_Title")}:
              </label>
              <span className={style[statusClass.className]}>
                {TrainingEventHelper.getTrainingNameFromCourse(
                  course,
                  language,
                  "-"
                )}
              </span>
            </Table.Cell>

            <Table.Cell>
              <label
                className={`${style.mobile_header} ${
                  style[statusClass.className]
                }`}
              >
                {t("TrainingProgramDetails_ProgramItems_TableHeader_Status")}:
              </label>
              <span className={style[statusClass.className]}>
                {isMeritStatus
                  ? t("TrainingProgramDetails_Merit_StatusText")
                  : isFailed
                  ? t("TrainingProgramDetails_Resit_StatusText")
                  : enrollmentStatus?.registrationStatus?.localized ||
                    (statusClass.className === "available"
                      ? t(
                          "Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus"
                        )
                      : t(
                          "Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus"
                        ))}
              </span>
            </Table.Cell>

            <Table.Cell>
              <label
                className={`${style.mobile_header} ${
                  style[statusClass.className]
                }`}
              >
                {t("TrainingProgramDetails_ProgramItems_TableHeader_Date")}:
              </label>
              <span className={style[statusClass.className]}>
                {isMeritStatus
                  ? "-"
                  : EventEnrollmentHelper.getStartDate(enrollmentStatus)}
              </span>
            </Table.Cell>
          </Table.Row>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };

  return (
    <Fragment>
      {/* Confirm withdraw modal */}
      <Modal
        dimmer={{ className: "withdraw-program-modal warning" }}
        open={isOpen}
        size={"tiny"}
      >
        <Modal.Header>
          <Boschicon name="bosch-ic-alert-warning" />{" "}
          <span>{t("Common_Warning_Title")}</span>
          <RDButton
            className={"close_button_header"}
            onClick={() => {
              closeModal();
            }}
          >
            <Boschicon name="bosch-close" />
          </RDButton>
        </Modal.Header>
        <Modal.Content>
          <Grid style={{ margin: 0 }}>
            <Grid.Row>
              <p>{t("Dashboard_MyTrainingProgram_Withdraw_Confirm")}</p>

              {isRegistrationExisting && (
                <div>
                  <p>
                    {t(
                      "Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting"
                    )}
                  </p>
                  <p>
                    <Table
                      unstackable
                      className={`${style.program_items_table}`}
                    >
                      <Table.Header className={`${style.table_header}`}>
                        <Table.Row>
                          <Table.Cell width={10}>
                            <b>
                              {t(
                                "TrainingProgramDetails_ProgramItems_TableHeader_Title"
                              )}
                            </b>
                          </Table.Cell>
                          <Table.Cell width={3}>
                            <b>
                              {t(
                                "TrainingProgramDetails_ProgramItems_TableHeader_Status"
                              )}
                            </b>
                          </Table.Cell>
                          <Table.Cell width={3}>
                            <b>
                              {t(
                                "TrainingProgramDetails_ProgramItems_TableHeader_Date"
                              )}
                            </b>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Header>

                      {!!trainingCourses && trainingCourses.length > 0 ? (
                        <Table.Body className={`${style.table_body}`}>
                          {selectedTrainingProgram?.trainingModules?.map(
                            (val) => GetCourseRows(val)
                          )}
                        </Table.Body>
                      ) : (
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell colSpan={6} className="empty-message">
                              {t("Dashboard_Courses_Table_NoRecordsToDisplay")}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      )}
                    </Table>
                  </p>
                </div>
              )}

              <p>
                {t("Dashboard_MyTrainingProgram_Withdraw_Confirm_Question")}
              </p>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <RDButton
            primary
            onClick={removeConfirmed}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t("Dashboard_MyTrainingProgram_Withdraw_Confirm_Button")}
          </RDButton>
          <RDButton secondary onClick={closeModal}>
            {t("Dashboard_MyTrainingProgram_Withdraw_Cancel_Button")}
          </RDButton>
        </Modal.Actions>
      </Modal>

      {isWithdrawSuccess && (
        <RDAlert
          size="tiny"
          type="success"
          isOpen={isWithdrawSuccess}
          title={selectedTrainingProgram?.title || ""}
          messages={[t("WithdrawProgramModal_Withdraw_Success_Message")]}
          acceptButtonText={t("CommonButton_Close")}
          onAccept={() => {
            closeSuccessModal();
          }}
        />
      )}
      {isWithdrawFailed && (
        <RDAlert
          size="tiny"
          type="error"
          isOpen={isWithdrawFailed}
          title={selectedTrainingProgram?.title || ""}
          messages={[t("Common_Error_Title")]}
          acceptButtonText={t("CommonButton_Close")}
          onAccept={() => {
            closeFailedModal();
          }}
        />
      )}
    </Fragment>
  );
};

export default WithdrawProgramModal;
