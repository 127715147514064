export const TRANSLATIONS_MS_MY = {
	LandingPage_Title: "Selamat datang ke Penyelesaian Latihan Automotif Bosch",
	LandingPage_SubTitle_ChooseCountry: "Pilih negara / wilayah anda",
	Header_ContactUs: "Hubungi Kami",
	Header_Search: "Cari",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Log masuk atau cipta akaun anda",
	Header_LogIn: "Log masuk",
	Header_Cart: "Troli",
	Header_ShoppingCart: "Troli beli-belah",
	Header_LogOut: "Log keluar",
	Header_ChangeDetails: "Tukar Butiran",
	Footer_ExploreOurTraining: "Terokai latihan kami",
	Footer_TrainingAtYourFingertips: "Latihan di hujung jari anda",
	Footer_DontMissAnyStory: "Jangan terlepas sebarang cerita",
	Footer_ExploreOurStories: "Terokai cerita kami",
	Footer_GetInTouchWithUs: "Hubungi kami",
	Footer_SendUsAMessage: "Hantar mesej kepada kami",
	Footer_ToContactForm: "Untuk menghubungi borang",
	Footer_InventedForLife: "Dicipta seumur hidup",
	Footer_GeneralContactInfo: "Maklumat hubungan am",
	Footer_ProductSecurity_Psirt: "Keselamatan Produk (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Paten dan lesen inovasi",
	Footer_PurchasingLogistics: "Pembelian & logistik",
	Footer_CorporateInformation: "Maklumat korporat",
	Footer_LegalNotice: "Notis undang-undang",
	Footer_DataProtectionNotice: "Notis perlindungan data",
	Footer_PrivacySettings: "Tetapan privasi",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch Gmbh, all rights reserved",
	Footer_StillLookingForSomething: "Masih mencari sesuatu",
	Footer_TrainingServices: "Perkhidmatan Latihan",
	Footer_Courses: "Kursus",
	Footer_Programs: "Program",
	Footer_EventsCalendar: "Kalendar Acara",
	Footer_TrainingCenters: "Pusat Latihan",
	Footer_Dashboard: "Papan pemuka",
	Footer_MyDashboard: "papan utama saya",
	Footer_ShoppingCart: "Troli Beli Belah",
	Footer_YourShoppingCart: "Troli Beli-belah Anda",
	Footer_Administration: "Pentadbiran",
	Footer_Employees: "Pekerja",
	Footer_Wholesalers: "Pemborong",
	Footer_Transactions: "Transaksi",
	Footer_Workshops: "Bengkel",
	Footer_WorkLikeABoschApplyNow: "Bekerja  - Mohon sekarang.",
	Footer_BoschGlobalCareers: "Kerjaya Global Bosch",
	Footer_PurchasingAndLogistics: "Pembelian dan Logistik",
	Footer_BecomeABusinessPartner: "Menjadi rakan kongsi perniagaan.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Bagaimana latihan kami berkembang untuk membantu perniagaan anda",
	Footer_Language: "Pilih negara",
	MenuPopup_MyCart: "Troli Saya",
	Stories_OurTrainingStories: "Kisah Latihan Kami",
	Stories_ConnectingExperts: "Menghubungkan pakar dan peminat pemimpi dan pelaku inovator dan pengaruh - teroka dan alami",
	Stories_TopStories: "Cerita Teratas",
	Stories_AtHome: "Di rumah",
	Stories_Careers: "Kerjaya",
	Stories_History: "Sejarah",
	Stories_IndustryAndTrades: "Perindustrian dan perdagangan",
	Stories_Mobility: "mobiliti",
	Stories_Research: "Penyelidikan",
	Stories_Sustainability: "Kelestarian",
	Stories_AllStories: "Semua Cerita",
	Stories_BlogATrainersDay: "BLOG | Hari Jurulatih",
	Stories_ADayinLifeOfATrainer: "Hari dalam kehidupan seorang Jurulatih",
	Stories_BoschServiceTraining: "Latihan Perkhidmatan Bosch",
	Stories_StoryWhatDrivesYouDrivesUs: "KISAH | Perkara yang mendorong anda mendorong kami",
	Stories_StoryHowCanWeBenefitYourBusiness: "KISAH | Bagaimana kami boleh memberi manfaat kepada perniagaan anda",
	Stories_HowOurTrainingEvolves: "Bagaimana latihan kami berkembang",
	Stories_AModernTrainingApproach: "Dunia latihan dalam talian",
	Stories_StoryAVirtualOnlineTrainingWorld: "KISAH | Peluang latihan dalam talian anda",
	Stories_SubscribeToRSSFeed: "Langgan suapan RSS",
	Title_Main: "Penyelesaian Latihan Automotif Bosch",
	Homepage_AboutUs: "Tentang kita",
	Homepage_AutomotiveAftermarket: "Pasaran Selepas Automotif",
	Homepage_GetInTouch: "Hubungi",
	Homepage_LatestNews: "Ketahui mengapa kita didorong oleh kehausan ilmu",
	Homepage_MainTitle: "Penyelesaian Latihan Automotif Bosch",
	Homepage_OurOpenPositions: "Jawatan terbuka kami",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Perkara yang mendorong anda mendorong kami",
	BoschServiceTraining_BoschServiceTraining: "Latihan Perkhidmatan Bosch",
	BoschServiceTraining_ShareThisOn: "Kongsi ini di",
	BoschServiceTraining_TheChallenge: "Cabarannya: Menguasai teknologi baharu",
	BoschServiceTraining_WorldwidePresence: "Kehadiran Seluruh Dunia",
	BoschServiceTraining_TechnicalTraining: "Kursus latihan teknikal dari Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Tawaran dan peluang yang kami di Bosch tawarkan",
	BoschServiceTraining_TechnicalCoursesDesc: "Terdapat kursus latihan yang sesuai untuk setiap kumpulan sasaran - sama ada orang baru atau profesional. Tawaran itu termasuk kursus latihan sehari dan kursus latihan selama beberapa hari. Kursus latihan ditawarkan dalam bidang teknikal dan siri latihan yang berbeza (seperti sistem elektrik suntikan diesel suntikan petrol). Dalam kursus latihan teknikal Bosch memberikan pengetahuan yang luas dalam semua sistem kenderaan standard daripada sistem Bosch daripada pengeluar lain juga dibincangkan dalam kursus latihan. Kandungannya dipadankan khas dengan keperluan pekerja bengkel.",
	BoschServiceTraining_TrainedEmployeesDesc: "Pekerja terlatih adalah faedah yang jelas untuk bengkel automotif. Kerana kenderaan hari ini menjadi semakin kompleks dan teknologi baharu sentiasa memberikan cabaran baharu. Hanya kepakaran yang diperlukan membolehkan pekerja bengkel melakukan kerja-kerja penyelenggaraan dan pembaikan diagnostik pada model kenderaan semasa dengan cara yang cekap dan menjimatkan kos.",
	BoschServiceTraining_RegardlessOfHow: "Tidak kira sejauh mana pakar automotif yang baik dan berpengalaman - sentiasa ada situasi di mana mereka akan bersentuhan dengan sistem teknologi kenderaan baharu atau kerosakan yang tidak diketahui. Dalam hal ini penyelesaian masalah dan penyelesaian masalah boleh menjadi sangat intensif masa atau mungkin tidak memberikan hasil yang berjaya. Dalam kes yang paling teruk, pelanggan mesti melakukan tanpa kenderaan mereka dengan ketara lebih lama daripada yang dijangkakan.",
	BoschServiceTraining_ItIsPrecisely: "Tepat pada ketika ini Bosch membawa Latihan Perkhidmatannya untuk menanggung: meningkatkan kualiti perkhidmatan dan mempercepatkan proses. Latihan semasa Bosch menyediakan sokongan dalam menyetempatkan masalah dengan lebih cepat membaiki lebih banyak kenderaan dalam masa yang sama dan mengurangkan masa menunggu pelanggan anda. Oleh itu prosedur sistematik yang belajar di Bosch memastikan kerja yang lebih cepat dan lebih produktif.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Selama lebih daripada 125 tahun Bosch telah menjadi pembekal pilihan komponen dan sistem alat ganti peralatan asli untuk kebanyakan pengeluar kereta terkemuka di seluruh dunia. Pengalaman ini datang dengan pengetahuan unik tentang teknologi terkini yang menjadi asas untuk sokongan Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Pusat Latihan Perkhidmatan Bosch boleh ditemui di mana-mana di dunia: dari Sepanyol ke ayam belanda Afrika Selatan Australia ke Brazil - Bosch menawarkan latihan latihan berorientasikan amalan kepada pekerjanya yang sentiasa selaras dengan teknologi terkini.",
	BoschServiceTraining_AgileCompany: "Syarikat Tangkas",
	BoschServiceTraining_Collaboration: "Kerjasama",
	BoschServiceTraining_ConnectedMobility: "Mobiliti Bersambung",
	BoschServiceTraining_ArtificialIntelligence: "Kecerdasan Buatan",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Pemimpin pemikiran dalam dialog",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostik dan Peralatan Ujian",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Asas Teknologi Automotif",
	BoschServiceTraining_Icon_Gasoline: "petrol",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Pemacu Alternatif",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektrik dan Elektronik",
	BoschServiceTraining_Icon_BodyAndComfort: "Badan dan Keselesaan",
	BoschServiceTraining_Icon_ChassisAndBraking: "Casis dan Brek",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmisi dan Drivetrain",
	BoschServiceTraining_Icon_CommercialVehicles: "Kenderaan Perdagangan",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Kursus Latihan Dalam Talian",
	BoschServiceTraining_Icon_BusinessSkills: "Kemahiran Perniagaan",
	BoschServiceTraining_Icon_Accreditations: "Akreditasi",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Program Kursus Latihan",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerFirstNameLastName",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Hari dalam kehidupan seorang jurulatih",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Setiap hari adalah pengembaraan",
	ADayInTheLifeOfATrainer_Summary: "Ringkasan",
	ADayInTheLifeOfATrainer_TheDayJourney: "Perjalanan hari itu",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Setiap hari adalah hari yang sibuk tetapi ia sentiasa pengalaman yang bermanfaat. Keseronokan melatih pelajar mengetahui bahawa mereka memegang kunci masa depan kita adalah satu tanggungjawab tetapi pada masa yang sama sangat mengujakan. Melihat pelajar berkembang dengan setiap pelajaran setiap hari dan mingguan membuktikan kepada kami bahawa kami sedang berusaha ke arah matlamat bersama untuk meningkatkan pengetahuan mereka sambil menggabungkan teknologi moden yang memacu masa depan!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Pada penghujung setiap hari, kita perlu duduk dan merenung hari-hari bekerja. Hari-hari berlalu begitu cepat tetapi ini adalah bukti sendiri bahawa apa yang kami capai bukan sahaja memberi ganjaran kepada pelajar tetapi untuk kami sebagai jurulatih juga.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Kami boleh pulang pada penghujung hari dengan berpuas hati bahawa kami telah melakukan kerja yang hebat!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Mulakan",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Penyediaan dan semakan akhir bahan latihan. Semak mel penting",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Latihan bermula",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Peserta dialu-alukan. Pembentangan jadual harian. Kenali kumpulan dengan memahami dinamik setiap individu.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teori",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Jumlah interaksi tertentu dengan kumpulan diperlukan untuk menjadikan latihan itu menarik yang mungkin. Memastikan bahawa terdapat tugasan yang saling berkaitan dengan teori untuk melibatkan kumpulan",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Rehat tengah hari",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktikal",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Latihan diteruskan di bengkel dengan beberapa tugas praktikal. Sebagai jurulatih, ia sentiasa menjadi keperluan untuk hadir dan sentiasa bersedia untuk sebarang kemungkinan dan bimbingan",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Rekap",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Kembali ke kelas untuk ringkas, untuk membincangkan latihan hari itu. Jawab sebarang soalan yang mungkin timbul. Mulakan persediaan untuk sesi latihan seterusnya.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Hari Akhir",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Sijil kehadiran disampaikan kepada peserta.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "rumah,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Bagaimana kami boleh membantu memberi manfaat kepada perniagaan anda",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Bagaimana latihan kami berkembang",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Kami sentiasa berubah untuk memastikan kami menyampaikan latihan terkini yang berkualiti",
	BoschTrainingBenefits_TheBoschServiceTraining: "Pusat Latihan Perkhidmatan Bosch ingin membantu anda dalam membangunkan perniagaan anda melalui portfolio latihan teknikal/perniagaan yang luas dan sangat relevan serta kursus pendidikan lanjutan dengan kelayakan yang diperakui. Halaman berikut memberi anda gambaran keseluruhan program latihan kami serta peluang pendidikan lanjutan semasa. Kami berharap dapat bertemu dengan anda dan pekerja anda di kursus latihan dan seminar kami.",
	BoschTrainingBenefits_LongStandingExperience: "Pengalaman lama dengan dan pengetahuan yang luas tentang semua sistem dan dalam bidang teknologi automobil.",
	BoschTrainingBenefits_QuickAndEfficient: "Penyelesaian yang cepat dan cekap untuk banyak masalah.",
	BoschTrainingBenefits_TheAdvantagesandBenefits: "Kelebihan dan faedah",
	BoschTrainingBenefits_LMS: "Sistem Pengurusan Pembelajaran - Penyelesaian Latihan Bosch",
	BoschTrainingBenefits_AcquiredKnowledge: "Kemudahan pengetahuan yang diperolehi penyelenggaraan pembaikan dan kerja perkhidmatan.",
	BoschTrainingBenefits_TheHighDegree: "Kandungan praktikal yang tinggi dan kumpulan kecil menjamin pembelajaran berorientasikan sasaran",
	BoschTrainingBenefits_ParticipationInTraining: "Penyertaan dalam latihan meningkatkan motivasi dan kepuasan pekerja dan kelayakan tambahan meyakinkan pelanggan",
	BoschTrainingBenefits_IncreasedSales: "Meningkatkan jualan melalui pengetahuan sistem silang buat",
	BoschTrainingBenefits_MethodicalProcedures: "Prosedur berkaedah mengurangkan masa menunggu untuk pelanggan",
	BoschTrainingBenefits_HighlyQualified: "Pengajar yang berkelayakan tinggi menyampaikan prosedur yang sistematik",
	BoschTrainingBenefits_IdentificationOftheTraining: "Pengenalpastian penyelesaian latihan yang sesuai untuk anda atau perniagaan anda",
	BoschTrainingBenefits_DirectOnlineBooking: "Tempahan dalam talian terus dan pengurusan kursus sama ada untuk diri sendiri atau pekerja anda",
	BoschTrainingBenefits_OnlineAccess: "Akses dalam talian kepada dokumentasi pembelajaran",
	BoschTrainingBenefits_AccessToYourOwn: "Akses kepada sejarah dan sijil latihan peribadi anda sendiri",
	BoschTrainingBenefits_ToolsForManagement: "Alat untuk pengurusan pembangunan kerjaya anda sendiri",
	BoschTrainingBenefits_ManagerHasFull: "Pengurus mempunyai gambaran keseluruhan penuh kursus pekerja mereka di dalam sistem",
	BoschTrainingBenefits_Access24hours: "Akses 24 jam 7 hari seminggu",
	ModernTrainingApproach_KnowledgeIsKey: "Pengetahuan adalah faktor kejayaan utama untuk mana-mana bengkel. Itulah sebabnya Bosch menawarkan program pembangunan latihan profesional yang komprehensif. Ini membolehkan pelajar memperoleh pengetahuan yang tepat pada masa dan tempat yang tepat - pengetahuan yang diperlukan untuk dapat menyelenggara dan membaiki kenderaan pelanggan secara profesional. Bosch telah menambah siaran web sebagai bahagian modul baharu pakej e-pembelajaran yang telah ditawarkan. Ia terdiri daripada sesi latihan perkhidmatan yang direka khusus untuk penstriman.",
	ModernTrainingApproach_ANewLearning: "Peluang pembelajaran baru",
	ModernTrainingApproach_YourOnlineTraining: "Peluang latihan dalam talian anda",
	ModernTrainingApproach_RecentlyTheWorld: "Baru-baru ini dunia telah berubah lihat apa yang kami lakukan untuk membantu",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Dunia latihan dalam talian",
	ModernTrainingApproach_NewTeachingMethod: "Kaedah pengajaran baru",
	ModernTrainingApproach_InAdditionTo: "Sebagai tambahan kepada latihan bersemuka yang sedia ada, kami telah memasukkan latihan siaran web secara langsung dalam program latihan. Jurulatih kami sedia membantu anda dengan pengetahuan teori yang luas dalam bilik darjah maya atau dengan contoh praktikal. Seperti biasa anda akan menerima banyak maklumat tentang sistem kenderaan dan produk Bosch yang sepadan. Sudah tentu anda juga berpeluang untuk bertanya soalan. Soalan-soalan ini akan dijawab terus dalam siaran web langsung. Siaran web langsung berlangsung antara 60 hingga 120 minit. Sambungan internet yang stabil diperlukan. Cari siaran web yang sesuai terus dalam katalog latihan kami dengan memasukkan \Siaran Web\ dalam medan carian.",
	ModernTrainingApproach_BoschWebcastingHeader: "Penyiaran web Bosch: Inovatif dan praktikal",
	ModernTrainingApproach_BoschWebcastingText: "Untuk mengambil bahagian dalam siaran web Bosch terlebih dahulu, peserta mesti mendail masuk. Kod akses individu dihantar sebelum sesi untuk membolehkan log masuk. Sehingga 200 peserta boleh mengambil bahagian pada masa yang sama dari mana-mana bilangan lokasi. Latihan ini diketuai oleh jurulatih dan soalan boleh dikemukakan pada bila-bila masa melalui fungsi sembang langsung.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Tambahan digital kepada latihan bilik darjah",
	ModernTrainingApproach_TheDigitalSupplementText: "Penyiaran web dan latihan bilik darjah saling melengkapi dengan sempurna. Dalam kedua-dua kes, jurulatih bertauliah mengetuai latihan. Siaran web sesuai untuk topik yang boleh dihantar secara digital tanpa perlu bekerja pada kenderaan. Contohnya mengenali ciri ESI[tronic] tertentu bagi sistem bantuan pemandu baharu. Apabila bercakap tentang kerja berpasukan yang cekap di bengkel atau kerja amali teknologi kenderaan latihan bersemuka masih menjadi pilihan pertama.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Penstriman boleh menjadi lebih murah",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Kos setiap peserta biasanya lebih rendah dengan siaran web berbanding kursus kelas yang setanding. Sebabnya ialah lebih ramai peserta boleh mendapat manfaat daripadanya dan usaha yang berkaitan dengan latihan adalah jauh lebih rendah (tiada perjalanan ke dan dari lokasi dan kurang masa yang diperlukan).",
	ModernTrainingApproach_BookAndProfitHeading: "Buku dan untung",
	ModernTrainingApproach_BookAndProfitText: "Siaran web boleh ditempah di tapak web kami sama seperti kursus latihan dan tawaran pembelajaran kami yang lain. Anda mempunyai pilihan sama ada membayar yuran individu untuk setiap siaran web atau mengambil langganan tahunan untuk sejumlah siaran web tertentu.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Bosch Webcasting dan latihan bilik darjah dibandingkan",
	ModernTrainingApproach_Webcasting: "Penyiaran web",
	ModernTrainingApproach_Classroom: "Bilik Darjah",
	ModernTrainingApproach_WebcastingP1: "Sehingga 200 peserta boleh mengambil bahagian pada masa yang sama dari mana-mana sahaja di seluruh dunia",
	ModernTrainingApproach_WebcastingP2: "Kerumitan yang rendah dan kos yang agak rendah tidak memerlukan perjalanan",
	ModernTrainingApproach_WebcastingP3: "Sesuai untuk topik individu yang khusus dan ringkas",
	ModernTrainingApproach_WebcastingP4: "Bengkel boleh menjalankan siaran web untuk pekerja mereka di mana-mana dan pada bila-bila masa",
	ModernTrainingApproach_WebcastingP5: "Antara 50-70% kurang masa yang diperlukan berbanding untuk latihan bilik darjah",
	ModernTrainingApproach_ClassroomTrainingP1: "Sehingga 16 peserta bertemu pada masa yang sama",
	ModernTrainingApproach_ClassroomTrainingP2: "Perbelanjaan perjalanan dan umumnya kos yang lebih tinggi bagi setiap orang",
	ModernTrainingApproach_ClassroomTrainingP3: "Sesuai untuk mengoptimumkan kerjasama atau pembelajaran secara langsung pada kenderaan",
	ModernTrainingApproach_ClassroomTrainingP4: "Latihan praktikal dan interaksi dengan jurulatih",
	ModernTrainingApproach_ClassroomTrainingP5: "Jurulatih boleh mengadaptasi ilmu yang akan diajar dengan lebih baik mengikut keperluan sebenar peserta",
	ModernTrainingApproach_OnlineTrainingOfferings: "Tawaran latihan dalam talian (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Hari ini pembelajaran bebas di komputer adalah sebahagian daripada pemindahan pengetahuan. Kebebasan untuk memilih masa dan tempat kebolehulangan tanpa had kelajuan pembelajaran individu dan kandungan pembelajaran interaktif menjadikan kaedah ini sebagai alat belajar yang sangat diperlukan.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Setiap elemen Latihan Berasaskan Web (WBT) telah disusun dengan teliti. Selain menyediakan pangkalan pengetahuan yang luas, kursus ini boleh berfungsi sebagai persediaan yang berkesan untuk program latihan di pusat latihan Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Simulasi diagnostik maya menawarkan kemungkinan main peranan pengguna untuk situasi bengkel harian seperti aliran kerja prosedur penyelesaian masalah menggunakan peranti diagnostik Bosch dalam bengkel maya.",
	ModernTrainingApproach_Advantages: "Kelebihan",
	ModernTrainingApproach_AdvantagesP1: "Kaedah penyampaian ilmu yang cepat dan cekap",
	ModernTrainingApproach_AdvantagesP2: "Pembelajaran yang pelbagai",
	ModernTrainingApproach_AdvantagesP3: "Boleh digunakan sekerap yang dikehendaki",
	ModernTrainingApproach_AdvantagesP4: "Masa dan wang yang minimum",
	ModernTrainingApproach_AdvantagesP5: "Kepantasan pembelajaran individu",
	ModernTrainingApproach_AdvantagesP6: "Masa belajar yang fleksibel",
	ModernTrainingApproach_AdvantagesP7: "Kebolehulangan",
	ModernTrainingApproach_AdvantagesP8: "Tiada kos perjalanan",
	ModernTrainingApproach_AdvantagesP9: "Tiada konflik kapasiti atau penjadualan",
	Dashboard_CoursesViewAndManageYourCourses: "KURSUS | Lihat dan urus kursus anda",
	Dashboard_MyCourses: "Kursus Saya",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAM LATIHAN | Lihat program latihan anda",
	Dashboard_MyTrainingProgram: "Program Latihan Saya",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "ePEMBELAJARAN | Lihat dan urus portfolio ePembelajaran anda",
	Dashboard_MyELearning: "ePembelajaran saya",
	Dashboard_AccountManageYourAccount: "AKAUN | Menguruskan akaun anda",
	Dashboard_MyAccount: "Akaun saya",
	Dashboard_MyDashboard: "papan utama saya",
	Dashboard_ManageYourAccount: "Menguruskan akaun anda",
	Dashboard_Workshops: "Bengkel",
	Dashboard_AdministrationManageYourWorkshop: "PENTADBIRAN | Urus bengkel anda",
	Dashboard_AdministrationManageYourTeams: "PENTADBIRAN | Urus pasukan anda",
	Dashboard_Administration: "Pentadbiran",
	Dashboard_ViewYourCourses: "Lihat kursus anda",
	Dashboard_ViewYourTrainingPrograms: "Lihat program latihan anda",
	Dashboard_TrainingPrograms: "Program Latihan",
	Dashboard_ViewAndEditYourDetails: "Lihat dan edit Butiran anda",
	Dashboard_ViewYourELearningCourses: "Lihat kursus ePembelajaran anda",
	Dashboard_Pending: "Yang belum selesai",
	Dashboard_RequiresAdministratorSignOff: "(memerlukan tanda keluar Pentadbir)",
	Dashboard_HistoricalRecords: "Rekod Sejarah",
	Dashboard_Registered: "Berdaftar",
	Dashboard_WaitingList: "Senarai menunggu",
	Dashboard_RecommendedForYou: "disyorkan untuk anda",
	Dashboard_AccountDetails: "Butiran Akaun",
	Dashboard_ChangeProfileImage: "menukar imej profil",
	Dashboard_Title: "Tajuk",
	Dashboard_Role: "Peranan",
	Dashboard_FirstName: "Nama pertama",
	Dashboard_LastName: "Nama terakhir",
	Dashboard_Telephone: "telefon",
	Dashboard_Mobile: "mudah alih",
	Dashboard_Email: "E-mel",
	Dashboard_Language: "Bahasa",
	Dashboard_Save: "Jimat",
	Dashboard_Student: "pelajar",
	Dashboard_WorkshopTrainingAdministrator: "Pentadbir Latihan Bengkel",
	Dashboard_WholesalerTrainingAdministrator: "Pentadbir Latihan Pemborong",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Pentadbiran Syarikat",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Urus bengkel anda...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Urus pekerja anda...",
	Dashboard_CompanyAdministration_Workshop: "Bengkel",
	Dashboard_CompanyAdministration_Address: "Alamat",
	Dashboard_CompanyAdministration_Zip: "Zip",
	Dashboard_CompanyAdministration_City: "Bandar",
	Dashboard_CompanyAdministration_Manage: "Mengurus",
	Dashboard_AdministrationSection_AdministrationTitle: "Pentadbiran",
	Dashboard_AdministrationSection_ManageTheTeam: "Urus pasukan",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "PENTADBIRAN | Urus Pekerja anda",
	Dashboard_AdministrationSection_Employees: "Pekerja",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "PENTADBIRAN | Urus Pemborong anda",
	Dashboard_AdministrationSection_Wholesalers: "Pemborong",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "PENTADBIRAN | Urus Transaksi anda",
	Dashboard_AdministrationSection_Transactions: "Transaksi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Pentadbiran Pekerja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Urus pekerja anda...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Daftar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Rekod",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Sunting",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Pekerja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Tambah",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Buat",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Berdaftar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Yang belum selesai",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(memerlukan tanda keluar Pentadbir)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Rekod Sejarah",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Senarai menunggu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Senarai Pekerja Semasa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Pengurusan Pekerja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "nama",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Akaun Pekerja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Lihat dan edit butiran pekerja...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "tetapan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Benarkan Akaun Peribadi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Naik Taraf Proksi Pentadbiran Bengkel Pekerja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Benarkan Pekerja mendaftar untuk Acara Latihan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Hantar E-mel Jemputan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Nyahaktifkan Akaun",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Jimat",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Hantar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Sunting",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Pengurusan Pemborong",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Uruskan pemborong anda...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Senarai Pemborong yang Diluluskan",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "nama",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Padam",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Buat",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Tambah",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Penciptaan Akaun Pekerja Baharu",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Buat butiran untuk pekerja baharu...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Tambah Pentadbir Pemborong Diluluskan baharu",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Buat butiran untuk pemborong baharu...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Pilih Pemborong yang diluluskan daripada menu lungsur...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transaksi",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Lihat dan urus urus niaga anda...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "belakang",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Nombor invois",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Penerangan",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Kaedah Pembayaran",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Tarikh",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Jumlah",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Muat turun",
	TrainingCentres_TrainingCentres: "Pusat Latihan",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Pusat latihan kami terletak di seluruh dunia",
	TrainingCentres_CountryCategory: "Kategori Negara",
	TrainingCentres_CityCategory: "Kategori Bandar",
	TrainingCentres_Country: "Negara",
	TrainingCentres_City: "Bandar",
	TrainingCatalogue_TrainingCatalogue: "Katalog Kursus Latihan",
	TrainingCatalogue_LearningAtTheHighestLevel: "Pembelajaran di Peringkat tertinggi",
	TrainingCatalogue_StartDate: "Tarikh mula",
	TrainingCatalogue_EndDate: "Tarikh tamat",
	TrainingCatalogue_TrainingName: "Nama Latihan",
	TrainingCatalogue_Language: "Bahasa",
	TrainingCatalogue_Days: "hari-hari",
	TrainingCatalogue_Seats: "Tempat duduk",
	TrainingCatalogue_Price: "harga",
	TrainingCatalogue_Location: "Lokasi",
	TrainingCatalogue_PartNumber: "Nombor Bahagian",
	TrainingCatalogue_TrainingCourse: "Kursus latihan",
	TrainingCatalogue_Category: "Kategori",
	TrainingCatalogue_TrainingCategory: "Kategori Pelatihan",
	TrainingCatalogue_CourseName: "Nama kursus",
	TrainingCatalogue_TotalDays: "Jumlah Hari",
	TrainingCatalogue_Venue: "tempat",
	TrainingCatalogue_Details: "Butiran",
	TrainingCatalogue_Prerequisites: "Prasyarat",
	TrainingCatalogue_CourseType: "Jenis Kursus",
	TrainingCatalogue_LearningMethod: "Kaedah Pembelajaran",
	TrainingCatalogue_AddToWaitingList: "Tambahkan ke senarai menunggu",
	ShoppingCart_ShoppingCart: "Troli Beli-belah",
	ShoppingCart_ReviewAndManage: "Semak dan urus langganan anda",
	ShoppingCart_YouHaveAddedItem: "Anda telah menambahkan item berikut pada troli anda",
	ShoppingCart_Item: "item",
	ShoppingCart_Quantity: "Kuantiti",
	ShoppingCart_PricePerItem: "Harga Setiap Item",
	ShoppingCart_TotalPrice: "Harga keseluruhan",
	ShoppingCart_VatIsIncluded: "Kecuali. SST",
	ShoppingCart_Redeem: "Jika anda tiada kredit, klik di sini untuk menebusnya",
	ShoppingCart_Purchase: "Jika anda tiada kredit klik di sini untuk membeli",
	ShoppingCart_Checkout: "Checkout",
	ShoppingCart_ContinueShopping: "Terus membeli-belah",
	ShoppingCart_TotalPriceInclVat: "Jumlah harga termasuk. SST",
	ShoppingCart_Apply: "Mohon",
	ShoppingCart_ShoppingCartEmpty: "Troli Beli-belah anda kosong",
	ShoppingCart_VatIncluded: "SST(Termasuk)",
	ShoppingCart_ManageYourShopping: "Uruskan belanja anda",
	ShoppingCart_Participant: "peserta",
	ShoppingCart_Complete: "lengkap",
	ShoppingCart_NetPrice: "Harga tetap",
	ShoppingCart_Workshop: "Bengkel",
	CheckOut_PreviewYourOrder: "Pratonton Pesanan Anda",
	CheckOut_YourShoppingCart: "Troli",
	CheckOut_Preview: "Pratonton",
	CheckOut_Payment: "Bayaran",
	CheckOut_Summary: "Ringkasan",
	CheckOut_Back: "belakang",
	CheckOut_PleaseReadAndAgreeOurTerms: "Sila baca dan bersetuju dengan terma dan syarat kami",
	CheckOut_IHaveReadTheTermsAndCondition: "Saya telah membaca [syarat dan ketentuan] dan saya mengerti dan menerimanya",
	CheckOut_YesIAgree: "Ya - Saya bersetuju dengan terma dan syarat",
	CheckOut_Continue: "teruskan",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "tahniah! Kami telah menerima pesanan anda",
	CheckOut_OrderConfirmation: "Anda akan menerima pengesahan pesanan terakhir setelah permintaan anda berjaya diproses",
	CheckOut_WeWillContactYouDirectly: "Kami akan menghubungi anda secara terus sekiranya kami mempunyai sebarang soalan berkaitan permintaan anda",
	CheckOut_AddtoCart: "Tambah ke Troli",
	CheckOut_CreditCart: "Troli Kredit",
	CheckOut_DirectDebit: "Debit langsung",
	CheckOut_TrainingCredits: "Kredit Latihan",
	CheckOut_PreferredWholesaler: "Pemborong Pilihan",
	EventCalendar_EventCalendar: "Kalendar Acara",
	EventCalendar_TrainingEventsForAllYourNeeds: "Acara latihan untuk semua keperluan anda",
	TrainingCourse_TrainingCourse: "Katalog Kursus Latihan",
	TrainingCourse_LearningAtTheHighestLevel: "Pembelajaran di Peringkat tertinggi",
	TrainingProgram_Title: "Katalog Program Latihan",
	TrainingProgram_SubTitle: "Pembelajaran di Peringkat tertinggi",
	AccountDeactivationModal_Title: "Adakah anda pasti mahu menyahaktifkan akaun ini?",
	AccountDeactivationModal_Line1: "Menyahaktifkan akaun ini adalah kekal dan akan membuang semua kandungan termasuk transaksi permintaan perkhidmatan dan mengimbangkan ahli pasukan dan tetapan profil.",
	AccountDeactivationModal_Line2Part1: "Sekiranya dalam tempoh langganan aktif, sila rujuk polisi pembatalan kami di kami",
	AccountDeactivationModal_Line2Part2: "terma & syarat",
	AccountDeactivationModal_Line2Part3: "sebelum mengemukakan permintaan anda.",
	AccountDeactivationModal_Line3: "Adakah anda pasti ingin mengemukakan permintaan untuk mematikan akaun ini?",
	AccountDeactivationModal_Cancel: "Batal",
	AccountDeactivationModal_Send: "Hantar",
	CurrentUserAccountDeactivationModal_Title: "Adakah anda pasti mahu mematikan akaun anda?",
	CurrentUserAccountDeactivationModal_Line1: "Menyahaktifkan akaun anda adalah kekal dan akan membuang semua kandungan termasuk transaksi permintaan perkhidmatan dan mengimbangkan ahli pasukan dan tetapan profil.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Sekiranya anda berada dalam tempoh langganan aktif, sila rujuk polisi pembatalan kami di kami",
	CurrentUserAccountDeactivationModal_Line3: "Adakah anda pasti ingin mengemukakan permintaan untuk mematikan akaun anda?",
	CurrentUserAccountDeactivationModal_Yes: "Ya",
	CurrentUserAccountDeactivationModal_No: "Tidak",
	CourseWithdrawalModal_Title: "Pembatalan anda tidak muktamad sebelum disetujui oleh pentadbiran latihan kami.",
	CourseWithdrawalModal_Line1: "Anda akan menerima pengesahan akhir melalui e-mel setelah diluluskan. Sekiranya kami mempunyai pertanyaan, kami akan menghubungi anda secara langsung. Adakah anda pasti mahu mengemukakan permintaan pengeluaran anda?",
	CourseWithdrawalModal_Line2Part1: "Sila baca kami",
	CourseWithdrawalModal_Line2Part2Link: "Dasar Pembatalan",
	CourseWithdrawalModal_Line2Part3: "Teruskan dengan permintaan pengeluaran?",
	CourseWithdrawalModal_Yes: "Ya",
	CourseWithdrawalModal_No: "Tidak",
	Settings_Settings: "Tetapan",
	Settings_ManageYourSettings: "Uruskan tetapan anda",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Maklumat Log Masuk ID Global Bosch saya",
	Settings_Edit: "Edit",
	Settings_CompanyDetails: "Butiran Syarikat",
	Settings_RequestAccountDeletion: "Minta Penghapusan Akaun",
	Settings_AccountDeletionText: "Sekiranya anda menghapus akaun anda, semua data anda (termasuk alamat dan data pembayaran anda) akan dihapus dan tidak lagi tersedia.",
	Settings_DeleteAccountRequestConfirmation: "Padamkan Pengesahan Permintaan Akaun",
	Settings_DeleteAccountRequestConfirmationText: "Kami telah menerima permintaan penghapusan akaun anda dan akan menghubungi anda melalui e-mel atau telefon untuk pengesahan akhir",
	RecommendedHotels_RecommendedHotels: "Hotel yang disyorkan",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Pusat latihan kami terletak di seluruh dunia",
	Error_Common: "Ada yang tidak kena!",
	Error_404: "Halaman yang anda lawati tidak ada",
	Error_401: "Anda tidak mempunyai akses ke halaman ini",
	Error_500: "Pelayan melaporkan ralat",
	Dashboard_Courses_Table_Course: "Kursus",
	Dashboard_Courses_Table_Date: "Tarikh [Hari]",
	Dashboard_Courses_Table_Venue: "tempat",
	Dashboard_Courses_Table_TrainingType: "Jenis Latihan",
	Dashboard_Courses_Table_Action: "Tindakan",
	Dashboard_Courses_Table_TasksToComplete: "Anda mempunyai tugas berikut untuk diselesaikan",
	Dashboard_Courses_Table_Withdraw: "tarik diri",
	Dashboard_Courses_Table_Remove: "Alih keluar",
	Dashboard_Courses_Table_View: "Lihat",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Tiada rekod untuk dipaparkan",
	Dashboard_Courses_Table_CompletionDate: "Tarikh Siap",
	Dashboard_Courses_Table_Actions_Assignments: "Tugasan",
	Dashboard_Courses_Table_Actions_Certification: "Pensijilan",
	Dashboard_Courses_Table_Actions_Evaluations: "Penilaian",
	Dashboard_Courses_Table_Actions_Assessments: "Penilaian",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Tambahkan pada Kalendar",
	Dashboard_Courses_Table_CourseDetails_Task: "Tugasan",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Tarikh Akhir",
	ContactUs_Headline: "Hubungi Kami",
	ContactUs_HowCanWeHelp: "Bagaimanakah kami boleh membantu anda?",
	ContactUs_EmailTab: "E-mel",
	ContactUs_PhoneTab: "telefon",
	ContactUs_LetterTab: "surat",
	ContactUs_Phone_Headline: "Hubungi kami!",
	ContactUs_Phone_Description: "Kami menantikan panggilan anda dan berbesar hati untuk membantu anda. Kami sedia untuk anda 24 jam, 7 hari seminggu.",
	ContactUs_Phone_PhoneNumber: "Sila gunakan borang hubungan untuk menghantar mesej kepada kami.",
	ContactUs_Phone_ChargeNotice: "Caj berbeza bergantung pada pembekal perkhidmatan dan negara anda.",
	ContactUs_Letter_Headline: "Hantar surat!",
	ContactUs_Letter_Description: "Adakah anda mempunyai soalan, permintaan atau cadangan? Kami menantikan pertanyaan anda.",
	ContactUs_Email_Headline: "Hantar email!",
	ContactUs_Email_Description: "Hantar mesej anda kepada kami dengan mengisi borang di bawah. Salah seorang penasihat kami akan berbesar hati untuk menghubungi anda.",
	ContactUs_Email_InputName: "nama*",
	ContactUs_Email_InputCompany: "Syarikat",
	ContactUs_Email_InputCountry: "negara*",
	ContactUs_Email_InputEmail: "E-mel*",
	ContactUs_Email_InputMessage: "Mesej*",
	ContactUs_Email_DataProtection: "Notis perlindungan data*",
	ContactUs_Email_DataProtectionConfirm: "Saya telah membaca Dasar Privasi.*",
	ContactUs_Email_SecurityCheck: "Pemeriksaan keselamatan",
	ContactUs_Email_SecurityCodeConfirm: "Sila masukkan aksara yang betul yang anda lihat dalam kotak di atas.",
	ContactUs_Email_SecurityCodeInvalid: "Jawapan teks captcha yang anda masukkan adalah salah.",
	ContactUs_Email_MandatoryNotice: "Sila isi ruangan wajib.",
	ContactUs_Email_SuccessMessage: "Mesej anda telah dihantar",
	ContactUs_Email_TakenNoteDataProtection1: "Saya telah membaca",
	ContactUs_Email_TakenNoteDataProtection2: "Dasar Privasi.*",
	ContactUs_Email_SubmitButton: "Hantar",
	ContactUs_Email_FieldErrorMessage: "Sila isi ruangan wajib.",
	ContactUs_Email_SuccessModal_OK: "okey",
	AccountInReviewModal_Title: "Akaun anda sedang dalam semakan!",
	AccountInReviewModal_Paragraph1: "Terima kasih kerana mendaftar akaun peribadi di Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Kakitangan latihan berdedikasi kami sedang menyemak dan mengkonfigurasi akaun anda. Anda akan menerima pengesahan akhir melalui mel, setelah akaun anda berjaya dikonfigurasikan.",
	AccountInReviewModal_Paragraph3: "Sementara itu, sila berasa bebas untuk melayari laman web kami dengan akses terhad.",
	AccountInReviewModal_Paragraph4: "Jika anda mempunyai sebarang soalan, atau jika akaun anda tidak diproses dalam tempoh 48 jam tidak termasuk hujung minggu dan cuti, anda boleh menghubungi kami",
	AccountInReviewModal_ContactUsLink: "di sini",
	AccountInReviewModal_OKButton: "Okay, izinkan saya masuk!",
	Header_YesButton: "ya",
	Header_NoButton: "Tidak",
	Header_LogOutText: "Adakah anda mahu log keluar?",
	Footer_EnquiryText: "Kami menantikan pertanyaan anda,",
	Footer_Telephone: "telefon,",
	Footer_TermsAndConditions: "Terma & Syarat",
	Footer_HowOurTrainingEvolves: "Bagaimana latihan kami berkembang",
	FooterSearch_SearchFieldPlaceholder: "Cari",
	TrainingCatalogue_Action: "Tindakan",
	TrainingCatalogue_Duration: "Tempoh",
	TrainingCatalogue_Information: "Maklumat",
	Settings_PersonalDetails: "Maklumat peribadi",
	Reset_All_Filters: "Tetapkan semula semua penapis",
	EventTable_Days: "hari-hari",
	SearchField_AllResults: "Semua keputusan",
	TrainingCentresLayout_ViewDetails: "Lihat butiran",
	Dropdown_Placeholder: "pilih...",
	WaitlistPopup_SelectButtonText: "Pilih",
	WaitlistPopup_StartDate: "Tarikh mula",
	WaitlistPopup_EndDate: "Tarikh tamat",
	WaitlistPopup_Language: "Bahasa",
	WaitlistPopup_City: "Bandar",
	WaitlistPopup_Workshop: "Bengkel",
	WaitlistPopup_Student: "pelajar",
	WaitlistPopup_PreferredLocation: "Lokasi pilihan",
	WaitlistPopup_SignUpCourseWaitingList: "Daftar ke senarai Menunggu Kursus",
	WaitlistPopup_SignUpWithoutSelectingDate: "Daftar ke senarai Menunggu tanpa memilih tarikh",
	WaitlistPopup_GeneralWaitList: "Senarai menunggu umum",
	WatinglistPopup_CourseLabel: "Kursus",
	Common_ContinueButton: "teruskan",
	UserAccountDeactivationModal_ConfirmLabel: "sahkan",
	Filter_FilterLabel: "Penapis",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Anda sudah berada dalam senarai menunggu, adakah anda mahu mengalih keluar pendaftaran sedia ada anda dan membuat yang baharu?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Ya, keluarkan",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Tidak, Batal",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Pembatalan kursus",
	WaitinglistPopup_SignUpButtonText: "daftar",
	MyAccount_FirstNameRequied: "Nama Pertama diperlukan",
	MyAccount_LastNameRequired: "Nama Akhir diperlukan",
	MyAccount_RoleRequired: "Peranan diperlukan",
	MyAccount_MobileRequired: "Mudah alih diperlukan",
	MyAccount_EmailRequired: "E-mel diperlukan",
	MyAccount_LanguageRequired: "Bahasa diperlukan",
	MyAccount_UpdateSuccessMessage: "Butiran akaun anda berjaya dikemas kini!",
	MyAccount_UpdateSuccessModal_ButtonText: "Ya, dekat",
	Infor_CorporateInformation_Header: "Maklumat Korporat",
	Infor_LegalNotice_Header: "Notis Undang-undang",
	Infor_PrivacyPolicy_Header: "Notis perlindungan data (Dasar privasi)",
	CreateEmployeeAccountModification_FormValidation_Role: "Peranan diperlukan",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Nama pertama diperlukan",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Nama keluarga diperlukan",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mudah alih diperlukan",
	CreateEmployeeAccountModification_FormValidation_Email: "E-mel diperlukan",
	CreateEmployeeAccountModification_FormValidation_Language: "Bahasa diperlukan",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Jemputan untuk menyertai Penyelesaian Latihan Automotif Bosch",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Anda telah berjaya menambah ahli baharu pada pasukan anda!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Adakah anda mahu kami berkongsi jemputan e-mel dengan ahli pasukan baharu anda, dengan arahan bagaimana mereka boleh membuat akaun peribadi mereka sendiri untuk Penyelesaian Latihan Automotif Bosch?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Tidak",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Ya, hantar jemputan",
	ShoppingCart_Venue_Fee: "Yuran Tempat",
	ShoppingCart_Trainning_Name: "Nama Latihan",
	ShoppingCart_Available_Credits: "Kredit Tersedia",
	ConfirmItsMeModal_Hi: "Hai",
	ConfirmItsMeModal_Description1: "Anda telah berjaya melengkapkan jemputan yang dikongsi oleh pentadbir syarikat anda, untuk mencipta akaun peribadi anda sendiri di Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Sebelum meneruskan, sila sahkan atau kemas kini butiran akaun anda.",
	ConfirmItsMeModal_Description3: "Selepas mengesahkan, anda akan diminta untuk log masuk semula untuk melengkapkan penciptaan akaun anda.",
	ConfirmItsMeModal_CompanyDetails: "Butiran Syarikat",
	ConfirmItsMeModal_YourDetails: "Butiran Anda",
	ConfirmItsMeModal_YourDetails_Title: "Tajuk",
	ConfirmItsMeModal_YourDetails_Function: "Fungsi",
	ConfirmItsMeModal_YourDetails_Name: "nama",
	ConfirmItsMeModal_YourDetails_Mobile: "mudah alih",
	ConfirmItsMeModal_YourDetails_Email: "E-mel",
	ConfirmItsMeModal_YourDetails_Language: "Bahasa",
	ConfirmItsMeModal_ConfirmButton: "sahkan",
	SelectWholesalerModal_WholesalerGroupLabel: "Pemborong",
	SelectWholesalerModal_WholesalerBranchLabel: "Outlet",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Sila pilih",
	ApplyButton: "Mohon",
	SelectWholesalerModal_Title: "Pilih Pemborong anda",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Adakah anda mahu mengalih keluar pendaftaran anda?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Pendaftaran anda berjaya dialih keluar!",
	WaitlistPopup_AddToWaitListSuccess: "Pendaftaran anda ditambah kejayaan!",
	ErrorCommon_Header: "RALAT",
	CommonButton_Close: "tutup",
	MyELearning_OpeningVideoPlayerTitle: "ePembelajaran Automotif Bosch",
	MyELearning_VideoPlayerClosingMessage: "Sila tutup tetingkap ini untuk meneruskan!",
	EmbeddedVideoPlayer_StartButtonText: "Mulakan",
	EmbeddedVideoPlayer_RefresherButtonText: "Penyegar semula",
	DeactivateWholesalerModal_ConfirmMessage: "Adakah anda pasti mahu mengalih keluar pemborong ini?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Hantar e-mel jemputan berjaya!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Menjemput semula",
	ContactUs_Mobile_MY: "+60(3)7966-3000",
	ContactUs_Address_MY: "Robert Bosch Sdn Bhd,No 8A Jalan 13/6,46200, Petaling Jaya,Selangor Darul Ehsan,Malaysia",
	ConfirmItsMeModal_CompanyDetails_Name: "Nama syarikat",
	ConfirmItsMeModal_CompanyDetails_Address: "Alamat Alamat",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Poskod Syarikat",
	ConfirmItsMeModal_CompanyDetails_Phone: "telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-mel",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Menarik diri daripada Kursus berjaya!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Pembuatan akaun gagal. Akaun sudah sedia ada!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Kemas kini akaun gagal. Akaun sudah sedia ada!",
	Footer_ExploreTrainingStories: "Terokai semua cerita latihan kami",
	Breadcrumb_Home: "Rumah",
	Breadcrumb_Contact: "Kenalan",
	Breadcrumb_Stories: "Cerita",
	Breadcrumb_Stories_BoschServiceTraining: "Latihan Perkhidmatan Bosch",
	Breadcrumb_Stories_ADayInLife: "Satu hari dalam kehidupan seorang jurulatih",
	Breadcrumb_Stories_BoschTrainingBenefits: "Faedah Latihan Bosch",
	Breadcrumb_Stories_TrainingApproach: "Pendekatan latihan moden",
	Breadcrumb_Settings: "tetapan",
	Breadcrumb_ShoppingCart: "Troli Beli Belah",
	Breadcrumb_Checkout: "Daftar Keluar",
	Breadcrumb_OrderSummary: "Ringkasan Pesanan",
	Breadcrumb_Exception: "Pengecualian",
	Breadcrumb_Dashboard: "Papan pemuka",
	Breadcrumb_MyCourses: "Kursus Saya",
	Breadcrumb_MyELearning: "ePembelajaran saya",
	Breadcrumb_CompanyAdministration: "Pentadbiran Syarikat",
	Breadcrumb_EmployeeManagement: "Pengurusan Pekerja",
	Breadcrumb_EmployeeAccount: "Akaun Pekerja",
	Breadcrumb_EmployeeCreation: "Penciptaan Pekerja",
	Breadcrumb_Administration: "Pentadbiran",
	Breadcrumb_EmployeeAdministration: "Pentadbiran Pekerja",
	Breadcrumb_WholesalerManagement: "Pengurusan Pemborong",
	Breadcrumb_NewWholesalerAccountCreation: "Penciptaan Akaun Pemborong Baharu",
	Breadcrumb_Transactions: "Transaksi",
	Breadcrumb_MyAccount: "Akaun saya",
	Breadcrumb_TrainingCentres: "Pusat Latihan",
	Breadcrumb_EventCalendar: "Kalendar Acara",
	Breadcrumb_TrainingCourseCatalogue: "Katalog Kursus Latihan",
	Breadcrumb_RecommendedHotels: "Hotel yang Disyorkan",
	Breadcrumb_CorporateInformation: "Maklumat Korporat",
	Breadcrumb_LegalNotice: "Notis Undang-undang",
	Breadcrumb_DataProtectionNotice: "Notis Perlindungan Data",
	Breadcrumb_PrivacyPolicy: "Dasar Privasi",
	Breadcrumb_TermCondition: "Terma & Syarat",
	Breadcrumb_Terms: "Syarat",
	Breadcrumb_PrivacyStatement: "Kenyataan privasi",
	Breadcrumb_Cookies: "biskut",
	Breadcrumb_Provider: "Pembekal",
	Breadcrumb_RegisterAccount: "Daftar Akaun",
	Stories_ExploreMore: "Terokai lebih banyak lagi",
	CheckOutLayout_ShoppingCartHeader: "Troli Beli Belah",
	CheckOutLayout_AddedItemCartMessage: "Anda telah menambah yang berikut pada troli anda",
	CheckOutLayout_CheckYourCartMessage: "Semak troli anda",
	ModernTrainingApproach_DateText: "44206",
	ADayInLifeofATrainer_DateText: "44620",
	BoschTrainingBenefits_DateText: "44620",
	BoschServiceTraining_DateText: "44620",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Cari Pekerja",
	CheckOutLayout_OrderWasNotPlaced_Message: "Maaf! Pesanan anda tidak dibuat",
	CheckOutLayout_RefusalReason: "Alasan Penolakan",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Pembayaran gagal. Sila cuba semula dengan kaedah pembayaran lain",
	CheckOutLayout_SubmittingApiFailed_Message: "Penyerahan API gagal",
	CartItem_ChooseWorkshop_Message: "Sila pilih bengkel untuk memilih peserta",
	CartItem_ChooseParticipant_Message: "Sila pilih hadir untuk meneruskan",
	CompanyAdministrationTable_SearchBox_Placeholder: "Bengkel Carian",
	CartItem_RemoveCartItem_ConfirmText: "Adakah anda mahu mengalih keluar item ini daripada troli?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Fungsi",
	AddToCartSuccessModal_Message: "Masukkan kod dan gunakan",
	AddToCartSuccessModal_ContinueButtonText: "Terus membeli-belah",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Troli Beli Belah Saya",
	AddVoucher_Header: "Tambah baucar",
	AddVoucher_InputPlaceholder: "Masukkan kod dan gunakan",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Malangnya, kami tidak mempunyai sebarang acara berjadual kursus latihan ini pada masa ini. Sila daftar ke senarai menunggu, dan kami akan memaklumkan anda sebaik sahaja acara tersedia.",
	ADYEN_ERR_2_: "transaksi dibatalkan",
	ADYEN_ERR_3_: "Rujukan kesilapan.",
	ADYEN_ERR_4_: "Transaksi dibatalkan kerana kesilapan daripada pemeroleh.",
	ADYEN_ERR_5_: "Kad yang digunakan untuk transaksi disekat, oleh itu tidak boleh digunakan.",
	ADYEN_ERR_6_: "Kad yang digunakan untuk transaksi telah tamat tempoh. Oleh itu ia tidak boleh digunakan.",
	ADYEN_ERR_7_: "Jumlah tidak sepadan berlaku semasa proses transaksi.",
	ADYEN_ERR_8_: "Nombor kad yang dinyatakan tidak betul atau tidak sah.",
	ADYEN_ERR_9_: "Anda tidak boleh menghubungi bank pembeli untuk membenarkan transaksi.",
	ADYEN_ERR_10_: "Bank pembeli tidak menyokong atau tidak membenarkan jenis transaksi ini.",
	ADYEN_ERR_11_: "Pengesahan 3D Secure tidak dilaksanakan atau tidak berjaya dilaksanakan.",
	ADYEN_ERR_12_: "Kad tidak mempunyai wang yang mencukupi untuk menampung jumlah yang perlu dibayar. Masalah ini mungkin berlaku disebabkan oleh had amaun perlu dibayar bulanan pada kad anda. Sila cuba gunakan kad lain seperti MasterCard, atau hubungi bank anda untuk menetapkan semula had bulanan kad anda.",
	ADYEN_ERR_14_: "Kemungkinan penipuan.",
	ADYEN_ERR_15_: "Transaksi dibatalkan.",
	ADYEN_ERR_16_: "Pembeli membatalkan transaksi sebelum menyelesaikannya.",
	ADYEN_ERR_17_: "PIN yang ditentukan adalah tidak betul atau tidak sah.",
	ADYEN_ERR_18_: "The shopper specified an incorrect PIN more that three times in a row.",
	ADYEN_ERR_19_: "Tidak mungkin untuk mengesahkan nombor PIN yang ditentukan.",
	ADYEN_ERR_20_: "Kemungkinan penipuan.",
	ADYEN_ERR_21_: "Urus niaga tidak diserahkan dengan betul untuk diproses.",
	ADYEN_ERR_22_: "Semakan risiko menandakan urus niaga itu sebagai penipuan (skor risiko >= 100); oleh itu, operasi dibatalkan.",
	ADYEN_ERR_23_: "Kod yang ditolak berikut diatas sebab penolakan ini: Transaksi tidak dibenarkan kepada pengeluar/pemegang kad",
	ADYEN_ERR_24_: "CVC (kod keselamatan kad) yang ditentukan adalah tidak sah.",
	ADYEN_ERR_25_: "Kod penolakan berikut diatas sebab penolakan ini: Kad tidak sah di negara ini",
	ADYEN_ERR_26_: "R1: Pembatalan Perintah Kebenaran / \"R3: Pembatalan Semua Perintah Kebenaran\" / \"R0: Perintah Hentikan Pembayaran\"",
	ADYEN_ERR_27_: "Respons ini memetakan semua kod respons yang tidak boleh dipetakan dengan pasti. Ini memudahkan untuk memberitahu penolakan generik (contohnya, jawapan Mastercard \"05: Jangan hormati\") daripada yang lebih khusus.",
	ADYEN_ERR_28_: "The withdrawal amount permitted for the shopper's card has exceeded.",
	ADYEN_ERR_29_: "Bilangan pengeluaran yang dibenarkan untuk kad pembeli telah melebihi.",
	ADYEN_ERR_31_: "Pengeluar melaporkan transaksi tersebut sebagai penipuan yang disyaki.",
	ADYEN_ERR_32_: "Data alamat yang dimasukkan pembeli adalah salah.",
	ADYEN_ERR_33_: "Bank pembeli memerlukan pembeli memasukkan PIN dalam talian.",
	ADYEN_ERR_34_: "Bank pembeli memerlukan akaun cek untuk melengkapkan pembelian.",
	ADYEN_ERR_35_: "Bank pembeli memerlukan akaun simpanan untuk melengkapkan pembelian.",
	ADYEN_ERR_36_: "Bank pembeli memerlukan pembeli memasukkan PIN mudah alih.",
	ADYEN_ERR_37_: "Pembeli telah meninggalkan transaksi selepas mencuba pembayaran tanpa sentuh dan digesa untuk mencuba kaedah kemasukan kad yang lain (PIN atau leret).",
	ADYEN_ERR_38_: "Pengeluar menolak permintaan pengecualian pengesahan dan memerlukan pengesahan untuk transaksi. Cuba semula dengan 3D Secure.",
	ADYEN_ERR_39_: "Pengeluar atau skim tidak dapat menyampaikan hasilnya melalui RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Nama pertama tidak boleh mengandungi aksara khas (cth: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Nama pertama mestilah kurang daripada 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Nama keluarga tidak boleh mengandungi aksara khas (cth: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Nama keluarga mestilah kurang daripada 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Nombor mudah alih tidak boleh termasuk aksara khas (cth: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Nombor mudah alih mestilah kurang daripada 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Nombor telefon tidak boleh termasuk aksara khas (cth: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Nombor telefon mestilah kurang daripada 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Alamat e-mel tidak sah",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-mel mestilah kurang daripada 100",
	ConfirmGeolocationModal_ConfirmButton: "Teruskan",
	ConfirmGeolocationModal_CancelButton: "Ubah",
	ConfirmGeolocationModal_CurrentCountrySelection: "Pilihan negara semasa anda ialah:",
	ConfirmGeolocationModal_AskChangeCountry: "Adakah anda ingin kekal di tapak ini atau menukar pilihan negara anda?",
	Addtocart_Modal_AddToCartFailTitle: "Peserta telah pun mendaftar didalam kursus ini",
	Addtocart_Modal_AlreadyInCart: "Peserta telah pun mendaftar didalam acara ini",
	TrainingCatalogue_DateRange: "Julat Tarikh",
	TrainingCatalogue_DateRange_placeholder: "Pilih penapis julat tarikh",
	Dashboard_MyPendingCourses: "Kursus Tertunda Saya",
	Dashboard_MyWaitingList: "Senarai Menunggu Saya",
	Dashboard_LatestNews: "Berita terkini",
	SettingsMainPage_EditAccountButton: "Edit Akaun",
	SettingsMainPage_EditBoschId: "Edit ID Bosch",
	EventInfoView_EventDetailTab: "Butiran Acara",
	EventInfoView_AssignmentsTab: "Tugasan",
	EventInfoView_EvaluationTab: "Penilaian",
	EventInfoView_AttachmentTab: "Lampiran",
	EventInfoView_CourseDescriptionTab: "Penerangan",
	EventInfoView_DescriptionTab_General: "Umum",
	EventInfoView_DescriptionTab_PartLabel: "Bahagian",
	AssignmentTab_Description: "Di sini anda akan menemui semua maklumat yang anda perlukan untuk melengkapkan kursus ini.",
	AssignmentTab_Columns_Download: "Muat turun",
	AssignmentTab_Columns_Upload: "Muat naik",
	AssignmentTab_Columns_TaskComplete: "Tugas Selesai",
	EventDetailTab_Location_Message_Video: "Ini ialah acara dalam talian yang boleh diakses dengan menggunakan pautan \"Launch Video\".",
	EventDetailTab_Location_Message_WebBased: "Ini ialah acara dalam talian yang boleh diakses dengan menggunakan pautan \"Launch WBT\".",
	EventDetailTab_Location_Message_WebCast: "Ini ialah acara dalam talian yang boleh diakses dengan menggunakan pautan \"Sertai Sekarang\".",
	EventDetailTab_Link_Video: "Lancarkan Video",
	EventDetailTab_Link_Video_Remark: "*Melancarkan video akan membuka tetingkap baharu. Anda akan dialihkan semula ke halaman ini sebaik sahaja tetingkap ditutup.",
	EventDetailTab_Link_WebBased: "Lancarkan WBT",
	EventDetailTab_Link_WebBased_Remark: "*Melancarkan WBT akan membuka tetingkap baharu. Anda akan dialihkan semula ke halaman ini sebaik sahaja tetingkap ditutup.",
	EventDetailTab_Link_WebCast: "Sertai sekarang",
	EventDetailTab_Link_WebCast_Remark: "*Pautan Sertai Sekarang akan aktif 30 minit sebelum acara bermula.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Tempoh (jam)",
	EventDetailTab_Title_Trainers: "Jurulatih",
	EventDetailTab_Title_Language: "Bahasa",
	EventDetailTab_Title_Score: "Skor (%)",
	EventDetailTab_Title_Calendar: "Tambahkan pada kalendar",
	EventDetailTab_Title_Certificate: "Muat turun sijil",
	EventDetailTab_Title_Messages: "Mesej",
	EventDetailTab_Title_StartDate: "Tarikh mula",
	EventDetailTab_Title_AvailableFrom: "Boleh didapati daripada",
	EventDetailTab_Title_Time: "Masa",
	EventDetailTab_Title_EndDate: "Tarikh tamat",
	EventDetailTab_Title_ExpiresOn: "Luput pada",
	EventDetailTab_Title_Room: "Bilik",
	EventDetailTab_Title_Method: "Kaedah",
	EventDetailTab_Title_Link: "Pautan*",
	EventDetailTab_Title_Location: "Lokasi",
	AttachmentTab_EmptyScreenDescription: "Tiada lampiran untuk acara ini.",
	AttachmentTab_Columns_Download: "Muat turun",
	AttachmentTab_Description: "Di sini anda akan menemui beberapa maklumat penting untuk membantu anda dengan kursus ini",
	EvaluationTab_Annotation: "Kami menghargai maklum balas anda dan ingin mendengar daripada anda untuk melihat prestasi kami. Sila luangkan masa untuk mengisi penilaian berikut untuk membantu kami meningkatkan perkhidmatan kami kepada anda secara berterusan. Terima kasih.",
	EvaluationTab_Notice: "Penilaian adalah seperti berikut",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Sila jawab semua soalan wajib sebelum menyerahkan penilaian acara anda",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Maklum balas anda telah berjaya diserahkan",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Penyerahan penilaian gagal",
	TrainingCatalogueLayout_ExportButtonText: "Eksport",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Penilaian telah selesai atau telah tamat tempoh.",
	EventDetailTab_Link_CopiedTooltip: "Disalin ke Clipboard",
	EventDetailTab_QRCode_ExplanationText_WBT: "Imbas untuk melancarkan Latihan Pangkalan Web",
	EventDetailTab_QRCode_ExplanationText_Video: "Imbas untuk melancarkan Video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Imbas untuk melancarkan Webcast",
	Footer_TrainingCoure_Headline: "Kursus latihan disediakan oleh Bosch",
	Footer_TrainingCourse_Content: "Sebagai Superbrand yang terkenal di dunia dan dengan pengalaman lebih seabad dalam inovasi, Bosch telah menetapkan piawaian di peringkat global sebagai pembekal sistem automotif, alat ganti dan penyelesaian teknikal. Sebahagian besar kekuatan kami datang daripada perkongsian rapat kami dengan pengeluar kenderaan, yang kami reka bentuk, bangunkan dan hasilkan sistem dan komponen tercanggih di seluruh dunia. Akibatnya, Bosch telah mengukuhkan sejarah kukuh dalam produk pasaran selepas pasaran automotif dan sokongan dengan pelbagai portfolio alat ganti, penyelesaian diagnostik dan perkhidmatan bengkel. Pembelajaran dan pembangunan adalah kunci kejayaan yang pasti dan falsafah ini disokong oleh pelbagai kursus latihan, dalam semua bidang teknologi automotif, sistem kenderaan dan kepakaran diagnostik.",
	SearchField_NoRecordsFound: "Tiada rekod dijumpai",
	TrainingCatalogue_FreeTextSearch: "Teks Bebas",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Cari",
	CheckoutLayout_WholeSalerGroup: "Pilih Kumpulan Pemborong",
	CreditInsufficientModal_Message: "Anda tidak mempunyai kredit yang mencukupi untuk menggunakan kaedah pembayaran ini. Sila cuba lagi dengan kaedah pembayaran lain.",
	ShoppingCart_Required_Credits: "Harga keseluruhan",
	MaintainingInfo_Message1: "Laman web ini dalam mod penyelenggaraan.",
	MaintainingInfo_Message2: "Kami akan kembali tidak lama lagi.",
	CountryValidationModal_Message: "Anda tidak boleh log masuk ke [CountryA] kerana akaun anda telah dibuat di [CountryB].",
	CountryValidationModal_ButtonText: "Teruskan ke [Country]",
	EventTable_LoginRequired: "Log masuk untuk mendaftar",
	TrainingPartTableLayout_Date: "Tarikh",
	TrainingPartTableLayout_StartTime: "Masa mula",
	TrainingPartTableLayout_EndTime: "Masa tamat",
	TrainingPartTableLayout_Duration: "Tempoh (jam)",
	TrainingPartTableLayout_TrainingMethod: "Kaedah Latihan",
	MyCoursesTableContents_ActiveHeader: "Kursus Aktif",
	MyCoursesTableContents_ActiveTabHeader: "Aktif",
	MyCoursesTableContents_ScheduledHeader: "Kursus Berjadual",
	MyCoursesTableContents_ScheduledTabHeader: "Dijadualkan",
	MyCoursesTableContents_OnlineHeader: "Kursus Dalam Talian",
	MyCoursesTableContents_OnlineTabHeader: "dalam talian",
	MyCoursesTableContents_CancelledHeader: "Kursus yang Dibatalkan",
	MyCoursesTableContents_CancelledTabHeader: "Dibatalkan",
	OnlineCoursesTable_Title_Progress: "Kemajuan",
	ScheduledCoursesTable_Title_Date: "Tarikh [Hari]",
	CoursesTable_Title_Completed: "Selesai",
	Footer_TrainingProgram: "Program Latihan",
	Breadcrumb_TrainingProgram: "Program Latihan",
	TrainingProgram_Title_Table: "Tajuk",
	TrainingProgram_TrainingProgram: "Program Latihan",
	TrainingProgram_TargetGroup: "Kumpulan sasaran",
	TrainingProgram_MaxLength: "Maks. Panjang",
	TrainingProgram_Information: "Maklumat",
	TrainingProgram_Detail: "Butiran",
	TrainingProgram_Modules: "Modul",
	TrainingProgram_Course: "Kursus",
	TrainingProgram_PartNumber: "Nombor Bahagian",
	TrainingProgram_Max_Length: "Panjang Maksimum",
	TrainingProgram_Duration: "Tempoh",
	TrainingProgram_Exam: "Peperiksaan",
	TrainingProgram_Assign_Popup: "Daftar untuk program latihan",
	TrainingProgram_Student: "Pelajar",
	TrainingProgram_Assign: "Tugaskan",
	TrainingProgram_Popup_Remove_Title: "Pembatalan latihan?",
	TrainingProgram_Description: "Penerangan",
	TrainingProgram_Days: "hari-hari",
	TrainingProgram_Type: "taip",
	TrainingProgram_Action: "Tindakan",
	TrainingProgram_Enrolled: "telah pun mendaftar ke program latihan ini",
	TrainingProgram_SelfAssign_Enrolled: "Anda telah pun ditugaskan untuk program latihan ini",
	TrainingProgram_Warning: "Sila daftarkan pelajar lain atau pilih program latihan lain.",
	TrainingProgram_Workshop: "Bengkel",
	TrainingProgram_Durations: "tahun",
	TrainingProgram_Duration_DaysLabel: "hari",
	TrainingProgram_HasExam_Yes: "ya",
	TrainingProgram_HasExam_No: "Tidak",
	AssignModal_SuccessModal_Header: "tahniah!",
	AssignModal_SuccessModal_Message: "telah didaftarkan ke program latihan ini dengan jayanya.",
	AssignModal_SelfAssign_SuccessModal_Message: "Anda telah berjaya ditugaskan ke program latihan.",
	Dashboard_MyTraningProgram: "Program Latihan Saya",
	Dashboard_MyTraningProgram_Title: "Tajuk",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Lihat",
	Dashboard_MyTraningProgram_Register: "Daftar",
	Dashboard_MyTraningProgram_Records: "Rekod Sejarah",
	Dashboard_MyTrainingProgram_In_Progress: "Sedang berlangsung",
	Dashboard_MyTrainingProgram_Accredited: "Bertauliah",
	Dashboard_Program_Modal_Withdraw: "tarik diri",
	Dashboard_Program_Modal_Grade: "Gred",
	Dashboard_MyTrainingProgram_Registered: "Berdaftar",
	Dashboard_MyTrainingProgram_Completedd: "Selesai",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "ya",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Tidak",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Sila ambil perhatian bahawa menarik diri bermakna anda akan menarik diri daripada program latihan yang lengkap, bukan hanya daripada kursus latihan individu.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Adakah anda masih ingin menarik diri daripada program latihan?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Yang belum selesai",
	WithdrawProgramModal_Withdraw_Success_Message: "Anda telah berjaya menarik diri daripada program latihan!",
	Common_Warning_Title: "Amaran!",
	Common_Error_Title: "Oops, sesuatu telah berlaku!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Tambah ke Troli",
	AddEventFromCatalogToCartModal_HoursText: "Jam",
	AddElearningToCartSuccessModal_Message: "telah ditambahkan pada troli beli-belah anda!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Peserta yang dipilih telah didaftarkan ke Kursus ini, atau Kursus telah ditambahkan ke troli.",
	EventDetailTab_SubjectForChange_Message: "Tertakluk untuk perubahan",
	Dashboard_MyTrainingProgram_Status_Failed: "gagal",
	Dashboard_MyTrainingProgram_Status_Passed: "lulus",
	Dashboard_MyTrainingProgram_Status_Merit: "Merit",
	Dashboard_MyTrainingProgram_Status_Distinction: "Perbezaan",
	Dashboard_Employee_Courses: "Kursus",
	Dashboard_Employee_Program: "Program Latihan",
	Dashboard_Employee_WaitList: "Senarai Tunggu",
	Dashboard_Employee_Account: "Akaun",
	Course_Price_Free: "Percuma",
	Dashboard_Employee_Account_Update_Success_Message: "Anda telah berjaya mengemas kini maklumat pekerja anda!",
	Dashboard_Supplier: "Penjual semula",
	Event_Detail_Comment: "Pada masa ini tiada mesej",
	DownLoad_PDF: "Muat Turun PDF",
	TrainingCatalogue_Currency: "mata wang",
	My_Wallet: "Dompet saya",
	Wallet: "Dompet",
	Credits_Balance: "Baki Kredit Latihan",
	MyFullCalendar_Today: "Hari ini",
	Header_Available_Languages: "Bahasa yang Tersedia",
	Header_CurrentRegion: "Anda sedang dalam",
	Header_ChangeRegions: "Adakah anda mahu mengubah negara?",
	Header_Anonymous: "Latihan Perkhidmatan",
	Header_Anonymous_Login: "Log masuk / Daftar",
	Header_RegisterCourse: "Latihan Buku",
	Homepage_Start: "Daftar",
	Homepage_Login: "Log masuk",
	MainPage_Title: "Penyelesaian Bengkel Terbaik",
	MainPage_Content: "Bengkel anda, pilihan anda.",
	MainPage_ImageTitle: "Penyelesaian Latihan Automotif Bosch",
	MainPage_IntroContent: "Membolehkan bengkel moden berfungsi secara profesional, boleh dipercayai & cekap dalam prosedur diagnostik, penyelesaian masalah, pembaikan dan servis semua jenis kenderaan.",
	BoschNews_TrainingNews: "Berita Latihan",
	Service_Assist_Workshop: "Tingkatkan pengalaman anda dengan Bantuan Perkhidmatan Bengkel",
	Enhance_Title_CDM3_v2: "Apa yang boleh saya lakukan dengan apl itu?",
	Enhance_Body_CDM3_Item_1: "Tempah kursus dan kekalkan perjalanan pembelajaran anda",
	Enhance_Body_CDM3_Item_2: "Muat turun bahan latihan dan sijil kursus",
	Enhance_Body_CDM3_Item_3: "Melancarkan kursus ePembelajaran",
	Enhance_Body_CDM3_Item_4: "Akses pelbagai perkhidmatan automotif dalam aplikasi mudah alih",
	Enhance_Body_CDM3_Item_5: "Penghantaran video secara langsung dengan Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Alat percuma untuk imbasan kenderaan automatik",
	Training_Stories: "Cerita Latihan",
	SettingsMyAccount_JoinCompanyButton: "Sertai Perniagaan",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Sertai Perniagaan",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Sila masukkan kod pengaktifan anda di bawah",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Nota: Sila hubungi pentadbir BATS perniagaan anda untuk mendapatkan kod pengaktifan di tapak BATS.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Pengaktifan anda telah berjaya diproses dan akaun anda kini dipautkan ke perniagaan anda.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Sila klik Teruskan untuk memasuki laman web.",
	ContinueButton: "Teruskan",
	WelcomeBatsModal_Title: "Akaun anda sedang dalam semakan!",
	WelcomeBatsModal_ActivationLink: "Klik di sini jika anda mempunyai Kod Pengaktifan untuk menyertai akaun perniagaan anda!",
	WelcomeBatsModal_ActivationLinkNotes: "Nota: Anda perlu menghubungi pentadbir perniagaan anda untuk mendapatkan kod pengaktifan.",
	AccountInReviewModal_Description_Paragraph1: "Terima kasih kerana mendaftar akaun peribadi di Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Kakitangan latihan berdedikasi kami sedang menyemak dan mengkonfigurasi akaun anda. Anda akan menerima pengesahan akhir melalui e-mel, setelah akaun anda berjaya dikonfigurasikan.",
	AccountInReviewModal_CloseButton: "Okay, izinkan saya masuk!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Kami tidak dapat mengesahkan kod pengaktifan dan alamat e-mel anda.",
	TryAgain_Button: "Cuba lagi",
	Error_Title: "Oops, sesuatu telah berlaku!",
	SettingsMyProfileActivationSuccessModal_Title: "Berjaya!",
	AccountInReviewModal_Description_Paragraph3: "Sementara itu, sila berasa bebas untuk melayari laman web kami dengan akses terhad.",
	AccountInReviewModal_Description_Paragraph4: "Jika anda mempunyai sebarang soalan, atau jika akaun anda tidak diproses dalam masa 72 jam tidak termasuk hujung minggu dan cuti, anda boleh menghubungi kami [di sini]",
	Support_Headline: "Penyelesaian Latihan Automotif Bosch",
	Support_SubHeadline: "Beberapa panduan untuk membantu anda...",
	Support_FAQ_Headline: "Soalan lazim",
	Support_FAQs_section1_title: "Umum",
	Support_FAQs_section1_question1: "Apakah itu Penyelesaian Latihan Automotif Bosch (BATS)?",
	Support_FAQs_section1_question1_answer1: "Penyelesaian Latihan Automotif Bosch ialah platform yang menyediakan gambaran menyeluruh tentang latihan teknikal dan membolehkan anda menempah & mengurus kursus latihan.",
	Support_FAQs_section1_question2: "Bolehkah saya menggunakan akaun Bosch saya yang sedia ada (ID SingleKey) untuk log masuk ke Bosch Automotive Training Solutions (BATS)?",
	Support_FAQs_section1_question2_answer1: "Ya, BATS boleh diakses dengan \"ID Bosch Saya\".",
	Support_FAQs_section1_question3: "Bolehkah saya menggunakan akaun saya yang dicipta untuk Penyelesaian Latihan Automotif Bosch untuk perkhidmatan atau aplikasi Bosch yang lain?",
	Support_FAQs_section1_question3_answer1: "Ya, anda juga boleh menggunakan ID Bosch peribadi anda untuk pelbagai aplikasi Bosch yang lain - secara profesional dan peribadi, seperti e-basikal atau rumah pintar.",
	Support_FAQs_section2_title: "Latihan",
	Support_FAQs_section2_question1: "Bagaimanakah saya boleh menempah latihan?",
	Support_FAQs_section2_question1_answer1: "Log masuk dengan akaun sedia ada anda atau daftar ke portal. Pergi ke \"Perkhidmatan Latihan\" atau \"Kalendar Acara\" pilih kursus latihan dan klik pada butang tambah ke troli (ikon troli beli-belah)",
	Support_FAQs_section2_question2: "Tiada tarikh latihan yang sesuai tersedia. Apa yang boleh saya lakukan?",
	Support_FAQs_section2_question2_answer1: "Anda mempunyai kemungkinan untuk meletakkan diri anda dalam senarai tunggu \"umum\" (tanpa memilih tarikh) dan pasukan kami akan memaklumkan anda sebaik sahaja tarikh latihan baharu tersedia.",
	Support_FAQs_section2_question3: "Di manakah saya boleh mendapatkan gambaran keseluruhan kursus yang saya tempah?",
	Support_FAQs_section2_question3_answer1: "Anda boleh mendapatkan gambaran keseluruhan lengkap bagi semua kursus yang ditempah & lengkap di papan pemuka anda melalui: Latihan Saya > Gambaran Keseluruhan Anda",
	Support_FAQs_section3_title: "tetapan",
	Support_FAQs_section3_question1: "Bagaimanakah saya boleh menukar E-mel & Kata Laluan saya?",
	Support_FAQs_section3_question1_answer1: "Anda perlu log masuk dahulu ke portal dan kemudian anda boleh menukar kepada maklumat profil peribadi anda melalui: Latihan Saya > Akaun Bosch Saya",
	Support_FAQs_section3_question1_answer3: "Di sana anda akan dapat menukar butiran (e-mel/kata laluan) ID Bosch anda",
	Support_FAQs_section3_question2: "Apa yang boleh saya lakukan apabila saya terlupa kata laluan saya?",
	Support_FAQs_section3_question2_answer1: "Sila gunakan butang Log masuk atau Daftar dan isikan alamat e-mel anda",
	Support_FAQs_section3_question2_answer2: "Jika anda klik pada teruskan anda mempunyai kemungkinan untuk mengklik pada \"Lupa Kata Laluan\"",
	Support_FAQs_section3_question3: "Bagaimana untuk menyediakan akaun pengurus bengkel?",
	Support_FAQs_section3_question3_answer1: "Jika anda perlu mengakses semua maklumat latihan kakitangan perniagaan anda, anda memerlukan peranan pengurus bengkel. Sila hubungi Pentadbiran Latihan tempatan anda cth. anda boleh menggunakan borang hubungan di laman web.",
	LandingPage_Header_Title: "Penyelesaian Latihan Automotif Bosch",
	LandingPage_ChooseCountry_Title: "Pilih Negara / Wilayah anda",
	MaintenanceBanner_Message_1: "Portal Penyelesaian Latihan Automotif Bosch akan dikemas kini pada [maintenance_schedule]. Kemas kini dijangka mengambil masa 30 minit. Portal tidak akan tersedia semasa kemas kini.",
	MaintenanceBanner_Message_2: "Untuk bantuan lanjut, sila hubungi kami melalui e-mel [support_email]",
	MaintenancePage_Message_1: "Portal Penyelesaian Latihan Automotif Bosch sedang dikemas kini",
	MaintenancePage_Message_2: "Kami menjangkakan Portal akan tersedia semula pada [maintenance_online_date] pada [maintenance_online_time]",
	MenuHeader_Support: "Sokongan",
	Course_Detail_No_Prerequisites: "Tiada Prasyarat",
	TableCell_Hours: "Jam",
	Dashboard_MenuItem_Dashboard: "Papan pemuka",
	Dashboard_MenuItem_Curricula: "Program Latihan",
	Dashboard_MenuItem_Certificates: "Sijil",
	Dashboard_MenuItem_Team: "Pasukan",
	Dashboard_MenuItem_Workshops: "Bengkel",
	Dashboard_MenuItem_External: "Penjual semula",
	Dashboard_MenuItem_Wallet: "Dompet",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Kursus Anda Akan Datang",
	Dashboard_Headline_YourELearningCourses: "Kursus ePembelajaran anda",
	Dashboard_Headline_TeamOverview: "Gambaran Keseluruhan Pasukan",
	Dashboard_Headline_YourOverview: "Gambaran Keseluruhan Anda",
	Dashboard_Headline_YourWishlist: "Program Latihan Anda",
	Dashboard_Headline_ActivePrograms: "Sijil anda",
	Dashboard_Headline_CompletedPrograms: "Program Selesai",
	Dashboard_Headline_YourCertificates: "Sijil anda",
	Dashboard_Headline_Transactions: "Transaksi",
	Dashboard_Table_NoRecordsToDisplay: "tiada rekod untuk dipaparkan",
	Dashboard_TableColumn_Location_Online: "dalam talian",
	Dashboard_TableColumn_Duration_Hours: "Jam",
	Dashboard_ELearningCourses_TableHeader_Title: "Tajuk",
	Dashboard_ELearningCourses_TableHeader_Duration: "Tempoh (jam)",
	Dashboard_ELearningCourses_ActionButton_View: "Lihat",
	Dashboard_UpcomingCourses_TableHeader_Title: "Tajuk",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Tarikh [Hari]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Lokasi",
	Dashboard_UpcomingCourses_ActionButton_View: "Lihat",
	Dashboard_YourOverview_TableHeader_Title: "Tajuk",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Tarikh / Tempoh",
	Dashboard_YourOverview_TableHeader_Location: "Lokasi",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Lihat",
	Dashboard_YourWishlist_TableHeader_Title: "Tajuk",
	Dashboard_YourWishlist_TableHeader_Action: "Tindakan",
	Dashboard_YourWishlist_ViewAllRecords: "Lihat semua rekod",
	Dashboard_TeamOverview_TableHeader_Employee: "Pekerja",
	Dashboard_TeamOverview_TableHeader_Title: "Tajuk",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Tarikh / Tempoh",
	Dashboard_TeamOverview_TableHeader_Location: "Lokasi",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Lihat",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Cari",
	Dashboard_ActiveProgram_TableHeader_Title: "Tajuk",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Lihat",
	Dashboard_CompletedProgram_TableHeader_Title: "Tajuk",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Lihat",
	Dashboard_Certificates_TableHeader_Title: "Tajuk",
	Dashboard_Certificates_TableHeader_Date: "Tarikh",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Tindakan",
	Dashboard_Certificates_ActionButton_Download: "Muat turun",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Nombor invois",
	Dashboard_Transactions_TableHeader_Description: "Penerangan",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Kaedah Pembayaran",
	Dashboard_Transactions_TableHeader_Date: "Tarikh",
	Dashboard_Transactions_TableHeader_Amount: "Jumlah",
	Dashboard_Transactions_ActionButton_Download: "Muat turun",
	Breadcrumb_MyTraining: "Latihan Saya",
	Breadcrumb_MyTraining_CourseDetails: "Butiran Kursus",
	Breadcrumb_MyTraining_Curricula: "Program Latihan",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Butiran Program Latihan",
	Breadcrumb_MyTraining_Team: "Pasukan",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Lihat Pekerja",
	MyTraining_Headerbar_CourseDetails: "Butiran Kursus",
	MyTraining_Headerbar_TrainingProgramDetails: "Butiran Program Latihan",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Kemajuan Keseluruhan",
	TrainingProgramDetails_ProgramItems_Headline: "Item Program",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Tajuk",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Gred",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Tarikh",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Lihat",
	TrainingProgramDetails_Status_InProgress: "Sedang Berlangsung",
	TrainingProgramDetails_Status_Completed: "Selesai",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Tempoh",
	CourseDetails_Label_Trainer: "Jurulatih",
	CourseDetails_Label_Language: "Bahasa",
	CourseDetails_Label_Score: "Markah:",
	CourseDetails_Label_StartDate: "Tarikh mula",
	CourseDetails_Label_EndDate: "Tarikh tamat",
	CourseDetails_Label_Venue: "Tempat",
	CourseDetails_Label_Address: "Alamat",
	CourseDetails_Label_Grade: "Gred:",
	CourseDetails_Action_Withdraw: "tarik diri",
	CourseDetails_Action_Evaluate: "Menilai kursus",
	CourseDetails_Action_Evaluate_Description: "Sila nilai kursus ini untuk menjana sijil anda.",
	CourseDetails_Action_DownloadCertificate: "Muat turun sijil",
	CourseDetails_Action_JoinWebcast: "Sertai Webcast",
	CourseDetails_Action_StartCourse: "Mulakan Kursus",
	CourseDetails_Action_RestartCourse: "Mulakan semula Kursus",
	CourseDetails_Action_RepeatCourse_Description: "“Sijil tidak boleh dimuat turun kerana markah lulus tidak tercapai.Sila ulangi dan lulus kursus ini untuk memuat turun sijil anda.",
	CourseDetails_PartSpecificDetails_Headline: "Butiran Khusus Bahagian",
	CourseDetails_PartSpecificDetails_Tab_Part: "Bahagian",
	CourseDetails_PartSpecificDetails_Description: "Penerangan",
	CourseDetails_PartSpecificDetails_StartDate: "Tarikh mula",
	CourseDetails_PartSpecificDetails_EndDate: "Tarikh tamat",
	CourseDetails_PartSpecificDetails_StartTime: "Masa mula",
	CourseDetails_PartSpecificDetails_EndTime: "Masa tamat",
	CourseDetails_PartSpecificDetails_Method: "Kaedah",
	Dashboard_Team_TableHeader_Name: "Nama",
	Dashboard_Team_TableHeader_Role: "Peranan",
	Dashboard_Team_TableHeader_Email: "E-mel",
	Dashboard_Team_TableHeader_PersonalAccount: "Akaun peribadi",
	Dashboard_Team_ActionButton_Select: "Pilih",
	Dashboard_Team_PersonalAccount_Activated: "Aktif",
	Dashboard_Team_PersonalAccount_NotActivated: "Tidak aktif",
	Dashboard_Team_ActionButton_AddMember: "Tambah Ahli Pasukan",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Cari",
	Dashboard_External_TableHeader_Name: "Nama",
	Dashboard_External_ActionButton_AddNew: "Tambah Penjual Semula",
	Dashboard_External_Headline: "Senarai Pemborong yang Diluluskan",
	Dashboard_Workshops_Headline: "Bengkel",
	Dashboard_Workshops_TableHeader_Company: "Syarikat",
	Dashboard_Workshops_TableHeader_Address: "Alamat",
	Dashboard_Workshops_TableHeader_Postcode: "Poskod",
	Dashboard_Workshops_TableHeader_City: "Bandar",
	Dashboard_Workshops_ActionButton_Select: "Pilih",
	Wallet_ServiceCredits: "Kredit Latihan",
	Wallet_Headline_CreditBalance: "Baki Kredit Latihan",
	Wallet_Headline_CreditActivity: "Aktiviti Kredit Latihan",
	Wallet_Headline_BillingTransactions: "Transaksi Pengebilan",
	Wallet_BillingTransaction_TableHeader_Order: "Pesanan",
	Wallet_BillingTransaction_TableHeader_Date: "Tarikh",
	Wallet_BillingTransaction_TableHeader_Amount: "Jumlah",
	Wallet_BillingTransaction_TableHeader_Action: "Tindakan",
	Wallet_CreditActivity_TableHeader_Date: "Tarikh",
	Wallet_CreditActivity_TableHeader_Description: "Penerangan",
	Wallet_CreditActivity_TableHeader_User: "pengguna",
	Wallet_CreditActivity_TableHeader_Amount: "Jumlah",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, semua hak terpelihara",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Keselamatan Produk (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovasi, Paten dan Lesen",
	FooterAdditionalLinks_Navigation_Logistics: "Pembelian dan Logistik",
	MenuHeader_MyTraining: "Latihan Saya",
	MenuHeader_TrainingServices: "Katalog Latihan & Kalendar",
	MenuHeader_OurLocations: "Lokasi Kami",
	MenuHeader_ContactsUs: "Hubungi Kami",
	MenuHeader_Logout: "Log keluar",
	MenuHeader_FAQ: "Soalan Lazim",
	MyProfile_MyBoschId: "ID Bosch saya",
	MyProfile_AccountDeletion: "Pemadaman Akaun",
	MyProfile_Edit: "Tukar e-mel atau kata laluan",
	MyProfile_Profile: "Akaun Bosch saya",
	MyProfile_EditUserProfile: "Edit Profil Pengguna",
	BackButton: "belakang",
	ContactUs_Email_DataProtection1_kr: "T/A",
	ContactUs_Email_DataProtection2_kr: "T/A",
	ContactUs_Email_DataProtection3_kr: "T/A",
	ContactUs_Email_DataProtection4_kr: "T/A",
	ContactUs_Email_DataProtection5_kr: "T/A",
	ContactUs_Email_DataProtection6_kr: "T/A",
	ContactUs_Email_DataProtection7_kr: "T/A",
	ContactUs_Email_DataProtection_kr: "T/A",
	ContactUs_Phone_Description_V3: "Kami menantikan panggilan anda dan berbesar hati untuk membantu anda.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Kami menantikan panggilan anda dan berbesar hati untuk membantu anda.",
	ContactUs_Email_MandatoryNotice_V3: "Masukkan aksara yang betul, seperti yang ditunjukkan dalam kotak di atas.",
	ContactUs_Email_SendButton: "Sila isi semua medan yang diperlukan.",
	Breadcrumb_TrainingCenters: "Hantar",
	Training_Centres_Book_Btn: "Pusat Latihan",
	Training_centres_booking_code: "Latihan Buku",
	Training_centers_header_bar_title: "Kod Tempahan",
	Training_centers_filter_item: "Pusat-pusat",
	TeamEdit_PersonalAccountToolTop: "Mendayakan ciri ini membolehkan pekerja membuat akaun peribadi dan menjadi sebahagian daripada akaun perniagaan anda. Setelah didayakan, anda boleh menghantar jemputan e-mel dengan kod pengaktifan kepada pekerja. Pekerja perlu memasukkan kod pengaktifan apabila mereka mendaftar untuk dipautkan ke akaun perniagaan anda",
	TeamEdit_Update: "Mendayakan ciri pentadbir syarikat menyediakan pekerja ini akses penuh ke akaun perniagaan anda. Mendayakan ciri ini memerlukan pengguna log keluar dan kembali ke tapak web, jika pengguna sudah log masuk.",
	TeamEdit_PersonalAccount: "Benarkan akaun web Penyelesaian Latihan Bosch peribadi",
	TeamEdit_SendInvitation: "Menghantar jemputan",
	TeamEdit_Deactivate: "Nyahaktifkan",
	TeamEdit_Telephone: "telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Mudah alih mesti mengandungi nombor sahaja",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telefon mesti mengandungi nombor sahaja",
	TeamEdit_Activate: "Aktif",
	TeamEdit_NotActivated: "Tidak aktif",
	TeamEdit_StatusPopup: "Status akan ditunjukkan sebagai 'Aktif' jika pengguna telah berjaya mencipta akaun peribadi. Jika status ditetapkan sebagai 'Tidak Aktif', ini bermakna pengguna tidak mempunyai akaun - anda boleh menghantar jemputan kepada mereka untuk melengkapkannya jika anda belum mempunyai akaun",
	Training_Catalog_Courses: "Kursus",
	Training_Catalog_Course: "Kursus",
	Reset_Filter: "Tetapkan Semula Penapis",
	TrainingCatalogue_CourseCatalogue: "Katalog Kursus Latihan",
	CheckoutHeader_CartItemCount_Text: "Anda mempunyai [count] item dalam troli beli-belah anda",
	CheckoutHeader_CartItemsCount_Text: "Anda mempunyai [count] item dalam troli beli-belah anda",
	ShoppingCart_PriceItem: "Harga Barang",
	ShoppingCartItemParticipants_AddParticipant_Button: "Tambah Peserta",
	ShoppingCartTotalPrice_SubTotalLabel: "jumlah kecil",
	ShoppingCartTotalPrice_TaxesLabel: "Cukai",
	ShoppingCartTotalPrice_TotalLabel: "Jumlah",
	ShoppingCartTotalPrice_IncludedVATLabel: "Termasuk SST sebanyak [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Kaedah pembayaran dalam talian yang tersedia",
	ShoppingCartItemRow_VenueFeeLabel: "Yuran tempat [fee_amount] setiap peserta",
	ShoppingCartItemRow_OnlineLabel: "dalam talian",
	AddParticipantsModal_Title: "Tambah Peserta",
	AddParticipantsModal_SelectEmployeeLabel: "Tambah Peserta",
	AddParticipantsModal_SelectWorkshopLabel: "Pilih Bengkel",
	ShoppingCartTotalPrice_ContinueButtonText: "Teruskan ke Checkout",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Peserta belum ditambah!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Anda mempunyai satu atau lebih kursus dalam troli beli-belah anda tanpa peserta.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Sila tambahkan peserta untuk meneruskan ke Checkout.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Edit peserta ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nama",
	AddParticipantsModal_ParticipantTable_Email: "E-mel",
	AddParticipantsModal_ParticipantTable_Action: "Tindakan",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Tiada peserta…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Alamat e-mel tidak dikonfigurasikan pada peserta yang dipilih",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Peserta yang dipilih tidak mempunyai alamat e-mel yang dikonfigurasikan pada profil mereka. Alamat e-mel adalah wajib untuk mendaftar ke kursus latihan.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Klik 'Tambah E-mel' untuk meneruskan atau 'Batal' untuk memilih peserta lain.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Tambah E-mel",
	CancelButton: "Batal",
	AddParticipantsModal_AddEmployeeButtonText: "Tambah Pekerja",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Anda akan memadamkan kursus daripada troli anda",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Anda cuba mengalih keluar kursus dengan peserta tambahan daripada troli anda.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Klik \"Teruskan\" jika anda ingin meneruskan atau klik \"Batal\".",
	ShoppingCartItemHeader_Participants: "peserta",
	ShoppingCartItemHeader_ParticipantPrice: "Harga setiap tempat duduk",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Tunjukkan dalam [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Tunjukkan dalam Kredit",
	ShoppingCart_CreditsPriceUnit: "Kredit",
	CheckOutHeader_Step1_Question: "bagaimana awak ingin membayarnya?",
	CheckOutHeader_Step2_Question: "Semak pesanan anda",
	CheckOutHeader_Step3_Question: "Lengkapkan pesanan anda",
	CheckOutHeader_Step4_Question: "Tempahan anda berjaya!",
	CheckOutHeader_Step_Payment: "Bayaran",
	CheckOutHeader_Step_Review: "Semakan",
	CheckOutHeader_Step_Place_Order: "Letakkan Pesanan",
	CheckOutRightPanel_Review_Order_Btn: "Semak Pesanan",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Letakkan Pesanan",
	CheckoutRightPanelContent_OrderSummary_Title: "Ringkasan Pesanan",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Edit troli beli-belah",
	ShoppingCart_Participants: "peserta",
	CheckOutStepPayment_Payment_Methods_Title: "Pilih kaedah pembayaran",
	CheckOutStepPayment_Credit_Title: "Kredit atau Debit",
	CheckOutStepPayment_Wholesaler_Title: "Pemborong",
	CheckOutStepPayment_ServiceCredit_Title: "Kredit Latihan",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Anda mempunyai Kredit Latihan [available_credit].",
	CheckOutStepPayment_BoschAccount_Title: "Akaun Bosch",
	Checkout_Second_PaymentMethod_Headline: "Kaedah pembayaran",
	CheckOutStepReview_Edit: "Sunting",
	CheckOutStepReview_Terms_Label: "Terma & Syarat dan Polisi Pembatalan",
	CheckOutStepReview_Terms_Text: "Saya telah membaca, memahami dan bersetuju menerima [terma, syarat dan polisi pembatalan] jualan.",
	SelectSecondPaymentMethodModal_Title: "Pilih kaedah pembayaran kedua",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Bayar [jumlah]",
	StepPaymentWholesaler_Title: "Pilih Penjual Semula",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Terima Terma & Syarat",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Sila baca dan terima Terma & Syarat dan Polisi Pembatalan kami sebelum meneruskan.",
	CheckoutDescription_ThankYou: "Terima kasih kerana menghantar pesanan anda pada Penyelesaian Latihan Automotif Bosch!",
	CheckoutDescription_Message: "Bit dan bait kami telah mula memproses pesanan anda. Sementara itu, sila pastikan enjin anda melahu.",
	CheckoutDesctiption_Text: "Kami akan menghantar pengesahan terakhir kepada anda melalui e-mel termasuk semua butiran tempahan anda.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Anda akan mengalih keluar bengkel yang dipilih",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Dengan mengalih keluar bengkel yang dipilih, semua peserta yang ditambah akan dialih keluar.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klik \"Teruskan\" jika anda ingin meneruskan atau klik \"Batal\" untuk menghentikan tindakan ini.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Anda mempunyai Kredit yang tidak mencukupi. Baki Kredit anda ialah Kredit [available_credit], tetapi anda memerlukan Kredit [cart_total_credits] untuk meneruskan ke Pembayaran. Sila pilih kaedah pembayaran lain atau alih keluar item daripada troli beli-belah anda.",
	Training_centers_filter_items: "Pusat-pusat",
	Training_Programs_Filter_Item: "Program",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "tahun",
	Training_Program_Detail_Title: "Program Kursus Latihan",
	AccountButton_Workshops_MenuItem: "Bengkel",
	AccountButton_Wallet_MenuItem: "Dompet",
	Header_MyBoschProfile_Header: "Profil MyBosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Tersedia",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Tambah/Edit peserta",
	ShoppingCart_CreditPriceUnit: "Kredit",
	Common_IncludedVAT: "termasuk SST",
	TrainingCatalogue_Length: "Panjang",
	TrainingCatalogue_Method: "Kaedah",
	Course_Price_Credits: "Kredit",
	Catalogue_Grid_Register_Button: "Daftar",
	Catalogue_Credit_Tilte: "Klik disini untuk melihat harga dalam Kredit",
	Catalogue_Price_Tilte: "Klik disini untuk melihat harga dalam Euro",
	Catalogue_Grid_Vat: "SST tidak termasuk",
	EventCalendar_Seats: "Bilangan",
	MenuHeader_Calendar: "Kalendar",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "minit",
	ListFilter_NoResult: "Tiada keputusan",
	Course_Description: "Penerangan Kursus",
	Training_course_detail: "Asas Sosial Media",
	Training_course_detail_add_waiting: "Tambahan ke senarai menunggu umum",
	Register_now_btn: "Daftar sekarang !",
	Register_now_btn_add_event_to_cart: "Daftar sekarang !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Lihat semua rekod",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Lihat kurang",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Lihat semua rekod",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Lihat kurang",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Lihat semua rekod",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Lihat kurang",
	TeamCreate_TitleError: "Tajuk diperlukan",
	Dashboard_Headerbar_BookCourse: "Daftar Kursus",
	Dashboard_Headerbar_ExitProxy: "Keluar dari Proksi",
	Dashboard_Headerbar_EvaluateCourse: "Menilai kursus",
	Dashboard_Headerbar_EvaluateCourse_Note: "Sila nilai kursus ini untuk menjana sijil anda!",
	Dashboard_Headerbar_DownloadCertificate: "Muat turun Sijil",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "tarik diri",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "tarik diri",
	Dashboard_Headerbar_WelcomeMessage: "Selamat datang ke papan pemuka pembelajaran anda",
	Dashboard_Headerbar_ProxingAs: "Memproksi sebagai",
	Dashboard_TrainingProgram_Status_Completed: "Selesai",
	Dashboard_TrainingProgram_Status_InProgress: "Sedang berlangsung",
	External_SelectWholesalerModal_Title: "Tambahkan akses pengguna luar",
	External_SelectWholesalerModal_Company_Label: "Syarikat",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Sila pilih",
	External_SelectWholesalerModal_Outlet_Placeholder: "Sila pilih",
	External_SelectWholesalerModal_ApplyButton: "Mohon",
	External_SelectWholesalerModal_CancelButton: "Batal",
	External_RemoveWholesalerModal_ConfirmLabel: "Pengesahan",
	External_SelectWholesalerModal_ConfirmMessage: "Adakah anda pasti mahu mengalih keluar pemborong ini?",
	External_SelectWholesalerModal_YesButton: "ya",
	External_SelectWholesalerModal_NoButton: "Tidak",
	Training_program_team_member: "Ahli pasukan",
	Training_program_assign_training_program: "Pencalonan program latihan",
	Assign_modal_student_confirmation_text1: "Anda akan memberikan [Training Program Name] kepada pembelajaran anda.",
	Assign_modal_student_confirmation_text2: "Adakah anda ingin meneruskan?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mac",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "Mei",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Jul",
	MyFullCalendar_Aug: "Ogos",
	MyFullCalendar_Sep: "Sep",
	MyFullCalendar_Oct: "Okt",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dis",
	MenuHeader_CourseCatalog: "Katalog Kursus Latihan",
	MenuHeader_Event: "Kalendar latihan",
	MenuHeader_Program: "Program latihan",
	MenuHeader_Services: "Perkhidmatan latihan",
	MenuHeader_Log_out: "Log keluar",
	Filter_Location: "Lokasi",
	TeamEdit_UpgradeToCompanyAdministrator: "Tingkatkan kepada pentadbir Syarikat",
	TeamEdit_PersonalWebAccountStatusIs: "Status akaun web peribadi ialah",
	Header_CreateNewEmployee: "Tambah Pekerja Baharu",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Penuh",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Tiada tempat duduk yang tersedia pada acara yang dipilih.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Sila pilih acara lain atau alih keluar peserta yang telah ditambah untuk meneruskan.",
	Common_SuccessModal_Title: "tahniah!",
	TrainingProgramDetails_Merit_StatusText: "Merit",
	TrainingProgramDetails_Resit_StatusText: "Duduk semula",
	EmployeeProfile_UserExistErrorMessage: "Akaun sudah sedia ada!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Pembayaran gagal. Sila cuba lagi atau gunakan kaedah pembayaran lain.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Anda telah berjaya menyahaktifkan pekerja yang dipilih!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Disimpan!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Perubahan anda telah disimpan.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Penarikan diri daripada kursus ePembelajaran tidak boleh dilakukan. Sila hubungi kami untuk butiran lanjut.",
	Dashboard_Team_PersonalAccount_Pending: "Yang belum selesai",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Pekerja berjaya direkod!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Pekerja baharu anda telah berjaya direkodkan.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Tetapan ini membolehkan anda menyekat pekerja daripada mendaftar secara terus ke acara latihan. Hanya pentadbir syarikat akan dapat mendaftarkan pekerja ke acara latihan apabila tetapan ini dinyahpilih.",
	Training_catalog_sort_by: "Disusun mengikut",
	CourseDetail_EvaluationRestriction_Title: "Penilaian latihan tidak mungkin!",
	CourseDetail_EvaluationRestriction_Message: "Penilaian latihan hanya boleh diserahkan oleh pelajar itu sendiri. Sila arahkan pelajar untuk log masuk ke Bosch Automotive Training Solutions dan melengkapkan penilaian latihan.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Jika pelajar belum mempunyai akaun, jemputan boleh dikongsi dalam tetapan profil pengguna ahli pasukan.",
	Breadcrumb_Download_Page: "Muat turun",
	Download_Table_Category_Cell: "kategori",
	Download_Table_Title_Cell: "Tajuk",
	Download_File_Filter_Item: "Fail",
	Download_File_Filter_Items: "Fail",
	Download_Header: "Kawasan Muat Turun",
	DownloadArea_Button_Download: "Muat turun",
	Popup_Title_Error: "ralat",
	Popup_Title_Success: "Kejayaan",
	Popup_Title_Info: "info",
	Popup_Title_Warning: "Amaran!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Pendaftaran acara berikut sudah wujud dan tidak akan dibatalkan secara automatik dengan menarik diri daripada Program Latihan. Sila batalkan ini secara individu.",
	TrainingProgram_PDF_Export: "Eksport PDF",
	TrainingProgram_PDF_Export_Participant: "Pesakit:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Kemajuan Keseluruhan:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Tajuk Latihan",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Gred",
	TrainingProgram_PDF_Export_TableHeader_Date: "Tarikh",
	AccountButton_CompanyChange_MenuItem: "Perubahan Syarikat",
	SelectCompanyModal_Title: "Pilih Syarikat",
	CloseButton: "tutup",
	Change_Button: "Ubah",
	ChangeManagerModal_Content: "Pilih Pengguna",
	ChangeCompanyModal_HeadOffice: "(Ibu Pejabat)",
	SelectCompanyModal_Content: "Sila pilih Syarikat untuk log masuk…",
	AlertSuccess_Title: "Kejayaan",
	CheckOutStepPayment_SecondaryPayment_Title: "Pilih kaedah pembayaran kedua",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Tambahkan kaedah pembayaran kedua",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Sila tambah kaedah pembayaran kedua di bawah atau pilih kaedah pembayaran lain di atas.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Anda tidak mempunyai Kredit Perkhidmatan yang mencukupi dalam akaun anda untuk membayar item troli anda. Untuk terus menggunakan kaedah pembayaran yang dipilih, anda mesti sama ada mendapatkan lebih banyak Kredit Perkhidmatan atau menggunakan kaedah pembayaran tambahan untuk membayar perbezaannya. Perbezaannya ialah [difference_amount] (tidak termasuk gst).",
	ShoppingCartTotalPrice_TotalInCredit: "Jumlah harga dalam kredit",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Kredit anda yang tersedia",
	ShoppingCartTotalPrice_YourDifference: "Beza",
	ShoppingCartTotalPrice_SubtotalExTax: "Jumlah kecil tidak termasuk gst",
	ShoppingCartTotalPrice_SubtotalInTax: "Jumlah kecil termasuk. gst",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Kaedah pembayaran sekunder",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Edit kaedah pembayaran",
	Checkout_Second_PaymentMethod: "Kaedah Pembayaran Kedua",
	Breadcrumb_TrainingCourseDetails: "Butiran kursus latihan",
	DownloadArea_FailedToDownload_ErrorMessage: "Muat turun dokumen telah gagal. Sila cuba lagi atau hubungi Pentadbir Syarikat anda untuk mendapatkan bantuan.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Muat turun fail tidak tersedia. Sila cuba lagi atau hubungi Pentadbir Syarikat anda untuk mendapatkan bantuan.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Muat turun fail adalah dilarang. Sila cuba lagi atau hubungi Pentadbir Syarikat anda untuk mendapatkan bantuan.",
	SSO_Redirecting_Message: "Anda sedang mengubah hala ke portal BATS...",
	SSO_Redirecting_ParameterErrorMessage: "Negara dan/atau Bahasa tidak dinyatakan. Sila cuba lagi atau hubungi Pentadbir Syarikat anda untuk mendapatkan bantuan.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Permintaan pendaftaran tidak diterima.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Ini mungkin disebabkan oleh berada dalam tempoh hari ambang pendaftaran kursus untuk satu atau lebih kursus. Sila gunakan kawasan 'Hubungi kami' jika anda mempunyai sebarang pertanyaan atau permintaan.",
	TimeSlotPicker_DateFormat_Title: "Format tarikh",
	UpdateAccountSettingsConfirmationModal_Title: "Anda akan menukar format tarikh!",
	UpdateAccountSettingsConfirmationModal_Message1: "Format tarikh akan dipaparkan mengikut pilihan anda dan boleh ditukar pada bila-bila masa.",
	UpdateAccountSettingsConfirmationModal_Message2: "Adakah anda ingin meneruskan?",
	ProfileSummary_Settings_Title: "tetapan",
	UpdateAccountSettings_DateFormat_Title: "Tetapan format tarikh",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Ia gagal mengemas kini format Tarikh.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Tetapan format tarikh telah berjaya dikemas kini.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Muat turun Sijil",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Sijil Program Latihan",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Muat turun gagal. Sila cuba lagi. Jika masalah berterusan, sila hubungi kami melalui borang hubungan kami.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Ambang pengeluaran melebihi",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Ambang untuk menarik diri daripada kursus ini telah melebihi dan memerlukan pemprosesan manual oleh Pentadbiran Latihan kami.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Pengesahan akhir akan dihantar melalui e-mel.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Sila rujuk kepada [dasar pembatalan] kami untuk butiran lanjut.",
	TrainingCourse_Course_NotAvailable_Title: "Kursus latihan tidak tersedia",
	TrainingCourse_Course_NotAvailable_Message: "Kursus latihan tidak tersedia. Sila cuba lagi atau hubungi Pentadbir Syarikat anda untuk mendapatkan bantuan lanjut.",
	TrainingCourse_NotAvailable_Message_1: "Anda cuba melihat kursus latihan yang tidak tersedia.",
	TrainingCourse_NotAvailable_Message_2: "Sila kembali ke [katalog latihan] kami untuk mencari semua kursus yang tersedia.",
	LanguageSelection_SelectALanguage: "Pilih Bahasa",
	LanguageSelection_SelectYourCountry: "Pilih negara anda",
	Checkout_PaymentMethod_Headline: "Kaedah pembayaran",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalendar",
	Download_Categories_General: "Umum",
	Download_Categories_Information: "Maklumat",
	Download_Categories_Userguides: "Panduan pengguna",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Polisi Pembatalan",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Batal",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Teruskan",
	Duration_Seconds: "Saat",
	Duration_Day: "Hari",
	Duration_Hour: "Jam",
	Duration_Minute: "Minit",
	Duration_Second: "Kedua",
	EventCalendar_ExternalLink_Tooltips: "Pembelian melalui [Nama Pendaftaran Luaran]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Batal",
	EventCalendar_ExternalLink_Continue: "Teruskan",
	EventCalendar_ExternalLinkModal_Title: "Anda akan diarahkan semula...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Acara yang dipilih dijual melalui [Nama Pendaftaran luaran].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Meneruskan akan mengarahkan anda ke laman web luaran.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Adakah anda ingin meneruskan?",
	Team_ContactPerson_FirstName_Tooltips: "Nama pelajar hanya boleh diubah suai oleh pasukan pentadbiran latihan Bosch. Sila hubungi kami melalui borang hubungan di laman web ini untuk sokongan lanjut.",
	Team_ContactPerson_LastName_Tooltips: "Nama pelajar hanya boleh diubah suai oleh pasukan pentadbiran latihan Bosch. Sila hubungi kami melalui borang hubungan di laman web ini untuk sokongan lanjut.",
	Withdraw_ExternalLink_Cancel: "Batal",
	Withdraw_ExternalLink_Confirm: "Mengesahkan",
	Withdraw_ExternalLinkModal_Title: "Anda akan diarahkan semula",
	Withdraw_ExternalLinkModal_Message: "Pengeluaran untuk kursus ini dikendalikan oleh pembekal luaran.",
	Withdraw_ExternalLinkModal_Confirmation: "Mengklik \"Sahkan\" akan membuka tab baru dan mengarahkan anda ke laman web luaran.",
	Button_AddToWaitingList_Tooltips: "Tambah pada Senarai Menunggu",
	Button_AddToCart_Tooltips: "Tambah ke Troli",
	Button_TrainingCourseDetail_Tooltips: "Lihat Butiran",
	Button_TrainingProgramDetail_Tooltips: "Lihat Butiran",
	Button_AssignTrainingProgram_Tooltips: "Tugaskan program latihan",
	Button_DeleteFromCart_Tooltips: "Padam daripada Troli",
	Button_RemoveParticipant_Tooltips: "Alih keluar peserta",
	Button_DownloadBillingTransaction_Tooltips: "Muat turun",
	Button_RemoveExternal_Tooltips: "Mengalih keluar",
	Breadcrumb_TrainingServices: "Perkhidmatan Latihan",
	Breadcrumb_Support: "Sokongan",
	Breadcrumb_FAQs: "Soalan Lazim",
	Breadcrumb_TrainingProgramDetail: "Butiran Program Latihan",
	Breadcrumb_MyTraining_MyTrainingEvents: "Acara Latihan Saya",
	Breadcrumb_MyTraining_External: "Luaran",
	Breadcrumb_MyTraining_Wallet: "Dompet",
	Breadcrumb_MyTraining_Workshop: "Bengkel",
	Breadcrumb_MyTraining_Workshop_Team: "Pasukan",
	Breadcrumb_MyTraining_Team_Profile: "Profil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Edit Profil",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Penciptaan Pekerja",
	SideNavigation_Title: "Penyelesaian Latihan",
	SideNavigation_AccountButton_RegisterOrLogin: "Daftar / Log Masuk",
	SideNavigation_Language: "Bahasa",
	SideNavigation_SelectLanguage: "Pilih bahasa",
	SideNavigation_AccountButton_Logout: "Log keluar",
	SideNavigation_ConntactUs: "Hubungi kami",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Maklumat korporat",
	FooterAdditionalLinks_Navigation_LegalNotice: "Notis undang-undang",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Notis perlindungan data",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Tetapan privasi",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Terma & Syarat",
	Common_Version_Text: "Versi: {{versi}}",
	Content_Header_Dashboard_MyTrainingEvents: "Acara Latihan Saya",
	Content_Header_Dashboard_MyTrainingPrograms: "Program Latihan Saya",
	Content_Header_Dashboard_Team: "Pasukan",
	Content_Header_Dashboard_Workshops: "Bengkel",
	Content_Header_Dashboard_External: "Luaran",
	Content_Header_Dashboard_Wallet: "Dompet",
	Content_Header_Dashboard_MyProfile: "Profil Saya",
	RedeemVoucher_RemoveButton: "Alih keluar",
	RedeemVoucher_ApplyButton: "Mohon",
	Checkout_VoucherApplied_Label: "Kod anda",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Kod Baucar tidak sah",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Kod Baucar yang digunakan adalah tidak sah.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Sila cuba lagi atau teruskan tanpa kod Baucar.",
	RedeemVoucherModal_CancelButton: "Batal",
	RedeemVoucherModal_TryAgainButton: "Cuba lagi",
	CreditsConfirmationModal_ContinueButton: "teruskan",
	CreditsConfirmationModal_CancelButton: "Batal",
	CreditsConfirmationModal_Title: "Baucar telah digunakan",
	CreditsConfirmationModal_Message_1: "Pembayaran dengan Kredit Perkhidmatan tidak boleh dilakukan apabila baucar diskaun telah digunakan pada troli beli-belah anda.",
	CreditsConfirmationModal_Message_2: "Klik \"Batal\" untuk kembali atau klik \"Teruskan\" untuk mengalih keluar baucar daripada troli anda."
};