export const triggerSideNavigation = (mode: "open" | "close") => {
  const sideNavigation = document.querySelector(".m-side-navigation");

  if (!sideNavigation) return;

  const sideNavigationTriggerBtn = sideNavigation.querySelector(
    ".m-side-navigation__header__trigger"
  );

  if (!sideNavigationTriggerBtn) return;

  const isOpening = sideNavigation.classList.contains("-open");

  if (mode === "open" ? !isOpening : isOpening)
    (sideNavigationTriggerBtn as HTMLButtonElement).click();
};

export const triggerSelectedSideNavGroupItem = (
  path: string,
  isSelected: boolean
) => {
  const item = document.getElementById(path);
  const firstChild = item?.childNodes[0];

  if (!firstChild) return;

  const itemButton = firstChild as HTMLButtonElement;
  const selectedClassName = "-selected";

  if (isSelected) itemButton.classList.add(selectedClassName);
  else {
    if (!item.classList.contains("-open")) itemButton.click();

    itemButton.classList.remove(selectedClassName);
  }
};
