import { FC, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, HeaderProps, Icon, Input } from "semantic-ui-react";

import { ReactComponent as Search } from "../../assets/svg/search.svg";
import { ReactComponent as Delete } from "../../assets/svg/delete.svg";
import "./SearchField.css";

import Interweave from "interweave";
import { useTranslation } from "react-i18next";
import {
  selectCountryCode,
  selectLanguage,
  selectTimeZoneId,
} from "../../redux-toolkit/authReducer";
import { useSelector } from "react-redux";
import {
  selectCiamId,
  selectCustomerId,
} from "../../redux-toolkit/accountReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { TrainingCourse } from "../../models/TrainingEvent";
import { TrainingEventHelper } from "../../helpers/TrainingEventHelper";
import { performSearch } from "../../redux-toolkit/searchReducer";
import {
  selectSimpleTrainingCourses,
  getTrainingCoursesSimpleAsync,
} from "../../redux-toolkit/trainingCourseReducer";

const SearchField: FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isLoadingCourse, setIsLoadingCourse] = useState(false);
  const history = useHistory();
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const ciamId = useSelector(selectCiamId);
  const customerId = useSelector(selectCustomerId);
  const timeZoneId = useSelector(selectTimeZoneId);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  // const trainingCourses = useSelector(selectTrainingCourses);
  const simpleTrainingCourses = useSelector(selectSimpleTrainingCourses);
  const [trainingCourses, setTrainingCourses] = useState<TrainingCourse[]>([]);

  useEffect(() => {
    if (simpleTrainingCourses) {
      setTrainingCourses(simpleTrainingCourses);
    }
  }, [simpleTrainingCourses]);

  useEffect(() => {
    if (!!trainingCourses && trainingCourses?.length > 0) {
      const data = trainingCourses.map((x: TrainingCourse) => {
        return {
          //name: "",
          localizedName: x.localizedName,
          englishName: x.englishName,
          codeName: x.codeName,
          partNumber: x.partNumber,
          path: "/training-course-catalogue",
          //role: ["all"]
        };
      });
      setSearchData(data);
    } else if (searchKey !== null && searchKey !== "") {
      setIsLoadingCourse(true);

      /* dispatchReduxToolkit(
        getTrainingCoursesAsync({
          countryCode: countryCode,
          ciamId: ciamId,
          customerId: customerId,
          language: language,
          timeZoneId: timeZoneId,
        })
      )
        .then((response: any) => {})
        .finally(() => {
          setIsLoadingCourse(false);
        }); */

      dispatchReduxToolkit(
        getTrainingCoursesSimpleAsync({
          country: countryCode,
          ciamId: ciamId ?? "",
          customerId: customerId,
          language: language,
          timeZoneId: timeZoneId,
          salesRegion: "",
        })
      )
        .then((response: any) => {})
        .finally(() => {
          setIsLoadingCourse(false);
        });
    }
  }, [searchKey, trainingCourses]);

  useEffect(() => {
    getSearchResults();
  }, [searchKey, searchData, isLoadingCourse]);

  const handleSearchValueChange = (event: any) => {
    search(event.target.value);
  };

  const search = (text: string) => {
    setSearchKey(text);
    // getSearchResults(text);
  };

  const handleSearch = () => {
    dispatchReduxToolkit(performSearch(searchKey));
    history.push("/training-course-catalogue");

    closeSearch();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      dispatchReduxToolkit(performSearch(searchKey));
      history.push("/training-course-catalogue");

      closeSearch();
    }
  };

  const getFormattedResult = (key: string, result: string) => {
    const reg = new RegExp(key, "gi");

    const formattedResult = result.replace(reg, (str) => {
      return `<b>${str}</b>`;
    });

    return formattedResult;
  };

  const getSearchResults = () => {
    const key = searchKey;
    let filteredResults = searchData.map((element: any, index: number) => {
      if (
        key !== "" &&
        ((element.localizedName
          ? element.localizedName.toLowerCase().includes(key.toLowerCase())
          : false) ||
          (element.englishName
            ? element.englishName.toLowerCase().includes(key.toLowerCase())
            : false) ||
          (element.codeName
            ? element.codeName.toLowerCase().includes(key.toLowerCase())
            : false) ||
          (element.partNumber
            ? element.partNumber.toLowerCase().includes(key.toLowerCase())
            : false))
      ) {
        return (
          <div className="search-suggestion-container" key={index}>
            <div
              className="search-suggestion"
              onClick={() => {
                goToPage(
                  element,
                  TrainingEventHelper.getTrainingNameFromCourse(
                    element,
                    language
                  )
                );
              }}
            >
              <Interweave
                content={getFormattedResult(
                  key,
                  TrainingEventHelper.getTrainingNameFromCourse(
                    element,
                    language
                  )
                )}
              />
            </div>
          </div>
        );
      }

      // If there is no match, add NULL element
      return null;
    });

    // Remove NULL results
    filteredResults = filteredResults.filter((element: any) => {
      return element != null;
    });

    // Get maximum 3 results
    filteredResults = filteredResults.slice(0, 5);

    if (filteredResults.length > 0) {
      filteredResults.push(
        <div
          className="all-results-suggestion"
          onClick={() => {
            handleSearch();
            closeSearch();
          }}
        >
          {t("SearchField_AllResults")} {">"}
        </div>
      );
    } else if (!isLoadingCourse) {
      filteredResults.push(
        <div className="no-results">{t("SearchField_NoRecordsFound")}</div>
      );
    }

    setSearchResults(filteredResults);
  };

  const goToPage = (routeElement: any, searchText: string) => {
    //window.location.href = routeElement.path;
    dispatchReduxToolkit(performSearch(searchText));
    history.push("/training-course-catalogue");

    closeSearch();
  };

  const closeSearch = () => {
    setSearchKey("");

    if (props.emitCloseEvent) {
      props.closeSearch();
    }
  };

  return (
    <Fragment>
      <div
        className={
          props.footerMode
            ? "footer-input-container grid-responsive-width"
            : "input-container grid-responsive-width"
        }
      >
        <Input
          placeholder={t("FooterSearch_SearchFieldPlaceholder")}
          onKeyPress={handleKeyPress}
          onChange={handleSearchValueChange}
          value={searchKey}
          className="search-input"
          loading={isLoadingCourse}
        />
        {!isLoadingCourse ? (
          <div className="search-field__buttons">
            <Button
              className="input-button search-field__clear-button"
              onClick={() => closeSearch()}
            >
              <Icon>
                <Delete />
              </Icon>
            </Button>
            <Button
              className="input-button search-field__search-button"
              onClick={handleSearch}
            >
              <Icon className="search-icon">
                <Search />
              </Icon>
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>

      {searchKey !== "" && props.showResults && (
        <div className="search-result-background">
          <div className="search-result-container">{searchResults}</div>
        </div>
      )}
    </Fragment>
  );
};

export default SearchField;
