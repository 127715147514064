import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShoppingCart } from "../models/CartItem.Model";
import { ContactPersonModel } from "../models/ContactPerson.Model";
import { GetPaymentMethodsResponse } from "../models/ShoppingCartUser.Model";
import { getContactPersonsAsync } from "./contactReducer";
import {
  getDisplayCartAsync,
  getPaymentMethodsAsync,
} from "./shoppingCartUserReducer";
import { RootState } from "./store";

export interface ShoppingCartState {
  item: any;
  addedEventIds: number[];
  currentRegisteredEmployee: RegisteredEmployee;
  itemQuantity: number;
  totalNetPrice: number;
  shoppingCart: ShoppingCart | null;
  participants: ContactPersonModel[];
  paymentMethods: GetPaymentMethodsResponse[];
  isContinueCheckoutClicked: boolean;
  selectedWorkshopId: number | null;
  voucherCode: string | null;
  isAppliedVoucherCode: boolean;
  isShowRedeemVoucherModal: boolean;
}

export interface RegisteredEmployee {
  employeeId: number;
  workshopId: number;
}

const initialState: ShoppingCartState = {
  item: [],
  addedEventIds: [],
  currentRegisteredEmployee: { employeeId: 0, workshopId: 0 },
  itemQuantity: 0,
  totalNetPrice: 0,
  shoppingCart: null,
  participants: [],
  paymentMethods: [],
  isContinueCheckoutClicked: false,
  selectedWorkshopId: null,
  voucherCode: null,
  isAppliedVoucherCode: false,
  isShowRedeemVoucherModal: false,
};

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    showRedeemModal: (state) => {
      return {
        ...state,
        isShowRedeemVoucherModal: true,
      };
    },
    hideRedeemModal: (state) => {
      return {
        ...state,
        isShowRedeemVoucherModal: false,
      };
    },
    saveVoucherCode: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        voucherCode: action.payload,
        isAppliedVoucherCode: true,
      };
    },
    deleteVoucherCode: (state) => {
      return {
        ...state,
        voucherCode: null,
        isAppliedVoucherCode: false,
      };
    },
    addEventIdToCart: (state, action: PayloadAction<number>) => {
      if (action.payload != null) {
        const itemIndex = state.addedEventIds.findIndex((item: Number) => {
          return item === action.payload;
        });
        if (itemIndex < 0) {
          state.addedEventIds.push(action.payload);
        }
      }
    },
    removeEventIdFromCart: (state, action: PayloadAction<number>) => {
      if (action.payload != null) {
        const events = state.addedEventIds?.filter((x) => x !== action.payload);
        const cartItems = state.shoppingCart?.items?.filter(
          (x) => x.eventId !== action.payload
        );
        let shoppingCart: ShoppingCart | null = null;
        if (state.shoppingCart) {
          shoppingCart = {
            ...state.shoppingCart,
            items: cartItems || [],
          };
        }
        return {
          ...state,
          addedEventIds: events,
          shoppingCart: shoppingCart,
        };
      }
      return {
        ...state,
      };
    },
    clearEventIdFromCart: (state) => {
      state.addedEventIds = [];
    },
    changeItemQuantity: (state, action: PayloadAction<any>) => {
      const { id, participants } = action.payload;
      const itemIndex = state.item.findIndex((item: any) => {
        return item.id === id;
      });
      if (itemIndex !== -1) {
        const currentStateItem = state.item[itemIndex];

        if (currentStateItem.participants?.length < participants.length) {
          const totalPrice =
            currentStateItem.exactPrice +
            (currentStateItem.venueFeePerItem > 0
              ? currentStateItem.venueFeePerItem
              : 0);
          state.totalNetPrice += totalPrice;
        }

        if (currentStateItem.participants?.length > participants.length) {
          if (state.totalNetPrice !== 0) {
            const priceToSubstract =
              currentStateItem.exactPrice +
              (currentStateItem.venueFeePerItem > 0
                ? currentStateItem.venueFeePerItem
                : 0);
            state.totalNetPrice -= priceToSubstract;
          }
        }
        state.item[itemIndex].participants = participants;
      }
    },
    addRegisterdEmployee: (
      state,
      action: PayloadAction<RegisteredEmployee>
    ) => {
      if (action.payload != null) {
        state.currentRegisteredEmployee = action.payload;
      }
    },
    setIsContinueCheckoutClicked: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isContinueCheckoutClicked: action.payload,
      };
    },
    updateSelectedWorkshop: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        selectedWorkshopId: action.payload,
      };
    },
    updateAddedEventIds: (state, action: PayloadAction<number[]>) => {
      return {
        ...state,
        addedEventIds: action.payload,
      };
    },
    clearParticipants: (state) => {
      return {
        ...state,
        participants: [],
      };
    },
    resetShoppingCartState: (state) => {
      return {
        ...initialState,
      };
    },
    clearRegisteredEmployee: (state) => {
      state.currentRegisteredEmployee = { employeeId: 0, workshopId: 0 };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDisplayCartAsync.fulfilled, (state, { payload }) => {
        if (!!payload?.dataObject) {
          return {
            ...state,
            shoppingCart: payload.dataObject,
          };
        }

        return {
          ...state,
          shoppingCart: null,
        };
      })
      .addCase(getContactPersonsAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.success && payload.dataObject) {
          return {
            ...state,
            participants: payload.dataObject,
          };
        }
        return {
          ...state,
          participants: [],
        };
      })
      .addCase(getPaymentMethodsAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.success && payload.dataObject) {
          return {
            ...state,
            paymentMethods: payload.dataObject,
          };
        }
        return {
          ...state,
          paymentMethods: [],
        };
      });
  },
});

export const {
  saveVoucherCode,
  deleteVoucherCode,
  showRedeemModal,
  hideRedeemModal,
  addEventIdToCart,
  removeEventIdFromCart,
  clearEventIdFromCart,
  changeItemQuantity,
  addRegisterdEmployee,
  setIsContinueCheckoutClicked,
  updateSelectedWorkshop,
  clearRegisteredEmployee,
  updateAddedEventIds,
  clearParticipants,
  resetShoppingCartState,
} = shoppingCartSlice.actions;

export const selectShoppingCart = (state: RootState) => state.shoppingCartItem;
export const selectItem = (state: RootState) => state.shoppingCartItem.item;
export const selectItemQuantity = (state: RootState) =>
  state.shoppingCartItem.itemQuantity;
export const selectTotalNetPrice = (state: RootState) =>
  state.shoppingCartItem.totalNetPrice;
export const selectAddedEventIds = (state: RootState) =>
  state.shoppingCartItem.addedEventIds;
export const selectCurrentRegisteredEmployee = (state: RootState) =>
  state.shoppingCartItem.currentRegisteredEmployee;
export const selectParticipants = (state: RootState) =>
  state.shoppingCartItem.participants;
export const selectPaymentMethods = (state: RootState) =>
  state.shoppingCartItem.paymentMethods;
export const selectIsContinueCheckoutClicked = (state: RootState) =>
  state.shoppingCartItem.isContinueCheckoutClicked;
export const selectShoppingCartModel = (state: RootState) =>
  state.shoppingCartItem.shoppingCart;
export const selectSelectedWorkshopId = (state: RootState) =>
  state.shoppingCartItem.selectedWorkshopId;

export const selectVoucherCode = (state: RootState) =>
  state.shoppingCartItem.voucherCode;

export const selectIsShowRedeemVoucherModal = (state: RootState) =>
  state.shoppingCartItem.isShowRedeemVoucherModal;

export const selectIsAppliedVoucherCode = (state: RootState) =>
  state.shoppingCartItem.isAppliedVoucherCode;

export default shoppingCartSlice.reducer;
