import { RouteConfigure } from "../models/Route";
import {
  HOME_BATS_V4_ROUTE,
  TRAINING_SERVICES_BATS_V4_ROUTE,
  TRAINING_COURSE_CATALOGUE_BATS_V4_ROUTE,
  COURSE_DETAIL_BATS_V4_ROUTE,
  TRAINING_COURSE_DETAIL_BATS_V4_ROUTE,
  EVENT_CALENDAR_BATS_V4_ROUTE,
  TRAINING_PROGRAM_BATS_V4_ROUTE,
  TRAINING_PROGRAM_DETAIL_BATS_V4_ROUTE,
  TRAINING_CENTERS_BATS_V4_ROUTE,
  RECOMMEND_HOTELS_BATS_V4_ROUTE,
  CONTACT_US_BATS_V4_ROUTE,
  STORIES_BATS_V4_ROUTE,
  STORIES_BOSCH_SERVICE_TRAINING_BATS_V4_ROUTE,
  STORIES_A_DAY_IN_LIFE_BATS_V4_ROUTE,
  STORIES_TRAINING_BENEFIT_BATS_V4_ROUTE,
  STORIES_MODERN_TRAINING_APPROACH_BATS_V4_ROUTE,
  SSO_BATS_V4_ROUTE,
  SUPPORT_BATS_V4_ROUTE,
  FAQ_BATS_V4_ROUTE,
  DOWNLOAD_AREA_BATS_V4_ROUTE,
  MY_PROFILE_BATS_V4_ROUTE,
  JOIN_COMPANY_BATS_V4_ROUTE,
  MY_SHOPPING_CART_BATS_V4_ROUTE,
  CHECK_OUT_BATS_V4_ROUTE,
  DASHBOARD_BATS_V4_ROUTE,
  DASHBOARD_CURRICULA_BATS_V4_ROUTE,
  DASHBOARD_TEAM_BATS_V4_ROUTE,
  DASHBOARD_TEAM_PROFILE_BATS_V4_ROUTE,
  DASHBOARD_EXTERNAL_BATS_V4_ROUTE,
  DASHBOARD_WORKSHOP_BATS_V4_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_BATS_V4_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_ADD_MEMBER_BATS_V4_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_EDIT_PROFILE_BATS_V4_ROUTE,
  DASHBOARD_WALLET_BATS_V4_ROUTE,
  MY_TRAINING_COURSE_DETAIL_BATS_V4_ROUTE,
  MY_TRAINING_PROGRAM_DETAIL_BATS_V4_ROUTE,
  DASHBOARD_MY_TRAINING_EVENTS_BATS_V4_ROUTE,
  DASHBOARD_TEAM_CREATE_BATS_V4_ROUTE,
} from "../constants/routes/batsv4Routes.constants";

export const BATS_V4_ROUTERS_CONFIG: RouteConfigure[] = [
  HOME_BATS_V4_ROUTE,
  TRAINING_SERVICES_BATS_V4_ROUTE,
  TRAINING_COURSE_CATALOGUE_BATS_V4_ROUTE,
  COURSE_DETAIL_BATS_V4_ROUTE,
  TRAINING_COURSE_DETAIL_BATS_V4_ROUTE,
  EVENT_CALENDAR_BATS_V4_ROUTE,
  TRAINING_PROGRAM_BATS_V4_ROUTE,
  TRAINING_PROGRAM_DETAIL_BATS_V4_ROUTE,
  TRAINING_CENTERS_BATS_V4_ROUTE,
  RECOMMEND_HOTELS_BATS_V4_ROUTE,
  CONTACT_US_BATS_V4_ROUTE,
  STORIES_BATS_V4_ROUTE,
  STORIES_BOSCH_SERVICE_TRAINING_BATS_V4_ROUTE,
  STORIES_A_DAY_IN_LIFE_BATS_V4_ROUTE,
  STORIES_TRAINING_BENEFIT_BATS_V4_ROUTE,
  STORIES_MODERN_TRAINING_APPROACH_BATS_V4_ROUTE,
  SSO_BATS_V4_ROUTE,
  SUPPORT_BATS_V4_ROUTE,
  FAQ_BATS_V4_ROUTE,
  DOWNLOAD_AREA_BATS_V4_ROUTE,
  JOIN_COMPANY_BATS_V4_ROUTE,
  MY_PROFILE_BATS_V4_ROUTE,
  MY_SHOPPING_CART_BATS_V4_ROUTE,
  CHECK_OUT_BATS_V4_ROUTE,
  DASHBOARD_BATS_V4_ROUTE,
  DASHBOARD_MY_TRAINING_EVENTS_BATS_V4_ROUTE,
  DASHBOARD_CURRICULA_BATS_V4_ROUTE,
  DASHBOARD_TEAM_BATS_V4_ROUTE,
  DASHBOARD_TEAM_CREATE_BATS_V4_ROUTE,
  DASHBOARD_TEAM_PROFILE_BATS_V4_ROUTE,
  DASHBOARD_EXTERNAL_BATS_V4_ROUTE,
  DASHBOARD_WORKSHOP_BATS_V4_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_BATS_V4_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_ADD_MEMBER_BATS_V4_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_EDIT_PROFILE_BATS_V4_ROUTE,
  DASHBOARD_WALLET_BATS_V4_ROUTE,
  MY_TRAINING_COURSE_DETAIL_BATS_V4_ROUTE,
  MY_TRAINING_PROGRAM_DETAIL_BATS_V4_ROUTE,
];
