export const TRANSLATIONS_PL_PL = {
	LandingPage_Title: "Witamy w Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Wybierz swój region / kraj",
	Header_ContactUs: "Skontaktuj się z nami",
	Header_Search: "Szukaj",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Zaloguj się lub utwórz konto",
	Header_LogIn: "Zaloguj sie",
	Header_Cart: "Koszyk",
	Header_ShoppingCart: "Koszyk",
	Header_LogOut: "Wyloguj",
	Header_ChangeDetails: "Zmień szczegóły",
	Footer_ExploreOurTraining: "Poznaj nasze szkolenia",
	Footer_TrainingAtYourFingertips: "Szkolenia na wyciągnięcie ręki",
	Footer_DontMissAnyStory: "Nie przegap żadnej historii",
	Footer_ExploreOurStories: "Poznaj nasze historie",
	Footer_GetInTouchWithUs: "Skontaktuj się z nami",
	Footer_SendUsAMessage: "Wyślij nam wiadomość",
	Footer_ToContactForm: "Formularz kontaktowy",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "Ogólne dane kontaktowe",
	Footer_ProductSecurity_Psirt: "Bezpieczeństwo produktu (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innowacje patenty i licencje",
	Footer_PurchasingLogistics: "zakupy i logistyka",
	Footer_CorporateInformation: "Informacje o firmie",
	Footer_LegalNotice: "Nota prawna",
	Footer_DataProtectionNotice: "Informacja o ochronie danych",
	Footer_PrivacySettings: "Ustawienia prywatności",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch GmbH, wszelkie prawa zastrzeżone",
	Footer_StillLookingForSomething: "Szukam dalej",
	Footer_TrainingServices: "Szkolenia",
	Footer_Courses: "Szkolenia",
	Footer_Programs: "Nagrania",
	Footer_EventsCalendar: "kalendarz akcji",
	Footer_TrainingCenters: "Centra szkoleniowe",
	Footer_Dashboard: "Panel",
	Footer_MyDashboard: "moje konto",
	Footer_ShoppingCart: "Koszyk zakupów",
	Footer_YourShoppingCart: "Twój Koszyk",
	Footer_Administration: "Administracja",
	Footer_Employees: "Pracownicy",
	Footer_Wholesalers: "Hurtownie",
	Footer_Transactions: "Transakcje",
	Footer_Workshops: "Warsztaty",
	Footer_WorkLikeABoschApplyNow: "Pracuj  - Aplikuj teraz.",
	Footer_BoschGlobalCareers: "Kariera globalna Bosch",
	Footer_PurchasingAndLogistics: "Zakupów i Logistyki",
	Footer_BecomeABusinessPartner: "Zostań partnerem biznesowym.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Jak nasze szkolenia ewoluują, aby pomóc Twojej firmie",
	Footer_Language: "Wybierz kraj",
	MenuPopup_MyCart: "Mój koszyk",
	Stories_OurTrainingStories: "Nasze historie szkoleniowe",
	Stories_ConnectingExperts: "Łączenie ekspertów i entuzjastów, marzycieli i działaczy, innowatorów i influencerów - odkrywaj i doświadczaj",
	Stories_TopStories: "Inspirujące historie",
	Stories_AtHome: "W domu",
	Stories_Careers: "Kariera",
	Stories_History: "Historia",
	Stories_IndustryAndTrades: "Przemysł i handel",
	Stories_Mobility: "Mobilność",
	Stories_Research: "Badania",
	Stories_Sustainability: "Zrównoważony rozwój",
	Stories_AllStories: "Wszystkie historie",
	Stories_BlogATrainersDay: "BLOG | Dzień Trenera",
	Stories_ADayinLifeOfATrainer: "Dzień z życia trenera",
	Stories_BoschServiceTraining: "Centrum Szkoleniowe Bosch",
	Stories_StoryWhatDrivesYouDrivesUs: "HISTORIA | To, co napędza Ciebie, napędza nas",
	Stories_StoryHowCanWeBenefitYourBusiness: "HISTORIA | Jakie korzyści możemy dostarczyć Twojwj firmie",
	Stories_HowOurTrainingEvolves: "Rozwój naszych szkoleń",
	Stories_AModernTrainingApproach: "Świat szkoleń online",
	Stories_StoryAVirtualOnlineTrainingWorld: "HISTORIA | Twoje możliwości szkolenia online",
	Stories_SubscribeToRSSFeed: "Subskrybuj kanał RSS",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "O nas",
	Homepage_AutomotiveAftermarket: "Mobility Aftermarket",
	Homepage_GetInTouch: "Skontaktuj się z nami",
	Homepage_LatestNews: "Inspiruje nas zdobywanie wiedzy",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Otwarci na wiedzę",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "What drives you drives us",
	BoschServiceTraining_BoschServiceTraining: "Centrum Szkoleniowe Bosch",
	BoschServiceTraining_ShareThisOn: "Udostępnij to dalej",
	BoschServiceTraining_TheChallenge: "Wyzwanie: opanowanie nowych technologii",
	BoschServiceTraining_WorldwidePresence: "Obecność na całym świecie",
	BoschServiceTraining_TechnicalTraining: "Szkolenia techniczne firmy Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Oferty i możliwości oferowane przez firmę Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Dla każdej grupy docelowej – zarówno nowicjusza, jak i profesjonalisty – dostępne są odpowiednie kursy szkoleniowe. Oferta obejmuje szkolenia jednodniowe oraz szkolenia kilkudniowe. Szkolenia oferowane są w różnych obszarach technicznych i seriach szkoleniowych (np. wtrysk benzyny wtrysk diesla układy elektryczne). Na szkoleniach technicznych Bosch uczestnicy szkoleń otrzymują zaawansowaną wiedzę na temat systemów wtryskowych produkowanych przez firme Bosch. zakres tematyczny szkoleń dopasowny jest wymagań i oczekiwań pracowników serwisu.",
	BoschServiceTraining_TrainedEmployeesDesc: "Dobrze wyszkoleni pracownicy to korzyść dla każdego serwisu samochodowego. Dzisiejsze pojazdy stają się coraz bardziej złożone, a nowe technologie nieustannie stawiają nowe wyzwania. Tylko niezbędna wiedza fachowa pozwala pracownikom warsztatu na sprawne i ekonomiczne wykonywanie prac diagnostyczno-serwisowych i naprawczych aktualnych modeli pojazdów.",
	BoschServiceTraining_RegardlessOfHow: "Niezależnie od tego, jak dobrzy i doświadczeni są specjaliści z branży motoryzacyjnej – zawsze zdarzają się sytuacje, w których zetkną się z nowymi systemami technologii pojazdu lub nieznanymi usterkami. W związku z tym rozwiązywanie problemów może stać się szczególnie czasochłonne lub może nie przynieść pomyślnych rezultatów. W najgorszym przypadku klienci muszą obejść się bez pojazdu znacznie dłużej, niż oczekiwano.",
	BoschServiceTraining_ItIsPrecisely: "Właśnie w tym momencie firma Bosch wprowadza swoje szkolenia serwisowe: poprawiając jakość usług i przyspieszając procesy. Bieżące szkolenia firmy Bosch zapewniają pomoc w szybszej lokalizacji problemów,  naprawianiu większej liczby pojazdów w tym samym czasie i skracaniu czasu oczekiwania klientów.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Od ponad 125 lat Bosch jest preferowanym dostawcą części  i systemów na oryginalne wyposażenie dla wielu czołowych  producentów samochodów na całym świecie. To doświadczenie wiąże się z wyjątkową znajomością najnowszych technologii, które stanowią podstawę wsparcia  firmy Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Centra szkoleniowe Bosch są na całym świecie: od Hiszpanii po Turcję, Republikę Południowej Afryki, Australię i Brazylię — Bosch oferuje swoim pracownikom praktyczne szkolenia, zawsze zgodne z najnowocześniejszymi technologiami.",
	BoschServiceTraining_AgileCompany: "organizacja Agile",
	BoschServiceTraining_Collaboration: "Współpraca",
	BoschServiceTraining_ConnectedMobility: "Połączona mobilność",
	BoschServiceTraining_ArtificialIntelligence: "Sztuczna inteligencja",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Liderzy myśli w dialogu",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Sprzęt diagnostyczny i testowy",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Podstawy technologii motoryzacyjnej",
	BoschServiceTraining_Icon_Gasoline: "Benzyna",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Inne systemy napędowe",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektryka i Elektronika",
	BoschServiceTraining_Icon_BodyAndComfort: "Ciało i wygoda",
	BoschServiceTraining_Icon_ChassisAndBraking: "Podwozie i układ hamulcowy",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Przekładnia i układ napędowy",
	BoschServiceTraining_Icon_CommercialVehicles: "Pojazdy ciężarowe",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Szkolenia online",
	BoschServiceTraining_Icon_BusinessSkills: "Umiejętności biznesowe",
	BoschServiceTraining_Icon_Accreditations: "Akredytacje",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programy szkoleń",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "Imię i nazwisko Trenera",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Dzień z życia trenera",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Codzienność jest przygodą",
	ADayInTheLifeOfATrainer_Summary: "Streszczenie",
	ADayInTheLifeOfATrainer_TheDayJourney: "Dzienna podróż",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Każdy dzień jest pracowity, ale zawsze jest to satysfakcjonujące doświadczenie. Przyjemność szkolenia uczniów ze świadomością, że dzierżą klucz do naszej przyszłości, jest obowiązkiem, ale ekscytującym. Obserwowanie, jak wiedza kursantów wzrasta z każdą lekcją, codziennie i co tydzień, dowodzi, że pracujemy nad wspólnym celem, jakim jest doskonalenie ich wiedzy, przy jednoczesnym włączeniu nowoczesnej technologii, która napędza przyszłość!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Każde przeprowadzone szkolenie to dla nas okazja do analizy i dalszego rozwoju. Coraz wieksze grono przeszkolonych osób jest powodem do satsfakci dla naszych trenerów.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Po każdym szkoleniu mamy satysfakcję z dobrze przekazanej wiedzy.",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Start",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Przygotowanie i końcowe sprawdzenie materiału szkoleniowego. Sprawdź ważną pocztę",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Rozpoczyna się trening",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Uczestnik mile widziany. Prezentacja planu dnia. Poznaj grupę, rozumiejąc dynamikę każdej jednostki.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teoria",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Aby szkolenie było jak najbardziej interesujące,  wymagana jest pewna interakcja z grupą. Upewnij się, że istnieją zadania przeplatające się z teorią, aby jeszcze bardziej zaangażować grupę",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Przerwa na lunch",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktyka",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Szkolenie jest kontynuowane w warsztacie z kilkoma praktycznymi zadaniami. Jako trener zawsze wymagana jest obecność i dostępność na każdą ewentualność i wskazówki",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Podsumowanie",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Powrót do sali szkoleniowej, aby krótko omówić dzień szkolenia. Odpowiedz na wszelkie pytania, które mogą się pojawić. Rozpocznij przygotowania do kolejnych treningów.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Ostatni dzień",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Uczestnikom wręczane są certyfikaty uczestnictwa.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Strona główna",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Jak możemy pomóc Twojej firmie",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Jak rozwijamy naszą ofertę szkoleniową",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Stale się rozwijamy, aby zapewnić wysokiej jakości  aktualne szkolenia",
	BoschTrainingBenefits_TheBoschServiceTraining: "Centrum Szkoleniowe Bosch oferuje pomoc w rozwoju Twojej firmy dzięki obszernemu i odpowiedniemu portfolio szkoleń technicznych. Na kolejnych stronach znajdziesz przegląd naszej oferty szkoleniowej oraz aktualne możliwości dalszego kształcenia. Czekamy na Ciebie i Twoich pracowników na naszych szkoleniach i seminariach.",
	BoschTrainingBenefits_LongStandingExperience: "Wieloletnie doświadczenie i rozległa wiedza na temat wszystkich systemów oraz w dziedzinie techniki samochodowej.",
	BoschTrainingBenefits_QuickAndEfficient: "Szybkie i skuteczne rozwiązania wielu problemów.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Zalety i korzyści",
	BoschTrainingBenefits_LMS: "System zarządzania nauczaniem — rozwiązania szkoleniowe firmy Bosch",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions obejmuje pełen zakres obszarów motoryzacyjnych. Specjalizuj się w określonych obszarach lub poszerzaj swoje kompetencje dzięki ofercie szkoleniowej firmy Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Zdobyta wiedza o zapleczu naprawczym, konserwacyjnym i serwisowym.",
	BoschTrainingBenefits_TheHighDegree: "Wysoki poziom praktycznych treści i małe grupy gwarantują naukę zorientowaną na cel",
	BoschTrainingBenefits_ParticipationInTraining: "Udział w szkoleniach zwiększa motywację i satysfakcję  pracowników, a dodatkowe kwalifikacje przekonują klientów",
	BoschTrainingBenefits_IncreasedSales: "Zwiększenie sprzedaży dzięki znajomości systemu cross-mark",
	BoschTrainingBenefits_MethodicalProcedures: "Metodyczne procedury skracają czas oczekiwania klientów",
	BoschTrainingBenefits_HighlyQualified: "Wysoko wykwalifikowani instruktorzy przekazują systematyczne procedury",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identyfikacja rozwiązania szkoleniowego odpowiedniego dla Ciebie lub Twojej firmy",
	BoschTrainingBenefits_DirectOnlineBooking: "Bezpośrednia rezerwacja online i zarządzanie kursami dla siebie lub swoich pracowników",
	BoschTrainingBenefits_OnlineAccess: "Dostęp online do dokumentacji szkoleniowej",
	BoschTrainingBenefits_AccessToYourOwn: "Dostęp do własnej historii szkoleń i certyfikatów",
	BoschTrainingBenefits_ToolsForManagement: "Narzędzia do zarządzania rozwojem własnej kariery",
	BoschTrainingBenefits_ManagerHasFull: "Manager ma pełny wgląd w kursy swoich pracowników wewnątrz systemu",
	BoschTrainingBenefits_Access24hours: "Dostęp 24h/7 dni",
	ModernTrainingApproach_KnowledgeIsKey: "Wiedza jest kluczowym czynnikiem sukcesu każdego warsztatu. Dlatego firma Bosch oferuje kompleksowy program doskonalenia zawodowego. Umożliwia to studentom zdobycie dokładnie odpowiedniej wiedzy we właściwym czasie i miejscu - wiedzy wymaganej do profesjonalnej konserwacji i naprawy pojazdu klienta. Firma Bosch dodała webcasting jako nowy moduł w ramach już oferowanego pakietu e-learningowego. Składają się z sesji szkoleniowych dotyczących usług zaprojektowanych specjalnie pod kątem przesyłania strumieniowego.",
	ModernTrainingApproach_ANewLearning: "Nowa okazja do nauki",
	ModernTrainingApproach_YourOnlineTraining: "Twoje możliwości szkolenia online",
	ModernTrainingApproach_RecentlyTheWorld: "Ostatnio świat się zmienił, zobacz co robimy by pomóc",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Świat szkoleń online",
	ModernTrainingApproach_NewTeachingMethod: "Nowe metody nauczania",
	ModernTrainingApproach_InAdditionTo: "Oprócz istniejących szkoleń bezpośrednich, w programie szkoleniowym uwzględniliśmy szkolenie z transmisji internetowej na żywo. Nasz trener jest do Twojej dyspozycji z obszerną wiedzą teoretyczną w wirtualnej klasie lub z praktycznymi przykładami. Jak zwykle otrzymasz wiele informacji na temat systemów pojazdu i odpowiednich produktów Bosch. Oczywiście masz również możliwość zadawania pytań. Odpowiedzi na te pytania zostaną udzielone bezpośrednio podczas transmisji internetowej na żywo. Webcasty na żywo trwają od 60 do 120 minut. Wymagane jest stabilne połączenie internetowe. Wyszukaj odpowiedni webcast bezpośrednio w naszym katalogu szkoleń, wpisując \Webcast\ w polu wyszukiwania.",
	ModernTrainingApproach_BoschWebcastingHeader: "Webcasting firmy Bosch: innowacyjny i praktyczny",
	ModernTrainingApproach_BoschWebcastingText: "Aby wziąć udział w webcastie firmy Bosch, uczestnik musi najpierw połączyć się przez telefon. Indywidualny kod dostępu wysyłany jest przed sesją w celu umożliwienia logowania. W jednym czasie może wziąć udział do 200 uczestników z dowolnej liczby lokalizacji. Szkolenie jest prowadzone przez trenera, a pytania można zadawać w dowolnym momencie za pośrednictwem funkcji czatu na żywo.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Cyfrowy dodatek do szkolenia w klasie",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasty i szkolenia stacjonarne doskonale się uzupełniają. W obu przypadkach szkolenie prowadzi wykwalifikowany trener. Webcasty są odpowiednie dla tematów, które mogą być transmitowane cyfrowo bez konieczności pracy przy pojeździe. Na przykład poznanie niektórych funkcji ESI[tronic] nowego systemu wspomagania kierowcy. Jeśli chodzi o efektywną pracę zespołową w warsztacie lub praktyczną pracę nad technologią pojazdu, nadal pierwszym wyborem jest szkolenie bezpośrednie.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Transmisja strumieniowa może być tańsza",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Koszty na uczestnika są zwykle niższe w przypadku transmisji internetowej niż w przypadku porównywalnego kursu stacjonarnego. Powodem jest to, że więcej uczestników może z niego skorzystać, a wysiłek związany ze szkoleniem jest znacznie niższy (brak dojazdów do i z miejsca oraz mniej czasu).",
	ModernTrainingApproach_BookAndProfitHeading: "Zarezerwuj i zyskaj",
	ModernTrainingApproach_BookAndProfitText: "Webcasty można rezerwować na naszej stronie internetowej, podobnie jak inne nasze szkolenia i oferty edukacyjne. Masz do wyboru uiszczenie indywidualnej opłaty za każdą transmisję internetową lub wykupienie rocznej subskrypcji na określoną liczbę transmisji internetowych.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Porównanie funkcji Bosch Webcasting i szkoleń  stacjonarnych",
	ModernTrainingApproach_Webcasting: "Transmisja internetowa",
	ModernTrainingApproach_Classroom: "Klasa",
	ModernTrainingApproach_WebcastingP1: "W jednym czasie może wziąć udział do 200 uczestników z dowolnego miejsca na świecie",
	ModernTrainingApproach_WebcastingP2: "Łatwo dostępne i stosunkowo niskie koszty nie wymagają podróży",
	ModernTrainingApproach_WebcastingP3: "Idealny do konkretnych i zwięzłych pojedynczych tematów",
	ModernTrainingApproach_WebcastingP4: "Warsztaty mogą prowadzić transmisję internetową dla swoich pracowników w dowolnym miejscu i czasie",
	ModernTrainingApproach_WebcastingP5: "Od 50 do 70% mniej czasu niż w przypadku szkoleń stacjonarnych",
	ModernTrainingApproach_ClassroomTrainingP1: "W tym samym czasie spotyka się do 16 uczestników",
	ModernTrainingApproach_ClassroomTrainingP2: "Koszty podróży i ogólnie wyższe koszty na osobę",
	ModernTrainingApproach_ClassroomTrainingP3: "Idealny, jeśli chodzi o optymalizację współpracy lub naukę bezpośrednio w pojeździe",
	ModernTrainingApproach_ClassroomTrainingP4: "Ćwiczenia praktyczne i interakcja z trenerem",
	ModernTrainingApproach_ClassroomTrainingP5: "Trener może lepiej dostosować przekazywaną wiedzę do  rzeczywistych potrzeb uczestników",
	ModernTrainingApproach_OnlineTrainingOfferings: "Oferty szkoleń online (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Dziś samodzielna nauka przy komputerze jest integralną częścią transferu wiedzy. Swoboda wyboru czasu i miejsca, nieograniczona powtarzalność, indywidualna szybkość uczenia się oraz interaktywne treści nauczania sprawiają, że metoda ta jest niezastąpionym narzędziem do nauki.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Każdy element szkolenia internetowego (WBT) został starannie ustrukturyzowany. Oprócz szerokiej bazy wiedzy kursy te mogą służyć jako skuteczne przygotowanie do programów szkoleniowych w centrach szkoleniowych firmy Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Wirtualna symulacja diagnostyczna oferuje użytkownikowi możliwość odgrywania ról w codziennych sytuacjach warsztatowych, takich jak przebieg procedury rozwiązywania problemów przy użyciu urządzeń diagnostycznych Bosch w wirtualnym warsztacie.",
	ModernTrainingApproach_Advantages: "Zalety i korzyści",
	ModernTrainingApproach_AdvantagesP1: "Szybka i skuteczna metoda przekazywania wiedzy",
	ModernTrainingApproach_AdvantagesP2: "Zróżnicowana nauka",
	ModernTrainingApproach_AdvantagesP3: "Może być używany tak często, jak chcesz",
	ModernTrainingApproach_AdvantagesP4: "Minimalny czas i pieniądze",
	ModernTrainingApproach_AdvantagesP5: "Indywidualne tempo nauki",
	ModernTrainingApproach_AdvantagesP6: "Elastyczny czas nauki",
	ModernTrainingApproach_AdvantagesP7: "Powtarzalność",
	ModernTrainingApproach_AdvantagesP8: "Brak kosztów podróży",
	ModernTrainingApproach_AdvantagesP9: "Brak konfliktów pojemności lub harmonogramów",
	Dashboard_CoursesViewAndManageYourCourses: "KURSY | Przeglądaj kursy i zarządzaj nimi",
	Dashboard_MyCourses: "Moje kursy",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAM SZKOLEŃ | Zobacz swoje programy szkoleniowe",
	Dashboard_MyTrainingProgram: "Moje szkolenia",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "e-NAUKA | Przeglądaj i zarządzaj swoim portfolio  e-learningowym",
	Dashboard_MyELearning: "Moje e-learning",
	Dashboard_AccountManageYourAccount: "KONTO | Zarządzaj kontem",
	Dashboard_MyAccount: "Moje konto",
	Dashboard_MyDashboard: "Panel",
	Dashboard_ManageYourAccount: "Zarządzaj kontem",
	Dashboard_Workshops: "Warsztaty",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRACJA | Zarządzaj swoim warsztatem",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRACJA | Zarządzaj swoimi zespołami",
	Dashboard_Administration: "Administracja",
	Dashboard_ViewYourCourses: "Wyświetl swoje szkolenia",
	Dashboard_ViewYourTrainingPrograms: "Zobacz swoje szkolenia",
	Dashboard_TrainingPrograms: "Programy szkoleniowe",
	Dashboard_ViewAndEditYourDetails: "Przeglądaj i edytuj swoje dane",
	Dashboard_ViewYourELearningCourses: "Wyświetl swoje kursy e-learningowe",
	Dashboard_Pending: "W przygotowaniu",
	Dashboard_RequiresAdministratorSignOff: "(wymaga wylogowania administratora)",
	Dashboard_HistoricalRecords: "Historyczne zmiany",
	Dashboard_Registered: "Zarejestrowany",
	Dashboard_WaitingList: "Lista oczekujących",
	Dashboard_RecommendedForYou: "polecany dla Ciebie",
	Dashboard_AccountDetails: "Szczegóły Konta",
	Dashboard_ChangeProfileImage: "zmień zdjęcie profilowe",
	Dashboard_Title: "Tytuł",
	Dashboard_Role: "Rola",
	Dashboard_FirstName: "Imię",
	Dashboard_LastName: "Nazwisko",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "mobilny",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Język",
	Dashboard_Save: "Zapisz",
	Dashboard_Student: "Student",
	Dashboard_WorkshopTrainingAdministrator: "Administrator szkoleń warsztatowych",
	Dashboard_WholesalerTrainingAdministrator: "Administrator Szkoleń Hurtowych",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administracja firmy",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Zarządzaj warsztatami...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Zarządzaj swoimi pracownikami...",
	Dashboard_CompanyAdministration_Workshop: "Warsztat",
	Dashboard_CompanyAdministration_Address: "Adres zamieszkania",
	Dashboard_CompanyAdministration_Zip: "Kod pocztowy",
	Dashboard_CompanyAdministration_City: "Miasto",
	Dashboard_CompanyAdministration_Manage: "Zarządzaj",
	Dashboard_AdministrationSection_AdministrationTitle: "Administracja",
	Dashboard_AdministrationSection_ManageTheTeam: "Zarządzaj zespołem",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRACJA | Zarządzaj swoimi pracownikami",
	Dashboard_AdministrationSection_Employees: "Pracownicy",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRACJA | Zarządzaj swoimi hurtowniami",
	Dashboard_AdministrationSection_Wholesalers: "Hurtownie",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRACJA | Zarządzaj swoimi transakcjami",
	Dashboard_AdministrationSection_Transactions: "Transakcje",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administracja Pracowników",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Zarządzaj swoimi pracownikami...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Zarejestruj",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Dokumentacja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Edytuj",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Pracownik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Dodaj",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Utwórz",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Zarejestrowany",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "W oczekiwaniu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(wymaga wylogowania administratora)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Dane historyczne",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Lista oczekujących",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Aktualna lista pracowników",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Zarządzanie pracownikami",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nazwa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Konto pracownika",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Przeglądaj i edytuj dane pracownika...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Ustawienia",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Zezwól na osobiste konto Bosch Automotive Training Solutions",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Zmień pracownika na administratora firmy",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Zezwól na rejestrację kursu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Wyślij zaproszenie e-mailem",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Dezaktywuj konto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Zapisz",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Zaproś",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Edytuj",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Zarządzaj hurtownią",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Zarządzaj swoimi hurtowniami...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Zatwierdzona lista hurtowników",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nazwa",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Usuń",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Utwórz",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Dodaj",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Tworzenie nowego konta pracownika",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Utwórz dane dla nowych pracowników...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Dodaj nowego zatwierdzonego administratora hutrowini",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Utwórz szczegóły dla nowych hurtowni",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Wybierz zatwierdzonego Hurtownika z rozwijanego menu...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transakcje",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Przeglądaj swoje transakcje i zarządzaj nimi...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Wróć",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Numer faktury",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Opis",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Metoda płatności",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Data",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Kwota",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Pobierz",
	TrainingCentres_TrainingCentres: "Centra szkoleniowe",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Nasze centra szkoleniowe znajdują się na całym świecie",
	TrainingCentres_CountryCategory: "Kategoria kraju",
	TrainingCentres_CityCategory: "Kategoria miasta",
	TrainingCentres_Country: "Kraj",
	TrainingCentres_City: "Miasto",
	TrainingCatalogue_TrainingCatalogue: "Katalog szkoleń",
	TrainingCatalogue_LearningAtTheHighestLevel: "Nauka na najwyższym poziomie",
	TrainingCatalogue_StartDate: "Data rozpoczęcia",
	TrainingCatalogue_EndDate: "Data zakonczenia",
	TrainingCatalogue_TrainingName: "Nazwa szkolenia",
	TrainingCatalogue_Language: "Język",
	TrainingCatalogue_Days: "Dni",
	TrainingCatalogue_Seats: "Wolne miejsca",
	TrainingCatalogue_Price: "Cena",
	TrainingCatalogue_Location: "Lokalizacja",
	TrainingCatalogue_PartNumber: "Numer części",
	TrainingCatalogue_TrainingCourse: "Kurs treningowy",
	TrainingCatalogue_Category: "Kategoria",
	TrainingCatalogue_TrainingCategory: "Kategoria szkolenia",
	TrainingCatalogue_CourseName: "Nazwa szkolenia",
	TrainingCatalogue_TotalDays: "Suma dni",
	TrainingCatalogue_Venue: "Miejsce wydarzenia",
	TrainingCatalogue_Details: "Szczegóły",
	TrainingCatalogue_Prerequisites: "Wymagania wstępne",
	TrainingCatalogue_CourseType: "Typ kursu",
	TrainingCatalogue_LearningMethod: "Metoda uczenia się",
	TrainingCatalogue_AddToWaitingList: "Dodaj do listy oczekujących",
	ShoppingCart_ShoppingCart: "Koszyk",
	ShoppingCart_ReviewAndManage: "Przeglądaj swoje subskrypcje i zarządzaj nimi",
	ShoppingCart_YouHaveAddedItem: "Dodałeś następujące pozycje do koszyka",
	ShoppingCart_Item: "Artykuł",
	ShoppingCart_Quantity: "Ilość",
	ShoppingCart_PricePerItem: "Cena za sztukę",
	ShoppingCart_TotalPrice: "Cena całkowita",
	ShoppingCart_VatIsIncluded: "Wył. faktura VAT",
	ShoppingCart_Redeem: "Jeśli nie masz kredytu, kliknij tutaj, aby go wykorzystać",
	ShoppingCart_Purchase: "Jeśli nie masz kredytu, kliknij tutaj, aby dokonać zakupu",
	ShoppingCart_Checkout: "Kasa",
	ShoppingCart_ContinueShopping: "Kontynuuj zakupy",
	ShoppingCart_TotalPriceInclVat: "Cena razem faktura VAT",
	ShoppingCart_Apply: "Zastosuj",
	ShoppingCart_ShoppingCartEmpty: "Twój koszyk jest pusty",
	ShoppingCart_VatIncluded: "VAT naliczony)",
	ShoppingCart_ManageYourShopping: "Zarządzaj swoimi zakupami",
	ShoppingCart_Participant: "Uczestnik",
	ShoppingCart_Complete: "Zakończ",
	ShoppingCart_NetPrice: "Cena netto",
	ShoppingCart_Workshop: "Warsztat",
	CheckOut_PreviewYourOrder: "Wyświetl podgląd zamówienia",
	CheckOut_YourShoppingCart: "Koszyk",
	CheckOut_Preview: "Podgląd",
	CheckOut_Payment: "Płatność",
	CheckOut_Summary: "Podsumowanie",
	CheckOut_Back: "Wróć",
	CheckOut_PleaseReadAndAgreeOurTerms: "Przeczytaj i zaakceptuj nasze warunki",
	CheckOut_IHaveReadTheTermsAndCondition: "Zapoznałem się z [warunkami], rozumiem je i akceptuję",
	CheckOut_YesIAgree: "Tak - zgadzam się na warunki",
	CheckOut_Continue: "Kontynuuj",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Gratulacje! Otrzymaliśmy Twoje zamówienie",
	CheckOut_OrderConfirmation: "Po pomyślnym przetworzeniu Twojego żądania otrzymasz ostateczne potwierdzenie zamówienia",
	CheckOut_WeWillContactYouDirectly: "Skontaktujemy się z Tobą bezpośrednio w przypadku  jakichkolwiek pytań związanych z Twoją prośbą",
	CheckOut_AddToCart: "Dodaj do koszyka",
	CheckOut_CreditCart: "Koszyk kredytowy",
	CheckOut_DirectDebit: "Polecenie zapłaty",
	CheckOut_TrainingCredits: "Kredyty szkoleniowe",
	CheckOut_PreferredWholesaler: "Preferowany hurtownik",
	EventCalendar_EventCalendar: "Harmonogram szkoleń",
	EventCalendar_TrainingEventsForAllYourNeeds: "Szkolenia dla wszystkich Twoich potrzeb",
	TrainingCourse_TrainingCourse: "Katalog szkoleń",
	TrainingCourse_LearningAtTheHighestLevel: "Nauka na najwyższym poziomie",
	TrainingProgram_Title: "Katalog programów szkoleniowych",
	TrainingProgram_SubTitle: "Nauka na najwyższym poziomie",
	AccountDeactivationModal_Title: "Czy na pewno chcesz dezaktywować to konto?",
	AccountDeactivationModal_Line1: "Dezaktywacja tego konta jest nieodwracalna i spowoduje  usunięcie całej zawartości, w tym transakcji żądań usług  i sald członków zespołu oraz ustawień profilu.",
	AccountDeactivationModal_Line2Part1: "Jeśli jest to aktywny okres subskrypcji, zapoznaj się z naszymi zasadami anulowania w naszym",
	AccountDeactivationModal_Line2Part2: "Zasady i Warunki",
	AccountDeactivationModal_Line2Part3: "przed złożeniem wniosku.",
	AccountDeactivationModal_Line3: "Czy na pewno chcesz przesłać prośbę o dezaktywację tego konta?",
	AccountDeactivationModal_Cancel: "Anuluj",
	AccountDeactivationModal_Send: "Wyślij",
	CurrentUserAccountDeactivationModal_Title: "Czy na pewno chcesz dezaktywować swoje konto?",
	CurrentUserAccountDeactivationModal_Line1: "Dezaktywacja konta jest trwała i spowoduje usunięcie całej zawartości, w tym transakcji żądań usług i sald członków zespołu oraz ustawień profilu.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Jeśli jesteś w aktywnym okresie subskrypcji, zapoznaj się z naszymi zasadami anulowania w naszym",
	CurrentUserAccountDeactivationModal_Line3: "Czy na pewno chcesz złożyć wniosek o dezaktywację konta?",
	CurrentUserAccountDeactivationModal_Yes: "Tak",
	CurrentUserAccountDeactivationModal_No: "nie",
	CourseWithdrawalModal_Title: "Rezygnacja ze szkolenia",
	CourseWithdrawalModal_Line1: "Czy na pewno chcesz zrezygnować ze szkolenia?",
	CourseWithdrawalModal_Line2Part1: "Wypłaty podlegają opłacie za anulowanie i będą naliczane zgodnie z",
	CourseWithdrawalModal_Line2Part2Link: "Zasady i Warunki",
	CourseWithdrawalModal_Line2Part3: "naszego serwisu.",
	CourseWithdrawalModal_Yes: "Tak, anuluj",
	CourseWithdrawalModal_No: "Nie, anuluj",
	Settings_Settings: "Ustawienia",
	Settings_ManageYourSettings: "Zarządzaj swoimi ustawieniami",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Dane logowania My Bosch Global ID",
	Settings_Edit: "Edytuj",
	Settings_CompanyDetails: "Szczegóły firmy",
	Settings_RequestAccountDeletion: "Poproś o usunięcie konta",
	Settings_AccountDeletionText: "Jeśli usuniesz konto, wszystkie Twoje dane (w tym adres i dane dotyczące płatności) zostaną usunięte i nie będą już dostępne.",
	Settings_DeleteAccountRequestConfirmation: "Usuń potwierdzenie prośby o konto",
	Settings_DeleteAccountRequestConfirmationText: "Otrzymaliśmy Twoją prośbę o usunięcie konta i skontaktujemy  się z Tobą e-mailem lub telefonicznie w celu ostatecznego potwierdzenia",
	RecommendedHotels_RecommendedHotels: "Polecane hotele",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Nasze centra szkoleniowe znajdują się na całym świecie",
	Error_Common: "Coś poszło nie tak!",
	Error_404: "Odwiedzona strona nie istnieje",
	Error_401: "Nie masz dostępu do tej strony",
	Error_500: "Błąd serwera",
	Dashboard_Courses_Table_Course: "Kurs",
	Dashboard_Courses_Table_Date: "Data [Czas trwania]",
	Dashboard_Courses_Table_Venue: "Miejsce wydarzenia",
	Dashboard_Courses_Table_TrainingType: "Rodzaj szkolenia",
	Dashboard_Courses_Table_Action: "Akcja",
	Dashboard_Courses_Table_TasksToComplete: "Masz następujące zadania do wykonania",
	Dashboard_Courses_Table_Withdraw: "Anuluj",
	Dashboard_Courses_Table_Remove: "Usuń",
	Dashboard_Courses_Table_View: "Pogląd",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Brak nagrań do odtworzenia",
	Dashboard_Courses_Table_CompletionDate: "Data zakończenia",
	Dashboard_Courses_Table_Actions_Assignments: "Zadania",
	Dashboard_Courses_Table_Actions_Certification: "Certyfikat",
	Dashboard_Courses_Table_Actions_Evaluations: "Oceny",
	Dashboard_Courses_Table_Actions_Assessments: "Oceny",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Dodaj do kalendarza",
	Dashboard_Courses_Table_CourseDetails_Task: "Zadanie",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Termin płatności",
	ContactUs_Headline: "Skontaktuj się z nami",
	ContactUs_HowCanWeHelp: "Jak możemy ci pomóc?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "List",
	ContactUs_Phone_Headline: "Zadzwoń do nas!",
	ContactUs_Phone_Description: "Czekamy na Twój telefon i chętnie Ci pomożemy.",
	ContactUs_Phone_PhoneNumber: "Skorzystaj z formularza kontaktowego, aby wysłać do nas wiadomość.",
	ContactUs_Phone_ChargeNotice: "Opłaty różnią się w zależności od dostawcy usług i kraju.",
	ContactUs_Letter_Headline: "Wyślij list!",
	ContactUs_Letter_Description: "Masz pytania, prośby lub sugestie? Czekamy na Twoje zapytanie.",
	ContactUs_Email_Headline: "Wyślij e-mail!",
	ContactUs_Email_Description: "Wyślij nam swoją wiadomość wypełniając poniższy formularz. Jeden z naszych doradców chętnie się z Tobą skontaktuje.",
	ContactUs_Email_InputName: "Nazwa*",
	ContactUs_Email_InputCompany: "Firma",
	ContactUs_Email_InputCountry: "Kraj*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Wiadomość*",
	ContactUs_Email_DataProtection: "Informacja o ochronie danych*",
	ContactUs_Email_DataProtectionConfirm: "Zapoznałem się z Polityką Prywatności.*",
	ContactUs_Email_SecurityCheck: "Kontrola bezpieczeństwa",
	ContactUs_Email_SecurityCodeConfirm: "Wpisz poprawne znaki, które widzisz w polu powyżej.",
	ContactUs_Email_SecurityCodeInvalid: "Wprowadzona odpowiedź tekstowa captcha jest nieprawidłowa.",
	ContactUs_Email_MandatoryNotice: "Proszę wypełnić pola obowiązkowe.",
	ContactUs_Email_SuccessMessage: "Twoja wiadomość została przesłana",
	ContactUs_Email_TakenNoteDataProtection1: "Przeczytałem",
	ContactUs_Email_TakenNoteDataProtection2: "Polityka prywatności.*",
	ContactUs_Email_SubmitButton: "Prześlij",
	ContactUs_Email_FieldErrorMessage: "Proszę wypełnić pola obowiązkowe.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Twoje konto jest obecnie sprawdzane!",
	AccountInReviewModal_Paragraph1: "Dziękujemy za zarejestrowanie konta osobistego w  Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Nasz oddany personel szkoleniowy aktualnie sprawdza i  konfiguruje Twoje konto.",
	AccountInReviewModal_Paragraph3: "W międzyczasie zachęcamy do przeglądania naszej  witryny internetowej z ograniczonym dostępem.",
	AccountInReviewModal_Paragraph4: "Jeśli masz jakieś pytania lub jeśli Twoje konto nie zostanie przetworzone w ciągu 48 godzin z wyłączeniem weekendów i świąt, możesz się z nami skontaktować",
	AccountInReviewModal_ContactUsLink: "tutaj",
	AccountInReviewModal_OKButton: "Kontynuuj",
	Header_YesButton: "Tak",
	Header_NoButton: "Nie",
	Header_LogOutText: "Czy chcesz się wylogować?",
	Footer_EnquiryText: "Czekamy na Twoje zapytanie,",
	Footer_Telephone: "Telefon",
	Footer_TermsAndConditions: "Zasady i Warunki",
	Footer_HowOurTrainingEvolves: "Jak ewoluuje nasze szkolenie",
	FooterSearch_SearchFieldPlaceholder: "Szukaj",
	TrainingCatalogue_Action: "Akcja",
	TrainingCatalogue_Duration: "Czas trwania",
	TrainingCatalogue_Information: "Informacja",
	Settings_PersonalDetails: "Dane osobowe",
	Reset_All_Filters: "Zresetuj wszystkie filtry",
	EventTable_Days: "Dni",
	SearchField_AllResults: "Wszystkie wyniki",
	TrainingCentresLayout_ViewDetails: "Pokaż szczegóły",
	Dropdown_Placeholder: "Wybierz...",
	WaitlistPopup_SelectButtonText: "Wybierz",
	WaitlistPopup_StartDate: "Data rozpoczęcia",
	WaitlistPopup_EndDate: "Data zakonczenia",
	WaitlistPopup_Language: "Język",
	WaitlistPopup_City: "Miasto",
	WaitlistPopup_Workshop: "Warsztat",
	WaitlistPopup_Student: "Uczestnik",
	WaitlistPopup_PreferredLocation: "Preferowana lokalizacja",
	WaitlistPopup_SignUpCourseWaitingList: "Zapisz się na listę oczekujących na kurs",
	WaitlistPopup_SignUpWithoutSelectingDate: "Zapisz się na listę oczekujących bez wybierania daty",
	WaitlistPopup_GeneralWaitList: "Ogólna lista oczekujących",
	WatinglistPopup_CourseLabel: "Kurs",
	Common_ContinueButton: "Kontynuuj",
	UserAccountDeactivationModal_ConfirmLabel: "Potwierdź",
	Filter_FilterLabel: "Filtr",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Jesteś już na liście oczekujących, czy chcesz usunąć istniejącą rejestrację i dokonać nowej?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Tak, usuń",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nie, anuluj",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Rezygnacja z kursu",
	WaitinglistPopup_SignUpButtonText: "Zapisz się",
	MyAccount_FirstNameRequied: "Pierwsze imię jest wymagane",
	MyAccount_LastNameRequired: "Nazwisko jest wymagane",
	MyAccount_RoleRequired: "Rola jest wymagana",
	MyAccount_MobileRequired: "Telefon komórkowy jest wymagany",
	MyAccount_EmailRequired: "E-mail jest wymagany",
	MyAccount_LanguageRequired: "Język jest wymagany",
	MyAccount_UpdateSuccessMessage: "Dane Twojego konta zostały zaktualizowane pomyślnie!",
	MyAccount_UpdateSuccessModal_ButtonText: "Tak, zamknij",
	Infor_CorporateInformation_Header: "Informacje o firmie",
	Infor_LegalNotice_Header: "Nota prawna",
	Infor_PrivacyPolicy_Header: "Informacja o ochronie danych (Polityka prywatności)",
	CreateEmployeeAccountModification_FormValidation_Role: "Rola jest wymagana",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Pierwsze imię jest wymagane",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Nazwisko jest wymagane",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Telefon komórkowy jest wymagany",
	CreateEmployeeAccountModification_FormValidation_Email: "E-mail jest wymagany",
	CreateEmployeeAccountModification_FormValidation_Language: "Język jest wymagany",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Zaproszenie do dołączenia do Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Pomyślnie dodałeś nowego członka do swojego zespołu!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Czy chcesz, abyśmy przesłali nowemu członkowi Twojego zespołu e-mail z zaproszeniem zawierającym instrukcje dotyczące tworzenia własnego konta osobistego w Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Nie",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Tak, wyślij zaproszenie",
	ShoppingCart_Venue_Fee: "Opłata za miejsce",
	ShoppingCart_Trainning_Name: "Nazwa szkolenia",
	ShoppingCart_Available_Credits: "Dostępne kredyty",
	ConfirmItsMeModal_Hi: "Cześć",
	ConfirmItsMeModal_Description1: "Pomyślnie wypełniłeś zaproszenie wysłane przez administratora Twojej firmy, aby utworzyć własne konto osobiste w Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Zanim przejdziesz dalej, potwierdź lub zaktualizuj dane  swojego konta.",
	ConfirmItsMeModal_Description3: "Po potwierdzeniu konieczne będzie ponowne zalogowanie się, aby dokończyć tworzenie konta.",
	ConfirmItsMeModal_CompanyDetails: "Szczegóły firmy",
	ConfirmItsMeModal_YourDetails: "Twoje szczegóły",
	ConfirmItsMeModal_YourDetails_Title: "Tytuł",
	ConfirmItsMeModal_YourDetails_Function: "Funkcja",
	ConfirmItsMeModal_YourDetails_Name: "Nazwa",
	ConfirmItsMeModal_YourDetails_Mobile: "Telefon komórkowy",
	ConfirmItsMeModal_YourDetails_Email: "E-mail",
	ConfirmItsMeModal_YourDetails_Language: "Język",
	ConfirmItsMeModal_ConfirmButton: "Potwierdź",
	SelectWholesalerModal_WholesalerGroupLabel: "Hurtownik",
	SelectWholesalerModal_WholesalerBranchLabel: "Wyprzedaż",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Proszę wybrać",
	ApplyButton: "Zastosuj",
	SelectWholesalerModal_Title: "Wybierz swojego Hurtownika",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Czy chcesz usunąć swoją rejestrację?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Twoja rejestracja została pomyślnie usunięta!",
	WaitlistPopup_AddToWaitListSuccess: "Twoja rejestracja to dodatkowy sukces!",
	ErrorCommon_Header: "BŁĄD",
	CommonButton_Close: "Zamknij",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Zamknij to okno, aby kontynuować!",
	EmbeddedVideoPlayer_StartButtonText: "Początek",
	EmbeddedVideoPlayer_RefresherButtonText: "Odświeżanie",
	DeactivateWholesalerModal_ConfirmMessage: "Czy na pewno chcesz usunąć tego hurtownika?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Wysłanie e-maila z zaproszeniem powiodło się!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Ponowne zaproszenie",
	ContactUs_Mobile_PL: "PL +48 22 715 45 19",
	ContactUs_Address_PL: "Centrum Szkoleniowe Techniki Motoryzacyjnej Bosch,ul. Jutrzenki 105, 02-231 Warszawa",
	ConfirmItsMeModal_CompanyDetails_Name: "Nazwa firmy",
	ConfirmItsMeModal_CompanyDetails_Address: "Adres",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Kod pocztowy",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-mail",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Rezygnacja z kursu powiodła się",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Utworzenie konta nie powiodło się. Konto już istnieje.",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Aktualizacja konta nie powiodła się. Konto już istnieje.",
	Footer_ExploreTrainingStories: "Poznaj historię naszych szkoleń.",
	Breadcrumb_Home: "Home",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Historie",
	Breadcrumb_Stories_BoschServiceTraining: "Centrum Szkoleniowe Bosch",
	Breadcrumb_Stories_ADayInLife: "Dzień z pracy trenera",
	Breadcrumb_Stories_BoschTrainingBenefits: "Benefity szkoleniowe",
	Breadcrumb_Stories_TrainingApproach: "Nowoczesne podejscie szkoleniowe",
	Breadcrumb_Settings: "Ustawienia",
	Breadcrumb_ShoppingCart: "Koszyk",
	Breadcrumb_Checkout: "Wyloguj się",
	Breadcrumb_OrderSummary: "Podsumowanie zamówienia",
	Breadcrumb_Exception: "Wyjątki",
	Breadcrumb_Dashboard: "Panel",
	Breadcrumb_MyCourses: "Moje szkolenia",
	Breadcrumb_MyELearning: "Mój eLearning",
	Breadcrumb_CompanyAdministration: "Administracja firmy",
	Breadcrumb_EmployeeManagement: "Zarządzanie pracownikami",
	Breadcrumb_EmployeeAccount: "Konto pracownika",
	Breadcrumb_EmployeeCreation: "Tworzenie pracowników",
	Breadcrumb_Administration: "Administracja",
	Breadcrumb_EmployeeAdministration: "Administracja Pracowników",
	Breadcrumb_WholesalerManagement: "Zarządzanie hurtownią",
	Breadcrumb_NewWholesalerAccountCreation: "Tworzenie nowego konta hurtownika",
	Breadcrumb_Transactions: "Transakcje",
	Breadcrumb_MyAccount: "Moje konto",
	Breadcrumb_TrainingCentres: "Centra szkoleniowe",
	Breadcrumb_EventCalendar: "Harmonogram szkoleń",
	Breadcrumb_TrainingCourseCatalogue: "Katalog szkoleń",
	Breadcrumb_RecommendedHotels: "Polecane hotele",
	Breadcrumb_CorporateInformation: "Informacje o firmie",
	Breadcrumb_LegalNotice: "Nota prawna",
	Breadcrumb_DataProtectionNotice: "Informacja o ochronie danych",
	Breadcrumb_PrivacyPolicy: "Polityka prywatności",
	Breadcrumb_TermCondition: "Zasady i warunki",
	Breadcrumb_Terms: "Warunki",
	Breadcrumb_PrivacyStatement: "Oświadczenie o ochronie prywatności",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Dostawca",
	Breadcrumb_RegisterAccount: "Zarejestruj Konto",
	Stories_ExploreMore: "Odkryj więcej",
	CheckOutLayout_ShoppingCartHeader: "Koszyk",
	CheckOutLayout_AddedItemCartMessage: "Dodałeś następujące elementy do koszyka",
	CheckOutLayout_CheckYourCartMessage: "Sprawdź swój koszyk",
	ModernTrainingApproach_DateText: "2/28/2022 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "3/1/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "3/2/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "3/3/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Szukaj pracowników",
	CheckOutLayout_OrderWasNotPlaced_Message: "Przepraszamy! Twoje zamówienie nie zostało złożone",
	CheckOutLayout_RefusalReason: "Powód odmowy",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Płatność nie powiodła się. Spróbuj ponownie, używając innej metody płatności",
	CheckOutLayout_SubmittingApiFailed_Message: "Przesyłanie interfejsu API nie powiodło się",
	CartItem_ChooseWorkshop_Message: "Wybierz warsztat, aby wybrać uczestnika",
	CartItem_ChooseParticipant_Message: "Wybierz uczestnika, aby kontynuować",
	CompanyAdministrationTable_SearchBox_Placeholder: "Wyszukaj warsztaty",
	CartItem_RemoveCartItem_ConfirmText: "Czy chcesz usunąć to szkolenie z koszyka?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funkcja",
	AddToCartSuccessModal_Message: "Twój koszyk został zaktualizowany!",
	AddToCartSuccessModal_ContinueButtonText: "Kontynuuj zakupy",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Mój koszyk",
	AddVoucher_Header: "Dodaj kupon",
	AddVoucher_InputPlaceholder: "Wpisz kod i zastosuj",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Niestety w tej chwili nie mamy zaplanowanych wydarzeń z tego  szkolenia. Zapisz się na listę oczekujących, a my poinformujemy Cię, gdy wydarzenie stanie się dostępne.",
	ADYEN_ERR_2_: "Transakcja została odrzucona.",
	ADYEN_ERR_3_: "Błąd przekierowań",
	ADYEN_ERR_4_: "Transakcja nie została zrealizowana z powodu błędu, który wystąpił po stronie agenta rozliczeniowego.",
	ADYEN_ERR_5_: "Karta użyta do transakcji jest zablokowana, więc nie nadaje się do użytku.",
	ADYEN_ERR_6_: "Karta użyta do transakcji straciła ważność. Karta jest bezużyteczna.",
	ADYEN_ERR_7_: "Podczas procesu transakcji wystąpiła niezgodność kwoty",
	ADYEN_ERR_8_: "Podany numer karty jest nieprawidłowy lub nieważny.",
	ADYEN_ERR_9_: "Nie ma możliwości skontaktowania się z bankiem kupującego w celu autoryzacji transakcji.",
	ADYEN_ERR_10_: "Bank kupującego nie obsługuje lub nie zezwala na tego typu transakcje.",
	ADYEN_ERR_11_: "Uwierzytelnianie 3D Secure nie zostało wykonane lub nie zostało wykonane pomyślnie.",
	ADYEN_ERR_12_: "Na karcie nie ma wystarczających środków na pokrycie kwoty do zapłaty. Ten problem może wystąpić z powodu miesięcznego limitu kwoty do zapłaty na Twojej karcie. Spróbuj użyć innej karty, takiej jak MasterCard, lub skontaktuj się ze swoim bankiem, aby zresetować miesięczny limit karty.",
	ADYEN_ERR_14_: "Możliwe oszustwo.",
	ADYEN_ERR_15_: "Transakcja została anulowana.",
	ADYEN_ERR_16_: "Kupujący anulował transakcję przed jej zakończeniem.",
	ADYEN_ERR_17_: "Podany kod PIN jest nieprawidłowy lub nieprawidłowy.",
	ADYEN_ERR_18_: "Kupujący podał błędny kod PIN więcej niż trzy razy z rzędu.",
	ADYEN_ERR_19_: "Nie ma możliwości zweryfikowania podanego numeru PIN.",
	ADYEN_ERR_20_: "Możliwe oszustwo.",
	ADYEN_ERR_21_: "Transakcja nie została poprawnie przesłana  do realizacji.",
	ADYEN_ERR_22_: "Kontrola ryzyka oznaczyła transakcje jako  oszustwo (ocena ryzyka >= 100); dlatego operacja jest anulowana.",
	ADYEN_ERR_23_: "Następujące kody odrzucenia są przypisane do tego powodu odmowy:  Transakcja nie jest dozwolona dla wydawcy/posiadacza karty",
	ADYEN_ERR_24_: "Podany kod CVC (kod bezpieczeństwa karty) jest nieprawidłowy.",
	ADYEN_ERR_25_: "Następujące kody odrzucenia są przypisane do tego powodu odmowy:  Nieprawidłowa karta  w tym kraju",
	ADYEN_ERR_26_: "R1: Cofnięcie polecenia autoryzacji / „R3:,Cofnięcie wszystkich poleceń autoryzacji”,/ „R0: Polecenie wstrzymania płatności”",
	ADYEN_ERR_27_: "Ta odpowiedź odwzorowuje wszystkie te,kody odpowiedzi, których nie można,wiarygodnie odwzorować. Ułatwia to,odróżnienie ogólnych odrzuceń (na przyklłd odpowiedzi Mastercard „05: Nie honoruj”),od bardziej szczegółowych.",
	ADYEN_ERR_28_: "Kwota wypłaty dozwolona dla karty klienta  została przekroczona.",
	ADYEN_ERR_29_: "Przekroczono liczbę dozwolonych wypłat dla karty klienta.",
	ADYEN_ERR_31_: "Emitent zglosił transakcję jako podejrzenie oszustwa.",
	ADYEN_ERR_32_: "Dane adresowe wprowadzone przez kupującego  sa nieprawidłowe.",
	ADYEN_ERR_33_: "Bank kupującego wymaga od kupującego wprowadzenia internetowego kodu PIN.",
	ADYEN_ERR_34_: "Bank kupującego wymaga konta czekowego, aby sfinalizować zakup.",
	ADYEN_ERR_35_: "Aby sfinalizować zakup, bank kupującego wymaga konta oszczędnościowego.",
	ADYEN_ERR_36_: "Bank kupującego wymaga od kupującego wprowadzenia mobilnego kodu PIN.",
	ADYEN_ERR_37_: "Kupujący porzucił transakcje po próbie płatności zbliżeniowej i został poproszony  o wypróbowanie innej metody wprowadzenia karty (PIN lub przeciagniecie).",
	ADYEN_ERR_38_: "Wystawca odrzucił wniosek o zwolnienie z uwierzytelniania i wymaga uwierzytelnienia transakcji. Spróbuj ponownie z 3D Secure.",
	ADYEN_ERR_39_: "Wystawca lub program nie był w stanie przekazać wyniku za pośrednictwem RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Imię nie może zawierać znaków specjalnych  (np. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Imię musi być krótsze niż 75 znaków",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Nazwisko nie może zawierać znaków specjalnych (np. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Nazwisko musi być krótsze niż 75 znaków",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Numer telefonu komórkowego nie może zawierać znaków specjalnych (np.:@#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Numer telefonu komórkowego musi być krótszy niż 45 znaków",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Numer telefonu nie może zawierać znaków  specjalnych (np.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Numer telefonu musi być krótszy niż 45 znaków",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Adres e-mail jest nieprawidłowy",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Adres e-mail musi być mniejszy niż 10 znaków",
	ConfirmGeolocationModal_ConfirmButton: "Kontynuuj",
	ConfirmGeolocationModal_CancelButton: "Zmień",
	ConfirmGeolocationModal_CurrentCountrySelection: "Aktualnie wybrany kraj to:",
	ConfirmGeolocationModal_AskChangeCountry: "Czy chcesz pozostać na tej stronie, czy zmienić preferencje dotyczace kraju?",
	Addtocart_Modal_AddToCartFailTitle: "Dodanie uczestnika nie powiodło sie",
	Addtocart_Modal_AlreadyInCart: "Uczestnik jest już zarejestrowany na to Wydarzenie",
	TrainingCatalogue_DateRange: "Zakres dat",
	TrainingCatalogue_DateRange_placeholder: "Wybierz filtr zakresu dat",
	Dashboard_MyPendingCourses: "Moje kursy oczekujące",
	Dashboard_MyWaitingList: "Moja lista oczekujących",
	Dashboard_LatestNews: "Najnowsze wiadomości",
	SettingsMainPage_EditAccountButton: "Edytuj konto",
	SettingsMainPage_EditBoschId: "Edytuj identyfikator Bosch",
	EventInfoView_EventDetailTab: "Szczegóły wydarzenia",
	EventInfoView_AssignmentsTab: "Zadania",
	EventInfoView_EvaluationTab: "Ocena",
	EventInfoView_AttachmentTab: "Załaczniki",
	EventInfoView_CourseDescriptionTab: "Opis",
	EventInfoView_DescriptionTab_General: "Ogólny",
	EventInfoView_DescriptionTab_PartLabel: "Część",
	AssignmentTab_Description: "Tutaj znajdziesz wszystkie informacje potrzebne do ukończenia tego kursu.",
	AssignmentTab_Columns_Download: "Pobierz",
	AssignmentTab_Columns_Upload: "Wgraj",
	AssignmentTab_Columns_TaskComplete: "Zadanie zakończone",
	EventDetailTab_Location_Message_Video: "Jest to wydarzenie online, do którego można uzyskać dostęp za pomoca łącza \"Uruchom wideo\".",
	EventDetailTab_Location_Message_WebBased: "Jest to wydarzenie online, do którego można  uzyskać dostęp za pomocą łącza \"Uruchom WBT\".",
	EventDetailTab_Location_Message_WebCast: "Jest to wydarzenie online, do którego można  uzyskać dostep za pomocą łącza \"Dolacz teraz\".",
	EventDetailTab_Link_Video: "Odtwórz wideo",
	EventDetailTab_Link_Video_Remark: "* Uruchomienie wideo otworzy nowe okno. Po zamknięciu okna zostaniesz przekierowany z powrotem na tę stronę.",
	EventDetailTab_Link_WebBased: "Uruchom WBT",
	EventDetailTab_Link_WebBased_Remark: "*Uruchomienie WBT otworzy nowe okno. Po zamknięciu okna zostaniesz przekierowany z powrotem na tę stronę.",
	EventDetailTab_Link_WebCast: "Dołącz teraz",
	EventDetailTab_Link_WebCast_Remark: "*Link Dołącz teraz bedzie aktywny 30 minut  przed rozpoczęciem wydarzenia.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Czas trwania (godz.)",
	EventDetailTab_Title_Trainers: "Trener(Trenerzy)",
	EventDetailTab_Title_Language: "Jezyk",
	EventDetailTab_Title_Score: "Wynik (%)",
	EventDetailTab_Title_Calendar: "Dodaj do kalendarza",
	EventDetailTab_Title_Certificate: "Pobierz certyfikat",
	EventDetailTab_Title_Messages: "Wiadomości",
	EventDetailTab_Title_StartDate: "Data rozpoczęcia",
	EventDetailTab_Title_AvailableFrom: "dostępne od",
	EventDetailTab_Title_Time: "Czas",
	EventDetailTab_Title_EndDate: "Data zakonczenia",
	EventDetailTab_Title_ExpiresOn: "Upływa w dniu",
	EventDetailTab_Title_Room: "Pokój",
	EventDetailTab_Title_Method: "metoda",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Lokalizacja",
	AttachmentTab_EmptyScreenDescription: "Brak załączników do tego wydarzenia.",
	AttachmentTab_Columns_Download: "Pobierz",
	AttachmentTab_Description: "Tutaj znajdziesz kilka ważnych informacji, które pomogą ci w tym kursie",
	EvaluationTab_Annotation: "Twoja opinia jest dla nas bardzo ważna. Prosimy o poświęcenie czasu na wypełnienie poniższej oceny, aby pomóc nam w ciagłym ulepszaniu naszych usług. Dziękujemy.",
	EvaluationTab_Notice: "Oceny sa następujące",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Przed przesłaniem oceny wydarzenia odpowiedz  na wszystkie obowiązkowe pytania",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Twoja opinia została przesłana pomyślnie",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Przesłanie oceny nie powiodło się",
	TrainingCatalogueLayout_ExportButtonText: "Eksportuj",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Ocena została zakończona lub wygasła.",
	EventDetailTab_Link_CopiedTooltip: "Skopiowane do schowka",
	EventDetailTab_QRCode_ExplanationText_WBT: "Skanuj, aby uruchomić WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Skanuj, aby uruchomić Wideo",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Skanuj, aby uruchomić Webcast",
	Footer_TrainingCoure_Headline: "Szkolenia oferowane przez firmę Bosch",
	Footer_TrainingCourse_Content: "Jako znana na całym świecie marka Superbrand  i mająca ponad stuletnie doświadczenie w dziedzinie innowacji, firma Bosch wyznacza światowe standardy jako dostawca systemów, części i rozwiązań technicznych dla motoryzacji. Duża część naszej siły pochodzi z bliskiej współpracy z producentami pojazdów, dla których projektujemy, rozwijamy i produkujemy najnowocześniejsze systemy i komponenty na całym świecie. W rezultacie firma Bosch ugruntowała swoją bogatą historię w zakresie produktów motoryzacyjnego rynku wtórnego i wsparcia, oferując zróznicowane portfolio części, rozwiazań diagnostycznych i usług warsztatowych. Nauka i rozwój są pewnymi kluczami do sukcesu, a filozofia ta opiera się na szerokiej gamie kursów szkoleniowych we wszystkich obszarach technologii motoryzacyjnej, systemów pojazdów i wiedzy diagnostycznej.",
	SearchField_NoRecordsFound: "Nic nie znaleziono",
	TrainingCatalogue_FreeTextSearch: "Dowolny tekst",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Szukaj",
	CheckoutLayout_WholeSalerGroup: "Wybierz grupę hurtowników",
	CreditInsufficientModal_Message: "Nie masz wystarczających środków na korzystanie z tej metody płatności. Spróbuj ponownie, korzystając z innej metody płatności.",
	ShoppingCart_Required_Credits: "Cena całkowita",
	MaintainingInfo_Message1: "Strona jest w trybie konserwacji.",
	MaintainingInfo_Message2: "Wrócimy wkrótce.",
	CountryValidationModal_Message: "Nie możesz zalogować sie do [CountryA], ponieważ Twoje konto zostało utworzone w [CountryB].",
	CountryValidationModal_ButtonText: "Przejdź do [Country]",
	EventTable_LoginRequired: "Zaloguj się, aby się zarejestrować",
	TrainingPartTableLayout_Date: "Data",
	TrainingPartTableLayout_StartTime: "Czas rozpoczęcia",
	TrainingPartTableLayout_EndTime: "Koniec czasu",
	TrainingPartTableLayout_Duration: "Czas trwania (godz.)",
	TrainingPartTableLayout_TrainingMethod: "Metoda szkolenia",
	MyCoursesTableContents_ActiveHeader: "Aktywne kursy",
	MyCoursesTableContents_ActiveTabHeader: "Aktywny",
	MyCoursesTableContents_ScheduledHeader: "Zaplanowane kursy",
	MyCoursesTableContents_ScheduledTabHeader: "Zaplanowany",
	MyCoursesTableContents_OnlineHeader: "Kursy online",
	MyCoursesTableContents_OnlineTabHeader: "online",
	MyCoursesTableContents_CancelledHeader: "Odwołane kursy",
	MyCoursesTableContents_CancelledTabHeader: "Odwołany",
	OnlineCoursesTable_Title_Progress: "Postęp",
	ScheduledCoursesTable_Title_Date: "Data [dni]",
	CoursesTable_Title_Completed: "Zakończony",
	Footer_TrainingProgram: "Programy szkoleniowe",
	Breadcrumb_TrainingProgram: "Programy szkoleniowe",
	TrainingProgram_Title_Table: "Tytuł",
	TrainingProgram_TrainingProgram: "Programy szkoleniowe",
	TrainingProgram_TargetGroup: "Grupa docelowa",
	TrainingProgram_MaxLength: "Czas trwania",
	TrainingProgram_Information: "Informacja",
	TrainingProgram_Detail: "Szczegóły",
	TrainingProgram_Modules: "Moduły",
	TrainingProgram_Course: "Kursy",
	TrainingProgram_PartNumber: "Numer części",
	TrainingProgram_Max_Length: "Maksymalna dlugość",
	TrainingProgram_Duration: "Czas trwania",
	TrainingProgram_Exam: "Badanie",
	TrainingProgram_Assign_Popup: "Zarejestruj się do programu szkoleniowego",
	TrainingProgram_Student: "Student",
	TrainingProgram_Assign: "Przypisz",
	TrainingProgram_Popup_Remove_Title: "Odwołanie treningu?",
	TrainingProgram_Description: "Opis",
	TrainingProgram_Days: "Dni",
	TrainingProgram_Type: "Typ",
	TrainingProgram_Action: "Działanie",
	TrainingProgram_Enrolled: "został już zapisany na to szkolenie",
	TrainingProgram_SelfAssign_Enrolled: "Jesteś już przypisany do tego programu  szkoleniowego",
	TrainingProgram_Warning: "Prosze zarejestrować kolejnego kursanta lub wybrać inny program szkolenia.",
	TrainingProgram_Workshop: "Warsztat",
	TrainingProgram_Durations: "lata",
	TrainingProgram_Duration_DaysLabel: "dni",
	TrainingProgram_HasExam_Yes: "Tak",
	TrainingProgram_HasExam_No: "NIE",
	AssignModal_SuccessModal_Header: "Gratulacje!",
	AssignModal_SuccessModal_Message: "został pomyślnie zapisany do tego programu szkoleniowego.",
	AssignModal_SelfAssign_SuccessModal_Message: "Zostałeś pomyślnie przydzielony do programu szkoleniowego.",
	Dashboard_MyTraningProgram: "Mój program szkoleniowy",
	Dashboard_MyTraningProgram_Title: "Tytuł",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Podgląd",
	Dashboard_MyTraningProgram_Register: "Rejestr",
	Dashboard_MyTraningProgram_Records: "Dane historyczne",
	Dashboard_MyTrainingProgram_In_Progress: "W trakcie",
	Dashboard_MyTrainingProgram_Accredited: "Akredytowany",
	Dashboard_Program_Modal_Withdraw: "Anuluj",
	Dashboard_Program_Modal_Grade: "Stopień",
	Dashboard_MyTrainingProgram_Registered: "Zarejestrowany",
	Dashboard_MyTrainingProgram_Completedd: "Zakończony",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Tak",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "NIE",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Należy pamietać, że rezygnacja oznacza rezygnację z pełnego programu szkoleniowego, a nie tylko z pojedynczego szkolenia.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Czy nadal chcesz zrezygnować z programu szkoleniowego?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "W trakcie potwierdzenia",
	WithdrawProgramModal_Withdraw_Success_Message: "Pomyślnie wycofałeś sie z programu szkoleniowego!",
	Common_Warning_Title: "Ostrzeżenie!",
	Common_Error_Title: "Ups, coś poszlo nie tak!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Dodaj do koszyka",
	AddEventFromCatalogToCartModal_HoursText: "godziny",
	AddElearningToCartSuccessModal_Message: "został dodany do Twojego koszyka!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Wybrany uczestnik został zarejestrowany na ten kurs lub kurs został już dodany do koszyka.",
	EventDetailTab_SubjectForChange_Message: "Temat do zmiany",
	Dashboard_MyTrainingProgram_Status_Failed: "Przegrany",
	Dashboard_MyTrainingProgram_Status_Passed: "Przeszedł",
	Dashboard_MyTrainingProgram_Status_Merit: "Zasługa",
	Dashboard_MyTrainingProgram_Status_Distinction: "Różnica",
	Dashboard_Employee_Courses: "Kursy",
	Dashboard_Employee_Program: "Program szkoleniowy",
	Dashboard_Employee_WaitList: "Lista oczekujących",
	Dashboard_Employee_Account: "Konto",
	Course_Price_Free: "-",
	Dashboard_Employee_Account_Update_Success_Message: "Pomyślnie zaktualizowałeś informacje o pracowniku!",
	Dashboard_Supplier: "Dostawca",
	Event_Detail_Comment: "Obecnie nie ma żadnych wiadomosci",
	DownLoad_PDF: "Pobierz plik PDF",
	TrainingCatalogue_Currency: "Waluta",
	My_Wallet: "Mój portfel",
	Wallet: "Portfel",
	Credits_Balance: "Saldo kredytów",
	MyFullCalendar_Today: "Dzisiaj",
	Header_Available_Languages: "dostępne języki",
	Header_CurrentRegion: "Jesteś obecnie w",
	Header_ChangeRegions: "Chcesz zmienić kraj?",
	Header_Anonymous: "Szkolenie serwisowe",
	Header_Anonymous_Login: "Zaloguj się/Zarejestruj się",
	Header_RegisterCourse: "Zarejestruj się na kurs",
	Homepage_Start: "Zarejestruj się",
	Homepage_Login: "Zaloguj się",
	MainPage_Title: "Najlepsze rozwiązanie warsztatowe",
	MainPage_Content: "Twój warsztat, Twój wybór.",
	MainPage_ImageTitle: "Bosch Automotive \n Szkolenia Techniczne",
	MainPage_IntroContent: "Umożliwiają nowoczesnemu warsztatowi profesjonalną, niezawodną i wydajną pracę w zakresie procedur diagnostycznych, usuwania usterek, napraw i obsługi wszystkich typów pojazdów.",
	BoschNews_TrainingNews: "Wiadomości szkoleniowe",
	Service_Assist_Workshop: "Udoskonal swoje doświadczenia dzięki aplikacji Asystent Serwisowy Warsztatu",
	Enhance_Title_CDM3_v2: "Co umożliwia aplikacja?",
	Enhance_Body_CDM3_Item_1: "Zarezerwuj kursy i kontynuuj swoją podróż edukacyjną",
	Enhance_Body_CDM3_Item_2: "Pobierz materiały szkoleniowe i certyfikaty kursów",
	Enhance_Body_CDM3_Item_3: "Uruchom kursy e-learningowe",
	Enhance_Body_CDM3_Item_4: "Uzyskaj dostęp do różnych usług motoryzacyjnych w aplikacji mobilnej",
	Enhance_Body_CDM3_Item_5: "Transmisja wideo na żywo z Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Darmowe narzędzie do automatycznego skanowania pojazdów",
	Training_Stories: "Historie szkoleniowe",
	SettingsMyAccount_JoinCompanyButton: "Dołacz do firmy",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Dołacz do firmy",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Wprowadz swój kod aktywacyjny poniżej",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Uwaga! Aby uzyskać kod aktywacyjny, należy skontaktować się z administratorem firmy.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Twoja aktywacja została pomyślnie przetworzona, a Twoje konto jest teraz powiązane z Twoją firmą.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Kliknij Kontynuuj, aby wejść na stronę.",
	ContinueButton: "Kontynuuj",
	WelcomeBatsModal_Title: "Twoje konto jest obecnie sprawdzane!",
	WelcomeBatsModal_ActivationLink: "Kliknij tutaj, jeśli masz kod aktywacyjny, aby dołączyć do firmy!",
	WelcomeBatsModal_ActivationLinkNotes: "Uwaga! Aby uzyskać kod aktywacyjny, należy skontaktować się z administratorem firmy.",
	AccountInReviewModal_Description_Paragraph1: "Dziękujemy za zarejestrowanie konta osobistego w Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Nasz oddany personel szkoleniowy aktualnie sprawdza i konfiguruje Twoje konto. Po pomyślnym skonfigurowaniu konta otrzymasz ostateczne potwierdzenie pocztą.",
	AccountInReviewModal_CloseButton: "Dobra, wpuść mnie!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Nie mogliśmy zweryfikowaćTwojego kodu aktywacyjnego i adresu e-mail.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Spróbuj ponownie lub skontaktuj sie z administratorem firmy w celu uzyskania pomocy.",
	TryAgain_Button: "Spróbuj ponownie",
	Error_Title: "Ups! Coś poszło nie tak!",
	SettingsMyProfileActivationSuccessModal_Title: "Powodzenie!",
	AccountInReviewModal_Description_Paragraph3: "W międzyczasie zachęcamy do przeglądania naszej witryny internetowej z ograniczonym dostępem.",
	AccountInReviewModal_Description_Paragraph4: "Jeśli masz jakies pytania lub jeśli Twoje konto nie zostanie przetworzone w ciagu 48 godzin z wyłączeniem weekendów i świąt, możesz skontaktowaś się z nami [tutaj]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Kilka wskazówek, które pomogą Ci...",
	Support_FAQ_Headline: "Czesto Zadawane Pytania",
	Support_FAQs_section1_title: "Ogólny",
	Support_FAQs_section1_question1: "Czym są rozwiązania szkoleniowe Bosch?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions to platforma zapewniająca kompleksowy przegląd szkoleń technicznych oraz umożliwiająca rezerwację szkoleń i zarzadząnie nimi.",
	Support_FAQs_section1_question2: "Czy mogę używać mojego istniejącego konta Bosch (singleKey ID) do logowania się do Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Tak, jest dostępny dla wszystkich kont utworzonych za pomocą \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Czy mogę używać konta utworzonego dla Bosch Training Solutions z innymi usługami lub aplikacjami Bosch?",
	Support_FAQs_section1_question3_answer1: "Tak, swojego osobistego identyfikatora Bosch ID możesz używać także w wielu innych zastosowaniach Bosch — zarówno zawodowych, jak i prywatnych, takich jak e-rower lub inteligentny dom.",
	Support_FAQs_section2_title: "Szkolenie",
	Support_FAQs_section2_question1: "Jak mogę zarezerwować szkolenie?",
	Support_FAQs_section2_question1_answer1: "Zaloguj się na istniejące konto LUB zarejestruj konto w portalu. Przejdź do \"Szkolenia\" LUB \"Harmonogram szkoleń\" wybierz szkolenie i kliknij przycisk dodaj do karty.",
	Support_FAQs_section2_question2: "Brak odpowiedniego terminu szkolenia. Co mogę zrobić?",
	Support_FAQs_section2_question2_answer1: "Masz możliwość wpisania się na „ogólną” listę oczekujacych (bez wybierania daty), a nasz zespół poinformuje Cię, gdy tylko pojawi się nowe szkolenie.",
	Support_FAQs_section2_question3: "Gdzie mogę znaleźć przegląd moich zarezerwowanych kursów?",
	Support_FAQs_section2_question3_answer1: "Możesz znaleźć pełny przegląd wszystkich zarezerwowanych i ukonczońych kursów po zalogowaniu się na pulpicie nawigacyjnym.",
	Support_FAQs_section3_title: "Ustawienia",
	Support_FAQs_section3_question1: "Jak mogę zmienić adres e-mail i hasło?",
	Support_FAQs_section3_question1_answer1: "Musisz zalogować się do portalu i przejść do swojego profilu osobistego. (Wewnątrz pulpitu nawigacyjnego możesz znaleźć informacje o swoim profilu lub klikając następujący przycisk w nagłówku",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Tam masz możliwość zmiany szczegółów swojego Bosch ID",
	Support_FAQs_section3_question2: "Co mogę zrobić, gdy zapomniałem hasła?",
	Support_FAQs_section3_question2_answer1: "Skorzystaj z przycisku zaloguj/zarejestruj się i podaj swój adres e-mail",
	Support_FAQs_section3_question2_answer2: "Jeśli klikniesz Kontynuuj, masz mozliwość kliknięcia \"Nie pamiętam hasła\"",
	Support_FAQs_section3_question3: "Jak założyć konto warsztatowe?",
	Support_FAQs_section3_question3_answer1: "Jeśli chcesz otrzymać rolę w warsztacie, skontaktuj się z lokalną administracją ds. szkoleń, np. możesz skorzystać z formularza kontaktowego na stronie.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Wybierz swój kraj/region",
	MaintenanceBanner_Message_1: "Portal Bosch Automotive Training Solutions zostanie zaktualizowany w dniu [maintenance_schedule]. Oczekuje się, że aktualizacja zajmie 30 minut. Portal nie będzie dostępny podczas aktualizacji.",
	MaintenanceBanner_Message_2: "\nAby uzyskać dalszą pomoc, skontaktuj się z nami przez e-mail [support_email]",
	MaintenancePage_Message_1: "Trwa aktualizacja portalu Bosch Automotive Training Solutions",
	MaintenancePage_Message_2: "Oczekujemy, że portal będzie ponownie dostępny w dniu [maintenance_online_date] o [maintenance_online_time]",
	MenuHeader_Support: "Wsparcie",
	Course_Detail_No_Prerequisites: "Brak wymagań wstępnych",
	TableCell_Hours: "godziny",
	Dashboard_MenuItem_Dashboard: "Panel",
	Dashboard_MenuItem_Curricula: "Programy nauczania",
	Dashboard_MenuItem_Certificates: "Certyfikaty",
	Dashboard_MenuItem_Team: "Zespół",
	Dashboard_MenuItem_Workshops: "Warsztaty",
	Dashboard_MenuItem_External: "Zewnętrzny",
	Dashboard_MenuItem_Wallet: "Portfel",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Twoje nadchodzące kursy",
	Dashboard_Headline_YourELearningCourses: "Twoje kursy e-learningowe",
	Dashboard_Headline_TeamOverview: "Przegląd zespołu",
	Dashboard_Headline_YourOverview: "Twój przegląd",
	Dashboard_Headline_YourWishlist: "Twoja lista życzeń",
	Dashboard_Headline_ActivePrograms: "Aktywne programy",
	Dashboard_Headline_CompletedPrograms: "Ukończone programy",
	Dashboard_Headline_YourCertificates: "Twoje certyfikaty",
	Dashboard_Headline_Transactions: "Transakcje",
	Dashboard_Table_NoRecordsToDisplay: "Brak danych do wyświetlenia",
	Dashboard_TableColumn_Location_Online: "online",
	Dashboard_TableColumn_Duration_Hours: "godziny",
	Dashboard_ELearningCourses_TableHeader_Title: "Tytuł",
	Dashboard_ELearningCourses_TableHeader_Duration: "Czas trwania (godz.)",
	Dashboard_ELearningCourses_ActionButton_View: "Podgląd",
	Dashboard_UpcomingCourses_TableHeader_Title: "Tytuł",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Data [dni]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Lokalizacja",
	Dashboard_UpcomingCourses_ActionButton_View: "Podgląd",
	Dashboard_YourOverview_TableHeader_Title: "Tytuł",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Data / Czas trwania",
	Dashboard_YourOverview_TableHeader_Location: "Lokalizacja",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Pogląd",
	Dashboard_YourWishlist_TableHeader_Title: "Tytuł",
	Dashboard_YourWishlist_TableHeader_Action: "Działanie",
	Dashboard_YourWishlist_ViewAllRecords: "Wyświetl wszystkie rekordy",
	Dashboard_TeamOverview_TableHeader_Employee: "Pracownik",
	Dashboard_TeamOverview_TableHeader_Title: "Tytuł",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Data / Czas trwania",
	Dashboard_TeamOverview_TableHeader_Location: "Lokalizacja",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Podgląd",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Szukaj",
	Dashboard_ActiveProgram_TableHeader_Title: "Tytuł",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Podgląd",
	Dashboard_CompletedProgram_TableHeader_Title: "Tytuł",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Podgląd",
	Dashboard_Certificates_TableHeader_Title: "Tytuł",
	Dashboard_Certificates_TableHeader_Date: "Data",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Dzialanie",
	Dashboard_Certificates_ActionButton_Download: "Pobierz",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Numer faktury",
	Dashboard_Transactions_TableHeader_Description: "Opis",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Metoda płatności",
	Dashboard_Transactions_TableHeader_Date: "Data",
	Dashboard_Transactions_TableHeader_Amount: "Kwota",
	Dashboard_Transactions_ActionButton_Download: "Pobierz",
	Breadcrumb_MyTraining: "Mój trening",
	Breadcrumb_MyTraining_CourseDetails: "Szczegóły kursu",
	Breadcrumb_MyTraining_Curricula: "Programy nauczania",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Szczegóły programu szkolenia",
	Breadcrumb_MyTraining_Team: "Zespół",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Zobacz pracownika",
	MyTraining_Headerbar_CourseDetails: "szczegóły kursu",
	MyTraining_Headerbar_TrainingProgramDetails: "Szczegóły programu szkolenia",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Ogólny postęp",
	TrainingProgramDetails_ProgramItems_Headline: "Elementy programu",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Tytuł",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Stopień",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Data",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Podgląd",
	TrainingProgramDetails_Status_InProgress: "W trakcie",
	TrainingProgramDetails_Status_Completed: "Zrealizowany",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Czas trwania",
	CourseDetails_Label_Trainer: "Trener",
	CourseDetails_Label_Language: "Język",
	CourseDetails_Label_Score: "Wynik:",
	CourseDetails_Label_StartDate: "Data rozpoczęcia",
	CourseDetails_Label_EndDate: "Data końcowa",
	CourseDetails_Label_Venue: "Lokal",
	CourseDetails_Label_Address: "Adres",
	CourseDetails_Label_Grade: "Stopień",
	CourseDetails_Action_Withdraw: "Anuluj",
	CourseDetails_Action_Evaluate: "Oceń kurs",
	CourseDetails_Action_Evaluate_Description: "Oceń ten kurs, aby wygenerować certyfikat!",
	CourseDetails_Action_DownloadCertificate: "Pobierz certyfikat",
	CourseDetails_Action_JoinWebcast: "Dołacz do transmisji internetowej",
	CourseDetails_Action_StartCourse: "Rozpocznij kurs",
	CourseDetails_Action_RestartCourse: "Uruchom ponownie kurs",
	CourseDetails_Action_RepeatCourse_Description: "Powtórz i zalicz ten kurs, aby pobrać certyfikat!",
	CourseDetails_PartSpecificDetails_Headline: "Szczegóły dotyczące części",
	CourseDetails_PartSpecificDetails_Tab_Part: "Część",
	CourseDetails_PartSpecificDetails_Description: "Opis",
	CourseDetails_PartSpecificDetails_StartDate: "Data rozpoczęcia",
	CourseDetails_PartSpecificDetails_EndDate: "Data końcowa",
	CourseDetails_PartSpecificDetails_StartTime: "Czas rozpoczęcia",
	CourseDetails_PartSpecificDetails_EndTime: "Koniec czasu",
	CourseDetails_PartSpecificDetails_Method: "metoda",
	Dashboard_Team_TableHeader_Name: "Nazwa",
	Dashboard_Team_TableHeader_Role: "Rola",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Konto osobiste",
	Dashboard_Team_ActionButton_Select: "Wybierz",
	Dashboard_Team_PersonalAccount_Activated: "Aktywowany",
	Dashboard_Team_PersonalAccount_NotActivated: "Nieaktywowany",
	Dashboard_Team_ActionButton_AddMember: "Dodaj członka",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Szukaj",
	Dashboard_External_TableHeader_Name: "Nazwa",
	Dashboard_External_ActionButton_AddNew: "Dodaj nowe",
	Dashboard_External_Headline: "Zatwierdzony dostęp zewnętrzny",
	Dashboard_Workshops_Headline: "Warsztaty",
	Dashboard_Workshops_TableHeader_Company: "Firma",
	Dashboard_Workshops_TableHeader_Address: "Adres",
	Dashboard_Workshops_TableHeader_Postcode: "Kod pocztowy",
	Dashboard_Workshops_TableHeader_City: "Miasto",
	Dashboard_Workshops_ActionButton_Select: "Wybierz",
	Wallet_ServiceCredits: "Kredyty serwisowe",
	Wallet_Headline_CreditBalance: "Saldo kredytowe",
	Wallet_Headline_CreditActivity: "Działalność kredytowa",
	Wallet_Headline_BillingTransactions: "Transakcje rozliczeniowe",
	Wallet_BillingTransaction_TableHeader_Order: "Zamówienie",
	Wallet_BillingTransaction_TableHeader_Date: "Data",
	Wallet_BillingTransaction_TableHeader_Amount: "Kwota",
	Wallet_BillingTransaction_TableHeader_Action: "Działanie",
	Wallet_CreditActivity_TableHeader_Date: "Data",
	Wallet_CreditActivity_TableHeader_Description: "Opis",
	Wallet_CreditActivity_TableHeader_User: "Użytkownik",
	Wallet_CreditActivity_TableHeader_Amount: "Kwota",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, wszelkie prawa zastrzezone",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Bezpieczeństwo produktów (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innowacje, patenty i licencje",
	FooterAdditionalLinks_Navigation_Logistics: "Zakupy i logistyka",
	MenuHeader_MyTraining: "Moje szkolenie",
	MenuHeader_TrainingServices: "Szkolenia",
	MenuHeader_OurLocations: "Lokalizacja",
	MenuHeader_ContactsUs: "Skontaktuj się z nami",
	MenuHeader_Logout: "Wyloguj",
	MenuHeader_FAQ: "Często zadawane pytania",
	MyProfile_MyBoschId: "Mój identyfikator Bosch",
	MyProfile_AccountDeletion: "Usuwanie konta",
	MyProfile_Edit: "Zmień adres e-mail lub edytuj haslo",
	MyProfile_Profile: "Mój Bosch",
	MyProfile_EditUserProfile: "Edytuj profil użytkownika",
	BackButton: "Wróc",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Czekamy na Twój telefon i chętnie Ci pomożemy. Pracujemy od poniedziałku do piątku w godzinach od 8:00 do 16:00",
	ContactUs_Email_SecurityCodeConfirm_V3: "Wprowadź prawidłowe znaki, jak wskazano w polu powyżej.",
	ContactUs_Email_MandatoryNotice_V3: "Wypełnij wszystkie wymagane pola.",
	ContactUs_Email_SendButton: "Wyślij zaproszenie",
	Breadcrumb_TrainingCenters: "Centrum Szkoleniowe",
	Training_Centres_Book_Btn: "Zarezerwuj kurs",
	Training_centres_booking_code: "Kod rezerwacji",
	Training_centers_header_bar_title: "Nasze centra szkoleniowe",
	Training_centers_filter_item: "Centrum",
	TeamEdit_PersonalAccountToolTop: "Włączenie tej funkcji umożliwia pracownikowi dostep do tej witryny. Użytkownik będzie mógł utworzyć konto osobiste i być częścią konta firmowego. Po włączeniu możesz wysłać pracownikowi zaproszenie z kodem aktywacyjnym. Zaproszenie zostanie wysłane na adres e-mail.",
	TeamEdit_Update: "Włączenie funkcji administratora firmy automatycznie zapewni temu pracownikowi pełny dostęp do Twojego konta firmowego. Włączenie tej funkcji wymaga wylogowania się użytkownika i ponownego wejścia do serwisu, jeśli jest już zalogowany.",
	TeamEdit_PersonalAccount: "Zezwól na osobiste konto internetowe Bosch Training Solutions",
	TeamEdit_SendInvitation: "Wyślij zaproszenie",
	TeamEdit_Deactivate: "Dezaktywuj",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Telefon komórkowy musi zawierać cyfry",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telefon musi zawierać cyfry",
	TeamEdit_Activate: "Aktywny",
	TeamEdit_NotActivated: "Nie aktywuj",
	TeamEdit_StatusPopup: "Status zostanie wyświetlony jako „Aktywny”, jeśli użytkownik pomyślnie utworzył konto osobiste. Jeśli status jest ustawiony jako „Nieaktywne”, oznacza to, że użytkownik nie zaakceptował Twojego zaproszenia.",
	Training_Catalog_Courses: "kursy",
	Training_Catalog_Course: "Kurs",
	Reset_Filter: "Zresetuj filtr",
	TrainingCatalogue_CourseCatalogue: "Katalog kursów",
	CheckoutHeader_CartItemCount_Text: "Masz [count] pozycji w koszyku",
	CheckoutHeader_CartItemsCount_Text: "Masz [count] przedmiotów w koszyku",
	ShoppingCart_PriceItem: "Cena przedmiotu",
	ShoppingCartItemParticipants_AddParticipant_Button: "Dodaj uczestników",
	ShoppingCartTotalPrice_SubTotalLabel: "Suma częściowa",
	ShoppingCartTotalPrice_TaxesLabel: "podatki",
	ShoppingCartTotalPrice_TotalLabel: "Całkowity",
	ShoppingCartTotalPrice_IncludedVATLabel: "Obejmuje podatek VAT w wysokości [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Dostępne metody płatności online",
	ShoppingCartItemRow_VenueFeeLabel: "Opłata za miejsce [fee_amount] na uczestnika",
	ShoppingCartItemRow_OnlineLabel: "online",
	AddParticipantsModal_Title: "Dodaj uczestnika",
	AddParticipantsModal_SelectEmployeeLabel: "Dodaj uczestnika",
	AddParticipantsModal_SelectWorkshopLabel: "Wybierz Warsztat",
	ShoppingCartTotalPrice_ContinueButtonText: "Kontynuuj żeby sprawdzić",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Uczestnik nie został dodany!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Masz w koszyku jeden lub więcej kursów bez wskazanego uczestnika.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Dodaj uczestnika, aby kontynuować realizację transakcji.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Edytuj uczestników ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nazwa",
	AddParticipantsModal_ParticipantTable_Email: "E-mail",
	AddParticipantsModal_ParticipantTable_Action: "Działanie",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Brak uczestników…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Adres e-mail nie został skonfigurowany dla wybranego uczestnika",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Wybrany uczestnik nie ma skonfigurowanego adresu e-mail w swoim profilu. Adres e-mail jest niezbędny do rejestracji na szkolenie.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Dodaj adres e-mail, aby kontynuować lub anuluj, aby wybrać innego uczestnika.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Dodaj e-mail",
	CancelButton: "Anuluj",
	AddParticipantsModal_AddEmployeeButtonText: "Dodaj pracownika",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Zamierzasz usunąć kurs z koszyka",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Próbujesz usunąć kurs z dodanymi uczestnikami z koszyka.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Kliknij \"Kontynuuj\", jeśli chcesz kontynuować lub kliknij \"Anuluj\".",
	ShoppingCartItemHeader_Participants: "Uczestnik (Uczestnicy)",
	ShoppingCartItemHeader_ParticipantPrice: "Cena za miejsce",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Pokaż w [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Pokaż w kredytach",
	ShoppingCart_CreditsPriceUnit: "Kredyty",
	CheckOutHeader_Step1_Question: "Jak chciałbyś zapłacić?",
	CheckOutHeader_Step2_Question: "Sprawdź swoje zamówienie",
	CheckOutHeader_Step3_Question: "Dokończ swoje zamówienie",
	CheckOutHeader_Step4_Question: "Twoja rezerwacja powiodła się!",
	CheckOutHeader_Step_Payment: "Płatność",
	CheckOutHeader_Step_Review: "Recenzja",
	CheckOutHeader_Step_Place_Order: "Zlóż zamówienie",
	CheckOutRightPanel_Review_Order_Btn: "Przegląd zamówienia",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Zlóż zamówienie",
	CheckoutRightPanelContent_OrderSummary_Title: "podsumowanie zamówienia",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Edytuj koszyk",
	ShoppingCart_Participants: "Uczestnicy",
	CheckOutStepPayment_Payment_Methods_Title: "Wybierz Metodę Płatności",
	CheckOutStepPayment_Credit_Title: "Kredyt lub debet",
	CheckOutStepPayment_Wholesaler_Title: "Hurtownik",
	CheckOutStepPayment_ServiceCredit_Title: "Kredyty serwisowe",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Masz [available_credit] kredytów",
	CheckOutStepPayment_BoschAccount_Title: "Konto Bosch",
	Checkout_Second_PaymentMethod_Headline: "Metoda płatności",
	CheckOutStepReview_Edit: "Edytuj",
	CheckOutStepReview_Terms_Label: "Regulamin i zasady rezygnacji",
	CheckOutStepReview_Terms_Text: "Przeczytałem, zrozumiałem i zgadzam się na [warunki i zasady anulowania] sprzedaży.",
	SelectSecondPaymentMethodModal_Title: "Wybierz dodatkową metodę płatności",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Zapłać[amount]",
	StepPaymentWholesaler_Title: "Wybierz hurtownie",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Zaakceptuj regulamin",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Przed kontynuowaniem przeczytaj i zaakceptuj nasze warunki i zasady rezygnacji.",
	CheckoutDescription_ThankYou: "Dziękujemy za złożenie zamówienia na Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Nasze bity i bajty juz rozpoczęły przetwarzanie Twojego zamówienia. W międzyczasie prosze utrzymywać silnik na biegu jałowym.",
	CheckoutDesctiption_Text: "Wyślemy Ci ostateczne potwierdzenie mailem, zawierąjace wszystkie szczegóły Twojej rezerwacji.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Zamierzasz usunąć wybrany warsztat",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Usunięcie wybranego warsztatu spowoduje zresetowanie wszystkich dodanych uczestników. Ta zmiana wpłynie na wszystkie kursy w Twoim koszyku.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Kliknij \"Kontynuuj\", jeśli chcesz kontynuować lub kliknij \"Anuluj\", aby zatrzymać tę akcję.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Masz niewystarczającą liczbę kredytów. Twoje saldo kredytów wynosi [available_credit] kredytów, ale potrzebujesz [cart_total_credits] kredytów, aby kontynuować realizacjeę transakcji. Wybierz inną metodę płatności lub usuń pozycje z koszyka.",
	Training_centers_filter_items: "Centra",
	Training_Programs_Filter_Item: "programy",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "Rok (lata)",
	Training_Program_Detail_Title: "Programy kursów",
	AccountButton_Workshops_MenuItem: "Warsztaty",
	AccountButton_Wallet_MenuItem: "Portfel",
	Header_MyBoschProfile_Header: "Mój profil Bosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Dostępny",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Dodaj/edytuj uczestnika",
	ShoppingCart_CreditPriceUnit: "Kredyt",
	Common_IncludedVAT: "zaw. VAT",
	TrainingCatalogue_Length: "Długość",
	TrainingCatalogue_Method: "metoda",
	Course_Price_Credits: "Kredyty",
	Catalogue_Grid_Register_Button: "Rejestr",
	Catalogue_Credit_Tilte: "Kliknij tutaj, aby zobaczyć ceny w kredytach",
	Catalogue_Price_Tilte: "Kliknij tutaj, aby wyswietlić ceny w EUR",
	Catalogue_Grid_Vat: "bez VATL.",
	EventCalendar_Seats: "Miejsca",
	MenuHeader_Calendar: "Kalendarz",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Minuty",
	ListFilter_NoResult: "Brak wyników",
	Course_Description: "Opis kursu",
	Training_course_detail: "Podstawy mediów społecznościowych",
	Training_course_detail_add_waiting: "Dodaj do ogólnej listy oczekujących",
	Register_now_btn: "Zarejestruj się teraz!",
	Register_now_btn_add_event_to_cart: "Zarejestruj się teraz!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Wyświetl wszystkie rekordy",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Zobacz mniej",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Wyświetl wszystkie rekordy",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Zobacz mniej",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Wyświetl wszystkie rekordy",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Zobacz mniej",
	TeamCreate_TitleError: "Tytuł jest wymagany",
	Dashboard_Headerbar_BookCourse: "Zarezerwuj kurs",
	Dashboard_Headerbar_ExitProxy: "Wyjdź z proxy",
	Dashboard_Headerbar_EvaluateCourse: "Oceń kurs",
	Dashboard_Headerbar_EvaluateCourse_Note: "Oceń ten kurs, aby wygenerować certyfikat!",
	Dashboard_Headerbar_DownloadCertificate: "Pobierz Certyfikat",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Anuluj",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Anuluj",
	Dashboard_Headerbar_WelcomeMessage: "Witamy w panelu edukacyjnym",
	Dashboard_Headerbar_ProxingAs: "Pełnomocnik jako",
	Dashboard_TrainingProgram_Status_Completed: "Zakończony",
	Dashboard_TrainingProgram_Status_InProgress: "W trakcie",
	External_SelectWholesalerModal_Title: "Dodaj dostęp użytkownika zewnętrznego",
	External_SelectWholesalerModal_Company_Label: "Firma",
	External_SelectWholesalerModal_Outlet_Label: "Wyprzedaż",
	External_SelectWholesalerModal_Company_Placeholder: "Prosze wybrać",
	External_SelectWholesalerModal_Outlet_Placeholder: "Prosze wybrać",
	External_SelectWholesalerModal_ApplyButton: "Zastosuj",
	External_SelectWholesalerModal_CancelButton: "Anuluj",
	External_RemoveWholesalerModal_ConfirmLabel: "Potwierdzenie",
	External_SelectWholesalerModal_ConfirmMessage: "Czy na pewno chcesz usunąć tego hurtownika?",
	External_SelectWholesalerModal_YesButton: "Tak",
	External_SelectWholesalerModal_NoButton: "NIE",
	Training_program_team_member: "Członek zespołu",
	Training_program_assign_training_program: "Przypisz program szkolenia",
	Assign_modal_student_confirmation_text1: "Zamierzasz przypisać [Training Program Name] do swojej scieżki edukacyjnej.",
	Assign_modal_student_confirmation_text2: "Czy chciałbyś kontynuować?",
	MyFullCalendar_Jan: "styczeń",
	MyFullCalendar_Feb: "luty",
	MyFullCalendar_Mar: "marzec",
	MyFullCalendar_Apr: "kwiecień",
	MyFullCalendar_May: "maj",
	MyFullCalendar_Jun: "czerwiec",
	MyFullCalendar_Jul: "lipiec",
	MyFullCalendar_Aug: "sierpień",
	MyFullCalendar_Sep: "wrzesień",
	MyFullCalendar_Oct: "październik",
	MyFullCalendar_Nov: "listopad",
	MyFullCalendar_Dec: "grudzień",
	MenuHeader_CourseCatalog: "Katalog kursów",
	MenuHeader_Event: "Harmonogram szkoleń",
	MenuHeader_Program: "Program Szkoleń",
	MenuHeader_Services: "Szkolenia",
	MenuHeader_Log_out: "Wyloguj",
	Filter_Location: "Lokalizacja",
	TeamEdit_UpgradeToCompanyAdministrator: "Uaktualnij do administratora firmy",
	TeamEdit_PersonalWebAccountStatusIs: "Stan osobistego konta internetowego to",
	Header_CreateNewEmployee: "Utwórz nowego pracownika",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Brak wolnych miejsc!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Brak wolnych miejsc na wybrane wydarzenie.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Wybierz inne wydarzenie lub usuń dodanego już uczestnika, aby kontynuować.",
	Common_SuccessModal_Title: "Gratulacje!",
	TrainingProgramDetails_Merit_StatusText: "Osiągnięcia",
	TrainingProgramDetails_Resit_StatusText: "Powtórz",
	EmployeeProfile_UserExistErrorMessage: "Konto już istnieje!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Płatność nie powiodła się. Spróbuj ponownie lub użyj innej metody płatności.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Pomyślnie zdezaktywowałeś wybranego pracownika!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Zapisane!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Twoje zmiany zostały zapisane.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Rezynacja nie jest mozliwa w kursach eLearningowych. Skontaktuj się z nami, jeśli potrzebne są dalsze szczegóły.",
	Dashboard_Team_PersonalAccount_Pending: "Aż do",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Utworzono pracownika!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Tworzenie pracownika zostało zakończone pomyślnie.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "To ustawienie umożliwia ograniczenie pracownikom możliwości rejestrowania się na szkolenia. Tylko administratorzy firmy będą mogli rejestrować pracownika na szkolenia, gdy to ustawienie zostanie odznaczone.",
	Training_catalog_sort_by: "Sortuj według",
	CourseDetail_EvaluationRestriction_Title: "Ocena szkolenia nie jest możliwa!",
	CourseDetail_EvaluationRestriction_Message: "Oceny szkoleń mogą być wystawiane wyłącznie przez samego kursanta. \nPoinstruuj ucznia, aby zalogował się do Bosch Automotive Training Solutions i ukonczył ocenę szkolenia.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Jeśli uczestnik nie ma jeszcze konta, zaproszenie można udostępnić w ustawieniach profilu użytkownika członka zespołu.",
	Breadcrumb_Download_Page: "Pobierz",
	Download_Table_Category_Cell: "Kategoria",
	Download_Table_Title_Cell: "Tytuł",
	Download_File_Filter_Item: "Plik",
	Download_File_Filter_Items: "Pliki",
	Download_Header: "Pobierz zakres",
	DownloadArea_Button_Download: "Pobierz",
	Popup_Title_Error: "Błąd",
	Popup_Title_Success: "Powodzenie",
	Popup_Title_Info: "Informacje",
	Popup_Title_Warning: "Ostrzeżenie!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Rejestracje na potencjalne wydarzenia, które już istnieą, nie zostaną anulowane i bedą musiały zostać anulowane indywidualnie.",
	TrainingProgram_PDF_Export: "Eksport PDF",
	TrainingProgram_PDF_Export_Participant: "Uczestnik:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Ogólny postęp:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Tytuł szkolenia",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Stopień",
	TrainingProgram_PDF_Export_TableHeader_Date: "Data",
	AccountButton_CompanyChange_MenuItem: "Zmiana firmy",
	SelectCompanyModal_Title: "Wybierz firmę",
	CloseButton: "Zamknij",
	Change_Button: "Zmień",
	ChangeManagerModal_Content: "Wybierz użytkownika",
	ChangeCompanyModal_HeadOffice: "(Główne biuro)",
	SelectCompanyModal_Content: "Wybierz firmę, aby się zalogować…",
	AlertSuccess_Title: "Powodzenie",
	CheckOutStepPayment_SecondaryPayment_Title: "Wybierz dodatkową metodę płatności",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Dodaj dodatkową metodę płatności",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Dodaj poniżej dodatkową metodę płatności lub wybierz inną metodę płatności powyżej.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Nie masz wystarczającej liczby Kredytów Usług na swoim koncie, aby zapłacić za pozycje w koszyku. Aby móc dalej korzystać z wybranej metody płatności, należy dopłacić różnicę. Różnica wynosi [difference_amount] (bez VAT).",
	ShoppingCartTotalPrice_TotalInCredit: "Całkowita cena w kredytach",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Twoje dostępne środki",
	ShoppingCartTotalPrice_YourDifference: "Różnica",
	ShoppingCartTotalPrice_SubtotalExTax: "Suma częściowa z wył. podatku",
	ShoppingCartTotalPrice_SubtotalInTax: "Suma częściowa łacznie z podatkiem",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Dodatkowa metoda płatności",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Edytuj metodę płatności",
	Checkout_Second_PaymentMethod: "Druga metoda płatności",
	Breadcrumb_TrainingCourseDetails: "Szczegóły szkolenia",
	DownloadArea_FailedToDownload_ErrorMessage: "Nie udało się pobrać dokumentu. Spróbuj ponownie lub skontaktuj sie z administratorem firmy w celu uzyskania pomocy.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Plik do pobrania jest niedostępny. Spróbuj ponownie lub skontaktuj się z administratorem firmy w celu uzyskania pomocy.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Pobieranie pliku jest zabronione. Spróbuj ponownie lub skontaktuj się z administratorem firmy w celu uzyskania pomocy.",
	SSO_Redirecting_Message: "Przekierowanie do portalu Bosch Training...",
	SSO_Redirecting_ParameterErrorMessage: "Kraj i/lub jezyk nie są określone. Spróbuj ponownie lub skontaktuj się z administratorem firmy w celu uzyskania pomocy.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Prośby o rejestrację nie zostały przyjęte.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Może to wynikać z faktu, że minął próg rejestracji jednego lub większej liczby kursów. Jeśli masz jakieś pytania lub prośby, skorzystaj z obszaru „Skontaktuj siś z nami”.",
	TimeSlotPicker_DateFormat_Title: "Format daty",
	UpdateAccountSettingsConfirmationModal_Title: "Zamierzasz zmienić format daty!",
	UpdateAccountSettingsConfirmationModal_Message1: "Format daty zostanie wyświetlony zgodnie z dokonanym wyborem i można go w każdej chwili zmienić.",
	UpdateAccountSettingsConfirmationModal_Message2: "Czy chcesz kontynuować?",
	ProfileSummary_Settings_Title: "Ustawienia",
	UpdateAccountSettings_DateFormat_Title: "Ustawienia formatu daty",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Nie udało się zaktualizować formatu daty.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Ustawienie formatu daty zostało pomyślnie zaktualizowane.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Pobierz Certyfikat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Certyfikat Programu Szkoleniowego",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Pobieranie nieudane. Spróbuj ponownie. Jesli problem nadal występuje, prosimy o kontakt poprzez formularz kontaktowy.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Przekroczono próg rezygnacji",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Próg rezygnacji zostal przekroczony, rezygnacja ze szkolenia wymaga ręcznego przetworzenia przez Administratora.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Ostateczne potwierdzenie zostanie wysłane e-mailem.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Prosimy zapoznać się z naszą [polityka anulowania] w celu uzyskania dalszych szczegółów.",
	TrainingCourse_Course_NotAvailable_Title: "Szkolenie jest niedostępne",
	TrainingCourse_Course_NotAvailable_Message: "Szkolenie jest niedostępne. Prosimy spróbować ponownie lub skontakowćc się z Centrum Szkoleniowym w celu uzyskania dalszej pomocy.",
	TrainingCourse_NotAvailable_Message_1: "Próbujesz obejrzeć szkolenie, które jest niedostępne.",
	TrainingCourse_NotAvailable_Message_2: "Prosze wrócić do naszego [katalogu szkoleń], aby znaleźć wszystkie dostępne kursy.",
	LanguageSelection_SelectALanguage: "Wybierz język",
	LanguageSelection_SelectYourCountry: "Wybierz kraj",
	Checkout_PaymentMethod_Headline: "Metoda płatności",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalendarz",
	Download_Categories_General: "Podstawowe informacje",
	Download_Categories_Information: "Wiadomości",
	Download_Categories_Userguides: "Przewodnik użytkownika",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "zasady rezygnacji",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Anuluj",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Kontynuuj",
	Duration_Seconds: "Sekundy",
	Duration_Day: "Dzień",
	Duration_Hour: "Godzina",
	Duration_Minute: "Minuta",
	Duration_Second: "Sekunda",
	EventCalendar_ExternalLink_Tooltips: "Zakup za pośrednictwem [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/d",
	EventCalendar_ExternalLink_Cancel: "Anuluj",
	EventCalendar_ExternalLink_Continue: "Kontynuuj",
	EventCalendar_ExternalLinkModal_Title: "Za chwilę nastąpi przekierowanie...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Wybrane wydarzenie jest sprzedawane za pośrednictwem [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Kontynuacja spowoduje przekierowanie do zewnętrznej strony internetowej.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Czy chciałbyś/łabyś kontynuować?",
	Team_ContactPerson_FirstName_Tooltips: "Zmiany uczestników szkoleń mogą być dokonywane wyłącznie przez Administratora. Prosimy skontaktować się z nami za pośrednictwem formularza kontaktowego dostępnego na tej stronie internetowej, aby uzyskać dalsze wsparcie.",
	Team_ContactPerson_LastName_Tooltips: "Zmiany uczestników szkoleń mogą być dokonywane wyłącznie przez Administratora. Prosimy skontaktować się z nami za pośrednictwem formularza kontaktowego dostępnego na tej stronie internetowej, aby uzyskać dalsze wsparcie.",
	Withdraw_ExternalLink_Cancel: "Anuluj",
	Withdraw_ExternalLink_Confirm: "Potwierdź",
	Withdraw_ExternalLinkModal_Title: "Za chwilę nastąpi przekierowanie.",
	Withdraw_ExternalLinkModal_Message: "Rezygnacje z tego kursu są obsługiwane przez zewnętrznego dostawcę.",
	Withdraw_ExternalLinkModal_Confirmation: "Kliknięcie przycisku \"Potwierdź\" spowoduje otwarcie nowej karty i przekierowanie na zewnetrzną stronę internetową.",
	Button_AddToWaitingList_Tooltips: "Dodaj do listy oczekujących",
	Button_AddToCart_Tooltips: "Dodaj do koszyka",
	Button_TrainingCourseDetail_Tooltips: "Zobacz szczegóły",
	Button_TrainingProgramDetail_Tooltips: "Zobacz szczegóły",
	Button_AssignTrainingProgram_Tooltips: "Przypisz program szkoleniowy",
	Button_DeleteFromCart_Tooltips: "Usuń z koszyka",
	Button_RemoveParticipant_Tooltips: "Usuń uczestnika",
	Button_DownloadBillingTransaction_Tooltips: "Pobierz",
	Button_RemoveExternal_Tooltips: "Usuń"
};