import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Dropdown, Grid, Message, Modal } from "semantic-ui-react";
import { Constants } from "../../helpers/Constants";
import { TrainingEventHelper } from "../../helpers/TrainingEventHelper";
import { ContactCompany } from "../../models/Account";
import { ContactPersonModel } from "../../models/ContactPerson.Model";
import {
  TrainingCourse,
  TrainingEvent,
  Venue,
} from "../../models/TrainingEvent";
import {
  AddToWaitListRequestModel,
  DeleteFromWaitListRequestModel,
  WaitListItem,
} from "../../models/Waitlist.Model";
import {
  getAccountExtendedAsync,
  selectCiamId,
  selectCustomerId,
  selectEmployeeId,
  selectUserRole,
} from "../../redux-toolkit/accountReducer";
import {
  selectCountryCode,
  selectLanguage,
} from "../../redux-toolkit/authReducer";
import { getContactPersonsAsync } from "../../redux-toolkit/contactReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { getTrainingVenuesAsync } from "../../redux-toolkit/trainingCenterReducer";
import {
  addToWaitListAsync,
  deleteFromWaitListAsync,
  getWaitListByParticipantIdAsync,
} from "../../redux-toolkit/waitlistReducer";
import formatShortDate from "../../utils/dateTime";
import Boschicon from "../BoschIcon/Boschicon";
import "./WaitlistPopup.css";
import RDAlert from "../RDComponents/RDAlert";
interface WaitlistPopupProps {
  courseItem: TrainingCourse;
  eventItem: TrainingEvent | null;
  isOpen: boolean;
  setIsOpen: Function;
  addedItemSuccess?: Function;
  className?: string;
}
const WaitlistPopup: FC<WaitlistPopupProps> = (props) => {
  const { t } = useTranslation();
  const [isShowError, setIsShowError] = useState<boolean>(false);
  //const [isOpen, setIsOpen] = useState<boolean>(true);
  const [courseEvents, setCourseEvents] = useState<any>([]);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(0);

  const [workshops, setWorkshops] = useState<any>([]);
  const [selectedWorkshop, setSelectedWorkshop] =
    useState<ContactCompany | null>(null);

  const [venues, setVenues] = useState<Venue[]>([]);
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);

  const [students, setStudents] = useState<any>([]);
  const [selectedStudent, setSelectedStudent] =
    useState<ContactPersonModel | null>(null);
  const [isAddingWaitListItem, setIsAddingWaitListItem] =
    useState<boolean>(false);

  const [
    isShowingDeleteWaitListConfirmPopup,
    setIsShowingDeleteWaitListConfirmPopup,
  ] = useState<boolean>(false);
  const [removeWaitlistItem, setRemoveWaitlistItem] =
    useState<WaitListItem | null>(null);
  const [isRemovingWatchlist, setIsRemovingWatchlist] =
    useState<boolean>(false);
  const [openSucessPopup, setOpenSucessPopup] = useState<boolean>(false);

  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const customerId = useSelector(selectCustomerId);
  const employeeId = useSelector(selectEmployeeId);
  const userRole = useSelector(selectUserRole);
  const userLanguage = useSelector(selectLanguage);
  const userCountry = useSelector(selectCountryCode);

  useEffect(() => {
    setIsShowError(false);
    setIsAddingWaitListItem(false);
    setSelectedEventId(0);
    populateCourseList();
    populateVenueList();

    initVariablesByRole();
  }, [props.courseItem]);

  const initVariablesByRole = () => {
    switch (userRole) {
      case Constants.USER_ROLE_WHOLESALER:
        setSelectedWorkshop(null);
        setSelectedStudent(null);
        populateWorkshopList();
        break;

      case Constants.USER_ROLE_MANAGER:
        const workshop = {
          id: customerId || 0,
        } as ContactCompany;
        setSelectedWorkshop(workshop);
        setSelectedStudent(null);
        populateStudentList(workshop);
        break;

      default:
        setSelectedWorkshop({
          id: customerId || 0,
        } as ContactCompany);
        setSelectedStudent({
          ciamId: ciamId || "",
          customerId: customerId || 0,
          id: employeeId || 0,
        } as ContactPersonModel);
        break;
    }
  };

  const populateCourseList = () => {
    if (
      props.courseItem?.trainingEvents &&
      props.courseItem.trainingEvents.length > 0
    ) {
      //TODO: Filter the course which has availableset <= 0
      const courseEventList = props.courseItem.trainingEvents
        ?.filter((x) => x.availableSeats <= 0)
        .map((x) => {
          return {
            key: x.eventId,
            text: `${formatShortDate(x.startDate?.toString())}, ${
              x.trainingLength
            } ${t("TrainingCatalogue_Days")}, ${TrainingEventHelper.getLocation(
              x,
              userCountry
            )}`,
            value: x.eventId,
          };
        });
      courseEventList.unshift({
        key: 0,
        text: t("WaitlistPopup_GeneralWaitList"),
        value: 0,
      });
      setCourseEvents(courseEventList);
      if (
        props.eventItem &&
        courseEventList.find((x) => x.key === props.eventItem?.eventId)
      ) {
        setSelectedEventId(props.eventItem.eventId);
      }
    } else {
      setCourseEvents([]);
    }
  };

  const populateWorkshopList = () => {
    if (ciamId) {
      let userProfileRequest = dispatchReduxToolkit(
        getAccountExtendedAsync(ciamId)
      ).unwrap();
      userProfileRequest.then((res) => {
        let workshopRes = res?.dataObject
          ?.associatedCompanies as ContactCompany[];
        let workshopList: any = [];
        if (workshopRes) {
          workshopList = workshopRes.map((x) => {
            return {
              key: x.id,
              text: `${x.name}`,
              value: x,
            };
          });
        }

        setWorkshops(workshopList);
      });
    } else {
      setWorkshops([]);
    }
  };

  const populateVenueList = () => {
    let venueResponse = dispatchReduxToolkit(
      getTrainingVenuesAsync({
        ciamId: ciamId || "",
        country: userCountry,
        customerId: customerId || 0,
        language: userLanguage,
      })
    ).unwrap();

    venueResponse.then((res: any) => {
      let data = res as unknown as Venue[];
      let venues: any = [];
      if (data) {
        venues = data.map((x) => {
          return {
            key: x.id,
            text: TrainingEventHelper.getLocationByVenue(x, userCountry),
            value: x,
          };
        });
      }
      setVenues(venues);
    });
  };

  const populateStudentList = (workshop: ContactCompany) => {
    if (workshop) {
      let userProfileRequest = dispatchReduxToolkit(
        getContactPersonsAsync({
          ciamId: ciamId || "",
          customerId: workshop.id || 0,
          language: userLanguage,
          country: userCountry,
        })
      ).unwrap();
      userProfileRequest.then((res) => {
        let participantsRes =
          res?.dataObject as unknown as ContactPersonModel[];
        let students: any = [];
        if (participantsRes) {
          students = participantsRes.map((x) => {
            return {
              key: x.id,
              text: `${x.firstName} ${x.lastName}`,
              value: x,
            };
          });
        }
        setStudents(students);
      });
    } else {
      setStudents([]);
    }
  };

  const onWorkshopSelected = (event: any, data: any) => {
    setSelectedWorkshop(data.value);
    populateStudentList(data.value);
  };

  const onCourseSelected = (event: any, data: any) => {
    if (+data.value !== 0) {
      setSelectedEventId(+data.value);
    } else {
      setSelectedVenue(null);
      setSelectedEventId(null);
    }
  };

  const getSelectedStudentWaitList = () => {
    return dispatchReduxToolkit(
      getWaitListByParticipantIdAsync({
        participantId: +(selectedStudent?.id || 0),
        ciamId: "",
        country: userCountry,
        customerId: selectedStudent?.customerId || 0,
        language: userLanguage,
      })
    );
  };

  const addToWaitList = () => {
    const addToWaitlistModel: AddToWaitListRequestModel = {
      ciamId: ciamId || "",
      customerId: customerId || 0,
      country: userCountry,
      language: userLanguage,
      waitListItems: [
        {
          id: 0,
          customerId: selectedStudent?.customerId || 0,
          participantId: +(selectedStudent?.id || 0),
          courseId: props.courseItem.id,
          eventId: selectedEventId || 0,
          venue: TrainingEventHelper.getLocationByVenue(
            selectedVenue,
            userCountry
          ),
          country: userCountry,
          saleRegion: props.courseItem.localizationInfo?.salesRegion || "",
        },
      ],
    };

    let dispatchResult = dispatchReduxToolkit(
      addToWaitListAsync(addToWaitlistModel)
    ).unwrap();
    dispatchResult
      .then((resp) => {
        if (resp?.success) {
          setOpenSucessPopup(true);
          if (props.addedItemSuccess != null) {
            props.addedItemSuccess();
          }
        } else setIsShowError(true);
      })
      .catch(() => setIsShowError(true))
      .finally(() => {
        setIsAddingWaitListItem(false);
      });
  };

  const removeConfirmed = () => {
    if (removeWaitlistItem) {
      setIsShowError(false);
      setIsRemovingWatchlist(true);
      setIsAddingWaitListItem(true);
      setIsShowingDeleteWaitListConfirmPopup(false);
      const deleteFromWaitlistModel: DeleteFromWaitListRequestModel = {
        ciamId: ciamId || "",
        customerId: customerId || 0,
        country: userCountry,
        language: userLanguage,
        waitListItems: [removeWaitlistItem],
      };
      let dispatchResult = dispatchReduxToolkit(
        deleteFromWaitListAsync(deleteFromWaitlistModel)
      ).unwrap();
      dispatchResult
        .then((resp: any) => {
          if (resp && resp.success) {
            addToWaitList();
          } else {
            setIsShowError(true);
          }
        })
        .catch(() => {
          setIsAddingWaitListItem(false);
          setIsShowError(true);
        })
        .finally(() => setIsRemovingWatchlist(false));
    }
  };

  const submitForm = () => {
    if (selectedStudent) {
      setIsShowError(false);
      setIsAddingWaitListItem(true);

      getSelectedStudentWaitList()
        .then((resp: any) => {
          if (resp.payload && resp.payload.dataObject) {
            let isCourseExistingInWaitList = false;
            const waitlistItems = resp.payload.dataObject as WaitListItem[];
            if (waitlistItems) {
              const waitListItem = waitlistItems.find(
                (x) =>
                  x.courseId === props.courseItem.id &&
                  x.participantId === +selectedStudent.id
              );
              if (waitListItem) {
                setRemoveWaitlistItem(waitListItem);
                isCourseExistingInWaitList = true;
              }
            }

            if (isCourseExistingInWaitList) {
              setIsShowingDeleteWaitListConfirmPopup(true);
            } else {
              addToWaitList();
            }
          } else {
            setIsAddingWaitListItem(false);
            setIsShowError(true);
          }
        })
        .catch(() => setIsShowError(true));
    }
  };

  return (
    <>
      <Modal
        onClose={() => props.setIsOpen(false)}
        open={props.isOpen && !openSucessPopup}
        dimmer={{
          className: "test-dimmer waitlist-popup waitlist-popup-center",
        }}
        size={"mini"}
        className="waitlist-modal"
      >
        <Modal.Content>
          <Grid style={{ margin: 0 }}>
            <Grid.Row className="course-name-area">
              <Grid.Column>
                <h5 className="course-name">
                  {TrainingEventHelper.getTrainingNameFromCourse(
                    props.courseItem,
                    userLanguage
                  )}
                </h5>
                <p>{t("WaitlistPopup_SignUpCourseWaitingList")}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid style={{ margin: 0 }}>
            {courseEvents && courseEvents.length > 1 && (
              <Grid.Row>
                <Grid.Column className="dropdown-row">
                  <label>
                    {t("WatinglistPopup_CourseLabel")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Dropdown
                    placeholder={t("Dropdown_Placeholder")}
                    selection
                    selectOnBlur={false}
                    options={courseEvents}
                    className="waiting-popup-dropdown"
                    icon={<Boschicon name="bosch-ic-down" />}
                    defaultValue={selectedEventId || 0}
                    onChange={onCourseSelected}
                  ></Dropdown>
                </Grid.Column>
              </Grid.Row>
            )}
            {userRole === Constants.USER_ROLE_WHOLESALER && (
              <Grid.Row>
                <Grid.Column className="dropdown-row">
                  <label>
                    {t("WaitlistPopup_Workshop")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Dropdown
                    className="waiting-popup-dropdown"
                    placeholder={t("Dropdown_Placeholder")}
                    selection
                    fluid
                    search
                    selectOnBlur={false}
                    icon={<Boschicon name="bosch-ic-down" />}
                    options={workshops}
                    onChange={onWorkshopSelected}
                  ></Dropdown>
                </Grid.Column>
              </Grid.Row>
            )}
            {(userRole === Constants.USER_ROLE_WHOLESALER ||
              userRole === Constants.USER_ROLE_MANAGER) && (
              <Grid.Row>
                <Grid.Column className={props.className + " dropdown-row"}>
                  <label>
                    {t("WaitlistPopup_Student")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Dropdown
                    placeholder={t("Dropdown_Placeholder")}
                    className="waiting-popup-dropdown"
                    selection
                    fluid
                    search
                    selectOnBlur={false}
                    options={students}
                    icon={<Boschicon name="bosch-ic-down" />}
                    onChange={(event, data: any) =>
                      setSelectedStudent(data.value)
                    }
                  ></Dropdown>
                </Grid.Column>
              </Grid.Row>
            )}
            {(!selectedEventId || selectedEventId === 0) && (
              <Grid.Row>
                <Grid.Column className="dropdown-row">
                  <label>{t("WaitlistPopup_PreferredLocation")}</label>
                  <Dropdown
                    style={{ width: "100%" }}
                    placeholder={t("Dropdown_Placeholder")}
                    className="waiting-popup-dropdown"
                    selection
                    fluid
                    search
                    selectOnBlur={false}
                    options={venues}
                    icon={<Boschicon name="bosch-ic-down" />}
                    onChange={(e: any, data: any) => {
                      setSelectedVenue(data.value);
                    }}
                  ></Dropdown>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row className="button-area">
              <Grid.Column>
                {isShowError && (
                  <Message color="red" style={{ textAlign: "left" }}>
                    {t("Error_Common")}
                  </Message>
                )}
                <Button
                  className="bwc-btn--primary button-block"
                  style={{ marginRight: 0 }}
                  disabled={!selectedStudent || isAddingWaitListItem}
                  onClick={submitForm}
                  loading={isAddingWaitListItem}
                >
                  {t("WaitinglistPopup_SignUpButtonText")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>

      <RDAlert
        size="tiny"
        type="warning"
        isOpen={isShowingDeleteWaitListConfirmPopup}
        title={t("WaitinglistPopup_RemoveWaitlistItem_Title")}
        messages={[t("WaitinglistPopup_RemoveWaitlistItemConfirmMessage")]}
        isAcceptLoading={isRemovingWatchlist}
        acceptButtonText={t("WaitinglistPopup_RemoveWaitlistItem_YesButton")}
        cancelButtonText={t("WaitinglistPopup_RemoveWaitlistItem_NoButton")}
        onAccept={() => {
          removeConfirmed();
        }}
        onCancel={() => {
          setIsShowingDeleteWaitListConfirmPopup(false);
          setIsAddingWaitListItem(false);
        }}
      />

      <RDAlert
        size="mini"
        type="success"
        isOpen={openSucessPopup}
        messages={[t("WaitlistPopup_AddToWaitListSuccess")]}
        acceptButtonText={t("CommonButton_Close")}
        onAccept={() => {
          props.setIsOpen(false);
          setOpenSucessPopup(false);
        }}
      />
    </>
  );
};
export default WaitlistPopup;
