import { AuthState } from "../redux-toolkit/authReducer";

export default class RedirectHelper {
  static redirectToSubDomain(
    url: string,
    countryCode: string,
    lang: string,
    originCountryCode: string
  ) {
    if (url.endsWith("/"))
      window.location.href = `${url}?c=${countryCode}&l=${lang}&oc=${originCountryCode}`;
    else
      window.location.href = `${url}/?c=${countryCode}&l=${lang}&oc=${originCountryCode}`;
  }

  static redirectBasedOnGeolocation(countryCode: string, lang: string) {
    let url = process?.env?.REACT_APP_DEFAULT_CLIENTROOT ?? "";
    if (url.endsWith("/"))
      window.location.href = `${url}?c=${countryCode}&l=${lang}&isGeo=1&oc=${countryCode}`;
    else
      window.location.href = `${url}/?c=${countryCode}&l=${lang}&isGeo=1&oc=${countryCode}`;
  }

  static redirectToRightCountry(countryCode: string, lang: string) {
    let url = process?.env?.REACT_APP_DEFAULT_CLIENTROOT ?? "";
    if (url.endsWith("/"))
      window.location.href = `${url}?c=${countryCode}&l=${lang}&ischangecountry=1&oc=${countryCode}`;
    else
      window.location.href = `${url}/?c=${countryCode}&l=${lang}&ischangecountry=1&oc=${countryCode}`;
  }

  static redirectToMainSite() {
    window.location.href = this.getDefaultClientRoot();
  }

  static getDefaultClientRoot(): string {
    return process?.env?.REACT_APP_DEFAULT_CLIENTID ?? "";
  }

  static getClientRoot(authState: AuthState) {
    if (authState?.domain !== "")
      return authState.domain;
    return this.getDefaultClientRoot();
  }

  static getClientId(authState: AuthState) {
    if (!!authState && !!authState.clientId && authState.clientId !== "")
      return authState.clientId;

    return process?.env?.REACT_APP_DEFAULT_CLIENTID ?? "";
  }

  static extractDomainName(domain: string | undefined) {
    if (!domain || domain === "") return domain;
    let startIndex = domain.indexOf("//") >= 0 ? domain.indexOf("//") + 2 : 0;
    let endIndex =
      domain.indexOf("/", startIndex) > 0
        ? domain.indexOf("/", startIndex)
        : domain.length;
    return domain.substring(startIndex, endIndex);
  }
}
