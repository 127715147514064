import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";
import {
  GetTrainingVenuesRequestModel,
  ITrainingCenter,
} from "../models/TrainingCenter";
import { Venue } from "../models/TrainingEvent";
import { http } from "../services/HttpService";
import { httpNoneSecure } from "../services/HttpServiceNoneSecure";
import { AppDispatch, RootState } from "./store";

export const getTrainingCentersAsync = createAsyncThunk<
  ITrainingCenter[] | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("trainingCenter/getTrainingCenters", async (country: string, thunkApi) => {
  try {
    const model = { country: country };
    let response = await httpNoneSecure.post<
      ResponseResultBase<ITrainingCenter[]>
    >(`/api/v1/TrainingCenters/country`, model);
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getTrainingVenuesAsync = createAsyncThunk<
  Venue[] | null,
  GetTrainingVenuesRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingCenter/GetTrainingVenues",
  async (req: GetTrainingVenuesRequestModel, thunkApi) => {
    try {
      let venueModel = {
        country: req.country,
        ciamId: req.ciamId,
        customerId: req.customerId,
        language: req.language,
      };
      let response = await http.post<ResponseResultBase<Venue[]>>(
        `/api/v1/TrainingCenters/GetTrainingVenues`,
        venueModel
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

interface TrainingCenterState {
  trainingCenters: ITrainingCenter[] | null;
  selectedTrainingCenter: ITrainingCenter | null;
  isForceReloadTrainingCenters: boolean;
}

const initialState: TrainingCenterState = {
  trainingCenters: null,
  selectedTrainingCenter: null,
  isForceReloadTrainingCenters: true,
};

export const trainingCenterSlice = createSlice({
  name: "trainingCenterReducer",
  initialState,
  reducers: {
    setTrainingCenters: (state, action) => {
      return {
        ...state,
        trainingCenters: action.payload,
      };
    },
    setSelectedTrainingCenter: (state, action) => {
      return {
        ...state,
        selectedTrainingCenter: action.payload,
      };
    },
    setIsForceReloadTrainingCenters: (state, action) => {
      return {
        ...state,
        isForceReloadTrainingCenters: action?.payload || false,
      };
    },
    resetTrainingCenters: (state, action: any) => {
      return {
        ...state,
        trainingCenters: null,
        isForceReloadTrainingCenters: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrainingCentersAsync.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        return {
          ...state,
          trainingCenters: payload,
          isForceReloadTrainingCenters: false,
        };
      } else {
        return {
          ...state,
          trainingCenters: null,
        };
      }
    });
  },
});

export const selectTrainingCenters = (rootState: RootState) =>
  rootState.trainingCenterReducer.trainingCenters;

export const selectSelectedTrainingCenter = (rootState: RootState) =>
  rootState.trainingCenterReducer.selectedTrainingCenter;

export const selectIsForceReloadTrainingCenters = (rootState: RootState) =>
  rootState.trainingCenterReducer.isForceReloadTrainingCenters;

export const {
  setTrainingCenters,
  setSelectedTrainingCenter,
  setIsForceReloadTrainingCenters,
  resetTrainingCenters,
} = trainingCenterSlice.actions;

export default trainingCenterSlice.reducer;
