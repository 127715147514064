export const TRANSLATIONS_EN_HR = {
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch, all rights reserved",
	ContactUs_Mobile_HR: "+3851 2958054",
	ContactUs_Address_HR: "Robert Bosch d.o.o., Auto oprema, 10040 Zagreb, Hrvatska",
	ConfirmItsMeModal_CompanyDetails_Name: "Company Name",
	ConfirmItsMeModal_CompanyDetails_Address: "Address",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Company Postcode",
	ConfirmItsMeModal_CompanyDetails_Phone: "Phone",
	ConfirmItsMeModal_CompanyDetails_Email: "Email",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Withdraw from the Course is successful!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Account creation is failed. The account is already existing!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Update account is failed. The account is already existing!",
	Footer_ExploreTrainingStories: "Explore all our training stories",
	Breadcrumb_Home: "Home",
	Breadcrumb_Contact: "Contact",
	Breadcrumb_Stories: "Stories",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "A day in the life of a trainer",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch Training Benefits",
	Breadcrumb_Stories_TrainingApproach: "A modern training approach",
	Breadcrumb_Settings: "Settings",
	Breadcrumb_ShoppingCart: "Shopping Cart",
	Breadcrumb_Checkout: "Check Out",
	Breadcrumb_OrderSummary: "Order Summary",
	Breadcrumb_Exception: "Exception",
	Breadcrumb_Dashboard: "Dashboard",
	Breadcrumb_MyCourses: "My Courses",
	Breadcrumb_MyELearning: "My eLearning",
	Breadcrumb_CompanyAdministration: "Company Administration",
	Breadcrumb_EmployeeManagement: "Employee Management",
	Breadcrumb_EmployeeAccount: "Employee Account",
	Breadcrumb_EmployeeCreation: "Employee Creation",
	Breadcrumb_Administration: "Administration",
	Breadcrumb_EmployeeAdministration: "Employee Administration",
	Breadcrumb_WholesalerManagement: "Wholesaler Management",
	Breadcrumb_NewWholesalerAccountCreation: "New Wholesaler Account Creation",
	Breadcrumb_Transactions: "Transactions",
	Breadcrumb_MyAccount: "My Account",
	Breadcrumb_TrainingCentres: "Training Centres",
	Breadcrumb_EventCalendar: "Event Calendar",
	Breadcrumb_TrainingCourseCatalogue: "Training Course Catalogue",
	Breadcrumb_RecommendedHotels: "Recommended Hotels",
	Breadcrumb_CorporateInformation: "Corporate Information",
	Breadcrumb_LegalNotice: "Legal Notice",
	Breadcrumb_DataProtectionNotice: "Data Protection Notice",
	Breadcrumb_PrivacyPolicy: "Privacy Policy",
	Breadcrumb_TermCondition: "Term & Condition",
	Breadcrumb_Terms: "Terms",
	Breadcrumb_PrivacyStatement: "Privacy Statement",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Provider",
	Breadcrumb_RegisterAccount: "Register Account",
	Stories_ExploreMore: "Explore more",
	CheckOutLayout_ShoppingCartHeader: "Shopping Cart",
	CheckOutLayout_AddedItemCartMessage: "You have added the following to your cart",
	CheckOutLayout_CheckYourCartMessage: "Check your cart",
	ModernTrainingApproach_DateText: "28-02-2022",
	ADayInLifeofATrainer_DateText: "28-02-2022",
	BoschTrainingBenefits_DateText: "28-02-2022",
	BoschServiceTraining_DateText: "28-02-2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Search Employees",
	CheckOutLayout_OrderWasNotPlaced_Message: "Sorry! Your order was not placed",
	CheckOutLayout_RefusalReason: "Refusal Reason",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Payment failed. Please retry with a different payment method",
	CheckOutLayout_SubmittingApiFailed_Message: "Submitting API failed",
	CartItem_ChooseWorkshop_Message: "Please choose workshop to choose participant",
	CartItem_ChooseParticipant_Message: "Please choose participant to continue",
	CompanyAdministrationTable_SearchBox_Placeholder: "Search Workshops",
	CartItem_RemoveCartItem_ConfirmText: "Do you want to remove this item out of the cart?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Function",
	AddToCartSuccessModal_Message: "Your shopping cart has been updated!",
	AddToCartSuccessModal_ContinueButtonText: "Continue shopping",
	AddToCartSuccessModal_MyShoppingCartButtonText: "My Shopping Cart",
	AddVoucher_Header: "Add voucher",
	AddVoucher_InputPlaceholder: "Enter code and apply",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Unfortunately, we do not have any scheduled events of this training course at the moment. Please register to waiting list, and we will inform you once an event becomes available.",
	ADYEN_ERR_2_: "The transaction was refused.",
	ADYEN_ERR_3_: "Referrals error",
	ADYEN_ERR_4_: "The transaction did not go through due to an error that occurred on the acquirer's end.",
	ADYEN_ERR_5_: "The card used for the transaction is blocked, therefore unusable.",
	ADYEN_ERR_6_: "The card used for the transaction has expired. Therefore it is unusable.",
	ADYEN_ERR_7_: "An amount mismatch occurred during the transaction process.",
	ADYEN_ERR_8_: "The specified card number is incorrect or invalid.",
	ADYEN_ERR_9_: "It is not possible to contact the shopper's bank to authorise the transaction.",
	ADYEN_ERR_10_: "The shopper's bank does not support or does not allow this type of transaction.",
	ADYEN_ERR_11_: "3D Secure authentication was not executed, or it did not execute successfully.",
	ADYEN_ERR_12_: "The card does not have enough money to cover the payable amount. This problem could occur due to a monthly payable amount limit on your card. Please try to use another card such as a MasterCard, or contact your bank to reset the monthly limit of your card.",
	ADYEN_ERR_14_: "Possible fraud.",
	ADYEN_ERR_15_: "The transaction was cancelled.",
	ADYEN_ERR_16_: "The shopper cancelled the transaction before completing it.",
	ADYEN_ERR_17_: "The specified PIN is incorrect or invalid.",
	ADYEN_ERR_18_: "The shopper specified an incorrect PIN more that three times in a row.",
	ADYEN_ERR_19_: "It is not possible to validate the specified PIN number.",
	ADYEN_ERR_20_: "Possible fraud.",
	ADYEN_ERR_21_: "The transaction was not submitted correctly for processing.",
	ADYEN_ERR_22_: "The risk check flagged the transaction as fraudulent (risk score >= 100); therefore, the operation is cancelled.",
	ADYEN_ERR_23_: "The following declined codes are mapped to this refusal reason:  Transaction not permitted to issuer/cardholder",
	ADYEN_ERR_24_: "The specified CVC (card security code) is invalid.",
	ADYEN_ERR_25_: "The following decline codes are mapped to this refusal reason:  Invalid card in this country",
	ADYEN_ERR_26_: "R1: Revocation of Authorization Order / \"R3: Revocation of All Authorizations Order\" / \"R0: Stop Payment Order\"",
	ADYEN_ERR_27_: "This response maps all those response codes that cannot be reliably mapped. This makes it easier to tell generic declines (for example, Mastercard \"05: Do not honor\" response) from more specific ones.",
	ADYEN_ERR_28_: "The withdrawal amount permitted for the shopper's card has exceeded.",
	ADYEN_ERR_29_: "The number of withdrawals permitted for the shopper's card has exceeded.",
	ADYEN_ERR_31_: "Issuer reported the transaction as suspected fraud.",
	ADYEN_ERR_32_: "The address data the shopper entered is incorrect.",
	ADYEN_ERR_33_: "The shopper's bank requires the shopper to enter an online PIN.",
	ADYEN_ERR_34_: "The shopper's bank requires a checking account to complete the purchase.",
	ADYEN_ERR_35_: "The shopper's bank requires a savings account to complete the purchase.",
	ADYEN_ERR_36_: "The shopper's bank requires the shopper to enter a mobile PIN.",
	ADYEN_ERR_37_: "The shopper abandoned the transaction after they attempted a contactless payment and were prompted to try a different card entry method (PIN or swipe).",
	ADYEN_ERR_38_: "The issuer declined the authentication exemption request and requires authentication for the transaction. Retry with 3D Secure.",
	ADYEN_ERR_39_: "The issuer or the scheme wasn't able to communicate the outcome via RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "First name must not include special characters (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "First name must be less than 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Last name must not include special characters (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Last name must be less than 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobile number must not include special characters (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobile number must be less than 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telephone number must not include special characters (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telephone number must be less than 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Email address is invalid",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Email must be less than 100",
	ConfirmGeolocationModal_ConfirmButton: "Continue",
	ConfirmGeolocationModal_CancelButton: "Change",
	ConfirmGeolocationModal_CurrentCountrySelection: "Your current country selection is:",
	ConfirmGeolocationModal_AskChangeCountry: "Do you wish to remain on this site or change your country preference?",
	Addtocart_Modal_AddToCartFailTitle: "Participant already registered to this course",
	Addtocart_Modal_AlreadyInCart: "Participant already registered to this Event",
	TrainingCatalogue_DateRange: "Date Range",
	TrainingCatalogue_DateRange_placeholder: "Select date range filter",
	Dashboard_MyPendingCourses: "My Pending Courses",
	Dashboard_MyWaitingList: "My Waiting List",
	Dashboard_LatestNews: "Latest News",
	SettingsMainPage_EditAccountButton: "Edit Account",
	SettingsMainPage_EditBoschId: "Edit Bosch ID",
	EventInfoView_EventDetailTab: "Event Details",
	EventInfoView_AssignmentsTab: "Assignments",
	EventInfoView_EvaluationTab: "Evaluation",
	EventInfoView_AttachmentTab: "Attachments",
	EventInfoView_CourseDescriptionTab: "Description",
	EventInfoView_DescriptionTab_General: "General",
	EventInfoView_DescriptionTab_PartLabel: "Part",
	AssignmentTab_Description: "Here you will find all of the information you will need to complete this course.",
	AssignmentTab_Columns_Download: "Download",
	AssignmentTab_Columns_Upload: "Upload",
	AssignmentTab_Columns_TaskComplete: "Task Complete",
	EventDetailTab_Location_Message_Video: "This is an online event which can be accessed by using the \"Launch Video\" link.",
	EventDetailTab_Location_Message_WebBased: "This is an online event which can be accessed by using the \"Launch WBT\" link.",
	EventDetailTab_Location_Message_WebCast: "This is an online event which can be accessed by using the \"Join Now\" link.",
	EventDetailTab_Link_Video: "Launch Video",
	EventDetailTab_Link_Video_Remark: "*Launching the video will open a new window. You will be redirected back to this page once the window is closed.",
	EventDetailTab_Link_WebBased: "Launch WBT",
	EventDetailTab_Link_WebBased_Remark: "*Launching the WBT will open a new window. You will be redirected back to this page once the window is closed.",
	EventDetailTab_Link_WebCast: "Join Now",
	EventDetailTab_Link_WebCast_Remark: "*Joining Now link will be active 30 minutes before the start of the event.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Durations (hrs)",
	EventDetailTab_Title_Trainers: "Trainer(s)",
	EventDetailTab_Title_Language: "Language",
	EventDetailTab_Title_Score: "Score (%)",
	EventDetailTab_Title_Calendar: "Add to calendar",
	EventDetailTab_Title_Certificate: "Download certificate",
	EventDetailTab_Title_Messages: "Messages",
	EventDetailTab_Title_StartDate: "Start date",
	EventDetailTab_Title_AvailableFrom: "Available from",
	EventDetailTab_Title_Time: "Time",
	EventDetailTab_Title_EndDate: "End date",
	EventDetailTab_Title_ExpiresOn: "Expires on",
	EventDetailTab_Title_Room: "Room",
	EventDetailTab_Title_Method: "Method",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Location",
	AttachmentTab_EmptyScreenDescription: "There is no attachments for this event.",
	AttachmentTab_Columns_Download: "Download",
	AttachmentTab_Description: "Here you will find some important information to help you with this course",
	EvaluationTab_Annotation: "We value your feedback and would love to hear from you to see how we are doing. Please take the time to fill in the following evaluation to help us to continually improve our service to you. Thank you.",
	EvaluationTab_Notice: "The ratings are as follows",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Please answer all mandatory questions before submitting your event evaluation",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Your feedback has been submited successful",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "The evaluation submission failed",
	TrainingCatalogueLayout_ExportButtonText: "Export",
	EvaluationTab_FeedbackHasBeenGiven_Message: "The Evaluation was completed or has expired.",
	EventDetailTab_Link_CopiedTooltip: "Copied to Clipboard",
	EventDetailTab_QRCode_ExplanationText_WBT: "Scan to launch WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Scan to launch Video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Scan to launch Webcast",
	Footer_TrainingCoure_Headline: "Training courses provided by Bosch",
	Footer_TrainingCourse_Content: "As a world-renowned Superbrand and with over a century of experience in innovation, Bosch has set the standards globally as a provider of automotive systems, parts and technical solutions. Much of our strength comes from our close partnerships with vehicle manufacturers, for whom we design, develop and produce state-of-the-art systems and components the world over. Consequently, Bosch has cemented a strong history in automotive aftermarket products and support with a diverse portfolio of parts, diagnostic solutions and workshop services. Learning and development are sure keys to success and this philosophy is underpinned by a broad range of training courses, in all areas of automotive technology, vehicle systems and diagnostic expertise.",
	SearchField_NoRecordsFound: "No records found",
	TrainingCatalogue_FreeTextSearch: "Free Text",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Search",
	CheckoutLayout_WholeSalerGroup: "Choose Wholesaler Group",
	CreditInsufficientModal_Message: "You don't have sufficient credit for using this payment method. Please try again with another payment method.",
	ShoppingCart_Required_Credits: "Total price",
	MaintainingInfo_Message1: "The website is in maintenance mode.",
	MaintainingInfo_Message2: "We will be back soon.",
	CountryValidationModal_Message: "You couldn't login to [CountryA] as your account has been created in [CountryB].",
	CountryValidationModal_ButtonText: "Continue to [Country]",
	EventTable_LoginRequired: "Login to register",
	TrainingPartTableLayout_Date: "Date",
	TrainingPartTableLayout_StartTime: "Start Time",
	TrainingPartTableLayout_EndTime: "End Time",
	TrainingPartTableLayout_Duration: "Durations (hrs)",
	TrainingPartTableLayout_TrainingMethod: "Training Method",
	MyCoursesTableContents_ActiveHeader: "Active Courses",
	MyCoursesTableContents_ActiveTabHeader: "Active",
	MyCoursesTableContents_ScheduledHeader: "Scheduled Courses",
	MyCoursesTableContents_ScheduledTabHeader: "Scheduled",
	MyCoursesTableContents_OnlineHeader: "Online Courses",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Cancelled Courses",
	MyCoursesTableContents_CancelledTabHeader: "Cancelled",
	OnlineCoursesTable_Title_Progress: "Progress",
	ScheduledCoursesTable_Title_Date: "Date [Days]",
	CoursesTable_Title_Completed: "Completed",
	Footer_TrainingProgram: "Training Programs",
	Breadcrumb_TrainingProgram: "Training Programs",
	TrainingProgram_Title_Table: "Title",
	TrainingProgram_TrainingProgram: "Training Programs",
	TrainingProgram_TargetGroup: "Target Group",
	TrainingProgram_MaxLength: "Max. Length",
	TrainingProgram_Information: "Information",
	TrainingProgram_Detail: "Details",
	TrainingProgram_Modules: "Modules",
	TrainingProgram_Course: "Courses",
	TrainingProgram_PartNumber: "Part Number",
	TrainingProgram_Max_Length: "Maximum Length",
	TrainingProgram_Duration: "Duration",
	TrainingProgram_Exam: "Examination",
	TrainingProgram_Assign_Popup: "Register for training program",
	TrainingProgram_Student: "Student",
	TrainingProgram_Assign: "Assign",
	TrainingProgram_Popup_Remove_Title: "Training cancellation?",
	TrainingProgram_Description: "Description",
	TrainingProgram_Days: "Days",
	TrainingProgram_Type: "Type",
	TrainingProgram_Action: "Action",
	TrainingProgram_Enrolled: "has already been enrolled to this training program",
	TrainingProgram_SelfAssign_Enrolled: "You are already assigned to this training program",
	TrainingProgram_Warning: "Please register another student or select a different training program.",
	TrainingProgram_Workshop: "Workshop",
	TrainingProgram_Durations: "years",
	TrainingProgram_Duration_DaysLabel: "days",
	TrainingProgram_HasExam_Yes: "Yes",
	TrainingProgram_HasExam_No: "No",
	AssignModal_SuccessModal_Header: "Congratulations!",
	AssignModal_SuccessModal_Message: "has been enrolled to this training program successful.",
	AssignModal_SelfAssign_SuccessModal_Message: "You have been succesfully assigned to the training program.",
	Dashboard_MyTraningProgram: "My Training Program",
	Dashboard_MyTraningProgram_Title: "Title",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "View",
	Dashboard_MyTraningProgram_Register: "Register",
	Dashboard_MyTraningProgram_Records: "Historical Records",
	Dashboard_MyTrainingProgram_In_Progress: "In progress",
	Dashboard_MyTrainingProgram_Accredited: "Accredited",
	Dashboard_Program_Modal_Withdraw: "Withdraw",
	Dashboard_Program_Modal_Grade: "Grade",
	Dashboard_MyTrainingProgram_Registered: "Registered",
	Dashboard_MyTrainingProgram_Completedd: "Completed",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Yes",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "No",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Please be aware that withdrawing means that you will withdraw from the complete training program, not just from an individual training course.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Do you still wish to withdraw from the training program?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Pending",
	WithdrawProgramModal_Withdraw_Success_Message: "You have successfully withdrawn from the training program!",
	Common_Warning_Title: "Warning!",
	Common_Error_Title: "Ups, something went wrong!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Add to Cart",
	AddEventFromCatalogToCartModal_HoursText: "hours",
	AddElearningToCartSuccessModal_Message: "has been added to your shopping cart!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "The selected participant has been registered to this Course, or the Course was already added to cart.",
	EventDetailTab_SubjectForChange_Message: "Subject for change",
	Dashboard_MyTrainingProgram_Status_Failed: "Failed",
	Dashboard_MyTrainingProgram_Status_Passed: "Passed",
	Dashboard_MyTrainingProgram_Status_Merit: "Merit",
	Dashboard_MyTrainingProgram_Status_Distinction: "Distinction",
	Dashboard_Employee_Courses: "Courses",
	Dashboard_Employee_Program: "Training Program",
	Dashboard_Employee_WaitList: "Wait List",
	Dashboard_Employee_Account: "Account",
	Course_Price_Free: "Free",
	Dashboard_Employee_Account_Update_Success_Message: "You have successfully update your employee information!",
	Dashboard_Supplier: "Supplier",
	Event_Detail_Comment: "There are currently no messages",
	DownLoad_PDF: "PDF Download",
	TrainingCatalogue_Currency: "Currency",
	My_Wallet: "My Wallet",
	Wallet: "Wallet",
	Credits_Balance: "Credits balance",
	MyFullCalendar_Today: "Today",
	Header_Available_Languages: "Available Languages",
	Header_CurrentRegion: "You are currently in",
	Header_ChangeRegions: "Do you want to change the country?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Login/Register",
	Header_RegisterCourse: "Register Course",
	Homepage_Start: "Get started",
	Homepage_Login: "Login",
	MainPage_Title: "The ultimate workshop solution",
	MainPage_Content: "Your workshop, your choice.",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Enables the modern workshop to work professionally, reliably & efficiently within diagnostic procedures, troubleshooting, repairs and servicing of all types of vehicles.",
	BoschNews_TrainingNews: "Training News",
	Service_Assist_Workshop: "Enhance your experience with Workshop Service Assist",
	Enhance_Title_CDM3_v2: "What can I do with the app?",
	Enhance_Body_CDM3_Item_1: "Book courses and maintain your learning journey",
	Enhance_Body_CDM3_Item_2: "Download training material and course certificates",
	Enhance_Body_CDM3_Item_3: "Launch eLearning courses",
	Enhance_Body_CDM3_Item_4: "Access various Automotive services within on mobile application",
	Enhance_Body_CDM3_Item_5: "Live video transmission with Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Free tool for automatic vehicle scan",
	Training_Stories: "Training Stories",
	SettingsMyAccount_JoinCompanyButton: "Join Company",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Join Company",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Please enter your activation code below",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Note! You need to contact your Company administrator to obtain an activation code.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Your activation has been successfully processed and your account is now linked to your company.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Please click Continue to enter the website.",
	ContinueButton: "Continue",
	WelcomeBatsModal_Title: "Your account is currently in review!",
	WelcomeBatsModal_ActivationLink: "Click here if you have an Activation Code to Join a Company!",
	WelcomeBatsModal_ActivationLinkNotes: "Note! You need contact your Company administrator to obtain an activation code.",
	AccountInReviewModal_Description_Paragraph1: "Thank you registering a personal account on Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Our dedicated training staff are currently reviewing and configuring your account. You will receive final confirmation by mail, once your account been successfully configured.",
	AccountInReviewModal_CloseButton: "Okay, let me in!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "We were unable to validate your activation code and email address.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Please try again or contact your Company Administrator for assistance.",
	TryAgain_Button: "Try Again",
	Error_Title: "Oops, something went wrong!",
	SettingsMyProfileActivationSuccessModal_Title: "Success!",
	AccountInReviewModal_Description_Paragraph3: "Meanwhile, please feel free to browse our website site with limited access.",
	AccountInReviewModal_Description_Paragraph4: "If you have any questions, or if your account is not processed within 48 hours excluding weekends and holidays, you can contact us [here]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Some guidance to help you...",
	Support_FAQ_Headline: "Frequently asked questions",
	Support_FAQs_section1_title: "General",
	Support_FAQs_section1_question1: "What is Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions is a platform which provides a comprehensive overview about technical trainings and enables to book & manage trainings.",
	Support_FAQs_section1_question2: "Can I use my existing Bosch account (singleKey ID) to login to Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Yes, it is accessible with all accounts created with \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Can I use an account created for Bosch Training Solutions with other Bosch services or applications?",
	Support_FAQs_section1_question3_answer1: "Yes, you can use your personal Bosch ID also for a variety of other Bosch applications - both professionally and privately, such as e-bike or smart home.",
	Support_FAQs_section2_title: "Training",
	Support_FAQs_section2_question1: "How can I book a training?",
	Support_FAQs_section2_question1_answer1: "Login with your existing account OR register an account into the portal. Go to \"Training Services\" OR \"Events Calendar\" select a training and click on the add to card button.",
	Support_FAQs_section2_question2: "No suitable training date is available. What can I do?",
	Support_FAQs_section2_question2_answer1: "You have the possibility to put yourself onto a \"general\" waitlist (without selecting a date) and our team will inform you as soon as a new training is available.",
	Support_FAQs_section2_question3: "Where can I find an overview of my booked courses?",
	Support_FAQs_section2_question3_answer1: "You can find a complete overview of all booked & completed courses after login in your dashboard.",
	Support_FAQs_section3_title: "Settings",
	Support_FAQs_section3_question1: "How can I change my Email & Password?",
	Support_FAQs_section3_question1_answer1: "You need to login to the portal and change to your personal profile. (Inside your Dashboard you can find your profile information or if you click on the following button in the header",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "There you have the possibilty to change the details of your Bosch ID",
	Support_FAQs_section3_question2: "What can I do when I forgot my password?",
	Support_FAQs_section3_question2_answer1: "Please use the login/register button and fill in your email address",
	Support_FAQs_section3_question2_answer2: "If you click on continue you have the possibilty to click onto \"Forgot Password\"",
	Support_FAQs_section3_question3: "How to set-up a workshop account?",
	Support_FAQs_section3_question3_answer1: "If you want to be able to get a workshop role please contact your local Training Administration e.g. you can use the contact form on the website.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Choose your country / region",
	MaintenanceBanner_Message_1: "The Bosch Automotive Training Solutions Portal will be updated on [maintenance_schedule]. The update is expected to take 30 minutes. The Portal will not be available during the update.",
	MaintenanceBanner_Message_2: "\nFor further assistance, please contact us by email [support_email]",
	MaintenancePage_Message_1: "The Bosch Automotive Training Solutions Portal is currently being updated",
	MaintenancePage_Message_2: "We expect the Portal to be available again on [maintenance_online_date] at [maintenance_online_time]",
	MenuHeader_Support: "Support",
	Course_Detail_No_Prerequisites: "No prerequisites",
	TableCell_Hours: "Hours",
	Dashboard_MenuItem_Dashboard: "Dashboard",
	Dashboard_MenuItem_Curricula: "Curricula",
	Dashboard_MenuItem_Certificates: "Certificates",
	Dashboard_MenuItem_Team: "Team",
	Dashboard_MenuItem_Workshops: "Workshops",
	Dashboard_MenuItem_External: "External",
	Dashboard_MenuItem_Wallet: "Wallet",
	Dashboard_MenuItem_Profile: "Profile",
	Dashboard_Headline_YourUpcomingCourses: "Your upcoming courses",
	Dashboard_Headline_YourELearningCourses: "Your eLearning courses",
	Dashboard_Headline_TeamOverview: "Team overview",
	Dashboard_Headline_YourOverview: "Your overview",
	Dashboard_Headline_YourWishlist: "Your Wishlist",
	Dashboard_Headline_ActivePrograms: "Active programs",
	Dashboard_Headline_CompletedPrograms: "Completed programs",
	Dashboard_Headline_YourCertificates: "Your certificates",
	Dashboard_Headline_Transactions: "Transactions",
	Dashboard_Table_NoRecordsToDisplay: "No record to display",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "hours",
	Dashboard_ELearningCourses_TableHeader_Title: "Title",
	Dashboard_ELearningCourses_TableHeader_Duration: "Duration (hrs)",
	Dashboard_ELearningCourses_ActionButton_View: "View",
	Dashboard_UpcomingCourses_TableHeader_Title: "Title",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Date [Days]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Location",
	Dashboard_UpcomingCourses_ActionButton_View: "View",
	Dashboard_YourOverview_TableHeader_Title: "Title",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Date / Duration",
	Dashboard_YourOverview_TableHeader_Location: "Location",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "View",
	Dashboard_YourWishlist_TableHeader_Title: "Title",
	Dashboard_YourWishlist_TableHeader_Action: "Action",
	Dashboard_YourWishlist_ViewAllRecords: "View all records",
	Dashboard_TeamOverview_TableHeader_Employee: "Employee",
	Dashboard_TeamOverview_TableHeader_Title: "Title",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Date / Duration",
	Dashboard_TeamOverview_TableHeader_Location: "Location",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "View",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Search",
	Dashboard_ActiveProgram_TableHeader_Title: "Title",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "View",
	Dashboard_CompletedProgram_TableHeader_Title: "Title",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "View",
	Dashboard_Certificates_TableHeader_Title: "Title",
	Dashboard_Certificates_TableHeader_Date: "Date",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Action",
	Dashboard_Certificates_ActionButton_Download: "Download",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Invoice Number",
	Dashboard_Transactions_TableHeader_Description: "Description",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Payment Method",
	Dashboard_Transactions_TableHeader_Date: "Date",
	Dashboard_Transactions_TableHeader_Amount: "Amount",
	Dashboard_Transactions_ActionButton_Download: "Download",
	Breadcrumb_MyTraining: "MyTraining",
	Breadcrumb_MyTraining_CourseDetails: "Course details",
	Breadcrumb_MyTraining_Curricula: "Curricula",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Training program details",
	Breadcrumb_MyTraining_Team: "Team",
	Breadcrumb_MyTraining_Team_ViewEmployee: "View employee",
	MyTraining_Headerbar_CourseDetails: "Course Details",
	MyTraining_Headerbar_TrainingProgramDetails: "Training Program Details",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Overall progress",
	TrainingProgramDetails_ProgramItems_Headline: "Program items",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Title",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Grade",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Date",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "View",
	TrainingProgramDetails_Status_InProgress: "In Progress",
	TrainingProgramDetails_Status_Completed: "Completed",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Duration",
	CourseDetails_Label_Trainer: "Trainer",
	CourseDetails_Label_Language: "Language",
	CourseDetails_Label_Score: "Score:",
	CourseDetails_Label_StartDate: "Start date",
	CourseDetails_Label_EndDate: "End date",
	CourseDetails_Label_Venue: "Venue",
	CourseDetails_Label_Address: "Address",
	CourseDetails_Label_Grade: "Grade",
	CourseDetails_Action_Withdraw: "Withdraw",
	CourseDetails_Action_Evaluate: "Evaluate course",
	CourseDetails_Action_Evaluate_Description: "Please evaluate this course to generate your certificate!",
	CourseDetails_Action_DownloadCertificate: "Download certificate",
	CourseDetails_Action_JoinWebcast: "Join Webcast",
	CourseDetails_Action_StartCourse: "Start Course",
	CourseDetails_Action_RestartCourse: "Restart Course",
	CourseDetails_Action_RepeatCourse_Description: "Please repeat and pass this course to download your certificate!",
	CourseDetails_PartSpecificDetails_Headline: "Part specific details",
	CourseDetails_PartSpecificDetails_Tab_Part: "Part",
	CourseDetails_PartSpecificDetails_Description: "Description",
	CourseDetails_PartSpecificDetails_StartDate: "Start date",
	CourseDetails_PartSpecificDetails_EndDate: "End date",
	CourseDetails_PartSpecificDetails_StartTime: "Start time",
	CourseDetails_PartSpecificDetails_EndTime: "End time",
	CourseDetails_PartSpecificDetails_Method: "Method",
	Dashboard_Team_TableHeader_Name: "Name",
	Dashboard_Team_TableHeader_Role: "Role",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Personal account",
	Dashboard_Team_ActionButton_Select: "Select",
	Dashboard_Team_PersonalAccount_Activated: "Activated",
	Dashboard_Team_PersonalAccount_NotActivated: "Not activated",
	Dashboard_Team_ActionButton_AddMember: "Add member",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Search",
	Dashboard_External_TableHeader_Name: "Name",
	Dashboard_External_ActionButton_AddNew: "Add new",
	Dashboard_External_Headline: "Approved external access",
	Dashboard_Workshops_Headline: "Workshops",
	Dashboard_Workshops_TableHeader_Company: "Company",
	Dashboard_Workshops_TableHeader_Address: "Address",
	Dashboard_Workshops_TableHeader_Postcode: "Post code",
	Dashboard_Workshops_TableHeader_City: "City",
	Dashboard_Workshops_ActionButton_Select: "Select",
	Wallet_ServiceCredits: "Service Credits",
	Wallet_Headline_CreditBalance: "Credit balance",
	Wallet_Headline_CreditActivity: "Credit activity",
	Wallet_Headline_BillingTransactions: "Billing transactions",
	Wallet_BillingTransaction_TableHeader_Order: "Order",
	Wallet_BillingTransaction_TableHeader_Date: "Date",
	Wallet_BillingTransaction_TableHeader_Amount: "Amount",
	Wallet_BillingTransaction_TableHeader_Action: "Action",
	Wallet_CreditActivity_TableHeader_Date: "Date",
	Wallet_CreditActivity_TableHeader_Description: "Description",
	Wallet_CreditActivity_TableHeader_User: "User",
	Wallet_CreditActivity_TableHeader_Amount: "Amount",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, all rights reserved",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Product security (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovations, patents, and licenses",
	FooterAdditionalLinks_Navigation_Logistics: "Purchasing and logistics",
	MenuHeader_MyTraining: "My Training",
	MenuHeader_TrainingServices: "Training Services",
	MenuHeader_OurLocations: "Our locations",
	MenuHeader_ContactsUs: "Contacts us",
	MenuHeader_Logout: "Logout",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "My Bosch Id",
	MyProfile_AccountDeletion: "Account Deletion",
	MyProfile_Edit: "Change email or edit password",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Edit user profile",
	BackButton: "Back",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "We look forward to your call and will be pleased to assist you. We are available for you 24 hours, 7 days a week.​",
	ContactUs_Email_SecurityCodeConfirm_V3: "Enter the correct characters, as indicated in the box above.",
	ContactUs_Email_MandatoryNotice_V3: "Fill in all the required fields.",
	ContactUs_Email_SendButton: "Send",
	Breadcrumb_TrainingCenters: "Training Centers",
	Training_Centres_Book_Btn: "Book a course",
	Training_centres_booking_code: "Booking Code",
	Training_centers_header_bar_title: "Our Training Centers",
	Training_centers_filter_item: "Center",
	TeamEdit_PersonalAccountToolTop: "Enabling this feature allows the employee to access this website. The user will be able to create a personal account and be part of your corporate account. Once enabled, you can send an invite with an activation code to the employee. The invite will be sent to the email address.",
	TeamEdit_Update: "Enabling the company administrator feature automatically provide this employee with full access to your corporate account. Enabling this feature requires that the user logs out and back into the website, if the user is already logged in.",
	TeamEdit_PersonalAccount: "Allow personal Bosch Training Solutions web account",
	TeamEdit_SendInvitation: "Send Invitation",
	TeamEdit_Deactivate: "Deactivate",
	TeamEdit_Telephone: "Telephone",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Mobile must be numbers",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telephone must be numbers",
	TeamEdit_Activate: "Active",
	TeamEdit_NotActivated: "Not Activate",
	TeamEdit_StatusPopup: "The status will be shown as ‘Active’ if the user has successfully created a personal account. If the status is set as ‘Not active’, it means that the user has not completed your invitation.",
	Training_Catalog_Courses: "Courses",
	Training_Catalog_Course: "Course",
	Reset_Filter: "Reset Filter",
	TrainingCatalogue_CourseCatalogue: "Course Catalog",
	CheckoutHeader_CartItemCount_Text: "You have [count] item in your shopping cart",
	CheckoutHeader_CartItemsCount_Text: "You have [count] items in your shopping cart",
	ShoppingCart_PriceItem: "Item price",
	ShoppingCartItemParticipants_AddParticipant_Button: "Add participants",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotal",
	ShoppingCartTotalPrice_TaxesLabel: "Taxes",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Includes VAT of [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Available online payment methods",
	ShoppingCartItemRow_VenueFeeLabel: "Venue fee [fee_amount] per participant",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Add participant",
	AddParticipantsModal_SelectEmployeeLabel: "Add Participant",
	AddParticipantsModal_SelectWorkshopLabel: "Select Workshop",
	ShoppingCartTotalPrice_ContinueButtonText: "Continue to checkout",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Participant has not been added!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "You have one or more courses in you shopping cart without a participant.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Please add a participant to continue checkout.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Edit participants ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Name",
	AddParticipantsModal_ParticipantTable_Email: "E-mail",
	AddParticipantsModal_ParticipantTable_Action: "Action",
	AddParticipantsModal_ParticipantTable_NoParticipant: "No participants…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "E-mail address not configured on selected participant",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "The selected participant doesn't have an e-mail address configured on their profile. E-mail address is mandatory for registering to a training course.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Add e-mail address to continue or cancel to select another participant.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Add e-mail",
	CancelButton: "Cancel",
	AddParticipantsModal_AddEmployeeButtonText: "Add employee",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "You are about to delete a course from your cart",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "You are trying to remove a course with added participants from your cart.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Click \"Continue\" if you wish to proceed or click \"Cancel\".",
	ShoppingCartItemHeader_Participants: "Participant(s)",
	ShoppingCartItemHeader_ParticipantPrice: "Price per seat",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Show in [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Show in Credits",
	ShoppingCart_CreditsPriceUnit: "Credits",
	CheckOutHeader_Step1_Question: "How would you like to pay?",
	CheckOutHeader_Step2_Question: "Review your order",
	CheckOutHeader_Step3_Question: "Complete your order",
	CheckOutHeader_Step4_Question: "Your booking was successful!",
	CheckOutHeader_Step_Payment: "Payment",
	CheckOutHeader_Step_Review: "Review",
	CheckOutHeader_Step_Place_Order: "Place Order",
	CheckOutRightPanel_Review_Order_Btn: "Review order",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Place Order",
	CheckoutRightPanelContent_OrderSummary_Title: "Order Summary",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Edit shopping cart",
	ShoppingCart_Participants: "Participants",
	CheckOutStepPayment_Payment_Methods_Title: "Select payment method",
	CheckOutStepPayment_Credit_Title: "Credit or debit",
	CheckOutStepPayment_Wholesaler_Title: "Wholesaler",
	CheckOutStepPayment_ServiceCredit_Title: "Service Credits",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "You have [available_credit] Credits",
	CheckOutStepPayment_BoschAccount_Title: "Bosch Account",
	Checkout_Second_PaymentMethod_Headline: "Payment method",
	CheckOutStepReview_Edit: "Edit",
	CheckOutStepReview_Terms_Label: "Terms, conditions & cancellation policy",
	CheckOutStepReview_Terms_Text: "I have read, understood and agree to the [terms, conditions and cancellation policy] of sale.",
	SelectSecondPaymentMethodModal_Title: "Select secondary payment method",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Pay [amount]",
	StepPaymentWholesaler_Title: "Select wholesaler",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Accept Terms and Conditions",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Please read and accept our terms, conditions and cancellation policy before continuing.",
	CheckoutDescription_ThankYou: "Thank you for submitting an order on Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Our bits and bytes has already started processing your order. Meanwhile, please keep your engine at idle.",
	CheckoutDesctiption_Text: "We will send you a final confirmation by mail including all the details of your booking.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "You are about to remove the selected workshop",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "By removing the selected workshop, all added participants will be reset. This change will affect all the courses in your shopping cart.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Click \"Continue\" if you wish to proceed or click \"Cancel\" to stop this action.",
	CheckOutStepPayment_Insufficient_Credit_Message: "You have insufficient Credits. Your Credit balance is [available_credit] Credits, but you need [cart_total_credits] Credits to continue checkout. Please select another payment method or remove items from your shopping cart.",
	Training_centers_filter_items: "Centers",
	Training_Programs_Filter_Item: "Programs",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "Year(s)",
	Training_Program_Detail_Title: "Training Course Programmes",
	AccountButton_Workshops_MenuItem: "Workshops",
	AccountButton_Wallet_MenuItem: "Wallet",
	Header_MyBoschProfile_Header: "MyBosch Profile",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Available",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Add/Edit participant",
	ShoppingCart_CreditPriceUnit: "Credit",
	Common_IncludedVAT: "Incl. VAT",
	TrainingCatalogue_Length: "Length",
	TrainingCatalogue_Method: "Method",
	Course_Price_Credits: "Credits",
	Catalogue_Grid_Register_Button: "Register",
	Catalogue_Credit_Tilte: "Click here to view prices in Credits​",
	Catalogue_Price_Tilte: "Click here to view prices in EUR​",
	Catalogue_Grid_Vat: "VAT excL.",
	EventCalendar_Seats: "Seats",
	MenuHeader_Calendar: "Calendar",
	MenuHeader_Catalog: "Catalog",
	TableCell_Minutes: "Minutes",
	ListFilter_NoResult: "No Result",
	Course_Description: "Course Description​",
	Training_course_detail: "Social Media Basics​",
	Training_course_detail_add_waiting: "Add to general waiting list​",
	Register_now_btn: "Register now !",
	Register_now_btn_add_event_to_cart: "Register now !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "View all records",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "View less",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "View all records",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "View less",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "View all records",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "View less",
	TeamCreate_TitleError: "Title is required",
	Dashboard_Headerbar_BookCourse: "Book course",
	Dashboard_Headerbar_ExitProxy: "Exit Proxy",
	Dashboard_Headerbar_EvaluateCourse: "Evaluate Course",
	Dashboard_Headerbar_EvaluateCourse_Note: "Please evaluate this course to generate your certificate!",
	Dashboard_Headerbar_DownloadCertificate: "Download Certificate",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Withdraw",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Withdraw",
	Dashboard_Headerbar_WelcomeMessage: "Welcome to your learning dashboard",
	Dashboard_Headerbar_ProxingAs: "Proxying as",
	Dashboard_TrainingProgram_Status_Completed: "Completed",
	Dashboard_TrainingProgram_Status_InProgress: "In progress",
	External_SelectWholesalerModal_Title: "Add external user access",
	External_SelectWholesalerModal_Company_Label: "Company",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Please select",
	External_SelectWholesalerModal_Outlet_Placeholder: "Please select",
	External_SelectWholesalerModal_ApplyButton: "Apply",
	External_SelectWholesalerModal_CancelButton: "Cancel",
	External_RemoveWholesalerModal_ConfirmLabel: "Confirmation",
	External_SelectWholesalerModal_ConfirmMessage: "Are you sure you want to remove this wholesaler?",
	External_SelectWholesalerModal_YesButton: "Yes",
	External_SelectWholesalerModal_NoButton: "No",
	Training_program_team_member: "Team member",
	Training_program_assign_training_program: "Assign training program",
	Assign_modal_student_confirmation_text1: "You are about to assign [Training Program Name] to your learning journey.",
	Assign_modal_student_confirmation_text2: "Would you like to continue?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "May",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Jul",
	MyFullCalendar_Aug: "Aug",
	MyFullCalendar_Sep: "Sep",
	MyFullCalendar_Oct: "Oct",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dec",
	MenuHeader_CourseCatalog: "Course catalog",
	MenuHeader_Event: "Event calendar",
	MenuHeader_Program: "Traning program",
	MenuHeader_Services: "Training services",
	MenuHeader_Log_out: "Log out",
	Filter_Location: "Location",
	TeamEdit_UpgradeToCompanyAdministrator: "Upgrade to Company administrator",
	TeamEdit_PersonalWebAccountStatusIs: "Personal web account status is",
	Header_CreateNewEmployee: "Create New Employee",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "No seats available!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "There are no available seats on the selected event.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Please select another event or remove an already added participant to continue.",
	Common_SuccessModal_Title: "Congratulations!",
	TrainingProgramDetails_Merit_StatusText: "Merit",
	TrainingProgramDetails_Resit_StatusText: "Resit",
	EmployeeProfile_UserExistErrorMessage: "The account is already existing!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Payment failed. Please try again or use another payment method.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "You have successfully deactivated the selected employee!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Saved!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Your changes have been saved.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Withdrawal not possible on eLearning courses. Please contact us if further details are required.",
	Dashboard_Team_PersonalAccount_Pending: "Pending",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Employee created!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Your Employee creation have been successfully completed.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "This setting enables you to restrict an employee from registering to training events. Only company administrators will be able to register the employee to training events when this setting has been unselected.",
	Training_catalog_sort_by: "Sort by",
	CourseDetail_EvaluationRestriction_Title: "Training evaluation not possible!",
	CourseDetail_EvaluationRestriction_Message: "Training evaluations can only be submitted by the student it self. \nPlease instruct the student to log in to Bosch Automotive Training Solutions and complete the training evaluation.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "If the student doesn't already have an account, an invitation can be shared in the user profile settings of the team member.",
	Breadcrumb_Download_Page: "Download",
	Download_Table_Category_Cell: "Category",
	Download_Table_Title_Cell: "Title",
	Download_File_Filter_Item: "File",
	Download_File_Filter_Items: "Files",
	Download_Header: "Download Area",
	DownloadArea_Button_Download: "Download",
	Popup_Title_Error: "Error",
	Popup_Title_Success: "Success",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Warning!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Following potential event registrations already existing, will not be cancelled, and will have to be cancelled individually.",
	TrainingProgram_PDF_Export: "PDF Export",
	TrainingProgram_PDF_Export_Participant: "Paticipant:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Overall Progress:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Training Title",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Grade",
	TrainingProgram_PDF_Export_TableHeader_Date: "Date",
	AccountButton_CompanyChange_MenuItem: "Company Change",
	SelectCompanyModal_Title: "Select Company",
	CloseButton: "Close",
	Change_Button: "Change",
	ChangeManagerModal_Content: "Select User",
	ChangeCompanyModal_HeadOffice: "(Head Office)",
	SelectCompanyModal_Content: "Please select Company to login…",
	AlertSuccess_Title: "Success",
	CheckOutStepPayment_SecondaryPayment_Title: "Select secondary payment method",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Add secondary payment method",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Please add secondary payment method below or select another payment method above.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "You have insufficient Service Credits on your account to pay for your cart items. To continue using the selected payment method, you must pay the difference. The difference is [difference_amount] (excl. vat).",
	ShoppingCartTotalPrice_TotalInCredit: "Total price in credits",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Your available credits",
	ShoppingCartTotalPrice_YourDifference: "Difference",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal excl. Tax",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal incl. tax",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Secondary payment method",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Edit payment method",
	Checkout_Second_PaymentMethod: "Second Payment method",
	Breadcrumb_TrainingCourseDetails: "Training course details",
	DownloadArea_FailedToDownload_ErrorMessage: "It's failed to download document. Please try again or contact your Company Administrator for assistance.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Download file is not available. Please try again or contact your Company Administrator for assistance.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Download file is forbidden. Please try again or contact your Company Administrator for assistance.",
	SSO_Redirecting_Message: "You are redirecting to Bosch Training portal...",
	SSO_Redirecting_ParameterErrorMessage: "Country and/or Language are not specifed. Please try again or contact your Company Administrator for assistance.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Enrolment request(s) not accepted.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "This may be due to being within the course's registration threshold days for one or more of the courses. Please use the 'Contact us' area if you have any questions or requests.",
	TimeSlotPicker_DateFormat_Title: "Date format",
	UpdateAccountSettingsConfirmationModal_Title: "You are about to change the date format!",
	UpdateAccountSettingsConfirmationModal_Message1: "The date format will be displayed according to your selection and can be changed at any time.",
	UpdateAccountSettingsConfirmationModal_Message2: "Do you wish to continue?",
	ProfileSummary_Settings_Title: "Settings",
	UpdateAccountSettings_DateFormat_Title: "Date format settings",
	UpdateAccountSettings_DateFormat_ErrorMessage: "It is failed to update Date format.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Date format setting has been updated successfully.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Download Certificate",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Training Program Certificate",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Download failed. Please try again. If the issue persists, please contact us via our contact form.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Withdrawal threshold exceeded",
	CourseWithdrawalModal_PendingWithdraw_Message1: "The threshold for withdrawing from this course has been exceeded and requires manual processing by our Training Administration.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Final confirmation will be sent by email.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Please refer to our [cancellation policy] for further details.",
	TrainingCourse_Course_NotAvailable_Title: "Training course is unavailable",
	TrainingCourse_Course_NotAvailable_Message: "Training course is unavailable. Please try again or contact your Company Administrator for further assistance.",
	TrainingCourse_NotAvailable_Message_1: "You are trying to view a training course which is unavailable.",
	TrainingCourse_NotAvailable_Message_2: "Please go back to our [training catalog] to find all available courses.",
	LanguageSelection_SelectALanguage: "Select language",
	LanguageSelection_SelectYourCountry: "Select your country",
	Checkout_PaymentMethod_Headline: "Payment method",
	Download_Categories_Catalogue: "Catalogue",
	Download_Categories_Calendar: "Calendar",
	Download_Categories_General: "General",
	Download_Categories_Information: "Information",
	Download_Categories_Userguides: "User guides",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "cancellation policy",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Cancel",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Continue",
	Duration_Seconds: "Seconds",
	Duration_Day: "Day",
	Duration_Hour: "Hour",
	Duration_Minute: "Minute",
	Duration_Second: "Second",
	EventCalendar_ExternalLink_Tooltips: "Purchase via [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Cancel",
	EventCalendar_ExternalLink_Continue: "Continue",
	EventCalendar_ExternalLinkModal_Title: "You are about to be redirected...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "The selected event is being sold via [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Continuing will redirect you to an external website.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Would you like to continue?",
	Team_ContactPerson_FirstName_Tooltips: "Student names can only be modified by the Bosch training administration team. Please contact us via the contact form on this website for further support.",
	Team_ContactPerson_LastName_Tooltips: "Student names can only be modified by the Bosch training administration team. Please contact us via the contact form on this website for further support.",
	Withdraw_ExternalLink_Cancel: "Cancel",
	Withdraw_ExternalLink_Confirm: "Confirm",
	Withdraw_ExternalLinkModal_Title: "You are about to be redirected",
	Withdraw_ExternalLinkModal_Message: "Withdrawals for this course is handled by an external provider.",
	Withdraw_ExternalLinkModal_Confirmation: "Clicking \"Confirm\" will open a new tab and redirect you to an external website.",
	Button_AddToWaitingList_Tooltips: "Add to Waiting List",
	Button_AddToCart_Tooltips: "Add to Cart",
	Button_TrainingCourseDetail_Tooltips: "View Details",
	Button_TrainingProgramDetail_Tooltips: "View Details",
	Button_AssignTrainingProgram_Tooltips: "Assign training program",
	Button_DeleteFromCart_Tooltips: "Delete from Cart",
	Button_RemoveParticipant_Tooltips: "Remove participant",
	Button_DownloadBillingTransaction_Tooltips: "Download",
	Button_RemoveExternal_Tooltips: "Remove",
	Breadcrumb_TrainingServices: "Training Services",
	Breadcrumb_Support: "Support",
	Breadcrumb_FAQs: "FAQs",
	Breadcrumb_TrainingProgramDetail: "Training Program Detail",
	Breadcrumb_MyTraining_MyTrainingEvents: "My Training Events",
	Breadcrumb_MyTraining_External: "External",
	Breadcrumb_MyTraining_Wallet: "Wallet",
	Breadcrumb_MyTraining_Workshop: "Workshop",
	Breadcrumb_MyTraining_Workshop_Team: "Team",
	Breadcrumb_MyTraining_Team_Profile: "Profile",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Edit Profile",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Employee Creation",
	SideNavigation_Title: "Training Solutions",
	SideNavigation_AccountButton_RegisterOrLogin: "Register / Login",
	SideNavigation_Language: "Language",
	SideNavigation_SelectLanguage: "Select language",
	SideNavigation_AccountButton_Logout: "Logout",
	SideNavigation_ConntactUs: "Contact us",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Corporate information",
	FooterAdditionalLinks_Navigation_LegalNotice: "Legal notice",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Data protection notice",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Privacy settings",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Terms & Conditions",
	Common_Version_Text: "Version: {{version}}",
	Content_Header_Dashboard_MyTrainingEvents: "My Training Events",
	Content_Header_Dashboard_MyTrainingPrograms: "My Training Programs",
	Content_Header_Dashboard_Team: "Team",
	Content_Header_Dashboard_Workshops: "Workshops",
	Content_Header_Dashboard_External: "External",
	Content_Header_Dashboard_Wallet: "Wallet",
	Content_Header_Dashboard_MyProfile: "My Profile",
	RedeemVoucher_RemoveButton: "Remove",
	RedeemVoucher_ApplyButton: "Apply",
	Checkout_VoucherApplied_Label: "Your code",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Invalid Voucher code",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "The Voucher code that were applied is invalid.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Please try again or continue without Voucher code.",
	RedeemVoucherModal_CancelButton: "Cancel",
	RedeemVoucherModal_TryAgainButton: "Try again",
	CreditsConfirmationModal_ContinueButton: "Continue",
	CreditsConfirmationModal_CancelButton: "Cancel",
	CreditsConfirmationModal_Title: "A voucher has been applied",
	CreditsConfirmationModal_Message_1: "Payment with Service Credits is not possible when a discounted voucher has been applied to your shopping cart.",
	CreditsConfirmationModal_Message_2: "Click \"Cancel\" to go back or click \"Continue\" to remove the voucher from your cart.",
	TrainingCourseCatalog_PricePerSeat: "Price per seat",
	TrainingCourseCatalog_Duration: "Duration:",
	Homepage_Title_BoschTrainingSolutions: "Bosch Training Solutions"
};