import { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";

const Boschicon = (props: any) => {
  const [selctedIcon, setselectedicon] = useState(0);
  const { name, style, ...data } = props;
  //Add below bosch font icon unicode and give name
  const iconconfig = [
    { name: "shopping-cart", key: "\ue247" },
    { name: "bell-plus", key: "\ue1f7" },
    { name: "alert-info", key: "\ueb0f" },
    { name: "filter", key: "\ue16e" },
    { name: "grid-view", key: "\ue190" },
    { name: "table-view", key: "\ue1c0" },
    { name: "up-small", key: "\ue6d4" },
    { name: "down-small", key: "\ue6c3" },
    { name: "up", key: "\ue296" },
    { name: "down", key: "\ue147" },
    { name: "bosch-share", key: "\ue242" },
    { name: "bosch-print", key: "\ue213" },
    { name: "bosch-delete", key: "\ue118" },
    { name: "bosch-edit", key: "\ue152" },
    { name: "bosch-add", key: "\ue087" },
    { name: "bosch-minimize", key: "\ue1ba" },
    { name: "locked", key: "\ue1c8" },
    { name: "spark", key: "\ue25d" },
    { name: "bosch-login", key: "\ue1ca" },
    { name: "bosch-logout", key: "\ue1cc" },
    { name: "bosch-forward-right", key: "\ue181" },
    { name: "bosch-close", key: "\ue0f0" },
    { name: "bosch-upload-frame", key: "\ue89f" },
    { name: "bosch-download-frame", key: "\ue893" },
    { name: "bosch-pdf-doc", key: "\ue133" },
    { name: "bosch-calendar-add", key: "\ue0c9" },
    { name: "bosch-copy", key: "\ue10c" },
    { name: "bosch-check-outline", key: "\ue891" },
    { name: "bosch-export-download", key: "\ue149" },
    { name: "bosch-ic-alert-warning-filled", key: "\ueb0c" },
    { name: "bosch-ic-recording", key: "\ue481" },
    { name: "bosch-ic-alert-warning", key: "\ueb0d" },
    { name: "bosch-ic-alert-error", key: "\ueb09" },
    { name: "bosch-ic-alert-success", key: "\ueb0b" },
    { name: "bosch-ic-denied", key: "\ue11a" },
    { name: "bosch-ic-badge-checkmark", key: "\ue3ee" },
    { name: "bosch-ic-clear-all", key: "\ue0ec" },
    { name: "bosch-ic-document", key: "\ue124" },
    { name: "bosch-ic-bookmark", key: "\ue0b5" },
    { name: "bosch-ic-document-clock-cycle", key: "\ue418" },
    { name: "bosch-ic-money-currency-coins", key: "\ueb10" },
    { name: "bosch-ic-externallink", key: "\ue167" },
    { name: "bosch-ic-service-time", key: "\ue23e" },
    { name: "bosch-ic-garage", key: "\ue597" },
    { name: "bosch-ic-car-connected", key: "\ue0d5" },
    { name: "bosch-ic-registration", key: "\ue220" },
    { name: "bosch-ic-desktop-dashboard", key: "\ue410" },
    { name: "bosch-ic-certificate", key: "\ue0dd" },
    { name: "bosch-ic-presentation-speaker", key: "\ue4c9" },
    { name: "bosch-ic-team-5", key: "\ue66d" },
    { name: "bosch-ic-checklist-stack", key: "\ue3fe" },
    { name: "bosch-ic-keys-user-access", key: "\ue42d" },
    { name: "bosch-ic-coins", key: "\ue50e" },
    { name: "bosch-ic-search", key: "\ue235"},
    { name: "bosch-ic-back-left", key: "\ue0a0" },
    { name: "bosch-ic-user-professional" , key: "\ue2a3"},
    { name: "bosch-ic-lock-open" , key: "\ue1c9"},
    { name: "bosch-ic-lock-closed" , key: "\ue1c8"}, 
    { name: "bosch-ic-arrow-left", key: "\ue090" },
    { name: "bosch-ic-map" , key: "\ue1d5"}, 
    { name: "bosch-ic-question-frame" , key: "\ue89b"}, 
    { name: "bosch-ic-abort-frame" , key: "\ue886"}, 
    { name: "bosch-ic-start-play-frame" , key: "\ue89e"}, 
    { name: "bosch-ic-add", key: "\ue087" },
    { name: "bosch-ic-user-add", key: "\ue2f7" },
    { name: "bosch-ic-payment", key: "\ue204" },
    { name: "bosch-ic-paperplane", key: "\ue201" },
    { name: "bosch-ic-checkmark", key: "\ue0e9" },
    { name: "bosch-ic-thumb-up", key: "\ue27e" },
    { name: "bosch-ic-lock-open", key: "\ue1c9" },
    { name: "bosch-ic-my-brand-frame", key: "\ue897" },
    { name: "bosch-ic-down", key: "\ue147" },
    { name: "bosch-ic-document-pdf", key: "\ue133" },
    { name: "bosch-ic-document-x", key: "\ue141" },
    { name: "bosch-ic-document-ppt", key: "\ue137" },
    { name: "bosch-ic-document-doc", key: "\ue12d" },
    { name: "bosch-ic-imagery", key: "\ue1a2" },
    { name: "bosch-ic-locator", key: "\ue1c3" },
    {name: "bosch-ic-map-city", key: "\ue4ee"}
  ];

  useEffect(() => {
    setselectedicon(iconconfig.findIndex((val) => val.name === name));
  }, [props.name]);

  return (
    <Icon className="Boschicon" {...data} style={style}>
      {iconconfig[selctedIcon]?.key || iconconfig[0].key}{" "}
    </Icon>
  );
};
export default Boschicon;
