import { FC } from "react";
import { useAxiosLoader } from "../../../hooks/useAxiosLoader";
import "./GlobalLoader.css";

export interface GlobalLoaderProps {
  showLoadingDirect?: boolean;
}
const GlobalLoader: FC<GlobalLoaderProps> = ({ showLoadingDirect }) => {
  const loading = useAxiosLoader();

  return (
    <div
      id="loadingPanel"
      className={`loadingPanel ${loading || showLoadingDirect ? " show" : ""}`}
    >
      <div className="a-activity-indicator" aria-live="off">
        <div className="a-activity-indicator__top-box"></div>
        <div className="a-activity-indicator__bottom-box"></div>
      </div>
    </div>
  );
};

export default GlobalLoader;
