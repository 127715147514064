import { useEffect, useState } from "react";
import { http } from "../services/HttpService";
import { httpNoneSecure } from "../services/HttpServiceNoneSecure";

export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);

  const inc = (mod: number) => {
    setCounter((c) => {
        const count = c + mod;
        return count;
    });
  };

  useEffect(() => {
    const handleRequest = (config: any) => {
      inc(1);
      return config;
    };
    const handleResponse = (response: any) => {
      inc(-1);
      return response;
    };
    const handleError = (error: any) => {
      inc(-1);
      return Promise.reject(error);
    };

    // add request interceptors
    const reqInterceptor = http.interceptors.request.use(
      handleRequest,
      handleError
    );
    // add response interceptors
    const resInterceptor = http.interceptors.response.use(
      handleResponse,
      handleError
    );

    // add request interceptors
    const reqInterceptorHttpNoneSecure =
      httpNoneSecure.interceptors.request.use(handleRequest, handleError);
    // add response interceptors
    const resInterceptorHttpNoneSecure =
      httpNoneSecure.interceptors.response.use(handleResponse, handleError);

    return () => {
      // remove all intercepts when done
      http.interceptors.request.eject(reqInterceptor);
      http.interceptors.response.eject(resInterceptor);

      httpNoneSecure.interceptors.request.eject(reqInterceptorHttpNoneSecure);
      httpNoneSecure.interceptors.response.eject(resInterceptorHttpNoneSecure);
    };
  }, []);

  return counter > 0;
};
