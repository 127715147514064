import { reducer as formReducer } from "redux-form";
import authBatsReducer from "../redux-toolkit/authReducer";
import {
  PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import accountBatsReducer from "./accountReducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import regionBatsReducer from "./regionReducer";
import shoppingCartReducer from "./shoppingCartReducer";
import checkoutReducers from "./checkoutReducer";
import dashboardReducer from "./dashboardReducer";
import searchReducer from "./searchReducer";
import dashboardEmployeeReducer from "./dashboardEmployeeReducer";
import trainingCourseReducer from "./trainingCourseReducer";
import homeReducer from "./homeReducer";
import trainingEventReducer from "./trainingEventReducer";
import trainingProgramReducer from "./trainingProgramReducer";
import contactReducer from "./contactReducer";
import trainingCenterReducer from "./trainingCenterReducer";
import maintenanceReducer from "./maintenanceReducer";

const rootReducer = combineReducers({
  form: formReducer,
  authBats: authBatsReducer,
  accountBats: accountBatsReducer,
  regionBats: regionBatsReducer,
  checkout: checkoutReducers,
  dashBoard: dashboardReducer,
  shoppingCartItem: shoppingCartReducer,
  searchStateBats: searchReducer,
  dashboardEmployeeReducer: dashboardEmployeeReducer,
  trainingCourseReducer: trainingCourseReducer,
  homeReducer: homeReducer,
  trainingEventReducer: trainingEventReducer,
  trainingProgramReducer: trainingProgramReducer,
  contactReducer: contactReducer,
  trainingCenterReducer: trainingCenterReducer,
  maintenanceReducer: maintenanceReducer,
});

const persistConfig = {
  key: "rootStoreBats",
  version: 1,
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false });
  },
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}
export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
