import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import {
  ContactPersonModel,
  ContactPersonRequestModel,
} from '../models/ContactPerson.Model'
import ResponseResultBase from '../models/ResponseResultBase'
import {
  AddToWaitListRequestModel,
  DeleteFromWaitListRequestModel,
  GetWaitListByParticipantIdRequestModel,
  WaitListItem,
} from '../models/Waitlist.Model'
import { http } from '../services/HttpService'
import { AppDispatch, RootState } from './store'

export const addToWaitListAsync = createAsyncThunk<
  ResponseResultBase<number> | null,
  AddToWaitListRequestModel,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>(
  'WaitList/AddToWaitList',
  async (req: AddToWaitListRequestModel, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `/api/v1/WaitList/AddToWaitList`,
        req,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return null
  },
)

export const getWaitListByParticipantIdAsync = createAsyncThunk<
  ResponseResultBase<WaitListItem[]> | null,
  GetWaitListByParticipantIdRequestModel,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>(
  'contacts/GetWaitListByParticipantId',
  async (req: GetWaitListByParticipantIdRequestModel, thunkApi) => {
    try {
      const model = {
        participantId: req.participantId,
        ciamId: req.ciamId,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
      }
      let response = await http.post<ResponseResultBase<WaitListItem[]>>(
        `/api/v1/WaitList/GetWaitListByParticipantId`,
        model,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return null
  },
)

export const deleteFromWaitListAsync = createAsyncThunk<
  ResponseResultBase<number> | null,
  DeleteFromWaitListRequestModel,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>(
  'contacts/DeleteFromWaitList',
  async (req: DeleteFromWaitListRequestModel, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `/api/v1/WaitList/DeleteFromWaitList`,
        req,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error)
      }
    }
    return null
  },
)
