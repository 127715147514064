import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { SignoutResponse, User } from "oidc-client-ts";
import { Constants } from "../helpers/Constants";
import {
  Account,
  CreateUpdateAccountInput,
  impersonation,
} from "../models/Account";
import ResponseResultBase from "../models/ResponseResultBase";
import { AuthService } from "../services/AuthService";
import { http } from "../services/HttpService";
import { httpEditProfile } from "../services/HttpServiceEditProfile";
import { AppDispatch, RootState } from "./store";
import { AccountSettingsModel } from "../models/accountSettings/AccountSettings.model";
import {
  createOrUpdateAccountSettingsAsync,
  getAccountSettingsAsync,
} from "../services/AccountSettingsService";

export type DisplayPriceType = "credit" | "currency";
export interface AccountState {
  account: Account | null;
  displayCreditPrice: DisplayPriceType;
  originalBranch: Account | null;
  impersonationList: impersonation[] | undefined;
  onSubWorkshopBranch: boolean;
  accountSettings: AccountSettingsModel | null;
}

const initialState: AccountState = {
  account: null,
  displayCreditPrice: "currency",
  originalBranch: null,
  impersonationList: [],
  onSubWorkshopBranch: false,
  accountSettings: null,
};

export const getAccountSimpleAsync = createAsyncThunk<
  ResponseResultBase<Account> | null,
  string | null | undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/getAccountSimple", async (ciamid, thunkApi) => {
  try {
    let model = {
      ciamId: ciamid,
    };
    let response = await http.post<ResponseResultBase<Account>>(
      `/api/v1/accounts/GetAccountSimple`,
      model
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getAccountOriginSimpleAsync = createAsyncThunk<
  ResponseResultBase<Account> | null,
  string | null | undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/getAccountOriginSimpleAsync", async (ciamid, thunkApi) => {
  try {
    let model = {
      ciamId: ciamid,
    };
    let response = await http.post<ResponseResultBase<Account>>(
      `/api/v1/accounts/GetAccountSimple`,
      model
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getAccountSimpleForEditProfileAsync = createAsyncThunk<
  ResponseResultBase<Account> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/getAccountSimpleForEditProfileAsync", async (ciamid, thunkApi) => {
  try {
    let response = await httpEditProfile.get<ResponseResultBase<Account>>(
      `/api/v1/accounts/GetAccountSimple?ciamId=${ciamid}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getAccountExtendedAsync = createAsyncThunk<
  ResponseResultBase<Account> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/getAccountExtended", async (ciamid, thunkApi) => {
  try {
    let model = {
      ciamId: ciamid,
    };
    let response = await http.post<ResponseResultBase<Account>>(
      `/api/v1/accounts/GetAccountExtended`,
      model
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
  }
  return null;
});

export const createAccount = createAsyncThunk<
  ResponseResultBase<Account> | null,
  CreateUpdateAccountInput,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "account/createAccount",
  async (account: CreateUpdateAccountInput, thunkApi) => {
    let result: ResponseResultBase<Account> | null = null;
    try {
      let response = await http.post<ResponseResultBase<Account>>(
        `/api/v1/accounts`,
        account
      );
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const createAccountForEditProfile = createAsyncThunk<
  ResponseResultBase<Account> | null,
  CreateUpdateAccountInput,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "account/createAccountForEditProfile",
  async (account: CreateUpdateAccountInput, thunkApi) => {
    let result: ResponseResultBase<Account> | null = null;
    try {
      let response = await httpEditProfile.post<ResponseResultBase<Account>>(
        `/api/v1/accounts`,
        account
      );
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const updateSignInAccount = createAsyncThunk<
  ResponseResultBase<Account> | null,
  Account,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/updateSignInAccount", async (account: Account, thunkApi) => {
  let result: ResponseResultBase<Account> | null = null;
  try {
    let response = await http.put<ResponseResultBase<Account>>(
      `/api/v1/accounts/${account.ciamId}`,
      account
    );
    if (
      response.status === 204 ||
      response.status === 200 ||
      response.status === 201 ||
      response.status === 202
    ) {
      result = {
        dataObject: account,
        errorMessages: [],
        httpStatusCode: 200,
        success: true,
      };
    } else {
      result = response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return result;
});

export const updateAccount = createAsyncThunk<
  ResponseResultBase<Account> | null,
  CreateUpdateAccountInput,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "account/updateAccount",
  async (account: CreateUpdateAccountInput, thunkApi) => {
    let result: ResponseResultBase<Account> | null = null;
    try {
      let response = await http.put<ResponseResultBase<Account>>(
        `/api/v1/accounts/${account.ciamId}`,
        account
      );
      result = response.data;
      /*if (response.status === 204 || response.status === 200 || response.status === 201) {
          result = {
            dataObject: account,
            errorMessages: [],
            httpStatusCode: 200,
            success: true
          };
        } else {
          result = response.data;
        }*/
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const loginCallbackAsync = createAsyncThunk<
  User | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/loginCallbackAsync", async () => {
  let result: User | null = null;
  try {
    const accountService = AuthService.getInstance();
    result = await accountService.loginCallbackAsync();
  } catch (error) {}
  return result;
});

export const logoutCallbackAsync = createAsyncThunk<
  SignoutResponse | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/logoutCallbackAsync", async () => {
  let result: SignoutResponse | null = null;
  try {
    const accountService = AuthService.getInstance();
    result = await accountService.logoutCallbackAsync();
  } catch (error) {}
  return result;
});

export const loginAsync = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/loginAsync", async () => {
  //var result: SignoutResponse | null = null;
  try {
    const accountService = AuthService.getInstance();
    //result =
    await accountService.loginAsync();
  } catch (error) {}
  //return result;
});

export const logoutAsync = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/logoutAsync", async () => {
  //var result: SignoutResponse | null = null;
  try {
    const accountService = AuthService.getInstance();
    //result =
    await accountService.logoutAsync();
  } catch (error) {}
  //return result;
});

export const accountSlice = createSlice({
  name: "account-bats-reducer",
  initialState,
  reducers: {
    getAccountState: (state) => {
      return { ...state };
    },
    setDisplayCredit: (state, action: PayloadAction<DisplayPriceType>) => {
      return {
        ...state,
        displayCreditPrice: action.payload,
      };
    },
    setAccountImpersonationList: (state, action) => {
      return {
        ...state,
        originalBranch: action.payload.dataObject,
        impersonationList: action.payload.dataObject.impersonationList,
      };
    },
    setOnSubWorkshopBranch: (state, action) => {
      return {
        ...state,
        onSubWorkshopBranch: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountSimpleAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          state.account = payload.dataObject;
        }
      })
      .addCase(getAccountOriginSimpleAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          state.originalBranch = payload.dataObject;
          state.impersonationList = payload.dataObject?.impersonationList;
        }
      })
      .addCase(getAccountExtendedAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          state.account = payload.dataObject;
        }
      })
      .addCase(createAccount.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          (payload.httpStatusCode === 200 || payload.httpStatusCode === 201)
        ) {
          state.account = payload.dataObject;
        }
      })
      .addCase(updateAccount.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          (payload.httpStatusCode === 200 ||
            payload.httpStatusCode === 201 ||
            payload.httpStatusCode === 204)
        ) {
          state.account = payload.dataObject;
        }
      })
      .addCase(updateSignInAccount.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          (payload.httpStatusCode === 200 ||
            payload.httpStatusCode === 201 ||
            payload.httpStatusCode === 204)
        ) {
          state.account = payload.dataObject;
        }
      })
      .addCase(logoutCallbackAsync.fulfilled, (state, { payload }) => {
        state.account = null;
      })
      .addCase(getAccountSettingsAsync.fulfilled, (state, { payload }) => {
        if (payload?.dataObject) {
          return {
            ...state,
            accountSettings: payload.dataObject,
          };
        }
        return {
          ...state,
        };
      })
      .addCase(
        createOrUpdateAccountSettingsAsync.fulfilled,
        (state, { payload }) => {
          if (payload?.dataObject) {
            return {
              ...state,
              accountSettings: payload.dataObject,
            };
          }
          return {
            ...state,
          };
        }
      );
  },
});

export const selectAccount = (rootState: RootState) =>
  rootState.accountBats.account;
export const selectCiamId = (rootState: RootState) =>
  rootState.accountBats.account?.ciamId ?? "";
export const selectCiamEmail = (rootState: RootState) =>
  rootState.accountBats.account?.ciamEmail;
export const selectCustomerId = (rootState: RootState) =>
  rootState.accountBats.account?.customerId;
export const selectEmployee = (rootState: RootState) =>
  rootState.accountBats.account?.employee;
export const selectEmployeeId = (rootState: RootState) =>
  rootState.accountBats.account?.employee?.id;
export const selectUserRole = (rootState: RootState) =>
  rootState.accountBats.account?.userRole?.role;
export const selectUserRoleId = (rootState: RootState) =>
  rootState.accountBats.account?.userRole?.id;
export const selectGuid = (rootState: RootState) =>
  rootState.accountBats.account?.employee?.guid;
export const selectBatsId = (rootState: RootState) =>
  rootState.accountBats.account?.batsId;
export const selectAllowsCreditAccount = (rootState: RootState) =>
  rootState.accountBats.account?.supportPaymentMethods?.allowsCreditAccount;
export const selectDisplayCredit = (rootState: RootState) =>
  rootState.accountBats.displayCreditPrice;
export const selectAllowSelfEnroll = (rootState: RootState) => {
  if (
    !rootState.accountBats.account?.userRole?.role ||
    rootState.accountBats.account?.userRole?.role ===
      Constants.USER_ROLE_INDIVIDUAL
  )
    return rootState.accountBats.account?.employee?.allowSelfEnroll;
  return true;
};
export const selectOriginComp = (rootState: RootState) =>
  rootState.accountBats.originalBranch;
export const selectImList = (rootState: RootState) =>
  rootState.accountBats.impersonationList;
export const selectWhoseler = (rootState: RootState) =>
  rootState.accountBats.account?.associatedWholesalers;

export const selectBalance = (rootState: RootState) =>
  rootState.accountBats.account?.availableCredits?.credits;
export const selectOnSubWorkshopBranch = (rootState: RootState) =>
  rootState.accountBats.onSubWorkshopBranch;
export const selectAccountSettings = (rootState: RootState) =>
  rootState.accountBats.accountSettings;
export const selectDateFormatSetting = (rootState: RootState) =>
  rootState.accountBats?.account?.dateFormat;
export const {
  getAccountState,
  setDisplayCredit,
  setAccountImpersonationList,
  setOnSubWorkshopBranch,
} = accountSlice.actions;

export default accountSlice.reducer;
