import { MutableRefObject } from "react";

export const handleClickedOutOfComponent = (
  event: MouseEvent,
  componentRef: MutableRefObject<HTMLElement | null>,
  callback: Function
) => {
  if (
    componentRef.current &&
    !componentRef.current.contains(event.target as Node)
  )
    callback();
};

export const getHeightOfComponent = (
  ref: MutableRefObject<HTMLElement | null>
) => {
  if (!ref.current) return 0;

  return ref.current.offsetHeight;
};

export const getWidthOfComponent = (
  ref: MutableRefObject<HTMLElement | null>
) => {
  if (!ref.current) return 0;

  return ref.current.offsetWidth;
};

export const addClassNameForComponent = (
  ref: MutableRefObject<HTMLElement | null>,
  className: string
) => {
  if (!ref.current) return;

  ref.current.classList.add(className);
};

export const removeClassNameForComponent = (
  ref: MutableRefObject<HTMLElement | null>,
  className: string
) => {
  if (!ref.current) return;

  ref.current.classList.remove(className);
};

export const isComponentContainsClassName = (
  ref: MutableRefObject<HTMLElement | null>,
  targetClassName: string
) => {
  if (!ref.current) return false;

  return ref.current.classList.contains(targetClassName);
};

export const scrollIntoCurrentView = (
  ref: MutableRefObject<HTMLElement | null>
) => {
  if (!ref.current) return;

  ref.current.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "nearest",
  });
};

export const scrollToTop = (options?: ScrollToOptions) => {
  window.scrollTo(options || { top: 0, behavior: "smooth" });
};
