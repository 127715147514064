import { useEffect } from "react";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { logoutCallbackAsync } from "../../redux-toolkit/accountReducer";
import { AuthService } from "../../services/AuthService";
import "../../Common-components/loadable/Loading/GlobalLoader.css";
import { resetTrainingCourses } from "../../redux-toolkit/trainingCourseReducer";
import { resetTrainingEvents } from "../../redux-toolkit/trainingEventReducer";
import { resetTrainingPrograms } from "../../redux-toolkit/trainingProgramReducer";
import { resetTrainingCenters } from "../../redux-toolkit/trainingCenterReducer";
import { resetContactPersons } from "../../redux-toolkit/contactReducer";
import {
  selectMainContentPinned,
  setOpenSideNav,
  setRedirectURLCallback,
} from "../../redux-toolkit/authReducer";
import { useSelector } from "react-redux";

const SignOutCallback = () => {
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const isMainContentPinned = useSelector(selectMainContentPinned);

  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));

    // clear all contacts list
    dispatchReduxToolkit(resetContactPersons(undefined));

    setRedirectURLCallback("");
  };

  useEffect(() => {
    async function performLogout() {
      const response = await dispatchReduxToolkit(logoutCallbackAsync());
      if (logoutCallbackAsync.fulfilled.match(response)) {
        const authService = AuthService.getInstance();
        await authService.clearLoginUserState();
        setTimeout(() => {
          if (!isMainContentPinned) dispatchReduxToolkit(setOpenSideNav(false));
          window.location.href = "/";
        }, 2000);
      }
    }

    // resetLoadedStatusData();
    forceToReloadData();

    performLogout();
    // authService.logoutCallback().then((res) => {
    //     //authService.clearStaleState();
    //     dispatch(logout());
    //     dispatch(authSetSignIn(false));
    //     setTimeout(() => {
    //         window.location.href = "/";
    //     }, 2000)
    //     ///localStorage.("");
    //     //window.location.href = "/";
    // });
  }, [dispatchReduxToolkit]);

  return (
    <div className="loadingPanel show">
      <div className="a-activity-indicator" aria-live="off">
        <div className="a-activity-indicator__top-box"></div>
        <div className="a-activity-indicator__bottom-box"></div>
      </div>
    </div>
  );
};

export default SignOutCallback;
