import moment from "moment";
import { ShoppingCartItem } from "../models/CartItem.Model";
import {
  TrainingCourse,
  TrainingEvent,
  TrainingMethod,
  Venue,
} from "../models/TrainingEvent";
import formatShortDate from "../utils/dateTime";
import { Constants } from "./Constants";
import { EventDetails, EventEnrollmentDetail } from "../models/EventDetail";
import { CommonHelper } from "./CommonHelper";

export class TrainingEventHelper {
  static getLocation = (event: TrainingEvent, countryCode: string) => {
    if (!event) return "";

    if (!countryCode) return event?.venue?.city;

    if (
      countryCode.toLocaleUpperCase() === "AU" ||
      countryCode.toLocaleUpperCase() === "NZ"
    ) {
      return event?.venue?.headline;
    }

    return event?.venue?.city ?? "";
  };

  static getLocationEx(
    event: TrainingEvent,
    countryCode: string | undefined,
    additionalText: string
  ) {
    if (!event) return "-";

    if (
      event?.trainingCourse?.trainingMethods &&
      event?.trainingCourse?.trainingMethods?.length > 0
    ) {
      const isOnlineTraining = event.trainingCourse.trainingMethods.every((x) =>
        [
          Constants.VIDEO_LEARNING_METHOD_ID,
          Constants.WEB_BASE_LEARNING_METHOD_ID,
        ].includes(x.id)
      );
      if (isOnlineTraining) {
        return `${additionalText}`;
      }
    }

    if (!countryCode) return event.venue?.city;

    if (
      countryCode.toLocaleUpperCase() === "AU" ||
      countryCode.toLocaleUpperCase() === "NZ"
    )
      return event.venue?.headline;

    return event.venue?.city;
  }

  static isLanguageFilterAvailable(countryCode: string) {
    const retrictedCountries = ["UK", "IE", "DE", "AT", "CH", "AU", "NZ"];
    if (!countryCode) return true;
    return !retrictedCountries.includes(countryCode.toUpperCase());
  }

  static getLocationByVenue = (venue: Venue | null, countryCode: string) => {
    if (!countryCode) return venue?.city;

    if (
      countryCode.toLocaleUpperCase() === "AU" ||
      countryCode.toLocaleUpperCase() === "NZ"
    )
      return venue?.headline;

    return venue?.city;
  };

  static getTrainingNameFromCourse(
    course: TrainingCourse | undefined | null,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!course) return defaultValue;
    if (!!language && language.startsWith("en"))
      return course.englishName || course.localizedName || defaultValue;
    return course.localizedName || course.englishName || defaultValue;
  }

  static getLocalizedTrainingCourseType(
    course: TrainingCourse | undefined | null,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!course) return defaultValue;
    if (!!language && language.startsWith("en"))
      return (
        course?.trainingCourseType?.courseTypeName ||
        course?.trainingCourseType?.localized ||
        defaultValue
      );
    return (
      course?.trainingCourseType?.localized ||
      course?.trainingCourseType?.courseTypeName ||
      defaultValue
    );
  }

  static getLocalizedTrainingMethods = (
    trainingMethods: TrainingMethod[] | null | undefined,
    language: string,
    defaultValue: string = "-"
  ) => {
    if (trainingMethods && trainingMethods.length > 0)
      return trainingMethods
        .map((x) => {
          return TrainingEventHelper.getLocalizedTrainingMethod(
            x,
            language,
            defaultValue
          );
        })
        .join(", ");

    return defaultValue;
  };

  static getLocalizedTrainingMethod(
    method: TrainingMethod | undefined | null,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!method) return defaultValue;
    //if (!!language && language.startsWith("en"))
    //  return method?.trainingMethod || method?.localized || defaultValue;
    return method?.localized || method?.trainingMethod || defaultValue;
  }

  static getTrainingNameFromEvent(
    event: TrainingEvent | undefined,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!event || !event?.trainingCourse) return defaultValue;
    if (!!language && language.startsWith("en"))
      return (
        event?.trainingCourse?.englishName ||
        event?.trainingCourse?.localizedName ||
        defaultValue
      );
    return (
      event?.trainingCourse?.localizedName ||
      event?.trainingCourse?.englishName ||
      defaultValue
    );
  }

  static getLocalizedEnrollmentStatus(
    eventDetail: EventDetails | undefined | null,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!eventDetail) return defaultValue;
    if (!!language && language.startsWith("en"))
      return (
        eventDetail?.eventStatus?.english ||
        eventDetail?.eventStatus?.localized ||
        defaultValue
      );
    return (
      eventDetail?.eventStatus?.localized ||
      eventDetail?.eventStatus?.english ||
      defaultValue
    );
  }

  static getLocalizedEventEnrollmentStatus(
    eventDetail: EventEnrollmentDetail | undefined | null,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!eventDetail) return defaultValue;
    if (!!language && language.startsWith("en"))
      return (
        eventDetail?.eventStatus?.english ||
        eventDetail?.eventStatus?.localized ||
        defaultValue
      );
    return (
      eventDetail?.eventStatus?.localized ||
      eventDetail?.eventStatus?.english ||
      defaultValue
    );
  }

  static getLocalizedTrainingName(
    eventDetail: EventEnrollmentDetail | undefined | null,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!eventDetail) return defaultValue;
    if (!!language && language.startsWith("en"))
      return (
        eventDetail?.trainingNameEnglish ||
        eventDetail?.trainingNameLocalized ||
        defaultValue
      );
    return (
      eventDetail?.trainingNameLocalized ||
      eventDetail?.trainingNameEnglish ||
      defaultValue
    );
  }

  static getLocalizedTrainingEventStatus(
    trainigEvent: TrainingEvent | undefined | null,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!trainigEvent) return defaultValue;
    if (!!language && language.startsWith("en"))
      return (
        trainigEvent?.eventStatus?.english ||
        trainigEvent?.eventStatus?.localized ||
        defaultValue
      );
    return (
      trainigEvent?.eventStatus?.localized ||
      trainigEvent?.eventStatus?.english ||
      defaultValue
    );
  }

  static getTrainingNameFromShoppingCartItem(
    item: ShoppingCartItem,
    language: string,
    defaultValue: string = "-"
  ) {
    if (!item) return defaultValue;
    if (!!language && language.startsWith("en"))
      return item.trainingNameEnglish || item.trainingName || defaultValue;
    return item.trainingName || item.trainingNameEnglish || defaultValue;
  }

  static getTrainingEventDate(
    event: TrainingEvent | undefined,
    additionalText: string
  ) {
    if (!event) return "-";

    if (
      event?.trainingCourse?.trainingMethods &&
      event?.trainingCourse?.trainingMethods?.length > 0
    ) {
      const isOnlineTraining = event.trainingCourse.trainingMethods.every((x) =>
        [
          Constants.VIDEO_LEARNING_METHOD_ID,
          Constants.WEB_BASE_LEARNING_METHOD_ID,
        ].includes(x.id)
      );
      if (isOnlineTraining) {
        return "-";
      }
    }

    return `${formatShortDate(event.startDate?.toString())} [${
      event.trainingLength
    } ${additionalText}]`;
  }

  static getTrainingEventDateEx(event: TrainingEvent) {
    if (!event) return "-";

    if (
      event?.trainingCourse?.trainingMethods &&
      event?.trainingCourse?.trainingMethods?.length > 0
    ) {
      const isOnlineTraining = event.trainingCourse.trainingMethods.every((x) =>
        [
          Constants.VIDEO_LEARNING_METHOD_ID,
          Constants.WEB_BASE_LEARNING_METHOD_ID,
        ].includes(x.id)
      );
      if (isOnlineTraining) {
        return "-";
      }
    }

    return `${formatShortDate(event.startDate?.toString())} [${
      event.trainingLength
    }]`;
  }

  static getTrainingEventDateDuration(
    event: TrainingEvent | undefined,
    additionalText: string
  ) {
    if (!event) return "-";

    if (
      event?.trainingCourse?.trainingMethods &&
      event?.trainingCourse?.trainingMethods.length > 0
    ) {
      const isOnlineTraining = event.trainingCourse.trainingMethods.every((x) =>
        [
          Constants.VIDEO_LEARNING_METHOD_ID,
          Constants.WEB_BASE_LEARNING_METHOD_ID,
        ].includes(x.id)
      );
      if (isOnlineTraining) {
        return (
          `${event?.duration?.totalHours?.toFixed(2)} ${additionalText}` || "-"
        );
      }
    }

    return `${formatShortDate(event.startDate?.toString())} [${
      event.trainingLength
    }]`;
  }

  static isElearning(event: TrainingEvent | undefined) {
    if (!event || !event.trainingCourse) return false;
    return event.trainingCourse.isElearning;
  }

  static getTrainingEventDateDurationByFormat(
    event: TrainingEvent | undefined,
    additionalText: string,
    format: string,
    locale: string
  ) {
    if (!event) return "-";

    if (
      event?.trainingCourse?.trainingMethods &&
      event?.trainingCourse?.trainingMethods.length > 0
    ) {
      const isOnlineTraining = event.trainingCourse.trainingMethods.every((x) =>
        [
          Constants.VIDEO_LEARNING_METHOD_ID,
          Constants.WEB_BASE_LEARNING_METHOD_ID,
        ].includes(x.id)
      );
      if (isOnlineTraining) {
        return (
          `${event.duration?.totalHours?.toFixed(2)} ${additionalText}` || "-"
        );
      }
    }

    /*     let startDate = "-";
    if (format) {
      startDate = moment(event?.startDate?.toString()).format(format);
    } else {
      startDate = moment(event?.startDate?.toString()).format("DD-MM-YYYY");
    }
 */
    let localizationDate = CommonHelper.convertLocalizationDatetime(
      event?.startDate?.toString(),
      format,
      locale
    );

    return `${localizationDate} [${event.trainingLength}]`;
  }

  static getTrainingEventDateByFormat(event: TrainingEvent, format: string) {
    if (!event) return "-";

    if (
      event?.trainingCourse?.trainingMethods &&
      event?.trainingCourse?.trainingMethods?.length > 0
    ) {
      const isOnlineTraining = event.trainingCourse.trainingMethods.every((x) =>
        [
          Constants.VIDEO_LEARNING_METHOD_ID,
          Constants.WEB_BASE_LEARNING_METHOD_ID,
        ].includes(x.id)
      );
      if (isOnlineTraining) {
        return "-";
      }
    }

    let startDate = "-";
    if (format) {
      startDate = moment(event?.startDate?.toString()).format(format);
    } else {
      startDate = moment(event?.startDate?.toString()).format("DD-MM-YYYY");
    }

    return `${startDate} [${event.trainingLength}]`;
  }

  /* static convertLocalizationDatetime(
    eventDate: Date,
    format: string,
    locale: string
  ) {
    const dateWithFormat = new Date(eventDate?.toString());

    let month = new Intl.DateTimeFormat(locale, {
      month: format.includes("MMMM")
        ? "long"
        : format.includes("MMM")
        ? "short"
        : "2-digit",
    }).format(dateWithFormat);

    let day = new Intl.DateTimeFormat(locale, {
      day: format.includes("DDD") ? "numeric" : "2-digit",
    }).format(dateWithFormat);

    let year = new Intl.DateTimeFormat(locale, {
      year: format.includes("YYYY") ? "numeric" : "2-digit",
    }).format(dateWithFormat);

    let weekDay = new Intl.DateTimeFormat(locale, {
      weekday:
        format.includes("dddd") || format.includes("ddd") ? "long" : "short",
    }).format(dateWithFormat);

    let localizationDate = format
      .replace("dddd", weekDay)
      .replace("ddd", weekDay)
      .replace("dd", weekDay)
      .replace("YYYY", year)
      .replace("YYY", year)
      .replace("YY", year)
      .replace("MMMM", month)
      .replace("MMM", month)
      .replace("MM", month)
      .replace("DDDD", day)
      .replace("DDD", day)
      .replace("DD", day);

    return localizationDate;
  } */

  static getTrainingEventDateByFormatAndLocale(
    event: TrainingEvent,
    format: string,
    locale: string
  ) {
    if (!event) return "-";

    if (
      event?.trainingCourse?.trainingMethods &&
      event?.trainingCourse?.trainingMethods?.length > 0
    ) {
      const isOnlineTraining = event.trainingCourse.trainingMethods.every((x) =>
        [
          Constants.VIDEO_LEARNING_METHOD_ID,
          Constants.WEB_BASE_LEARNING_METHOD_ID,
        ].includes(x.id)
      );
      if (isOnlineTraining) {
        return "-";
      }
    }

    let localizationDate = CommonHelper.convertLocalizationDatetime(
      event?.startDate?.toString(),
      format,
      locale
    );

    return `${localizationDate} [${event.trainingLength}]`;
  }
}
