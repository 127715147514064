import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { ContactPerson } from "../models/Account";
import {
  ContactPersonModel,
  ContactPersonRequestModel,
  CreateContactPersonRequestModel,
  GetContactLanguagesRequestModel,
  GetContactPersonByGuidRequestModel,
  MapInvitationCodeRequestModel,
  MapPersonalUserAccountModel,
  SendInvitationEmailRequestModel,
  UpdateContactPersonRequestModel,
} from "../models/ContactPerson.Model";
import { ContactLanguage } from "../models/dashboard/Dashboard";
import ResponseResultBase from "../models/ResponseResultBase";
import { http } from "../services/HttpService";
import { AppDispatch, RootState } from "./store";

export const getContactPersonByGuidAsync = createAsyncThunk<
  ResponseResultBase<ContactPerson> | null,
  GetContactPersonByGuidRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("contacts/getContactPersonsByGuid", async (req, thunkApi) => {
  try {
    let model = {
      country: req.country,
      contactGuid: req.guid,
      language: req.language,
      customerId: req.customerId,
    };

    let response = await http.post<ResponseResultBase<ContactPerson>>(
      `/api/v1/Contacts/GetContactPersonByGuid`,
      model
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const mapPersonalUserAccount = createAsyncThunk<
  ResponseResultBase<null> | null,
  MapPersonalUserAccountModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contacts/MapPersonalUserAccount",
  async (req: MapPersonalUserAccountModel, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<null>>(
        `/api/v1/Contacts/MapPersonalUserAccount`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getContactPersonsAsync = createAsyncThunk<
  ResponseResultBase<ContactPersonModel[]> | null,
  ContactPersonRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contacts/getContactPersons",
  async (req: ContactPersonRequestModel, thunkApi) => {
    try {
      let model = {
        ciamId: req.ciamId,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
      };
      let response = await http.post<ResponseResultBase<ContactPersonModel[]>>(
        `/api/v1/contacts/getContactPersons`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getContactLanguagesAsync = createAsyncThunk<
  ResponseResultBase<ContactLanguage[]> | null,
  GetContactLanguagesRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contacts/getContactLanguages",
  async (req: GetContactLanguagesRequestModel, thunkApi) => {
    try {
      const model = {
        ciamId: req.ciamId,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
      };
      let response = await http.post<ResponseResultBase<ContactLanguage[]>>(
        `/api/v1/contacts/GetLanguages`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const updateContactPersonAsync = createAsyncThunk<
  ResponseResultBase<number> | null,
  UpdateContactPersonRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contacts/UpdateContactPerson",
  async (req: UpdateContactPersonRequestModel, thunkApi) => {
    try {
      let response = await http.put<ResponseResultBase<number>>(
        `/api/v1/contacts/UpdateContactPerson`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const createContactPersonAsync = createAsyncThunk<
  ResponseResultBase<ContactPersonModel> | null,
  CreateContactPersonRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contacts/CreateContactPerson",
  async (req: CreateContactPersonRequestModel, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<ContactPersonModel>>(
        `/api/v1/contacts/CreateContactPerson`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const sendInvitationAsync = createAsyncThunk<
  ResponseResultBase<any> | null,
  SendInvitationEmailRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contacts/SendInvitation",
  async (req: SendInvitationEmailRequestModel, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<any>>(
        `/api/v1/contacts/SendInvitationCode`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const mapInvitationAsync = createAsyncThunk<
  ResponseResultBase<any> | null,
  MapInvitationCodeRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contacts/MapInvitationCode",
  async (req: MapInvitationCodeRequestModel, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<any>>(
        `/api/v1/contacts/MapInvitationCode`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

interface ContactState {
  contactPersons: ContactPersonModel[] | null;
  isForceReloadContactPersons: boolean;
}

const initialState: ContactState = {
  contactPersons: null,
  isForceReloadContactPersons: true,
};

export const ContactSlice = createSlice({
  name: "contactSlice",
  initialState,
  reducers: {
    setContactPersons: (state, action) => {
      return {
        ...state,
        contactPersons: action?.payload || null,
      };
    },
    setIsForceReloadContactPersons: (state, action) => {
      return {
        ...state,
        isForceReloadContactPersons: action?.payload || false,
      };
    },
    resetContactPersons: (state, action: any) => {
      return {
        ...state,
        contactPersons: null,
        isForceReloadContactPersons: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContactPersonsAsync.fulfilled, (state, { payload }) => {
      if (payload != null && payload.httpStatusCode === 200) {
        const persons = payload.dataObject as ContactPersonModel[];
        return {
          ...state,
          contactPersons: persons,
          isForceReloadContactPersons: false,
        };
      } else {
        return {
          ...state,
          contactPersons: null,
        };
      }
    });
  },
});

export const selectContactPersons = (rootState: RootState) =>
  rootState.contactReducer.contactPersons;

export const selectIsForceReloadContactPersons = (rootState: RootState) =>
  rootState.contactReducer.isForceReloadContactPersons;

export const {
  setContactPersons,
  setIsForceReloadContactPersons,
  resetContactPersons,
} = ContactSlice.actions;
export default ContactSlice.reducer;
