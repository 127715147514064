import { FC, Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import {
  Button,
  Grid,
  Icon,
  Image,
  Menu,
  Message,
  Popup as PopupNew,
  Segment,
  TransitionablePortal,
} from "semantic-ui-react";
import { Constants } from "../../helpers/Constants";
import {
  selectAccount,
  selectAllowSelfEnroll,
  selectDisplayCredit,
  selectUserRoleId,
  selectCiamId,
  selectOnSubWorkshopBranch,
  selectCiamEmail,
} from "../../redux-toolkit/accountReducer";
import {
  selectAuthState,
  selectCountryCode,
  selectIsSignIn,
  authSetLanguage,
  selectTimeZoneId,
  selectLanguage,
} from "../../redux-toolkit/authReducer";
import {
  clearEventIdFromCart,
  clearRegisteredEmployee,
} from "../../redux-toolkit/shoppingCartReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { AuthService } from "../../services/AuthService";
import boschSupergraphic from "../../assets/Bosch-Supergraphic_.png";
import logo from "../../assets/Bosch_DigitalLogo_BlackAnchor_144x96px.png";
import { ReactComponent as Search } from "../../assets/svg/search.svg";
import { ReactComponent as Chat } from "../../assets/svg/chat.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu.svg";
import { ReactComponent as Profile } from "../../assets/svg/profile.svg";
import { ReactComponent as Regions } from "../../assets/svg/locator.svg";

import Breadcrumbs, { CrumbProps } from "./Breadcrumbs";
import "./Header.css";
import HeaderCartButton from "./HeaderCartButton";
import MenuHeaderPopup from "./MenuHeaderPopup";
import SearchField from "./SearchField";
import Boschicon from "../BoschIcon/Boschicon";
import MenuHeader from "./MenuHeader";
import AccountButton from "./AccountButton";
import {
  resetTrainingCourses,
  selectTrainingCourse,
} from "../../redux-toolkit/trainingCourseReducer";
import AddEventFromCatalogToCartComponent from "../ShoppingCart/AddEventFromCatalogToCart.Component";
import Styles from "./Header.module.css";
import {
  getEventCertificateAsync,
  selectEnrollmentId,
  selectEnrollmentDetail,
  selectSelectedTrainingProgram,
  selectMyTrainingEvents,
  resetEventEnrollmentSummaryList,
  resetMyTrainingEvents,
} from "../../redux-toolkit/dashboardReducer";
import {
  getTrainingProgramCertificateAsync,
  resetAssignedTrainingPrograms,
  resetTrainingPrograms,
  selectProgram,
  setIsForceReloadAssignedTrainingPrograms,
} from "../../redux-toolkit/trainingProgramReducer";
import {
  resetTrainingEvents,
  selectEvent,
} from "../../redux-toolkit/trainingEventReducer";
import CourseWithdrawalModal from "../MyDashboard/CourseWithdrawalModal";
import { EnrollmentDetails } from "../../models/EventDetail";
import { RequestGetCertificateModel } from "../../models/dashboard/request.GetCertificate.model";
import { EventCertificate } from "../../models/EventCertificate";
import AssignButton from "../TrainingProgramLayout/AssignButton";
import {
  selectEmployeeId,
  selectProxyMode,
  selectFullEmployeeName,
  switchProxyMode,
  selectWholesalerProxyMode,
  switchWholesalerProxyMode,
  selectSelectedWorkshop,
} from "../../redux-toolkit/dashboardEmployeeReducer";
import WithdrawProgramModal from "../TrackingProgramModal/WithdrawProgramModal";
import PriceTag from "../TrainingCatalogue/PriceTag";
import SwitchCountryModal from "./SwitchCountryModal";
import CourseEvaluationModal from "../Dashboard/Courses/CourseDetails/CourseEvaluationModal";
import EvaluationRestrictionModal from "../Dashboard/Courses/CourseDetails/EvaluationRestrictionModal";
import RDButton from "../RDComponents/RDButton";
import ChangeCompanyModal from "../CompanyModal/ChangeCompanyModal";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import { getGeolocationAsync } from "../../redux-toolkit/regionReducer";
import { TrainingEventHelper } from "../../helpers/TrainingEventHelper";
import { RequestGetTrainingProgramCertificateModel } from "../../models/trainingProgram/request.getTrainingProgramCertificate.model";
import { TrainingProgramCertificate } from "../../models/trainingProgram/TrainingProgramCertificate";
import RDAlert from "../RDComponents/RDAlert";
import { resetContactPersons } from "../../redux-toolkit/contactReducer";
import RDLanguageSelection from "../RDComponents/RDLanguageSelection/RDLanguageSelection";
import { resetTrainingCenters } from "../../redux-toolkit/trainingCenterReducer";
import {
  selectIsForcedToHideNotificationBanner,
  updateForcedToHideNotificationBanner,
} from "../../redux-toolkit/maintenanceReducer";

interface HeaderProps {
  crumbs?: CrumbProps[];
  isInLandingPage?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const authService: AuthService = AuthService.getInstance();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [language, setLanguage] = useState<string>(
    useSelector(selectAuthState).language
  );
  const [headerContent, setHeaderContent] = useState<string>("");
  const isLoggedIn = useSelector(selectIsSignIn);

  const authState = useSelector(selectAuthState);
  const languageCode = useSelector(selectLanguage);
  const allowSelfEnroll = useSelector(selectAllowSelfEnroll);
  const onSubWorkshopBranch = useSelector(selectOnSubWorkshopBranch);
  const displayCredit = useSelector(selectDisplayCredit);
  const closeSearch = () => {
    setIsSearch(false);
  };
  const redirectToPage = (url: string) => {
    history.push(url);
  };
  const blurBtn = () => {
    document.getElementById("btn-focus-error")?.blur();
  };
  const program = useSelector(selectProgram);
  const [isOpenSwitchRegion, setIsOpenSwitchRegion] = useState(false);
  const [loginOpen, setloginOpen] = useState(false);
  const [mobLoginOpen, setmobLoginOpen] = useState(false);
  const ciamId = useSelector(selectCiamId);
  const country = useSelector(selectCountryCode);
  const timezoneId = useSelector(selectTimeZoneId);
  const role = useSelector(selectUserRoleId);
  const account = useSelector(selectAccount);
  const [withdrawing, setWithdrawing] = useState(false);
  const [isTrainingProgramDownloading, setIsTrainingProgramDownloading] =
    useState(false);
  const [
    isTrainingProgramDownloadingFailed,
    setIsTrainingProgramDownloadingFailed,
  ] = useState(false);
  const [isWithdrawSelected, setIsWithdrawSelected] = useState<boolean>(false);
  const ciamEmail = useSelector(selectCiamEmail);

  // const WS_URL = process.env.WS_MAINTENANCE_URL || 'ws://127.0.0.1:8000';
  // const socket = new WebSocket(WS_URL);

  const [isMaintenanceScheduleOn, setIsMaintenanceScheduleOn] = useState(false);
  const maintenanceScheduleDatetime = "22/03/2024 05:00 AM (CET)";
  const [maintenanceSupportEmail, setMaintenanceSupportEmail] = useState(
    Constants.NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_DEFAULT
  );
  const isForceToHideNotificationBanner = useSelector(
    selectIsForcedToHideNotificationBanner
  );

  const myTrainingEvents = useSelector(selectMyTrainingEvents);
  const proxyMode = useSelector(selectProxyMode);
  const wholesalerProxyMode = useSelector(selectWholesalerProxyMode);
  const selectedEmployee = useSelector(selectFullEmployeeName);
  const selectedWorkshop = useSelector(selectSelectedWorkshop);
  const studentId = useSelector(selectEmployeeId);
  const [isChangingCompany, setIsChangingCompany] = useState<boolean>(false);
  const selectedEnrollmentId = useSelector(selectEnrollmentId);
  const [enableCourseWithdrawalModal, setEnableCourseWithdrawalModal] =
    useState<boolean>(false);
  const trainingEvent = useSelector(selectEvent);
  const [enrollmentDetail, setEnrollmentDetail] =
    useState<EnrollmentDetails | null>(null);
  const [isDownloadCertificate, setIsDownloadCertificate] = useState(false);
  const selectedEnrollmentDetail = useSelector(selectEnrollmentDetail);
  const [openCourseEvaluation, setOpenCourseEvaluation] = useState(false);
  const [openEvaluationRestriction, setOpenEvaluationRestriction] =
    useState(false);

  const [isWithdrawCourseAllow, setIsWithdrawCourseAllow] =
    useState<boolean>(false);
  const [isTrainingCourseWithdrawVisible, setIsTrainingCourseWithdrawVisible] =
    useState<boolean>(false);
  const [isElearning, setIsElearning] = useState<boolean>(false);

  const selectedTrainingProgram = useSelector(selectSelectedTrainingProgram);
  const [isWithdrawProgramVisible, setIsWithdrawProgramVisible] =
    useState<boolean>(false);

  const headerContainerRef = useRef<HTMLDivElement | null>(null);
  const superGraphicContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollYFlagToShowStickyHeader = 220; // scroll on Y Axis enough 220 to show sticky header
  const widthFlagToShowStickyHeader = 1024; // Only devices have screen width size from 1024px and up can view sticky header
  const stickySuperGraphicContainerClassName = "sticky-super-graphic-shown";
  const stickyHeaderContainerClassName = "sticky-header-shown";

  const trainingCourseDetail = useSelector(selectTrainingCourse);
  // const course = useSelector(selectCourse);

  const [showExternalWithdrawModal, setShowExternalWithdrawModal] =
    useState<boolean>(false);

  const onCourseWithdrawButtonClick = () => {
    if (trainingEvent?.hasExternalRegistration) {
      setShowExternalWithdrawModal(true);
    } else {
      setEnableCourseWithdrawalModal(true);
    }
  };

  const redirectExternalLink = (url: string) => {
    setShowExternalWithdrawModal(false);
    window.open(url, "_blank");
  };

  const handleDownloadTrainingCourseCertificateClick = () => {
    setIsDownloadCertificate(true);
    let employeeCustomerId = wholesalerProxyMode
      ? selectedWorkshop?.customerId
      : account?.customerId;
    const certInput: RequestGetCertificateModel = {
      enrollmentId: selectedEnrollmentId || 0,
      ciamId: ciamId ?? "",
      customerId: employeeCustomerId ?? 0,
      country: country ?? "",
      language: language ?? "",
      timeZoneId: timezoneId ?? "",
    };
    const getCerts = dispatch(getEventCertificateAsync(certInput));
    getCerts
      .then((data: any) => {
        if (data?.payload?.dataObject) {
          const certificate = data.payload.dataObject as EventCertificate;
          if (certificate) {
            const fileExt = certificate?.fileExt?.replace(".", "");
            const hiddenElement = document.createElement("a");
            hiddenElement.href =
              `data:application/${fileExt};base64,` +
              encodeURI(certificate?.file || "");
            hiddenElement.target = "_blank";
            hiddenElement.download = `${certificate?.fileName}`;
            hiddenElement.click();
          }
        }
      })
      .finally(() => {
        setIsDownloadCertificate(false);
      });
  };

  const handleDownloadTrainingProgramCertificate = () => {
    setIsTrainingProgramDownloading(true);

    let employeeCustomerId = wholesalerProxyMode
      ? selectedWorkshop?.customerId
      : account?.customerId;

    const certInput: RequestGetTrainingProgramCertificateModel = {
      ciamId: ciamId ?? "",
      ciamEmail: ciamEmail ?? "",
      trainingProgramMembersId:
        selectedTrainingProgram?.properties?.trainingProgramMembersId ?? 0,
      customerId: employeeCustomerId ?? 0,
      country: country ?? "",
      language: language ?? "",
      timeZoneId: timezoneId ?? "",
      salesRegion: "",
    };
    const getCerts = dispatch(getTrainingProgramCertificateAsync(certInput));
    getCerts
      .then((data: any) => {
        if (data?.payload?.dataObject) {
          const certificate = data.payload
            .dataObject as TrainingProgramCertificate;
          if (certificate) {
            const fileExt = certificate?.fileExt?.replace(".", "");
            const hiddenElement = document.createElement("a");
            hiddenElement.href =
              `data:application/${fileExt};base64,` +
              encodeURI(certificate?.file || "");
            hiddenElement.target = "_blank";
            hiddenElement.download = `${certificate?.fileName}`;
            hiddenElement.click();
          }
        } else {
          setIsTrainingProgramDownloadingFailed(true);
        }
      })
      .finally(() => {
        setIsTrainingProgramDownloading(false);
      });
  };

  const handleCourseEvaluationClick = () => {
    if (wholesalerProxyMode || proxyMode) {
      setOpenEvaluationRestriction(true);
      setOpenCourseEvaluation(false);
    } else {
      let selectedEvent = myTrainingEvents?.filter(
        (e) => e.enrollmentId === selectedEnrollmentId
      );
      if (selectedEvent && selectedEvent.length > 0) {
        setOpenCourseEvaluation(true);
        setOpenEvaluationRestriction(false);
      } else {
        setOpenEvaluationRestriction(true);
        setOpenCourseEvaluation(false);
      }
    }
  };

  const closeEvaluationRestriction = () => {
    setOpenEvaluationRestriction(false);
  };

  const closeCourseEvaluation = () => {
    setOpenCourseEvaluation(false);
  };

  const closeCourseWithdrawalModal = () => {
    setEnableCourseWithdrawalModal(false);
  };

  const onCourseWithdrawalSuccess = () => {
    history.goBack();
  };

  useEffect(() => {
    if (languageCode) {
      setLanguage(languageCode);
    }
  }, [languageCode]);

  /* useEffect(() => {
    socket.onopen = () => {
      // Query about maintenance info
      socket.send(JSON.stringify({
        type: 'maintenance-get'
      }));  
    };

    socket.onmessage = (e) => {

      let maintenanceInfo = JSON.parse(e.data)
      let messageType = maintenanceInfo.type;

      setMaintenanceScheduleDatetime(maintenanceInfo.data.maintenanceScheduleDatetime);
      setMaintenanceOnlineDatetime(maintenanceInfo.data.maintenanceOnlineDatetime);
      setMaintenanceSupportEmail(maintenanceInfo.data.maintenanceSupportEmail);

      if (messageType === 'maintenance-get') {

        setIsMaintenanceScheduleOn(maintenanceInfo.data.isMaintainning);

      } else if (messageType === 'maintenance-set') {

        setIsMaintenanceScheduleOn(true);

      }  else if (messageType === 'maintenance-clear') {

        setIsMaintenanceScheduleOn(false);

      } else {
         // do nothing
      }
    };

    return () => {
      socket.close()
    }
  }, []); */

  const handleCloseMaintenanceBanner = () => {
    setIsMaintenanceScheduleOn(false);
    dispatchReduxToolkit(updateForcedToHideNotificationBanner(true));
  };

  useEffect(() => {
    window.onscroll = () => {
      if (
        window.innerWidth < widthFlagToShowStickyHeader ||
        !headerContainerRef.current ||
        !superGraphicContainerRef.current
      )
        return;

      if (window.scrollY >= scrollYFlagToShowStickyHeader) {
        headerContainerRef.current.classList.add(
          stickyHeaderContainerClassName
        );
        superGraphicContainerRef.current.classList.add(
          stickySuperGraphicContainerClassName
        );
      } else {
        headerContainerRef.current.classList.remove(
          stickyHeaderContainerClassName
        );
        superGraphicContainerRef.current.classList.remove(
          stickySuperGraphicContainerClassName
        );
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  useEffect(() => {
    // check if training event is allow to withdraw with flag cancellationButtonEnabled
    if (trainingEvent) {
      let visible = trainingEvent?.cancellationButtonEnabled &&
      Constants.ACTIVE_EVENT_STATUS.includes(trainingEvent?.eventStatus?.id);

      setIsTrainingCourseWithdrawVisible(visible);
      setIsWithdrawCourseAllow(visible);

      /* if (
        Constants.ACTIVE_EVENT_STATUS.includes(trainingEvent?.eventStatus?.id)
      ) {
        setIsTrainingCourseWithdrawVisible(visible);
        setIsWithdrawCourseAllow(visible);
      } 
      else {
        setIsTrainingCourseWithdrawVisible(false);
        setIsWithdrawCourseAllow(false);
      }  */
    }

    if (trainingCourseDetail?.isElearning) {
      setIsElearning(true);
    }

    if (selectedEnrollmentDetail) {
      setEnrollmentDetail(selectedEnrollmentDetail);

      if (
        Constants.TRAININGEVENTSTATUSES_WITHDRAWAL_PENDING ===
        selectedEnrollmentDetail?.eventDetails?.eventStatus?.id
      ) {
        setIsTrainingCourseWithdrawVisible(false);
        setIsWithdrawCourseAllow(false);
      }
    }
  }, [trainingCourseDetail, selectedEnrollmentDetail, trainingEvent]);

  useEffect(() => {
    if (
      !!selectedTrainingProgram &&
      selectedTrainingProgram?.properties &&
      selectedTrainingProgram?.properties?.is_completed
    ) {
      setIsWithdrawProgramVisible(false);
    } else {
      setIsWithdrawProgramVisible(true);
    }
  }, [selectedTrainingProgram]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setHeaderContent(t("Header_Anonymous"));
      return;
    }
    const mapItem = Constants.headerMap.find((x) =>
      window.location.pathname.startsWith(x[0])
    );
    if (mapItem) {
      setHeaderContent(t(mapItem[1]));
    }
  }, [window.location.pathname, language]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("menu-mobile");
    } else {
      document.body.classList.remove("menu-mobile");
    }
  }, [open]);

  const login = async () => {
    await authService.loginAsync();
  };

  const logout = async () => {
    dispatchReduxToolkit(clearEventIdFromCart());
    dispatchReduxToolkit(clearRegisteredEmployee());

    await authService.logoutAsync();
  };

  const withdrawSucessAction = () => {
    history.push("/dashboard/my-training-program");

    dispatchReduxToolkit(setIsForceReloadAssignedTrainingPrograms(true));
  };

  const languageOptions =
    !!authState.languages && authState.languages.length > 0
      ? authState.languages.map((x) => {
          return {
            key: x.localized,
            text: x.localized,
            value: x.lcid,
          };
        })
      : [
          {
            key: "English",
            text: "English",
            value: "en-US",
          },
        ];

  const switchLanguage = (data: any) => {
    setLanguage(data);
    i18n.changeLanguage(data.trim());
    dispatch(authSetLanguage(data));

    reloadPersonalData();
    forceToReloadData();
  };

  const switchProxy = () => {
    dispatchReduxToolkit(switchProxyMode(false));
    redirectToPage(`/dashboard/my-employees`);

    reloadPersonalData();
    forceToReloadData();
  };

  const switchWholesalerProxy = () => {
    if (!!wholesalerProxyMode && !!proxyMode) {
      dispatchReduxToolkit(switchProxyMode(false));
      redirectToPage(`/dashboard`);
    } else if (!!wholesalerProxyMode && !proxyMode) {
      dispatchReduxToolkit(switchWholesalerProxyMode(false));
      redirectToPage(`/dashboard/my-workshops`);
    }

    reloadPersonalData();
    forceToReloadData();
  };

  const reloadPersonalData = () => {
    // clear all assigned training programs
    dispatchReduxToolkit(resetAssignedTrainingPrograms(undefined));

    // clear all assigned training events
    dispatchReduxToolkit(resetMyTrainingEvents(undefined));

    // clear all contact persons
    dispatchReduxToolkit(resetContactPersons(undefined));

    // force to reload team EventEnrollmentSummaryList data.
    dispatchReduxToolkit(resetEventEnrollmentSummaryList(undefined));
  };

  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));
  };

  const detectCountry = async () => {
    let resGeoLocation = await dispatchReduxToolkit(getGeolocationAsync());
    if (getGeolocationAsync.fulfilled.match(resGeoLocation)) {
      if (
        !!resGeoLocation.payload &&
        resGeoLocation.payload.success &&
        !!resGeoLocation.payload.dataObject
      ) {
        AuthService.setGeolocation(resGeoLocation.payload.dataObject);
        let countryCodeGeolocation =
          resGeoLocation.payload.dataObject.countryCode;

        if (countryCodeGeolocation && countryCodeGeolocation === "ES") {
          setMaintenanceSupportEmail(
            Constants.NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_ES
          );
        } else {
          setMaintenanceSupportEmail(
            Constants.NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_DEFAULT
          );
        }
      } else {
        setMaintenanceSupportEmail(
          Constants.NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_DEFAULT
        );
      }
    }
  };

  useEffect(() => {
    if (country) {
      if (country === "ES") {
        setMaintenanceSupportEmail(
          Constants.NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_ES
        );
      } else {
        setMaintenanceSupportEmail(
          Constants.NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_DEFAULT
        );
      }
    } else {
      detectCountry();
    }
  }, []);

  const headerBar = () => {
    switch (window.location.pathname) {
      case "/:country?/course/:id?":
      case "/course/:country?/:id?":
      case "/course-detail":
      case "/training-course-catalogue/training-course-detail":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">
                  {/* { course?.localizedName } */}
                  {TrainingEventHelper.getTrainingNameFromCourse(
                    trainingCourseDetail,
                    language,
                    "-"
                  )}
                </span>
              </div>
              <div className="training_course_detail_header">
                <div className="price_container">
                  {displayCredit === "credit" ? (
                    <>
                      {trainingCourseDetail && (
                        <PriceTag
                          price={trainingCourseDetail.batsPrice}
                          creditPrice={trainingCourseDetail.batsCreditPrice}
                        />
                      )}
                    </>
                  ) : (
                    <div className="price-currency">
                      {trainingCourseDetail && (
                        <PriceTag
                          price={trainingCourseDetail.batsPrice}
                          creditPrice={trainingCourseDetail.batsCreditPrice}
                          isDisplayVATText
                        />
                      )}
                    </div>
                  )}
                </div>
                {isLoggedIn ? (
                  <AddEventFromCatalogToCartComponent
                    isDisabled={!allowSelfEnroll || onSubWorkshopBranch}
                    eventInfo={trainingCourseDetail}
                    isHeader={true}
                  />
                ) : (
                  <RDButton primary className="login-button" onClick={login}>
                    {t("Homepage_Login")}
                  </RDButton>
                )}
              </div>
            </div>
          </div>
        );
      case "/training-course-catalogue":
      case "/event-calendar":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{headerContent}</span>
              </div>
              <RDButton
                primary
                onClick={
                  !isLoggedIn
                    ? login
                    : () => {
                        history.push("/training-course-catalogue");
                      }
                }
              >
                {isLoggedIn ? t("Header_RegisterCourse") : t("Homepage_Login")}
              </RDButton>
            </div>
          </div>
        );
      case "/dashboard":
      case "/dashboard/my-training-program":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                  <Boschicon name="bosch-ic-keys-user-access" />
                ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                  wholesalerProxyMode ? (
                  proxyMode ? (
                    <Boschicon name="bosch-ic-keys-user-access" />
                  ) : (
                    <Boschicon name="bosch-ic-garage" />
                  )
                ) : (
                  <Boschicon name="bosch-ic-user-professional" />
                )}
                <span className="anonymous-header">
                  {role === Constants.USER_ROLE_MANAGER_ID && proxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ? selectedEmployee : ""
                      }`
                    : role === Constants.USER_ROLE_WHOLESALER_ID &&
                      wholesalerProxyMode
                    ? proxyMode
                      ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                          selectedEmployee ? selectedEmployee : ""
                        }`
                      : `${t("Dashboard_Headerbar_ProxingAs")} ${
                          selectedWorkshop ? selectedWorkshop?.workshopName : ""
                        }`
                    : `${t("Dashboard_Headerbar_WelcomeMessage")}, ${
                        account?.employee?.firstName
                          ? account?.employee?.firstName + "!"
                          : ""
                      }`}
                </span>
              </div>

              {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                <RDButton
                  isSignal
                  onClick={() => switchProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                wholesalerProxyMode ? (
                <RDButton
                  isSignal
                  onClick={() => switchWholesalerProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              ) : (
                /* (<Button className={`login-button`} onClick={!isLoggedIn ? login : () => { history.push("/training-course-catalogue") }} >{t("Dashboard_Headerbar_BookCourse")} </Button>) */
                <RDButton
                  primary
                  onClick={
                    !isLoggedIn
                      ? login
                      : () => {
                          history.push("/training-course-catalogue");
                        }
                  }
                  content={t("Dashboard_Headerbar_BookCourse")}
                />
              )}
            </div>
          </div>
        );
      case "/dashboard/edit-profile":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                  <Boschicon name="bosch-ic-keys-user-access" />
                ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                  wholesalerProxyMode ? (
                  <Boschicon name="bosch-ic-keys-user-access" />
                ) : (
                  <Boschicon name="bosch-ic-user-professional" />
                )}

                <span className="anonymous-header">
                  {role === Constants.USER_ROLE_MANAGER_ID && proxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ? selectedEmployee : ""
                      }`
                    : role === Constants.USER_ROLE_WHOLESALER_ID &&
                      wholesalerProxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ? selectedEmployee : ""
                      }`
                    : `${t("Dashboard_Headerbar_WelcomeMessage")}, ${
                        account?.employee?.firstName
                          ? account?.employee?.firstName + "!"
                          : ""
                      }`}
                </span>
              </div>

              {role === Constants.USER_ROLE_MANAGER_ID && proxyMode && (
                <RDButton
                  isSignal
                  onClick={() => switchProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              )}
              {role === Constants.USER_ROLE_WHOLESALER_ID &&
                wholesalerProxyMode && (
                  <RDButton
                    isSignal
                    onClick={() => switchWholesalerProxy()}
                    content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                  />
                )}
            </div>
          </div>
        );
      case "/dashboard/my-employees":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                  <Boschicon name="bosch-ic-keys-user-access" />
                ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                  wholesalerProxyMode ? (
                  <Boschicon name="bosch-ic-garage" />
                ) : (
                  <Boschicon name="bosch-ic-user-professional" />
                )}

                <span className="anonymous-header">
                  {role === Constants.USER_ROLE_MANAGER_ID && proxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ? selectedEmployee : ""
                      }`
                    : role === Constants.USER_ROLE_WHOLESALER_ID &&
                      wholesalerProxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedWorkshop ? selectedWorkshop?.workshopName : ""
                      }`
                    : `${t("Dashboard_Headerbar_WelcomeMessage")}, ${
                        account?.employee?.firstName
                          ? account?.employee?.firstName + "!"
                          : ""
                      }`}
                </span>
              </div>

              {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                <RDButton
                  isSignal
                  onClick={() => switchProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                wholesalerProxyMode ? (
                <RDButton
                  isSignal
                  onClick={() => switchWholesalerProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              ) : (
                <RDButton
                  primary
                  onClick={
                    !isLoggedIn
                      ? login
                      : () => {
                          history.push("/training-course-catalogue");
                        }
                  }
                >
                  {t("Dashboard_Headerbar_BookCourse")}
                </RDButton>
              )}
            </div>
          </div>
        );
      case "/dashboard/my-certificates":
      case "/dashboard/my-suppliers":
      case "/dashboard/my-workshops":
      case "/dashboard/my-wallet":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <Boschicon name="bosch-ic-user-professional" />
                <span className="anonymous-header">
                  {`${t("Dashboard_Headerbar_WelcomeMessage")}, ${
                    account?.employee?.firstName
                      ? account?.employee?.firstName + "!"
                      : ""
                  }`}
                </span>
              </div>
              <RDButton
                primary
                onClick={
                  !isLoggedIn
                    ? login
                    : () => {
                        history.push("/training-course-catalogue");
                      }
                }
              >
                {t("Dashboard_Headerbar_BookCourse")}
              </RDButton>
            </div>
          </div>
        );
      case "/administration/employee-administration/employee-creation":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <Boschicon name="bosch-ic-user-add" />

                <span className="anonymous-header">
                  {role === Constants.USER_ROLE_MANAGER_ID && proxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ?? ""
                      }`
                    : role === Constants.USER_ROLE_WHOLESALER_ID &&
                      wholesalerProxyMode
                    ? `${t("Header_CreateNewEmployee")}`
                    : `${t("Header_CreateNewEmployee")}`}
                </span>
              </div>

              {role === Constants.USER_ROLE_MANAGER_ID && proxyMode && (
                <RDButton
                  isSignal
                  onClick={() => switchProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              )}

              {role === Constants.USER_ROLE_MANAGER_ID && !proxyMode && (
                <RDButton
                  primary
                  onClick={() => {
                    history.push("/training-course-catalogue");
                  }}
                >
                  {t("Dashboard_Headerbar_BookCourse")}
                </RDButton>
              )}

              {role === Constants.USER_ROLE_WHOLESALER_ID &&
                wholesalerProxyMode && (
                  <RDButton
                    isSignal
                    onClick={() => switchWholesalerProxy()}
                    content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                  />
                )}
            </div>
          </div>
        );
      case "/mytraining/course-details":
        return (
          <div className={"anonymous-user"}>
            <div className={Styles["header_bar"]}>
              <div className={Styles["training_program_header"]}>
                <span className="anonymous-header">
                  {t("MyTraining_Headerbar_CourseDetails")}
                </span>

                {isTrainingCourseWithdrawVisible && !isElearning && (
                  <RDButton
                    isSignal
                    //className={`${Styles.header_bar_button_with_icon} ${Styles.with_draw_program_btn}`}
                    disabled={!isWithdrawCourseAllow}
                    onClick={() => onCourseWithdrawButtonClick()}
                    /* loading={withdrawing} */
                    // icon={<Boschicon name="bosch-ic-abort-frame"size="large" />}
                    content={
                      <span>
                        {t("Dashboard_Headerbar_TrainingCourse_Withdraw")}
                      </span>
                    }
                  />
                )}

                {isTrainingCourseWithdrawVisible && isElearning && (
                  <PopupNew
                    inverted
                    size="tiny"
                    content={t("CourseDetail_Withdrawal_NotPossible_Tooltips")}
                    trigger={
                      <RDButton
                        isSignal
                        //className={`${Styles.header_bar_button_with_icon} ${Styles.with_draw_program_btn}`}
                        /* loading={withdrawing} */
                        // icon={<Boschicon name="bosch-ic-abort-frame"size="large" />}
                        content={
                          <span>
                            {t("Dashboard_Headerbar_TrainingCourse_Withdraw")}
                          </span>
                        }
                      />
                    }
                  />
                )}

                {enrollmentDetail?.isCertificateAvailable && (
                  <RDButton
                    primary
                    disabled={isDownloadCertificate}
                    // className={Styles.header_bar_button_with_icon}
                    // icon={<Boschicon name="bosch-download-frame" size="large" />}
                    onClick={() =>
                      handleDownloadTrainingCourseCertificateClick()
                    }
                    content={t("Dashboard_Headerbar_DownloadCertificate")}
                  />
                )}

                {enrollmentDetail?.isEvaluationAvailable &&
                  !enrollmentDetail?.isCertificateAvailable && (
                    <>
                      <span className={Styles.evaluate_note}>
                        {t("Dashboard_Headerbar_EvaluateCourse_Note")}{" "}
                      </span>
                      <RDButton
                        primary
                        // className={Styles.header_bar_button_with_icon}
                        // icon={<Boschicon name="bosch-ic-question-frame" size="large" />}
                        onClick={() => handleCourseEvaluationClick()}
                        content={t("Dashboard_Headerbar_EvaluateCourse")}
                      />{" "}
                    </>
                  )}
              </div>
            </div>
          </div>
        );
      case "/mytraining/curricula/training-program-details":
        return (
          <div className={"anonymous-user"}>
            <div className={Styles["header_bar"]}>
              <div className={Styles["training_program_header"]}>
                <span className="anonymous-header">
                  {t("MyTraining_Headerbar_TrainingProgramDetails")}
                </span>
                {isWithdrawProgramVisible && (
                  <RDButton
                    isSignal
                    onClick={() => setIsWithdrawSelected(true)}
                    loading={withdrawing}
                    // className={`${Styles.header_bar_button_with_icon} ${Styles.with_draw_program_btn}`}
                    // icon={<Boschicon name="bosch-ic-abort-frame"size="large" />}
                    content={
                      <span>
                        {t("Dashboard_Headerbar_TrainingProgram_Withdraw")}
                      </span>
                    }
                  />
                )}
                {selectedTrainingProgram?.properties
                  ?.isCertificateAvailable && (
                  <RDButton
                    primary
                    onClick={() => handleDownloadTrainingProgramCertificate()}
                    loading={isTrainingProgramDownloading}
                    // className={`${Styles.header_bar_button_with_icon} ${Styles.with_draw_program_btn}`}
                    // icon={<Boschicon name="bosch-ic-abort-frame"size="large" />}
                    content={
                      <span>
                        {t(
                          "Dashboard_Headerbar_TrainingProgram_DownloadCertificate"
                        )}
                      </span>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        );
      case "/contacts":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{headerContent}</span>
              </div>
            </div>
          </div>
        );
      case "/training-program":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{headerContent}</span>
              </div>
              {!isLoggedIn && (
                <RDButton primary onClick={login}>
                  {t("Homepage_Login")}
                </RDButton>
              )}
            </div>
          </div>
        );
      case "/training-centers":
      case "/training-centers/recommended-hotel":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{headerContent}</span>
              </div>
              <RDButton
                primary
                onClick={
                  !isLoggedIn
                    ? login
                    : () => {
                        history.push("/training-course-catalogue");
                      }
                }
              >
                {isLoggedIn
                  ? t("Training_Centres_Book_Btn")
                  : t("Homepage_Login")}
              </RDButton>
            </div>
          </div>
        );
      case "/training-program/training-program-detail":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{program?.title}</span>
              </div>
              {isLoggedIn ? (
                <AssignButton isHeader={true} />
              ) : (
                <RDButton primary onClick={login}>
                  {t("Homepage_Login")}
                </RDButton>
              )}
            </div>
          </div>
        );
      case "/myprofile":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <Boschicon name="bosch-ic-my-brand-frame" />
                <span className="anonymous-header">
                  {t("Header_MyBoschProfile_Header")}
                </span>
              </div>
              {/* <AssignButton isHeader={true}/> */}
            </div>
          </div>
        );
      case "/download-files":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{t("Download_Header")}</span>
              </div>
            </div>
          </div>
        );
      default:
        if (window.location.pathname.includes("/course/")) {
          return (
            <div className={"anonymous-user"}>
              <div className="header-bar">
                <div className="header-text-container">
                  <span className="anonymous-header">
                    {/* { course?.localizedName } */}
                    {TrainingEventHelper.getTrainingNameFromCourse(
                      trainingCourseDetail,
                      language,
                      "-"
                    )}
                  </span>
                </div>
                <div className="training_course_detail_header">
                  <div className="price_container">
                    {displayCredit === "credit" ? (
                      <>
                        {trainingCourseDetail && (
                          <PriceTag
                            price={trainingCourseDetail.batsPrice}
                            creditPrice={trainingCourseDetail.batsCreditPrice}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <div className="price-currency">
                          {trainingCourseDetail && (
                            <PriceTag
                              price={trainingCourseDetail.batsPrice}
                              creditPrice={trainingCourseDetail.batsCreditPrice}
                              isDisplayVATText
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {isLoggedIn ? (
                    <AddEventFromCatalogToCartComponent
                      isDisabled={!allowSelfEnroll || onSubWorkshopBranch}
                      eventInfo={trainingCourseDetail}
                      isHeader={true}
                    />
                  ) : (
                    <RDButton primary className="login-button" onClick={login}>
                      {t("Homepage_Login")}
                    </RDButton>
                  )}
                </div>
              </div>
            </div>
          );
        }

        return <></>;
    }
  };

  return (
    <Fragment>
      {isMaintenanceScheduleOn && !isForceToHideNotificationBanner && (
        <div className="maintenance_block">
          <Message className="maintenance_message" info size="small">
            <div className="maintenance_detail">
              <Message.Content>
                <Boschicon name="bosch-ic-service-time" />
                <p>
                  {isMaintenanceScheduleOn && (
                    <>
                      <span>
                        {t("MaintenanceBanner_Message_1").replace(
                          "[maintenance_schedule]",
                          maintenanceScheduleDatetime || ""
                        )}
                      </span>
                      <span> </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("MaintenanceBanner_Message_2").replace(
                            "[support_email]",
                            "<a href='mailto:" +
                              maintenanceSupportEmail +
                              "' target='_blank'>" +
                              maintenanceSupportEmail +
                              "</a>"
                          ),
                        }}
                      ></span>
                      <br />
                    </>
                  )}
                </p>
                <Boschicon
                  name="bosch-close"
                  onClick={handleCloseMaintenanceBanner}
                />
              </Message.Content>
            </div>
          </Message>
        </div>
      )}

      <div ref={superGraphicContainerRef} className="supergraphic">
        <Image src={boschSupergraphic} fluid></Image>
      </div>

      {!props.isInLandingPage && (
        <Popup
          className="header-search-popup"
          position={"bottom left"}
          open={isSearch}
          trigger={<span style={{ flexGrow: 0 }}></span>}
          closeOnEscape
          onClose={() => setIsSearch(false)}
        >
          <div className="search-field-container search-responsive">
            <SearchField
              showResults={true}
              emitCloseEvent={true}
              closeSearch={closeSearch}
            />
          </div>
        </Popup>
      )}
      <div ref={headerContainerRef} className="grid-responsive-width">
        <div className="header-large-box">
          <div className="header_logo">
            <Image src={logo} as="a" href="/"></Image>
            {window.location.pathname === "/checkout" && (
              <span className="checkout_cart">
                {t("ShoppingCart_Checkout")}
              </span>
            )}
            {!props.isInLandingPage &&
            !window.location.pathname.toLowerCase().startsWith("/checkout") ? (
              <div className="sticky-menu-header">
                <MenuHeader isStickyMode={true} />
              </div>
            ) : (
              <></>
            )}
          </div>

          {props.isInLandingPage && (
            <h4 className="landing-page-title">
              {t("LandingPage_Header_Title")}
            </h4>
          )}

          {!props.isInLandingPage &&
            !window.location.pathname.toLowerCase().startsWith("/checkout") && ( //icons
              <Grid className="header-buttons">
                <Grid.Row
                  className="header-icon-mobile"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <AccountButton
                    // className="header-icon-button icon-desktop"
                    className="icon_desktop"
                    isMobile={false}
                    login={login}
                    logout={logout}
                    changeCompany={() => {
                      setIsChangingCompany(true);
                    }}
                  />

                  <Button
                    className="header-icon-button icon-desktop"
                    basic
                    onClick={() => setIsOpenSwitchRegion(true)}
                    icon={
                      <Icon>
                        <Regions />
                      </Icon>
                    }
                  ></Button>

                  <Button
                    className="header-icon-button icon-desktop"
                    basic
                    // content={<span>{t("Header_Search")}</span>}
                    onClick={() => setIsSearch((prevIsSearch) => !prevIsSearch)}
                    icon={
                      <Icon>
                        <Search />
                      </Icon>
                    }
                  ></Button>

                  <HeaderCartButton
                    className="header-icon-button header-cart icon-desktop"
                    isMobile={false}
                  />

                  <Button
                    className="header-icon-button"
                    basic
                    style={{ display: "none" }}
                    // content={<span>{t('Header_ContactUs')}</span>}
                    onClick={() => redirectToPage("/contacts")}
                    icon={
                      <Icon>
                        <Chat />
                      </Icon>
                    }
                  ></Button>

                  {/* <PopupNew
                    basic
                    id="lang-test"
                    offset={[0, 0]}
                    position="bottom left"
                    trigger={
                      <Button
                        className="header-icon-button header-language-icon icon-desktop"
                        basic
                        content={
                          <span className="language-text">
                            {language.slice(0, 2).toLowerCase()}
                          </span>
                        }
                        // onClick={() => redirectToPage('/contacts')}
                        icon={
                          <Icon className="language-icon">
                            <GlobeLanguage />
                          </Icon>
                        }
                      ></Button>
                    }
                    hideOnScroll
                    eventsEnabled={true}
                    on="click"
                    onClose={() => setLanguageOpen(false)}
                    onOpen={() => setLanguageOpen(true)}
                    style={{ borderRadius: 0, padding: 0 }}
                    open={languageOpen}
                  >
                    <Menu
                      vertical
                      style={{ borderRadius: 0 }}
                      className="pick-language-box"
                      styles={{ borderRadius: "10px" }}
                    >
                      <Menu.Item header className="available-languages-header">
                        {t("Header_Available_Languages")}
                      </Menu.Item>
                      {languageOptions.map((x: any) => (
                        <Menu.Item
                          className="language-options"
                          name={x.text}
                          onClick={() => {
                            setLanguageOpen(false);
                            switchLanguage(x.value);
                          }}
                        ></Menu.Item>
                      ))}
                    </Menu>
                  </PopupNew> */}

                  {/* <LanguageButton
                    // className="header-icon-button icon-desktop"
                    className="icon_desktop"
                    isMobile={false}
                    //login={login}
                    //logout={logout}
                    // changeCompany={() => {
                    //   setIsChangingCompany(true);
                    // }}
                  /> */}

                  <RDLanguageSelection
                    className="icon_desktop"
                    currentLanguage={language}
                    languages={languageOptions.map(({ key, text, value }) => {
                      return {
                        key,
                        text,
                        value,
                      };
                    })}
                    onChangeLanguage={(newLang) => switchLanguage(newLang)}
                  />

                  {!open && (
                    <Button
                      className="header-icon-button open-menu-button-mobile"
                      basic
                      // content={
                      //   <span style={{ padding: '0px' }}>{t('Header_Menu')}</span>
                      // }
                      onClick={() => setOpen(true)}
                      icon={
                        <Icon>
                          <MenuIcon />
                        </Icon>
                      }
                    ></Button>
                  )}
                </Grid.Row>
              </Grid>
            )}
        </div>
      </div>
      {!props.isInLandingPage &&
        !window.location.pathname.toLowerCase().startsWith("/checkout") && (
          <div className="grid-responsive-width ui-header-wrapper">
            <MenuHeader />
          </div>
        )}
      {!props.isInLandingPage &&
        !window.location.pathname.toLowerCase().startsWith("/checkout") && (
          <TransitionablePortal
            open={open}
            transition={{ animation: "slide right", duration: 0 }}
            style={{ width: "100%", border: "0px" }}
            className="header-menu-popup"
            closeOnDocumentClick
            onOpen={() => blurBtn()}
            onClose={() => setOpen(false)}
          >
            <Segment
              style={{
                position: "absolute",
                width: "100%",
                zIndex: 1000,
                margin: 0,
                border: "none",
                height: "100vh",
              }}
              className="menu-popup-segment"
            >
              <MenuHeaderPopup
                hideMenu={() => setOpen(false)}
                login={() => login()}
                logout={() => {
                  setIsOpenLogOutModal(true);
                  setloginOpen(false);
                }}
                // switchLanguage={()=>switchLanguage()}
                setIsSearch={() => setIsSearch((prevIsSearch) => !prevIsSearch)}
                setIsOpenSwitchRegion={() => setIsOpenSwitchRegion(true)}
                setLoginOpen={() => setloginOpen}
                profileOpen={loginOpen}
                languageOptions={languageOptions}
                changeCompany={() => {
                  setIsChangingCompany(true);
                }}
              />
            </Segment>
          </TransitionablePortal>
        )}
      {headerBar()}

      {!props.isInLandingPage &&
        window.location.pathname !== "/" &&
        !window.location.pathname.toLowerCase().startsWith("/shopping-cart") &&
        !window.location.pathname.toLowerCase().startsWith("/checkout") && (
          <div className="breadcumb-box-account-cart grid-responsive-width">
            {!["mytraining", "dashboard"].includes(
              window.location.pathname.split("/")[1]
            ) && (
              <Grid>
                <Grid.Row only="computer">
                  <Breadcrumbs crumbs={props.crumbs} />
                </Grid.Row>
              </Grid>
            )}
            <Grid>
              <Grid.Row
                only="tablet computer"
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid.Row>
              {!open && (
                <Grid.Row
                  only="mobile"
                  style={{ display: "none", justifyContent: "flex-end" }}
                >
                  {!isLoggedIn && (
                    <Button
                      className="header-icon-button"
                      basic
                      content={<span>{t("Header_LogIn")}</span>}
                      icon={
                        <Icon style={{ verticalAlign: "sub" }}>
                          <Profile />
                        </Icon>
                      }
                      onClick={login}
                    ></Button>
                  )}
                  {isLoggedIn && (
                    <PopupNew
                      basic
                      trigger={
                        <Button
                          className="header-icon-button"
                          basic
                          icon={
                            <Icon style={{ verticalAlign: "sub" }}>
                              <Profile />
                            </Icon>
                          }
                        ></Button>
                      }
                      eventsEnabled={true}
                      on="click"
                      onClose={() => setmobLoginOpen(false)}
                      onOpen={() => setmobLoginOpen(true)}
                      style={{ borderRadius: 0, padding: 0 }}
                      open={mobLoginOpen}
                      position="bottom center"
                    >
                      <Menu vertical style={{ borderRadius: 0 }}>
                        <Menu.Item
                          name={t("Header_ChangeDetails")}
                          onClick={() => {
                            redirectToPage(
                              `/my-dashboard/${Constants.MYDASHBOARD_MYACOUNTDETAILSTAB}`
                            );
                            setloginOpen(false);
                          }}
                        ></Menu.Item>
                        <Menu.Item
                          name={t("Header_LogOut")}
                          onClick={() => {
                            setIsOpenLogOutModal(true);
                            setmobLoginOpen(false);
                          }}
                        ></Menu.Item>
                      </Menu>
                    </PopupNew>
                  )}
                  <HeaderCartButton
                    className="header-icon-button"
                    isMobile={true}
                  />
                </Grid.Row>
              )}
            </Grid>
          </div>
        )}

      <RDCustomAlert
        type="warning"
        size="mini"
        isOpen={isOpenLogOutModal}
        AcceptButton={
          <RDButton
            primary
            style={{ marginRight: "1rem" }}
            onClick={() => {
              setIsLoggingOut(true);
              logout();
            }}
            disabled={isLoggingOut}
            loading={isLoggingOut}
          >
            {t("CurrentUserAccountDeactivationModal_Yes")}
          </RDButton>
        }
        CancelButton={
          <RDButton
            secondary
            style={{ borderRadius: 0 }}
            onClick={() => setIsOpenLogOutModal(false)}
          >
            {t("CurrentUserAccountDeactivationModal_No")}
          </RDButton>
        }
      >
        <Grid>
          <Grid.Row>
            <p>{t("Header_LogOutText")}</p>
          </Grid.Row>
        </Grid>
      </RDCustomAlert>

      {isOpenSwitchRegion && (
        <SwitchCountryModal
          isShow={isOpenSwitchRegion}
          onCancel={() => setIsOpenSwitchRegion(false)}
        />
      )}

      {isWithdrawCourseAllow && (
        <CourseWithdrawalModal
          enablePendingWithdrawal={
            enrollmentDetail?.eventDetails?.enablePendingWithdrawal ?? false
          }
          employeeCustomerId={account?.customerId ?? 0}
          openModal={enableCourseWithdrawalModal}
          closeCourseWithdrawalModal={closeCourseWithdrawalModal}
          item={trainingEvent}
          enrollmentDetail={enrollmentDetail}
          enrollmentId={selectedEnrollmentId}
          onWithdrawSuccess={() => {
            onCourseWithdrawalSuccess();
          }}
        />
      )}

      {isWithdrawSelected && (
        <WithdrawProgramModal
          closeAction={() => setIsWithdrawSelected(false)}
          withdrawSucessAction={withdrawSucessAction}
        />
      )}

      {openCourseEvaluation && (
        <CourseEvaluationModal
          onDisplayModal={openCourseEvaluation}
          onCloseModalTrigger={() => closeCourseEvaluation()}
          employeeCustomerId={
            (wholesalerProxyMode
              ? selectedWorkshop?.customerId
              : account?.customerId) ?? 0
          }
          employeeStudentId={
            (wholesalerProxyMode || proxyMode
              ? studentId
              : account?.employeeId) ?? 0
          }
        ></CourseEvaluationModal>
      )}

      {isChangingCompany && (
        <ChangeCompanyModal
          setCloseModalTrigger={() => {
            setIsChangingCompany(false);
          }}
        />
      )}

      <EvaluationRestrictionModal
        onDisplayModal={openEvaluationRestriction}
        onCloseModalTrigger={closeEvaluationRestriction}
      />

      {isTrainingProgramDownloadingFailed && (
        <RDAlert
          size="tiny"
          isOpen
          type={"warning"}
          title={t("Dashboard_TrainingProgram_DownloadCertificate_Title")}
          messages={t(
            "Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage"
          )}
          acceptButtonText={t("CommonButton_Close")}
          onAccept={() => {
            setIsTrainingProgramDownloadingFailed(false);
          }}
        />
      )}

      {showExternalWithdrawModal && (
        <RDAlert
          size="tiny"
          isOpen={showExternalWithdrawModal}
          type={"info"}
          title={t("Withdraw_ExternalLinkModal_Title")}
          messages={[
            t("Withdraw_ExternalLinkModal_Message"),
            t("Withdraw_ExternalLinkModal_Confirmation"),
          ]}
          cancelButtonText={t("Withdraw_ExternalLink_Cancel")}
          onCancel={() => setShowExternalWithdrawModal(false)}
          acceptButtonText={t("Withdraw_ExternalLink_Confirm")}
          onAccept={() =>
            redirectExternalLink(trainingEvent?.externalRegistrationLink ?? "")
          }
        />
      )}
    </Fragment>
  );
};

export default Header;
