export const TRANSLATIONS_EN_NL = {
	LandingPage_Title: "Welcome to Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Choose your region / country",
	Header_ContactUs: "Contact us",
	Header_Search: "Search",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Log in or create your account",
	Header_LogIn: "Log in",
	Header_Cart: "Cart",
	Header_ShoppingCart: "Shopping cart",
	Header_LogOut: "Log Out",
	Header_ChangeDetails: "Change Details",
	Footer_ExploreOurTraining: "Explore our training",
	Footer_TrainingAtYourFingertips: "Training at your fingertips",
	Footer_DontMissAnyStory: "Don't miss any story",
	Footer_ExploreOurStories: "Explore our stories",
	Footer_GetInTouchWithUs: "Get in touch with us",
	Footer_SendUsAMessage: "Send us a message",
	Footer_ToContactForm: "To contact form",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "General contact info",
	Footer_ProductSecurity_Psirt: "Product Security (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innovations patents and licenses",
	Footer_PurchasingLogistics: "Purchasing & logistics",
	Footer_CorporateInformation: "Corporate information",
	Footer_LegalNotice: "Legal notice",
	Footer_DataProtectionNotice: "Data protection notice",
	Footer_PrivacySettings: "Privacy settings",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch GmbH 2021, all rights reserved",
	Footer_StillLookingForSomething: "Still looking for something",
	Footer_TrainingServices: "Training Services",
	Footer_Courses: "Courses",
	Footer_Programs: "Programs",
	Footer_EventsCalendar: "Events Calendar",
	Footer_TrainingCenters: "Training Centers",
	Footer_Dashboard: "Dashboard",
	Footer_MyDashboard: "My Dashboard",
	Footer_ShoppingCart: "Shopping Cart",
	Footer_YourShoppingCart: "Your Shopping Cart",
	Footer_Administration: "Administration",
	Footer_Employees: "Employees",
	Footer_Wholesalers: "Wholesalers",
	Footer_Transactions: "Transactions",
	Footer_Workshops: "Workshops",
	Footer_WorkLikeABoschApplyNow: "Work #LikeABosch - Apply now.",
	Footer_BoschGlobalCareers: "Bosch Global Careers",
	Footer_PurchasingAndLogistics: "Purchasing and Logistics",
	Footer_BecomeABusinessPartner: "Become a business partner.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "How our training evolves to help your business",
	Footer_Language: "Country select",
	MenuPopup_MyCart: "My Cart",
	Stories_OurTrainingStories: "Our Training Stories",
	Stories_ConnectingExperts: "Connecting experts and enthusiasts dreamers and doers innovators and influencers - explore and experience",
	Stories_TopStories: "Top Stories",
	Stories_AtHome: "At home",
	Stories_Careers: "Careers",
	Stories_History: "History",
	Stories_IndustryAndTrades: "Industry and trades",
	Stories_Mobility: "Mobility",
	Stories_Research: "Research",
	Stories_Sustainability: "Sustainability",
	Stories_AllStories: "All Stories",
	Stories_BlogATrainersDay: "BLOG | A Trainer's day",
	Stories_ADayinLifeOfATrainer: "A day in the life of a Trainer",
	Stories_BoschServiceTraining: "Bosch Service Training",
	Stories_StoryWhatDrivesYouDrivesUs: "STORY | What drives you drives us",
	Stories_StoryHowCanWeBenefitYourBusiness: "STORY | How can we benefit your business",
	Stories_HowOurTrainingEvolves: "How our training evolves",
	Stories_AModernTrainingApproach: "The online training world",
	Stories_StoryAVirtualOnlineTrainingWorld: "STORY | Your online training opportunities",
	Stories_SubscribeToRSSFeed: "Subscribe to RSS feed",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "About us",
	Homepage_AutomotiveAftermarket: "Automotive Aftermarket",
	Homepage_GetInTouch: "Get in touch",
	Homepage_LatestNews: "Find out why we are driven by the thirst for knowledge",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Our open positions",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "What drives you drives us",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service Training",
	BoschServiceTraining_ShareThisOn: "Share this on",
	BoschServiceTraining_TheChallenge: "The challenge: Mastering new technologies",
	BoschServiceTraining_WorldwidePresence: "Worldwide Presence",
	BoschServiceTraining_TechnicalTraining: "Technical training courses from Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "The offers and opportunities that we at Bosch offer",
	BoschServiceTraining_TechnicalCoursesDesc: "There are suitable training courses for every target group - whether novice or pro. The offering includes one-day training courses and training courses over several days. The training courses are offered in different technical areas and training series (such as gasoline injection diesel injection electrical systems). In the technical training courses Bosch imparts extensive know-how in all standard vehicle systems from Bosch systems from other manufacturers are also discussed in the training courses. The content is specially matched to the requirements of workshop employees.",
	BoschServiceTraining_TrainedEmployeesDesc: "Trained employees are an obvious benefit for automotive workshops. Because today's vehicles are becoming more and more complex and new technologies constantly present new challenges. Only the necessary expertise allows workshop employees to perform diagnostics maintenance and repair work on current vehicle models in an effecient and cost-effective way.",
	BoschServiceTraining_RegardlessOfHow: "Regardless of how good and experienced automotive specialists are - there are always situations in which they will come into contact with new vehicle technology systems or unknown faults. In this regard troubleshooting and problem solving can become especially time-intensive or possibly do not render sucessful results. In the worst case customers must do without their vehicle significantly longer than expected.",
	BoschServiceTraining_ItIsPrecisely: "It is precisely at this point that Bosch bring its Service Training to bear: improving the service quality and speeding the processes. Bosch's current training provides support in localising problems more quickly repairing more vehicles in the same time and reducing te wait times of your customers. Thus the systematic procedures that learn at Bosch ensure faster and more productive work.",
	BoschServiceTraining_WorldwidePresenceDesc1: "For more than 125 years Bosch has been the preferred supplier of original equipments parts components and systems for many of leading automobile manufacturers worldwide. This experience comes with unique knowledge of the latest technologies that serves as the basis for Bosch support.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Bosch Service Training Centers can be found everywhere in the world: from spain to turkey South Africa Australia to Brazil - Bosch offers its employees practice-oriented training training which is always in line with the state-of-the-art technologies.",
	BoschServiceTraining_AgileCompany: "Agile Company",
	BoschServiceTraining_Collaboration: "Collaboration",
	BoschServiceTraining_ConnectedMobility: "Connected Mobility",
	BoschServiceTraining_ArtificialIntelligence: "Artificial Intelligence",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Thought leaders in dialogue",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostics and Test Equipments",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Automotive Technology Fundamentals",
	BoschServiceTraining_Icon_Gasoline: "Gasoline",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternative Drives",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Electrical and Electronics",
	BoschServiceTraining_Icon_BodyAndComfort: "Body and Comfort",
	BoschServiceTraining_Icon_ChassisAndBraking: "Chassis and Braking",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmission and Drivetrain",
	BoschServiceTraining_Icon_CommercialVehicles: "Commercial Vehicles",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Online Training Courses",
	BoschServiceTraining_Icon_BusinessSkills: "Business Skills",
	BoschServiceTraining_Icon_Accreditations: "Accreditations",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Training Course Programmes",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerFirstNameLastName",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "A day in the life of a trainer",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Everyday is an adventure",
	ADayInTheLifeOfATrainer_Summary: "Summary",
	ADayInTheLifeOfATrainer_TheDayJourney: "The day's journey",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Everyday is a busy day but it is always a rewarding experience. The pleasure of training students knowing that they hold the key to our future is a responsibilty but at the same time so exciting. Seeing the students growing with each lesson on a daily and weekly basis proves to us that we are working towards a comon goal to improve their knowledge whilst incorporating the mordern technology that drives the future!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "At the end of each day we have a sit down and reflect on the days work. The days go so quickly but this is proof itself that what we are achieving is not only rewarding for the students but for us as trainers too.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "We can go home at the end of day satisfied that we have done a great job!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Start",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Preparation and final checks of the training material. Check important mail",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Training starts",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Participant welcome. Daily schedule presentation. Get to know the group by understanding the dynamics of each individual.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Theory",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "A certain amount of interaction with the group is required to make the training interesting as possible. Ensure that there are tasks intertwined with the theory to further engage the group",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Lunch break",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Practical",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "The training continues in the workshop with some practical tasks. As a trainer, it is always a requirement to be present and available as always for any eventuality and guidance",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Recap",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Back to the classroom for a brief, to discuss the day's training. Answer any questions that may arise. Begin preparation for the next training sessions.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Final Day",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Attendance certificates are presented to the participants.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Home,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "How can we help benefit your business",
	BoschTrainingBenefits_HowOurTrainingEvolves: "How our training evolves",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "We are constantly evolving to ensure we deliver quality up-to-date-training",
	BoschTrainingBenefits_TheBoschServiceTraining: "The Bosch Service Training Center would like to assist you in developing your business through its extensive and highly relevant portfolio of technical/business training and further education courses with certified qualifications. The following pages provide you with an overview of our training program as well asthe current further education opportunities. We look forward to meeting you and your employees at our training courses and seminars.",
	BoschTrainingBenefits_LongStandingExperience: "Long-standing experience with and extensive know-how of all systems and in thea area of automobile technology.",
	BoschTrainingBenefits_QuickAndEfficient: "Quick and efficient solutions for many problems.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "The Advantages and benefits",
	BoschTrainingBenefits_LMS: "Learning Management System - Bosch Training solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions covers the full range of automotive areas. Specialize in specific areas or broaden your competence with the training offers from Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Acquired knowledge facilities repair maintenance and service work.",
	BoschTrainingBenefits_TheHighDegree: "The high degree of practical content and small groups guarantee target-oriented learning",
	BoschTrainingBenefits_ParticipationInTraining: "Participation in training increases employee motivation and satisfaction and supplemental qualifications convince customers",
	BoschTrainingBenefits_IncreasedSales: "Increased sales through cross-make system knowledge",
	BoschTrainingBenefits_MethodicalProcedures: "Methodical procedures reduce the wait time for customers",
	BoschTrainingBenefits_HighlyQualified: "Highly qualified instructors convey systematic procedures",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identification of the training solution appropriate to you or your business",
	BoschTrainingBenefits_DirectOnlineBooking: "Direct online booking and management of courses either for yourself or your employees",
	BoschTrainingBenefits_OnlineAccess: "Online access to learning documentation",
	BoschTrainingBenefits_AccessToYourOwn: "Access to your own personal training history and certificates",
	BoschTrainingBenefits_ToolsForManagement: "Tools for management of your own career development",
	BoschTrainingBenefits_ManagerHasFull: "Manager has full overview of their employees courses inside of the system",
	BoschTrainingBenefits_Access24hours: "Access 24 hours 7 days a week",
	ModernTrainingApproach_KnowledgeIsKey: "Knowledge is a key success factor for any workshop. That is why Bosch offers a comprehensive professional training development program. This enables students to acquire exactly the right knowledge at right time and place - knowledge required to be able to maintain and repair customer's vehicle professionally. Bosch has added webcasting as a new module part of the e-learning package already offered. They consist of service training sessions designed specifically for streaming.",
	ModernTrainingApproach_ANewLearning: "A new learning opportunity",
	ModernTrainingApproach_YourOnlineTraining: "Your online training opportunities",
	ModernTrainingApproach_RecentlyTheWorld: "Recently the world has changed see what we are doing to help",
	ModernTrainingApproach_TheOnlineTrainingWorld: "The online training world",
	ModernTrainingApproach_NewTeachingMethod: "New teaching method",
	ModernTrainingApproach_InAdditionTo: "In addition to the existing face-to-face training we have included live webcast training in the training program. Our trainer is at your disposal with extensive theoretical knowledge in the virtual classroom or with practical examples. As usual you will receive a lot of information about vehicle systems and the corresponding Bosch products. Of course you also have the opportunity to ask questions. These questions will be answered directly in the live webcast. The live webcasts last between 60 to 120 minutes. A stable internet connection is required. Search for a suitable webcast directly in our training catalog by entering \Webcast\ in the search field.",
	ModernTrainingApproach_BoschWebcastingHeader: "Bosch webcasting: Innovative and practical",
	ModernTrainingApproach_BoschWebcastingText: "To participate in a Bosch webcast first the participant must dial-in. An individual access code is sent in advance of the session in order to enable login. Up to 200 participants can take part at the same time from any number of locations. The training is led by a trainer and questions can be submitted at any time via the live chat function.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "The digital supplement to classroom training",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasting and classroom training complement each other perfectly. In both cases a qualified trainer leads the training. Webcasts are suitable for topics that can be transmitted digitally without the need to work on the vehicle. For example getting to know certain ESI[tronic] features of a new driver assistance system. When it comes to efficient teamwork in the workshop or practical work on a vehicle technology face-to-face training is still the first choice.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Streaming can be cheaper",
	ModernTrainingApproach_StreamingCanBeCheaperText: "The costs per participant are usually lower with webcasting than with a comparable classroom course. The reason is that more participants can benefit from it and the effort associated with the training is significantly lower (no travel to and from the location and less time required).",
	ModernTrainingApproach_BookAndProfitHeading: "Book and profit",
	ModernTrainingApproach_BookAndProfitText: "Webcasts can be booked on our website just like our other training courses and learning offers. You have the choice of either paying the individual fee for each webcast or taking out an annual subscription for a certain number of webcasts.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Bosch Webcasting and classroom training compared",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Classroom",
	ModernTrainingApproach_WebcastingP1: "Up to 200 participants can take part at the same time from anywhere around the world",
	ModernTrainingApproach_WebcastingP2: "Low complexity and relatively low costs no travel required",
	ModernTrainingApproach_WebcastingP3: "Ideal for specific and concise individual topics",
	ModernTrainingApproach_WebcastingP4: "Workshops can run a webcast for their employees anywhere and anytime",
	ModernTrainingApproach_WebcastingP5: "Between 50-70% less time required than for classroom trainings",
	ModernTrainingApproach_ClassroomTrainingP1: "Up to 16 participants meet at the same time",
	ModernTrainingApproach_ClassroomTrainingP2: "Travel expenses and generally higher costs per person",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideal when it comes to optimising collaboration or learning directly on a vehicle",
	ModernTrainingApproach_ClassroomTrainingP4: "Practical exercises and interaction with the trainer",
	ModernTrainingApproach_ClassroomTrainingP5: "The trainer can better adapt the knowledge to be taught according to the actual needs of the participants",
	ModernTrainingApproach_OnlineTrainingOfferings: "Online training offerings (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Today independent learning at the computer is an integral part of knowledge transfer. The freedom to choose time and place unlimited repeatability individual speed of learning and interactive learning contents make this method an indispensable studying tool.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Each Web-Based Training (WBT) element has been carefully structured. As well as providing a broad knowledge base these courses can serve as effective preparation for training programs at Bosch training centers.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "The virtual diagnostic simulation offers the user role-play possibilities for daily workshop situations such as the workflow of a troubleshooting procedure using Bosch diagnostic devices in a virtual workshop.",
	ModernTrainingApproach_Advantages: "Advantages",
	ModernTrainingApproach_AdvantagesP1: "A fast and efficient method of providing knowledge",
	ModernTrainingApproach_AdvantagesP2: "Varied learning",
	ModernTrainingApproach_AdvantagesP3: "Can be used as often as desired",
	ModernTrainingApproach_AdvantagesP4: "Minimal time and money",
	ModernTrainingApproach_AdvantagesP5: "Individual speed of learning",
	ModernTrainingApproach_AdvantagesP6: "Flexible study time",
	ModernTrainingApproach_AdvantagesP7: "Repeatability",
	ModernTrainingApproach_AdvantagesP8: "No travel costs",
	ModernTrainingApproach_AdvantagesP9: "No capacity or scheduling conflicts",
	Dashboard_CoursesViewAndManageYourCourses: "COURSES | View and manage your courses",
	Dashboard_MyCourses: "My Courses",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "TRAINING PROGRAM | View your training programs",
	Dashboard_MyTrainingProgram: "My Training Program",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | View and manage your eLearning portfolio",
	Dashboard_MyELearning: "My eLearning",
	Dashboard_AccountManageYourAccount: "ACCOUNT | Manage your account",
	Dashboard_MyAccount: "My Account",
	Dashboard_MyDashboard: "Dashboard",
	Dashboard_ManageYourAccount: "Manage your account",
	Dashboard_Workshops: "Workshops",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRATION | Manage your workshop",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRATION | Manage your teams",
	Dashboard_Administration: "Administration",
	Dashboard_ViewYourCourses: "View your courses",
	Dashboard_ViewYourTrainingPrograms: "View your training programs",
	Dashboard_TrainingPrograms: "Training Programs",
	Dashboard_ViewAndEditYourDetails: "View and edit your Details",
	Dashboard_ViewYourELearningCourses: "View your eLearning courses",
	Dashboard_Pending: "Pending",
	Dashboard_RequiresAdministratorSignOff: "(requires Administrator sign off)",
	Dashboard_HistoricalRecords: "Historical Records",
	Dashboard_Registered: "Registered",
	Dashboard_WaitingList: "Waiting List",
	Dashboard_RecommendedForYou: "Recommended for you",
	Dashboard_AccountDetails: "Account Details",
	Dashboard_ChangeProfileImage: "change profile image",
	Dashboard_Title: "Title",
	Dashboard_Role: "Role",
	Dashboard_FirstName: "First Name",
	Dashboard_LastName: "Last Name",
	Dashboard_Telephone: "Telephone",
	Dashboard_Mobile: "Mobile",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Language",
	Dashboard_Save: "Save",
	Dashboard_Student: "Student",
	Dashboard_WorkshopTrainingAdministrator: "Workshop Training Administrator",
	Dashboard_WholesalerTrainingAdministrator: "Wholesaler Training Administrator",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Company Administration",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Manage your workshops...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Manage your employees...",
	Dashboard_CompanyAdministration_Workshop: "Workshop",
	Dashboard_CompanyAdministration_Address: "Address",
	Dashboard_CompanyAdministration_Zip: "Zip",
	Dashboard_CompanyAdministration_City: "City",
	Dashboard_CompanyAdministration_Manage: "Manage",
	Dashboard_AdministrationSection_AdministrationTitle: "Administration",
	Dashboard_AdministrationSection_ManageTheTeam: "Manage the team",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRATION | Manage your Employees",
	Dashboard_AdministrationSection_Employees: "Employees",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRATION | Manage your Wholesalers",
	Dashboard_AdministrationSection_Wholesalers: "Wholesalers",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRATION | Manage your Transactions",
	Dashboard_AdministrationSection_Transactions: "Transactions",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Employee Administration",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Manage your employees...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Register",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Records",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Edit",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Employee",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Add New",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Create",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registered",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Pending",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(requires Administrator sign off)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historical Records",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Waiting List",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Current Employee List",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Employee Management",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Name",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Employee Account",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "View and edit employee details...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Settings",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Allow personal Bosch Automotive Training Solutions account",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Upgrade employee to company administrator",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Allow course registration",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Send Email Invitation",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Deactivate Account",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Save",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Invite",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Edit",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Wholesaler Management",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Manage your wholesalers...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Approved Wholesaler List",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Name",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Remove",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Create",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Add",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "New Employee Account Creation",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Create details for new employees...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Add new Approved Wholesaler Administrator",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Create details for new wholesalers...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Choose approved Wholesaler from drop-down menu...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transactions",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "View and manage your transactions...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Back",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Invoice Number",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Description",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Payment Method",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Date",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Amount",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Download",
	TrainingCentres_TrainingCentres: "Training Centres",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Our training centres are situated throughout the globe",
	TrainingCentres_CountryCategory: "Country Category",
	TrainingCentres_CityCategory: "City Category",
	TrainingCentres_Country: "Country",
	TrainingCentres_City: "City",
	TrainingCatalogue_TrainingCatalogue: "Training Course Catalogue",
	TrainingCatalogue_LearningAtTheHighestLevel: "Learning at the highest Level",
	TrainingCatalogue_StartDate: "Start Date",
	TrainingCatalogue_EndDate: "End Date",
	TrainingCatalogue_TrainingName: "Training Name",
	TrainingCatalogue_Language: "Language",
	TrainingCatalogue_Days: "Days",
	TrainingCatalogue_Seats: "Available Seats",
	TrainingCatalogue_Price: "Price",
	TrainingCatalogue_Location: "Location",
	TrainingCatalogue_PartNumber: "Part Number",
	TrainingCatalogue_TrainingCourse: "Training Course",
	TrainingCatalogue_Category: "Category",
	TrainingCatalogue_TrainingCategory: "Training Category",
	TrainingCatalogue_CourseName: "Course Name",
	TrainingCatalogue_TotalDays: "Total Days",
	TrainingCatalogue_Venue: "Venue",
	TrainingCatalogue_Details: "Details",
	TrainingCatalogue_Prerequisites: "Prerequisites",
	TrainingCatalogue_CourseType: "Course Type",
	TrainingCatalogue_LearningMethod: "Learning Method",
	TrainingCatalogue_AddToWaitingList: "Add to waiting list",
	ShoppingCart_ShoppingCart: "Shoppping Cart",
	ShoppingCart_ReviewAndManage: "Review and manage your subscriptions",
	ShoppingCart_YouHaveAddedItem: "You have added the following items to your cart",
	ShoppingCart_Item: "Item",
	ShoppingCart_Quantity: "Quantity",
	ShoppingCart_PricePerItem: "Price Per Item",
	ShoppingCart_TotalPrice: "Total price",
	ShoppingCart_VatIsIncluded: "Excl. VAT",
	ShoppingCart_Redeem: "If you have no credit click here to redeem it",
	ShoppingCart_Purchase: "If you have no credit click here to purchase",
	ShoppingCart_Checkout: "Checkout",
	ShoppingCart_ContinueShopping: "Continue Shopping",
	ShoppingCart_TotalPriceInclVat: "Total price incl. VAT",
	ShoppingCart_Apply: "Apply",
	ShoppingCart_ShoppingCartEmpty: "Your Shopping Cart is empty",
	ShoppingCart_VatIncluded: "VAT(Included)",
	ShoppingCart_ManageYourShopping: "Manage your shopping",
	ShoppingCart_Participant: "Participant",
	ShoppingCart_Complete: "Complete",
	ShoppingCart_NetPrice: "Net price",
	ShoppingCart_Workshop: "Workshop",
	CheckOut_PreviewYourOrder: "Preview Your Order",
	CheckOut_YourShoppingCart: "Cart",
	CheckOut_Preview: "Preview",
	CheckOut_Payment: "Payment",
	CheckOut_Summary: "Summary",
	CheckOut_Back: "Back",
	CheckOut_PleaseReadAndAgreeOurTerms: "Please read and agree to our terms and conditions",
	CheckOut_IHaveReadTheTermsAndCondition: "I have read the [terms and conditions] and I understand and accept them",
	CheckOut_YesIAgree: "Yes - I agree to the terms and conditions",
	CheckOut_Continue: "Continue",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Congratulations! We have received your order",
	CheckOut_OrderConfirmation: "You will receive final order confirmation once your request has been processed successfully",
	CheckOut_WeWillContactYouDirectly: "We will contact you directly in case we have any questions related to your request",
	CheckOut_AddToCart: "Add to Cart",
	CheckOut_CreditCart: "Credit Cart",
	CheckOut_DirectDebit: "Direct Debit",
	CheckOut_TrainingCredits: "Credits",
	CheckOut_PreferredWholesaler: "Preferred Wholesaler",
	EventCalendar_EventCalendar: "Event Calendar",
	EventCalendar_TrainingEventsForAllYourNeeds: "Training events for all your needs",
	TrainingCourse_TrainingCourse: "Training Course Catalogue",
	TrainingCourse_LearningAtTheHighestLevel: "Learning at the highest Level",
	TrainingProgram_Title: "Training Program Catalogue",
	TrainingProgram_SubTitle: "Learning at the highest Level",
	AccountDeactivationModal_Title: "Are you sure you want to deactivate this account?",
	AccountDeactivationModal_Line1: "Deactivating this account is permanent and will remove all content including services request transactions and balances team members and profile settings.",
	AccountDeactivationModal_Line2Part1: "If it is within an active subscription period please refer to our cancellation policy in our",
	AccountDeactivationModal_Line2Part2: "terms & conditions",
	AccountDeactivationModal_Line2Part3: "before submitting your request.",
	AccountDeactivationModal_Line3: "Are you sure you want to submit a request to deactivate this account?",
	AccountDeactivationModal_Cancel: "Cancel",
	AccountDeactivationModal_Send: "Send",
	CurrentUserAccountDeactivationModal_Title: "Are you sure you want to deactivate your account?",
	CurrentUserAccountDeactivationModal_Line1: "Deactivating your account is permanent and will remove all content including services request transactions and balances team members and profile settings.",
	CurrentUserAccountDeactivationModal_Line2Part1: "If you are within an active subscription period please refer to our cancellation policy in our",
	CurrentUserAccountDeactivationModal_Line3: "Are you sure you want to submit a request to deactivate your account?",
	CurrentUserAccountDeactivationModal_Yes: "Yes",
	CurrentUserAccountDeactivationModal_No: "No",
	CourseWithdrawalModal_Title: "Course Cancellation",
	CourseWithdrawalModal_Line1: "Are you sure you want to withdraw from the Course?",
	CourseWithdrawalModal_Line2Part1: "Withdrawals are subject to cancellation fees and will be charged according to the",
	CourseWithdrawalModal_Line2Part2Link: "terms & conditions",
	CourseWithdrawalModal_Line2Part3: "of our service.",
	CourseWithdrawalModal_Yes: "Yes, withdraw",
	CourseWithdrawalModal_No: "No, cancel",
	Settings_Settings: "Settings",
	Settings_ManageYourSettings: "Manage your settings",
	Settings_Profile: "Profile",
	Settings_MyBoschGlobalIdLoginInformation: "My Bosch Global ID Login Information",
	Settings_Edit: "Edit",
	Settings_CompanyDetails: "Company Details",
	Settings_RequestAccountDeletion: "Request Account Deletion",
	Settings_AccountDeletionText: "If you delete your account all of your data (including your address and payment data) will be erased and will no longer be available.",
	Settings_DeleteAccountRequestConfirmation: "Delete Account Request Confirmation",
	Settings_DeleteAccountRequestConfirmationText: "We have received your account deletion request and will contact you by email or phone for final confirmation",
	RecommendedHotels_RecommendedHotels: "Recommended Hotels",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Our training centres are situated throughout the globe",
	Error_Common: "Something went wrong!",
	Error_404: "The page you visited does not exist",
	Error_401: "You dont have access to this page",
	Error_500: "The server is reporting an error",
	Dashboard_Courses_Table_Course: "Course",
	Dashboard_Courses_Table_Date: "Date []",
	Dashboard_Courses_Table_Venue: "Venue",
	Dashboard_Courses_Table_TrainingType: "Training Type",
	Dashboard_Courses_Table_Action: "Action",
	Dashboard_Courses_Table_TasksToComplete: "You have following tasks to complete",
	Dashboard_Courses_Table_Withdraw: "Withdraw",
	Dashboard_Courses_Table_Remove: "Remove",
	Dashboard_Courses_Table_View: "View",
	Dashboard_Courses_Table_NoRecordsToDisplay: "No Records to display",
	Dashboard_Courses_Table_CompletionDate: "Completion Date",
	Dashboard_Courses_Table_Actions_Assignments: "Assignments",
	Dashboard_Courses_Table_Actions_Certification: "Certification",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluations",
	Dashboard_Courses_Table_Actions_Assessments: "Assessments",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Add to Calendar",
	Dashboard_Courses_Table_CourseDetails_Task: "Task",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Due Date",
	ContactUs_Headline: "Contact us",
	ContactUs_HowCanWeHelp: "How can we help you?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Phone",
	ContactUs_LetterTab: "Letter",
	ContactUs_Phone_Headline: "Call us!",
	ContactUs_Phone_Description: "We look forward to your call and will be pleased to assist you.",
	ContactUs_Phone_PhoneNumber: "Please use the contact form to send us a message.",
	ContactUs_Phone_ChargeNotice: "Charges vary depending on your service provider and country.",
	ContactUs_Letter_Headline: "Send a letter!",
	ContactUs_Letter_Description: "Do you have questions, requests, or suggestions? We look forward to your inquiry.",
	ContactUs_Email_Headline: "Send an e-mail!",
	ContactUs_Email_Description: "Send us your message by filling out the form below. One of our advisors will be happy to get in touch with you.",
	ContactUs_Email_InputName: "Name*",
	ContactUs_Email_InputCompany: "Company",
	ContactUs_Email_InputCountry: "Country*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Message*",
	ContactUs_Email_DataProtection: "Data protection notice*",
	ContactUs_Email_DataProtectionConfirm: "I have read the Privacy Policy.*",
	ContactUs_Email_SecurityCheck: "Security check",
	ContactUs_Email_SecurityCodeConfirm: "Please enter the correct characters you see in the box above.",
	ContactUs_Email_SecurityCodeInvalid: "The captcha text answer you entered is incorrect.",
	ContactUs_Email_MandatoryNotice: "Please fill in mandatory fields.",
	ContactUs_Email_SuccessMessage: "Your message has been submitted",
	ContactUs_Email_TakenNoteDataProtection1: "I have read the",
	ContactUs_Email_TakenNoteDataProtection2: "Privacy Policy.*",
	ContactUs_Email_SubmitButton: "Submit",
	ContactUs_Email_FieldErrorMessage: "Please fill in mandatory fields.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Your account is currently in review!",
	AccountInReviewModal_Paragraph1: "Thank you registering a personal account on Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Our dedicated training staff are currently reviewing and configuring your account. You will receive final confirmation by mail, once your account been successfully configured.",
	AccountInReviewModal_Paragraph3: "Meanwhile, please feel free to browse our website site with limited access.",
	AccountInReviewModal_Paragraph4: "If you have any questions, or if your account is not processed within 48 hours excluding weekends and holidays, you can contact us",
	AccountInReviewModal_ContactUsLink: "here",
	AccountInReviewModal_OKButton: "Okay, let me in!",
	Header_YesButton: "Yes",
	Header_NoButton: "No",
	Header_LogOutText: "Do you want to log out?",
	Footer_EnquiryText: "We look forward to your enquiry,",
	Footer_Telephone: "Telephone,",
	Footer_TermsAndConditions: "Terms & Conditions",
	Footer_HowOurTrainingEvolves: "How our training evolves",
	FooterSearch_SearchFieldPlaceholder: "Search",
	TrainingCatalogue_Action: "Action",
	TrainingCatalogue_Duration: "Duration",
	TrainingCatalogue_Information: "Information",
	Settings_PersonalDetails: "Personal Details",
	Reset_All_Filters: "Reset all filters",
	EventTable_Days: "Days",
	SearchField_AllResults: "All results",
	TrainingCentresLayout_ViewDetails: "View details",
	Dropdown_Placeholder: "Choose...",
	WaitlistPopup_SelectButtonText: "Select",
	WaitlistPopup_StartDate: "Start Date",
	WaitlistPopup_EndDate: "End Date",
	WaitlistPopup_Language: "Language",
	WaitlistPopup_City: "City",
	WaitlistPopup_Workshop: "Workshop",
	WaitlistPopup_Student: "Student",
	WaitlistPopup_PreferredLocation: "Preferred location",
	WaitlistPopup_SignUpCourseWaitingList: "Sign up to Course Waiting list",
	WaitlistPopup_SignUpWithoutSelectingDate: "Sign up to Waiting list without selecting a date",
	WaitlistPopup_GeneralWaitList: "General wait list",
	WatinglistPopup_CourseLabel: "Course",
	Common_ContinueButton: "Continue",
	UserAccountDeactivationModal_ConfirmLabel: "Confirm",
	Filter_FilterLabel: "Filter",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "You are already on the wait list, do you want to remove your existing registration and make a new one?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Yes, remove",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "No, Cancel",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Course cancellation",
	WaitinglistPopup_SignUpButtonText: "Sign up",
	MyAccount_FirstNameRequied: "First Name is required",
	MyAccount_LastNameRequired: "Last Name is required",
	MyAccount_RoleRequired: "Role is required",
	MyAccount_MobileRequired: "Mobile is required",
	MyAccount_EmailRequired: "Email is required",
	MyAccount_LanguageRequired: "Language is required",
	MyAccount_UpdateSuccessMessage: "Your account details is updated successful!",
	MyAccount_UpdateSuccessModal_ButtonText: "Yes, close",
	Infor_CorporateInformation_Header: "Corporate Information",
	Infor_LegalNotice_Header: "Legal Notice",
	Infor_PrivacyPolicy_Header: "Data protection notice (Privacy policy)",
	CreateEmployeeAccountModification_FormValidation_Role: "Role is required",
	CreateEmployeeAccountModification_FormValidation_Firstname: "First name is required",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Last name is required",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobile is required",
	CreateEmployeeAccountModification_FormValidation_Email: "Email is required",
	CreateEmployeeAccountModification_FormValidation_Language: "Language is required",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Invitation to join Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "You have successfully added a new member to your team!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Would you like us to share an email invitation with your new team member, with instructions how they can create their own personal account for Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "No",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Yes, send invite",
	ShoppingCart_Venue_Fee: "Venue Fee",
	ShoppingCart_Trainning_Name: "Trainning Name",
	ShoppingCart_Available_Credits: "Available Credits",
	ConfirmItsMeModal_Hi: "Hi",
	ConfirmItsMeModal_Description1: "You have successfully completed the invitation shared by your company administrator, to create your own personal account on Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Before continuing, please confirm or update your account details.",
	ConfirmItsMeModal_Description3: "After confirming, you will be required to log in again to complete your account creation.",
	ConfirmItsMeModal_CompanyDetails: "Company Details",
	ConfirmItsMeModal_YourDetails: "Your Details",
	ConfirmItsMeModal_YourDetails_Title: "Title",
	ConfirmItsMeModal_YourDetails_Function: "Function",
	ConfirmItsMeModal_YourDetails_Name: "Name",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobile",
	ConfirmItsMeModal_YourDetails_Email: "Email",
	ConfirmItsMeModal_YourDetails_Language: "Language",
	ConfirmItsMeModal_ConfirmButton: "Confirm",
	SelectWholesalerModal_WholesalerGroupLabel: "Wholesaler",
	SelectWholesalerModal_WholesalerBranchLabel: "Outlet",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Please select",
	ApplyButton: "Apply",
	SelectWholesalerModal_Title: "Choose your Wholesaler",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Do you want to remove your registration?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Your registration is removed success!",
	WaitlistPopup_AddToWaitListSuccess: "Your registration is added success!",
	ErrorCommon_Header: "ERROR",
	CommonButton_Close: "Close",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Please close this window to continue!",
	EmbeddedVideoPlayer_StartButtonText: "Start",
	EmbeddedVideoPlayer_RefresherButtonText: "Refresher",
	DeactivateWholesalerModal_ConfirmMessage: "Are you sure you want to remove this wholesaler?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Send invitation email successful!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Re-Invite",
	ContactUs_Mobile_NL: "+31 0342403467",
	ContactUs_Address_NL: "Robert Bosch B.V.,Hanzeweg 31,3771 NG Barneveld,Netherland",
	ConfirmItsMeModal_CompanyDetails_Name: "Company Name",
	ConfirmItsMeModal_CompanyDetails_Address: "Address",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Company Postcode",
	ConfirmItsMeModal_CompanyDetails_Phone: "Phone",
	ConfirmItsMeModal_CompanyDetails_Email: "Email",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Withdraw from the Course is successful!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Account creation is failed. The account is already existing!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Update account is failed. The account is already existing!",
	Footer_ExploreTrainingStories: "Explore all our training stories",
	Breadcrumb_Home: "Home",
	Breadcrumb_Contact: "Contact",
	Breadcrumb_Stories: "Stories",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "A day in the life of a trainer",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch Training Benefits",
	Breadcrumb_Stories_TrainingApproach: "A modern training approach",
	Breadcrumb_Settings: "Settings",
	Breadcrumb_ShoppingCart: "Shopping Cart",
	Breadcrumb_Checkout: "Check Out",
	Breadcrumb_OrderSummary: "Order Summary",
	Breadcrumb_Exception: "Exception",
	Breadcrumb_Dashboard: "Dashboard",
	Breadcrumb_MyCourses: "My Courses",
	Breadcrumb_MyELearning: "My eLearning",
	Breadcrumb_CompanyAdministration: "Company Administration",
	Breadcrumb_EmployeeManagement: "Employee Management",
	Breadcrumb_EmployeeAccount: "Employee Account",
	Breadcrumb_EmployeeCreation: "Employee Creation",
	Breadcrumb_Administration: "Administration",
	Breadcrumb_EmployeeAdministration: "Employee Administration",
	Breadcrumb_WholesalerManagement: "Wholesaler Management",
	Breadcrumb_NewWholesalerAccountCreation: "New Wholesaler Account Creation",
	Breadcrumb_Transactions: "Transactions",
	Breadcrumb_MyAccount: "My Account",
	Breadcrumb_TrainingCentres: "Training Centres",
	Breadcrumb_EventCalendar: "Event Calendar",
	Breadcrumb_TrainingCourseCatalogue: "Training Course Catalogue",
	Breadcrumb_RecommendedHotels: "Recommended Hotels",
	Breadcrumb_CorporateInformation: "Corporate Information",
	Breadcrumb_LegalNotice: "Legal Notice",
	Breadcrumb_DataProtectionNotice: "Data Protection Notice",
	Breadcrumb_PrivacyPolicy: "Privacy Policy",
	Breadcrumb_TermCondition: "Term & Condition",
	Breadcrumb_Terms: "Terms",
	Breadcrumb_PrivacyStatement: "Privacy Statement",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Provider",
	Breadcrumb_RegisterAccount: "Register Account",
	Stories_ExploreMore: "Explore more",
	CheckOutLayout_ShoppingCartHeader: "Shopping Cart",
	CheckOutLayout_AddedItemCartMessage: "You have added the following to your cart",
	CheckOutLayout_CheckYourCartMessage: "Check your cart",
	ModernTrainingApproach_DateText: "28-02-2022",
	ADayInLifeofATrainer_DateText: "28-02-2022",
	BoschTrainingBenefits_DateText: "28-02-2022",
	BoschServiceTraining_DateText: "28-02-2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Search Employees",
	CheckOutLayout_OrderWasNotPlaced_Message: "Sorry! Your order was not placed",
	CheckOutLayout_RefusalReason: "Refusal Reason",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Payment failed. Please retry with a different payment method",
	CheckOutLayout_SubmittingApiFailed_Message: "Submitting API failed",
	CartItem_ChooseWorkshop_Message: "Please choose workshop to choose participant",
	CartItem_ChooseParticipant_Message: "Please choose participant to continue",
	CompanyAdministrationTable_SearchBox_Placeholder: "Search Workshops",
	CartItem_RemoveCartItem_ConfirmText: "Do you want to remove this item out of the cart?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Function",
	AddToCartSuccessModal_Message: "Your shopping cart has been updated!",
	AddToCartSuccessModal_ContinueButtonText: "Continue shopping",
	AddToCartSuccessModal_MyShoppingCartButtonText: "My Shopping Cart",
	AddVoucher_Header: "Add voucher",
	AddVoucher_InputPlaceholder: "Enter code and apply",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Unfortunately, we do not have any scheduled events of this training course at the moment. Please register to waiting list, and we will inform you once an event becomes available.",
	ADYEN_ERR_2_: "The transaction was refused.",
	ADYEN_ERR_3_: "Referrals error",
	ADYEN_ERR_4_: "The transaction did not go through due to an error that occurred on the acquirer's end.",
	ADYEN_ERR_5_: "The card used for the transaction is blocked, therefore unusable.",
	ADYEN_ERR_6_: "The card used for the transaction has expired. Therefore it is unusable.",
	ADYEN_ERR_7_: "An amount mismatch occurred during the transaction process.",
	ADYEN_ERR_8_: "The specified card number is incorrect or invalid.",
	ADYEN_ERR_9_: "It is not possible to contact the shopper's bank to authorise the transaction.",
	ADYEN_ERR_10_: "The shopper's bank does not support or does not allow this type of transaction.",
	ADYEN_ERR_11_: "3D Secure authentication was not executed, or it did not execute successfully.",
	ADYEN_ERR_12_: "The card does not have enough money to cover the payable amount. This problem could occur due to a monthly payable amount limit on your card. Please try to use another card such as a MasterCard, or contact your bank to reset the monthly limit of your card.",
	ADYEN_ERR_14_: "Possible fraud.",
	ADYEN_ERR_15_: "The transaction was cancelled.",
	ADYEN_ERR_16_: "The shopper cancelled the transaction before completing it.",
	ADYEN_ERR_17_: "The specified PIN is incorrect or invalid.",
	ADYEN_ERR_18_: "The shopper specified an incorrect PIN more that three times in a row.",
	ADYEN_ERR_19_: "It is not possible to validate the specified PIN number.",
	ADYEN_ERR_20_: "Possible fraud.",
	ADYEN_ERR_21_: "The transaction was not submitted correctly for processing.",
	ADYEN_ERR_22_: "The risk check flagged the transaction as fraudulent (risk score >= 100); therefore, the operation is cancelled.",
	ADYEN_ERR_23_: "The following declined codes are mapped to this refusal reason:  Transaction not permitted to issuer/cardholder",
	ADYEN_ERR_24_: "The specified CVC (card security code) is invalid.",
	ADYEN_ERR_25_: "The following decline codes are mapped to this refusal reason:  Invalid card in this country",
	ADYEN_ERR_26_: "R1: Revocation of Authorization Order / \"R3: Revocation of All Authorizations Order\" / \"R0: Stop Payment Order\"",
	ADYEN_ERR_27_: "This response maps all those response codes that cannot be reliably mapped. This makes it easier to tell generic declines (for example, Mastercard \"05: Do not honor\" response) from more specific ones.",
	ADYEN_ERR_28_: "The withdrawal amount permitted for the shopper's card has exceeded.",
	ADYEN_ERR_29_: "The number of withdrawals permitted for the shopper's card has exceeded.",
	ADYEN_ERR_31_: "Issuer reported the transaction as suspected fraud.",
	ADYEN_ERR_32_: "The address data the shopper entered is incorrect.",
	ADYEN_ERR_33_: "The shopper's bank requires the shopper to enter an online PIN.",
	ADYEN_ERR_34_: "The shopper's bank requires a checking account to complete the purchase.",
	ADYEN_ERR_35_: "The shopper's bank requires a savings account to complete the purchase.",
	ADYEN_ERR_36_: "The shopper's bank requires the shopper to enter a mobile PIN.",
	ADYEN_ERR_37_: "The shopper abandoned the transaction after they attempted a contactless payment and were prompted to try a different card entry method (PIN or swipe).",
	ADYEN_ERR_38_: "The issuer declined the authentication exemption request and requires authentication for the transaction. Retry with 3D Secure.",
	ADYEN_ERR_39_: "The issuer or the scheme wasn't able to communicate the outcome via RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "First name must not include special characters (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "First name must be less than 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Last name must not include special characters (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Last name must be less than 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobile number must not include special characters (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobile number must be less than 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telephone number must not include special characters (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telephone number must be less than 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Email address is invalid",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "Email must be less than 100",
	ConfirmGeolocationModal_ConfirmButton: "Continue",
	ConfirmGeolocationModal_CancelButton: "Change",
	ConfirmGeolocationModal_CurrentCountrySelection: "Your current country selection is:",
	ConfirmGeolocationModal_AskChangeCountry: "Do you wish to remain on this site or change your country preference?",
	Addtocart_Modal_AddToCartFailTitle: "Add participant failed",
	Addtocart_Modal_AlreadyInCart: "Participant already registered to this Event",
	TrainingCatalogue_DateRange: "Date Range",
	TrainingCatalogue_DateRange_placeholder: "Select date range filter",
	Dashboard_MyPendingCourses: "My Pending Courses",
	Dashboard_MyWaitingList: "My Waiting List",
	Dashboard_LatestNews: "Latest News",
	SettingsMainPage_EditAccountButton: "Edit Account",
	SettingsMainPage_EditBoschId: "Edit Bosch ID",
	EventInfoView_EventDetailTab: "Event Details",
	EventInfoView_AssignmentsTab: "Assignments",
	EventInfoView_EvaluationTab: "Evaluation",
	EventInfoView_AttachmentTab: "Attachments",
	EventInfoView_CourseDescriptionTab: "Description",
	EventInfoView_DescriptionTab_General: "General",
	EventInfoView_DescriptionTab_PartLabel: "Part",
	AssignmentTab_Description: "Here you will find all of the information you will need to complete this course.",
	AssignmentTab_Columns_Download: "Download",
	AssignmentTab_Columns_Upload: "Upload",
	AssignmentTab_Columns_TaskComplete: "Task Complete",
	EventDetailTab_Location_Message_Video: "This is an online event which can be accessed by using the \"Launch Video\" link.",
	EventDetailTab_Location_Message_WebBased: "This is an online event which can be accessed by using the \"Launch WBT\" link.",
	EventDetailTab_Location_Message_WebCast: "This is an online event which can be accessed by using the \"Join Now\" link.",
	EventDetailTab_Link_Video: "Launch Video",
	EventDetailTab_Link_Video_Remark: "*Launching the video will open a new window. You will be redirected back to this page once the window is closed.",
	EventDetailTab_Link_WebBased: "Launch WBT",
	EventDetailTab_Link_WebBased_Remark: "*Launching the WBT will open a new window. You will be redirected back to this page once the window is closed.",
	EventDetailTab_Link_WebCast: "Join Now",
	EventDetailTab_Link_WebCast_Remark: "*Joining Now link will be active 30 minutes before the start of the event.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Durations (hrs)",
	EventDetailTab_Title_Trainers: "Trainer(s)",
	EventDetailTab_Title_Language: "Language",
	EventDetailTab_Title_Score: "Score (%)",
	EventDetailTab_Title_Calendar: "Add to calendar",
	EventDetailTab_Title_Certificate: "Download certificate",
	EventDetailTab_Title_Messages: "Messages",
	EventDetailTab_Title_StartDate: "Start date",
	EventDetailTab_Title_AvailableFrom: "Available from",
	EventDetailTab_Title_Time: "Time",
	EventDetailTab_Title_EndDate: "End date",
	EventDetailTab_Title_ExpiresOn: "Expires on",
	EventDetailTab_Title_Room: "Room",
	EventDetailTab_Title_Method: "Method",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Location",
	AttachmentTab_EmptyScreenDescription: "There is no attachments for this event.",
	AttachmentTab_Columns_Download: "Download",
	AttachmentTab_Description: "Here you will find some important information to help you with this course",
	EvaluationTab_Annotation: "We value your feedback and would love to hear from you to see how we are doing. Please take the time to fill in the following evaluation to help us to continually improve our service to you. Thank you.",
	EvaluationTab_Notice: "The ratings are as follows",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Please answer all mandatory questions before submitting your event evaluation",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Your feedback has been submited successful",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "The evaluation submission failed",
	TrainingCatalogueLayout_ExportButtonText: "Export",
	EvaluationTab_FeedbackHasBeenGiven_Message: "The Evaluation was completed or has expired.",
	EventDetailTab_Link_CopiedTooltip: "Copied to Clipboard",
	EventDetailTab_QRCode_ExplanationText_WBT: "Scan to launch WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Scan to launch Video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Scan to launch Webcast",
	Footer_TrainingCoure_Headline: "Training courses provided by Bosch",
	Footer_TrainingCourse_Content: "As a world-renowned Superbrand and with over a century of experience in innovation, Bosch has set the standards globally as a provider of automotive systems, parts and technical solutions. Much of our strength comes from our close partnerships with vehicle manufacturers, for whom we design, develop and produce state-of-the-art systems and components the world over. Consequently, Bosch has cemented a strong history in automotive aftermarket products and support with a diverse portfolio of parts, diagnostic solutions and workshop services. Learning and development are sure keys to success and this philosophy is underpinned by a broad range of training courses, in all areas of automotive technology, vehicle systems and diagnostic expertise.",
	SearchField_NoRecordsFound: "No records found",
	TrainingCatalogue_FreeTextSearch: "Free Text",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Search",
	CheckoutLayout_WholeSalerGroup: "Choose Wholesaler Group",
	CreditInsufficientModal_Message: "You don't have sufficient credit for using this payment method. Please try again with another payment method.",
	ShoppingCart_Required_Credits: "Total price",
	MaintainingInfo_Message1: "The website is in maintenance mode.",
	MaintainingInfo_Message2: "We will be back soon.",
	CountryValidationModal_Message: "You couldn't login to [CountryA] as your account has been created in [CountryB].",
	CountryValidationModal_ButtonText: "Continue to [Country]",
	EventTable_LoginRequired: "Login to register",
	TrainingPartTableLayout_Date: "Date",
	TrainingPartTableLayout_StartTime: "Start Time",
	TrainingPartTableLayout_EndTime: "End Time",
	TrainingPartTableLayout_Duration: "Durations (hrs)",
	TrainingPartTableLayout_TrainingMethod: "Training Method",
	MyCoursesTableContents_ActiveHeader: "Active Courses",
	MyCoursesTableContents_ActiveTabHeader: "Active",
	MyCoursesTableContents_ScheduledHeader: "Scheduled Courses",
	MyCoursesTableContents_ScheduledTabHeader: "Scheduled",
	MyCoursesTableContents_OnlineHeader: "Online Courses",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Cancelled Courses",
	MyCoursesTableContents_CancelledTabHeader: "Cancelled",
	OnlineCoursesTable_Title_Progress: "Progress",
	ScheduledCoursesTable_Title_Date: "Date [Days]",
	CoursesTable_Title_Completed: "Completed",
	Footer_TrainingProgram: "Training Programs",
	Breadcrumb_TrainingProgram: "Training Programs",
	TrainingProgram_Title_Table: "Title",
	TrainingProgram_TrainingProgram: "Training Programs",
	TrainingProgram_TargetGroup: "Target Group",
	TrainingProgram_MaxLength: "Max. Length",
	TrainingProgram_Information: "Information",
	TrainingProgram_Detail: "Details",
	TrainingProgram_Modules: "Modules",
	TrainingProgram_Course: "Courses",
	TrainingProgram_PartNumber: "Part Number",
	TrainingProgram_Max_Length: "Maximum Length",
	TrainingProgram_Duration: "Duration",
	TrainingProgram_Exam: "Examination",
	TrainingProgram_Assign_Popup: "Register for training program",
	TrainingProgram_Student: "Student",
	TrainingProgram_Assign: "Assign",
	TrainingProgram_Popup_Remove_Title: "Training cancellation?",
	TrainingProgram_Description: "Description",
	TrainingProgram_Days: "Days",
	TrainingProgram_Type: "Type",
	TrainingProgram_Action: "Action",
	TrainingProgram_Enrolled: "has already been enrolled to this training program",
	TrainingProgram_SelfAssign_Enrolled: "You are already assigned to this training program",
	TrainingProgram_Warning: "Please register another student or select a different training program.",
	TrainingProgram_Workshop: "Workshop",
	TrainingProgram_Durations: "years",
	TrainingProgram_Duration_DaysLabel: "days",
	TrainingProgram_HasExam_Yes: "Yes",
	TrainingProgram_HasExam_No: "No",
	AssignModal_SuccessModal_Header: "Congratulations!",
	AssignModal_SuccessModal_Message: "has been enrolled to this training program successful.",
	AssignModal_SelfAssign_SuccessModal_Message: "You have been succesfully assigned to the training program.",
	Dashboard_MyTraningProgram: "My Training Program",
	Dashboard_MyTraningProgram_Title: "Title",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "View",
	Dashboard_MyTraningProgram_Register: "Register",
	Dashboard_MyTraningProgram_Records: "Historical Records",
	Dashboard_MyTrainingProgram_In_Progress: "In progress",
	Dashboard_MyTrainingProgram_Accredited: "Accredited",
	Dashboard_Program_Modal_Withdraw: "Withdraw",
	Dashboard_Program_Modal_Grade: "Grade",
	Dashboard_MyTrainingProgram_Registered: "Registered",
	Dashboard_MyTrainingProgram_Completedd: "Completed",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Yes",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "No",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Please be aware that withdrawing means that you will withdraw from the complete training program, not just from an individual training course.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Do you still wish to withdraw from the training program?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Pending",
	WithdrawProgramModal_Withdraw_Success_Message: "You have successfully withdrawn from the training program!",
	Common_Warning_Title: "Warning!",
	Common_Error_Title: "Ups, something went wrong!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Add to Cart",
	AddEventFromCatalogToCartModal_HoursText: "hours",
	AddElearningToCartSuccessModal_Message: "has been added to your shopping cart!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "The selected participant has been registered to this Course, or the Course was already added to cart.",
	EventDetailTab_SubjectForChange_Message: "Subject for change",
	Dashboard_MyTrainingProgram_Status_Failed: "Failed",
	Dashboard_MyTrainingProgram_Status_Passed: "Passed",
	Dashboard_MyTrainingProgram_Status_Merit: "Merit",
	Dashboard_MyTrainingProgram_Status_Distinction: "Distinction"
};