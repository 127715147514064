import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image} from 'semantic-ui-react';
import logo from '../../../assets/Bosch_DigitalLogo_BlackAnchor_144x96px.png';
import boschSupergraphic from '../../../assets/Bosch-Supergraphic_.png';
import style from "./MaintenancePage.module.css";
import { ReactComponent as MaintenanceImage }  from "../../../assets/svg/maintenance.svg";

// interface MaintenancePageProps { 
//   maintenanceOnlineDate: string;
//   maintenanceOnlineTime: string;
// }

const MaintenancePage: FC<any> = () => {
  const { t } = useTranslation();
  const WS_URL = process.env.WS_MAINTENANCE_URL || 'ws://127.0.0.1:8000';
  const [maintenanceOnlineDate, setMaintenanceOnlineDate] =  useState('22/03/2024');
  const [maintenanceOnlineTime, setMaintenanceOnlineTime] =  useState('08:00 AM CET');
  
  const socket = new WebSocket(WS_URL);

  useEffect(() => {
    socket.onopen = () => {
      // Query about maintenance info
      socket.send(JSON.stringify({
        type: 'maintenance-get'
      }));  
    };

    socket.onmessage = (e) => {
      let maintenanceInfo = JSON.parse(e.data);
      setMaintenanceOnlineDate(maintenanceInfo.data.maintenanceOnlineDate);
      setMaintenanceOnlineTime(maintenanceInfo.data.maintenanceOnlineTime);      
    };

    return () => {
      socket.close()
    }
  }, []);

  return (
    <Fragment>
      <div className="supergraphic">
        <Image src={boschSupergraphic} fluid></Image>
      </div>

      <div className="grid-responsive-width">
        <div className="header-large-box">
          <Image src={logo} as="a" href="/"></Image>
          <h5 className="landing-page-title">
              {t("LandingPage_Header_Title")}
          </h5>
        </div>

        <div className={`${style.maintenance_message}` }>
          <p className={`${style.info_message}` }>
            {t("MaintenancePage_Message_1")}
            <br></br>
            {t("MaintenancePage_Message_2").replace("[maintenance_online_date]", maintenanceOnlineDate || "").replace("[maintenance_online_time]", maintenanceOnlineTime || "")}
          </p>
          <div className={`${style.maintenance_image}` }>
            <MaintenanceImage/>
          </div>          
        </div>
      </div>  

    </Fragment>
  );
};

export default MaintenancePage;
