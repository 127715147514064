import { FC } from "react";
import { Breadcrumb, Icon } from "semantic-ui-react";
import { ReactComponent as WideAngledArrow } from "../../assets/svg/wide-angled-arrow.svg";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../redux-toolkit/authReducer";
import Styles from "./Breadcrumbs.module.css";
import { selectTrainingCourse } from "../../redux-toolkit/trainingCourseReducer";
export interface CrumbProps {
  name?: string;
  crumb: string;
  Component?: FC<RouteComponentProps>;
  path: string;
}
interface BreadcrumbsProps {
  crumbs?: CrumbProps[];
}
interface SectionsProps {
  content: string;
  href: string;
}
const Breadcrumbs: FC<BreadcrumbsProps> = ({ crumbs }) => {
  const { t } = useTranslation();
  let sections: SectionsProps[] = [];
  const selectedLanguage = useSelector(selectLanguage);
  const trainingCourseDetail = useSelector(selectTrainingCourse);

  const termAndConditionCode = () => {
    if (!!selectedLanguage && selectedLanguage.trim() !== "") {
      return selectedLanguage.toLowerCase().trim();
    }
    return "en";
  };

  if (!(crumbs?.length === 1 && crumbs?.[0].name === "Home")) {
    crumbs?.map((item) => {
      sections.push({
        content: t(item.crumb),
        href:
          item.path === "/term-condition/:lang"
            ? `${termAndConditionCode()}`
            : item.path,
      });
    });
  }

  if (
    [
      "/training-course-catalogue",
      "/training-course-catalogue/training-course-detail",
      "/event-calendar",
      "/training-program",
      "/training-program/training-program-detail",
      //"/:country?/course/:id?",
    ].includes(window.location.pathname)
  ) {
    sections.splice(0, 1, {
      content: t("MenuHeader_TrainingServices"),
      href: "/",
    });
  } else if (["/shopping-cart"].includes(window.location.pathname)) {
    sections.shift();
  }

  if (
    window.location.pathname ===
    "/administration/employee-administration/employee-creation"
  ) {
    sections = [
      {
        content: t("Breadcrumb_MyTraining"),
        href: "/dashboard",
      },
      {
        content: t("Breadcrumb_MyTraining_Team"),
        href: "/dashboard/my-employees",
      },
      {
        content: t("Breadcrumb_EmployeeCreation"),
        href: "/administration/employee-administration/employee-creation",
      },
    ];
  }

  if (window.location.pathname.includes("/course/")) {
    let courseID = trainingCourseDetail?.id;
    let courseCountry = trainingCourseDetail?.country;
    sections = [
      {
        content: t("MenuHeader_TrainingServices"),
        href: "/",
      },
      {
        content: t("Breadcrumb_TrainingCourseCatalogue"),
        href: "/training-course-catalogue",
      },
      {
        content: t("Breadcrumb_TrainingCourseDetails"),
        //href: "/" + courseCountry + "/course/" + courseID,
        href: "/course/" + courseCountry + "/" +courseID,
      },
    ];
  }

  if (window.location.pathname.includes("/course-detail")) {
    const urlParams = new URLSearchParams(window.location.search);
    const countryParam =
      window.location.search !== "" ? urlParams.get("COUNTRY_CODE") : "";
    const languageParam =
      window.location.search !== "" ? urlParams.get("LANG") : "";
    const courseIDParam =
      window.location.search !== "" ? urlParams.get("COURSE_ID") : "";

    sections = [
      {
        content: t("MenuHeader_TrainingServices"),
        href: "/",
      },
      {
        content: t("Breadcrumb_TrainingCourseCatalogue"),
        href: "/training-course-catalogue",
      },
      {
        content: t("Breadcrumb_TrainingCourseDetails"),
        href:
          "/course-detail?COUNTRY_CODE=" +
          countryParam +
          "&LANG=" +
          languageParam +
          "&COURSE_ID=" +
          courseIDParam,
      },
    ];
  }

  if (["/download-files"].includes(window.location.pathname)) {
    sections.splice(0, 1, {
      content: t("MenuHeader_Support"),
      href: "/",
    });
  }

  return (
    <Breadcrumb
      className={Styles.breadcumbs}
      icon={
        <Icon>
          <WideAngledArrow fill="#000" style={{ height: "10px" }} />
        </Icon>
      }
      sections={sections}
    />
  );
};

export default Breadcrumbs;
