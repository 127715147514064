import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAccount } from "../redux-toolkit/accountReducer";
import { Constants } from "../helpers/Constants";

export const useUserRole = () => {
  const account = useSelector(selectAccount);
  const [isManager, setIsManager] = useState(false);
  const [isWholesaler, setIsWholesaler] = useState(false);

  useEffect(() => {
    setIsManager(account?.userRole?.id === Constants.USER_ROLE_MANAGER_ID);
    setIsWholesaler(
      account?.userRole?.id === Constants.USER_ROLE_WHOLESALER_ID
    );
  }, [account]);

  return { isManager, isWholesaler };
};
