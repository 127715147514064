import { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import style from "./LandingPageBanner.module.css";
import { ReactComponent as GroupCoaching } from "../../../assets/svg/group-coaching-turquoise.svg";
import { ReactComponent as RBPortrait } from "../../../assets/svg/robert-bosch-portrait-brille.svg";

const LandingPageBanner = () => {
    return (
        <Fragment>
            <Grid className={`${style.landing_banner} computer tablet only` }>
                <Grid.Column className={`${style.group_coaching}` } >
                    <GroupCoaching/>
                </Grid.Column>
                <Grid.Column className={`${style.portrait}` }>
                    <RBPortrait/>
                </Grid.Column> 
            </Grid>
        </Fragment>
    );
}

export default LandingPageBanner;