import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Boschicon from "../BoschIcon/Boschicon";
import { Modal, Grid, Dropdown } from "semantic-ui-react";
import { TrainingProgram } from "../../models/TrainingEvent";
import { AppDispatch } from "../../redux-toolkit/store";
import { useAppDispatch } from "../../redux/hooks";
import { getContactPersonsAsync } from "../../redux-toolkit/contactReducer";
import { ContactPersonModel } from "../../models/ContactPerson.Model";
import { useSelector } from "react-redux";
import {
  getAccountExtendedAsync,
  selectAccount,
  selectCiamEmail,
  selectCiamId,
  selectCustomerId,
  selectEmployeeId,
} from "../../redux-toolkit/accountReducer";
import {
  selectLanguage,
  selectCountryCode,
  selectTimeZoneId,
} from "../../redux-toolkit/authReducer";
import {
  RequestAddToTrainingPrograms,
  RequestWithdrawStudentFromProgram,
} from "../../models/trainingProgram/request.getTrainingPrograms.model";
import {
  addToTrainingProgramAsync,
  withdrawStudentFromProgramAsync,
} from "../../redux-toolkit/trainingProgramReducer";
import { Constants } from "../../helpers/Constants";
import "./AssignModal.css";
import { ContactCompany } from "../../models/Account";
import RDButton from "../RDComponents/RDButton";
import RDCustomAlert from "../RDComponents/RDCustomAlert";

interface AssignModalProps {
  item: TrainingProgram | undefined;
  showAssignDialogBox: boolean;
  closePopup: any;
}

const AssignModal: FC<AssignModalProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [students, setStudents] = useState<any>([]);
  const [selectedStudent, setSelectedStudent] =
    useState<ContactPersonModel | null>(null);
  const [isAssigningProgram, setIsAssigningProgram] = useState<boolean>(false);

  const [workshops, setWorkshops] = useState<any>([]);
  const [selectedWorkshop, setSelectedWorkshop] =
    useState<ContactCompany | null>(null);
  const [isStudent, setIsStudent] = useState<boolean>();

  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const customerId = useSelector(selectCustomerId);
  const userCountry = useSelector(selectCountryCode);
  const userLanguage = useSelector(selectLanguage);
  const ciamEmail = useSelector(selectCiamEmail);
  const timezoneId = useSelector(selectTimeZoneId);
  const account = useSelector(selectAccount);
  const employeeId = useSelector(selectEmployeeId);

  useEffect(() => {
    setOpen(props.showAssignDialogBox);
  }, [props]);

  useEffect(() => {
    if (open) {
      initVariablesByRole();
    }
  }, [open]);

  const initVariablesByRole = () => {
    switch (account?.userRole?.role) {
      case Constants.USER_ROLE_WHOLESALER:
        setSelectedWorkshop(null);
        setSelectedStudent(null);
        populateWorkshopList();
        break;

      case Constants.USER_ROLE_MANAGER:
        const workshop = {
          id: customerId || 0,
        } as ContactCompany;
        setSelectedWorkshop(workshop);
        setSelectedStudent(null);
        populateStudentList(workshop);
        break;

      default:
        // const workshopStudent = {
        //   id: customerId || 0
        // } as ContactCompany;
        // setSelectedWorkshop(workshopStudent)
        setSelectedStudent({
          ciamId: ciamId || "",
          customerId: customerId || 0,
          id: account?.employeeId || 0,
          firstName: account?.employee.firstName,
          lastName: account?.employee.lastName,
        } as ContactPersonModel);
        // populateStudentList(workshopStudent)
        setIsStudent(true);
        break;
    }
  };

  const populateWorkshopList = () => {
    if (ciamId) {
      let userProfileRequest = dispatchReduxToolkit(
        getAccountExtendedAsync(ciamId)
      ).unwrap();
      userProfileRequest.then((res) => {
        let workshopRes = res?.dataObject
          ?.associatedCompanies as ContactCompany[];
        let workshopList: any = [];
        if (workshopRes) {
          workshopList = workshopRes.map((x) => {
            return {
              key: x.id,
              text: `${x.name}`,
              value: x,
            };
          });
        }

        setWorkshops(workshopList);
      });
    } else {
      setWorkshops([]);
    }
  };

  const populateStudentList = (workshop: ContactCompany) => {
    if (workshop) {
      let userProfileRequest = dispatchReduxToolkit(
        getContactPersonsAsync({
          ciamId: ciamId || "",
          customerId: workshop.id || 0,
          language: userLanguage,
          country: userCountry,
        })
      ).unwrap();
      userProfileRequest.then((res) => {
        let participantsRes =
          res?.dataObject as unknown as ContactPersonModel[];
        let students: any = [];
        if (participantsRes) {
          if (account?.userRole?.role === Constants.USER_ROLE_INDIVIDUAL) {
            students = participantsRes
              .filter((x) => x.id === account.employeeId)
              .map((x) => {
                return {
                  key: x.id,
                  text: `${x.firstName} ${x.lastName}`,
                  value: x,
                };
              });
          } else {
            students = participantsRes.map((x) => {
              return {
                key: x.id,
                text: `${x.firstName} ${x.lastName}`,
                value: x,
              };
            });
          }
        }
        setStudents(students);
      });
    } else {
      setStudents([]);
    }
  };

  const onWorkshopSelected = (event: any, data: any) => {
    setSelectedWorkshop(data.value);
    populateStudentList(data.value);
  };

  const closeAssignModal = () => {
    setOpen(false);
    setSelectedStudent(null);
    if (props.closePopup) props.closePopup(false);
  };

  const closeSuccessModal = () => {
    setIsShowSuccessModal(false);
    setSelectedStudent(null);
    if (props.closePopup) props.closePopup(employeeId === selectedStudent?.id);
  };

  const addToProgram = () => {
    const addToProgramModel: RequestAddToTrainingPrograms = {
      ciamId: ciamId || "",
      customerId: !!selectedWorkshop ? selectedWorkshop.id : customerId || 0,
      ciamEmail: ciamEmail || "",
      country: userCountry,
      salesRegions: "",
      language: userLanguage,
      timezoneId: timezoneId,
      membership: {
        trainingProgramId: props?.item?.id,
        studentId: selectedStudent?.id,
        requester: ciamId || "",
        trainingProgram: props.item,
      },
    };
    let dispatchResult = dispatchReduxToolkit(
      addToTrainingProgramAsync(addToProgramModel)
    ).unwrap();
    dispatchResult
      .then((resp) => {
        if (resp?.success) {
          setIsShowSuccessModal(true);
        } else {
          setIsShowError(true);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsAssigningProgram(false);
      });
  };

  const submitForm = () => {
    if (selectedStudent) {
      setIsAssigningProgram(true);
      addToProgram();
    }
  };

  const removeConfirmed = () => {
    const withdrawStudentFromProgram: RequestWithdrawStudentFromProgram = {
      ciamId: ciamId || "",
      ciamEmail: ciamEmail || "",
      customerId: customerId || 0,
      country: userCountry,
      language: userLanguage,
      timezoneId: timezoneId,
      salesRegions: "",
      membership: {
        trainingProgramId: props.item?.id,
        studentId: selectedStudent?.id,
        requester: "",
        trainingProgram: props.item,
      },
    };
    let dispatchResult = dispatchReduxToolkit(
      withdrawStudentFromProgramAsync(withdrawStudentFromProgram)
    ).unwrap();
    dispatchResult
      .then((resp: any) => {})
      .catch(() => {})
      .finally(() => {});
  };

  const closeErrorModal = () => {
    setIsShowError(false);
  };

  return (
    <>
      <Modal
        onClose={closeAssignModal}
        // onOpen={populateStudentList}
        open={open}
        dimmer={{ className: "test-dimmer training-program-assign-modal" }}
        size={"tiny"}
        className="assign-modal assign-modal-mini"
      >
        <Modal.Header>
          <Grid className="assign-modal-header">
            <Grid.Column
              computer={2}
              tablet={3}
              mobile={3}
              className="assign-modal-add-user-icon"
            >
              <Boschicon name="bosch-ic-user-add" onClick={closeAssignModal} />
            </Grid.Column>
            <Grid.Column
              computer={14}
              tablet={13}
              mobile={13}
              className="assign-modal-title"
            >
              <h5 className="course-name">
                {t("Training_program_assign_training_program")}
              </h5>
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Grid style={{ margin: 0 }}>
            {account?.userRole?.role === Constants.USER_ROLE_WHOLESALER && (
              <Grid.Row>
                <Grid.Column className="dropdown-row">
                  <label>{t("ContactUs_Email_InputCompany")}</label>
                  <Dropdown
                    className="traning-program-student-choice"
                    selection
                    fluid
                    search
                    options={workshops}
                    icon={<Boschicon name="bosch-ic-down" />}
                    onChange={onWorkshopSelected}
                  ></Dropdown>
                </Grid.Column>
              </Grid.Row>
            )}

            {isStudent ? (
              <Grid.Row>
                <Grid.Column>
                  <p>
                    {t("Assign_modal_student_confirmation_text1").replace(
                      "[Training Program Name]",
                      props?.item?.title || ""
                    )}
                  </p>
                  <p>{t("Assign_modal_student_confirmation_text2")}</p>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Column>
                  <label>{t("Training_program_team_member")}</label>
                  <Dropdown
                    className="traning-program-student-choice"
                    selection
                    fluid
                    search
                    options={students}
                    icon={<Boschicon name="bosch-ic-down" />}
                    onChange={(event, data: any) =>
                      setSelectedStudent(data.value)
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <Grid style={{ margin: 0 }}>
            <Grid.Row className="button-area">
              <Grid.Column>
                <RDButton
                  style={{ minWidth: "7.5rem" }}
                  primary
                  disabled={!selectedStudent || isAssigningProgram}
                  onClick={submitForm}
                  loading={isAssigningProgram}
                >
                  {t("WaitlistPopup_SelectButtonText")}
                </RDButton>
                <RDButton
                  style={{ minWidth: "7.5rem" }}
                  secondary
                  onClick={closeAssignModal}
                  disabled={isAssigningProgram}
                >
                  {t("CancelButton")}
                </RDButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>

      {isShowError && (
        // <InformationModal
        //   type="error"
        //   informationMessage={`${t("TrainingProgram_Enrolled")}`}
        //   userName={`${selectedStudent?.firstName} ${selectedStudent?.lastName}`}
        //   additionMessage={t("TrainingProgram_Warning")}
        //   popupTitle={t("Common_Error_Title")}
        //   isDisplayModal={isShowError}
        //   setCloseModalTrigger={() => setIsShowError(false)}
        // />

        <RDCustomAlert
          type="error"
          isOpen={isShowError}
          title={t("Common_Error_Title")}
          AcceptButton={
            <RDButton primary onClick={() => closeErrorModal()}>
              {t("CommonButton_Close")}
            </RDButton>
          }
        >
          <Grid>
            <Grid.Row>
              <p>
                {" "}
                <strong>{`${selectedStudent?.firstName} ${selectedStudent?.lastName}`}</strong>{" "}
                {t("TrainingProgram_Enrolled")}
              </p>
              <p>{t("TrainingProgram_Warning")}</p>
            </Grid.Row>
          </Grid>
        </RDCustomAlert>
      )}

      {isShowSuccessModal && (
        // <InformationModal
        //   type="success"
        //   informationMessage={`${t("AssignModal_SuccessModal_Message")}`}
        //   popupTitle={t("AssignModal_SuccessModal_Header")}
        //   userName={`${selectedStudent?.firstName} ${selectedStudent?.lastName}`}
        //   isDisplayModal={isShowSuccessModal}
        //   setCloseModalTrigger={closeSuccessModal}
        // />
        <RDCustomAlert
          type="success"
          isOpen={isShowSuccessModal}
          title={t("AssignModal_SuccessModal_Header")}
          AcceptButton={
            <RDButton primary onClick={() => closeSuccessModal()}>
              {t("CommonButton_Close")}
            </RDButton>
          }
        >
          <Grid>
            <Grid.Row>
              <p>
                {" "}
                <strong>{`${selectedStudent?.firstName} ${selectedStudent?.lastName}`}</strong>{" "}
                {t("AssignModal_SuccessModal_Message")}
              </p>
            </Grid.Row>
          </Grid>
        </RDCustomAlert>
      )}
    </>
  );
};

export default AssignModal;
