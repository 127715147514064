export const TRANSLATIONS_EN_CN = {
	Breadcrumb_MyTraining_Curricula: "N/A",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "N/A",
	Breadcrumb_MyTraining_Team: "N/A",
	Breadcrumb_MyTraining_Team_ViewEmployee: "N/A",
	MyTraining_Headerbar_CourseDetails: "N/A",
	MyTraining_Headerbar_TrainingProgramDetails: "N/A",
	TrainingProgramDetails_Label_Status: "N/A",
	TrainingProgramDetails_Label_OverallProgress: "N/A",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A"
};