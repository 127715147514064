export const TRANSLATIONS_JP = {
	LandingPage_Title: "ボッシュ オートモーティブ トレーニング ソリューションズへようこそ",
	LandingPage_SubTitle_ChooseCountry: "国/地域を選択してください",
	Header_ContactUs: "お問い合わせ",
	Header_Search: "検索",
	Header_Menu: "メニュー",
	Header_LogInOrCreateYourAccount: "ログインまたはアカウントの作成",
	Header_LogIn: "ログイン",
	Header_Cart: "カート",
	Header_ShoppingCart: "ショッピングカート",
	Header_LogOut: "ログアウト",
	Header_ChangeDetails: "詳細の変更",
	Footer_ExploreOurTraining: "トレーニングをご覧ください",
	Footer_TrainingAtYourFingertips: "トレーニング情報",
	Footer_DontMissAnyStory: "ストーリーをお見逃しなく",
	Footer_ExploreOurStories: "ストーリー",
	Footer_GetInTouchWithUs: "お問い合わせ",
	Footer_SendUsAMessage: "お問い合わせ",
	Footer_ToContactForm: "お問い合わせフォームへ",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "一般連絡先",
	Footer_ProductSecurity_Psirt: "製品セキュリティ（PSIRT）",
	Footer_InnovationsPatentsAndLicenses: "ライセンスおよび特許",
	Footer_PurchasingLogistics: "購買およびロジスティクス",
	Footer_CorporateInformation: "企業情報",
	Footer_LegalNotice: "法的通知",
	Footer_DataProtectionNotice: "個人情報保護方針",
	Footer_PrivacySettings: "プライバシー設定",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch, all rights reserved",
	Footer_StillLookingForSomething: "ほかに何かお探しですか",
	Footer_TrainingServices: "トレーニングサービス",
	Footer_Courses: "コース",
	Footer_Programs: "プログラム",
	Footer_EventsCalendar: "トレーニング コース日程",
	Footer_TrainingCenters: "トレーニングセンター",
	Footer_Dashboard: "ダッシュボード",
	Footer_MyDashboard: "マイダッシュボード",
	Footer_ShoppingCart: "ショッピングカート",
	Footer_YourShoppingCart: "ショッピングカート",
	Footer_Administration: "管理",
	Footer_Employees: "従業員",
	Footer_Wholesalers: "代理店",
	Footer_Transactions: "取引",
	Footer_Workshops: "ワークショップ",
	Footer_WorkLikeABoschApplyNow: "Work  — 今すぐ申し込む",
	Footer_BoschGlobalCareers: "ボッシュグローバルキャリア",
	Footer_PurchasingAndLogistics: "購買およびロジスティクス",
	Footer_BecomeABusinessPartner: "ビジネスパートナーになる。",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "私たちのトレーニングはお客様のビジネスに貢献するため、進化を続けます。",
	Footer_Language: "国選択",
	MenuPopup_MyCart: "マイカート",
	Stories_OurTrainingStories: "トレーニングストーリー",
	Stories_ConnectingExperts: "専門家と愛好家、夢想家と実行者、イノベーターとインフルエンサーをつなぐ-探求と経験",
	Stories_TopStories: "トップストーリー",
	Stories_AtHome: "住宅",
	Stories_Careers: "キャリア",
	Stories_History: "歴史",
	Stories_IndustryAndTrades: "産業および販売",
	Stories_Mobility: "モビリティ",
	Stories_Research: "研究",
	Stories_Sustainability: "サステナビリティ",
	Stories_AllStories: "すべてのストーリー",
	Stories_BlogATrainersDay: "ブログ | トレーナー日常",
	Stories_ADayinLifeOfATrainer: "トレーナーの一日",
	Stories_BoschServiceTraining: "ボッシュサービストレーニング",
	Stories_StoryWhatDrivesYouDrivesUs: "ストーリー | What drives you drives us",
	Stories_StoryHowCanWeBenefitYourBusiness: "ストーリー | どうすればお客様のビジネスに利益をもたらすことができるか",
	Stories_HowOurTrainingEvolves: "トレーニングがどのように進化するか",
	Stories_AModernTrainingApproach: "オンライントレーニングの世界",
	Stories_StoryAVirtualOnlineTrainingWorld: "ストーリー | オンライントレーニングの機会",
	Stories_SubscribeToRSSFeed: "RSSフィードを購読する",
	Title_Main: "ボッシュ オートモーティブ トレーニング ソリューションズ",
	Homepage_AboutUs: "私たちについて",
	Homepage_AutomotiveAftermarket: "オートモーティブアフターマーケット",
	Homepage_GetInTouch: "お問い合わせ",
	Homepage_LatestNews: "私たちが知識への渇望に駆り立てられている理由をご覧ください",
	Homepage_MainTitle: "ボッシュ オートモーティブ トレーニング ソリューションズ",
	Homepage_OurOpenPositions: "採用情報",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "What drives you drives us",
	BoschServiceTraining_BoschServiceTraining: "ボッシュサービストレーニング",
	BoschServiceTraining_ShareThisOn: "シェア",
	BoschServiceTraining_TheChallenge: "チャレンジ：新しいテクノロジーを習得する",
	BoschServiceTraining_WorldwidePresence: "世界的な存在感",
	BoschServiceTraining_TechnicalTraining: "ボッシュの技術トレーニングコース",
	BoschServiceTraining_TheOffersAndOpportunities: "ボッシュが提供する機会",
	BoschServiceTraining_TechnicalCoursesDesc: "初心者からプロフェッショナルまで、あらゆるターゲット層に適したトレーニングコースを用意しています。トレーニングコースは、1日コースと数日間にわたるコースがあります。トレーニングコースは、さまざまな技術分野とトレーニングシリーズ（ガソリン噴射、ディーゼル噴射、電気系統など）で提供されています。技術トレーニングコースでは、ボッシュが提供するすべての標準的な車両システムに関する幅広いノウハウを提供します。内容は、ワークショップの従業員の要求に特別に対応するものです。",
	BoschServiceTraining_TrainedEmployeesDesc: "自動車のワークショップにとって、訓練された従業員は明らかに有益な存在です。なぜなら、今日の自動車はますます複雑化し、新しい技術が常に新しい課題を突きつけてくるからです。必要な専門知識があればこそ、ワークショップの従業員は、効率的かつ費用対効果の高い方法で、現行車種の診断・保守・修理作業を行うことができます。",
	BoschServiceTraining_RegardlessOfHow: "どんなに優秀で経験豊富な自動車専門家であっても、新しい自動車技術システムや未知の故障に遭遇することは常にあるものです。このような場合、トラブルシューティングや問題解決に特に時間がかかったり、うまくいかないことがあります。最悪の場合、お客様は予想以上に長い間、車なしで過ごさなければならなくなります。",
	BoschServiceTraining_ItIsPrecisely: "ボッシュのサービス・トレーニングは、まさにこのような場面で、サービスの質を向上させ、プロセスをスピードアップさせます。ボッシュの現在のトレーニングは、問題をより迅速に特定し、より多くの車両を同時に修理し、お客様の待ち時間を短縮するためのサポートを提供します。このように、ボッシュで学ぶ体系的な手順は、より速く、より生産的な仕事を保証します。",
	BoschServiceTraining_WorldwidePresenceDesc1: "ボッシュは125年以上にわたり、世界の主要自動車メーカーに純正部品とシステムを供給してきました。この経験には、ボッシュのサポートの基礎となる最新技術に関する独自の知識が含まれています。",
	BoschServiceTraining_WorldwidePresenceDesc2: "ボッシュのサービストレーニングセンターは、スペイン、トルコ、南アフリカ、オーストラリア、ブラジルなど、世界各地にあります。ボッシュは、常に最先端の技術に即した実践的なトレーニングを従業員に提供しています。",
	BoschServiceTraining_AgileCompany: "アジャイルな企業",
	BoschServiceTraining_Collaboration: "コラボレーション",
	BoschServiceTraining_ConnectedMobility: "コネクテッドモビリティ",
	BoschServiceTraining_ArtificialIntelligence: "人工知能",
	BoschServiceTraining_ThoughtLeadersInDialogue: "対話における思想的指導者",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "診断およびテスト機器",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "自動車技術の基礎",
	BoschServiceTraining_Icon_Gasoline: "ガソリン",
	BoschServiceTraining_Icon_Diesel: "ディーゼル",
	BoschServiceTraining_Icon_AlternativeDrives: "代替駆動システム",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "電気、電子システム",
	BoschServiceTraining_Icon_BodyAndComfort: "ボディ、コンフォート",
	BoschServiceTraining_Icon_ChassisAndBraking: "シャーシ、ブレーキ",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "トランスミッション、ドライブトレイン",
	BoschServiceTraining_Icon_CommercialVehicles: "商用車",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "オンライントレーニングコース",
	BoschServiceTraining_Icon_BusinessSkills: "ビジネススキル",
	BoschServiceTraining_Icon_Accreditations: "認定",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "トレーニングコースプログラム",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "トレーナー　氏名",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "トレーナーの一日",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "毎日が冒険",
	ADayInTheLifeOfATrainer_Summary: "サマリー",
	ADayInTheLifeOfATrainer_TheDayJourney: "一日の流れ",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "忙しい日々が続きますが、いつもやりがいを感じています。受講者の方々が私たちの未来への鍵を握っていることを知りながらトレーニングを行う喜びは、責任重大であると同時に、とてもエキサイティングなものです。毎日、毎週、レッスンを受けるたびに成長していく受講者の方々を見ると、未来を動かす最新技術を取り入れながら、知識を向上させるという同じ目標に向かって働いているのだということがわかります。",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "一日の終わりには、その日の仕事を振り返ります。一日があっという間に過ぎていきますが、これは私たちが達成しようとしていることが、受講者にとってだけでなく、トレーナーである私たちにとってもやりがいのあることだという証拠でもあるのです。",
	ADayInTheLifeOfATrainer_WeCanGoHome: "私たちは素晴らしい仕事をしたことに満足して帰路につくことができます。",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "開始",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "トレーニング資料の準備と最終チェック。 重要なメールを確認する",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "トレーニング開始",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "参加者の歓迎。 1日のスケジュール発表。一人ひとりの特性を理解し、グループを知る。",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "座学",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "トレーニングをできるだけ興味深いものにするためには、グループとの一定の相互作用が必要です。理論に絡めたタスクを用意し、グループの関心をさらに高めるようにします。",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "昼休み",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "実習",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "トレーニングはワークショップ形式で、いくつかの実践的なタスクで継続されます。トレーナーとして、万が一の事態や指導に常に対応できることが求められます。",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "総括",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "教室に戻り、その日のトレーニングについて簡単に振り返ります。質問があれば回答します。次のトレーニングの準備を始めます。",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "最終日",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "修了証が参加者に授与されます。",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "ホーム",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "どうすればお客様のビジネスに利益をもたらすことができるか",
	BoschTrainingBenefits_HowOurTrainingEvolves: "トレーニングがどのように進化するか",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "私たちは常に進化し続け、質の高い最新のトレーニングを提供できるようにしています。",
	BoschTrainingBenefits_TheBoschServiceTraining: "ボッシュサービストレーニングセンターは、技術/ビジネストレーニングの広範で関連性の高いポートフォリオと、認定資格を備えたさらなる教育コースを通じて、ビジネスの発展を支援したいと考えています。ここでは、トレーニングプログラムの概要と、現在のさらなる教育の機会について説明します。トレーニングコースやセミナーでお会いできるのを楽しみにしています。",
	BoschTrainingBenefits_LongStandingExperience: "すべてのシステムおよび自動車技術の分野での長年の経験と豊富なノウハウ。",
	BoschTrainingBenefits_QuickAndEfficient: "多くの問題に対する迅速で効率的な解決策。",
	BoschTrainingBenefits_TheAdvantagesandBenefits: "利点と効果",
	BoschTrainingBenefits_LMS: "学習管理システム―ボッシュトレーニングソリューション",
	BoschTrainingBenefits_BTSCoversTheFullRange: "ボッシュトレーニングソリューションは、自動車分野の全範囲をカバーしています。 特定の分野に特化するか、ボッシュのトレーニングオファーで能力を広げてください。",
	BoschTrainingBenefits_AcquiredKnowledge: "獲得した知識によるスムーズなリペア、メンテナス、サービス作業",
	BoschTrainingBenefits_TheHighDegree: "高度に実践的な内容と少人数制により、目標に沿った学習を保証します。",
	BoschTrainingBenefits_ParticipationInTraining: "トレーニングへの参加は、従業員のモチベーションと満足度を高め、補足的な資格は顧客を納得させます",
	BoschTrainingBenefits_IncreasedSales: "多種多様な知識による売上の増加",
	BoschTrainingBenefits_MethodicalProcedures: "整然とした手順により、顧客の待ち時間が短縮されます",
	BoschTrainingBenefits_HighlyQualified: "高度な資格を持つインストラクターが体系的な手順を伝えます",
	BoschTrainingBenefits_IdentificationOftheTraining: "お客様またはお客様のビジネスに適したトレーニングソリューションの特定",
	BoschTrainingBenefits_DirectOnlineBooking: "お客様ご自身や従業員のために、オンラインで直接コースを予約・管理することができます。",
	BoschTrainingBenefits_OnlineAccess: "学習ドキュメントへのオンラインアクセス",
	BoschTrainingBenefits_AccessToYourOwn: "個人のトレーニング履歴と終了証へのアクセス",
	BoschTrainingBenefits_ToolsForManagement: "お客様ご自身のキャリア開発を管理するためのツール",
	BoschTrainingBenefits_ManagerHasFull: "管理者は、システム内の従業員のコースを完全に把握することができます。",
	BoschTrainingBenefits_Access24hours: "24時間年中無休でアクセス",
	ModernTrainingApproach_KnowledgeIsKey: "知識は、どんなワークショップでも重要な成功要因です。そのため、ボッシュは包括的なプロフェッショナルトレーニングの開発プログラムを提供しています。これにより、受講者は適切なタイミングで適切な知識、つまりお客様の車両を専門的に保守・修理できるようになるために必要な知識を正確に習得することができます。ボッシュは、すでに提供しているeラーニング・パッケージの新しいモジュールとして、ウェブキャスティングを追加しました。これは、ストリーミング用に特別にデザインされたサービス・トレーニング・セッションで構成されています。",
	ModernTrainingApproach_ANewLearning: "新しい学習の機会",
	ModernTrainingApproach_YourOnlineTraining: "オンライントレーニングの機会",
	ModernTrainingApproach_RecentlyTheWorld: "近年、世界が変化している中で、私たちが行っていることをご覧ください。",
	ModernTrainingApproach_TheOnlineTrainingWorld: "オンライントレーニングの世界",
	ModernTrainingApproach_NewTeachingMethod: "新しいトレーニング手法",
	ModernTrainingApproach_InAdditionTo: "従来の対面式トレーニングに加え、ライブ・ウェブキャスト・トレーニングをトレーニングプログラムに取り入れました。ボッシュのトレーナーが、バーチャルクラスルームや実践的な事例を交えて、幅広い理論的知識を提供します。通常通り、車両システムおよび対応するボッシュ製品に関する多くの情報を得ることができます。もちろん、質問をする機会もあります。質問はライブ中継の中で直接お答えします。ライブ中継は60分～120分です。安定したインターネット接続が必要です。トレーニングカタログの検索フィールドに \"webcast \"と入力し、適切なウェブキャストを直接検索してください。",
	ModernTrainingApproach_BoschWebcastingHeader: "ボッシュのウェブキャスティング：革新的で実用的",
	ModernTrainingApproach_BoschWebcastingText: "ボッシュのウェブキャストに参加するには、まず参加者がダイヤルインする必要があります。ログインするためのアクセスコードがセッション前に送信されます。最大200名の参加者が同時に、さまざまな場所から参加することができます。トレーニングはトレーナーが担当し、質問はいつでもライブチャット機能で受け付けています。",
	ModernTrainingApproach_TheDigitalSupplementHeading: "教室でのトレーニングのデジタルサプリメント",
	ModernTrainingApproach_TheDigitalSupplementText: "ウェブキャスティングとクラスルームトレーニングは、互いに完全に補完し合うものです。どちらの場合も、資格を持ったトレーナーがトレーニングを指導します。ウェブキャストは、車両に手を加えることなくデジタルで伝えることができるトピックに適しています。たとえば、新しい運転支援システムのESI[tronic]機能を理解することができます。ワークショップでの効率的なチームワークや車両技術に関する実践的な作業に関しては、対面式のトレーニングが第一の選択肢となることに変わりはありません。",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "ストリーミングによるコスト削減の可能性",
	ModernTrainingApproach_StreamingCanBeCheaperText: "参加者一人当たりのコストは、通常、同等の教室でのコースよりもウェブキャスティングの方が低くなります。その理由は、より多くの参加者が恩恵を受けることができ、トレーニングに関連する労力が大幅に削減されるからです（場所への移動がなく、時間も少なくてすみます）。",
	ModernTrainingApproach_BookAndProfitHeading: "予約と利益",
	ModernTrainingApproach_BookAndProfitText: "ウェブキャストは、他のトレーニングコースやラーニングオファーと同様に、弊社のウェブサイトで予約することができます。各ウェブキャストの料金を個別に支払うか、一定のウェブキャスト数を年間契約するか、どちらかを選択できます。",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "ボッシュのウェブキャスティングとクラスルームトレーニングの比較",
	ModernTrainingApproach_Webcasting: "Webキャスト",
	ModernTrainingApproach_Classroom: "クラスルーム",
	ModernTrainingApproach_WebcastingP1: "世界中のどこからでも最大200人の参加者が同時に参加できます",
	ModernTrainingApproach_WebcastingP2: "複雑さが少なく、比較的低コスト 出張の必要なし",
	ModernTrainingApproach_WebcastingP3: "特定の簡潔な個々のトピックに最適",
	ModernTrainingApproach_WebcastingP4: "ワークショップでは、いつでもどこでも従業員向けのWebキャストを実行できます。",
	ModernTrainingApproach_WebcastingP5: "クラスルームトレーニングに比べ、所要時間が50～70％短縮されます。",
	ModernTrainingApproach_ClassroomTrainingP1: "最大16名の参加者が同時に参加可能",
	ModernTrainingApproach_ClassroomTrainingP2: "旅費がかかり、一般的に一人当たりのコストが高くなる",
	ModernTrainingApproach_ClassroomTrainingP3: "コラボレーションを最適化する場合、あるいは車両で直接学習する場合に最適",
	ModernTrainingApproach_ClassroomTrainingP4: "実践的な演習とトレーナーとの対話",
	ModernTrainingApproach_ClassroomTrainingP5: "トレーナーは、受講者の実際のニーズに合わせて、教えるべき知識をよりよく適応させることができます",
	ModernTrainingApproach_OnlineTrainingOfferings: "オンライントレーニングオファリング（WBT / VBS）",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "今日、コンピュータを使った自主学習は、知識伝達の不可欠な要素となっています。時間や場所を選ばない自由さ、再現性の高さ、個々の学習スピード、インタラクティブな学習コンテンツなど、この学習法はなくてはならないものとなっています。",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "ウェブベースドトレーニング（WBT）の各要素は、慎重に構成されています。これらのコースは、幅広い知識を提供するだけでなく、ボッシュのトレーニングセンターで行われるトレーニングプログラムの効果的な準備としても役立ちます。",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "バーチャル診断シミュレーションでは、ボッシュの診断機器を使用したトラブルシューティングのワークフローなど、日々のワークショップの状況を、バーチャルなワークショップの中でロールプレイすることが可能です。",
	ModernTrainingApproach_Advantages: "利点",
	ModernTrainingApproach_AdvantagesP1: "迅速かつ効率的に知識を提供する方法",
	ModernTrainingApproach_AdvantagesP2: "多彩な学習",
	ModernTrainingApproach_AdvantagesP3: "何度でも利用可能",
	ModernTrainingApproach_AdvantagesP4: "最小限の時間と費用",
	ModernTrainingApproach_AdvantagesP5: "個々の学習スピード",
	ModernTrainingApproach_AdvantagesP6: "柔軟な学習時間",
	ModernTrainingApproach_AdvantagesP7: "繰り返し学習が可能",
	ModernTrainingApproach_AdvantagesP8: "旅費がかからない",
	ModernTrainingApproach_AdvantagesP9: "定員や日程の制約がない",
	Dashboard_CoursesViewAndManageYourCourses: "コース | コースを表示および管理する",
	Dashboard_MyCourses: "マイコース",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "トレーニングプログラム | トレーニングプログラムを表示する",
	Dashboard_MyTrainingProgram: "マイトレーニングプログラム",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eラーニング| eラーニングポートフォリオを表示および管理する",
	Dashboard_MyELearning: "マイeラーニング",
	Dashboard_AccountManageYourAccount: "アカウント | アカウントを管理する",
	Dashboard_MyAccount: "マイアカウント",
	Dashboard_MyDashboard: "マイダッシュボード",
	Dashboard_ManageYourAccount: "アカウントを管理する",
	Dashboard_Workshops: "ワークショップ",
	Dashboard_AdministrationManageYourWorkshop: "管理 | ワークショップを管理する",
	Dashboard_AdministrationManageYourTeams: "管理 | チームを管理する",
	Dashboard_Administration: "管理",
	Dashboard_ViewYourCourses: "コースを表示する",
	Dashboard_ViewYourTrainingPrograms: "トレーニングプログラムを表示する",
	Dashboard_TrainingPrograms: "トレーニングプログラム",
	Dashboard_ViewAndEditYourDetails: "詳細を表示および編集する",
	Dashboard_ViewYourELearningCourses: "eラーニングコースを表示する",
	Dashboard_Pending: "保留中",
	Dashboard_RequiresAdministratorSignOff: "（管理者のサインオフが必要です）",
	Dashboard_HistoricalRecords: "履歴",
	Dashboard_Registered: "登録済み",
	Dashboard_WaitingList: "キャンセル待ちリスト",
	Dashboard_RecommendedForYou: "お勧め",
	Dashboard_AccountDetails: "アカウント情報",
	Dashboard_ChangeProfileImage: "プロフィール画像を変更する",
	Dashboard_Title: "役職",
	Dashboard_Role: "職務",
	Dashboard_FirstName: "名",
	Dashboard_LastName: "姓",
	Dashboard_Telephone: "電話",
	Dashboard_Mobile: "携帯電話",
	Dashboard_Email: "Eメール",
	Dashboard_Language: "言語",
	Dashboard_Save: "保存",
	Dashboard_Student: "受講者",
	Dashboard_WorkshopTrainingAdministrator: "ワークショップトレーニング管理者",
	Dashboard_WholesalerTrainingAdministrator: "代理店トレーニング管理者",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "会社の管理",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "ワークショップを管理する...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "従業員を管理する...",
	Dashboard_CompanyAdministration_Workshop: "ワークショップ",
	Dashboard_CompanyAdministration_Address: "住所",
	Dashboard_CompanyAdministration_Zip: "郵便番号",
	Dashboard_CompanyAdministration_City: "市",
	Dashboard_CompanyAdministration_Manage: "管理",
	Dashboard_AdministrationSection_AdministrationTitle: "管理",
	Dashboard_AdministrationSection_ManageTheTeam: "チームを管理する",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "管理 | 従業員を管理する",
	Dashboard_AdministrationSection_Employees: "従業員",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "管理 | 代理店を管理する",
	Dashboard_AdministrationSection_Wholesalers: "代理店",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "管理 | 取引を管理する",
	Dashboard_AdministrationSection_Transactions: "取引",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "従業員の管理",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "従業員を管理する...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "登録",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "記録",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "編集",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "従業員",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "追加",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "作成",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "登録済み",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "保留中",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "（管理者のサインオフが必要です）",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "履歴",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "キャンセル待ちリスト",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "現在の従業員リスト",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "従業員の管理",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "氏名",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "従業員のアカウント",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "従業員の詳細を表示および編集...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "設定",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "ボッシュ オートモーティブ トレーニング ソリューションズの個人アカウントを許可する",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "従業員を会社管理者に昇格させる",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "コース登録の許可",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "招待メール送信",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "アカウントの無効化",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "保存",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "送信",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "編集",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "代理店の管理",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "代理店の管理",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "承認済み代理店リスト",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "名称",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "消去",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "作成",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "追加",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "新入社員のアカウント作成",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "新入社員の詳細を作成...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "新しく承認された代理店管理者を追加します",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "新しい代理店の詳細を作成します...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "ドロップダウンメニューから承認済みの代理店を選択してください...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "取引",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "取引を表示および管理します...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "戻る",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "請求書番号",
	Dashboard_AdministrationSection_TransactionsSection_Description: "概要",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "支払方法",
	Dashboard_AdministrationSection_TransactionsSection_Date: "日付",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "金額",
	Dashboard_AdministrationSection_TransactionsSection_Download: "ダウンロード",
	TrainingCentres_TrainingCentres: "トレーニングセンター",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "私たちのトレーニングセンターは世界中にあります",
	TrainingCentres_CountryCategory: "国カテゴリ",
	TrainingCentres_CityCategory: "都市カテゴリ",
	TrainingCentres_Country: "国",
	TrainingCentres_City: "市",
	TrainingCatalogue_TrainingCatalogue: "トレーニングコースカタログ",
	TrainingCatalogue_LearningAtTheHighestLevel: "最高レベルでの学習",
	TrainingCatalogue_StartDate: "開始日",
	TrainingCatalogue_EndDate: "終了日",
	TrainingCatalogue_TrainingName: "トレーニング名",
	TrainingCatalogue_Language: "言語",
	TrainingCatalogue_Days: "日",
	TrainingCatalogue_Seats: "空席",
	TrainingCatalogue_Price: "価格",
	TrainingCatalogue_Location: "開催地",
	TrainingCatalogue_PartNumber: "品番",
	TrainingCatalogue_TrainingCourse: "トレーニングコース",
	TrainingCatalogue_Category: "カテゴリー",
	TrainingCatalogue_TrainingCategory: "トレーニングカテゴリ",
	TrainingCatalogue_CourseName: "コース名",
	TrainingCatalogue_TotalDays: "合計日数",
	TrainingCatalogue_Venue: "会場",
	TrainingCatalogue_Details: "詳細",
	TrainingCatalogue_Prerequisites: "受講条件",
	TrainingCatalogue_CourseType: "コースタイプ",
	TrainingCatalogue_LearningMethod: "学習方法",
	TrainingCatalogue_AddToWaitingList: "キャンセル待ちリストに追加",
	ShoppingCart_ShoppingCart: "ショッピングカート",
	ShoppingCart_ReviewAndManage: "サブスクリプションを確認および管理する",
	ShoppingCart_YouHaveAddedItem: "カートに次のアイテムを追加しました",
	ShoppingCart_Item: "アイテム",
	ShoppingCart_Quantity: "数量",
	ShoppingCart_PricePerItem: "アイテムあたりの価格",
	ShoppingCart_TotalPrice: "合計金額",
	ShoppingCart_VatIsIncluded: "税別",
	ShoppingCart_Redeem: "クレジットがない場合は、ここをクリックして利用してください",
	ShoppingCart_Purchase: "クレジットがない場合は、ここをクリックして購入してください",
	ShoppingCart_Checkout: "チェックアウト",
	ShoppingCart_ContinueShopping: "ショッピングを続ける",
	ShoppingCart_TotalPriceInclVat: "合計金額",
	ShoppingCart_Apply: "適用",
	ShoppingCart_ShoppingCartEmpty: "ショッピングカートは空です",
	ShoppingCart_VatIncluded: "消費税（10%）",
	ShoppingCart_ManageYourShopping: "ショッピングを管理する",
	ShoppingCart_Participant: "参加者",
	ShoppingCart_Complete: "完了",
	ShoppingCart_NetPrice: "小計",
	ShoppingCart_Workshop: "ワークショップ",
	CheckOut_PreviewYourOrder: "お申込み内容のプレビュー",
	CheckOut_YourShoppingCart: "カート",
	CheckOut_Preview: "プレビュー",
	CheckOut_Payment: "支払い",
	CheckOut_Summary: "概要",
	CheckOut_Back: "戻る",
	CheckOut_PleaseReadAndAgreeOurTerms: "利用規約を読み、同意してください",
	CheckOut_IHaveReadTheTermsAndCondition: "[利用規約]を読み、理解して同意します",
	CheckOut_YesIAgree: "はい、利用規約に同意します",
	CheckOut_Continue: "続行",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "ありがとうございます。お申込みを承りました",
	CheckOut_OrderConfirmation: "リクエストが正常に処理されると、最終的なごお申込み確認メールが送信されます",
	CheckOut_WeWillContactYouDirectly: "リクエスト内容に不明な点がある場合は、弊社より直接ご連絡させていただきます",
	CheckOut_AddtoCart: "カートに追加",
	CheckOut_CreditCart: "クレジットカート",
	CheckOut_DirectDebit: "自動引き落とし",
	CheckOut_TrainingCredits: "トレーニングクレジット",
	CheckOut_PreferredWholesaler: "優先代理店",
	EventCalendar_EventCalendar: "トレーニング コース日程",
	EventCalendar_TrainingEventsForAllYourNeeds: "すべてのニーズに対応するトレーニングイベント",
	TrainingCourse_TrainingCourse: "トレーニングコースカタログ",
	TrainingCourse_LearningAtTheHighestLevel: "最高レベルでの学習",
	TrainingProgram_Title: "トレーニングプログラムカタログ",
	TrainingProgram_SubTitle: "最高レベルでの学習",
	AccountDeactivationModal_Title: "アカウントを停止してもよろしいですか？",
	AccountDeactivationModal_Line1: "このアカウントを停止すると、サービスリクエストの取引や残高、チームメンバー、プロフィール設定など、すべてのコンテンツが削除されます。",
	AccountDeactivationModal_Line2Part1: "サブスクリプションの有効期間内の場合は、当社のキャンセルポリシーを参照してください。",
	AccountDeactivationModal_Line2Part2: "利用規約",
	AccountDeactivationModal_Line2Part3: "リクエストを送信する前に",
	AccountDeactivationModal_Line3: "このアカウントを停止するリクエストを送信してもよろしいですか？",
	AccountDeactivationModal_Cancel: "キャンセル",
	AccountDeactivationModal_Send: "送信",
	CurrentUserAccountDeactivationModal_Title: "アカウントを停止してもよろしいですか？",
	CurrentUserAccountDeactivationModal_Line1: "アカウントの非アクティブ化は永続的であり、サービスリクエストトランザクションを含むすべてのコンテンツを削除し、チームメンバーとプロファイル設定のバランスを取ります。",
	CurrentUserAccountDeactivationModal_Line2Part1: "有効なサブスクリプション期間内の場合は、当社のキャンセルポリシーを参照してください。",
	CurrentUserAccountDeactivationModal_Line3: "このアカウントを停止するリクエストを送信してもよろしいですか？",
	CurrentUserAccountDeactivationModal_Yes: "はい",
	CurrentUserAccountDeactivationModal_No: "いいえ",
	CourseWithdrawalModal_Title: "トレーニングコースのキャンセル",
	CourseWithdrawalModal_Line1: "キャンセルを続行しますか？",
	CourseWithdrawalModal_Line2Part1: "出金にはキャンセル料が発生し、その額は以下の通りです。",
	CourseWithdrawalModal_Line2Part2Link: "利用規約",
	CourseWithdrawalModal_Line2Part3: "キャンセル処理を続行しますか？",
	CourseWithdrawalModal_Yes: "はい",
	CourseWithdrawalModal_No: "いいえ",
	Settings_Settings: "設定",
	Settings_ManageYourSettings: "設定を管理する",
	Settings_Profile: "プロフィール",
	Settings_MyBoschGlobalIdLoginInformation: "ボッシュのグローバルIDログイン情報",
	Settings_Edit: "編集",
	Settings_CompanyDetails: "企業情報",
	Settings_RequestAccountDeletion: "アカウント削除を依頼する",
	Settings_AccountDeletionText: "アカウントを削除すると、すべてのデータ（住所と支払いデータを含む）が消去され、利用できなくなります。",
	Settings_DeleteAccountRequestConfirmation: "アカウント削除リクエストの確認",
	Settings_DeleteAccountRequestConfirmationText: "お客様からのアカウント削除のご依頼を承りました。折り返しメールまたはお電話にて最終確認のご連絡をさせていただきます。",
	RecommendedHotels_RecommendedHotels: "おすすめホテル",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "私たちのトレーニングセンターは世界中にあります",
	Error_Common: "何らかの不具合が発生しました。",
	Error_404: "アクセスしたページは存在しません",
	Error_401: "このページへのアクセス権がありません。",
	Error_500: "サーバーがエラーを報告しています",
	Dashboard_Courses_Table_Course: "コース",
	Dashboard_Courses_Table_Date: "日程(期間)",
	Dashboard_Courses_Table_Venue: "会場",
	Dashboard_Courses_Table_TrainingType: "トレーニングタイプ",
	Dashboard_Courses_Table_Action: "アクション",
	Dashboard_Courses_Table_TasksToComplete: "次のタスクを完了する必要があります",
	Dashboard_Courses_Table_Withdraw: "キャンセル",
	Dashboard_Courses_Table_Remove: "削除",
	Dashboard_Courses_Table_View: "表示",
	Dashboard_Courses_Table_NoRecordsToDisplay: "表示するレコードがありません",
	Dashboard_Courses_Table_CompletionDate: "完了日",
	Dashboard_Courses_Table_Actions_Assignments: "課題",
	Dashboard_Courses_Table_Actions_Certification: "認証",
	Dashboard_Courses_Table_Actions_Evaluations: "評価",
	Dashboard_Courses_Table_Actions_Assessments: "アセスメント",
	Dashboard_Courses_Table_Actions_AddToCalendar: "カレンダーに追加",
	Dashboard_Courses_Table_CourseDetails_Task: "タスク",
	Dashboard_Courses_Table_CourseDetails_DueDate: "期日",
	ContactUs_Headline: "お問い合わせ",
	ContactUs_HowCanWeHelp: "何かお困りですか？",
	ContactUs_EmailTab: "Eメール",
	ContactUs_PhoneTab: "電話",
	ContactUs_LetterTab: "手紙",
	ContactUs_Phone_Headline: "お問い合わせ",
	ContactUs_Phone_Description: "お電話でのお問い合わせは、下記の番号へおかけ下さい。",
	ContactUs_Phone_PhoneNumber: "お問い合わせフォームからメッセージをお送りください。",
	ContactUs_Phone_ChargeNotice: "料金はサービスプロバイダーや国によって異なります。",
	ContactUs_Letter_Headline: "郵便でのお問い合わせ",
	ContactUs_Letter_Description: "ご質問、ご要望、ご提案などございましたら、ご連絡下さい。",
	ContactUs_Email_Headline: "メールでのお問い合わせ",
	ContactUs_Email_Description: "以下のフォームに記入して、メッセージを送信してください。 担当者からご連絡差し上げます。",
	ContactUs_Email_InputName: "氏名*",
	ContactUs_Email_InputCompany: "会社",
	ContactUs_Email_InputCountry: "国*",
	ContactUs_Email_InputEmail: "Eメール*",
	ContactUs_Email_InputMessage: "メッセージ*",
	ContactUs_Email_DataProtection: "個人情報保護方針*",
	ContactUs_Email_DataProtectionConfirm: "個人情報保護方針を読みました*",
	ContactUs_Email_SecurityCheck: "セキュリティーチェック",
	ContactUs_Email_SecurityCodeConfirm: "上のボックスに表示されている正しい文字を入力してください。",
	ContactUs_Email_SecurityCodeInvalid: "入力したキャプチャテキストの回答が正しくありません。",
	ContactUs_Email_MandatoryNotice: "必須項目を入力してください。",
	ContactUs_Email_SuccessMessage: "メッセージが送信されました",
	ContactUs_Email_TakenNoteDataProtection1: "読みました。",
	ContactUs_Email_TakenNoteDataProtection2: "個人情報保護方針を*",
	ContactUs_Email_SubmitButton: "送信",
	ContactUs_Email_FieldErrorMessage: "必須項目を入力してください。",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "お客様のアカウントは現在審査中です。",
	AccountInReviewModal_Paragraph1: "ボッシュ オートモーティブ トレーニング ソリューションズに個人アカウントを登録くださり、ありがとうございます。",
	AccountInReviewModal_Paragraph2: "現在、専属のトレーニングスタッフがお客様のアカウントの確認と設定を行っています。  アカウントの設定が完了しましたら、最終確認のメールをお送りします。",
	AccountInReviewModal_Paragraph3: "その間は、アクセス制限付きのウェブサイトをご覧ください。",
	AccountInReviewModal_Paragraph4: "ご質問がある場合、または週末と休日を除いて48時間以内にアカウントが処理されない場合は、お問い合わせください。",
	AccountInReviewModal_ContactUsLink: "お問い合わせフォームへ",
	AccountInReviewModal_OKButton: "ウェブサイトへ進む",
	Header_YesButton: "はい",
	Header_NoButton: "いいえ",
	Header_LogOutText: "ログアウトしますか？",
	Footer_EnquiryText: "お問い合わせお待ちしております。",
	Footer_Telephone: "電話",
	Footer_TermsAndConditions: "利用規約",
	Footer_HowOurTrainingEvolves: "トレーニングの進化",
	FooterSearch_SearchFieldPlaceholder: "検索",
	TrainingCatalogue_Action: "アクション",
	TrainingCatalogue_Duration: "期間",
	TrainingCatalogue_Information: "情報",
	Settings_PersonalDetails: "個人情報",
	Reset_All_Filters: "フィルターをリセット",
	EventTable_Days: "日間",
	SearchField_AllResults: "すべての結果",
	TrainingCentresLayout_ViewDetails: "詳細表示",
	Dropdown_Placeholder: "選択...",
	WaitlistPopup_SelectButtonText: "選択する",
	WaitlistPopup_StartDate: "開始日",
	WaitlistPopup_EndDate: "終了日",
	WaitlistPopup_Language: "言語",
	WaitlistPopup_City: "市",
	WaitlistPopup_Workshop: "ワークショップ",
	WaitlistPopup_Student: "受講者",
	WaitlistPopup_PreferredLocation: "ご希望の場所",
	WaitlistPopup_SignUpCourseWaitingList: "キャンセル待ちリストにサインアップ",
	WaitlistPopup_SignUpWithoutSelectingDate: "日付を選択せず​​にキャンセル待ちリストにサインアップ",
	WaitlistPopup_GeneralWaitList: "一般的なキャンセル待ちリスト",
	WatinglistPopup_CourseLabel: "コース",
	Common_ContinueButton: "続行",
	UserAccountDeactivationModal_ConfirmLabel: "確認",
	Filter_FilterLabel: "フィルター",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "お客様はすでにキャンセル待ちリストに登録されています。既存の登録を削除して新しい登録を作成しますか？",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "はい",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "いいえ",
	WaitinglistPopup_RemoveWaitlistItem_Title: "コースのキャンセル",
	WaitinglistPopup_SignUpButtonText: "サインアップ",
	MyAccount_FirstNameRequied: "名は必須項目です",
	MyAccount_LastNameRequired: "姓は必須項目です",
	MyAccount_RoleRequired: "職務は必須項目です",
	MyAccount_MobileRequired: "携帯電話番号は必須項目です",
	MyAccount_EmailRequired: "メールアドレスは必須項目です",
	MyAccount_LanguageRequired: "言語は必須項目です",
	MyAccount_UpdateSuccessMessage: "アカウント情報が正常に更新されました。",
	MyAccount_UpdateSuccessModal_ButtonText: "はい",
	Infor_CorporateInformation_Header: "企業情報",
	Infor_LegalNotice_Header: "法的通知",
	Infor_PrivacyPolicy_Header: "個人情報保護方針（プライバシーポリシー）",
	CreateEmployeeAccountModification_FormValidation_Role: "職務は必須項目です",
	CreateEmployeeAccountModification_FormValidation_Firstname: "名は必須項目です",
	CreateEmployeeAccountModification_FormValidation_Lastname: "姓は必須項目です",
	CreateEmployeeAccountModification_FormValidation_Mobile: "携帯電話番号は必須項目です",
	CreateEmployeeAccountModification_FormValidation_Email: "メールアドレスは必須項目です",
	CreateEmployeeAccountModification_FormValidation_Language: "言語は必須項目です",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "ボッシュ オートモーティブ トレーニング ソリューションズへの招待",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "チームに新しいメンバーが正常に追加されました。",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "新しいチームメンバーに、ボッシュ オートモーティブ トレーニング ソリューションズの個人アカウントを作成する方法を記載した招待メールを共有しますか？",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "いいえ",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "はい",
	ShoppingCart_Venue_Fee: "会場費",
	ShoppingCart_Trainning_Name: "トレーニングコース",
	ShoppingCart_Available_Credits: "利用可能なクレジット",
	ConfirmItsMeModal_Hi: "こんにちは",
	ConfirmItsMeModal_Description1: "ボッシュ オートモーティブ トレーニング ソリューションズの個人アカウントを作成するために、会社の管理者から招待状を受け取りました。",
	ConfirmItsMeModal_Description2: "続行する前に、アカウント情報を確認または更新してください。",
	ConfirmItsMeModal_Description3: "確認後、アカウントの作成を完了するには、再度ログインする必要があります。",
	ConfirmItsMeModal_CompanyDetails: "企業情報",
	ConfirmItsMeModal_YourDetails: "お客様情報",
	ConfirmItsMeModal_YourDetails_Title: "役職",
	ConfirmItsMeModal_YourDetails_Function: "職務は必須項目です",
	ConfirmItsMeModal_YourDetails_Name: "氏名",
	ConfirmItsMeModal_YourDetails_Mobile: "携帯電話",
	ConfirmItsMeModal_YourDetails_Email: "Eメール",
	ConfirmItsMeModal_YourDetails_Language: "言語",
	ConfirmItsMeModal_ConfirmButton: "確認",
	SelectWholesalerModal_WholesalerGroupLabel: "代理店",
	SelectWholesalerModal_WholesalerBranchLabel: "販路",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "選択してください",
	ApplyButton: "申し込み",
	SelectWholesalerModal_Title: "代理店を選択してください",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "登録を削除しますか？",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "お客様の登録は正常に削除されました。",
	WaitlistPopup_AddToWaitListSuccess: "お客様の登録は正常に追加されました。",
	ErrorCommon_Header: "エラー",
	CommonButton_Close: "閉じる",
	MyELearning_OpeningVideoPlayerTitle: "ボッシュオートモーティブeラーニング",
	MyELearning_VideoPlayerClosingMessage: "続行するには、このウィンドウを閉じてください。",
	EmbeddedVideoPlayer_StartButtonText: "始める",
	EmbeddedVideoPlayer_RefresherButtonText: "リフレッシャー",
	DeactivateWholesalerModal_ConfirmMessage: "この代理店を削除してもよろしいですか？",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "招待メールは正常に送信されました",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "再招待",
	ContactUs_Mobile_JP: "(+81) 352136657",
	ContactUs_Address_JP: "ボッシュ株式会社,〒150-8360,東京都渋谷区渋谷3-6-7",
	ConfirmItsMeModal_CompanyDetails_Name: "会社名",
	ConfirmItsMeModal_CompanyDetails_Address: "住所",
	ConfirmItsMeModal_CompanyDetails_Postcode: "郵便番号",
	ConfirmItsMeModal_CompanyDetails_Phone: "電話",
	ConfirmItsMeModal_CompanyDetails_Email: "Eメール",
	CourseWithdrawalModal_WithdrawSuccessMessage: "トレーニングの申し込みがキャンセルされました。",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "アカウントの作成に失敗しました。 アカウントはすでに存在しています。",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "アカウントの更新に失敗しました。 アカウントはすでに存在しています。",
	Footer_ExploreTrainingStories: "すべてのトレーニングストーリーをご覧ください",
	Breadcrumb_Home: "ホーム",
	Breadcrumb_Contact: "お問い合わせ",
	Breadcrumb_Stories: "ストーリー",
	Breadcrumb_Stories_BoschServiceTraining: "ボッシュのサービストレーニング",
	Breadcrumb_Stories_ADayInLife: "トレーナーの一日",
	Breadcrumb_Stories_BoschTrainingBenefits: "ボッシュのトレーニングのメリット",
	Breadcrumb_Stories_TrainingApproach: "最新のトレーニングアプローチ",
	Breadcrumb_Settings: "設定",
	Breadcrumb_ShoppingCart: "ショッピングカート",
	Breadcrumb_Checkout: "お申し込み手続き",
	Breadcrumb_OrderSummary: "お申込み内容の概要",
	Breadcrumb_Exception: "例外",
	Breadcrumb_Dashboard: "ダッシュボード",
	Breadcrumb_MyCourses: "マイコース",
	Breadcrumb_MyELearning: "マイeラーニング",
	Breadcrumb_CompanyAdministration: "会社の管理",
	Breadcrumb_EmployeeManagement: "従業員の管理",
	Breadcrumb_EmployeeAccount: "従業員アカウント",
	Breadcrumb_EmployeeCreation: "従業員の追加",
	Breadcrumb_Administration: "管理",
	Breadcrumb_EmployeeAdministration: "従業員の管理",
	Breadcrumb_WholesalerManagement: "代理店の管理",
	Breadcrumb_NewWholesalerAccountCreation: "新しい代理店のアカウントの作成",
	Breadcrumb_Transactions: "取引",
	Breadcrumb_MyAccount: "マイアカウント",
	Breadcrumb_TrainingCentres: "トレーニングセンター",
	Breadcrumb_EventCalendar: "トレーニング コース日程",
	Breadcrumb_TrainingCourseCatalogue: "トレーニングコースカタログ",
	Breadcrumb_RecommendedHotels: "おすすめのホテル",
	Breadcrumb_CorporateInformation: "企業情報",
	Breadcrumb_LegalNotice: "法的通知",
	Breadcrumb_DataProtectionNotice: "個人情報保護方針",
	Breadcrumb_PrivacyPolicy: "個人情報保護方針",
	Breadcrumb_TermCondition: "利用規約",
	Breadcrumb_Terms: "条項",
	Breadcrumb_PrivacyStatement: "プライバシーに関する声明",
	Breadcrumb_Cookies: "クッキー",
	Breadcrumb_Provider: "プロバイダー",
	Breadcrumb_RegisterAccount: "アカウント登録",
	Stories_ExploreMore: "詳細を見る",
	CheckOutLayout_ShoppingCartHeader: "ショッピングカート",
	CheckOutLayout_AddedItemCartMessage: "カートに以下を追加しました",
	CheckOutLayout_CheckYourCartMessage: "カートを確認してください",
	ModernTrainingApproach_DateText: "2/22/2022",
	ADayInLifeofATrainer_DateText: "2/22/2022",
	BoschTrainingBenefits_DateText: "2/22/2022",
	BoschServiceTraining_DateText: "2/22/2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "従業員を検索",
	CheckOutLayout_OrderWasNotPlaced_Message: "申し訳ございません。お申込みが成立していません。",
	CheckOutLayout_RefusalReason: "拒否理由",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "支払いに失敗しました。別のお支払い方法で再試行してください",
	CheckOutLayout_SubmittingApiFailed_Message: "APIの送信に失敗しました",
	CartItem_ChooseWorkshop_Message: "参加者を選ぶためにワークショップを選択してください",
	CartItem_ChooseParticipant_Message: "続行するには参加者を選択してください",
	CompanyAdministrationTable_SearchBox_Placeholder: "ワークショップの検索",
	CartItem_RemoveCartItem_ConfirmText: "このアイテムをカートから削除しますか？",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "職務",
	AddToCartSuccessModal_Message: "ショッピングカートが更新されました",
	AddToCartSuccessModal_ContinueButtonText: "ショッピングを続ける",
	AddToCartSuccessModal_MyShoppingCartButtonText: "マイショッピングカート",
	AddVoucher_Header: "クーポン券を追加する",
	AddVoucher_InputPlaceholder: "コードを入力して適用",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "現在、このトレーニングコースの開催予定はございません。キャンセル待ちの登録をしていただければ、開催が決定した際にお知らせいたします。",
	ADYEN_ERR_2_: "取引は拒否されました。",
	ADYEN_ERR_3_: "リファーラルエラー",
	ADYEN_ERR_4_: "アクワイヤラー側で発生したエラーのため、取引が成立しませんでした。",
	ADYEN_ERR_5_: "決済に使用されたカードはブロックされ、使用できません。",
	ADYEN_ERR_6_: "取引に使用されたカードは有効期限が切れているため、使用できません。",
	ADYEN_ERR_7_: "取引処理中に金額の不一致が発生しました。",
	ADYEN_ERR_8_: "指定されたカード番号に誤りがあるか、または無効です。",
	ADYEN_ERR_9_: "ショッパーの銀行にコンタクトできないため、取引を承認することができません。",
	ADYEN_ERR_10_: "ショッパーの銀行がこのタイプの取引をサポートしていない、または許可していません。",
	ADYEN_ERR_11_: "3D Secure認証が実行されなかった、または正常に実行されませんでした。",
	ADYEN_ERR_12_: "カードには支払い可能な金額をカバーするのに十分な残高がありません。 この問題は、カードの毎月の支払限度額が原因で発生する可能性があります。 MasterCard などの別のカードを使用するか、銀行に連絡してカードの毎月の限度額をリセットしてください。",
	ADYEN_ERR_14_: "不正の可能性があります。",
	ADYEN_ERR_15_: "取引はキャンセルされました。",
	ADYEN_ERR_16_: "取引が完了する前にショッパーがキャンセルしました。",
	ADYEN_ERR_17_: "指定された暗証番号が正しくないか、無効です。",
	ADYEN_ERR_18_: "不正な暗証番号を3回以上連続して指定しました。",
	ADYEN_ERR_19_: "指定されたPIN番号の認証ができません。",
	ADYEN_ERR_20_: "不正の可能性があります。",
	ADYEN_ERR_21_: "取引が処理のために正しく送信されませんでした。",
	ADYEN_ERR_22_: "リスクチェックの結果、不正な取引と判定された（リスクスコア100以上）ため、処理を中止します。",
	ADYEN_ERR_23_: "この拒否理由には、次のような辞退コードが割り当てられています。  イシュア―/カード所有者に許可されていない取引",
	ADYEN_ERR_24_: "指定されたCVC（カードセキュリティコード）が無効です。",
	ADYEN_ERR_25_: "この拒否理由には、次のような拒否コードが割り当てられています： この国ではカードが無効です。",
	ADYEN_ERR_26_: "R1：承認取消し命令／R3:全権限停止命令／R0：支払い停止命令",
	ADYEN_ERR_27_: "このレスポンスは、信頼性の高いマッピングができないレスポンスコードをすべてマッピングします。これにより、一般的な拒否（たとえば、Mastercard の \"05: Do not honor\" 応答）とより具体的な拒否を簡単に見分けることができます。",
	ADYEN_ERR_28_: "カードの引出可能額を超えました。",
	ADYEN_ERR_29_: "カードの引出し可能回数を超えました。",
	ADYEN_ERR_31_: "イシュア―から不正の疑いがある取引として報告されました。",
	ADYEN_ERR_32_: "入力した住所データに誤りがあります。",
	ADYEN_ERR_33_: "ショッパーの銀行がショッパーにオンライン PIN の入力を要求しています。",
	ADYEN_ERR_34_: "ショッパーの銀行では、購入を完了するために当座預金口座が必要です。",
	ADYEN_ERR_35_: "ショッパーの銀行では、購入を完了するために普通預金口座が必要です。",
	ADYEN_ERR_36_: "ショッパーの銀行が、買い物客にモバイル PIN の入力を要求しています。",
	ADYEN_ERR_37_: "ショッパーは、非接触型決済を試みた後、別のカード入力方法（PINまたはスワイプ）を試すよう促され、取引を断念しました。",
	ADYEN_ERR_38_: "イシュアーが認証免除要求を拒否したため、この取引には認証が必要です。3Dセキュアで再試行してください。",
	ADYEN_ERR_39_: "イシュアーまたはスキームがRReqで結果を伝えることができませんでした。",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "ファーストネーム(名)に特殊文字（例：@#$...）を含まないこと",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "ファーストネーム(名)は75文字未満であること",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "ラストネーム(姓)に特殊文字（例：@#$...）を含めないでください。",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "ラストネーム(姓)は75文字未満であること",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "携帯電話番号には、特殊文字（例：@#$...）を含めないでください。",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "携帯電話番号は45文字未満であること",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "電話番号には、特殊文字（例：@#$...）を含めないでください。",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "電話番号は45文字未満であること",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "eメールアドレスが無効です",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "eメールアドレスは100文字未満であること",
	ConfirmGeolocationModal_ConfirmButton: "続ける",
	ConfirmGeolocationModal_CancelButton: "変更する",
	ConfirmGeolocationModal_CurrentCountrySelection: "現在選択している国は次のとおりです。",
	ConfirmGeolocationModal_AskChangeCountry: "このサイトにとどまりますか、それとも国を変更しますか？",
	Addtocart_Modal_AddToCartFailTitle: "参加者の追加に失敗しました",
	Addtocart_Modal_AlreadyInCart: "参加者はすでにこのイベントに登録しています",
	TrainingCatalogue_DateRange: "日付範囲",
	TrainingCatalogue_DateRange_placeholder: "日付範囲フィルタの選択",
	Dashboard_MyPendingCourses: "保留中のコース",
	Dashboard_MyWaitingList: "キャンセル待ちリスト",
	Dashboard_LatestNews: "最新ニュース",
	SettingsMainPage_EditAccountButton: "アカウントの編集",
	SettingsMainPage_EditBoschId: "ボッシュIDを編集する",
	EventInfoView_EventDetailTab: "イベント詳細",
	EventInfoView_AssignmentsTab: "課題",
	EventInfoView_EvaluationTab: "評価",
	EventInfoView_AttachmentTab: "添付資料",
	EventInfoView_CourseDescriptionTab: "概要",
	EventInfoView_DescriptionTab_General: "一般",
	EventInfoView_DescriptionTab_PartLabel: "パート",
	AssignmentTab_Description: "ここでは、このコースを完了するために必要なすべての情報が記載されています。",
	AssignmentTab_Columns_Download: "ダウンロード",
	AssignmentTab_Columns_Upload: "アップロード",
	AssignmentTab_Columns_TaskComplete: "タスクの完了",
	EventDetailTab_Location_Message_Video: "このイベントはオンラインイベントであり、「動画再生」のリンクからアクセスできます。",
	EventDetailTab_Location_Message_WebBased: "このイベントはオンラインイベントであり、「WBT開始」のリンクからアクセスできます。",
	EventDetailTab_Location_Message_WebCast: "このイベントはオンラインイベントであり、「今すぐ参加」のリンクからアクセスすることができます。",
	EventDetailTab_Link_Video: "動画再生",
	EventDetailTab_Link_Video_Remark: "*動画を再生すると、新しいウィンドウが開きます。ウィンドウを閉じると、このページに戻ります。",
	EventDetailTab_Link_WebBased: "WBT開始",
	EventDetailTab_Link_WebBased_Remark: "*WBTを開始すると、新しいウィンドウが開きます。ウィンドウを閉じると、このページに戻ります。",
	EventDetailTab_Link_WebCast: "今すぐ参加",
	EventDetailTab_Link_WebCast_Remark: "*イベント開始の30分前になると、「今すぐ参加」のリンクがアクティブになります。",
	EventDetailTab_Title_Status: "状態",
	EventDetailTab_Title_Duration: "所要時間 (時間)",
	EventDetailTab_Title_Trainers: "トレーナー",
	EventDetailTab_Title_Language: "言語",
	EventDetailTab_Title_Score: "得点（点）",
	EventDetailTab_Title_Calendar: "カレンダーに追加",
	EventDetailTab_Title_Certificate: "終了証のダウンロード",
	EventDetailTab_Title_Messages: "メッセージ",
	EventDetailTab_Title_StartDate: "開始日",
	EventDetailTab_Title_AvailableFrom: "から利用可能",
	EventDetailTab_Title_Time: "時間",
	EventDetailTab_Title_EndDate: "終了日",
	EventDetailTab_Title_ExpiresOn: "有効期限",
	EventDetailTab_Title_Room: "部屋",
	EventDetailTab_Title_Method: "方法",
	EventDetailTab_Title_Link: "リンク*",
	EventDetailTab_Title_Location: "開催地",
	AttachmentTab_EmptyScreenDescription: "このイベントには添付ファイルがありません。",
	AttachmentTab_Columns_Download: "ダウンロード",
	AttachmentTab_Description: "ここでは、このコースに参加するための重要な情報が記載されています。",
	EvaluationTab_Annotation: "私たちはお客様からのフィードバックを大切にしています。お客様が私たちにどのような印象をお持ちなのか、ご意見をお待ちしています。お客様へのサービスを継続的に向上させるため、お手数ですが、以下のアンケートにご協力ください。",
	EvaluationTab_Notice: "アンケートは以下の通りです。",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "アンケートを送信する前に、すべての必須項目にお答えください。",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "お客様のご意見は、正常に送信されました。",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "アンケートの送信に失敗しました。",
	TrainingCatalogueLayout_ExportButtonText: "書き出し",
	EvaluationTab_FeedbackHasBeenGiven_Message: "アンケートは終了、または有効期限が切れています。",
	EventDetailTab_Link_CopiedTooltip: "クリップボードにコピー",
	EventDetailTab_QRCode_ExplanationText_WBT: "スキャンしてWBTを開始",
	EventDetailTab_QRCode_ExplanationText_Video: "スキャンして動画を再生",
	EventDetailTab_QRCode_ExplanationText_Webcast: "スキャンしてWebcastを開始",
	Footer_TrainingCoure_Headline: "ボッシュが提供するトレーニングコース",
	Footer_TrainingCourse_Content: "ボッシュは、世界的に有名なブランドとして、また100年以上にわたる革新的な経験をもとに、自動車用システム、部品、技術ソリューションを提供する企業としてグローバルスタンダードを確立してきました。ボッシュの強みは、自動車メーカーとの緊密なパートナーシップにあります。ボッシュは自動車メーカーのために最先端のシステムやコンポーネントを設計・開発し、世界中で生産しています。その結果、ボッシュは自動車アフターマーケット製品およびサポートにおいて、部品、診断ソリューション、ワークショップサービスなどの多様なポートフォリオで確固たる地位を築いてきました。学習と開発は成功の鍵であり、この哲学は自動車技術、車両システム、診断の専門知識のあらゆる分野における幅広いトレーニングコースによって支えられています。",
	SearchField_NoRecordsFound: "記録が見つかりません。",
	TrainingCatalogue_FreeTextSearch: "自由記述",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "検索",
	CheckoutLayout_WholeSalerGroup: "代理店を選択",
	CreditInsufficientModal_Message: "この支払方法を使用するための十分なクレジットがありません。別の支払い方法でもう一度やり直してください。",
	ShoppingCart_Required_Credits: "合計金額",
	MaintainingInfo_Message1: "現在ウェブサイトはメンテナンス中です。",
	MaintainingInfo_Message2: "すぐに戻ります。",
	CountryValidationModal_Message: "あなたのアカウントは[CountryB]で作成されているため、[CountryA]にログインすることができませんでした。",
	CountryValidationModal_ButtonText: "[Country]へ進む",
	EventTable_LoginRequired: "ログインして登録する",
	TrainingPartTableLayout_Date: "日付",
	TrainingPartTableLayout_StartTime: "開始時間",
	TrainingPartTableLayout_EndTime: "終了時間",
	TrainingPartTableLayout_Duration: "所要時間(時間)",
	TrainingPartTableLayout_TrainingMethod: "トレーニング形態",
	MyCoursesTableContents_ActiveHeader: "申し込み済みのコース",
	MyCoursesTableContents_ActiveTabHeader: "申し込み済み",
	MyCoursesTableContents_ScheduledHeader: "トレーニングスケジュール",
	MyCoursesTableContents_ScheduledTabHeader: "スケジュール",
	MyCoursesTableContents_OnlineHeader: "オンラインコース",
	MyCoursesTableContents_OnlineTabHeader: "オンライン",
	MyCoursesTableContents_CancelledHeader: "キャンセル済みのコース",
	MyCoursesTableContents_CancelledTabHeader: "キャンセル済み",
	OnlineCoursesTable_Title_Progress: "進行状況",
	ScheduledCoursesTable_Title_Date: "日程[期間]",
	CoursesTable_Title_Completed: "完了",
	Footer_TrainingProgram: "トレーニングプログラム",
	Breadcrumb_TrainingProgram: "トレーニングプログラム",
	TrainingProgram_Title_Table: "タイトル",
	TrainingProgram_TrainingProgram: "トレーニングプログラム",
	TrainingProgram_TargetGroup: "対象",
	TrainingProgram_MaxLength: "最大長",
	TrainingProgram_Information: "インフォメーション",
	TrainingProgram_Detail: "詳細",
	TrainingProgram_Modules: "モジュール",
	TrainingProgram_Course: "コース",
	TrainingProgram_PartNumber: "品番",
	TrainingProgram_Max_Length: "最大長",
	TrainingProgram_Duration: "期間",
	TrainingProgram_Exam: "試験",
	TrainingProgram_Assign_Popup: "トレーニングプログラムへの登録",
	TrainingProgram_Student: "受講者",
	TrainingProgram_Assign: "割り当て",
	TrainingProgram_Popup_Remove_Title: "トレーニンをキャンセルしますか？",
	TrainingProgram_Description: "概要",
	TrainingProgram_Days: "日",
	TrainingProgram_Type: "タイプ",
	TrainingProgram_Action: "アクション",
	TrainingProgram_Enrolled: "既にこのトレーニングプログラムに登録されています。",
	TrainingProgram_SelfAssign_Enrolled: "既にこのトレーニングプログラムに登録されています。",
	TrainingProgram_Warning: "他の受講者を登録するか、他のトレーニングプログラムを選択してください。",
	TrainingProgram_Workshop: "研修",
	TrainingProgram_Durations: "年",
	TrainingProgram_Duration_DaysLabel: "日",
	TrainingProgram_HasExam_Yes: "はい",
	TrainingProgram_HasExam_No: "いいえ",
	AssignModal_SuccessModal_Header: "ありがとうございます",
	AssignModal_SuccessModal_Message: "トレーニングプログラムに正常に登録されました。",
	AssignModal_SelfAssign_SuccessModal_Message: "トレーニングプログラムに正常に登録されました。",
	Dashboard_MyTraningProgram: "マイトレーニングプログラム",
	Dashboard_MyTraningProgram_Title: "タイトル",
	Dashboard_MyTraningProgram_Status: "状態",
	Dashboard_MyTraningProgram_Table_View: "表示",
	Dashboard_MyTraningProgram_Register: "登録",
	Dashboard_MyTraningProgram_Records: "履歴",
	Dashboard_MyTrainingProgram_In_Progress: "進行中",
	Dashboard_MyTrainingProgram_Accredited: "認定済み",
	Dashboard_Program_Modal_Withdraw: "キャンセル",
	Dashboard_Program_Modal_Grade: "等級",
	Dashboard_MyTrainingProgram_Registered: "登録済み",
	Dashboard_MyTrainingProgram_Completedd: "完了",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "はい",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "いいえ",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "キャンセルとは、個々のトレーニングコースだけでなく、トレーニングプログラム全体をキャンセルすることを意味しますので、ご注意ください。",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "キャンセルを続行しますか？",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "保留",
	WithdrawProgramModal_Withdraw_Success_Message: "トレーニングプログラムのキャンセルが正常に完了しました。",
	Common_Warning_Title: "警告！",
	Common_Error_Title: "何らかの不具合が発生しました。",
	AddEventFromCatalogToCartModal_AddToCartButton: "カートへ追加",
	AddEventFromCatalogToCartModal_HoursText: "時間",
	AddElearningToCartSuccessModal_Message: "カートに追加済み",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "選択された受講者はこのコースに登録されているか、またはコースがすでにカートに追加されています。",
	EventDetailTab_SubjectForChange_Message: "変更対象項目",
	Dashboard_MyTrainingProgram_Status_Failed: "失敗",
	Dashboard_MyTrainingProgram_Status_Passed: "成功",
	Dashboard_MyTrainingProgram_Status_Merit: "利点",
	Dashboard_MyTrainingProgram_Status_Distinction: "区別",
	Dashboard_Employee_Courses: "コース",
	Dashboard_Employee_Program: "トレーニングプログラム",
	Dashboard_Employee_WaitList: "キャンセル待ちリスト",
	Dashboard_Employee_Account: "アカウント",
	Course_Price_Free: "無料",
	Dashboard_Employee_Account_Update_Success_Message: "お客様の従業員情報が正常に更新されました。",
	Dashboard_Supplier: "代理店",
	Event_Detail_Comment: "現在メッセージはありません",
	DownLoad_PDF: "PDFをダウンロード",
	TrainingCatalogue_Currency: "通貨",
	My_Wallet: "マイウォレット",
	Wallet: "ウォレット",
	Credits_Balance: "クレジットバランス",
	MyFullCalendar_Today: "本日",
	Header_Available_Languages: "言語選択",
	Header_CurrentRegion: "現在選択中の国：",
	Header_ChangeRegions: "国を変更しますか？",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "ログイン/登録",
	Header_RegisterCourse: "トレーニング申込み",
	Homepage_Start: "始める",
	Homepage_Login: "ログイン",
	MainPage_ImageTitle: "ボッシュ オートモーティブ トレーニング ソリューションズ",
	MainPage_IntroContent: "あらゆるタイプの自動車の診断手順、トラブルシューティング、修理、サービスにおいて、現代のワークショップが専門的かつ信頼性の高い効率的な作業を行うことを可能にします。",
	BoschNews_TrainingNews: "トレーニングニュース",
	Service_Assist_Workshop: "ワークショップ サービス アシストでより高度な体験を",
	Enhance_Title_CDM3_v2: "アプリで何ができますか？",
	Enhance_Body_CDM3_Item_1: "コースを予約して、学習を継続",
	Enhance_Body_CDM3_Item_2: "トレーニング教材とコース修了証のダウンロード",
	Enhance_Body_CDM3_Item_3: "eラーニングコースの開始",
	Enhance_Body_CDM3_Item_4: "モバイル アプリケーションで様々な自動車関連サービスにアクセス",
	Enhance_Body_CDM3_Item_5: "ビジュアルコネクトプロによるライブビデオ配信",
	Enhance_Body_CDM3_Item_6: "自動車両スキャン用無料ツール",
	Training_Stories: "トレーニング ストーリー",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "アクティベーションコードを以下へ入力してください",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "ご注意ください！アクティベーションコードを取得するためには、会社の管理者に連絡する必要があります。",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "アクティベーションが正常に処理され、アカウントが貴社にリンクされました。",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "「続ける」をクリックして、ウェブサイトにアクセスしてください。",
	ContinueButton: "続ける",
	WelcomeBatsModal_Title: "お客様のアカウントは現在審査中です。",
	WelcomeBatsModal_ActivationLink: "アクティベーションコードをお持ちの方は、こちらをクリックしてください。",
	WelcomeBatsModal_ActivationLinkNotes: "ご注意ください！アクティベーションコードを取得するためには、会社の管理者に連絡する必要があります。",
	AccountInReviewModal_Description_Paragraph1: "ボッシュ オートモーティブ トレーニング ソリューションズの個人アカウントを登録いただき、ありがとうございます。",
	AccountInReviewModal_Description_Paragraph2: "現在、専任のトレーニングスタッフがお客様のアカウントを確認し、設定を行っています。アカウント設定が完了しましたら、最終確認のメールをお送りします。",
	AccountInReviewModal_CloseButton: "OK",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "お客様のアクティベーションコードとメールアドレスの認証ができませんでした。",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "再度お試しいただくか、カンパニーアドミニストレータにお問い合わせください。",
	TryAgain_Button: "再試行",
	Error_Title: "不具合が発生しました。",
	SettingsMyProfileActivationSuccessModal_Title: "成功しました。",
	AccountInReviewModal_Description_Paragraph3: "その間は、アクセス制限付きのウェブサイトをご覧ください。",
	AccountInReviewModal_Description_Paragraph4: "ご質問がある場合、または土日祝日を除く48時間以内に処理されない場合は、[こちら]からご連絡ください。",
	Support_Headline: "ボッシュ オートモーティブ トレーニング ソリューションズ",
	Support_SubHeadline: "ガイダンスを参考にしてください",
	Support_FAQ_Headline: "よくある質問",
	Support_FAQs_section1_title: "一般",
	Support_FAQs_section1_question1: "ボッシュ オートモーティブ トレーニング ソリューションズとは何ですか？",
	Support_FAQs_section1_question1_answer1: "ボッシュ オートモーティブ トレーニング ソリューションズ (BATS)とは、技術トレーニングの包括的な概要を提供し、トレーニングを予約・管理することができるプラットフォームです。",
	Support_FAQs_section1_question2: "既存のボッシュアカウント（singleKey ID）を使ってBATSにログインすることはできますか？",
	Support_FAQs_section1_question2_answer1: "はい、「マイボッシュID」で作成したすべてのアカウントでアクセス可能です。",
	Support_FAQs_section1_question3: "BATSで作成したアカウントは、ボッシュの他のサービスやアプリケーションで使用できますか？",
	Support_FAQs_section1_question3_answer1: "ボッシュIDは、e-bikeやスマートホームなど、仕事でもプライベートでも、ボッシュのさまざまなアプリケーションに使用することができます。",
	Support_FAQs_section2_title: "トレーニング",
	Support_FAQs_section2_question1: "トレーニングを予約するにはどうすればよいですか？",
	Support_FAQs_section2_question1_answer1: "既存のアカウントでログインするか、ポータルにアカウントを登録してください。トレーニングサービス」または「イベントカレンダー」にて、トレーニングを選択し、「カートに追加」ボタンをクリックします。",
	Support_FAQs_section2_question2: "適切なトレーニングの日程がありません。どうすればいいのでしょうか？",
	Support_FAQs_section2_question2_answer1: "新しいトレーニングが開始され次第、弊社よりお知らせいたしますので、「一般」から日程を選択せずにウェイティングリストに登録ください。",
	Support_FAQs_section2_question3: "予約したコースの概要はどこで確認できますか？",
	Support_FAQs_section2_question3_answer1: "ログイン後、ダッシュボードで予約済み・完了したコースの詳細を確認することができます。",
	Support_FAQs_section3_title: "設定",
	Support_FAQs_section3_question1: "メールアドレスとパスワードを変更したいのですが、どうすればよいですか？",
	Support_FAQs_section3_question1_answer1: "BATSにログインし、個人プロフィールを変更する必要があります。(ダッシュボードの中にあるプロフィール情報、またはヘッダーにある以下のボタンをクリックすると、プロフィール情報を見ることができます。",
	Support_FAQs_section3_question1_answer3: "そこでボッシュIDの詳細を変更することができます。",
	Support_FAQs_section3_question2: "パスワードを忘れてしまったときはどうすればよいですか？",
	Support_FAQs_section3_question2_answer1: "ログイン/登録ボタンをクリックし、メールアドレスを入力してください。",
	Support_FAQs_section3_question2_answer2: "「続行」をクリックして「パスワードを忘れた場合」をクリックします。",
	Support_FAQs_section3_question3: "ワークショップのアカウントを設定するには？",
	Support_FAQs_section3_question3_answer1: "お問い合わせフォームよりご連絡ください。",
	LandingPage_Header_Title: "ボッシュ オートモーティブ トレーニング ソリューションズ",
	LandingPage_ChooseCountry_Title: "地域/国を選択してください",
	MaintenanceBanner_Message_1: "BATSポータルは、[maintenance_schedule]で更新されます。アップデートには30分程度かかる予定です。アップデート中は、ポータルをご利用いただけません。",
	MaintenanceBanner_Message_2: "ご不明な点がございましたら、メール[support_email]でお問い合わせください。",
	MaintenancePage_Message_1: "BATSポータルは現在更新中です。",
	MaintenancePage_Message_2: "ポータルは、[maintenance_online_date]の[maintenance_online_time]に利用可能になる見込みです。",
	MenuHeader_Support: "サポート",
	Course_Detail_No_Prerequisites: "前提条件なし",
	TableCell_Hours: "時間",
	Dashboard_MenuItem_Dashboard: "ダッシュボード",
	Dashboard_MenuItem_Curricula: "カリキュラム",
	Dashboard_MenuItem_Certificates: "終了証",
	Dashboard_MenuItem_Team: "チーム",
	Dashboard_MenuItem_Workshops: "ワークショップ",
	Dashboard_MenuItem_External: "外部",
	Dashboard_MenuItem_Wallet: "ウォレット",
	Dashboard_MenuItem_Profile: "プロフィール",
	Dashboard_Headline_YourUpcomingCourses: "あなたの開催予定コース",
	Dashboard_Headline_YourELearningCourses: "あなたのeラーニングコース",
	Dashboard_Headline_TeamOverview: "チームの概要",
	Dashboard_Headline_YourOverview: "あなたの概要",
	Dashboard_Headline_YourWishlist: "あなたのウィッシュリスト",
	Dashboard_Headline_ActivePrograms: "有効プログラム",
	Dashboard_Headline_CompletedPrograms: "完了プログラム",
	Dashboard_Headline_YourCertificates: "あなたの終了証",
	Dashboard_Headline_Transactions: "取引",
	Dashboard_Table_NoRecordsToDisplay: "表示する記録はありません",
	Dashboard_TableColumn_Location_Online: "オンライン",
	Dashboard_TableColumn_Duration_Hours: "時間",
	Dashboard_ELearningCourses_TableHeader_Title: "タイトル",
	Dashboard_ELearningCourses_TableHeader_Duration: "所要時間",
	Dashboard_ELearningCourses_ActionButton_View: "詳細",
	Dashboard_UpcomingCourses_TableHeader_Title: "タイトル",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "日数",
	Dashboard_UpcomingCourses_TableHeader_Location: "場所",
	Dashboard_UpcomingCourses_ActionButton_View: "詳細",
	Dashboard_YourOverview_TableHeader_Title: "タイトル",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "日付／所要時間",
	Dashboard_YourOverview_TableHeader_Location: "場所",
	Dashboard_YourOverview_TableHeader_Status: "状態",
	Dashboard_YourOverview_ActionButton_View: "詳細",
	Dashboard_YourWishlist_TableHeader_Title: "タイトル",
	Dashboard_YourWishlist_TableHeader_Action: "実行",
	Dashboard_YourWishlist_ViewAllRecords: "すべての記録を見る",
	Dashboard_TeamOverview_TableHeader_Employee: "従業員",
	Dashboard_TeamOverview_TableHeader_Title: "タイトル",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "日付／所要時間",
	Dashboard_TeamOverview_TableHeader_Location: "場所",
	Dashboard_TeamOverview_TableHeader_Status: "状態",
	Dashboard_TeamOverview_ActionButton_View: "詳細",
	Dashboard_TeamOverview_SearchBox_Placeholder: "検索",
	Dashboard_ActiveProgram_TableHeader_Title: "タイトル",
	Dashboard_ActiveProgram_TableHeader_Status: "状態",
	Dashboard_ActiveProgram_ActionButton_View: "詳細",
	Dashboard_CompletedProgram_TableHeader_Title: "タイトル",
	Dashboard_CompletedProgram_TableHeader_Status: "状態",
	Dashboard_CompletedProgram_ActionButton_View: "詳細",
	Dashboard_Certificates_TableHeader_Title: "タイトル",
	Dashboard_Certificates_TableHeader_Date: "日付",
	Dashboard_Certificates_TableHeader_Status: "状態",
	Dashboard_Certificates_TableHeader_Action: "実行",
	Dashboard_Certificates_ActionButton_Download: "ダウンロード",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "請求書番号",
	Dashboard_Transactions_TableHeader_Description: "概要",
	Dashboard_Transactions_TableHeader_PaymentMethod: "支払い方法",
	Dashboard_Transactions_TableHeader_Date: "日付",
	Dashboard_Transactions_TableHeader_Amount: "数量",
	Dashboard_Transactions_ActionButton_Download: "ダウンロード",
	Breadcrumb_MyTraining: "私のトレーニング",
	Breadcrumb_MyTraining_CourseDetails: "コースの詳細",
	Breadcrumb_MyTraining_Curricula: "カリキュラム",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "トレーニングプログラムの詳細",
	Breadcrumb_MyTraining_Team: "チーム",
	Breadcrumb_MyTraining_Team_ViewEmployee: "従業員を見る",
	MyTraining_Headerbar_CourseDetails: "コースの詳細",
	MyTraining_Headerbar_TrainingProgramDetails: "トレーニングプログラムの詳細",
	TrainingProgramDetails_Label_Status: "状態",
	TrainingProgramDetails_Label_OverallProgress: "全体の進捗状況",
	TrainingProgramDetails_ProgramItems_Headline: "プログラム項目",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "タイトル",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "状態",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "等級",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "日付",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "詳細",
	TrainingProgramDetails_Status_InProgress: "進行中",
	TrainingProgramDetails_Status_Completed: "完了",
	CourseDetails_Label_Status: "状態",
	CourseDetails_Label_Duration: "時間",
	CourseDetails_Label_Trainer: "トレーナー",
	CourseDetails_Label_Language: "言語",
	CourseDetails_Label_Score: "得点：",
	CourseDetails_Label_StartDate: "開始日",
	CourseDetails_Label_EndDate: "終了日",
	CourseDetails_Label_Venue: "開催場所",
	CourseDetails_Label_Address: "住所",
	CourseDetails_Label_Grade: "等級",
	CourseDetails_Action_Withdraw: "キャンセル",
	CourseDetails_Action_Evaluate: "評価",
	CourseDetails_Action_Evaluate_Description: "本コースを評価し、終了証を生成してください！",
	CourseDetails_Action_DownloadCertificate: "終了証をダウンロードする",
	CourseDetails_Action_JoinWebcast: "ウェブキャストへ参加する",
	CourseDetails_Action_StartCourse: "コースを開始する",
	CourseDetails_Action_RestartCourse: "コースを再開する",
	CourseDetails_Action_RepeatCourse_Description: "本コースを繰り返し受講し、合格すると終了証がダウンロードできます！",
	CourseDetails_PartSpecificDetails_Headline: "各パートの詳細",
	CourseDetails_PartSpecificDetails_Tab_Part: "パート",
	CourseDetails_PartSpecificDetails_Description: "概要",
	CourseDetails_PartSpecificDetails_StartDate: "開始日",
	CourseDetails_PartSpecificDetails_EndDate: "終了日",
	CourseDetails_PartSpecificDetails_StartTime: "開始時間",
	CourseDetails_PartSpecificDetails_EndTime: "終了時間",
	CourseDetails_PartSpecificDetails_Method: "方法",
	Dashboard_Team_TableHeader_Name: "氏名",
	Dashboard_Team_TableHeader_Role: "役職",
	Dashboard_Team_TableHeader_Email: "メールアドレス",
	Dashboard_Team_TableHeader_PersonalAccount: "個人アカウント",
	Dashboard_Team_ActionButton_Select: "選択",
	Dashboard_Team_PersonalAccount_Activated: "有効",
	Dashboard_Team_PersonalAccount_NotActivated: "無効",
	Dashboard_Team_ActionButton_AddMember: "メンバーを追加する",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "検索",
	Dashboard_External_TableHeader_Name: "氏名",
	Dashboard_External_ActionButton_AddNew: "新規追加",
	Dashboard_External_Headline: "外部からのアクセスを許可",
	Dashboard_Workshops_Headline: "ワークショップ",
	Dashboard_Workshops_TableHeader_Company: "会社名",
	Dashboard_Workshops_TableHeader_Address: "住所",
	Dashboard_Workshops_TableHeader_Postcode: "郵便番号",
	Dashboard_Workshops_TableHeader_City: "市",
	Dashboard_Workshops_ActionButton_Select: "選択",
	Wallet_ServiceCredits: "サービスクレジット",
	Wallet_Headline_CreditBalance: "クレジットバランス",
	Wallet_Headline_CreditActivity: "クレジットアクティビティ",
	Wallet_Headline_BillingTransactions: "請求書取引",
	Wallet_BillingTransaction_TableHeader_Order: "オーダー",
	Wallet_BillingTransaction_TableHeader_Date: "日付",
	Wallet_BillingTransaction_TableHeader_Amount: "数量",
	Wallet_BillingTransaction_TableHeader_Action: "実行",
	Wallet_CreditActivity_TableHeader_Date: "日付",
	Wallet_CreditActivity_TableHeader_Description: "概要",
	Wallet_CreditActivity_TableHeader_User: "ユーザー",
	Wallet_CreditActivity_TableHeader_Amount: "数量",
	FooterAdditionalLinks_Navigation_ProductSecurity: "製品セキュリティ（PSIRT）",
	FooterAdditionalLinks_Navigation_Patents: "ライセンスおよび特許",
	FooterAdditionalLinks_Navigation_Logistics: "購買およびロジスティクス",
	MenuHeader_MyTraining: "私のトレーニング",
	MenuHeader_TrainingServices: "トレーニングサービス",
	MenuHeader_OurLocations: "拠点",
	MenuHeader_ContactsUs: "お問い合わせ",
	MenuHeader_Logout: "ログアウト",
	MenuHeader_FAQ: "よくある質問",
	MyProfile_MyBoschId: "マイボッシュID",
	MyProfile_AccountDeletion: "アカウントの削除",
	MyProfile_Edit: "メールアドレス、パスワードの変更",
	MyProfile_Profile: "マイボッシュ",
	MyProfile_EditUserProfile: "ユーザープロフィールの変更",
	BackButton: "戻る",
	ContactUs_Phone_Description_V3: "お問い合わせはEメールおよび電話で受け付けております。どうぞお気軽にご連絡ください。",
	ContactUs_Email_SecurityCodeConfirm_V3: "上に示すように、正しい文字を入力してください。",
	ContactUs_Email_MandatoryNotice_V3: "必要事項をすべて入力してください。",
	ContactUs_Email_SendButton: "送信",
	Breadcrumb_TrainingCenters: "トレーニングセンター",
	Training_Centres_Book_Btn: "コースを予約する",
	Training_centres_booking_code: "予約コード",
	Training_centers_header_bar_title: "トレーニングセンター",
	Training_centers_filter_item: "センター",
	TeamEdit_PersonalAccountToolTop: "この機能を有効にすると、従業員はこのウェブサイトにアクセスできるようになります。ユーザーは、個人アカウントを作成し、企業アカウントの一部となることができます。一度有効にすると、アクティベーションコードを含む招待状を従業員に送ることができます。招待状は、eメールアドレスに送信されます。",
	TeamEdit_Update: "会社管理者機能を有効にすると、この従業員は自動的に会社アカウントにフルアクセスできるようになります。この機能を有効にするには、ユーザーがすでにログインしている場合、一度ログアウトしてウェブサイトに戻る必要があります。",
	TeamEdit_PersonalAccount: "BATSの個人用ウェブアカウントを許可する",
	TeamEdit_SendInvitation: "招待メールを送信",
	TeamEdit_Deactivate: "非アクティブ化",
	TeamEdit_Telephone: "電話",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "携帯電話番号は数字であること",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "電話番号は数字であること",
	TeamEdit_Activate: "有効",
	TeamEdit_NotActivated: "無効",
	TeamEdit_StatusPopup: "ステータスは、ユーザーが個人アカウントの作成に成功した場合、「アクティブ」と表示されます。ステータスが「非アクティブ」に設定されている場合は、ユーザーが招待を完了していないことを意味します。",
	Training_Catalog_Courses: "コース",
	Training_Catalog_Course: "コース",
	Reset_Filter: "フィルターのリセット",
	TrainingCatalogue_CourseCatalogue: "コースカタログ",
	CheckoutHeader_CartItemCount_Text: "ショッピングカートに[count]アイテムが入っています。",
	CheckoutHeader_CartItemsCount_Text: "ショッピングカートに[count]アイテムが入っています。",
	ShoppingCart_PriceItem: "アイテム価格",
	ShoppingCartItemParticipants_AddParticipant_Button: "参加者を追加",
	ShoppingCartTotalPrice_SubTotalLabel: "小計",
	ShoppingCartTotalPrice_TaxesLabel: "消費税",
	ShoppingCartTotalPrice_TotalLabel: "合計",
	ShoppingCartTotalPrice_IncludedVATLabel: "消費税[vat_amount]含む",
	AddVoucher_AvailablePaymentMethods: "利用可能なオンライン決済方法",
	ShoppingCartItemRow_VenueFeeLabel: "1名様あたりの会場費 [fee_amount]",
	ShoppingCartItemRow_OnlineLabel: "オンライン",
	AddParticipantsModal_Title: "参加者を追加",
	AddParticipantsModal_SelectEmployeeLabel: "参加者を追加",
	AddParticipantsModal_SelectWorkshopLabel: "ワークショップを選択",
	ShoppingCartTotalPrice_ContinueButtonText: "チェックアウトを続行",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "参加者が追加されていません。",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "ショッピングカートに1つ以上のコースがあり、参加者がいない状態です。",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "チェックアウトを続けるには、参加者を追加してください。",
	ShoppingCartItemParticipants_ShowMoreLinkText: "参加者を編集する ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "名前",
	AddParticipantsModal_ParticipantTable_Email: "eメール",
	AddParticipantsModal_ParticipantTable_Action: "アクション",
	AddParticipantsModal_ParticipantTable_NoParticipant: "参加者がいません...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "選択した参加者にeメールアドレスが設定されていません",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "選択された参加者は、プロフィールにeメールアドレスが設定されていません。トレーニングコースに登録するためには、メールアドレスが必須です。",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "メールアドレスを追加して続行するか、キャンセルして別の参加者を選択してください。",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "メールアドレスを追加する",
	CancelButton: "キャンセルする",
	AddParticipantsModal_AddEmployeeButtonText: "従業員を追加する",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "カートからコースを削除しようとしています。",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "参加者が追加されたコースをカートから削除しようとしています。",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "続行したい場合は「続行」を、キャンセルしたい場合は「キャンセル」をクリックしてください。",
	ShoppingCartItemHeader_Participants: "参加者数",
	ShoppingCartItemHeader_ParticipantPrice: "1席あたりの料金",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "[currency]で表示",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "クレジットで表示",
	ShoppingCart_CreditsPriceUnit: "クレジット",
	CheckOutHeader_Step1_Question: "お支払いはどうされますか？",
	CheckOutHeader_Step2_Question: "ご注文内容を確認する",
	CheckOutHeader_Step3_Question: "注文を完了する",
	CheckOutHeader_Step4_Question: "予約が完了しました。",
	CheckOutHeader_Step_Payment: "お支払い方法",
	CheckOutHeader_Step_Review: "レビュー",
	CheckOutHeader_Step_Place_Order: "注文する",
	CheckOutRightPanel_Review_Order_Btn: "注文を確認する",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "注文する",
	CheckoutRightPanelContent_OrderSummary_Title: "注文の概要",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "ショッピングカートを編集する",
	ShoppingCart_Participants: "参加者情報",
	CheckOutStepPayment_Payment_Methods_Title: "お支払い方法の選択",
	CheckOutStepPayment_Credit_Title: "クレジットまたはデビット",
	CheckOutStepPayment_Wholesaler_Title: "代理店",
	CheckOutStepPayment_ServiceCredit_Title: "サービスクレジット",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "あなたは[available_credit]クレジットをお持ちです。",
	CheckOutStepPayment_BoschAccount_Title: "ボッシュアカウント",
	Checkout_Second_PaymentMethod_Headline: "支払い方法",
	CheckOutStepReview_Edit: "編集する",
	CheckOutStepReview_Terms_Label: "利用規約とキャンセルポリシー",
	CheckOutStepReview_Terms_Text: "販売に関する[規約・条件・キャンセルポリシー]を読み、理解し、これに同意します。",
	SelectSecondPaymentMethodModal_Title: "二次支払方法を選択する",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "[amount]を支払う",
	StepPaymentWholesaler_Title: "代理店を選択する",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "利用規約を承認する",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "販売条件とキャンセルポリシーをお読みになり、同意の上、次へお進みください。",
	CheckoutDescription_ThankYou: "BATSをご利用くださり、ありがとうございます。",
	CheckoutDescription_Message: "ご注文の処理を開始しています。しばらくお待ちください。",
	CheckoutDesctiption_Text: "ご予約の詳細を含む最終確認書をメールでお送りします。",
	WorkshopSelection_RemoveWorkshopWarning_Title: "選択したワークショップを削除しようとしています",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "選択したワークショップを削除すると、追加された参加者はすべてリセットされます。この変更は、ショッピングカートにあるすべてのコースに影響します。",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "続行する場合は「続行」を、中止する場合は「キャンセル」をクリックしてください。",
	CheckOutStepPayment_Insufficient_Credit_Message: "クレジットが不足しています。お客様のクレジット残高は[available_credit]クレジットですが、チェックアウトを続けるには[cart_total_credits]クレジットが必要です。他の支払い方法を選択するか、ショッピングカートから商品を取り除いてください。",
	Training_centers_filter_items: "センター",
	Training_Programs_Filter_Item: "プログラム",
	Training_Program_Filter_Item: "プログラム",
	Training_Program_Year: "年",
	Training_Program_Detail_Title: "トレーニングコースプログラム",
	AccountButton_Workshops_MenuItem: "ワークショップ",
	AccountButton_Wallet_MenuItem: "ウォレット",
	Header_MyBoschProfile_Header: "マイボッシュ プロフィール",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "利用可能",
	AddParticipantsModal_AddOrEditParticipantsTitle: "参加者の追加・編集",
	ShoppingCart_CreditPriceUnit: "クレジット",
	Common_IncludedVAT: "消費税込み",
	TrainingCatalogue_Length: "所要時間",
	TrainingCatalogue_Method: "方法",
	Course_Price_Credits: "単位数",
	Catalogue_Grid_Register_Button: "登録",
	Catalogue_Credit_Tilte: "クレジットの価格はこちらをご覧ください",
	Catalogue_Price_Tilte: "EURでの価格はこちらをご覧ください",
	Catalogue_Grid_Vat: "消費税別",
	EventCalendar_Seats: "座席数",
	MenuHeader_Calendar: "カレンダー",
	MenuHeader_Catalog: "カタログ",
	TableCell_Minutes: "分",
	ListFilter_NoResult: "結果なし",
	Course_Description: "コース内容",
	Training_course_detail: "ソーシャルメディア基礎",
	Training_course_detail_add_waiting: "一般キャンセル待ちリストに追加",
	Register_now_btn: "今すぐ登録する！",
	Register_now_btn_add_event_to_cart: "今すぐ登録する ！",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "すべての記録を見る",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "もっと見る",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "すべてのレコードを表示する",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "以下を表示する",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "すべてのレコードを表示する",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "検索結果を表示する",
	TeamCreate_TitleError: "タイトルが必要です",
	Dashboard_Headerbar_BookCourse: "コースを予約する",
	Dashboard_Headerbar_ExitProxy: "プロキシを終了する",
	Dashboard_Headerbar_EvaluateCourse: "コースを評価する",
	Dashboard_Headerbar_EvaluateCourse_Note: "このコースを評価し、終了証を発行してください！",
	Dashboard_Headerbar_DownloadCertificate: "終了証ダウンロード",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "キャンセル",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "キャンセル",
	Dashboard_Headerbar_WelcomeMessage: "学習ダッシュボードへようこそ",
	Dashboard_Headerbar_ProxingAs: "プロキシング",
	Dashboard_TrainingProgram_Status_Completed: "完了",
	Dashboard_TrainingProgram_Status_InProgress: "進行中",
	External_SelectWholesalerModal_Title: "外部ユーザーのアクセス権を追加する",
	External_SelectWholesalerModal_Company_Label: "会社",
	External_SelectWholesalerModal_Outlet_Label: "アウトレット",
	External_SelectWholesalerModal_Company_Placeholder: "選択してください",
	External_SelectWholesalerModal_Outlet_Placeholder: "選択してください",
	External_SelectWholesalerModal_ApplyButton: "適用",
	External_SelectWholesalerModal_CancelButton: "キャンセル",
	External_RemoveWholesalerModal_ConfirmLabel: "確認",
	External_SelectWholesalerModal_ConfirmMessage: "この代理店を削除してよろしいですか。",
	External_SelectWholesalerModal_YesButton: "はい",
	External_SelectWholesalerModal_NoButton: "いいえ",
	Training_program_team_member: "チームメンバー",
	Training_program_assign_training_program: "トレーニングの割り当て",
	Assign_modal_student_confirmation_text1: "[Training Program Name] を割り当てます。",
	Assign_modal_student_confirmation_text2: "続けますか？",
	MyFullCalendar_Jan: "1月",
	MyFullCalendar_Feb: "2月",
	MyFullCalendar_Mar: "3月",
	MyFullCalendar_Apr: "4月",
	MyFullCalendar_May: "5月",
	MyFullCalendar_Jun: "6月",
	MyFullCalendar_Jul: "7月",
	MyFullCalendar_Aug: "8月",
	MyFullCalendar_Sep: "9月",
	MyFullCalendar_Oct: "10月",
	MyFullCalendar_Nov: "11月",
	MyFullCalendar_Dec: "12月",
	MenuHeader_CourseCatalog: "コースカタログ",
	MenuHeader_Event: "イベントカレンダー",
	MenuHeader_Program: "トレーニングプログラム",
	MenuHeader_Services: "トレーニングサービス",
	MenuHeader_Log_out: "ログアウト",
	Filter_Location: "拠点",
	TeamEdit_UpgradeToCompanyAdministrator: "管理者へアップグレード",
	TeamEdit_PersonalWebAccountStatusIs: "個人ウェブアカウントの状態は",
	Header_CreateNewEmployee: "従業員を追加する",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "空席なし",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "選択したイベントに空席はありません。",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "他のイベントを選択するか、既に追加された参加者を削除して続行してください。",
	Common_SuccessModal_Title: "おめでとうございます。",
	TrainingProgramDetails_Merit_StatusText: "優秀",
	TrainingProgramDetails_Resit_StatusText: "再試験",
	EmployeeProfile_UserExistErrorMessage: "アカウントは既に存在します。",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "お支払いに失敗しました。再度お試しいただくか、別のお支払い方法をご利用ください。",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "選択された従業員の無効化が完了しました。",
	EmployeeProfile_ProfileUpdateSuccess_Title: "保存されました。",
	EmployeeProfile_ProfileUpdateSuccess_Message: "変更が保存されました。",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "eラーニングコースはキャンセルできません。詳細が必要な場合はお問い合わせください。",
	Dashboard_Team_PersonalAccount_Pending: "保留中",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "従業員が追加されました。",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "従業員の追加は正常に完了しました。",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "この設定により、従業員の研修イベントへの登録を制限することができます。この設定が選択されていない場合、会社管理者のみが従業員をトレーニングイベントに登録することができます。",
	Training_catalog_sort_by: "並べ替え",
	CourseDetail_EvaluationRestriction_Title: "トレーニングの評価はできません！",
	CourseDetail_EvaluationRestriction_Message: "トレーニング評価は受講者本人のみが提出できます。受講者がボッシュ・オートモーティブ・トレーニング・ソリューションにログインし、トレーニング評価を完了するよう指示してください。",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "受講者がまだアカウントを持っていない場合は、チームメンバーのユーザープロファイル設定で招待状を共有することができます。",
	Breadcrumb_Download_Page: "ダウンロード",
	Download_Table_Category_Cell: "カテゴリー",
	Download_Table_Title_Cell: "タイトル",
	Download_File_Filter_Item: "ファイル",
	Download_File_Filter_Items: "ファイル",
	Download_Header: "ダウンロードエリア",
	DownloadArea_Button_Download: "ダウンロード",
	Popup_Title_Error: "エラー",
	Popup_Title_Success: "成功",
	Popup_Title_Info: "インフォメーション",
	Popup_Title_Warning: "警告！",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "以下のイベント登録はすでに存在しており、トレーニングプログラムの撤回によって自動的にキャンセルされることはありません。個別にキャンセルしてください。",
	TrainingProgram_PDF_Export: "PDFで出力",
	TrainingProgram_PDF_Export_Participant: "参加者：",
	TrainingProgram_PDF_Export_Status: "状態：",
	TrainingProgram_PDF_Export_Progress: "全体の進捗：",
	TrainingProgram_PDF_Export_TableHeader_Title: "トレーニングタイトル",
	TrainingProgram_PDF_Export_TableHeader_Status: "状態",
	TrainingProgram_PDF_Export_TableHeader_Grade: "評価",
	TrainingProgram_PDF_Export_TableHeader_Date: "日付",
	AccountButton_CompanyChange_MenuItem: "会社変更",
	SelectCompanyModal_Title: "会社選択",
	CloseButton: "閉じる",
	Change_Button: "変更",
	ChangeManagerModal_Content: "ユーザー選択",
	ChangeCompanyModal_HeadOffice: "(本社)",
	SelectCompanyModal_Content: "ログインする会社を選択・・・",
	AlertSuccess_Title: "成功",
	CheckOutStepPayment_SecondaryPayment_Title: "別の支払い方法を選択",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "別の支払い方法を追加",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "下に2番目の支払方法を追加するか、上で別の支払方法を選択してください。",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "カートの商品代金を支払うには、アカウント内のサービスクレジットが不足しています。選択した支払い方法を継続して使用するには、サービスクレジットを追加取得するか、追加の支払い方法を使用して差額を支払う必要があります。差額は[difference_amount]（税抜）です。",
	ShoppingCartTotalPrice_TotalInCredit: "クレジット総額",
	ShoppingCartTotalPrice_YourAvaliableCredit: "利用可能クレジット",
	ShoppingCartTotalPrice_YourDifference: "差額",
	ShoppingCartTotalPrice_SubtotalExTax: "税抜き小計",
	ShoppingCartTotalPrice_SubtotalInTax: "税込小計",
	CheckOutStepPayment_Second_Payment_Methods_Title: "別の支払方法",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "支払い方法の編集",
	Checkout_Second_PaymentMethod: "別の支払方法",
	Breadcrumb_TrainingCourseDetails: "トレーニングコースの詳細",
	DownloadArea_FailedToDownload_ErrorMessage: "書類のダウンロードに失敗しました。再度お試しいただくか、会社管理者までお問い合わせください。",
	DownloadArea_FileNotAvailable_ErrorMessage: "ダウンロードファイルがありません。再試行するか、会社管理者にお問い合わせください。",
	DownloadArea_FileAccessForbidden_ErrorMessage: "ファイルのダウンロードが禁止されています。再試行するか、会社管理者にお問い合わせください。",
	SSO_Redirecting_Message: "ボッシュ・オートモーティブ・トレーニング・ソリューションにリダイレクトします。",
	SSO_Redirecting_ParameterErrorMessage: "国または言語が指定されていません。再試行するか、会社管理者にお問い合わせください。",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "登録申請が受理されません。",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "これは、1つまたは複数のコースの登録基準日数内であることが原因である可能性があります。ご質問やご要望がある場合は、「お問い合わせ」エリアをご利用ください。",
	TimeSlotPicker_DateFormat_Title: "日付フォーマット",
	UpdateAccountSettingsConfirmationModal_Title: "日付フォーマットを変更しようとしています！",
	UpdateAccountSettingsConfirmationModal_Message1: "日付形式は選択した内容に従って表示され、いつでも変更することができます。",
	UpdateAccountSettingsConfirmationModal_Message2: "このまま続けますか？",
	ProfileSummary_Settings_Title: "設定",
	UpdateAccountSettings_DateFormat_Title: "日付フォーマットの設定",
	UpdateAccountSettings_DateFormat_ErrorMessage: "日付フォーマットの更新に失敗しました。",
	UpdateAccountSettings_DateFormat_SuccessMessage: "日付フォーマットの設定が正常に更新されました。",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "修了証ダウンロード",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "研修プログラム修了証",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "ダウンロードに失敗しました。もう一度お試しください。問題が解決しない場合は、お問い合わせフォームからご連絡ください。",
	CourseWithdrawalModal_PendingWithdraw_Title: "キャンセルの基準を満たしていません。",
	CourseWithdrawalModal_PendingWithdraw_Message1: "このコースをキャンセルするための基準を満たしていないため、トレーニング事務局による手動処理が必要です。",
	CourseWithdrawalModal_PendingWithdraw_Message2: "最終確認はEメールにて行われます。",
	CourseWithdrawalModal_PendingWithdraw_Message3: "詳しくは「キャンセルポリシー」をご覧ください。",
	TrainingCourse_Course_NotAvailable_Title: "トレーニングコースは利用できません",
	TrainingCourse_Course_NotAvailable_Message: "トレーニングコースはご利用いただけません。もう一度お試しいただくか、会社管理者までお問い合わせください。",
	TrainingCourse_NotAvailable_Message_1: "利用できないトレーニングコースを閲覧しようとしています。",
	TrainingCourse_NotAvailable_Message_2: "利用可能なすべてのコースを検索するには、[トレーニングカタログ]に戻ってください。",
	LanguageSelection_SelectALanguage: "言語選択",
	LanguageSelection_SelectYourCountry: "国選択",
	Checkout_PaymentMethod_Headline: "お支払い方法",
	Download_Categories_Catalogue: "カタログ",
	Download_Categories_Calendar: "カレンダー",
	Download_Categories_General: "一般情報",
	Download_Categories_Information: "インフォメーション",
	Download_Categories_Userguides: "ユーザーガイド",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "キャンセルポリシー",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "キャンセル",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "続ける",
	Duration_Seconds: "秒",
	Duration_Day: "日",
	Duration_Hour: "時",
	Duration_Minute: "分",
	Duration_Second: "秒",
	EventCalendar_ExternalLink_Tooltips: "[externalRegistrationName]経由での購入",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "キャンセル",
	EventCalendar_ExternalLink_Continue: "続ける",
	EventCalendar_ExternalLinkModal_Title: "リダイレクトされます...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "選択されたイベントは[externalRegistrationName]経由で販売されています。",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "続行すると外部のウェブサイトにリダイレクトされます。",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "続行しますか？",
	Team_ContactPerson_FirstName_Tooltips: "受講者名の変更は、ボッシュ・トレーニング事務局でのみ可能です。当サイトのお問い合わせフォームよりご連絡ください。",
	Team_ContactPerson_LastName_Tooltips: "受講者名の変更はトレーニング事務局のみ可能です。当サイトのお問い合わせフォームよりご連絡ください。",
	Withdraw_ExternalLink_Cancel: "キャンセル",
	Withdraw_ExternalLink_Confirm: "確認する",
	Withdraw_ExternalLinkModal_Title: "リダイレクトされます",
	Withdraw_ExternalLinkModal_Message: "このコースのキャンセル手続きは外部プロバイダーが行います。",
	Withdraw_ExternalLinkModal_Confirmation: "「確認」をクリックすると、新しいタブが開き、外部サイトへ移動します。",
	Button_AddToWaitingList_Tooltips: "ウェイティングリストに追加",
	Button_AddToCart_Tooltips: "カートに追加",
	Button_TrainingCourseDetail_Tooltips: "詳細",
	Button_TrainingProgramDetail_Tooltips: "詳細",
	Button_AssignTrainingProgram_Tooltips: "トレーニングの割り当て",
	Button_DeleteFromCart_Tooltips: "カートから削除",
	Button_RemoveParticipant_Tooltips: "参加者を削除",
	Button_DownloadBillingTransaction_Tooltips: "ダウンロード",
	Button_RemoveExternal_Tooltips: "削除"
};