import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";
import { TrainingProgram } from "../models/TrainingEvent";
import {
  RequestAddToTrainingPrograms,
  RequestGetTrainingProgramDetail,
  RequestGetTrainingPrograms,
  RequestGetTrainingProgramsByStudent,
  RequestGetTrainingProgramsSimple,
} from "../models/trainingProgram/request.getTrainingPrograms.model";
import { http } from "../services/HttpService";
import { httpNoneSecure } from "../services/HttpServiceNoneSecure";
import { AppDispatch, RootState } from "./store";
import { TrainingProgramCertificate } from "../models/trainingProgram/TrainingProgramCertificate";
import { RequestGetTrainingProgramCertificateModel } from "../models/trainingProgram/request.getTrainingProgramCertificate.model";
import { RequestGetTrainingProgramProgressReportModel } from "../models/trainingProgram/request.getTrainingProgramProgressReport.model";
import { TrainingProgramProgressReport } from "../models/trainingProgram/TrainingProgramProgressReport";

export const getTrainingProgramAsync = createAsyncThunk<
  TrainingProgram[] | null,
  RequestGetTrainingPrograms,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingPrograms/getTrainingPrograms",
  async (requestModel: RequestGetTrainingPrograms, thunkApi) => {
    try {
      const model = {
        country: requestModel.country,
        ciamEmail: requestModel.ciamEmail,
        ciamId: requestModel.ciamId,
        customerId: requestModel.customerId,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
      };
      let reponse = await httpNoneSecure.post<
        ResponseResultBase<TrainingProgram[]>
      >(`api/v1/TrainingPrograms/GetTrainingPrograms`, model);
      return reponse.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const addToTrainingProgramAsync = createAsyncThunk<
  ResponseResultBase<number> | null,
  RequestAddToTrainingPrograms,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "programs/AddTrainingProgram",
  async (req: RequestAddToTrainingPrograms, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `api/v1/TrainingPrograms/AssignStudentToTrainingProgram`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const withdrawStudentFromProgramAsync = createAsyncThunk<
  ResponseResultBase<number> | null,
  RequestAddToTrainingPrograms,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "programs/withdrawStudentFromProgram",
  async (req: RequestAddToTrainingPrograms, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `/api/v1/TrainingPrograms/WidthdrawStudentFromTrainingProgram`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getAssignedTrainingProgramsByStudentAsync = createAsyncThunk<
  TrainingProgram[] | null,
  RequestGetTrainingProgramsByStudent,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "programs/getAssignedTrainingProgramByStudent",
  async (requestModel: RequestGetTrainingProgramsByStudent, thunkApi) => {
    try {
      const model = {
        country: requestModel.country,
        ciamEmail: requestModel.ciamEmail,
        ciamId: requestModel.ciamId,
        customerId: requestModel.customerId,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
        studentId: requestModel.studentId,
      };
      let reponse = await http.post<ResponseResultBase<TrainingProgram[]>>(
        `/api/v1/TrainingPrograms/GetAssignedTrainingProgramByStudent`,
        model
      );
      return reponse.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingProgramCertificateAsync = createAsyncThunk<
  ResponseResultBase<TrainingProgramCertificate> | null,
  RequestGetTrainingProgramCertificateModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "programs/getTrainingProgramCertificate",
  async (req: RequestGetTrainingProgramCertificateModel, thunkApi) => {
    try {
      const model = {
        ciamId: req.ciamId,
        ciamEmail: req.ciamEmail,
        trainingProgramMembersId: req.trainingProgramMembersId,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
        timeZoneId: req.timeZoneId,
      };
      let response = await http.post<
        ResponseResultBase<TrainingProgramCertificate>
      >(`/api/v1/TrainingPrograms/GetTrainingProgramCertificate`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingProgramProgressReportAsync = createAsyncThunk<
  ResponseResultBase<TrainingProgramProgressReport> | null,
  RequestGetTrainingProgramProgressReportModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "programs/getTrainingProgramProgressReport",
  async (req: RequestGetTrainingProgramProgressReportModel, thunkApi) => {
    try {
      const model = {
        ciamId: req.ciamId,
        ciamEmail: req.ciamEmail,
        trainingProgramMembersId: req.trainingProgramMembersId,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
        timeZoneId: req.timeZoneId,
      };
      let response = await http.post<
        ResponseResultBase<TrainingProgramProgressReport>
      >(`/api/v1/TrainingPrograms/getTrainingProgramProgressReport`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingProgramsSimpleAsync = createAsyncThunk<
  ResponseResultBase<TrainingProgram[]> | null,
  RequestGetTrainingProgramsSimple,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingPrograms/getTrainingProgramsSimple",
  async (requestModel: RequestGetTrainingProgramsSimple, thunkApi) => {
    try {
      const model = {
        ciamId: requestModel.ciamId,
        ciamEmail: requestModel.ciamEmail,
        country: requestModel.country,
        customerId: requestModel.customerId,
        salesRegion: requestModel.salesRegion,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
      };
      let response = await httpNoneSecure.post<
        ResponseResultBase<TrainingProgram[]>
      >(`/api/v1/TrainingPrograms/getTrainingProgramsSimple`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingProgramDetailAsync = createAsyncThunk<
  ResponseResultBase<TrainingProgram> | null,
  RequestGetTrainingProgramDetail,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingPrograms/getTrainingProgramDetail",
  async (requestModel: RequestGetTrainingProgramDetail, thunkApi) => {
    try {
      const model = {
        ciamId: requestModel.ciamId,
        ciamEmail: requestModel.ciamEmail,
        country: requestModel.country,
        customerId: requestModel.customerId,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
        programId: requestModel.programId,
        salesRegion: requestModel.salesRegion,
      };
      let response = await httpNoneSecure.post<
        ResponseResultBase<TrainingProgram>
      >(`/api/v1/TrainingPrograms/getTrainingProgramDetail`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

interface ProgramState {
  program: TrainingProgram | undefined;
  assignedTrainingPrograms: TrainingProgram[] | null;
  trainingPrograms: TrainingProgram[] | null;
  isForceReloadTrainingPrograms: boolean;
  isForceReloadAssignedTrainingPrograms: boolean;
  trainingProgramDetail: TrainingProgram | null;
  isTrainingProgramsLoaded: boolean;
}

const initialState: ProgramState = {
  program: undefined,
  assignedTrainingPrograms: null,
  trainingPrograms: null,
  isForceReloadTrainingPrograms: true,
  isForceReloadAssignedTrainingPrograms: true,
  trainingProgramDetail: null,
  isTrainingProgramsLoaded: false,
};

export const programSlice = createSlice({
  name: "trainingProgramReducer",
  initialState,
  reducers: {
    setTrainingProgram: (state, action) => {
      return {
        ...state,
        program: action.payload,
      };
    },
    setTrainingProgramDetail: (state, action) => {
      return {
        ...state,
        trainingProgramDetail: action.payload,
      };
    },
    setAssignedTrainingPrograms: (state, action) => {
      return {
        ...state,
        assignedTrainingPrograms: action.payload,
      };
    },
    setIsForceReloadTrainingPrograms: (state, action) => {
      return {
        ...state,
        isForceReloadTrainingPrograms: action?.payload || false,
      };
    },
    setIsTrainingProgramsLoaded: (state, action) => {
      return {
        ...state,
        isTrainingProgramsLoaded: action?.payload || false,
      };
    },
    setIsForceReloadAssignedTrainingPrograms: (state, action) => {
      return {
        ...state,
        isForceReloadAssignedTrainingPrograms: action?.payload || false,
      };
    },
    resetTrainingPrograms: (state, action: any) => {
      return {
        ...state,
        trainingPrograms: null,
        isForceReloadTrainingPrograms: true,
      };
    },
    resetAssignedTrainingPrograms: (state, action: any) => {
      return {
        ...state,
        assignedTrainingPrograms: null,
        isForceReloadAssignedTrainingPrograms: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAssignedTrainingProgramsByStudentAsync.fulfilled,
        (state, { payload }) => {
          if (payload !== null) {
            return {
              ...state,
              assignedTrainingPrograms: payload,
              isForceReloadAssignedTrainingPrograms: false,
            };
          } else {
            return {
              ...state,
              assignedTrainingPrograms: null,
            };
          }
        }
      )
      .addCase(
        getTrainingProgramsSimpleAsync.fulfilled,
        (state, { payload }) => {
          if (payload?.dataObject !== null && payload?.httpStatusCode === 200) {
            const trainingEvents = payload?.dataObject;
            return {
              ...state,
              trainingPrograms: trainingEvents,
              isForceReloadTrainingPrograms: false,
              isTrainingProgramsLoaded: true,
            };
          } else {
            return {
              ...state,
              trainingPrograms: null,
            };
          }
        }
      )
      .addCase(
        getTrainingProgramDetailAsync.fulfilled,
        (state, { payload }) => {
          if (payload?.dataObject !== null && payload?.httpStatusCode === 200) {
            const trainingProgram = payload?.dataObject;
            return {
              ...state,
              trainingProgramDetail: trainingProgram,
            };
          } else {
            return {
              ...state,
              trainingProgramDetail: null,
            };
          }
        }
      )
      .addCase(getTrainingProgramAsync.fulfilled, (state, { payload }) => {
        if (payload !== null) {
          return {
            ...state,
            trainingPrograms: payload,
            isForceReloadTrainingPrograms: false,
          };
        } else {
          return {
            ...state,
            trainingPrograms: null,
          };
        }
      });
  },
});

export const selectProgram = (rootState: RootState) =>
  rootState.trainingProgramReducer.program;

export const selectAssignedTrainingPrograms = (rootState: RootState) =>
  rootState.trainingProgramReducer.assignedTrainingPrograms;

export const selectTrainingPrograms = (rootState: RootState) =>
  rootState.trainingProgramReducer.trainingPrograms;

export const selectIsForceReloadTrainingPrograms = (rootState: RootState) =>
  rootState.trainingProgramReducer.isForceReloadTrainingPrograms;

export const selectIsForceReloadAssignedTrainingPrograms = (
  rootState: RootState
) => rootState.trainingProgramReducer.isForceReloadAssignedTrainingPrograms;

export const selectTrainingProgramDetail = (rootState: RootState) =>
  rootState.trainingProgramReducer.trainingProgramDetail;

export const selectIsTrainingProgramsLoaded = (rootState: RootState) =>
  rootState.trainingProgramReducer.isTrainingProgramsLoaded;

export const {
  setTrainingProgram,
  setTrainingProgramDetail,
  resetTrainingPrograms,
  setIsForceReloadTrainingPrograms,
  setIsForceReloadAssignedTrainingPrograms,
  setIsTrainingProgramsLoaded,
  resetAssignedTrainingPrograms,
} = programSlice.actions;
export default programSlice.reducer;
