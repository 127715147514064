export const TRANSLATIONS_SL = {
	LandingPage_Title: "Dobrodošli na Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Izberite svojo regijo/državo",
	Header_ContactUs: "Kontaktirajte nas",
	Header_Search: "Iskanje",
	Header_Menu: "Meni",
	Header_LogInOrCreateYourAccount: "Prijavite se ali ustvarite svoj račun",
	Header_LogIn: "Prijava",
	Header_Cart: "Voziček",
	Header_ShoppingCart: "Nakupovalni voziček",
	Header_LogOut: "Odjava",
	Header_ChangeDetails: "Spremenite podrobnosti",
	Footer_ExploreOurTraining: "Odkrijte naše usposabljanje",
	Footer_TrainingAtYourFingertips: "Usposabljanje na dosegu vaših rok",
	Footer_DontMissAnyStory: "Ne zamudite nobene zgodbe",
	Footer_ExploreOurStories: "Odkrijte naše zgodbe",
	Footer_GetInTouchWithUs: "Stopite v stik z nami",
	Footer_SendUsAMessage: "Pošljite nam sporočilo",
	Footer_ToContactForm: "Na kontaktni obrazec",
	Footer_InventedForLife: "Tehnika za življenje",
	Footer_GeneralContactInfo: "Splošne kontaktne informacije",
	Footer_ProductSecurity_Psirt: "Varnost izdelkov (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Inovacijski patenti in licence",
	Footer_PurchasingLogistics: "Nabava in logistika",
	Footer_CorporateInformation: "Informacije o družbi",
	Footer_LegalNotice: "Pravno obvestilo",
	Footer_DataProtectionNotice: "Obvestilo o varstvu podatkov",
	Footer_PrivacySettings: "Nastavitve zasebnosti",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch, vse pravice pridržane",
	Footer_StillLookingForSomething: "Ali vas zanima še kaj",
	Footer_TrainingServices: "Storitve usposabljanja",
	Footer_Courses: "Tečaji",
	Footer_Programs: "Programi",
	Footer_EventsCalendar: "Koledar dogodkov",
	Footer_TrainingCenters: "Centri usposabljanja",
	Footer_Dashboard: "Nadzorna plošča",
	Footer_MyDashboard: "Moja nadzorna plošča",
	Footer_ShoppingCart: "Nakupovalni voziček",
	Footer_YourShoppingCart: "Vaš nakupovalni voziček",
	Footer_Administration: "Administracija",
	Footer_Employees: "Zaposleni",
	Footer_Wholesalers: "Trgovci na debelo",
	Footer_Transactions: "Transakcije",
	Footer_Workshops: "Delavnice",
	Footer_WorkLikeABoschApplyNow: "Delajte  – Prijavite se zdaj.",
	Footer_BoschGlobalCareers: "Bosch Global Careers",
	Footer_PurchasingAndLogistics: "Nabava in logistika",
	Footer_BecomeABusinessPartner: "Postanite poslovni partner.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Kako se naše usposabljanje razvija za pomoč vašemu podjetju",
	Footer_Language: "Jezik",
	MenuPopup_MyCart: "Moj voziček",
	Stories_OurTrainingStories: "Naše zgodbe z usposabljanj",
	Stories_ConnectingExperts: "Povezujemo strokovnjake in navdušence, sanjače in ustvarjalce, inovatorje in vplivneže – raziskujte in doživljajte",
	Stories_TopStories: "Najboljše zgodbe",
	Stories_AtHome: "Doma",
	Stories_Careers: "Kariere",
	Stories_History: "Zgodovina",
	Stories_IndustryAndTrades: "Industrija in obrt",
	Stories_Mobility: "Mobilnost",
	Stories_Research: "Raziskave",
	Stories_Sustainability: "Trajnost",
	Stories_AllStories: "Vse zgodbe",
	Stories_BlogATrainersDay: "BLOG | Dan trenerja",
	Stories_ADayinLifeOfATrainer: "Dan v življenju trenerja",
	Stories_BoschServiceTraining: "Boschevo usposabljanje serviserjev",
	Stories_StoryWhatDrivesYouDrivesUs: "ZGODBA | Kar poganja vas, poganja tudi nas",
	Stories_StoryHowCanWeBenefitYourBusiness: "ZGODBA | Kako lahko koristimo vašemu podjetju",
	Stories_HowOurTrainingEvolves: "Kako se razvija naše usposabljanje",
	Stories_AModernTrainingApproach: "Svet spletnega usposabljanja",
	Stories_StoryAVirtualOnlineTrainingWorld: "ZGODBA | Vaše možnosti spletnega usposabljanja",
	Stories_SubscribeToRSSFeed: "Naročite se na novice RSS",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "O nas",
	Homepage_AutomotiveAftermarket: "Avtomobilski poprodajni trg",
	Homepage_GetInTouch: "Stopite v stik z nami",
	Homepage_LatestNews: "Preberite, zakaj nas poganja želja po znanju.",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Delovna mesta, ki so na voljo pri nas",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Kar poganja vas, poganja tudi nas.",
	BoschServiceTraining_BoschServiceTraining: "Boschevo usposabljanje serviserjev",
	BoschServiceTraining_ShareThisOn: "Deli to na",
	BoschServiceTraining_TheChallenge: "Izziv: Z obvladovanjem novih tehnologij",
	BoschServiceTraining_WorldwidePresence: "Prisotnost po vsem svetu",
	BoschServiceTraining_TechnicalTraining: "Boschevi tečaji tehničnega usposabljanja",
	BoschServiceTraining_TheOffersAndOpportunities: "Ponudbe in priložnosti, ki jih ponujamo v podjetju Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Za vsako ciljno skupino so na voljo primerni tečaji usposabljanja – tako za novince kot za strokovnjake. Ponudba vključuje enodnevne in večdnevne tečaje usposabljanja. Tečaji usposabljanja so na voljo na različnih tehničnih področjih in kot izobraževalne serije (na primer električni sistemi za vbrizgavanje bencina, vbrizgavanje dizelskega goriva). Na tečajih tehničnega usposabljanja Bosch posreduje obsežno znanje o vseh standardnih sistemih za vozila podjetja Bosch, na tečajih usposabljanja pa se obravnavajo tudi sistemi drugih proizvajalcev. Vsebina je posebej prilagojena zahtevam zaposlenih v delavnicah.",
	BoschServiceTraining_TrainedEmployeesDesc: "Usposobljeni zaposleni so očitna prednost avtomobilskih delavnic. Ker današnja vozila postajajo vse bolj kompleksna in nove tehnologije nenehno predstavljajo nove izzive. Samo potrebno strokovno znanje zaposlenim v delavnici omogoča, da izvedejo diagnostiko, vzdrževanje in popravilo na sedanjih modelih vozil učinkovito in stroškovno učinkovito.",
	BoschServiceTraining_RegardlessOfHow: "Ne glede na to kako dobri in izkušeni so avtomobilski strokovnjaki – vedno obstajajo situacije, v katerih bodo prišli v stik z novimi sistemi tehnologije vozil ali neznanimi napakami. V tem pogledu lahko iskanje in odpravljanje težav postane še posebno časovno zamudno ali pa morda ne da uspešnih rezultatov. V najslabšem primeru bodo stranke morale na svoje vozilo čakati veliko dlje od pričakovanj.",
	BoschServiceTraining_ItIsPrecisely: "Prav na tej točki Boschevo usposabljanje za serviserje: izboljša kakovost storitev in pospeši postopke. Boschevo sedanje usposabljanje zagotavlja podporo pri hitrejši lokalizaciji težav, omogoča popravila več vozil hkrati in krajša čakalne dobe vaših strank. Tako sistematični postopki, ki se jih naučite pri Boschu, zagotavljajo hitrejše in produktivnejše delo.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Bosch je že več kot 125 let prednostni dobavitelj originalnih delov opreme, sestavnih delov in sistemov za mnogo vodilnih proizvajalcev avtomobilov po vsem svetu. Ta izkušnja vsebuje edinstveno znanje o najnovejših tehnologijah, ki je podlaga za Boschevo podporo.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Boscheve servisne centre za usposabljanje lahko najdemo povsod po svetu: od Španije do Turčije, od Južne Afrike in Avstralije do Brazilije – Bosch svojim zaposlenim ponuja praktična usposabljanja, ki so vedno v skladu z najsodobnejšimi tehnologijami.",
	BoschServiceTraining_AgileCompany: "Agilno podjetje",
	BoschServiceTraining_Collaboration: "Sodelovanje",
	BoschServiceTraining_ConnectedMobility: "Povezana mobilnost",
	BoschServiceTraining_ArtificialIntelligence: "Umetna inteligenca",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Dialog z vodilnimi misleci",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostika in testna oprema",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Osnove avtomobilske tehnologije",
	BoschServiceTraining_Icon_Gasoline: "Bencin",
	BoschServiceTraining_Icon_Diesel: "Dizel",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternativni pogoni",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektrika in elektronika",
	BoschServiceTraining_Icon_BodyAndComfort: "Karoserija in udobje",
	BoschServiceTraining_Icon_ChassisAndBraking: "Šasija in zaviranje",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Prenos in pogon",
	BoschServiceTraining_Icon_CommercialVehicles: "Gospodarska vozila",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Tečaji usposabljanja na spletu",
	BoschServiceTraining_Icon_BusinessSkills: "Poslovne veščine",
	BoschServiceTraining_Icon_Accreditations: "Akreditacije",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programi tečajev usposabljanja",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrenerImePriimek",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Dan v življenju trenerja",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Vsak dan je pustolovščina",
	ADayInTheLifeOfATrainer_Summary: "Povzetek",
	ADayInTheLifeOfATrainer_TheDayJourney: "Dnevno potovanje",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Vsak dan je naporen, vendar je to vedno nagrajujoča izkušnja. Usposabljanje učencev z zavedanjem, da imajo v rokah ključ do naše prihodnosti, je velika odgovornost, hkrati pa je vznemirljivo. Vsakodnevna in tedenska rast učencev pri vsaki učni uri nam dokazuje, da si prizadevamo za skupen cilj – izboljšati njihovo znanje in hkrati vključiti sodobno tehnologijo, ki je gonilo prihodnosti!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Ob koncu vsakega dneva se usedemo in razmislimo o delu ta dan. Dnevi minevajo tako hitro, vendar je to le dokaz, da to, kar dosegamo, ni koristno samo za učence, ampak tudi za nas kot trenerje.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Ob koncu dneva se lahko vrnemo domov zadovoljni, da smo delo odlično opravili!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Začetek",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Priprava in končno preverjanje gradiva za usposabljanje. Preverite pomembna e-poštna sporočila",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Usposabljanje se začne",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Dobrodošlica udeležencem. Predstavitev programa. Spoznajte skupino z razumevanjem dinamike vsakega posameznika.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teorija",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Da bi usposabljanje naredili karseda zanimivo, je potrebna interakcija znotraj skupine. Poleg teorije predstavite tudi posamezne naloge, s katerimi dodatno aktivirate skupino",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Odmor za kosilo",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktično",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Usposabljanje se nadaljuje v delavnici z posameznimi praktičnimi nalogami. Kot vodja usposabljanja morate vedno biti prisotni in na voljo za morebitna vprašanja in usmerjanja",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Povzetek",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Nazaj v razred po navodila, za diskusijo o dnevnem usposabljanju. Odgovorite na vsa postavljena vprašanja. Začnite s pripravami na naslednje seje usposabljanja.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Zadnji dan",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Udeležencem se podelijo potrdila o udeležbi.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Domov,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Kako lahko pomagamo vašemu podjetju",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Kako se razvija naše usposabljanje",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Nenehno se razvijamo, da bi zagotovili kakovostno posodobljeno usposabljanje",
	BoschTrainingBenefits_TheBoschServiceTraining: "Da bi vam pomagali pri razvoju vašega podjetja, vam Boschev Center za usposabljanje serviserjev ponuja obsežen nabor relevantnih tehničnih/poslovnih usposabljanj in tečajev za nadaljnje izobraževanje, na katerih lahko pridobite certifikate o usposobljenosti. Na naslednjih straneh boste našli pregled našega programa usposabljanj in priložnosti za nadaljnje izobraževanje. Veselimo se že, da bomo vas in vaše zaposlene srečali na naših usposabljanjih in seminarjih.",
	BoschTrainingBenefits_LongStandingExperience: "Dolgoletne izkušnje in obsežno strokovno znanje o sistemih v avtomobilski tehnologiji.",
	BoschTrainingBenefits_QuickAndEfficient: "Hitre in učinkovite rešitve za številne težave.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Prednosti in koristi",
	BoschTrainingBenefits_LMS: "Sistem upravljanja učenja – Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions pokriva vsa avtomobilska področja. Specializirajte se na posameznih področjih ali pa razširite znanje z usposabljanji družbe Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Pridobljeno znanje olajša popravila, vzdrževanje in servisiranje.",
	BoschTrainingBenefits_TheHighDegree: "Veliko praktične vsebine in majhne skupine zagotavljajo ciljno usmerjeno učenje",
	BoschTrainingBenefits_ParticipationInTraining: "Udeležba na usposabljanju veča motivacijo in zadovoljstvo zaposlenih, dodatne kvalifikacije pa prepričajo stranke",
	BoschTrainingBenefits_IncreasedSales: "Povečana prodaja s pomočjo sistemskega znanja o več blagovnih znamkah",
	BoschTrainingBenefits_MethodicalProcedures: "Metodični postopki krajšajo čakalni čas strank",
	BoschTrainingBenefits_HighlyQualified: "Visoko usposobljeni inštruktorji zagotavljajo sistematične postopke",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Opredelitev rešitve za usposabljanje, ki je primerna za vas ali vaše podjetje",
	BoschTrainingBenefits_DirectOnlineBooking: "Neposredna spletna rezervacija in upravljanje tečajev za vas ali vaše zaposlene",
	BoschTrainingBenefits_OnlineAccess: "Spletni dostop do učne dokumentacije",
	BoschTrainingBenefits_AccessToYourOwn: "Dostop do osebne zgodovine usposabljanja in certifikatov",
	BoschTrainingBenefits_ToolsForManagement: "Orodja za upravljanje lastnega poklicnega razvoja",
	BoschTrainingBenefits_ManagerHasFull: "Vodja ima popoln pregled nad tečaji svojih zaposlenih znotraj sistema",
	BoschTrainingBenefits_Access24hours: "Dostop 24 ur na dan, 7 dni v tednu",
	ModernTrainingApproach_KnowledgeIsKey: "Znanje je ključni dejavnik uspeha vsake delavnice. Zato Bosch ponuja celovit program strokovnega usposabljanja. Tako lahko učenci ob pravem času in na pravem mestu pridobijo pravo znanje – znanje, ki je potrebno za strokovno vzdrževanje in popravilo vozila stranke. Bosch je dodal spletno oddajanje kot nov modul, ki je del že ponujenega paketa e-učenja. Sestavljen je iz službenih usposabljanj, ki so zasnovana posebej za pretočni prenos.",
	ModernTrainingApproach_ANewLearning: "Nova priložnost za učenje",
	ModernTrainingApproach_YourOnlineTraining: "Vaše možnosti spletnega usposabljanja",
	ModernTrainingApproach_RecentlyTheWorld: "V zadnjem času se je svet spremenil, poglejte, kaj počnemo, da bi pomagali",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Svet spletnega usposabljanja",
	ModernTrainingApproach_NewTeachingMethod: "Nova metoda poučevanja",
	ModernTrainingApproach_InAdditionTo: "Poleg osebnega usposabljanja smo v program usposabljanja vključili tudi spletno usposabljanje v živo. Naš trener vam je na voljo z obsežnim teoretičnim znanjem v virtualni učilnici ali s praktičnimi primeri. Kot običajno boste prejeli veliko informacij o sistemih vozil in ustreznih Boschevih izdelkih. Seveda imate tudi možnost postavljati vprašanja. Na ta vprašanja boste dobili odgovor neposredno v spletni oddaji v živo. Spletne oddaje v živo trajajo od 60 do 120 minut. Potrebna je stabilna internetna povezava. Ustrezen spletni prenos poiščite neposredno v našem katalogu usposabljanj tako, da v iskalno polje vnesete \Webcast\.",
	ModernTrainingApproach_BoschWebcastingHeader: "Boschevo spletno oddajanje: Inovativno in praktično",
	ModernTrainingApproach_BoschWebcastingText: "Če želite sodelovati v Boschevi spletni oddaji, se morate najprej prijaviti. Pred sejo se udeležencu pošlje individualna dostopna koda, ki omogoča prijavo. Hkrati lahko sodeluje do 200 udeležencev s poljubnega števila lokacij. Usposabljanje vodi trener, vprašanja pa lahko kadarkoli zastavite preko funkcije klepeta v živo.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Digitalni dodatek k usposabljanju v učilnici",
	ModernTrainingApproach_TheDigitalSupplementText: "Spletno oddajanje in usposabljanje v učilnici se odlično dopolnjujeta. V obeh primerih usposabljanje vodi usposobljen trener. Spletne oddaje so primerne za teme, ki jih je mogoče prenašati digitalno, ne da bi bilo treba delati na vozilu. Na primer spoznavanje nekaterih funkcij ESI[tronic] novega sistema za pomoč vozniku. Pri učinkovitem skupinskem delu v delavnici ali praktičnem delu na tehnologiji vozila je usposabljanje v živo še vedno prva izbira.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Pretočni prenos je lahko cenejši",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Stroški na udeleženca so pri spletnem oddajanju navadno nižji kot pri podobnem tečaju v učilnici. Razlog za to je, da lahko usposabljanje koristi večjemu številu udeležencev in da je napor, povezan z usposabljanjem, bistveno manjši (ni treba potovati na lokacijo in nazaj, potrebno je manj časa).",
	ModernTrainingApproach_BookAndProfitHeading: "Rezervirajte in profitirajte",
	ModernTrainingApproach_BookAndProfitText: "Spletne oddaje lahko rezervirate na našem spletnem mestu, tako kot druge tečaje usposabljanja in ponudbe za učenje. Izbirate lahko med plačilom posamezne pristojbine za vsako spletno oddajo ali letne naročnine za izbrano število spletnih oddaj.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Primerjava Boschevega spletnega oddajanja in usposabljanja v učilnici",
	ModernTrainingApproach_Webcasting: "Spletno oddajanje",
	ModernTrainingApproach_Classroom: "Učilnica",
	ModernTrainingApproach_WebcastingP1: "Hkrati lahko sodeluje do 200 udeležencev z vsega sveta",
	ModernTrainingApproach_WebcastingP2: "Majhna zapletenost in dokaj nizki stroški, potovanja niso potrebna",
	ModernTrainingApproach_WebcastingP3: "Idealno za specifične in strnjene posamezne teme",
	ModernTrainingApproach_WebcastingP4: "Delavnice lahko za zaposlene kjerkoli in kadarkoli izvedejo spletno oddajanje",
	ModernTrainingApproach_WebcastingP5: "Potrebno je 50–70 % manj časa kot pri usposabljanju v učilnici",
	ModernTrainingApproach_ClassroomTrainingP1: "Srečanje do 16 udeležencev hkrati",
	ModernTrainingApproach_ClassroomTrainingP2: "Potni stroški in na splošno višji stroški na osebo",
	ModernTrainingApproach_ClassroomTrainingP3: "Idealno za optimizacijo sodelovanja ali učenja neposredno na vozilu",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktične vaje in interakcija s trenerjem",
	ModernTrainingApproach_ClassroomTrainingP5: "Trener lahko znanje, ki ga bo poučeval, bolje prilagodi dejanskim potrebam udeležencev",
	ModernTrainingApproach_OnlineTrainingOfferings: "Ponudba spletnega usposabljanja (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Danes je samostojno učenje z računalnikom sestavni del prenosa znanja. Zaradi proste izbire časa in kraja, neomejene ponovljivosti, individualne hitrosti učenja in interaktivnih učnih vsebin je ta metoda nepogrešljivo orodje za učenje.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Vsak element spletnega usposabljanja (WBT) je skrbno strukturiran. Poleg tega, da ti tečaji zagotavljajo široko bazo znanja, so lahko učinkovita priprava na programe usposabljanja v Boschevih centrih za usposabljanje.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Virtualna simulacija diagnostike ponuja uporabniku možnost igranja vlog v vsakodnevnih situacijah v delavnici, kot je potek postopka odpravljanja težav z uporabo Boschevih diagnostičnih naprav v virtualni delavnici.",
	ModernTrainingApproach_Advantages: "Prednosti",
	ModernTrainingApproach_AdvantagesP1: "Hitra in učinkovita metoda zagotavljanja znanja",
	ModernTrainingApproach_AdvantagesP2: "Raznovrstno učenje",
	ModernTrainingApproach_AdvantagesP3: "Uporablja se lahko poljubno pogosto",
	ModernTrainingApproach_AdvantagesP4: "Minimalno časa in denarja",
	ModernTrainingApproach_AdvantagesP5: "Individualna hitrost učenja",
	ModernTrainingApproach_AdvantagesP6: "Prilagodljiv čas učenja",
	ModernTrainingApproach_AdvantagesP7: "Ponovljivost",
	ModernTrainingApproach_AdvantagesP8: "Brez potnih stroškov",
	ModernTrainingApproach_AdvantagesP9: "Brez konfliktov glede zmogljivosti ali razporeda",
	Dashboard_CoursesViewAndManageYourCourses: "TEČAJI | Ogled in upravljanje vaših tečajev",
	Dashboard_MyCourses: "Moji tečaji",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAMI USPOSABLJANJA | Ogled vaših programov usposabljanja",
	Dashboard_MyTrainingProgram: "Moj program usposabljanja",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "e-UČENJE | Ogled in upravljanje portfelja e-Učenja",
	Dashboard_MyELearning: "Moje e-Učenje",
	Dashboard_AccountManageYourAccount: "RAČUN | Upravljanje vašega računa",
	Dashboard_MyAccount: "Moj račun",
	Dashboard_MyDashboard: "Moja nadzorna plošča",
	Dashboard_ManageYourAccount: "Upravljanje vašega računa",
	Dashboard_Workshops: "Delavnice",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRACIJA | Upravljanje vaše delavnice",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRACIJA | Upravljanje vaših ekip",
	Dashboard_Administration: "Administracija",
	Dashboard_ViewYourCourses: "Ogled vaših tečajev",
	Dashboard_ViewYourTrainingPrograms: "Ogled vaših programov usposabljanja",
	Dashboard_TrainingPrograms: "Programi usposabljanja",
	Dashboard_ViewAndEditYourDetails: "Ogled in urejanje vaših podatkov",
	Dashboard_ViewYourELearningCourses: "Ogled vaših tečajev e-Učenja",
	Dashboard_Pending: "Čakajoče",
	Dashboard_RequiresAdministratorSignOff: "(potrebna je potrditev skrbnika)",
	Dashboard_HistoricalRecords: "Zgodovinski zapisi",
	Dashboard_Registered: "Registrirano",
	Dashboard_WaitingList: "Čakalni seznam",
	Dashboard_RecommendedForYou: "Priporočeno za vas",
	Dashboard_AccountDetails: "Podrobnosti o računu",
	Dashboard_ChangeProfileImage: "spremenite sliko profila",
	Dashboard_Title: "Naslov",
	Dashboard_Role: "Vloga",
	Dashboard_FirstName: "Ime",
	Dashboard_LastName: "Priimek",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobilna številka",
	Dashboard_Email: "E-pošta",
	Dashboard_Language: "Jezik",
	Dashboard_Save: "Shrani",
	Dashboard_Student: "Učenec",
	Dashboard_WorkshopTrainingAdministrator: "Skrbnik usposabljanja za delavnice",
	Dashboard_WholesalerTrainingAdministrator: "Skrbnik usposabljanja za trgovce na debelo",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administracija podjetja",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Upravljajte svoje delavnice ...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Upravljanje vaših zaposlenih ...",
	Dashboard_CompanyAdministration_Workshop: "Delavnica",
	Dashboard_CompanyAdministration_Address: "Naslov",
	Dashboard_CompanyAdministration_Zip: "Poštna številka",
	Dashboard_CompanyAdministration_City: "Kraj",
	Dashboard_CompanyAdministration_Manage: "Upravljanje",
	Dashboard_AdministrationSection_AdministrationTitle: "Administracija",
	Dashboard_AdministrationSection_ManageTheTeam: "Upravljanje ekipe",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRACIJA | Upravljanje vaših zaposlenih",
	Dashboard_AdministrationSection_Employees: "Zaposleni",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRACIJA | Upravljanje vaših trgovcev na debelo",
	Dashboard_AdministrationSection_Wholesalers: "Trgovci na debelo",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRACIJA | Upravljanje vaših transakcij",
	Dashboard_AdministrationSection_Transactions: "Transakcije",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administracija zaposlenih",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Upravljanje vaših zaposlenih ...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registracija",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Zapisi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Uredi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Zaposleni",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Dodaj novo",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Ustvari",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrirano",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Čakajoče",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(potrebna je potrditev skrbnika)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Zgodovinski zapisi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Čakalni seznam",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Seznam zaposlenih",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Upravljanje zaposlenih",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Ime",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Račun zaposlenega",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Ogled in urejanje podatkov o zaposlenih ...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Nastavitve",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Omogoči osebni račun",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Nadgradnja pooblaščenca za upravljanje delavnice za zaposlene",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Omogočite zaposlenim, da se prijavijo na dogodke usposabljanja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Pošlji e-poštno povabilo",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Deaktiviranje računa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Shrani",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Pošlji",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Uredi",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Upravljanje trgovcev na debelo",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Upravljanje vaših trgovcev na debelo ...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Seznam odobrenih trgovcev na debelo",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Ime",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Odstrani",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Ustvari",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Dodaj",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Ustvarjanje novega računa za zaposlenega",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Ustvarite podrobnosti za nove zaposlene ...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Dodajanje novega skrbnika za odobrenega trgovca na debelo",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Ustvarite podrobnosti za nove trgovce na debelo ...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "V spustnem meniju izberite odobrenega trgovca na debelo ...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transakcije",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Ogled in upravljanje vaših transakcij ...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Nazaj",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Številka računa",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Opis",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Način plačila",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Datum",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Količina",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Prenos",
	TrainingCentres_TrainingCentres: "Centri za usposabljanje",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Naši centri za usposabljanje so po vsem svetu",
	TrainingCentres_CountryCategory: "Kategorija države",
	TrainingCentres_CityCategory: "Kategorija mesta",
	TrainingCentres_Country: "Država",
	TrainingCentres_City: "Kraj",
	TrainingCatalogue_TrainingCatalogue: "Katalog tečajev usposabljanja",
	TrainingCatalogue_LearningAtTheHighestLevel: "Najvišja stopnja izobraževanja",
	TrainingCatalogue_StartDate: "Začetni datum",
	TrainingCatalogue_EndDate: "Končni datum",
	TrainingCatalogue_TrainingName: "Ime usposabljanja",
	TrainingCatalogue_Language: "Jezik",
	TrainingCatalogue_Days: "Dni",
	TrainingCatalogue_Seats: "Razpoložljiva mesta",
	TrainingCatalogue_Price: "Cena",
	TrainingCatalogue_Location: "Lokacija",
	TrainingCatalogue_PartNumber: "Kataloška številka",
	TrainingCatalogue_TrainingCourse: "Tečaj usposabljanja",
	TrainingCatalogue_Category: "Kategorija",
	TrainingCatalogue_TrainingCategory: "Kategorija usposabljanja",
	TrainingCatalogue_CourseName: "Ime tečaja",
	TrainingCatalogue_TotalDays: "Skupno število dni",
	TrainingCatalogue_Venue: "Prizorišče",
	TrainingCatalogue_Details: "Podrobnosti",
	TrainingCatalogue_Prerequisites: "Pogoji",
	TrainingCatalogue_CourseType: "Vrsta tečaja",
	TrainingCatalogue_LearningMethod: "Učna metoda",
	TrainingCatalogue_AddToWaitingList: "Dodaj na čakalni seznam",
	ShoppingCart_ShoppingCart: "Nakupovalni voziček",
	ShoppingCart_ReviewAndManage: "Pregled in upravljanje vaših naročnin",
	ShoppingCart_YouHaveAddedItem: "V košarico ste dodali naslednje predmete",
	ShoppingCart_Item: "Predmet",
	ShoppingCart_Quantity: "Količina",
	ShoppingCart_PricePerItem: "Cena na predmet",
	ShoppingCart_TotalPrice: "Skupna cena",
	ShoppingCart_VatIsIncluded: "brez DDV",
	ShoppingCart_Redeem: "Če nimate kreditov, kliknite tukaj in jih unovčite",
	ShoppingCart_Purchase: "Če nimate kreditov, kliknite tukaj za nakup",
	ShoppingCart_Checkout: "Na blagajno",
	ShoppingCart_ContinueShopping: "Nadaljuj nakupovanje",
	ShoppingCart_TotalPriceInclVat: "Skupna cena z DDV",
	ShoppingCart_Apply: "Uporabi",
	ShoppingCart_ShoppingCartEmpty: "Vaš nakupovalni voziček je prazen",
	ShoppingCart_VatIncluded: "DDV (vključen)",
	ShoppingCart_ManageYourShopping: "Upravljanje vašega nakupovanja",
	ShoppingCart_Participant: "Udeleženec",
	ShoppingCart_Complete: "Popolno",
	ShoppingCart_NetPrice: "Neto cena",
	ShoppingCart_Workshop: "Delavnica",
	CheckOut_PreviewYourOrder: "Predogled vašega naročila",
	CheckOut_YourShoppingCart: "Voziček",
	CheckOut_Preview: "Predogled",
	CheckOut_Payment: "Plačilo",
	CheckOut_Summary: "Povzetek",
	CheckOut_Back: "Nazaj",
	CheckOut_PleaseReadAndAgreeOurTerms: "Prosimo, preberite in se strinjajte z našimi pogoji uporabe",
	CheckOut_IHaveReadTheTermsAndCondition: "Prebral/-a sem [pogoje uporabe], jih razumem in sprejemam",
	CheckOut_YesIAgree: "Da – strinjam se s pogoji uporabe",
	CheckOut_Continue: "Nadaljuj",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Čestitamo! Prejeli smo vaše naročilo",
	CheckOut_OrderConfirmation: "Ko bo vaša zahteva uspešno obdelana, boste prejeli končno potrditev naročila",
	CheckOut_WeWillContactYouDirectly: "Pri kakršnihkoli vprašanjih v zvezi z vašo zahtevo se bomo obrnili neposredno na vas",
	CheckOut_AddToCart: "Dodaj v nakupovalni voziček",
	CheckOut_CreditCart: "Košarica s krediti",
	CheckOut_DirectDebit: "Direktna bremenitev",
	CheckOut_TrainingCredits: "Krediti za usposabljanje",
	CheckOut_PreferredWholesaler: "Prednostni trgovec na debelo",
	EventCalendar_EventCalendar: "Koledar dogodkov",
	EventCalendar_TrainingEventsForAllYourNeeds: "Dogodki usposabljanja za vse vaše potrebe",
	TrainingCourse_TrainingCourse: "Katalog tečajev usposabljanja",
	TrainingCourse_LearningAtTheHighestLevel: "Najvišja stopnja izobraževanja",
	TrainingProgram_Title: "Katalog programov usposabljanja",
	TrainingProgram_SubTitle: "Najvišja stopnja izobraževanja",
	AccountDeactivationModal_Title: "Ali ste prepričani, da želite deaktivirati ta račun?",
	AccountDeactivationModal_Line1: "Deaktivacija računa je trajna in odstrani vso vsebino, vključno s transakcijami, zahtevki za storitve in saldi članov ekipe ter nastavitvami profila.",
	AccountDeactivationModal_Line2Part1: "Če do deaktivacije pride v aktivnem naročniškem obdobju, si oglejte naš pravilnik o odstopu v naših",
	AccountDeactivationModal_Line2Part2: "pogojih uporabe",
	AccountDeactivationModal_Line2Part3: "pred oddajo svojega zahtevka.",
	AccountDeactivationModal_Line3: "Ali ste prepričani, da želite oddati zahtevo za deaktivacijo tega računa?",
	AccountDeactivationModal_Cancel: "Prekliči",
	AccountDeactivationModal_Send: "Pošlji",
	CurrentUserAccountDeactivationModal_Title: "Ali ste prepričani, da želite deaktivirati svoj račun?",
	CurrentUserAccountDeactivationModal_Line1: "Deaktivacija računa je trajna in odstrani vso vsebino, vključno s transakcijami, zahtevki za storitve in saldi članov ekipe ter nastavitvami profila.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Če ste v aktivnem naročniškem obdobju, si oglejte naš pravilnik o odstopu v naših",
	CurrentUserAccountDeactivationModal_Line3: "Ali ste prepričani, da želite oddati zahtevo za deaktivacijo svojega računa?",
	CurrentUserAccountDeactivationModal_Yes: "Da",
	CurrentUserAccountDeactivationModal_No: "Ne",
	CourseWithdrawalModal_Title: "Preklic tečaja",
	CourseWithdrawalModal_Line1: "Ali ste prepričani, da se želite odjaviti od tečaja?",
	CourseWithdrawalModal_Line2Part1: "Pri odjavah se zaračunajo stroški odjave v skladu s",
	CourseWithdrawalModal_Line2Part2Link: "pogoji uporabe",
	CourseWithdrawalModal_Line2Part3: "naših storitev.",
	CourseWithdrawalModal_Yes: "Da, odjavi",
	CourseWithdrawalModal_No: "Ne, prekliči",
	Settings_Settings: "Nastavitve",
	Settings_ManageYourSettings: "Upravljanje vaših nastavitev",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Podatki za prijavo v My Bosch Global ID",
	Settings_Edit: "Uredi",
	Settings_CompanyDetails: "Podatki o podjetju",
	Settings_RequestAccountDeletion: "Zahteva za izbris računa",
	Settings_AccountDeletionText: "Če izbrišete svoj račun, bodo vsi vaši podatki (vključno z naslovom in podatki o plačilu) izbrisani in ne bodo več na voljo.",
	Settings_DeleteAccountRequestConfirmation: "Potrditev zahteve za izbris računa",
	Settings_DeleteAccountRequestConfirmationText: "Prejeli smo vašo zahtevo za izbris računa. Za dokončno potrditev vas bomo kontaktirali po e-pošti ali telefonu",
	RecommendedHotels_RecommendedHotels: "Priporočeni hoteli",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Naši centri za usposabljanje so po vsem svetu",
	Error_Common: "Prišlo je do napake!",
	Error_404: "Stran, ki ste jo obiskali, ne obstaja",
	Error_401: "Do te strani nimate dostopa",
	Error_500: "Strežnik poroča o napaki",
	Dashboard_Courses_Table_Course: "Tečaj",
	Dashboard_Courses_Table_Date: "Datum [dni]",
	Dashboard_Courses_Table_Venue: "Prizorišče",
	Dashboard_Courses_Table_TrainingType: "Vrsta usposabljanja",
	Dashboard_Courses_Table_Action: "Dejanje",
	Dashboard_Courses_Table_TasksToComplete: "Opraviti morate te naloge",
	Dashboard_Courses_Table_Withdraw: "Odjavi",
	Dashboard_Courses_Table_Remove: "Odstrani",
	Dashboard_Courses_Table_View: "Ogled",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Za prikaz ni nobenih podatkov",
	Dashboard_Courses_Table_CompletionDate: "Datum dokončanja",
	Dashboard_Courses_Table_Actions_Assignments: "Zadolžitve",
	Dashboard_Courses_Table_Actions_Certification: "Potrdilo",
	Dashboard_Courses_Table_Actions_Evaluations: "Ovrednotenja",
	Dashboard_Courses_Table_Actions_Assessments: "Ocene",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Dodaj v koledar",
	Dashboard_Courses_Table_CourseDetails_Task: "Naloga",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Rok",
	ContactUs_Headline: "Kontaktirajte nas",
	ContactUs_HowCanWeHelp: "Kako vam lahko pomagamo?",
	ContactUs_EmailTab: "E-pošta",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Pismo",
	ContactUs_Phone_Headline: "Pokličite nas!",
	ContactUs_Phone_Description: "Veselimo se vašega klica. Z veseljem vam bomo pomagali. Dosegljivi smo od ponedeljka do petka od 09:00 do 17:00.",
	ContactUs_Phone_PhoneNumber: "Prosimo vas, da za posredovanje sporočil uporabite kontaktni obrazec.",
	ContactUs_Phone_ChargeNotice: "Stroški so odvisni od vašega ponudnika storitev in države.",
	ContactUs_Letter_Headline: "Pošljite dopis!",
	ContactUs_Letter_Description: "Ali imate vprašanja, zahteve, predloge? Veselimo se vaše poizvedbe.",
	ContactUs_Email_Headline: "Pošljite e-pošto!",
	ContactUs_Email_Description: "Pošljite nam sporočilo preko spodnjega obrazca. Naši svetovalci bodo z veseljem navezali stik z vami.",
	ContactUs_Email_InputName: "Ime*",
	ContactUs_Email_InputCompany: "Podjetje",
	ContactUs_Email_InputCountry: "Država*",
	ContactUs_Email_InputEmail: "E-pošta*",
	ContactUs_Email_InputMessage: "Sporočilo*",
	ContactUs_Email_DataProtection: "Obvestilo o varstvu podatkov*",
	ContactUs_Email_DataProtectionConfirm: "Prebral sem politiko zasebnosti.*",
	ContactUs_Email_SecurityCheck: "Varnostno preverjanje",
	ContactUs_Email_SecurityCodeConfirm: "Prosimo vas, da vnesete znake, izpisane v zgornjem okvirju.",
	ContactUs_Email_SecurityCodeInvalid: "Vaš odgovor captcha je nepravilen.",
	ContactUs_Email_MandatoryNotice: "Izpolnite obvezna polja.",
	ContactUs_Email_SuccessMessage: "Vaše sporočilo je bilo poslano",
	ContactUs_Email_TakenNoteDataProtection1: "Prebral/-a sem",
	ContactUs_Email_TakenNoteDataProtection2: "Politiko zasebnosti.*",
	ContactUs_Email_SubmitButton: "Pošlji",
	ContactUs_Email_FieldErrorMessage: "Izpolnite obvezna polja.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Vaš račun se pregleduje!",
	AccountInReviewModal_Paragraph1: "Zahvaljujemo se vam, da ste si ustvarili osebni račun pri Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Naša predana ekipa za usposabljanje trenutno pregleduje in oblikuje vaš račun. Po uspešnem oblikovanju vašega računa boste končno odobritev prejeli po e-pošti.",
	AccountInReviewModal_Paragraph3: "Medtem vas vabimo, da si ogledate našo spletno stran z omejenim dostopom.",
	AccountInReviewModal_Paragraph4: "Če imate kakršnakoli vprašanja ali če vaš račun ne bo obdelan v 48 urah (brez upoštevanja vikendov in praznikov), stopite v stik z nami",
	AccountInReviewModal_ContactUsLink: "Tukaj",
	AccountInReviewModal_OKButton: "Ok, spusti me notri!",
	Header_YesButton: "Da",
	Header_NoButton: "Ne",
	Header_LogOutText: "Ali se želite odjaviti?",
	Footer_EnquiryText: "Veselimo se vaše poizvedbe,",
	Footer_Telephone: "Telefon,",
	Footer_TermsAndConditions: "Pogoji uporabe",
	Footer_HowOurTrainingEvolves: "Kako se razvija naše usposabljanje",
	FooterSearch_SearchFieldPlaceholder: "Iskanje",
	TrainingCatalogue_Action: "Dejanje",
	TrainingCatalogue_Duration: "Trajanje",
	TrainingCatalogue_Information: "Informacije",
	Settings_PersonalDetails: "Osebni podatki",
	Reset_All_Filters: "Ponastavi vse filtre",
	EventTable_Days: "Dni",
	SearchField_AllResults: "Vsi rezultati",
	TrainingCentresLayout_ViewDetails: "Ogled podrobnosti",
	Dropdown_Placeholder: "Izberite...",
	WaitlistPopup_SelectButtonText: "Izberi",
	WaitlistPopup_StartDate: "Začetni datum",
	WaitlistPopup_EndDate: "Končni datum",
	WaitlistPopup_Language: "Jezik",
	WaitlistPopup_City: "Kraj",
	WaitlistPopup_Workshop: "Delavnica",
	WaitlistPopup_Student: "Učenec",
	WaitlistPopup_PreferredLocation: "Prednostna lokacija",
	WaitlistPopup_SignUpCourseWaitingList: "Vpišite se na čakalni seznam tečaja",
	WaitlistPopup_SignUpWithoutSelectingDate: "Vpišite se na čakalni seznam brez izbora datuma",
	WaitlistPopup_GeneralWaitList: "Splošni čakalni seznam",
	WatinglistPopup_CourseLabel: "Tečaj",
	Common_ContinueButton: "Nadaljuj",
	UserAccountDeactivationModal_ConfirmLabel: "Potrditev",
	Filter_FilterLabel: "Filter",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Ste že na čakalnem seznamu. Ali želite odstraniti vašo obstoječo registracijo in ustvariti novo?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Da, odstrani",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Ne, prekliči",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Preklic tečaja",
	WaitinglistPopup_SignUpButtonText: "Prijava",
	MyAccount_FirstNameRequied: "Ime je obvezno",
	MyAccount_LastNameRequired: "Priimek je obvezen",
	MyAccount_RoleRequired: "Vloga je obvezna",
	MyAccount_MobileRequired: "Mobilna številka je obvezna",
	MyAccount_EmailRequired: "E-poštni naslov je obvezen",
	MyAccount_LanguageRequired: "Jezik je obvezen",
	MyAccount_UpdateSuccessMessage: "Podatki o vašem računu uspešno posodobljeni!",
	MyAccount_UpdateSuccessModal_ButtonText: "Da, zapri",
	Infor_CorporateInformation_Header: "Informacije o družbi",
	Infor_LegalNotice_Header: "Pravno obvestilo",
	Infor_PrivacyPolicy_Header: "Obvestilo o varstvu podatkov (Politika zasebnosti)",
	CreateEmployeeAccountModification_FormValidation_Role: "Vloga je obvezna",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Ime je obvezno",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Priimek je obvezen",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobilna številka je obvezna",
	CreateEmployeeAccountModification_FormValidation_Email: "E-poštni naslov je obvezen",
	CreateEmployeeAccountModification_FormValidation_Language: "Jezik je obvezen",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Vabilo za pridružitev Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Vaši ekipi ste uspešno dodali novega člana!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Ali želite, da vašemu novemu članu ekipe posredujemo e-poštno vabilo z navodili, kako si lahko sami ustvarijo svoj račun za Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Ne",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Da, pošlji vabilo",
	ShoppingCart_Venue_Fee: "Pristojbina za prizorišče",
	ShoppingCart_Trainning_Name: "Ime usposabljanja",
	ShoppingCart_Available_Credits: "Razpoložljivo dobroimetje",
	ConfirmItsMeModal_Hi: "Pozdravljeni",
	ConfirmItsMeModal_Description1: "Uspešno ste se odzvali na vabilo upravitelja vašega podjetja in si ustvarili račun za Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Pred nadaljevanjem potrdite ali posodobite podatke v vašem računu.",
	ConfirmItsMeModal_Description3: "Po potrditvi se boste morali znova prijaviti in potrditi oblikovanje vašega računa.",
	ConfirmItsMeModal_CompanyDetails: "Podatki o podjetju",
	ConfirmItsMeModal_YourDetails: "Vaši podatki",
	ConfirmItsMeModal_YourDetails_Title: "Naslov",
	ConfirmItsMeModal_YourDetails_Function: "Funkcija",
	ConfirmItsMeModal_YourDetails_Name: "Ime",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobilna številka",
	ConfirmItsMeModal_YourDetails_Email: "E-naslov",
	ConfirmItsMeModal_YourDetails_Language: "Jezik",
	ConfirmItsMeModal_ConfirmButton: "Potrditev",
	SelectWholesalerModal_WholesalerGroupLabel: "Trgovec na debelo",
	SelectWholesalerModal_WholesalerBranchLabel: "Prodajalna",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Prosimo, izberite.",
	ApplyButton: "Uporabi",
	SelectWholesalerModal_Title: "Izberite vašega trgovca na debelo",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Ali želite odstraniti vašo registracijo?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Vaša registracija je bila uspešno odstranjena!",
	WaitlistPopup_AddToWaitListSuccess: "Vaša registracija je bila uspešno dodana!",
	ErrorCommon_Header: "NAPAKA",
	CommonButton_Close: "Zapri",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Prosimo vas, da za nadaljevanje zaprete to okno!",
	EmbeddedVideoPlayer_StartButtonText: "Začetek",
	EmbeddedVideoPlayer_RefresherButtonText: "Osvežitev",
	DeactivateWholesalerModal_ConfirmMessage: "Ali ste prepričani, da želite odstraniti tega trgovca na debelo?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "E-poštno vabilo uspešno poslano!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Ponovno vabilo",
	ContactUs_Mobile_SI: "+386 40 990808",
	ContactUs_Address_SI: "Robert Bosch GmBH,Bosch Service Center,Postfach 30 02 20,70442 Stuttgart,GERMANY",
	ConfirmItsMeModal_CompanyDetails_Name: "Naziv podjetja",
	ConfirmItsMeModal_CompanyDetails_Address: "Naslov",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Poštna številka podjetja",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-naslov",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Odjava od tečaja uspešna!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Ustvarjanje računa ni uspelo. Račun že obstaja!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Posodobitev računa ni uspela. Račun že obstaja!",
	Footer_ExploreTrainingStories: "Odkrijte vse naše zgodbe usposabljanja",
	Breadcrumb_Home: "Domov",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Zgodbe",
	Breadcrumb_Stories_BoschServiceTraining: "Boschevo usposabljanje serviserjev",
	Breadcrumb_Stories_ADayInLife: "Dan v življenju trenerja",
	Breadcrumb_Stories_BoschTrainingBenefits: "Prednosti Boschevih usposabljanj",
	Breadcrumb_Stories_TrainingApproach: "Sodoben pristop k usposabljanju",
	Breadcrumb_Settings: "Nastavitve",
	Breadcrumb_ShoppingCart: "Nakupovalni voziček",
	Breadcrumb_Checkout: "Preverite",
	Breadcrumb_OrderSummary: "Pregled naročila",
	Breadcrumb_Exception: "Izjema",
	Breadcrumb_Dashboard: "Nadzorna plošča",
	Breadcrumb_MyCourses: "Moji tečaji",
	Breadcrumb_MyELearning: "Moje e-Učenje",
	Breadcrumb_CompanyAdministration: "Administracija podjetja",
	Breadcrumb_EmployeeManagement: "Upravljanje zaposlenih",
	Breadcrumb_EmployeeAccount: "Račun zaposlenega",
	Breadcrumb_EmployeeCreation: "Ustvarjanje zaposlenega",
	Breadcrumb_Administration: "Administracija",
	Breadcrumb_EmployeeAdministration: "Administracija zaposlenih",
	Breadcrumb_WholesalerManagement: "Upravljanje trgovcev na debelo",
	Breadcrumb_NewWholesalerAccountCreation: "Ustvarjanje novega računa za trgovca na debelo",
	Breadcrumb_Transactions: "Transakcije",
	Breadcrumb_MyAccount: "Moj račun",
	Breadcrumb_TrainingCentres: "Centri za usposabljanje",
	Breadcrumb_EventCalendar: "Koledar dogodkov",
	Breadcrumb_TrainingCourseCatalogue: "Katalog tečajev usposabljanja",
	Breadcrumb_RecommendedHotels: "Priporočeni hoteli",
	Breadcrumb_CorporateInformation: "Informacije o družbi",
	Breadcrumb_LegalNotice: "Pravno obvestilo",
	Breadcrumb_DataProtectionNotice: "Obvestilo o varstvu podatkov",
	Breadcrumb_PrivacyPolicy: "Politika zasebnosti",
	Breadcrumb_TermCondition: "Pogoji uporabe",
	Breadcrumb_Terms: "Pogoji",
	Breadcrumb_PrivacyStatement: "Izjava o zasebnosti",
	Breadcrumb_Cookies: "Piškotki",
	Breadcrumb_Provider: "Ponudnik",
	Breadcrumb_RegisterAccount: "Registracija računa",
	Stories_ExploreMore: "Odkrijte več",
	CheckOutLayout_ShoppingCartHeader: "Nakupovalni voziček",
	CheckOutLayout_AddedItemCartMessage: "V košarico ste dodali naslednje predmete",
	CheckOutLayout_CheckYourCartMessage: "Preverite vašo košarico",
	ModernTrainingApproach_DateText: "2/28/2022 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Iskanje po zaposlenih",
	CheckOutLayout_OrderWasNotPlaced_Message: "Oprostite! Vaše naročilo ni bilo oddano",
	CheckOutLayout_RefusalReason: "Razlog za zavrnitev",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Plačilo ni uspelo. Prosimo, poskusite znova z drugim načinom plačila",
	CheckOutLayout_SubmittingApiFailed_Message: "Pošiljanje API ni uspelo",
	CartItem_ChooseWorkshop_Message: "Prosimo, izberite delavnico za izbor udeleženca",
	CartItem_ChooseParticipant_Message: "Prosimo, da za nadaljevanje izberete udeleženca",
	CompanyAdministrationTable_SearchBox_Placeholder: "Iskanje po delavnicah",
	CartItem_RemoveCartItem_ConfirmText: "Ali želite odstraniti ta predmet iz košarice?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funkcija",
	AddToCartSuccessModal_Message: "Vaš nakupovalni voziček je bil posodobljen!",
	AddToCartSuccessModal_ContinueButtonText: "Nadaljuj nakupovanje",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Moj nakupovalni voziček",
	AddVoucher_Header: "Dodaj kupon",
	AddVoucher_InputPlaceholder: "Vnesite in potrdite kodo",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Žal ta trenutek nimamo nobenih načrtovanih dogodkov. Prosimo vas, da se vpišete na čakalni seznam, in obvestili vas bomo, ko bo dogodek na voljo",
	ADYEN_ERR_2_: "Transakcija je bila zavrnjena.",
	ADYEN_ERR_3_: "Napaka pri napotitvah",
	ADYEN_ERR_4_: "Transakcija ni bila uspešna zaradi napake na strani prejemnika.",
	ADYEN_ERR_5_: "Kartica, ki je bila uporabljena pri transakcija, je blokirana in tako neuporabna.",
	ADYEN_ERR_6_: "Kartica, ki je bila uporabljena pri transakcija, je potekla. Zato je neuporabna.",
	ADYEN_ERR_7_: "Pri postopku transakcije se zneski niso ujemali.",
	ADYEN_ERR_8_: "Navedena številka kartice je nepravilna ali neveljavna.",
	ADYEN_ERR_9_: "Z banko kupca ni mogoče vzpostaviti stika in potrditi transakcije.",
	ADYEN_ERR_10_: "Banka kupca ne podpira ali ne dovoljuje takšne vrste transakcije.",
	ADYEN_ERR_11_: "Avtentikacija 3D Secure ni bila izvršena oz. ni bila uspešno izvršena.",
	ADYEN_ERR_12_: "Kartica nima dovolj denarja za pokritje zneska za plačilo. Do te napake je lahko prišlo zaradi mesečnega limita na vaši kartici. Prosimo, uporabite drugo kartico, kot je MasterCard, ali stopite v stik z vašo banko, da ponastavite mesečni limit vaše kartice.",
	ADYEN_ERR_14_: "Morebitna goljufija.",
	ADYEN_ERR_15_: "Transakcija je bila preklicana.",
	ADYEN_ERR_16_: "Kupec je pred zaključkom preklical transakcijo.",
	ADYEN_ERR_17_: "Navedena številka PIN je nepravilna ali neveljavna.",
	ADYEN_ERR_18_: "Kupec je več kot trikrat zapored vnesel napačno PIN-številko.",
	ADYEN_ERR_19_: "Navedene PIN-številke ni mogoče preveriti.",
	ADYEN_ERR_20_: "Morebitna goljufija.",
	ADYEN_ERR_21_: "Transakcija ni bila pravilno posredovana v obdelavo.",
	ADYEN_ERR_22_: "Sistem analize tveganja je transakcijo označil za goljufivo (ocena tveganja >= 100), zato je bilo dejanje preklicano.",
	ADYEN_ERR_23_: "V sklopu razloga za zavrnitev so navedene naslednje kode:  Transakcija ni dovoljena zaradi izdajatelja/imetnika kartice",
	ADYEN_ERR_24_: "Navedena koda CVC (varnostna koda kartice) ni veljavna.",
	ADYEN_ERR_25_: "V sklopu razloga za zavrnitev so navedene naslednje kode:  Neveljavna kartica v tej državi",
	ADYEN_ERR_26_: "R1: Nalog za preklic dovoljenja / »R3: Nalog za preklic vseh dovoljenj / »R0: Prekinitev naročila za plačilo«",
	ADYEN_ERR_27_: "Ta odgovor zabeleži vse te odzivne kode, ki jih ni mogoče zanesljivo zabeležiti. Tako je mogoče ločiti splošne zavrnitve (npr. Mastercard »05: Neizpolnjevanje«) od specifičnih.",
	ADYEN_ERR_28_: "Dovoljen znesek za dvig na kupčevi kartici je bil presežen.",
	ADYEN_ERR_29_: "Dovoljeno število dvigov na kupčevi kartici je bilo preseženo.",
	ADYEN_ERR_31_: "Izdajatelj je transakcijo označil za morebitno goljufijo.",
	ADYEN_ERR_32_: "Naslov za podatke, ki ga je vnesel kupec, ni pravilen.",
	ADYEN_ERR_33_: "Banka kupca od slednjega zahteva vnos spletne PIN-številke.",
	ADYEN_ERR_34_: "Banka kupca zahteva tekoči račun za dokončanje nakupa.",
	ADYEN_ERR_35_: "Banka kupca zahteva varčevalni račun za dokončanje nakupa.",
	ADYEN_ERR_36_: "Banka kupca od slednjega zahteva vnos mobilne PIN-številke.",
	ADYEN_ERR_37_: "Kupec je prekinil transakcijo po tem, ko je poskusil z brezkontaktnim nakupom in je bil pozvan za nakup z drugim načinom kartičnega plačila (PIN ali potegnitev).",
	ADYEN_ERR_38_: "Izdajatelj je zavrnil zahtevo po izvzetju avtentikacije in zahteva avtentikacijo za transakcijo. Poskusite ponovno s 3D Secure.",
	ADYEN_ERR_39_: "Izdajatelj ali shema ni bila zmožna prenesti izida prek RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Ime ne sme vsebovati posebnih znakov (npr.: @, #, $ itd.)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Ime mora biti krajše od 75 znakov",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Priimek ne sme vsebovati posebnih znakov (npr.: @, #, $ itd.)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Priimek mora biti krajši od 75 znakov",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Številka mobilnega telefona ne sme vsebovati posebnih znakov (npr.: @, #, $ itd.)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Številka mobilnega telefona mora biti krajša od 45 znakov",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telefonska številka ne sme vsebovati posebnih znakov (npr.: @, #, $ itd.)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telefonska številka mora biti krajša od 45 znakov",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Nepravilen e-naslov",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-naslov mora biti krajši od 100 znakov",
	ConfirmGeolocationModal_ConfirmButton: "Nadaljuj",
	ConfirmGeolocationModal_CancelButton: "Spremeni",
	ConfirmGeolocationModal_CurrentCountrySelection: "Vaša trenutna izbira države je:",
	ConfirmGeolocationModal_AskChangeCountry: "Ali želite ostati na tem spletnem mestu ali spremeniti svojo državo?",
	Addtocart_Modal_AddToCartFailTitle: "Udeleženec je že prijavljen na ta tečaj",
	Addtocart_Modal_AlreadyInCart: "Udeleženec je že prijavljen na ta dogodek",
	TrainingCatalogue_DateRange: "Razpon datumov",
	TrainingCatalogue_DateRange_placeholder: "Izberite filter za razpon datumov",
	Dashboard_MyPendingCourses: "Moji čakajoči tečaji",
	Dashboard_MyWaitingList: "Moj čakalni seznam",
	Dashboard_LatestNews: "Najnovejše novice",
	SettingsMainPage_EditAccountButton: "Uredi uporabniški račun",
	SettingsMainPage_EditBoschId: "Uredi Bosch ID",
	EventInfoView_EventDetailTab: "Podrobnosti o dogodku",
	EventInfoView_AssignmentsTab: "Zadolžitve",
	EventInfoView_EvaluationTab: "Ovrednotenje",
	EventInfoView_AttachmentTab: "Priloge",
	EventInfoView_CourseDescriptionTab: "Opis",
	EventInfoView_DescriptionTab_General: "Splošno",
	EventInfoView_DescriptionTab_PartLabel: "Del",
	AssignmentTab_Description: "Tu boste našli vse informacije, ki jih boste potrebovali za dokončanje tega tečaja.",
	AssignmentTab_Columns_Download: "Prenos",
	AssignmentTab_Columns_Upload: "Naloži",
	AssignmentTab_Columns_TaskComplete: "Naloga dokončana",
	EventDetailTab_Location_Message_Video: "To je spletni dogodek, do katerega lahko dostopate prek povezave \"Zaženi videoposnetek\".",
	EventDetailTab_Location_Message_WebBased: "To je spletni dogodek, do katerega lahko dostopate prek povezave \"Zaženi WBT\".",
	EventDetailTab_Location_Message_WebCast: "To je spletni dogodek, do katerega lahko dostopate prek povezave \"Pridruži se zdaj\".",
	EventDetailTab_Link_Video: "Zaženi videoposnetek",
	EventDetailTab_Link_Video_Remark: "* Pri zagonu videoposnetka se odpre novo okno. Po zaprtju okna boste preusmerjeni nazaj na to stran.",
	EventDetailTab_Link_WebBased: "Zaženi WBT",
	EventDetailTab_Link_WebBased_Remark: "* Z zagonom spletnega usposabljanja (WBT) se bo odprlo novo okno. Po zaprtju okna boste preusmerjeni nazaj na to stran.",
	EventDetailTab_Link_WebCast: "Pridruži se zdaj",
	EventDetailTab_Link_WebCast_Remark: "* Povezava »Pridruži se zdaj« bo aktivna 30 minut pred začetkom dogodka.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Trajanje (ur)",
	EventDetailTab_Title_Trainers: "Vodja(e) usposabljanja",
	EventDetailTab_Title_Language: "Jezik",
	EventDetailTab_Title_Score: "Rezultat (%)",
	EventDetailTab_Title_Calendar: "Dodaj v koledar",
	EventDetailTab_Title_Certificate: "Prenos certifikata",
	EventDetailTab_Title_Messages: "Sporočila",
	EventDetailTab_Title_StartDate: "Začetni datum",
	EventDetailTab_Title_AvailableFrom: "Na voljo od",
	EventDetailTab_Title_Time: "Čas",
	EventDetailTab_Title_EndDate: "Končni datum",
	EventDetailTab_Title_ExpiresOn: "Poteče dne",
	EventDetailTab_Title_Room: "Prostor",
	EventDetailTab_Title_Method: "Metoda",
	EventDetailTab_Title_Link: "Povezava*",
	EventDetailTab_Title_Location: "Lokacija",
	AttachmentTab_EmptyScreenDescription: "Za ta dogodek priloge niso na voljo.",
	AttachmentTab_Columns_Download: "Prenos",
	AttachmentTab_Description: "Tukaj boste našli nekaj pomembnih informacij, ki vam bodo v pomoč pri tem tečaju",
	EvaluationTab_Annotation: "Cenimo vaše povratne informacije in zelo radi bi od vas slišali, kako nam gre. Prosimo, da si vzamete čas in izpolnite naslednjo evalvacijo, ki nam bo pomagala nenehno izboljševati naše storitve za vas. Hvala.",
	EvaluationTab_Notice: "Ocene so naslednje",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Pred oddajo ocene dogodka odgovorite na vsa obvezna vprašanja",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Vaše povratne informacije so bile uspešno posredovane",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Predložitev ocene ni bila uspešna",
	TrainingCatalogueLayout_ExportButtonText: "Izvoz",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Evalvacija je bila zaključena ali je potekla.",
	EventDetailTab_Link_CopiedTooltip: "Kopirano v odložišče",
	EventDetailTab_QRCode_ExplanationText_WBT: "Skenirajte za zagon WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Skenirajte za zagon videoposnetka",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Skenirajte za zagon spletne oddaje",
	Footer_TrainingCoure_Headline: "Tečaji usposabljanja, ki jih ponuja Bosch",
	Footer_TrainingCourse_Content: "Bosch kot svetovno priznana blagovna znamka in z več kot stoletjem izkušenj na področju inovacij postavlja standarde na globalni ravni kot ponudnik avtomobilskih sistemov, delov in tehničnih rešitev. Veliko moč nam dajejo tesna partnerstva s proizvajalci vozil, za katere oblikujemo, razvijamo in proizvajamo najsodobnejše sisteme in komponente po vsem svetu. Zato je Bosch z raznolikim portfeljem delov, diagnostičnih rešitev in storitev za delavnice utrdil svojo tradicijo na področju izdelkov in podpore za avtomobilski poprodajni trg. Učenje in razvoj sta gotovo ključ do uspeha in ta filozofija je podkrepljena s široko paleto usposabljanj na vseh področjih avtomobilske tehnologije, sistemov vozil in diagnostičnega znanja.",
	SearchField_NoRecordsFound: "Najdenih ni bilo nobenih zapisov",
	TrainingCatalogue_FreeTextSearch: "Prosto besedilo",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Iskanje",
	CheckoutLayout_WholeSalerGroup: "Izberite skupino trgovcev na debelo",
	CreditInsufficientModal_Message: "Za uporabo tega načina plačila nimate dovolj kreditov. Poskusite znova z drugim načinom plačila.",
	ShoppingCart_Required_Credits: "Skupna cena",
	MaintainingInfo_Message1: "Spletna stran je v načinu vzdrževanja.",
	MaintainingInfo_Message2: "Kmalu bomo nazaj.",
	CountryValidationModal_Message: "Niste se mogli prijaviti v [CountryA], ker je bil vaš račun ustvarjen v [CountryB].",
	CountryValidationModal_ButtonText: "Nadaljujte na [Country]",
	EventTable_LoginRequired: "Za registracijo se prijavite",
	TrainingPartTableLayout_Date: "Datum",
	TrainingPartTableLayout_StartTime: "Začetni čas",
	TrainingPartTableLayout_EndTime: "Končni čas",
	TrainingPartTableLayout_Duration: "Trajanje (ur)",
	TrainingPartTableLayout_TrainingMethod: "Metoda usposabljanja",
	MyCoursesTableContents_ActiveHeader: "Aktivni tečaji",
	MyCoursesTableContents_ActiveTabHeader: "Aktivno",
	MyCoursesTableContents_ScheduledHeader: "Načrtovani tečaji",
	MyCoursesTableContents_ScheduledTabHeader: "Načrtovano",
	MyCoursesTableContents_OnlineHeader: "Spletni tečaji",
	MyCoursesTableContents_OnlineTabHeader: "Spletno",
	MyCoursesTableContents_CancelledHeader: "Odpovedani tečaji",
	MyCoursesTableContents_CancelledTabHeader: "Odpovedano",
	OnlineCoursesTable_Title_Progress: "Napredek",
	ScheduledCoursesTable_Title_Date: "Datum",
	CoursesTable_Title_Completed: "Končano",
	Footer_TrainingProgram: "Programi usposabljanja",
	Breadcrumb_TrainingProgram: "Programi usposabljanja",
	TrainingProgram_Title_Table: "Naslov",
	TrainingProgram_TrainingProgram: "Programi usposabljanja",
	TrainingProgram_TargetGroup: "Ciljna skupina",
	TrainingProgram_MaxLength: "Najv. Dolžina",
	TrainingProgram_Information: "Informacije",
	TrainingProgram_Detail: "Podrobnosti",
	TrainingProgram_Modules: "Moduli",
	TrainingProgram_Course: "Tečaji",
	TrainingProgram_PartNumber: "Kataloška številka",
	TrainingProgram_Max_Length: "Največja dolžina",
	TrainingProgram_Duration: "Trajanje",
	TrainingProgram_Exam: "Preizkus",
	TrainingProgram_Assign_Popup: "Prijavite se na program usposabljanja",
	TrainingProgram_Student: "Učenec",
	TrainingProgram_Assign: "Dodeli",
	TrainingProgram_Popup_Remove_Title: "Odpoved usposabljanja?",
	TrainingProgram_Description: "Opis",
	TrainingProgram_Days: "Dni",
	TrainingProgram_Type: "Tip",
	TrainingProgram_Action: "Dejanje",
	TrainingProgram_Enrolled: "je že vpisan/-a v ta program usposabljanja",
	TrainingProgram_SelfAssign_Enrolled: "V ta program usposabljanja ste že vključeni",
	TrainingProgram_Warning: "Prosimo, prijavite drugega učenca ali izberite drug program usposabljanja.",
	TrainingProgram_Workshop: "Delavnica",
	TrainingProgram_Durations: "let",
	TrainingProgram_Duration_DaysLabel: "dni",
	TrainingProgram_HasExam_Yes: "Da",
	TrainingProgram_HasExam_No: "Ne",
	AssignModal_SuccessModal_Header: "Čestitamo!",
	AssignModal_SuccessModal_Message: "je bil/-a uspešno vpisan/-a v ta program usposabljanja.",
	AssignModal_SelfAssign_SuccessModal_Message: "Uspešno ste bili dodeljeni v program usposabljanja.",
	Dashboard_MyTraningProgram: "Moj program usposabljanja",
	Dashboard_MyTraningProgram_Title: "Naslov",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Ogled",
	Dashboard_MyTraningProgram_Register: "Registracija",
	Dashboard_MyTraningProgram_Records: "Zgodovinski zapisi",
	Dashboard_MyTrainingProgram_In_Progress: "V teku",
	Dashboard_MyTrainingProgram_Accredited: "Akreditirano",
	Dashboard_Program_Modal_Withdraw: "Odjavi",
	Dashboard_Program_Modal_Grade: "Ocena",
	Dashboard_MyTrainingProgram_Registered: "Registrirano",
	Dashboard_MyTrainingProgram_Completedd: "Končano",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Da",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Ne",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Prosimo, upoštevajte, da odjava pomeni, da se boste odjavili iz celotnega programa usposabljanja, in ne le s posameznega tečaja usposabljanja.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Ali se še vedno želite odjaviti iz programa usposabljanja?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Čakajoče",
	WithdrawProgramModal_Withdraw_Success_Message: "Uspešno ste se odjavili iz programa usposabljanja!",
	Common_Warning_Title: "Opozorilo!",
	Common_Error_Title: "Ups, prišlo je do napake!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Dodaj v nakupovalni voziček",
	AddEventFromCatalogToCartModal_HoursText: "ur",
	AddElearningToCartSuccessModal_Message: "je bilo dodano v vaš nakupovalni voziček!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Izbrani udeleženec je bil prijavljen na ta tečaj ali pa je bil tečaj že dodan v košarico.",
	EventDetailTab_SubjectForChange_Message: "Predmet spremembe",
	Dashboard_MyTrainingProgram_Status_Failed: "Neuspešno",
	Dashboard_MyTrainingProgram_Status_Passed: "Uspešno",
	Dashboard_MyTrainingProgram_Status_Merit: "Vrednost",
	Dashboard_MyTrainingProgram_Status_Distinction: "Priznanje",
	Dashboard_Employee_Courses: "Tečaji",
	Dashboard_Employee_Program: "Program usposabljanja",
	Dashboard_Employee_WaitList: "Čakalna lista",
	Dashboard_Employee_Account: "Račun",
	Course_Price_Free: "Prosto",
	Dashboard_Employee_Account_Update_Success_Message: "Uspešno ste posodobili podatke o zaposlenem!",
	Dashboard_Supplier: "Dobavitelj",
	Event_Detail_Comment: "Trenutno ni nobenih sporočil",
	DownLoad_PDF: "Prenos PDF-datoteke",
	TrainingCatalogue_Currency: "Valuta",
	My_Wallet: "Moja denarnica",
	Wallet: "Denarnica",
	Credits_Balance: "Stanje kreditov",
	MyFullCalendar_Today: "Danes",
	Header_Available_Languages: "Razpoložljivi jeziki",
	Header_CurrentRegion: "Trenutno ste v",
	Header_ChangeRegions: "Želite spremeniti državo?",
	Header_Anonymous: "Storitveno usposabljanje",
	Header_Anonymous_Login: "Prijava/registracija",
	Header_RegisterCourse: "Registracija tečaja",
	Homepage_Start: "Začni",
	Homepage_Login: "Prijava",
	MainPage_Title: "Popolna rešitev za delavnice",
	MainPage_Content: "Vaša delavnica, vaša priložnost.",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Sodobni delavnici omogoča strokovno, zanesljivo in učinkovito delo pri diagnostičnih postopkih, odpravljanju težav, popravilih in servisih vseh vrst vozil.",
	BoschNews_TrainingNews: "Novice o usposabljanju",
	Service_Assist_Workshop: "Izboljšajte svojo izkušnjo z aplikacijo Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Kaj lahko storim z aplikacijo?",
	Enhance_Body_CDM3_Item_1: "Rezerviram tečaj in urejam svojo učno pot",
	Enhance_Body_CDM3_Item_2: "Prenašam gradivo za usposabljanje in certifikate tečajev",
	Enhance_Body_CDM3_Item_3: "Zaženi tečaje eLearning",
	Enhance_Body_CDM3_Item_4: "Dostopam do različnih avtomobilskih storitev v mobilni aplikaciji",
	Enhance_Body_CDM3_Item_5: "Prenašam videoposnetke v živo z Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Brezplačno orodje za samodejno skeniranje vozila",
	Training_Stories: "Ogledam si zgodbe z usposabljanj",
	SettingsMyAccount_JoinCompanyButton: "Pridružim se podjetju",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Pridružite se podjetju",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Spodaj vnesite svojo aktivacijsko kodo",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Opomba! Za pridobitev aktivacijske kode se obrnite na skrbnika v svojem podjetju.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Vaša aktivacijska koda je uspešno obdelana in vaš račun je povezan z vašim podjetjem.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Kliknite Nadaljuj za dostop do spletne stani.",
	ContinueButton: "Nadaljuj",
	WelcomeBatsModal_Title: "Vaš račun se pregleduje!",
	WelcomeBatsModal_ActivationLink: "Kliknite tukaj, če imate aktivacijsko kodo za pridružitev podjetju.",
	WelcomeBatsModal_ActivationLinkNotes: "Opomba! Za pridobitev aktivacijske kode se obrnite na skrbnika v svojem podjetju.",
	AccountInReviewModal_Description_Paragraph1: "Zahvaljujemo se vam, da ste si ustvarili osebni račun pri Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Naša predana ekipa za usposabljanje trenutno pregleduje in oblikuje vaš račun. Po uspešnem oblikovanju vašega računa boste končno odobritev prejeli po e-pošti.",
	AccountInReviewModal_CloseButton: "Ok, spusti me noter!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Vaše aktivacijske kode in e-naslova nismo mogli potrditi.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Poskusite ponovno ali se za pomoč obrnite na skrbnika v svojem podjetju.",
	TryAgain_Button: "Poskusite ponovno",
	Error_Title: "Ups, prišlo je do napake!",
	SettingsMyProfileActivationSuccessModal_Title: "Uspeh!",
	AccountInReviewModal_Description_Paragraph3: "Medtem vas vabimo, da si ogledate našo spletno stran z omejenim dostopom.",
	AccountInReviewModal_Description_Paragraph4: "Če imate kakršnakoli vprašanja ali če vaš račun ne bo obdelan v 48 urah (brez upoštevanja vikendov in praznikov), stopite v stik z nami [here]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Nekaj napotkov za pomoč ...",
	Support_FAQ_Headline: "Pogosto zastavljena vprašanja",
	Support_FAQs_section1_title: "Splošno",
	Support_FAQs_section1_question1: "Kaj je Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions je platforma, ki zagotavlja celovit pregled tehničnih tečajev in omogoča rezervacijo in urejanje tečajev.",
	Support_FAQs_section1_question2: "Ali lahko svoj obstoječi račun Bosch (SingleKey ID) uporabim za prijavo v Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Da, dostopna je z vsemi računi, ustvarjenimi z \"MyBosch ID\".",
	Support_FAQs_section1_question3: "Ali lahko račun, ustvarjen za Bosch Training Solutions, uporabim z drugimi storitvami ali aplikacijami Bosch?",
	Support_FAQs_section1_question3_answer1: "Da, svoj Bosch ID lahko uporabite za različne druge Boscheve aplikacije, tako službene kot zasebne, npr. za e-kolo in aplikacije za pametne domove.",
	Support_FAQs_section2_title: "Usposabljanje",
	Support_FAQs_section2_question1: "Kako lahko rezerviram usposabljanje?",
	Support_FAQs_section2_question1_answer1: "Prijavite se svojim obstoječim računom ali registrirajte račun na portalu. Pojdite na \"Storitve usposabljanja\" ali \"Koledar dogodkov\", izberite usposabljanje in kliknite na gumb Dodaj v nakupovalni voziček.",
	Support_FAQs_section2_question2: "Ni na voljo ustreznih datumov usposabljanja. Kaj lahko storim?",
	Support_FAQs_section2_question2_answer1: "Imate možnost, da se dodate na \"splošni\" čakalni seznam (brez izbiranja datuma) ali pa vas bo naša ekipa obvestila takoj, ko bo na voljo novo usposabljanje.",
	Support_FAQs_section2_question3: "Kje lahko najdem pregled svojih rezerviranih tečajev?",
	Support_FAQs_section2_question3_answer1: "Po prijavi najdete celovit pregled vseh rezerviranih in opravljenih tečajev na nadzorni plošči.",
	Support_FAQs_section3_title: "Nastavitve",
	Support_FAQs_section3_question1: "Kako spremenim svoj e-naslov in geslo?",
	Support_FAQs_section3_question1_answer1: "Prijaviti se morate na portal in spremeniti svoj osebni profil (informacije o svojem profilu najdete na nadzorni plošči, ali če kliknete na naslednji gumb v glavi",
	Support_FAQs_section3_question1_answer2: ").",
	Support_FAQs_section3_question1_answer3: "Tam imate možnost spremeniti podrobnosti svojega Bosch ID.",
	Support_FAQs_section3_question2: "Kaj lahko storim, če sem pozabil svoje geslo?",
	Support_FAQs_section3_question2_answer1: "Uporabite gumb Prijava/registracija in vnesite svoj e-naslov.",
	Support_FAQs_section3_question2_answer2: "Če kliknete na Nadaljuj, imate možnost klikniti na \"Pozabljeno geslo\".",
	Support_FAQs_section3_question3: "Kako nastavim račun delavnice?",
	Support_FAQs_section3_question3_answer1: "Če želite dobiti vlogo v delavnici, se obrnite na lokalno administracijo za usposabljanje, na primer s pomočjo kontaktnega obrazca na naši spletni strani.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Izberite svojo državo/regijo",
	MaintenanceBanner_Message_1: "Portal Bosch Automotive Training Solutions Portal bo posodobljen dne [maintenance_schedule]. Pričakujemo, da bo posodobitev trajala 30 minut. Med posodobitvijo portal ne bo razpoložljiv.",
	MaintenanceBanner_Message_2: "\nZa dodatno pomoč nas kontaktirajte po e-pošti [support_email]",
	MaintenancePage_Message_1: "Bosch Automotive Training Solutions Portal se trenutno posodablja",
	MaintenancePage_Message_2: "Pričakujemo, da bo portal znova na voljo dne [maintenance_online_date] ob [maintenance_online_time]",
	MenuHeader_Support: "Podpora",
	Course_Detail_No_Prerequisites: "Ni predpogojev",
	TableCell_Hours: "Ur",
	Dashboard_MenuItem_Dashboard: "Nadzorna plošča",
	Dashboard_MenuItem_Curricula: "Učni program",
	Dashboard_MenuItem_Certificates: "Certifikati",
	Dashboard_MenuItem_Team: "Ekipa",
	Dashboard_MenuItem_Workshops: "Delavnice",
	Dashboard_MenuItem_External: "Zunaj",
	Dashboard_MenuItem_Wallet: "Denarnica",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Vaši prihodnji tečaji",
	Dashboard_Headline_YourELearningCourses: "Vaši tečaji e-Učenja",
	Dashboard_Headline_TeamOverview: "Predogled ekipe",
	Dashboard_Headline_YourOverview: "Vaš predogled",
	Dashboard_Headline_YourWishlist: "Vaš seznam želja",
	Dashboard_Headline_ActivePrograms: "Aktivni programi",
	Dashboard_Headline_CompletedPrograms: "Opravljeni programi",
	Dashboard_Headline_YourCertificates: "Vaši certifikati",
	Dashboard_Headline_Transactions: "Transakcije",
	Dashboard_Table_NoRecordsToDisplay: "Za prikaz ni nobenih podatkov",
	Dashboard_TableColumn_Location_Online: "Spletno",
	Dashboard_TableColumn_Duration_Hours: "ur",
	Dashboard_ELearningCourses_TableHeader_Title: "Naslov",
	Dashboard_ELearningCourses_TableHeader_Duration: "Trajanje (ur)",
	Dashboard_ELearningCourses_ActionButton_View: "Ogled",
	Dashboard_UpcomingCourses_TableHeader_Title: "Naslov",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Datum [Days]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Lokacija",
	Dashboard_UpcomingCourses_ActionButton_View: "Ogled",
	Dashboard_YourOverview_TableHeader_Title: "Naslov",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Datum/trajanje",
	Dashboard_YourOverview_TableHeader_Location: "Lokacija",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Ogled",
	Dashboard_YourWishlist_TableHeader_Title: "Naslov",
	Dashboard_YourWishlist_TableHeader_Action: "Dejanje",
	Dashboard_YourWishlist_ViewAllRecords: "Oglejte si vse podatke",
	Dashboard_TeamOverview_TableHeader_Employee: "Zaposleni",
	Dashboard_TeamOverview_TableHeader_Title: "Naslov",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Datum/trajanje",
	Dashboard_TeamOverview_TableHeader_Location: "Lokacija",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Ogled",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Iskanje",
	Dashboard_ActiveProgram_TableHeader_Title: "Naslov",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Ogled",
	Dashboard_CompletedProgram_TableHeader_Title: "Naslov",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Ogled",
	Dashboard_Certificates_TableHeader_Title: "Naslov",
	Dashboard_Certificates_TableHeader_Date: "Datum",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Dejanje",
	Dashboard_Certificates_ActionButton_Download: "Prenos",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Številka računa",
	Dashboard_Transactions_TableHeader_Description: "Opis",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Način plačila",
	Dashboard_Transactions_TableHeader_Date: "Datum",
	Dashboard_Transactions_TableHeader_Amount: "Količina",
	Dashboard_Transactions_ActionButton_Download: "Prenos",
	Breadcrumb_MyTraining: "Moje usposabljanje",
	Breadcrumb_MyTraining_CourseDetails: "Podrobnosti tečaja",
	Breadcrumb_MyTraining_Curricula: "Učni program",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Podrobnosti programa usposabljanj",
	Breadcrumb_MyTraining_Team: "Ekipa",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Ogled zaposlenega",
	MyTraining_Headerbar_CourseDetails: "Podrobnosti tečaja",
	MyTraining_Headerbar_TrainingProgramDetails: "Podrobnosti programa usposabljanj",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Splošni napredek",
	TrainingProgramDetails_ProgramItems_Headline: "Programske postavke",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Naslov",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Ocena",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Datum",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Ogled",
	TrainingProgramDetails_Status_InProgress: "V teku",
	TrainingProgramDetails_Status_Completed: "Končano",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Trajanje",
	CourseDetails_Label_Trainer: "Trener",
	CourseDetails_Label_Language: "Jezik",
	CourseDetails_Label_Score: "Rezultat:",
	CourseDetails_Label_StartDate: "Začetni datum",
	CourseDetails_Label_EndDate: "Končni datum",
	CourseDetails_Label_Venue: "Prizorišče",
	CourseDetails_Label_Address: "Naslov",
	CourseDetails_Label_Grade: "Ocena",
	CourseDetails_Action_Withdraw: "Odjavi",
	CourseDetails_Action_Evaluate: "Ocenite tečaj",
	CourseDetails_Action_Evaluate_Description: "Ocenite ta tečaj, da ustvarite svoj certifikat!",
	CourseDetails_Action_DownloadCertificate: "Prenos certifikata",
	CourseDetails_Action_JoinWebcast: "Pridružite se spletnemu predvajanju",
	CourseDetails_Action_StartCourse: "Začnite tečaj",
	CourseDetails_Action_RestartCourse: "Ponovno začnite tečaj",
	CourseDetails_Action_RepeatCourse_Description: "Ponovite in pojdite skozi ta tečaj, da prenesete svoj certifikat!",
	CourseDetails_PartSpecificDetails_Headline: "Podrobnosti specifičnega dela",
	CourseDetails_PartSpecificDetails_Tab_Part: "Del",
	CourseDetails_PartSpecificDetails_Description: "Opis",
	CourseDetails_PartSpecificDetails_StartDate: "Začetni datum",
	CourseDetails_PartSpecificDetails_EndDate: "Končni datum",
	CourseDetails_PartSpecificDetails_StartTime: "Začetni čas",
	CourseDetails_PartSpecificDetails_EndTime: "Končni čas",
	CourseDetails_PartSpecificDetails_Method: "Metoda",
	Dashboard_Team_TableHeader_Name: "Ime",
	Dashboard_Team_TableHeader_Role: "Vloga",
	Dashboard_Team_TableHeader_Email: "E-pošta",
	Dashboard_Team_TableHeader_PersonalAccount: "Osebni račun",
	Dashboard_Team_ActionButton_Select: "Izberi",
	Dashboard_Team_PersonalAccount_Activated: "Aktivirano",
	Dashboard_Team_PersonalAccount_NotActivated: "Ni aktivirano",
	Dashboard_Team_ActionButton_AddMember: "Dodaj člana",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Iskanje",
	Dashboard_External_TableHeader_Name: "Ime",
	Dashboard_External_ActionButton_AddNew: "Dodaj novo",
	Dashboard_External_Headline: "Odobren zunanji dostop",
	Dashboard_Workshops_Headline: "Delavnice",
	Dashboard_Workshops_TableHeader_Company: "Podjetje",
	Dashboard_Workshops_TableHeader_Address: "Naslov",
	Dashboard_Workshops_TableHeader_Postcode: "Poštna številka",
	Dashboard_Workshops_TableHeader_City: "Kraj",
	Dashboard_Workshops_ActionButton_Select: "Izberi",
	Wallet_ServiceCredits: "Krediti za storitve",
	Wallet_Headline_CreditBalance: "Stanje kreditov",
	Wallet_Headline_CreditActivity: "Kreditna aktivnost",
	Wallet_Headline_BillingTransactions: "Obračunske transakcije",
	Wallet_BillingTransaction_TableHeader_Order: "Naročilo",
	Wallet_BillingTransaction_TableHeader_Date: "Datum",
	Wallet_BillingTransaction_TableHeader_Amount: "Količina",
	Wallet_BillingTransaction_TableHeader_Action: "Dejanje",
	Wallet_CreditActivity_TableHeader_Date: "Datum",
	Wallet_CreditActivity_TableHeader_Description: "Opis",
	Wallet_CreditActivity_TableHeader_User: "Uporabnik",
	Wallet_CreditActivity_TableHeader_Amount: "Količina",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, vse pravice pridržane",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Varnost izdelkov (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovacije, patenti in licence",
	FooterAdditionalLinks_Navigation_Logistics: "Nabava in logistika",
	MenuHeader_MyTraining: "Moje usposabljanje",
	MenuHeader_TrainingServices: "Storitve usposabljanja",
	MenuHeader_OurLocations: "Naše lokacije",
	MenuHeader_ContactsUs: "Kontaktirajte nas",
	MenuHeader_Logout: "Odjava",
	MenuHeader_FAQ: "POGOSTO ZASTAVLJENA VPRAŠANJA",
	MyProfile_MyBoschId: "MyBosch ID",
	MyProfile_AccountDeletion: "Izbris računa",
	MyProfile_Edit: "Spreminjanje e-naslova ali gesla",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Uredi uporabniški profil",
	BackButton: "Nazaj",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Veselimo se vašega klica. Z veseljem vam bomo pomagali. Na voljo smo 24 ur na dan, 7 dni v tednu.​",
	ContactUs_Email_SecurityCodeConfirm_V3: "Vnesite znake, izpisane v zgornjem okvirju.",
	ContactUs_Email_MandatoryNotice_V3: "Izpolnite vsa zahtevana polja.",
	ContactUs_Email_SendButton: "Pošlji",
	Breadcrumb_TrainingCenters: "Centri usposabljanja",
	Training_Centres_Book_Btn: "Rezervirajte tečaj",
	Training_centres_booking_code: "Koda rezervacije",
	Training_centers_header_bar_title: "Naši centri usposabljanja",
	Training_centers_filter_item: "Center",
	TeamEdit_PersonalAccountToolTop: "Omogočanje te funkcije omogoča zaposlenemu dostop do te spletne strani. Uporabnik bo lahko ustvaril osebni račun in postal del vašega poslovnega računa. Ko je omogočena, lahko zaposlenemu pošljete povabilo za aktivacijsko kodo. Povabilo bo poslano na e-naslov.",
	TeamEdit_Update: "Če omogočite skrbniško funkcijo podjetja, temu zaposlenemu samodejno zagotovite popoln dostop do svojega poslovnega računa. Omogočanje te funkcije zahteva, da se uporabnik odjavi in se ponovno vrne na spletno stran, če je uporabnik že prijavljen.",
	TeamEdit_PersonalAccount: "Omogočite osebni spletni račun Bosch Training Solutions",
	TeamEdit_SendInvitation: "Pošlji povabilo",
	TeamEdit_Deactivate: "Dezaktivirajte",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Številka mobilnega telefona mora vsebovati le številke",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telefonska številka mora vsebovati le številke",
	TeamEdit_Activate: "Aktivno",
	TeamEdit_NotActivated: "Ni aktivno",
	TeamEdit_StatusPopup: "Status se prikazuje kot aktiven, če je uporabnik uspešno ustvaril osebni račun. Če se prikazuje neaktiven status, to pomeni, da uporabnik ni dokončal vašega povabila.",
	Training_Catalog_Courses: "Tečaji",
	Training_Catalog_Course: "Tečaj",
	Reset_Filter: "Ponastavi filtre",
	TrainingCatalogue_CourseCatalogue: "Katalog tečajev",
	CheckoutHeader_CartItemCount_Text: "V svojem nakupovalnem vozičku imate [count] izdelek",
	CheckoutHeader_CartItemsCount_Text: "V svojem nakupovalnem vozičku imate [count] izdelkov",
	ShoppingCart_PriceItem: "Cena izdelka",
	ShoppingCartItemParticipants_AddParticipant_Button: "Dodaj udeleženca",
	ShoppingCartTotalPrice_SubTotalLabel: "Vmesna vsota",
	ShoppingCartTotalPrice_TaxesLabel: "Davki",
	ShoppingCartTotalPrice_TotalLabel: "Skupno",
	ShoppingCartTotalPrice_IncludedVATLabel: "Vključuje DDV [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Razpoložljive spletne metode plačevanja",
	ShoppingCartItemRow_VenueFeeLabel: "Kotizacija [fee_amount] na udeleženca",
	ShoppingCartItemRow_OnlineLabel: "Spletno",
	AddParticipantsModal_Title: "Dodaj udeleženca",
	AddParticipantsModal_SelectEmployeeLabel: "Dodaj udeleženca",
	AddParticipantsModal_SelectWorkshopLabel: "Izberi delavnico",
	ShoppingCartTotalPrice_ContinueButtonText: "Naprej na blagajno",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Udeleženec še ni bil dodan!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "V svojem nakupovalnem vozičku imate enega ali več tečajev brez udeleženca.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Za nadaljevanje na blagajno dodajte udeleženca.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Uredite udeležence ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Ime",
	AddParticipantsModal_ParticipantTable_Email: "E-pošta",
	AddParticipantsModal_ParticipantTable_Action: "Dejanje",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Ni udeležencev ...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "E-naslov ni konfiguriran za izbranega udeležence",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Izbrani udeleženec nima nastavljenega e-naslova v svojem profilu. E-naslov je obvezen za prijavo na tečaj usposabljanja.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Za nadaljevanje dodajte e-naslov ali prekličite, da dodate drugega udeleženca.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Dodaj e-naslov",
	CancelButton: "Prekliči",
	AddParticipantsModal_AddEmployeeButtonText: "Dodaj zaposlenega",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Izbrisali boste tečaj iz svojega nakupovalnega vozička",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Iz svojega nakupovalnega vozička poskušate odstraniti tečaj z dodanimi udeleženci.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Kliknite \"Nadaljuj\", če želite nadaljevati, ali kliknite \"Prekliči\".",
	ShoppingCartItemHeader_Participants: "Udeleženec(-ci)",
	ShoppingCartItemHeader_ParticipantPrice: "Cena na sedež",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Prikaži v [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Prikaži v kreditih",
	ShoppingCart_CreditsPriceUnit: "Krediti",
	CheckOutHeader_Step1_Question: "Kako želite plačati?",
	CheckOutHeader_Step2_Question: "Preglejte svoje naročilo",
	CheckOutHeader_Step3_Question: "Dokončajte svoje naročilo",
	CheckOutHeader_Step4_Question: "Vaša rezervacija je bila uspešna!",
	CheckOutHeader_Step_Payment: "Plačilo",
	CheckOutHeader_Step_Review: "Pregled",
	CheckOutHeader_Step_Place_Order: "Oddajte naročilo",
	CheckOutRightPanel_Review_Order_Btn: "Preglejte naročilo",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Oddajte naročilo",
	CheckoutRightPanelContent_OrderSummary_Title: "Pregled naročila",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Uredite nakupovalni voziček",
	ShoppingCart_Participants: "Udeleženci",
	CheckOutStepPayment_Payment_Methods_Title: "Izberite način plačila",
	CheckOutStepPayment_Credit_Title: "Kredit ali poravnava",
	CheckOutStepPayment_Wholesaler_Title: "Trgovec na debelo",
	CheckOutStepPayment_ServiceCredit_Title: "Krediti za storitve",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Imate [available_credit] kreditov",
	CheckOutStepPayment_BoschAccount_Title: "Račun Bosch",
	Checkout_Second_PaymentMethod_Headline: "Način plačila",
	CheckOutStepReview_Edit: "Uredi",
	CheckOutStepReview_Terms_Label: "Pogoji poslovanja in politika odpovedi",
	CheckOutStepReview_Terms_Text: "Prebral sem, razumem in se strinjam s [terms, conditions and cancellation policy] prodaje.",
	SelectSecondPaymentMethodModal_Title: "Izberite sekundarni način plačila",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Plačaj [amount]",
	StepPaymentWholesaler_Title: "Izberi veletrgovca",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Sprejmi pogoje",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Pred nadaljevanjem preberite in sprejmite naše pogoje poslovanja in politiko odpovedi.",
	CheckoutDescription_ThankYou: "Zahvaljujemo se vam, da ste naročili pri Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Naša programska oprema je že začela obdelovati vaše naročilo. Medtem pustite motor v prostem teku.",
	CheckoutDesctiption_Text: "Po e-pošti vam bomo poslali dokončno potrditev z vsemi podrobnostmi vaše rezervacije.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Izbrisali boste izbrano delavnico",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Iz izbrisom izbrane delavnice, bodo izbrisani vsi udeleženci. Ta sprememba bo vplivala na vse tečaje v vašem nakupovalnem vozičku.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Kliknite \"Nadaljuj\", če želite nadaljevati, ali kliknite \"Prekliči\" za zaključek tega dejanja.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Nimate zadosti kreditov. Trenutno imate [available_credit] kreditov, vendar potrebujete [cart_total_credits] kreditov za nadaljevanje na blagajno. Izberite drug način plačila ali odstranite izdelke iz svojega nakupovalnega vozička.",
	Training_centers_filter_items: "Centri",
	Training_Programs_Filter_Item: "Programi",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "Leto(-a)",
	Training_Program_Detail_Title: "Programi tečajev usposabljanja",
	AccountButton_Workshops_MenuItem: "Delavnice",
	AccountButton_Wallet_MenuItem: "Denarnica",
	Header_MyBoschProfile_Header: "Profil MyBosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Na voljo",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Dodaj/uredi udeleženca",
	ShoppingCart_CreditPriceUnit: "Kredit",
	Common_IncludedVAT: "Vklj. DDV",
	TrainingCatalogue_Length: "Dolžina",
	TrainingCatalogue_Method: "Metoda",
	Course_Price_Credits: "Krediti",
	Catalogue_Grid_Register_Button: "Registracija",
	Catalogue_Credit_Tilte: "Kliknite tukaj za prikaz cen v kreditih​",
	Catalogue_Price_Tilte: "Kliknite tukaj za prikaz cen v EUR​",
	Catalogue_Grid_Vat: "Brez DDV",
	EventCalendar_Seats: "Sedežev",
	MenuHeader_Calendar: "Koledar",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Minut",
	ListFilter_NoResult: "Ni rezultatov",
	Course_Description: "Opis tečaja​",
	Training_course_detail: "Osnove družbenih omrežij​",
	Training_course_detail_add_waiting: "Dodaj na splošni čakalni seznam​",
	Register_now_btn: "Registrirajte se zdaj!",
	Register_now_btn_add_event_to_cart: "Registrirajte se zdaj!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Oglejte si vse podatke",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Prikaži manj",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Oglejte si vse podatke",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Prikaži manj",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Oglejte si vse podatke",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Prikaži manj",
	TeamCreate_TitleError: "Zahtevan je naslov",
	Dashboard_Headerbar_BookCourse: "Rezervirajte tečaj",
	Dashboard_Headerbar_ExitProxy: "Zapri proxy",
	Dashboard_Headerbar_EvaluateCourse: "Ocenite tečaj",
	Dashboard_Headerbar_EvaluateCourse_Note: "Ocenite ta tečaj, da ustvarite svoj certifikat!",
	Dashboard_Headerbar_DownloadCertificate: "Prenos certifikata",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Odjavi",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Odjavi",
	Dashboard_Headerbar_WelcomeMessage: "Pozdravljena na vaši učni nadzorni plošči",
	Dashboard_Headerbar_ProxingAs: "Proxy kot",
	Dashboard_TrainingProgram_Status_Completed: "Končano",
	Dashboard_TrainingProgram_Status_InProgress: "V teku",
	External_SelectWholesalerModal_Title: "Dodaj zunanji uporabniški dostop",
	External_SelectWholesalerModal_Company_Label: "Podjetje",
	External_SelectWholesalerModal_Outlet_Label: "Prodajalna",
	External_SelectWholesalerModal_Company_Placeholder: "Prosimo, izberite.",
	External_SelectWholesalerModal_Outlet_Placeholder: "Prosimo, izberite.",
	External_SelectWholesalerModal_ApplyButton: "Uporabi",
	External_SelectWholesalerModal_CancelButton: "Prekliči",
	External_RemoveWholesalerModal_ConfirmLabel: "Potrditev",
	External_SelectWholesalerModal_ConfirmMessage: "Ali ste prepričani, da želite odstraniti tega trgovca na debelo?",
	External_SelectWholesalerModal_YesButton: "Da",
	External_SelectWholesalerModal_NoButton: "Ne",
	Training_program_team_member: "Člani ekipe",
	Training_program_assign_training_program: "Dodaj program usposabljanja",
	Assign_modal_student_confirmation_text1: "Dodali boste [Training Program Name] k svoji učni poti.",
	Assign_modal_student_confirmation_text2: "Želite nadaljevati?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "Maj",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Jul",
	MyFullCalendar_Aug: "Avg",
	MyFullCalendar_Sep: "Sep",
	MyFullCalendar_Oct: "Okt",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dec",
	MenuHeader_CourseCatalog: "Katalog tečajev",
	MenuHeader_Event: "Koledar dogodkov",
	MenuHeader_Program: "Program usposabljanja",
	MenuHeader_Services: "Storitve usposabljanja",
	MenuHeader_Log_out: "Odjava",
	Filter_Location: "Lokacija",
	TeamEdit_UpgradeToCompanyAdministrator: "Nadgradnja na skrbnika podjetja",
	TeamEdit_PersonalWebAccountStatusIs: "Status osebnega spletnega računa je",
	Header_CreateNewEmployee: "Ustvari novega zaposlenega",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Ni prostih sedežev!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Na izbranem dogodku ni prostih sedežev.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Za nadaljevanje izberite drug dogodek ali odstranite že dodanega udeleženca.",
	Common_SuccessModal_Title: "Čestitamo!",
	TrainingProgramDetails_Merit_StatusText: "Vrednost",
	TrainingProgramDetails_Resit_StatusText: "Ponovno",
	EmployeeProfile_UserExistErrorMessage: "Račun že obstaja!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Plačilo ni uspelo. Poskusite znova z drugim načinom plačila.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Uspešno ste dezaktivirali izbranega zaposlenega.",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Shranjeno!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Vaše spremembe so shranjene.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Na tečajih eLearning ni mogoče odstopiti. Za dodatne informacije nas kontaktirajte.",
	Dashboard_Team_PersonalAccount_Pending: "Čakajoče",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Zaposleni je ustvarjen!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Uspešno ste ustvarili zaposlenega.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Ta nastavitev vam omogoča, da zaposlenemu preprečite prijavo na dogodke usposabljanja. Če ta nastavitev ni izbrana, bodo lahko zaposlenega prijavili na izobraževalne dogodke le skrbniki podjetja.",
	Training_catalog_sort_by: "Razvrsti po",
	CourseDetail_EvaluationRestriction_Title: "Tečaja ni mogoče oceniti!",
	CourseDetail_EvaluationRestriction_Message: "Oceno tečaja lahko pošlje le študent. \nŠtudentu naročite, da se prijavi v Bosch Automotive Training Solutions in zaključi oceno tečaja.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Če študent še nima računa, lahko povabilo delite v nastavitvah uporabniškega profila člana ekipe.",
	Breadcrumb_Download_Page: "Prenos",
	Download_Table_Category_Cell: "Kategorija",
	Download_Table_Title_Cell: "Naslov",
	Download_File_Filter_Item: "Datoteka",
	Download_File_Filter_Items: "Datoteke",
	Download_Header: "Območje prenosa",
	DownloadArea_Button_Download: "Prenos",
	Popup_Title_Error: "Napaka",
	Popup_Title_Success: "Uspeh",
	Popup_Title_Info: "Informacije",
	Popup_Title_Warning: "Opozorilo!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Že obstoječe morebitne prijave na dogodek ne bodo preklicane in jih bo treba preklicati posamično.",
	TrainingProgram_PDF_Export: "PDF izvoz",
	TrainingProgram_PDF_Export_Participant: "Udeleženec:",
	TrainingProgram_PDF_Export_Status: "status:",
	TrainingProgram_PDF_Export_Progress: "Splošni napredek:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Naslov usposabljanja",
	TrainingProgram_PDF_Export_TableHeader_Status: "Stanje",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Ocena",
	TrainingProgram_PDF_Export_TableHeader_Date: "Datum",
	AccountButton_CompanyChange_MenuItem: "Sprememba podjetja",
	SelectCompanyModal_Title: "Izberite Podjetje",
	CloseButton: "Zapri",
	Change_Button: "spremeniti",
	ChangeManagerModal_Content: "Izberite Uporabnik",
	ChangeCompanyModal_HeadOffice: "(Sedež)",
	SelectCompanyModal_Content: "Za prijavo izberite podjetje ...",
	AlertSuccess_Title: "Uspeh",
	CheckOutStepPayment_SecondaryPayment_Title: "Izberite sekundarno plačilno sredstvo",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Dodajte sekundarno plačilno sredstvo",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Spodaj dodajte drugo plačilno sredstvo ali zgoraj izberite drugo plačilno sredstvo.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Na svojem računu nimate dovolj dobroimetja za storitve za plačilo izdelkov v košarici. Za nadaljevanje uporabe izbranega načina plačila morate doplačati razliko. Razlika je [razlika_znesek] (brez DDV).",
	ShoppingCartTotalPrice_TotalInCredit: "Skupna cena v dobropisih",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Vaši razpoložljivi krediti",
	ShoppingCartTotalPrice_YourDifference: "Razlika",
	ShoppingCartTotalPrice_SubtotalExTax: "Vmesni seštevek brez davek",
	ShoppingCartTotalPrice_SubtotalInTax: "Vmesni seštevek vklj. davek",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Sekundarni način plačila",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Uredi način plačila",
	Checkout_Second_PaymentMethod: "Drugi način plačila",
	Breadcrumb_TrainingCourseDetails: "Podrobnosti tečaja usposabljanja",
	DownloadArea_FailedToDownload_ErrorMessage: "Prenos dokumenta ni uspel. Poskusite znova ali se za pomoč obrnite na skrbnika podjetja.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Datoteka za prenos ni na voljo. Poskusite znova ali se za pomoč obrnite na skrbnika podjetja.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Prenos datoteke je prepovedan. Poskusite znova ali se za pomoč obrnite na skrbnika podjetja.",
	SSO_Redirecting_Message: "Preusmerjate se na Boschev portal za usposabljanje ...",
	SSO_Redirecting_ParameterErrorMessage: "Država in/ali jezik nista podana. Poskusite znova ali se za pomoč obrnite na skrbnika podjetja.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Zahteve za vpis niso sprejete.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "To je lahko posledica tega, da ste znotraj praga za registracijo tečaja za enega ali več tečajev. Prosimo, uporabite področje 'Stik z nami', če imate kakršna koli vprašanja ali zahteve.",
	TimeSlotPicker_DateFormat_Title: "Format datuma",
	UpdateAccountSettingsConfirmationModal_Title: "Spremenili boste obliko datuma!",
	UpdateAccountSettingsConfirmationModal_Message1: "Oblika datuma bo prikazana glede na vašo izbiro in jo lahko kadar koli spremenite.",
	UpdateAccountSettingsConfirmationModal_Message2: "Želite nadaljevati?",
	ProfileSummary_Settings_Title: "nastavitve",
	UpdateAccountSettings_DateFormat_Title: "Nastavitve zapisa datuma",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Formata datuma ni bilo mogoče posodobiti.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Nastavitev zapisa datuma je bila uspešno posodobljena.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Prenesite potrdilo",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Potrdilo o programu usposabljanja",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Prenos ni uspel. prosim poskusite ponovno Če težave ne odpravite, se obrnite na nas preko kontaktnega obrazca.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Prag dviga presežen",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Prag za umik iz tega tečaja je bil presežen in zahteva ročno obdelavo s strani naše uprave za usposabljanje.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Končna potrditev bo poslana po e-pošti.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Za več podrobnosti si oglejte naše [politike odpovedi].",
	TrainingCourse_Course_NotAvailable_Title: "Tečaj usposabljanja ni na voljo",
	TrainingCourse_Course_NotAvailable_Message: "Tečaj usposabljanja ni na voljo. Poskusite znova ali se za nadaljnjo pomoč obrnite na skrbnika podjetja.",
	TrainingCourse_NotAvailable_Message_1: "Poskušate si ogledati tečaj usposabljanja, ki ni na voljo.",
	TrainingCourse_NotAvailable_Message_2: "Vrnite se na naš [katalog usposabljanj] in poiščite vse razpoložljive tečaje.",
	LanguageSelection_SelectALanguage: "Izberite jezik",
	LanguageSelection_SelectYourCountry: "Izberite svojo državo",
	Checkout_PaymentMethod_Headline: "Način plačila",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Koledar",
	Download_Categories_General: "Splošno",
	Download_Categories_Information: "Informacije",
	Download_Categories_Userguides: "Navodila za uporabo",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "politika odpovedi",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Prekliči",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Nadaljuj",
	Duration_Seconds: "sekund",
	Duration_Day: "Dan",
	Duration_Hour: "ura",
	Duration_Minute: "minuta",
	Duration_Second: "drugič",
	EventCalendar_ExternalLink_Tooltips: "Nakup prek [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Prekliči",
	EventCalendar_ExternalLink_Continue: "Nadaljuj",
	EventCalendar_ExternalLinkModal_Title: "Preusmerjeni boste ...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Izbrani dogodek se prodaja prek [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Če nadaljujete, boste preusmerjeni na zunanje spletno mesto.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Bi radi nadaljevali?",
	Team_ContactPerson_FirstName_Tooltips: "Imena učencev lahko spremeni samo Boscheva ekipa za administracijo usposabljanja. Za nadaljnjo podporo nas kontaktirajte preko kontaktnega obrazca na tej spletni strani.",
	Team_ContactPerson_LastName_Tooltips: "Imena učencev lahko spremeni samo Boscheva ekipa za administracijo usposabljanja. Za nadaljnjo podporo nas kontaktirajte preko kontaktnega obrazca na tej spletni strani.",
	Withdraw_ExternalLink_Cancel: "Prekliči",
	Withdraw_ExternalLink_Confirm: "Potrdi",
	Withdraw_ExternalLinkModal_Title: "Preusmerjeni boste",
	Withdraw_ExternalLinkModal_Message: "Dvige za ta tečaj obravnava zunanji ponudnik.",
	Withdraw_ExternalLinkModal_Confirmation: "Če kliknete \"Potrdi\", se odpre nov zavihek in vas preusmeri na zunanje spletno mesto.",
	Button_AddToWaitingList_Tooltips: "Dodaj na čakalni seznam",
	Button_AddToCart_Tooltips: "Dodaj v košarico",
	Button_TrainingCourseDetail_Tooltips: "Poglej podrobnosti",
	Button_TrainingProgramDetail_Tooltips: "Poglej podrobnosti",
	Button_AssignTrainingProgram_Tooltips: "Določite program usposabljanja",
	Button_DeleteFromCart_Tooltips: "Izbriši iz košarice",
	Button_RemoveParticipant_Tooltips: "Odstrani udeleženca",
	Button_DownloadBillingTransaction_Tooltips: "Prenos",
	Button_RemoveExternal_Tooltips: "Odstrani",
	Breadcrumb_TrainingServices: "Storitve usposabljanja",
	Breadcrumb_Support: "Podpora",
	Breadcrumb_FAQs: "pogosta vprašanja",
	Breadcrumb_TrainingProgramDetail: "Podrobnosti o programu usposabljanja",
	Breadcrumb_MyTraining_MyTrainingEvents: "Moji dogodki usposabljanja",
	Breadcrumb_MyTraining_External: "Zunanji",
	Breadcrumb_MyTraining_Wallet: "Denarnica",
	Breadcrumb_MyTraining_Workshop: "Delavnica",
	Breadcrumb_MyTraining_Workshop_Team: "Ekipa",
	Breadcrumb_MyTraining_Team_Profile: "Profil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Uredi profil",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Ustvarjanje zaposlenih",
	SideNavigation_Title: "Rešitve za usposabljanje",
	SideNavigation_AccountButton_RegisterOrLogin: "Registracija / Prijava",
	SideNavigation_Language: "Jezik",
	SideNavigation_SelectLanguage: "Izberite jezik",
	SideNavigation_AccountButton_Logout: "Odjava",
	SideNavigation_ConntactUs: "Kontaktirajte nas",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Informacije o podjetju",
	FooterAdditionalLinks_Navigation_LegalNotice: "Pravno obvestilo",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Obvestilo o varstvu podatkov",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Nastavitve zasebnosti",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Pogoji in določila",
	Common_Version_Text: "Različica: {{version}}",
	Content_Header_Dashboard_MyTrainingEvents: "Moji dogodki usposabljanja",
	Content_Header_Dashboard_MyTrainingPrograms: "Moji programi usposabljanja",
	Content_Header_Dashboard_Team: "Ekipa",
	Content_Header_Dashboard_Workshops: "Delavnice",
	Content_Header_Dashboard_External: "Zunanji",
	Content_Header_Dashboard_Wallet: "Denarnica",
	Content_Header_Dashboard_MyProfile: "Moj profil",
	RedeemVoucher_RemoveButton: "Odstrani",
	RedeemVoucher_ApplyButton: "Prijavite se",
	Checkout_VoucherApplied_Label: "Vaša koda",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Neveljavna koda bona",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Uporabljena koda kupona ni veljavna.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Poskusite znova ali nadaljujte brez kode kupona.",
	RedeemVoucherModal_CancelButton: "Prekliči",
	RedeemVoucherModal_TryAgainButton: "poskusi ponovno",
	CreditsConfirmationModal_ContinueButton: "Nadaljuj",
	CreditsConfirmationModal_CancelButton: "Prekliči",
	CreditsConfirmationModal_Title: "Vavčer je bil uporabljen",
	CreditsConfirmationModal_Message_1: "Plačilo z dobroimetjem za storitve ni mogoče, če je bil v nakupovalni voziček pripisan kupon s popustom.",
	CreditsConfirmationModal_Message_2: "Kliknite \"Prekliči\", da se vrnete nazaj, ali kliknite \"Nadaljuj\", da odstranite kupon iz košarice."
};