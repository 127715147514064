import React from "react";
import { Redirect, Route, RouteProps, useParams } from "react-router-dom";

interface IPublicRouteProps extends RouteProps {
  countryCode: string | null;
  domain: string;
}

const PublicRoute: React.FC<IPublicRouteProps> = ({
  component: Component,
  countryCode,
  domain,
  ...rest
}) => {
  const isSelectedRegion = !!countryCode && countryCode !== "";
  //const mainSiteDomain = RedirectHelper.extractDomainName(defaultClientRoot);
  //const currentDomain = RedirectHelper.extractDomainName(window.location.href);
  useParams<{
    guid: string;
    paramCountryCode: string;
    paramLanguageCode: string;
  }>();
  return (
    <Route
      {...rest}
      render={(insideProps) => {
        const urlParams = new URLSearchParams(window.location.search);
        const country = window.location.search !== "" ? urlParams.get("c") : "";
        const lang = window.location.search !== "" ? urlParams.get("l") : "";
        const isGeo =
          window.location.search !== "" ? urlParams.get("isGeo") : "";
        if (!!country && !!lang && isGeo !== "1") {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: insideProps.location },
              }}
            />
          );
        }

        if (window.location.href.includes("/RegisterPersonalAccount")) {
          return Component ? (
            <Component {...insideProps} />
          ) : (
            <Redirect
              to={{
                pathname: "/exception?type=401",
                state: { from: insideProps.location },
              }}
            />
          );
        }

        if (window.location.href.includes("/course-detail")) {
          return Component ? (
            <Component {...insideProps} />
          ) : (
            <Redirect
              to={{
                pathname: "/exception?type=401",
                state: { from: insideProps.location },
              }}
            />
          );
        }

        if (
          !window.location.href.includes("/regions") ||
          window.location.href.includes("/redirect-subdomain") ||
          window.location.href.includes("/exception") ||
          window.location.href.includes("/clear-state") ||
          window.location.href.includes("/privacy-policy") ||
          window.location.href.includes("/term-condition") ||
          window.location.href.includes("/corporate-information") ||
          window.location.href.includes("/legal-notice") ||
          window.location.href.includes("/data-protection-notice")
        ) {
          // to test in redirect in localhost, use the if below
          // if ((!isSelectedRegion) && CommonService.isDev())
          //if (currentDomain === mainSiteDomain || !isSelectedRegion) {
          if (!isSelectedRegion) {
            return (
              <Redirect
                to={{
                  pathname: "/regions",
                  state: { from: insideProps.location },
                }}
              />
            );
          }
        }

        return Component ? (
          <Component {...insideProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/exception?type=401",
              state: { from: insideProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default PublicRoute;
