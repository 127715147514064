import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Embed } from "semantic-ui-react";

const LaunchLinkRedirect: FC<any> = (props) => {
    const { t } = useTranslation();

    return (
        <div style={{ paddingTop: "15%", textAlign: "center" }}>{t("MyELearning_VideoPlayerClosingMessage")}</div>
    );
};


export default LaunchLinkRedirect;
