export const TRANSLATIONS_ET_EE = {
	LandingPage_Title: "Tere tulemast keskkonda Bosch Automotive Training Solutions!",
	LandingPage_SubTitle_ChooseCountry: "Valige oma riik/regioon",
	Header_ContactUs: "Võtke meiega ühendust",
	Header_Search: "Otsi",
	Header_Menu: "Menüü",
	Header_LogInOrCreateYourAccount: "Logige sisse või looge konto",
	Header_LogIn: "Logi sisse",
	Header_Cart: "Korv",
	Header_ShoppingCart: "Ostukorv",
	Header_LogOut: "Logi välja",
	Header_ChangeDetails: "Muutke üksikasju",
	Footer_ExploreOurTraining: "Tutvuge meie koolitusega",
	Footer_TrainingAtYourFingertips: "Koolitus on käeulatuses",
	Footer_DontMissAnyStory: "Ärge jääge ilma ühestki loost",
	Footer_ExploreOurStories: "Tutvuge meie lugudega",
	Footer_GetInTouchWithUs: "Võtke meiega ühendust",
	Footer_SendUsAMessage: "Saatke meile sõnum",
	Footer_ToContactForm: "Liikumine kontaktivormile",
	Footer_InventedForLife: "Loodud kestma",
	Footer_GeneralContactInfo: "Üldine kontaktteave",
	Footer_ProductSecurity_Psirt: "Toote turvalisus (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Uuenduste patendid ja litsentsid",
	Footer_PurchasingLogistics: "Ostmine ja logistika",
	Footer_CorporateInformation: "Ettevõtte info",
	Footer_LegalNotice: "Õiguslik teade",
	Footer_DataProtectionNotice: "Andmekaitseteatis",
	Footer_PrivacySettings: "Andmekaitsesätted",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch OÛ",
	Footer_StillLookingForSomething: "Kas ikka otsite veel midagi?",
	Footer_TrainingServices: "Service Training",
	Footer_Courses: "Kursused",
	Footer_Programs: "Programmid",
	Footer_EventsCalendar: "Plaanitud sündmused",
	Footer_TrainingCenters: "Koolituskeskused",
	Footer_Dashboard: "Koondvaade",
	Footer_MyDashboard: "Minu koondvaade",
	Footer_ShoppingCart: "Ostukorv",
	Footer_YourShoppingCart: "Teie ostukorv",
	Footer_Administration: "Haldus",
	Footer_Employees: "Töötajad",
	Footer_Wholesalers: "Hulgimüüjad",
	Footer_Transactions: "Tehingud",
	Footer_Workshops: "Töökojad",
	Footer_WorkLikeABoschApplyNow: "Tööta  – kandideeri kohe.",
	Footer_BoschGlobalCareers: "Boschi karjäärivõimalused",
	Footer_PurchasingAndLogistics: "Ostmine ja logistika",
	Footer_BecomeABusinessPartner: "Hakake äripartneriks.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Kuidas meie koolitus aitab teie ettevõtet?",
	Footer_Language: "Riigi valimine",
	MenuPopup_MyCart: "Minu ostukorv",
	Stories_OurTrainingStories: "Meie lood koolitustest",
	Stories_ConnectingExperts: "Viime kokku eksperte ja entusiaste, unistajaid ja tegijaid, uuendajaid ja mõjuisikuid – tutvuge ja kogege",
	Stories_TopStories: "Populaarseimad lood",
	Stories_AtHome: "Kodus",
	Stories_Careers: "Karjäär",
	Stories_History: "Ajalugu",
	Stories_IndustryAndTrades: "Tööstus ja kaubandus",
	Stories_Mobility: "Mobiilsus",
	Stories_Research: "Uurimistöö",
	Stories_Sustainability: "Jätkusuutlikkus",
	Stories_AllStories: "Kõik lood",
	Stories_BlogATrainersDay: "BLOGI | Koolitaja päev",
	Stories_ADayinLifeOfATrainer: "Üks päev koolitaja elus",
	Stories_BoschServiceTraining: "Bosch Service koolitus",
	Stories_StoryWhatDrivesYouDrivesUs: "LUGU | Teile oluline on oluline ka meile",
	Stories_StoryHowCanWeBenefitYourBusiness: "LUGU | Kuidas saame teie ettevõttele kasu tuua?",
	Stories_HowOurTrainingEvolves: "Kuidas meie koolitus areneb?",
	Stories_AModernTrainingApproach: "Veebipõhine koolitusmaailm",
	Stories_StoryAVirtualOnlineTrainingWorld: "LUGU | Teie veebipõhised koolitusvõimalused",
	Stories_SubscribeToRSSFeed: "RSS-kanali tellimine",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Meist",
	Homepage_AutomotiveAftermarket: "Autotööstuse järelturg",
	Homepage_GetInTouch: "Võtke ühendust",
	Homepage_LatestNews: "Tutvuge, miks meid ajendab teadmisjanu",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Meie vabad töökohad",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Teile oluline on oluline ka meile",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service koolitus",
	BoschServiceTraining_ShareThisOn: "Jagage seda siin",
	BoschServiceTraining_TheChallenge: "Väljakutse: uue tehnoloogia omandamine",
	BoschServiceTraining_WorldwidePresence: "Ülemaailmne kohalolek",
	BoschServiceTraining_TechnicalTraining: "Boschi tehnilised koolitused",
	BoschServiceTraining_TheOffersAndOpportunities: "Boschi pakkumised ja võimalused",
	BoschServiceTraining_TechnicalCoursesDesc: "Iga sihtgrupi jaoks on saadaval sobivad koolitused - pole tähtis, kas tegu on algajate või proffidega. Pakkumine sisaldab ühepäevaseid ja mitmepäevaseid koolitusi. Pakume erinevate tehnikavaldkondade ja koolitussarjade koolitusi (näiteks bensiini-, diiselmootori juhtimine, elektrisüsteemid). Tehnilistel koolitustel jagab Bosch laialdast oskusteavet Boschi kõigist sõiduki standardsüsteemidest, samuti on jutuks ka teiste valmistajate süsteemid. Koolituse sisu on loodud vastama töökoja töötajate spetsiifilistele vajadustele.",
	BoschServiceTraining_TrainedEmployeesDesc: "Koolitatud töötajad on autotöökodade jaoks ilmselgelt kasulikud. Tänapäevased sõidukid muutuvad üha keerulisemaks ja uus tehnoloogia esitab pidevalt uusi väljakutseid. Ainult vajalike teadmiste olemasolul saavad töökoja töötajad teha praeguste sõidukimudelite diagnostika- ja remonditöid efektiivselt ning kulutõhusalt.",
	BoschServiceTraining_RegardlessOfHow: "Sõltumata sellest, kui head ja kogenud on autospetsialistid, tekib alati olukordi, kus puututakse kokku uue sõiduki tehnoloogia või tundmatute vigadega. Seetõttu võib tõrkeotsing ja probleemide lahendamine muutuda eriti ajamahukaks või lihtsalt ei pruugi anda häid tulemusi. Halvimal juhul peavad kliendid olema ilma sõidukita eeldatust oluliselt kauem.",
	BoschServiceTraining_ItIsPrecisely: "Bosch pakub oma hoolduskoolitust just sellise olukorra vältimiseks, et parandada hoolduse kvaliteedi ja kiirendada protsessi. Käesolev Boschi koolitus pakub tuge probleemide kiiremaks lokaliseerimiseks, parandades sama ajaga rohkem sõidukeid ja vähendades klientide ooteaegu. Boschilt õpitud süsteemsed protseduurid tagavad kiirema ja produktiivsema töö.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Paljude maailma juhtivate autotootjate jaoks on Bosch olnud juba üle 125 aasta eelistatuim originaalseadmete, osade, komponentide ja süsteemide tarnija. Sellele kogemusele tuleb lisada ainulaadsed teadmised uusimast tehnoloogiast, mis on aluseks Boschi toele.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Bosch Service koolituskeskusi võib leida kõikjal maailmas: Hispaaniast kuni Türgi, Lõuna-Aafrika, Austraalia ja Brasiiliani – Bosch pakub oma töötajatele praktilist koolitust, mis on alati kooskõlas tipptasemel tehnoloogiatega.",
	BoschServiceTraining_AgileCompany: "Paindlik ettevõte",
	BoschServiceTraining_Collaboration: "Koostöö",
	BoschServiceTraining_ConnectedMobility: "Ühendatud mobiilsus",
	BoschServiceTraining_ArtificialIntelligence: "Tehisintellekt",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Dialoogi pidavad arvamusliidrid",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostika ja testimisseadmed",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Autotehnika alused",
	BoschServiceTraining_Icon_Gasoline: "Bensiinimootorid",
	BoschServiceTraining_Icon_Diesel: "Diiselmootorid",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternatiivsed ajamid",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elekter ja elektroonika",
	BoschServiceTraining_Icon_BodyAndComfort: "Kere- ja mugavussüsteemid",
	BoschServiceTraining_Icon_ChassisAndBraking: "Pidurisüsteemid",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Käigukast ja jõuülekanne",
	BoschServiceTraining_Icon_CommercialVehicles: "Kommertssõidukid",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Veebikoolitused",
	BoschServiceTraining_Icon_BusinessSkills: "Ärioskused",
	BoschServiceTraining_Icon_Accreditations: "Akrediteeringud",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Koolitusprogrammid",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "KoolitajaEesNimiPerekonnaNimi",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Päev koolitaja elust",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "iga päev on seiklus",
	ADayInTheLifeOfATrainer_Summary: "Kokkuvõte",
	ADayInTheLifeOfATrainer_TheDayJourney: "Päeva teekond",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Iga päev on kiire päev, kuid see on alati rahuldust pakkuv kogemus. Rõõm koolitada õpilasi teadmisega, et nende käes on meie tuleviku võti, on vastutusrikas, kuid samas nii põnev. Nähes, kuidas õpilased kasvavad iga õppetunniga iga päev ja iganädalaselt, tõestab meile, et töötame ühise eesmärgi nimel, et parandada nende teadmisi, kaasates samal ajal tänapäevase tehnoloogia, mis juhib tulevikku!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Iga päeva lõpus istume maha ja võtame kokku päeva töö. Päevad lähevad nii kiiresti, kuid see on tõestus sellest, et see, mida me saavutame, pole kasulik mitte ainult õpilastele, vaid ka meile kui koolitajatele.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Õhtul võime minna koju tundega, et oleme teinud head tööd!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Alusta",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Ettevalmistus ja viimane koolitusmaterjali kontroll. Tähtsate meilide kontroll",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Koolitus algab",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Osalejate tervitamine. Päevakava tutvustamine. Tutvumine grupiga mõistes iga osaleja dünaamikat.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teooria",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Selleks, et koolitus oleks võimalikult huvitav, on vaja teatud määral grupiga suhtlemist. Veenduge, et teooriaga on põimunud ülesanded, et rühma veelgi kaasata",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Lõunapaus",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktiline töö",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Koolitus jätkub töökojas mõne praktilise tegevusega. Koolitajana peab alati olema kohal ja saadaval, et juhendada ja nõu anda",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Kokkuvõte",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Hetkeks tagasi klassi, et päev kokku võtta ning vastata tekkinud küsimustele. Alustada ettevalmistusi järgmiseks päevaks.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Viimane päev",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Koolituse tunnistused antakse osalejatele.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Koju",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Kuidas saame teie ettevõttele kasu tuua",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Kuidas meie koolitus areneb?",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Arendame end pidevalt, et tagada kvaliteetsete ja ajakohaste koolituste pakkumine",
	BoschTrainingBenefits_TheBoschServiceTraining: "Bosch Service koolituskeskus soovib teid aidata teie ettevõtte arendamisel oma ulatusliku ja väga asjakohase tehniliste/ärikoolituste ja sertifitseeritud kvalifikatsiooniga täiendõppe kursuste portfelli kaudu. Järgmistel lehekülgedel saate ülevaate meie koolitusprogrammist ja praegustest täiendõppe võimalustest. Ootame teid ja teie töötajaid meie koolitustele ja seminaridele.",
	BoschTrainingBenefits_LongStandingExperience: "Pikaajaline kogemus ja laialdane oskusteave kõigi süsteemide ja autotehnoloogia valdkonnas.",
	BoschTrainingBenefits_QuickAndEfficient: "Kiire ja efektiivne lahendus paljudele probleemidele",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Eelised",
	BoschTrainingBenefits_LMS: "Learning Management System - Bosch Training solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions hõlmab kõiki autotööstuse valdkondi. Spetsialiseeruge kindlatele valdkondadele või laiendage oma pädevust Boschi koolituspakkumiste abil.",
	BoschTrainingBenefits_AcquiredKnowledge: "Omandatud teadmised hõlbustamaks remondi- ja hooldustööid.",
	BoschTrainingBenefits_TheHighDegree: "Suur praktiliste tööde osakaal ja väikesed grupid garanteerivad eesmärgipõhise koolituse",
	BoschTrainingBenefits_ParticipationInTraining: "Koolitustel osalemine tõstab töötajate motivatsiooni ja rahulolu ning täiendav kvalifikatsioon veenab kliente",
	BoschTrainingBenefits_IncreasedSales: "Suurenenud käive tänu teadmistele erinevatest markidest/süsteemidest",
	BoschTrainingBenefits_MethodicalProcedures: "Metoodilised protseduurid vähendavad klientide ooteaega",
	BoschTrainingBenefits_HighlyQualified: "Kõrgelt kvalifitseeritud instruktorid edastavad süstemaatilisi protseduure",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Teile või teie ettevõttele sobiva koolituslahenduse leidmine",
	BoschTrainingBenefits_DirectOnlineBooking: "Kursuste veebipõhine otsebroneerimine ja haldamine  endale või oma töötajatele",
	BoschTrainingBenefits_OnlineAccess: "Veebipõhine juurdepääs õppedokumentatsioonile",
	BoschTrainingBenefits_AccessToYourOwn: "Juurdepääs isiklikule koolitusajaloole ja sertifikaatidele",
	BoschTrainingBenefits_ToolsForManagement: "Tööriistad oma karjääriarengu haldamiseks",
	BoschTrainingBenefits_ManagerHasFull: "Juhil on süsteemis täielik ülevaade oma töötajate kursustest",
	BoschTrainingBenefits_Access24hours: "Juurdepääsetav 24/7",
	ModernTrainingApproach_KnowledgeIsKey: "Teadmised on iga töökoja peamine edutegur. Seepärast pakub Bosch põhjalikku professionaalset koolitusprogrammi. See võimaldab õppijatel omandada täpselt õiged teadmised õigel ajal ja kohas – teadmised, mis on vajalikud kliendi sõiduki professionaalseks hooldamiseks ja parandamiseks. Bosch on juba pakutavale e-õppe paketile uue mooduliosana lisanud veebikoolituse. Need koosnevad spetsiaalselt voogesituseks mõeldud hoolduskoolitustest.",
	ModernTrainingApproach_ANewLearning: "Uus õppimisvõimalus",
	ModernTrainingApproach_YourOnlineTraining: "Teie veebipõhised koolitusvõimalused",
	ModernTrainingApproach_RecentlyTheWorld: "Maailm on muutunud, vaadake, mida me teeme, et aidata",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Veebipõhine koolitusmaailm",
	ModernTrainingApproach_NewTeachingMethod: "Uus õpimeetod",
	ModernTrainingApproach_InAdditionTo: "Lisaks tavapärasele vahetule koolitusele oleme koolitusprogrammi lisanud reaalajas veebiülekandekoolituse. Meie laialdaste teoreetiliste teadmistega koolitaja on teie käsutuses kas virtuaalses klassiruumis või praktiliste näidetega. Nagu tavaliselt, saate palju teavet sõidukisüsteemide ja vastavate Boschi toodete kohta. Loomulikult on teil ka võimalus küsimusi esitada. Nendele küsimustele vastatakse otse veebiülekandes. Reaalajas veebiülekanded kestavad 60–120 minutit. Vajalik on stabiilne Interneti-ühendus. Otsige sobivat veebiülekannet meie koolituskataloogist, sisestades otsinguväljale märksõna \Webcast\.",
	ModernTrainingApproach_BoschWebcastingHeader: "Boschi veebiülekanne: uuenduslik ja praktiline",
	ModernTrainingApproach_BoschWebcastingText: "Boschi veebiülekandes osalemiseks peab osaleja esmalt sisse logima, selleks saadetakse enne seanssi individuaalne juurdepääsukood. Korraga saab osaleda kuni 200 osalejat olenemata nende asukohast. Koolitust juhib koolitaja ja küsimusi saab igal ajal esitada reaalajas vestluse funktsiooni kaudu.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Digilisa koolitusele klassiruumis",
	ModernTrainingApproach_TheDigitalSupplementText: "Veebiülekanne ja klassiruumikoolitus täiendavad teineteist suurepäraselt. Mõlemal juhul juhib koolitust kvalifitseeritud koolitaja. Veebiülekanded sobivad teemade jaoks, mida saab edastada digitaalselt, ilma et oleks vaja sõidukiga töötada. Näiteks ESI[tronic] teatavate funktsioonide tundmaõppimine uue juhiabisüsteemi jaoks. Mis puutub tõhusasse meeskonnatöösse töökojas või praktilisesse töösse sõidukitehnoloogiaga, siis on vahetu koolitus endiselt esimene valik.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Voogesitus võib olla odavam",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Kulud osaleja kohta on veebiülekande puhul tavaliselt väiksemad kui võrreldava klassiruumi kursuse korral. Põhjuseks on see, et sellest saab kasu rohkem osalejaid ja koolitusega seotud jõupingutused on oluliselt väiksemad (edasi-tagasi sõitmist ei ole ning vaja on vähem aega).",
	ModernTrainingApproach_BookAndProfitHeading: "Broneerimine ja kasu",
	ModernTrainingApproach_BookAndProfitText: "Veebiülekandeid saab broneerida meie veebisaidil nagu ka teisi meie koolituskursusi ja õppepakkumisi. Võite valida, kas maksta iga veebiülekande eest individuaalset tasu või võtta iga-aastane tellimus teatud arvu veebiülekannete jaoks.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Boschi veebiülekande ja klassiruumikoolituse võrdlus",
	ModernTrainingApproach_Webcasting: "Veebiülekanne",
	ModernTrainingApproach_Classroom: "Klassiruum",
	ModernTrainingApproach_WebcastingP1: "Korraga saab osaleda kuni 200 inimest kõikjalt maailmast",
	ModernTrainingApproach_WebcastingP2: "Vähene keerukus ja suhteliselt madalad kulud, reisimine pole vajalik",
	ModernTrainingApproach_WebcastingP3: "Ideaalne konkreetsete ja lühikeste üksikteemade jaoks",
	ModernTrainingApproach_WebcastingP4: "Töökojad saavad oma töötajatele korraldada veebiülekande igal pool ja igal ajal",
	ModernTrainingApproach_WebcastingP5: "Ajakulu on 50–70% väiksem kui klassiruumikoolitustel",
	ModernTrainingApproach_ClassroomTrainingP1: "Korraga osaleb kuni 16 inimest",
	ModernTrainingApproach_ClassroomTrainingP2: "Sõidukulud ja suuremad üldkulud inimese kohta",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideaalne koostöö optimeerimiseks või õppimiseks sõiduk juures",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktilised harjutused ja suhtlemine koolitajaga",
	ModernTrainingApproach_ClassroomTrainingP5: "Koolitaja saab õpetatavaid teadmisi paremini kohandada vastavalt osalejate tegelike vajadustega",
	ModernTrainingApproach_OnlineTrainingOfferings: "Veebipõhised koolituspakkumised (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Tänapäeval on iseseisev õppimine arvutis teadmiste edasiandmise lahutamatu osa. Vabadus valida aega ja kohta, piiramatu arv võimalusi kordamiseks, individuaalne õpikiirus ning interaktiivne õppesisu muudavad selle meetodi hädavajalikuks õppevahendiks.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Iga veebipõhise koolituse (WBT) element on hoolikalt struktureeritud. Lisaks laialdase teadmistebaasi pakkumisele võivad need kursused olla tõhusaks ettevalmistuseks Boschi koolituskeskuste programmide läbimiseks.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Virtuaalne diagnostika simulatsioon pakub kasutajale rollimängu võimalust igapäevases töökoja olukorras, näiteks tõrkeotsingu protseduuri töövoog Boschi diagnostikaseadmetega virtuaalses töökojas.",
	ModernTrainingApproach_Advantages: "Eelised",
	ModernTrainingApproach_AdvantagesP1: "Kiire ja tõhus meetod teadmiste edastamiseks",
	ModernTrainingApproach_AdvantagesP2: "Mitmekesine õppimine",
	ModernTrainingApproach_AdvantagesP3: "Võib kasutada nii tihti kui soovite",
	ModernTrainingApproach_AdvantagesP4: "Minimaalne aja- ja rahakulu",
	ModernTrainingApproach_AdvantagesP5: "Individuaalne õppimiskiirus",
	ModernTrainingApproach_AdvantagesP6: "Paindlik õppeaeg",
	ModernTrainingApproach_AdvantagesP7: "Korratavus",
	ModernTrainingApproach_AdvantagesP8: "Reisikulud puuduvad",
	ModernTrainingApproach_AdvantagesP9: "Kapasiteedi- või planeerimiskonfliktid puuduvad",
	Dashboard_CoursesViewAndManageYourCourses: "KURSUSED | Kursuste vaatamine ja haldamine",
	Dashboard_MyCourses: "Minu kursused",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "KOOLITUSPROGRAMM | Koolitusprogrammide vaatamine",
	Dashboard_MyTrainingProgram: "Minu koolitusprogramm",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "E-ÕPE | E-õppe portfelli vaatamine ja haldamine",
	Dashboard_MyELearning: "Minu e-õpe",
	Dashboard_AccountManageYourAccount: "KONTO | Konto haldamine",
	Dashboard_MyAccount: "Minu konto",
	Dashboard_MyDashboard: "Koondvaade",
	Dashboard_ManageYourAccount: "Konto haldamine",
	Dashboard_Workshops: "Töökojad",
	Dashboard_AdministrationManageYourWorkshop: "HALDUS | Töökoja haldamine",
	Dashboard_AdministrationManageYourTeams: "HALDUS | Meeskondade haldamine",
	Dashboard_Administration: "Haldus",
	Dashboard_ViewYourCourses: "Kursuste vaatamine",
	Dashboard_ViewYourTrainingPrograms: "Koolitusprogrammide vaatamine",
	Dashboard_TrainingPrograms: "Koolitusprogrammid",
	Dashboard_ViewAndEditYourDetails: "Üksikasjade vaatamine ja redigeerimine",
	Dashboard_ViewYourELearningCourses: "E-õppe kursuste vaatamine",
	Dashboard_Pending: "Ootel",
	Dashboard_RequiresAdministratorSignOff: "(nõuab juhtkonna nõusolekut)",
	Dashboard_HistoricalRecords: "Ajaloolised kirjed",
	Dashboard_Registered: "Registreeritud",
	Dashboard_WaitingList: "Ooteloend",
	Dashboard_RecommendedForYou: "Teile soovitatud",
	Dashboard_AccountDetails: "Konto üksikasjad",
	Dashboard_ChangeProfileImage: "profiilipildi muutmine",
	Dashboard_Title: "Pealkiri",
	Dashboard_Role: "Roll",
	Dashboard_FirstName: "Eesnimi",
	Dashboard_LastName: "Perekonnanimi",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobiiltelefon",
	Dashboard_Email: "E-posti aadress",
	Dashboard_Language: "Keel",
	Dashboard_Save: "Salvesta",
	Dashboard_Student: "Õppur",
	Dashboard_WorkshopTrainingAdministrator: "Töökoja koolituse administraator",
	Dashboard_WholesalerTrainingAdministrator: "Hulgimüüja koolituse administraator",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Ettevõtte juhtkond",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Töökodade haldus...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Halda töötajaid...",
	Dashboard_CompanyAdministration_Workshop: "Töökoda",
	Dashboard_CompanyAdministration_Address: "Aadress",
	Dashboard_CompanyAdministration_Zip: "Sihtnumber",
	Dashboard_CompanyAdministration_City: "Linn",
	Dashboard_CompanyAdministration_Manage: "Haldamine",
	Dashboard_AdministrationSection_AdministrationTitle: "Haldus",
	Dashboard_AdministrationSection_ManageTheTeam: "Meeskonna haldamine",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "HALDUS | Töötajate haldamine",
	Dashboard_AdministrationSection_Employees: "Töötajad",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "HALDUS | Hulgimüüjate haldamine",
	Dashboard_AdministrationSection_Wholesalers: "Hulgimüüjad",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "HALDUS | Tehingute haldamine",
	Dashboard_AdministrationSection_Transactions: "Tehingud",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Töötajate haldus",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Halda töötajaid...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registreeri",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Kirjed",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Muuda",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Töötaja",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Lisa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Loo",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registreeritud",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Ootel",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(nõuab juhtkonna nõusolekut)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Ajaloolised kirjed",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Ooteloend",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Praegune töötajate loend",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Töötajate haldamine",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nimi",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Töötajakonto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Töötaja üksikasjade kuvamine ja redigeerimine...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Sätted",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Luba isiklik konto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Töötaja töökojahalduse puhverserveri uuendus",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Luba töötajal registreeruda koolitussündmustele",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Saada meilikutse",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Desaktiveeri konto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Salvesta",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Saada",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Muuda",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Hulgimüüja haldus",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Halda hulgimüüjaid...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Heakskiidetud hulgimüüjate loend",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nimi",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Kustuta",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Loo",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Lisa",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Uue töötajakonto loomine",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Loo uute töötajate üksikasjad...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Lisa uus kinnitatud hulgimüüja haldur",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Loo üksikasjad uute hulgimüüjate jaoks...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Vali rippmenüüst kinnitatud hulgimüüja...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Tehingud",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Vaata ja halda oma tehinguid...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Tagasi",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Arvenumber",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Kirjeldus",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Makseviis",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Kuupäev",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Summa",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Laadi alla",
	TrainingCentres_TrainingCentres: "Koolituskeskused",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Meie koolituskeskused asuvad kõikjal maailmas",
	TrainingCentres_CountryCategory: "Riigi kategooria",
	TrainingCentres_CityCategory: "Linna kategooria",
	TrainingCentres_Country: "Riik",
	TrainingCentres_City: "Linn",
	TrainingCatalogue_TrainingCatalogue: "Koolituskursuste kataloog",
	TrainingCatalogue_LearningAtTheHighestLevel: "Õppimine kõrgeimal tasemel",
	TrainingCatalogue_StartDate: "Alguskuupäev",
	TrainingCatalogue_EndDate: "Lõppkuupäev",
	TrainingCatalogue_TrainingName: "Koolituse nimi",
	TrainingCatalogue_Language: "Keel",
	TrainingCatalogue_Days: "Päeva",
	TrainingCatalogue_Seats: "Vabu kohti",
	TrainingCatalogue_Price: "Hind",
	TrainingCatalogue_Location: "Asukoht",
	TrainingCatalogue_PartNumber: "Artikli number",
	TrainingCatalogue_TrainingCourse: "Koolituskursus",
	TrainingCatalogue_Category: "Kategooria",
	TrainingCatalogue_TrainingCategory: "Koolituse kategooria",
	TrainingCatalogue_CourseName: "Koolituse nimetus",
	TrainingCatalogue_TotalDays: "Päevi kokku",
	TrainingCatalogue_Venue: "Koht",
	TrainingCatalogue_Details: "Üksikasjad",
	TrainingCatalogue_Prerequisites: "Eeltingimused",
	TrainingCatalogue_CourseType: "Kursuse tüüp",
	TrainingCatalogue_LearningMethod: "Õpimeetod",
	TrainingCatalogue_AddToWaitingList: "Lisa ooteloendisse",
	ShoppingCart_ShoppingCart: "Ostukorv",
	ShoppingCart_ReviewAndManage: "Tellimuste läbivaatamine ja haldamine",
	ShoppingCart_YouHaveAddedItem: "Olete ostukorvi lisanud järgmised tooted",
	ShoppingCart_Item: "Toode",
	ShoppingCart_Quantity: "Kogus",
	ShoppingCart_PricePerItem: "Hind toote kohta",
	ShoppingCart_TotalPrice: "Hind kokku",
	ShoppingCart_VatIsIncluded: "V.a km",
	ShoppingCart_Redeem: "Kui teil krediiti pole, klõpsake selle lunastamiseks siin",
	ShoppingCart_Purchase: "Kui teil krediiti pole, klõpsake ostmiseks siin",
	ShoppingCart_Checkout: "Kassa",
	ShoppingCart_ContinueShopping: "Jätka ostmist",
	ShoppingCart_TotalPriceInclVat: "Hind kokku, sh km",
	ShoppingCart_Apply: "Rakenda",
	ShoppingCart_ShoppingCartEmpty: "Teie ostukorv on tühi",
	ShoppingCart_VatIncluded: "KM",
	ShoppingCart_ManageYourShopping: "Halda ostukorvi",
	ShoppingCart_Participant: "Osaleja",
	ShoppingCart_Complete: "Lõplik",
	ShoppingCart_NetPrice: "Netohind",
	ShoppingCart_Workshop: "Töökoda",
	CheckOut_PreviewYourOrder: "Tellimuse eelvaade",
	CheckOut_YourShoppingCart: "Korv",
	CheckOut_Preview: "Eelvaade",
	CheckOut_Payment: "Makse",
	CheckOut_Summary: "Kokkuvõte",
	CheckOut_Back: "Tagasi",
	CheckOut_PleaseReadAndAgreeOurTerms: "Palun lugege meie tingimused läbi ja nõustuge nendega",
	CheckOut_IHaveReadTheTermsAndCondition: "[Olen tingimustega tutvunud ja nõustun nendega]",
	CheckOut_YesIAgree: "Jah, nõustun tingimustega",
	CheckOut_Continue: "Jätka",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Palju õnne! Oleme teie tellimuse kätte saanud",
	CheckOut_OrderConfirmation: "Saate lõpliku tellimuse kinnituse, kui teie taotlus on edukalt töödeldud",
	CheckOut_WeWillContactYouDirectly: "Võtame teiega ühendust, kui meil tekib teie taotlusega seotud küsimusi",
	CheckOut_AddToCart: "Lisa ostukorvi",
	CheckOut_CreditCart: "Krediitkaart",
	CheckOut_DirectDebit: "Otsekorraldus",
	CheckOut_TrainingCredits: "Koolituskrediit",
	CheckOut_PreferredWholesaler: "Eelistatud hulgimüüja",
	EventCalendar_EventCalendar: "Sündmuste kalender",
	EventCalendar_TrainingEventsForAllYourNeeds: "Koolitusüritused kõigi teie vajaduste jaoks",
	TrainingCourse_TrainingCourse: "Koolituskursuste kataloog",
	TrainingCourse_LearningAtTheHighestLevel: "Õppimine kõrgeimal tasemel",
	TrainingProgram_Title: "Koolitusprogrammi kataloog",
	TrainingProgram_SubTitle: "Õppimine kõrgeimal tasemel",
	AccountDeactivationModal_Title: "Kas soovite kindlasti selle konto desaktiveerida?",
	AccountDeactivationModal_Line1: "Selle konto desaktiveerimine on püsiv ja eemaldab kogu sisu, sealhulgas teenused, mis jaoks on vajalikud tehingud ja bilanss, meeskonnaliikmed ja profiilisätted.",
	AccountDeactivationModal_Line2Part1: "Kui desaktiveerite aktiivse tellimisperioodi ajal, vaadake meie tühistamiseeskirju",
	AccountDeactivationModal_Line2Part2: "üldtingimustest",
	AccountDeactivationModal_Line2Part3: "enne taotluse esitamist.",
	AccountDeactivationModal_Line3: "Kas soovite kindlasti esitada taotluse selle konto desaktiveerimiseks?",
	AccountDeactivationModal_Cancel: "Tühista",
	AccountDeactivationModal_Send: "Saada",
	CurrentUserAccountDeactivationModal_Title: "Kas soovite kindlasti selle konto desaktiveerida?",
	CurrentUserAccountDeactivationModal_Line1: "Teie konto desaktiveerimine on püsiv ja eemaldab kogu sisu, sealhulgas teenused, mis jaoks on vajalikud tehingud ja bilanss, meeskonnaliikmed ja profiilisätted.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Kui desaktiveerite aktiivse tellimisperioodi ajal, vaadake meie tühistamiseeskirju",
	CurrentUserAccountDeactivationModal_Line3: "Kas soovite kindlasti esitada taotluse oma konto desaktiveerimiseks?",
	CurrentUserAccountDeactivationModal_Yes: "Jah",
	CurrentUserAccountDeactivationModal_No: "Ei",
	CourseWithdrawalModal_Title: "Koolituse tühistamine",
	CourseWithdrawalModal_Line1: "kas tahate kindlasti koolitusel osalemise tühistada?",
	CourseWithdrawalModal_Line2Part1: "Tühistamiste suhtes kehtivad tühistamistasud ja nende eest tuleb tasuda vastavalt",
	CourseWithdrawalModal_Line2Part2Link: "meie teenuste",
	CourseWithdrawalModal_Line2Part3: "üldtingimustele",
	CourseWithdrawalModal_Yes: "Jah, tühistan koolituse",
	CourseWithdrawalModal_No: "Ei, katkestan protsessi",
	Settings_Settings: "Sätted",
	Settings_ManageYourSettings: "Halda sätteid",
	Settings_Profile: "Profiil",
	Settings_MyBoschGlobalIdLoginInformation: "My Bosch Global ID sisselogimisteave",
	Settings_Edit: "Muuda",
	Settings_CompanyDetails: "Ettevõtte üksikasjad",
	Settings_RequestAccountDeletion: "Konto kustutamise taotlemine",
	Settings_AccountDeletionText: "Kui kustutate oma konto, kustutatakse kõik teie andmed (sh aadress ja makseandmed) ning need pole enam saadaval.",
	Settings_DeleteAccountRequestConfirmation: "Konto kustutamise taotluse kinnitus",
	Settings_DeleteAccountRequestConfirmationText: "Oleme saanud teie konto kustutamise taotluse ja võtame teiega lõpliku kinnituse saamiseks ühendust e-posti või telefoni teel",
	RecommendedHotels_RecommendedHotels: "Soovitatud hotellid",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Meie koolituskeskused asuvad kõikjal maailmas",
	Error_Common: "Midagi läks valesti!",
	Error_404: "Külastatud lehte pole olemas",
	Error_401: "Teil pole sellele lehele juurdepääsu",
	Error_500: "Server teatab tõrkest",
	Dashboard_Courses_Table_Course: "Koolitus",
	Dashboard_Courses_Table_Date: "Kuupäev  [päeva]",
	Dashboard_Courses_Table_Venue: "Koht",
	Dashboard_Courses_Table_TrainingType: "Koolituse tüüp",
	Dashboard_Courses_Table_Action: "Tegevus",
	Dashboard_Courses_Table_TasksToComplete: "Teil on vaja lõpetada järgnevad tegevused",
	Dashboard_Courses_Table_Withdraw: "Tühista",
	Dashboard_Courses_Table_Remove: "Eemalda",
	Dashboard_Courses_Table_View: "Vaata",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Kirjed puuduvad",
	Dashboard_Courses_Table_CompletionDate: "Lõpetamise kuupäev",
	Dashboard_Courses_Table_Actions_Assignments: "Ülesanded",
	Dashboard_Courses_Table_Actions_Certification: "Tunnistus",
	Dashboard_Courses_Table_Actions_Evaluations: "Hindamine/atesteerimine",
	Dashboard_Courses_Table_Actions_Assessments: "Hinnangud",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Lisa kalendrisse",
	Dashboard_Courses_Table_CourseDetails_Task: "Ülesanne",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Tähtaeg",
	ContactUs_Headline: "Võtke meiega ühendust",
	ContactUs_HowCanWeHelp: "Kuidas saame aidata?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Kiri",
	ContactUs_Phone_Headline: "Helistage meile!",
	ContactUs_Phone_Description: "Ootame teie kõnet ja abistame meelsasti.",
	ContactUs_Phone_PhoneNumber: "Palume kasutada kontakti vormi meile sõnumi saatmiseks",
	ContactUs_Phone_ChargeNotice: "Kulud võivad olla erinevad sõltuvalt asukohamaast ja teenusepakkujast.",
	ContactUs_Letter_Headline: "Saada kiri!",
	ContactUs_Letter_Description: "Kas teil on küsimusi või ettepanekuid? Ootame teie päringut",
	ContactUs_Email_Headline: "Saada e-kiri!",
	ContactUs_Email_Description: "Saatke meile oma sõnum, täites alloleva vormi. Keegi meie nõustajatest võtab teiega hea meelega ühendust.",
	ContactUs_Email_InputName: "Nimi*",
	ContactUs_Email_InputCompany: "Firma",
	ContactUs_Email_InputCountry: "Maa*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Sõnum*",
	ContactUs_Email_DataProtection: "Andmekaitseteatis*",
	ContactUs_Email_DataProtectionConfirm: "Olen lugenud läbi privaatsuspoliitika tingimused*",
	ContactUs_Email_SecurityCheck: "Turvakontroll",
	ContactUs_Email_SecurityCodeConfirm: "Palume sisestada üleval olevale väljale õiged märgid.",
	ContactUs_Email_SecurityCodeInvalid: "Sisestatud tekst on vale.",
	ContactUs_Email_MandatoryNotice: "Palume täita kohustuslikud väljad.",
	ContactUs_Email_SuccessMessage: "Teie sõnum on saadetud",
	ContactUs_Email_TakenNoteDataProtection1: "Olen lugenud läbi",
	ContactUs_Email_TakenNoteDataProtection2: "privaatsuspoliitika sätted*",
	ContactUs_Email_SubmitButton: "Edasta",
	ContactUs_Email_FieldErrorMessage: "Palume täita kohustuslikud väljad.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Teie konto on praegu ülevaatamisel!",
	AccountInReviewModal_Paragraph1: "Täname, et registreerisite isikliku konto teenuses Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Meie pühendunud koolitustöötajad vaatavad praegu teie kontot üle ja seadistavad seda. Pärast konto edukat seadistamist saate lõpliku kinnituse posti teel.",
	AccountInReviewModal_Paragraph3: "Seni võite tutvuda meie veebilehega piiratud ulatuses",
	AccountInReviewModal_Paragraph4: "Kui teil on küsimusi või kui teie kontot ei töödelda 48 tunni jooksul, välja arvatud nädalavahetused ja pühad, võite meiega ühendust võtta",
	AccountInReviewModal_ContactUsLink: "siit",
	AccountInReviewModal_OKButton: "Selge, lase mind sisse!",
	Header_YesButton: "Jah",
	Header_NoButton: "Ei",
	Header_LogOutText: "Kas soovite välja logida!",
	Footer_EnquiryText: "Ootame teie päringut",
	Footer_Telephone: "Telefon",
	Footer_TermsAndConditions: "Lepingutingimused",
	Footer_HowOurTrainingEvolves: "Kuidas meie koolitus areneb",
	FooterSearch_SearchFieldPlaceholder: "Otsi",
	TrainingCatalogue_Action: "Tegevus",
	TrainingCatalogue_Duration: "Kestvus",
	TrainingCatalogue_Information: "Informatsioon",
	Settings_PersonalDetails: "Isiklikud andmed",
	Reset_All_Filters: "Lähtesta kõik filtrid",
	EventTable_Days: "Päeva",
	SearchField_AllResults: "Kõik tulemused",
	TrainingCentresLayout_ViewDetails: "Vaata üksikasju",
	Dropdown_Placeholder: "Vali…",
	WaitlistPopup_SelectButtonText: "Vali",
	WaitlistPopup_StartDate: "Alguse kuupäev",
	WaitlistPopup_EndDate: "Lõpu kuupäev",
	WaitlistPopup_Language: "Keel",
	WaitlistPopup_City: "Linn",
	WaitlistPopup_Workshop: "Töökoda",
	WaitlistPopup_Student: "Õpilane",
	WaitlistPopup_PreferredLocation: "Eelistatud asukoht",
	WaitlistPopup_SignUpCourseWaitingList: "Registreeri koolituse ootelehele",
	WaitlistPopup_SignUpWithoutSelectingDate: "Regatereeri koolituse ootelehele ilma kuupäevata",
	WaitlistPopup_GeneralWaitList: "Üldine ootenimekiri",
	WatinglistPopup_CourseLabel: "Koolitus",
	Common_ContinueButton: "Edasi",
	UserAccountDeactivationModal_ConfirmLabel: "Kinnita",
	Filter_FilterLabel: "Filter",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Olete juba ootelehel, kas soovite eemaldada olemasoleva registreeringu ja teha uue?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Jah, eemalda",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Ei, katkesta",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Koolituse tühistamine",
	WaitinglistPopup_SignUpButtonText: "Registreerimine",
	MyAccount_FirstNameRequied: "Eesnimi on vajalik",
	MyAccount_LastNameRequired: "Perekonnanimi on vajalik",
	MyAccount_RoleRequired: "Roll on vajalik",
	MyAccount_MobileRequired: "Mobiili number on vajalik",
	MyAccount_EmailRequired: "E-mail on vajalik",
	MyAccount_LanguageRequired: "Keel on vajalik",
	MyAccount_UpdateSuccessMessage: "Teie konto üksikasjad on edukalt uuendatud!",
	MyAccount_UpdateSuccessModal_ButtonText: "Jah, sulge",
	Infor_CorporateInformation_Header: "Ettevõtte teave",
	Infor_LegalNotice_Header: "Õiguslik teade",
	Infor_PrivacyPolicy_Header: "Andmekaitseteatis (privaatsuspoliitika)",
	CreateEmployeeAccountModification_FormValidation_Role: "Roll on vajalik",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Eesnimi on vajalik",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Perekonnanimi on vajalik",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobiili number on vajalik",
	CreateEmployeeAccountModification_FormValidation_Email: "E-mail on vajalik",
	CreateEmployeeAccountModification_FormValidation_Language: "Keel on vajalik",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Kutse Bosch Automotive Solutions keskkonnaga ühinemiseks",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Olete edukalt lisanud uue liikme oma meeskonda!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Kas soovite, et jagaksime teie uue meeskonnaliikmega meilikutset koos juhistega, kuidas luua Bosch Automotive Training Solutionsi jaoks isikliku konto?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Ei",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Jah, saada kutse",
	ShoppingCart_Venue_Fee: "kohatasu",
	ShoppingCart_Trainning_Name: "Koolituse nimetus",
	ShoppingCart_Available_Credits: "Vaba krediit",
	ConfirmItsMeModal_Hi: "Tere",
	ConfirmItsMeModal_Description1: "Olete edukalt täitnud oma ettevõtte administraatori jagatud kutse luua oma isiklik konto teenuses Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Enne jätkamist palume kinnitada või uuendada oma konto andmeid.",
	ConfirmItsMeModal_Description3: "Peale kinnitamist peate uuesti sisse logima oma konto loomise lõpetamiseks.",
	ConfirmItsMeModal_CompanyDetails: "Firma andmed",
	ConfirmItsMeModal_YourDetails: "Teie andmed",
	ConfirmItsMeModal_YourDetails_Title: "Tiitel",
	ConfirmItsMeModal_YourDetails_Function: "Amet",
	ConfirmItsMeModal_YourDetails_Name: "Nimi",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobiil",
	ConfirmItsMeModal_YourDetails_Email: "E-mail",
	ConfirmItsMeModal_YourDetails_Language: "Keel",
	ConfirmItsMeModal_ConfirmButton: "Kinnita",
	SelectWholesalerModal_WholesalerGroupLabel: "hulgimüüja",
	SelectWholesalerModal_WholesalerBranchLabel: "Kohalik edasimüüja",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Palun valige",
	ApplyButton: "Rakenda",
	SelectWholesalerModal_Title: "Valige oma hulgimüüja",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Kas soovite oma registreeringu eemaldada?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Teie registreering on edukalt eemaldatud!",
	WaitlistPopup_AddToWaitListSuccess: "Teie registreering on edukalt lisatud!",
	ErrorCommon_Header: "VIGA",
	CommonButton_Close: "Sulge",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Jätkamiseks palun sulgege see aken!",
	EmbeddedVideoPlayer_StartButtonText: "Alusta",
	EmbeddedVideoPlayer_RefresherButtonText: "Värskenda",
	DeactivateWholesalerModal_ConfirmMessage: "Kas soovite kindlasti eemaldada selle hulgimüüja?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "kutse e-kiri edukalt saadetud!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "kutsu uuesti",
	ContactUs_Mobile_EE: "+372 6549561",
	ContactUs_Address_EE: "Robert Bosch OÜ,Kesk tee 10,Juri alevik,EE-75301 Harjumaa,Estonia",
	ConfirmItsMeModal_CompanyDetails_Name: "firma nimi",
	ConfirmItsMeModal_CompanyDetails_Address: "aadress",
	ConfirmItsMeModal_CompanyDetails_Postcode: "firma postiindeks",
	ConfirmItsMeModal_CompanyDetails_Phone: "telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "Email",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Edukalt kursuselt eemaldatud!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Konto loomine ebaõnnestus, konto on juba olemas!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Konto uuendamine ebaõnnestus, konto on juba olemas!",
	Footer_ExploreTrainingStories: "vaata kõiki koolitusjutte",
	Breadcrumb_Home: "avaleht",
	Breadcrumb_Contact: "kontakt",
	Breadcrumb_Stories: "jutud",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "päev koolitaja elus",
	Breadcrumb_Stories_BoschTrainingBenefits: "Boschi koolituste eelised",
	Breadcrumb_Stories_TrainingApproach: "tänapäevane lähenemine koolitusele",
	Breadcrumb_Settings: "asetused",
	Breadcrumb_ShoppingCart: "ostukorv",
	Breadcrumb_Checkout: "kontrollima ja maksma",
	Breadcrumb_OrderSummary: "tellimuse kokkuvõte",
	Breadcrumb_Exception: "erand",
	Breadcrumb_Dashboard: "esileht",
	Breadcrumb_MyCourses: "minu kursused",
	Breadcrumb_MyELearning: "minu eKoolitused",
	Breadcrumb_CompanyAdministration: "firma haldamine",
	Breadcrumb_EmployeeManagement: "töötaja haldamine",
	Breadcrumb_EmployeeAccount: "töötaja konto",
	Breadcrumb_EmployeeCreation: "töötaja loomine",
	Breadcrumb_Administration: "haldamine",
	Breadcrumb_EmployeeAdministration: "töötaja haldamine",
	Breadcrumb_WholesalerManagement: "hulgimüüja haldamine",
	Breadcrumb_NewWholesalerAccountCreation: "uue hulgimüüja konto loomine",
	Breadcrumb_Transactions: "tehingud",
	Breadcrumb_MyAccount: "minu konto",
	Breadcrumb_TrainingCentres: "koolituskeskused",
	Breadcrumb_EventCalendar: "koolituste kalender",
	Breadcrumb_TrainingCourseCatalogue: "koolituste kataloog",
	Breadcrumb_RecommendedHotels: "soovitatavad hotellid",
	Breadcrumb_CorporateInformation: "korporatiivne informatsioon",
	Breadcrumb_LegalNotice: "juriidiline teade",
	Breadcrumb_DataProtectionNotice: "andmekaitseteade",
	Breadcrumb_PrivacyPolicy: "privaatsuspoliitika",
	Breadcrumb_TermCondition: "tingimused",
	Breadcrumb_Terms: "tingimused",
	Breadcrumb_PrivacyStatement: "privaatsusavaldus",
	Breadcrumb_Cookies: "küpsised",
	Breadcrumb_Provider: "pakkuja",
	Breadcrumb_RegisterAccount: "registreeri konto",
	Stories_ExploreMore: "otsi lisa",
	CheckOutLayout_ShoppingCartHeader: "ostukorv",
	CheckOutLayout_AddedItemCartMessage: "olete lisanud ostukorvi järgneva",
	CheckOutLayout_CheckYourCartMessage: "kontrollige ostukorvi",
	ModernTrainingApproach_DateText: "2/28/2022 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "töötajate otsing",
	CheckOutLayout_OrderWasNotPlaced_Message: "Vabandame, teie tellimust ei teha",
	CheckOutLayout_RefusalReason: "keeldumise põhjus",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "maksmine ebaõnnestus, palume korrata erineva maksemeetodiga",
	CheckOutLayout_SubmittingApiFailed_Message: "API saatmine ebaõnnestus",
	CartItem_ChooseWorkshop_Message: "osaleja valimiseks palume valida töökoda",
	CartItem_ChooseParticipant_Message: "jätkamiseks palume valida osaleja",
	CompanyAdministrationTable_SearchBox_Placeholder: "töökodade otsing",
	CartItem_RemoveCartItem_ConfirmText: "Kas soovite eemaldada antud toote ostukorvist?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funktsioon",
	AddToCartSuccessModal_Message: "Ostukorvi sisu on uuendatud",
	AddToCartSuccessModal_ContinueButtonText: "jätka ostmist",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Minu ostukorv",
	AddVoucher_Header: "Lisage vautšer",
	AddVoucher_InputPlaceholder: "Sisestage kood ja kinnitage",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Kahjuks ei ole meil hetkel antud koolitust planeeritud. Registreeruge ootenimekirja ja me teavitame teid, kui koolitus on saadaval.",
	ADYEN_ERR_2_: "Tehingust keelduti.",
	ADYEN_ERR_3_: "Viitamise viga",
	ADYEN_ERR_4_: "Tehing ei toimunud ostja poolel ilmnenud vea tõttu.",
	ADYEN_ERR_5_: "Tehinguks kasutatud kaart on blokeeritud, seega kasutuskõlbmatu.",
	ADYEN_ERR_6_: "Tehinguks kasutatud kaart on aegunud. Seetõttu on see kasutuskõlbmatu.",
	ADYEN_ERR_7_: "Tehinguprotsessi ajal ilmnes summa mittevastavus.",
	ADYEN_ERR_8_: "Määratud kaardi number on vale või kehtetu.",
	ADYEN_ERR_9_: "Ostja pangaga ei ole võimalik tehingu autoriseerimiseks ühendust võtta.",
	ADYEN_ERR_10_: "Ostja pank ei toeta või ei luba seda tüüpi tehinguid.",
	ADYEN_ERR_11_: "3D Secure autentimist ei teostatud või see ei õnnestunud.",
	ADYEN_ERR_12_: "Kaardil pole piisavalt raha tasumisele kuuluva summa katmiseks. See probleem võib tekkida teie kaardi igakuise tasumisele kuuluva summa limiidi tõttu. Proovige kasutada mõnda muud kaarti, või võtke ühendust oma pangaga, et lähtestada oma kaardi kuulimiit.",
	ADYEN_ERR_14_: "Võimalik pettus.",
	ADYEN_ERR_15_: "Tehing tühistati.",
	ADYEN_ERR_16_: "Ostja tühistas tehingu enne selle lõpetamist.",
	ADYEN_ERR_17_: "Sisestatud PIN on vale või kehtetu.",
	ADYEN_ERR_18_: "Ostja sisestas rohkem kui kolm korda järjest vale PIN-koodi.",
	ADYEN_ERR_19_: "Sisestatud PIN-koodi ei ole võimalik kinnitada.",
	ADYEN_ERR_20_: "Võimalik pettus.",
	ADYEN_ERR_21_: "Tehingut ei esitatud töötlemiseks korrektselt.",
	ADYEN_ERR_22_: "Riskikontroll märkis tehingu kui pettuse (riskiskoor >= 100); seetõttu tehing tühistatakse.",
	ADYEN_ERR_23_: "Selle keeldumise põhjusteks on: Tehing pole käsitlejale/kaardiomanikule lubatud.",
	ADYEN_ERR_24_: "Sisestatud CVC (kaardi turvakood) on kehtetu.",
	ADYEN_ERR_25_: "Selle keeldumise põhjuseks on: Kehtetu kaart selles riigis",
	ADYEN_ERR_26_: "R1: Autoriseerimiskorralduse tühistamine / \"R3: Kõigi autoriseerimiskorralduste tühistamine\" / \"R0: Makse peatamine\"",
	ADYEN_ERR_27_: "See vastus kaardistab kõik need veakoodid, millele puudub ühene selgitus. See hõlbustab üldiste keeldumiste (nt Mastercardi vastuse \"05: Ära austa\") eristamist konkreetsematest tagasilükkamistest.",
	ADYEN_ERR_28_: "Kaardile lubatud väljamakse summa on ületanud.",
	ADYEN_ERR_29_: "Kaardile lubatud väljamaksete arv on ületanud.",
	ADYEN_ERR_31_: "Maksu käsitleja teatas tehingust kui pettusekahtlusest.",
	ADYEN_ERR_32_: "Ostja sisestatud aadressiandmed on valed.",
	ADYEN_ERR_33_: "Ostja pank nõuab, et ostja sisestaks veebipõhise PIN-koodi.",
	ADYEN_ERR_34_: "Ostja pank nõuab ostu sooritamiseks arvelduskontot.",
	ADYEN_ERR_35_: "Ostja pank nõuab ostu sooritamiseks hoiukontot.",
	ADYEN_ERR_36_: "Ostja pank nõuab ostjalt mobiili PIN-koodi sisestamist",
	ADYEN_ERR_37_: "Ostja loobus tehingust pärast viipemaksekatset, kui tal paluti proovida teist kaardisisestusmeetodit (PIN-kood või magnetriba lugemine).",
	ADYEN_ERR_38_: "Makse käsitleja lükkas autentimisest vabastamise taotluse tagasi ja nõuab tehingu jaoks autentimist. Proovige uuesti 3D Secure'iga.",
	ADYEN_ERR_39_: "Makse käsitleja või süsteem ei saanud tulemust RReqi kaudu edastada",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Eesnimi ei tohi sisaldada erimärke (nt: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Eesnimi peab olema väiksem kui 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Perekonnanimi ei tohi sisaldada erimärke (nt: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Perekonnanimi peab olema väiksem kui 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobiilinumber ei tohi sisaldada erimärke (nt @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobiilinumber peab olema väiksem kui 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telefoninumber ei tohi sisaldada erimärke (nt @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telefoninumber peab olema väiksem kui 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Emaili aadress on vale",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-posti aadress peab olema väiksem kui 100",
	ConfirmGeolocationModal_ConfirmButton: "Jätka",
	ConfirmGeolocationModal_CancelButton: "Muuda",
	ConfirmGeolocationModal_CurrentCountrySelection: "Teie praegune riigivalik on:",
	ConfirmGeolocationModal_AskChangeCountry: "Kas soovite sellele saidile jääda või muuta oma riigi-eelistust?",
	Addtocart_Modal_AddToCartFailTitle: "Osaleja on juba sellele kursusele registreerunud",
	Addtocart_Modal_AlreadyInCart: "Osaleja on juba sellele kursusele registreerunud",
	TrainingCatalogue_DateRange: "Kuupäevavahemik",
	TrainingCatalogue_DateRange_placeholder: "Valige kuupäevavahemik",
	Dashboard_MyPendingCourses: "Minu ootel koolitused",
	Dashboard_MyWaitingList: "Minu ooteleht",
	Dashboard_LatestNews: "Viimased uudised",
	SettingsMainPage_EditAccountButton: "Konto muutmine",
	SettingsMainPage_EditBoschId: "Bosch ID muutmine",
	EventInfoView_EventDetailTab: "Sündmuse üksikasjad",
	EventInfoView_AssignmentsTab: "Ülesanded",
	EventInfoView_EvaluationTab: "Hindamine",
	EventInfoView_AttachmentTab: "Manused",
	EventInfoView_CourseDescriptionTab: "Kirjeldus",
	EventInfoView_DescriptionTab_General: "Üldine",
	EventInfoView_DescriptionTab_PartLabel: "Osa",
	AssignmentTab_Description: "Siit leiate kursuse lõpetamiseks kogu vajaliku info",
	AssignmentTab_Columns_Download: "Lae alla",
	AssignmentTab_Columns_Upload: "Lae üles",
	AssignmentTab_Columns_TaskComplete: "Ülesanne täidetud",
	EventDetailTab_Location_Message_Video: "See on veebikoolitus, kuhu pääseb lingi \"Käivita video\" kaudu.",
	EventDetailTab_Location_Message_WebBased: "See on veebikoolitus, kuhu pääseb lingi \"Käivita VBT\" kaudu.",
	EventDetailTab_Location_Message_WebCast: "See on veebikoolitus, kuhu pääseb lingi \"Liitu kohe\" kaudu.",
	EventDetailTab_Link_Video: "Käivita video",
	EventDetailTab_Link_Video_Remark: "*Video käivitamine avab uue akna. Teid suunatakse tagasi säesolevale lehele, kui aken on suletud.",
	EventDetailTab_Link_WebBased: "Käivita WBT",
	EventDetailTab_Link_WebBased_Remark: "*WBT käivitamine avab uue akna. Teid suunatakse tagasi säesolevale lehele, kui aken on suletud.",
	EventDetailTab_Link_WebCast: "Liitu kohe",
	EventDetailTab_Link_WebCast_Remark: "*Liitu kohe link aktiveerub 30 minutit enne koolituse algust.",
	EventDetailTab_Title_Status: "Staatus",
	EventDetailTab_Title_Duration: "Kestvus (tundi)",
	EventDetailTab_Title_Trainers: "Koolitaja(d)",
	EventDetailTab_Title_Language: "Keel",
	EventDetailTab_Title_Score: "Tulemus (%)",
	EventDetailTab_Title_Calendar: "Lisa kalendrisse",
	EventDetailTab_Title_Certificate: "Lae diplom alla",
	EventDetailTab_Title_Messages: "Sõnumid",
	EventDetailTab_Title_StartDate: "Alguse kuupäev",
	EventDetailTab_Title_AvailableFrom: "Saadaval alates",
	EventDetailTab_Title_Time: "Aeg",
	EventDetailTab_Title_EndDate: "Lõpu kuupäev",
	EventDetailTab_Title_ExpiresOn: "kehtivus lõpeb",
	EventDetailTab_Title_Room: "ruum",
	EventDetailTab_Title_Method: "meetod",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "asukoht",
	AttachmentTab_EmptyScreenDescription: "Sellele koolitusele puuduvad manused",
	AttachmentTab_Columns_Download: "Lae alla",
	AttachmentTab_Description: "Siit leiate tähtsat abimaterjaali antud koolitusele",
	EvaluationTab_Annotation: "Me hindame teie tagasisidet ja sooviksime teada, kuidas meil läheb. Palun täitke järgnev hindamisleht aitamaks meil parandada teile pakutavat teenust. Suur tänu.",
	EvaluationTab_Notice: "Hinnangud on järgnevad",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Palun vastake kõigile kohustuslikele küsimustele enne oma hinnagu esitamist",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Teie tegasiside on edukalt esitatud",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Hinnangu esitamine ebaõnnestus",
	TrainingCatalogueLayout_ExportButtonText: "ekspordi",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Hinnang oli juba esitatud või on aegunud",
	EventDetailTab_Link_CopiedTooltip: "Kopeeriti lõikelauale",
	EventDetailTab_QRCode_ExplanationText_WBT: "WBT käivitamiseks skanneerige",
	EventDetailTab_QRCode_ExplanationText_Video: "Video käivitamiseks skanneerige",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Webcasti käivitamiseks skanneerige",
	Footer_TrainingCoure_Headline: "Boschi koolituskursused",
	Footer_TrainingCourse_Content: "Maailmakuulsa superbrändina ja enam kui sajandi pikkuse innovatsioonikogemusega Bosch on autosüsteemide, varuosade ja tehniliste lahenduste pakkujana kehtestanud ülemaailmsed standardid. Suur osa meie tugevusest tuleneb tihedast koostööst sõidukitootjatega, kelle jaoks projekteerime, arendame ja toodame tipptasemel süsteeme ja komponente üle kogu maailma. Sellest tulenevalt on Bosch taganud tugeva ajaloo autode järelturu toodete ja tugiteenuste vallas mitmekülgse varuosade, diagnostikalahenduste ja töökojateenustega. Õppimine ja areng on kindlad edu võtmed ning selle filosoofia aluseks on lai valik koolituskursusi kõigis autotehnoloogia, sõidukisüsteemide ja diagnostikateadmiste valdkondades.",
	SearchField_NoRecordsFound: "Kirjeid ei leitud",
	TrainingCatalogue_FreeTextSearch: "Vaba tekst",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Otsing",
	CheckoutLayout_WholeSalerGroup: "Valige hulgimüüjate grupp",
	CreditInsufficientModal_Message: "Teil ei ole selle makseviisi kasutamiseks piisavalt krediiti. Proovige uuesti mõne muu makseviisiga.",
	ShoppingCart_Required_Credits: "Koguhind",
	MaintainingInfo_Message1: "Veebileht on hooldusrežiimis.",
	MaintainingInfo_Message2: "Varsti tuleme tagasi.",
	CountryValidationModal_Message: "Te ei saanud riiki [CountryA] sisse logida, kuna teie konto on loodud riigis [CountryB].",
	CountryValidationModal_ButtonText: "Edasi sihtkohta [Country]",
	EventTable_LoginRequired: "Registreerimiseks logige sisse",
	TrainingPartTableLayout_Date: "Kuupäev",
	TrainingPartTableLayout_StartTime: "Algusaeg",
	TrainingPartTableLayout_EndTime: "Lõpuaeg",
	TrainingPartTableLayout_Duration: "Kestus (h)",
	TrainingPartTableLayout_TrainingMethod: "Koolitusmeetod",
	MyCoursesTableContents_ActiveHeader: "Aktiivsed kursused",
	MyCoursesTableContents_ActiveTabHeader: "Aktiivne",
	MyCoursesTableContents_ScheduledHeader: "Planeeritud kursused",
	MyCoursesTableContents_ScheduledTabHeader: "Planeeritud",
	MyCoursesTableContents_OnlineHeader: "Veebikoolitused",
	MyCoursesTableContents_OnlineTabHeader: "Internetis",
	MyCoursesTableContents_CancelledHeader: "Tühistatud kursused",
	MyCoursesTableContents_CancelledTabHeader: "Tühistatud",
	OnlineCoursesTable_Title_Progress: "Edenemine",
	ScheduledCoursesTable_Title_Date: "Kuupäev [päeva]",
	CoursesTable_Title_Completed: "Lõpetatud",
	Footer_TrainingProgram: "Koolitusprogrammid",
	Breadcrumb_TrainingProgram: "Koolitusprogrammid",
	TrainingProgram_Title_Table: "Pealkiri",
	TrainingProgram_TrainingProgram: "Koolitusprogrammid",
	TrainingProgram_TargetGroup: "Sihtgrupp",
	TrainingProgram_MaxLength: "Pikkus",
	TrainingProgram_Information: "Teave",
	TrainingProgram_Detail: "Üksikasjad",
	TrainingProgram_Modules: "Moodulid",
	TrainingProgram_Course: "Kursused",
	TrainingProgram_PartNumber: "Tootekood",
	TrainingProgram_Max_Length: "Maksimaalne pikkus",
	TrainingProgram_Duration: "Kestus",
	TrainingProgram_Exam: "Eksam",
	TrainingProgram_Assign_Popup: "Registreeru koolitusprogrammi",
	TrainingProgram_Student: "Osaleja",
	TrainingProgram_Assign: "Määra",
	TrainingProgram_Popup_Remove_Title: "Koolituse tühistamine?",
	TrainingProgram_Description: "Kirjeldus",
	TrainingProgram_Days: "Päevad",
	TrainingProgram_Type: "Tüüp",
	TrainingProgram_Action: "Tegevus",
	TrainingProgram_Enrolled: "on juba sellesse koolitusprogrammi registreerunud",
	TrainingProgram_SelfAssign_Enrolled: "Olete juba sellele koolitusprogrammile määratud",
	TrainingProgram_Warning: "Palun registreerige teine ​​õpilane või valige mõni muu koolitusprogramm.",
	TrainingProgram_Workshop: "Töökoda",
	TrainingProgram_Durations: "aastat",
	TrainingProgram_Duration_DaysLabel: "päeva",
	TrainingProgram_HasExam_Yes: "Jah",
	TrainingProgram_HasExam_No: "Ei",
	AssignModal_SuccessModal_Header: "Palju õnne!",
	AssignModal_SuccessModal_Message: "on edukalt registreerunud sellele koolitusprogrammile.",
	AssignModal_SelfAssign_SuccessModal_Message: "Teid on edukalt määratud koolitusprogrammi.",
	Dashboard_MyTraningProgram: "Minu koolitusprogramm",
	Dashboard_MyTraningProgram_Title: "Pealkiri",
	Dashboard_MyTraningProgram_Status: "Olek",
	Dashboard_MyTraningProgram_Table_View: "Vaade",
	Dashboard_MyTraningProgram_Register: "Registreeri",
	Dashboard_MyTraningProgram_Records: "arhiiv",
	Dashboard_MyTrainingProgram_In_Progress: "Pooleli",
	Dashboard_MyTrainingProgram_Accredited: "Akrediteeritud",
	Dashboard_Program_Modal_Withdraw: "Tõmba tagasi",
	Dashboard_Program_Modal_Grade: "Hinne",
	Dashboard_MyTrainingProgram_Registered: "Registreeritud",
	Dashboard_MyTrainingProgram_Completedd: "Lõpetatud",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Jah",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Ei",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Pange tähele, et loobumine tähendab, et loobute kogu koolitusprogrammist, mitte ainult individuaalsest koolitusest.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Kas soovite endiselt koolitusprogrammist loobuda?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Ootel",
	WithdrawProgramModal_Withdraw_Success_Message: "Olete edukalt koolitusprogrammist loobunud!",
	Common_Warning_Title: "Hoiatus!",
	Common_Error_Title: "oih, midagi läks valesti!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Lisa ostukorvi",
	AddEventFromCatalogToCartModal_HoursText: "tundi",
	AddElearningToCartSuccessModal_Message: "on lisatud teie ostukorvi!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Valitud osaleja on sellele Kursusele registreerunud või on Kursus juba ostukorvi lisatud.",
	EventDetailTab_SubjectForChange_Message: "muudatused võimalikud",
	Dashboard_MyTrainingProgram_Status_Failed: "Ei läbinud",
	Dashboard_MyTrainingProgram_Status_Passed: "Läbitud",
	Dashboard_MyTrainingProgram_Status_Merit: "väärtus",
	Dashboard_MyTrainingProgram_Status_Distinction: "Excellence",
	Dashboard_Employee_Courses: "Kursused",
	Dashboard_Employee_Program: "Koolitusprogramm",
	Dashboard_Employee_WaitList: "Ootenimekiri",
	Dashboard_Employee_Account: "Konto",
	Course_Price_Free: "Tasuta",
	Dashboard_Employee_Account_Update_Success_Message: "Olete edukalt uuendanud oma töötaja andmeid!",
	Dashboard_Supplier: "Tarnija",
	Event_Detail_Comment: "Hetkel sõnumeid pole",
	DownLoad_PDF: "PDF allalaadimine",
	TrainingCatalogue_Currency: "valuuta",
	My_Wallet: "minu rahakott",
	Wallet: "rahakott",
	Credits_Balance: "krediidijääk",
	MyFullCalendar_Today: "Täna",
	Header_Available_Languages: "Saadaval olevad keeled",
	Header_CurrentRegion: "Olete hetkel",
	Header_ChangeRegions: "Kas soovite muuta maa?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Logi sisse / registreeri",
	Header_RegisterCourse: "Registreeri kursusele",
	Homepage_Start: "alusta",
	Homepage_Login: "logi sisse",
	MainPage_Title: "Hea lahendus töökodadele",
	MainPage_Content: "Sinu töökoda, sinu valik",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Võimaldab kaasaegsel töökojal töötada professionaalselt, usaldusväärselt ja tõhusalt igat tüüpi sõidukite diagnostikaprotseduuride, tõrkeotsingu, remondi ja teeninduse käigus.",
	BoschNews_TrainingNews: "koolituste uudised",
	Service_Assist_Workshop: "Täiustage oma kogemusi Workshop Service Assisti abil",
	Enhance_Title_CDM3_v2: "Mida saan rakendusega teha?",
	Enhance_Body_CDM3_Item_1: "broneeri koolitusi ja halda oma õppeteekonda",
	Enhance_Body_CDM3_Item_3: "käivita e-õppe kursusi",
	Enhance_Body_CDM3_Item_4: "mobiilirakendusega ligipääs erinevatele teenustele",
	Enhance_Body_CDM3_Item_5: "otsevideo ühendus Visual Connect Pro abil",
	Enhance_Body_CDM3_Item_6: "seade sõiduki scanneerimiseks",
	Training_Stories: "koolitusjutud",
	SettingsMyAccount_JoinCompanyButton: "liitu firmaga",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "liitu firmaga",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "sisestage alla aktiveerimiskood",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "NB! Peate võtma ühendust oma firma administraatoriga aktiveerimiskoodi saamiseks.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Teie aktiveerimiskood on edukalt käsitletud ja konto seotud teie firmaga.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "palun klikake Edasi, veebilehele sisenemiseks.",
	ContinueButton: "Edasi",
	WelcomeBatsModal_Title: "Teie konto on hetkel kontrollis!",
	WelcomeBatsModal_ActivationLink: "Klikake siia, kui teil on firmaga liitumiseks aktiveerimiskood!",
	WelcomeBatsModal_ActivationLinkNotes: "NB! Peate võtma ühendust oma firma administraatoriga aktiveerimiskoodi saamiseks.",
	AccountInReviewModal_Description_Paragraph1: "Täname, et registreerisite oma konto Bosch Automotive Training Solutions keskkonda",
	AccountInReviewModal_Description_Paragraph2: "Meie koolitusmeeskond vaatab teie kontot üle ja konfigureerib seda. Saate lõpliku kinnituse e-kirja teel, kui konto on edukalt konfigureeritud.",
	AccountInReviewModal_CloseButton: "OK, lase mind sisse!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Me ei suutnud teie aktiveerimiskoodi ja e-posti aadressi valideerida",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Palun võtke oma firma administraatoriga uuesti ühendust.",
	TryAgain_Button: "Proovi uuesti",
	Error_Title: "OI, miskit läks valesti!",
	SettingsMyProfileActivationSuccessModal_Title: "Edukas!",
	AccountInReviewModal_Description_Paragraph3: "Seni võite vabalt tutvude meie veebilehe piiratud sisuga",
	AccountInReviewModal_Description_Paragraph4: "Kui teil on küsimusi, või kui teie kontot ei käsitleta 48 tunni jooksul (va nädalavahetused ja riigipühad), saate meiega võtta ühendust [siin]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Mõned abistavad juhised....",
	Support_FAQ_Headline: "Sageli esitatud küsimused",
	Support_FAQs_section1_title: "Üldine",
	Support_FAQs_section1_question1: "Mis on Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions on platvorm, mis pakub ülevaadet tehnilistest koolitustest ja võimaldab koolitustele registreeruda ja registreerumisi käsitleda.",
	Support_FAQs_section1_question2: "Kas ma saan kasutada olemasolevat Boschi kontot (singleKey ID) Bosch Training Solutions keskkonda sisenemiseks?",
	Support_FAQs_section1_question2_answer1: "Jah, see on ligi pääsetav kõigi kontodega, mis on loodud \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Kas see sobib ka teistele Boschi teenustele või rakendustele?",
	Support_FAQs_section1_question3_answer1: "Jah, võite kasutada ome personaalset Bosch ID-d ka mitmetes teistes Boschi rakendustes - professionaalsetes ja ka erakasutuseks mõeldud, nagu näiteks smart home, e-bike.",
	Support_FAQs_section2_title: "Koolitus",
	Support_FAQs_section2_question1: "Kuidas ma saan broneerida koolitusele?",
	Support_FAQs_section2_question1_answer1: "Logige sisse olemasoleva kontoga VÕI registreerige konto portaalis. Minge valikusse\"Koolitused\" VÕI \"Sündmuste kalender\" valige koolitus ja klikake \"lisa ostukorvi\" nupule.",
	Support_FAQs_section2_question2: "Sobivaid koolituskuupäevi ei ole. Mida ma teen?",
	Support_FAQs_section2_question2_answer1: "Võite registreeruda \"üldisele\" ootelehele (ilma kuupäeva valikuta) ja meie meeskond informeerib teid kohe, kui koolitus on saadaval.",
	Support_FAQs_section2_question3: "Kus ma näen ülevaadet oma broneeritud koolitustest?",
	Support_FAQs_section2_question3_answer1: "Leiate ülevaate kõigist broneeritud ja läbitud koolitustest peale sisse logimist oma avakuvalt.",
	Support_FAQs_section3_title: "Seadistused",
	Support_FAQs_section3_question1: "Kuidas ma saan muuta oma e-posti aadressi ja salasõna?",
	Support_FAQs_section3_question1_answer1: "Peate portaali sisse logima ja leidma oma profiili. (oma avakuvast leiate oma profiili info, või kui klikkate järgneval nupul pealkirjas)",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Siin saate muuta oma Bosch ID puudutavaid detaile",
	Support_FAQs_section3_question2: "Mis ma teen, kui olen unustanud salasõna?",
	Support_FAQs_section3_question2_answer1: "kasutage valikut logi sisse/registreeri ja sisestage e-posti aadress",
	Support_FAQs_section3_question2_answer2: "Kui sealt valite edasi, saate klikata \"salasõna ununenud\"",
	Support_FAQs_section3_question3: "Kuidas seadistada töökoja kontot?",
	Support_FAQs_section3_question3_answer1: "Kui soovite saada töökoja rolli, võtke ühendust kohaliku koolituste administraatoriga, selleks võite kasutada veebilehe kontaktivormi.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Valige maa / region",
	MaintenanceBanner_Message_1: "Bosch Automotive Training Solutions portaali uuendatakse [maintenance_schedule]. Uuendus võtab eeldatavalt aega 30 minutit. Uuendamise ajal portaali ei pääse.",
	MaintenanceBanner_Message_2: "\nlisainfoks võtke palun ühendust e-posti teel [support_email]",
	MaintenancePage_Message_1: "Bosch Automotive Training Solutions portaali uuendatakse praegu",
	MaintenancePage_Message_2: "Eeldatavalt on portaal saadaval jälle [maintenance_online_date] kell [maintenance_online_time]",
	MenuHeader_Support: "Tugi",
	Course_Detail_No_Prerequisites: "eelistused puuduvad",
	TableCell_Hours: "tunnid",
	Dashboard_MenuItem_Dashboard: "avakuva",
	Dashboard_MenuItem_Curricula: "õppekava",
	Dashboard_MenuItem_Certificates: "sertifikaadid",
	Dashboard_MenuItem_Team: "meeskond",
	Dashboard_MenuItem_Workshops: "töökojad",
	Dashboard_MenuItem_External: "väline",
	Dashboard_MenuItem_Wallet: "rahakott",
	Dashboard_MenuItem_Profile: "profiil",
	Dashboard_Headline_YourUpcomingCourses: "teie tulevad koolitused",
	Dashboard_Headline_YourELearningCourses: "teie e-õppe kursused",
	Dashboard_Headline_TeamOverview: "meeskonna ülevaade",
	Dashboard_Headline_YourOverview: "teie ülevaade",
	Dashboard_Headline_YourWishlist: "teie soovinimekiri",
	Dashboard_Headline_ActivePrograms: "aktiivsed programmid",
	Dashboard_Headline_CompletedPrograms: "läbitud programmid",
	Dashboard_Headline_YourCertificates: "teie sertifikaadid",
	Dashboard_Headline_Transactions: "tehingud",
	Dashboard_Table_NoRecordsToDisplay: "kirjed puuduvad",
	Dashboard_TableColumn_Location_Online: "online",
	Dashboard_TableColumn_Duration_Hours: "tunnid",
	Dashboard_ELearningCourses_TableHeader_Title: "tiitel",
	Dashboard_ELearningCourses_TableHeader_Duration: "kestvus (h)",
	Dashboard_ELearningCourses_ActionButton_View: "vaade",
	Dashboard_UpcomingCourses_TableHeader_Title: "tiitel",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "kuupäev (päeva)",
	Dashboard_UpcomingCourses_TableHeader_Location: "koht",
	Dashboard_UpcomingCourses_ActionButton_View: "vaade",
	Dashboard_YourOverview_TableHeader_Title: "tiitel",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "kuupäev / kestvus",
	Dashboard_YourOverview_TableHeader_Location: "koht",
	Dashboard_YourOverview_TableHeader_Status: "staatus",
	Dashboard_YourOverview_ActionButton_View: "vaade",
	Dashboard_YourWishlist_TableHeader_Title: "tiitel",
	Dashboard_YourWishlist_TableHeader_Action: "tegevus",
	Dashboard_YourWishlist_ViewAllRecords: "vaata kõiki kirjeid",
	Dashboard_TeamOverview_TableHeader_Employee: "töötaja",
	Dashboard_TeamOverview_TableHeader_Title: "tiitel",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "kuupäev / kestvus",
	Dashboard_TeamOverview_TableHeader_Location: "koht",
	Dashboard_TeamOverview_TableHeader_Status: "staatus",
	Dashboard_TeamOverview_ActionButton_View: "vaade",
	Dashboard_TeamOverview_SearchBox_Placeholder: "otsing",
	Dashboard_ActiveProgram_TableHeader_Title: "tiitel",
	Dashboard_ActiveProgram_TableHeader_Status: "staatus",
	Dashboard_ActiveProgram_ActionButton_View: "vaade",
	Dashboard_CompletedProgram_TableHeader_Title: "tiitel",
	Dashboard_CompletedProgram_TableHeader_Status: "staatus",
	Dashboard_CompletedProgram_ActionButton_View: "vaade",
	Dashboard_Certificates_TableHeader_Title: "tiitel",
	Dashboard_Certificates_TableHeader_Date: "kuupäev",
	Dashboard_Certificates_TableHeader_Status: "staatus",
	Dashboard_Certificates_TableHeader_Action: "tegevus",
	Dashboard_Certificates_ActionButton_Download: "allalaadimine",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "arve number",
	Dashboard_Transactions_TableHeader_Description: "kirjeldus",
	Dashboard_Transactions_TableHeader_PaymentMethod: "makseviis",
	Dashboard_Transactions_TableHeader_Date: "kuupäev",
	Dashboard_Transactions_TableHeader_Amount: "kogus",
	Dashboard_Transactions_ActionButton_Download: "allalaadimine",
	Breadcrumb_MyTraining: "minu koolitus",
	Breadcrumb_MyTraining_CourseDetails: "koolituse info",
	Breadcrumb_MyTraining_Curricula: "õppekava",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "koolitusprogrammi info",
	Breadcrumb_MyTraining_Team: "meeskond",
	Breadcrumb_MyTraining_Team_ViewEmployee: "vaata töölist",
	MyTraining_Headerbar_CourseDetails: "koolituse info",
	MyTraining_Headerbar_TrainingProgramDetails: "koolitusprogrammi info",
	TrainingProgramDetails_Label_Status: "staatus",
	TrainingProgramDetails_Label_OverallProgress: "üldine edenemine",
	TrainingProgramDetails_ProgramItems_Headline: "programmi osad",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "tiitel",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "staatus",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "hinne",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "kuupäev",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "vaade",
	TrainingProgramDetails_Status_InProgress: "pooleli",
	TrainingProgramDetails_Status_Completed: "lõpetatud",
	CourseDetails_Label_Status: "staatus",
	CourseDetails_Label_Duration: "kestvus",
	CourseDetails_Label_Trainer: "koolitaja",
	CourseDetails_Label_Language: "keel",
	CourseDetails_Label_Score: "skoor:",
	CourseDetails_Label_StartDate: "alguse kuupäev",
	CourseDetails_Label_EndDate: "lõpu kuupäev",
	CourseDetails_Label_Venue: "toimumiskoht",
	CourseDetails_Label_Address: "aadress",
	CourseDetails_Label_Grade: "hinne",
	CourseDetails_Action_Withdraw: "võta tagasi",
	CourseDetails_Action_Evaluate: "koolituse hindamine",
	CourseDetails_Action_Evaluate_Description: "Palume sertifikaadi väljastamiseks hinnata koolitust",
	CourseDetails_Action_DownloadCertificate: "Sertifikaadi alla laadimine",
	CourseDetails_Action_JoinWebcast: "ühine veebikoolitusega",
	CourseDetails_Action_StartCourse: "alusta koolitust",
	CourseDetails_Action_RestartCourse: "taasalusta koolitust",
	CourseDetails_Action_RepeatCourse_Description: "Palume seda koolitust korrata ja läbida edukalt sertifikaadi allalaadimiseks!",
	CourseDetails_PartSpecificDetails_Headline: "osa info",
	CourseDetails_PartSpecificDetails_Tab_Part: "osa",
	CourseDetails_PartSpecificDetails_Description: "kirjeldus",
	CourseDetails_PartSpecificDetails_StartDate: "alguse kuupäev",
	CourseDetails_PartSpecificDetails_EndDate: "lõpu kuupäev",
	CourseDetails_PartSpecificDetails_StartTime: "alguse aeg",
	CourseDetails_PartSpecificDetails_EndTime: "lõpu aeg",
	CourseDetails_PartSpecificDetails_Method: "meetod",
	Dashboard_Team_TableHeader_Name: "nimi",
	Dashboard_Team_TableHeader_Role: "roll",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "isiklik konto",
	Dashboard_Team_ActionButton_Select: "vali",
	Dashboard_Team_PersonalAccount_Activated: "aktiveeritud",
	Dashboard_Team_PersonalAccount_NotActivated: "ai ole aktiveeritud",
	Dashboard_Team_ActionButton_AddMember: "liikme lisamine",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "otsi",
	Dashboard_External_TableHeader_Name: "nimi",
	Dashboard_External_ActionButton_AddNew: "lisa uus",
	Dashboard_External_Headline: "väline ligipääs lubatud",
	Dashboard_Workshops_Headline: "töökojad",
	Dashboard_Workshops_TableHeader_Company: "firma",
	Dashboard_Workshops_TableHeader_Address: "aadress",
	Dashboard_Workshops_TableHeader_Postcode: "indeks",
	Dashboard_Workshops_TableHeader_City: "linn",
	Dashboard_Workshops_ActionButton_Select: "vali",
	Wallet_ServiceCredits: "töökoja krediit",
	Wallet_Headline_CreditBalance: "krediidisaldo",
	Wallet_Headline_CreditActivity: "krediidiaktiivsus",
	Wallet_Headline_BillingTransactions: "arveldustehingud",
	Wallet_BillingTransaction_TableHeader_Order: "tellimus",
	Wallet_BillingTransaction_TableHeader_Date: "kuupäev",
	Wallet_BillingTransaction_TableHeader_Amount: "kogus",
	Wallet_BillingTransaction_TableHeader_Action: "tegevus",
	Wallet_CreditActivity_TableHeader_Date: "kuupäev",
	Wallet_CreditActivity_TableHeader_Description: "kirjeldus",
	Wallet_CreditActivity_TableHeader_User: "kasutaja",
	Wallet_CreditActivity_TableHeader_Amount: "kogus",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, kõik õigused kaitstud",
	FooterAdditionalLinks_Navigation_ProductSecurity: "tooteturvalisus (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "innovatsioonid, patendid ja litsentsid",
	FooterAdditionalLinks_Navigation_Logistics: "ostmine ja logistika",
	MenuHeader_MyTraining: "minu koolitus",
	MenuHeader_TrainingServices: "koolitusteenused",
	MenuHeader_OurLocations: "meie asukohad",
	MenuHeader_ContactsUs: "võtke ühendust",
	MenuHeader_Logout: "logi välja",
	MenuHeader_FAQ: "SEK",
	MyProfile_MyBoschId: "Minu Bosch ID",
	MyProfile_AccountDeletion: "konto kustutamine",
	MyProfile_Edit: "vaheta e-posti aadressi või salasõna",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "seadista kasutaja profiili",
	BackButton: "tagasi",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "ootame teie kõne ja oleme meeleldi nõus abistama. oleme teie jaoks saadaval 24/7",
	ContactUs_Email_SecurityCodeConfirm_V3: "Sisestage õiged märgid, nagu on näidatud ülemises kastis.",
	ContactUs_Email_MandatoryNotice_V3: "Täitke kõik kohustuslikud väljad.",
	ContactUs_Email_SendButton: "Saada",
	Breadcrumb_TrainingCenters: "Koolituskeskused",
	Training_Centres_Book_Btn: "broneeri koolitus",
	Training_centres_booking_code: "broneerimiskood",
	Training_centers_header_bar_title: "meie koolituskeskused",
	Training_centers_filter_item: "keskus",
	TeamEdit_PersonalAccountToolTop: "Selle funktsiooni lubamine võimaldab töölisel luua oma isiklik konto ja olla osaks teie firma kontost. Kui funktsioon on lubatud, saate saata kutse koos aktiveerimiskoodiga töölisele. Kutse saadetakse e-posti aadressile.",
	TeamEdit_Update: "Firma administraatori funktsiooni lubamine annab töötajale automaatselt täieliku ligipääsu teie firma kontole. Selle funktsiooni lubamiseks peab kasutaja lehelt välja logima ja uuesti sisse logima, kuimta parajasti on juba sisse logitud.",
	TeamEdit_PersonalAccount: "Personaalse Bosch Training Solutions veebikonto lubamine",
	TeamEdit_SendInvitation: "saada kutse",
	TeamEdit_Deactivate: "deaktiveeri",
	TeamEdit_Telephone: "telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "mobiili väljal peavad olema numbrid",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "telefoni väljal peavad olema numbrid",
	TeamEdit_Activate: "aktiivne",
	TeamEdit_NotActivated: "mitte aktiivne",
	TeamEdit_StatusPopup: "Olek kuvatakse \"aktiivne\", kui kasutaja on oma personaalse konto edukalt loonud. Kui olek kuvatakse \"mitte aktiivne\", tähendab see, et kasutaja ei ole kutset lõpuni käsitlenud.",
	Training_Catalog_Courses: "Koolitused",
	Training_Catalog_Course: "koolitus",
	Reset_Filter: "taasta filter",
	TrainingCatalogue_CourseCatalogue: "koolituste kataloog",
	CheckoutHeader_CartItemCount_Text: "Teil on [count] ühik ostukorvis",
	CheckoutHeader_CartItemsCount_Text: "Teil on [count] ühikut ostukorvis",
	ShoppingCart_PriceItem: "ühiku hind",
	ShoppingCartItemParticipants_AddParticipant_Button: "lisa osalejaid",
	ShoppingCartTotalPrice_SubTotalLabel: "vahesumma",
	ShoppingCartTotalPrice_TaxesLabel: "maksud",
	ShoppingCartTotalPrice_TotalLabel: "kokku",
	ShoppingCartTotalPrice_IncludedVATLabel: "sisaldab KM [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Saadaval online makseviis",
	ShoppingCartItemRow_VenueFeeLabel: "Koolituse maksumus [fee_amount] osaleja kohta",
	ShoppingCartItemRow_OnlineLabel: "online",
	AddParticipantsModal_Title: "lisa osaleja",
	AddParticipantsModal_SelectEmployeeLabel: "lisa osaleja",
	AddParticipantsModal_SelectWorkshopLabel: "vali töökoda",
	ShoppingCartTotalPrice_ContinueButtonText: "edasi ostma",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "osaleja ei ole lisatud!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Teil on üks, või rohkem koolitust ostukorvis",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Palun lisage osaleja ostuprotsessi jätkamiseks.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "muuda osalejaid ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "nimi",
	AddParticipantsModal_ParticipantTable_Email: "e-post",
	AddParticipantsModal_ParticipantTable_Action: "tegevus",
	AddParticipantsModal_ParticipantTable_NoParticipant: "osalejad puuduvad...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "e-posti aadress valitud osalejale ei ole konfigureeritud",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Valitud osalejal ei ole e-posti aadress profiili lisatud, e-posti aadress on kohustuslik koolitusele registreerumisel.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Jätkamiseks lisage e-posti aadrress või katkestage ja valige uus osaleja.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Lisa e-posti aadress",
	CancelButton: "katkesta",
	AddParticipantsModal_AddEmployeeButtonText: "lisa töötaja",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "te olete eemaldamas koolitust ostukorvist",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Te proovite eemaldada ostukorvist koolitust,kuhu on lisatud osalejad",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Kliki \"Edasi\" kui soovite jätkata või kliki \"Katkesta\".",
	ShoppingCartItemHeader_Participants: "Osaleja(d)",
	ShoppingCartItemHeader_ParticipantPrice: "ühe koha hind",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Näita [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Näita krediidis",
	ShoppingCart_CreditsPriceUnit: "krediite",
	CheckOutHeader_Step1_Question: "Kuidas soovite maksta?",
	CheckOutHeader_Step2_Question: "Tellimuse ülevaade",
	CheckOutHeader_Step3_Question: "Tellimuse täitmine",
	CheckOutHeader_Step4_Question: "teie broneerimine oli edukas!!",
	CheckOutHeader_Step_Payment: "Maksmine",
	CheckOutHeader_Step_Review: "ülevaade",
	CheckOutHeader_Step_Place_Order: "Tellimuse esitamine",
	CheckOutRightPanel_Review_Order_Btn: "Tellimuse ülevaatamine",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Tellimuse esitamine",
	CheckoutRightPanelContent_OrderSummary_Title: "Tellimuse kokkuvõte",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "seadista ostukorvi",
	ShoppingCart_Participants: "osalejad",
	CheckOutStepPayment_Payment_Methods_Title: "valge makseviis",
	CheckOutStepPayment_Credit_Title: "krediit või debit",
	CheckOutStepPayment_Wholesaler_Title: "edasimüüja",
	CheckOutStepPayment_ServiceCredit_Title: "töökojakrediidid",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Teil on [available_credit] krediiti",
	CheckOutStepPayment_BoschAccount_Title: "Boschi konto",
	Checkout_Second_PaymentMethod_Headline: "makseviis",
	CheckOutStepReview_Edit: "muuda",
	CheckOutStepReview_Terms_Label: "kasutustingimused ja tühistamispoliitika",
	CheckOutStepReview_Terms_Text: "Olen läbi lugenud, aru saanud ja nõustun: [kasutustingimused ja tühistamispoliitika] tingimustega.",
	SelectSecondPaymentMethodModal_Title: "Valige alternatiivne makseviis",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "makske [amount]",
	StepPaymentWholesaler_Title: "valige edasimüüja",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Tngimustega nõustumine",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Palun lugege kasutustingimused ja tühistamispoliitika läbi ja nõustuge nendega enne, kui jätkate",
	CheckoutDescription_ThankYou: "Täname, et tegite tellimuse Bosch Automotive Training Solutions keskkonnas!",
	CheckoutDescription_Message: "Teie tellimust juba käsitletakse, seni hoidke oma mootor tühikäigul.",
	CheckoutDesctiption_Text: "Me saadame lõpliku kinnituse e-posti teel kogu detailse infoga tellimuse kohta.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Olete eemaldamas valitud töökoda",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Valitud töökoja eemaldamine lähtestab ka kõik valitud osalejad. See muutus mõjutab kõiki korvis olevaid koolitusi.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klikake \"Edasi\" kui soovite jätkata või klikake \"Katkesta\" selle protsessi peatamiseks.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Teil ei ole piisavalt krediiti. Teie krediidikonto on [available_credit] krediiti, aga teil on vaja [cart_total_credits] krediiti ostu sooritamiseks. Palun valige alternatiivne makseviis või eemaldage ostukorvist tooteid.",
	Training_centers_filter_items: "keskused",
	Training_Programs_Filter_Item: "programmid",
	Training_Program_Filter_Item: "programm",
	Training_Program_Year: "aasta(d)",
	Training_Program_Detail_Title: "koolitusprogrammid",
	AccountButton_Workshops_MenuItem: "töökojad",
	AccountButton_Wallet_MenuItem: "rahakott",
	Header_MyBoschProfile_Header: "MyBosch profiil",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Saadaval",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Lisa/muuda osalejat",
	ShoppingCart_CreditPriceUnit: "Krediit",
	Common_IncludedVAT: "sis. KM",
	TrainingCatalogue_Length: "Pikkus",
	TrainingCatalogue_Method: "Meetod",
	Course_Price_Credits: "Krediiti",
	Catalogue_Grid_Register_Button: "Registreeri",
	Catalogue_Credit_Tilte: "Kliki siia nägemaks hinda krediidis",
	Catalogue_Price_Tilte: "Kliki siia nägemaks hinda EUR",
	Catalogue_Grid_Vat: "va.KM",
	EventCalendar_Seats: "Kohti",
	MenuHeader_Calendar: "Kalender",
	MenuHeader_Catalog: "Kataloog",
	TableCell_Minutes: "Minutit",
	ListFilter_NoResult: "Tulemused puuduvad",
	Course_Description: "Kursuse kirjeldus",
	Training_course_detail: "Sotsiaalmeedia alusel",
	Training_course_detail_add_waiting: "Lisa üldisesse ootenimekirja",
	Register_now_btn: "Registreeri nüüd!",
	Register_now_btn_add_event_to_cart: "Registreeri nüüd!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Vaata kõiki kirjeid",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Vaata vähem",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Vaata kõiki kirjeid",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Vaata vähem",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Vaata kõiki kirjeid",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Vaata vähem",
	TeamCreate_TitleError: "Tiitel on vajalik",
	Dashboard_Headerbar_BookCourse: "Broneeri koolitus",
	Dashboard_Headerbar_ExitProxy: "Välju asendusest",
	Dashboard_Headerbar_EvaluateCourse: "Hinda koolitust",
	Dashboard_Headerbar_EvaluateCourse_Note: "Sertifikaadi loomiseks on vaja anda koolitusele hinnang!",
	Dashboard_Headerbar_DownloadCertificate: "Lae sertifikaat alla",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Võta tagasi",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Võta tagasi",
	Dashboard_Headerbar_WelcomeMessage: "Tere tulemast koolituste avalehele",
	Dashboard_Headerbar_ProxingAs: "Asendus",
	Dashboard_TrainingProgram_Status_Completed: "Valmis",
	Dashboard_TrainingProgram_Status_InProgress: "pooleli",
	External_SelectWholesalerModal_Title: "Välise kasutaja ligipääsu lisamine",
	External_SelectWholesalerModal_Company_Label: "Firma",
	External_SelectWholesalerModal_Outlet_Label: "Osakond",
	External_SelectWholesalerModal_Company_Placeholder: "Palun valige",
	External_SelectWholesalerModal_Outlet_Placeholder: "Palun valige",
	External_SelectWholesalerModal_ApplyButton: "Lisa",
	External_SelectWholesalerModal_CancelButton: "Katkesta",
	External_RemoveWholesalerModal_ConfirmLabel: "Kinnitus",
	External_SelectWholesalerModal_ConfirmMessage: "Kas soovite kindlasti eemaldada selle edasimüüja?",
	External_SelectWholesalerModal_YesButton: "Jah",
	External_SelectWholesalerModal_NoButton: "Ei",
	Training_program_team_member: "Tiimi liige",
	Training_program_assign_training_program: "Koolitusprogrammi määramine",
	Assign_modal_student_confirmation_text1: "Olete lisamas [Training Program Name] oma koolitusteekonnale.",
	Assign_modal_student_confirmation_text2: "Kas soovite jätkata?",
	MyFullCalendar_Jan: "Jaan.",
	MyFullCalendar_Feb: "Veebr.",
	MyFullCalendar_Mar: "Märts",
	MyFullCalendar_Apr: "Apr.",
	MyFullCalendar_May: "Mai",
	MyFullCalendar_Jun: "Juuni",
	MyFullCalendar_Jul: "Juuli",
	MyFullCalendar_Aug: "Aug.",
	MyFullCalendar_Sep: "Sept.",
	MyFullCalendar_Oct: "Okt.",
	MyFullCalendar_Nov: "Nov.",
	MyFullCalendar_Dec: "Dets.",
	MenuHeader_CourseCatalog: "Koolituskataloog",
	MenuHeader_Event: "koolituste kalender",
	MenuHeader_Program: "Koolitusprogramm",
	MenuHeader_Services: "Koolitusteenused",
	MenuHeader_Log_out: "Logi välja",
	Filter_Location: "Koht",
	TeamEdit_UpgradeToCompanyAdministrator: "Firma administraatoriks tõstmine",
	TeamEdit_PersonalWebAccountStatusIs: "Personaalse veebikonto seis on",
	Header_CreateNewEmployee: "Uue töötaja loomine",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Vabu kohti pole!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Valitud koolitusele ei ole vabu kohti.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Jätkamiseks palume valida mõni teine koolitus või eemaldada juba lisatud osaleja.",
	Common_SuccessModal_Title: "Õnnitlused!",
	TrainingProgramDetails_Merit_StatusText: "väärtus",
	TrainingProgramDetails_Resit_StatusText: "uuesti lisama",
	EmployeeProfile_UserExistErrorMessage: "Konto on juba olemas!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "maksmine ebaõnnestus, palume korrata erineva maksemeetodiga",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Valitud töötaja on edukalt deaktiveeritud!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Salvestatud!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Muudatused on salvestatud.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "e-kursuselt tagasi võtmine pole võimalik. Palume võtta meiega ühendust, kui on vaja täpsustusi.",
	Dashboard_Team_PersonalAccount_Pending: "ootel",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Töötaja loodud!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Teie töötaja loomine on edukalt lõpule viidud.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "See seadistus võimaldab keelata töötajal koolitustele registreerumise. Kui see seadistus on välja lülitatud, saab töötaja koolitusele registreerida vaid firma administraator.",
	Training_catalog_sort_by: "Sordi",
	CourseDetail_EvaluationRestriction_Title: "Koolituse hindamine pole võimalik!",
	CourseDetail_EvaluationRestriction_Message: "Koolituse hindamine on võimalik vaid koolitusel osaleja poolt. \nPalume juhendada osalejat sisse logimao Bosch Automotive Training Solutions keskkonda ja teostama koolituse hindamist.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Kui koolitusel osaleja ei oma veel kontot, on võimalik kutse saata kasutaja tiimi liikme profiili seadistustes.",
	Breadcrumb_Download_Page: "Lae alla",
	Download_Table_Category_Cell: "Kategooria",
	Download_Table_Title_Cell: "Tiitel",
	Download_File_Filter_Item: "Fail",
	Download_File_Filter_Items: "Failid",
	Download_Header: "Allalaadimisala",
	DownloadArea_Button_Download: "Lae alla",
	Popup_Title_Error: "Viga",
	Popup_Title_Success: "Edukas!",
	Popup_Title_Info: "Teave",
	Popup_Title_Warning: "HOIATUS!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Pärast võimalikke sündmuste registreerimist juba olemasolevaid registreerimisi ei tühistata ja need tuleb eraldi tühistada.",
	TrainingProgram_PDF_Export: "PDF -eksport",
	TrainingProgram_PDF_Export_Participant: "Osaleja:",
	TrainingProgram_PDF_Export_Status: "Staatus:",
	TrainingProgram_PDF_Export_Progress: "Üldine areng:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Koolituse pealkiri",
	TrainingProgram_PDF_Export_TableHeader_Status: "Olek",
	TrainingProgram_PDF_Export_TableHeader_Grade: "hinne",
	TrainingProgram_PDF_Export_TableHeader_Date: "Kuupäev",
	AccountButton_CompanyChange_MenuItem: "Ettevõtte muutus",
	SelectCompanyModal_Title: "Valige ettevõte",
	CloseButton: "Sulge",
	Change_Button: "Muutus",
	ChangeManagerModal_Content: "Valige kasutaja",
	ChangeCompanyModal_HeadOffice: "(Peakontor)",
	SelectCompanyModal_Content: "Valige sisselogimiseks ettevõte ...",
	AlertSuccess_Title: "Edukas!",
	CheckOutStepPayment_SecondaryPayment_Title: "Valige teisene makseviis",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Lisage teisene makseviis",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Lisage allpool sekundaarne makseviis või valige ülal mõni muu makseviis.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Teil on oma kontol ebapiisav teenusekrediit, et maksta ostukorvi sisu eest. Valitud makseviisi kasutamise jätkamiseks peate maksma erinevuse. Erinevus on [difference_amount] (ilma käibemaksuta).",
	ShoppingCartTotalPrice_TotalInCredit: "Kogu hind krediidis",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Teie olemasolev krediit",
	ShoppingCartTotalPrice_YourDifference: "Erinevus",
	ShoppingCartTotalPrice_SubtotalExTax: "Hind kokku ilma KM-ta.",
	ShoppingCartTotalPrice_SubtotalInTax: "Hind kokku koos km-ga.",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Teisene makseviis",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Muuda maksemeetodit",
	Checkout_Second_PaymentMethod: "Teine makseviis",
	Breadcrumb_TrainingCourseDetails: "Koolituskursuse üksikasjad",
	DownloadArea_FailedToDownload_ErrorMessage: "Dokumenti ei õnnestunud alla laadida. Proovige uuesti või pöörduge abi saamiseks oma ettevõtte administraatori poole.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Allalaadimisfail pole saadaval. Proovige uuesti või pöörduge abi saamiseks oma ettevõtte administraatori poole.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Allalaadimisfail on keelatud. Proovige uuesti või pöörduge abi saamiseks oma ettevõtte administraatori poole.",
	SSO_Redirecting_Message: "Teid suunatakse Boschi koolitusportaali…",
	SSO_Redirecting_ParameterErrorMessage: "Maa ja/või keel ei ole määratud. Palun proovige uuesti või võtke ühendust oma asutuse administraatoriga abi saamiseks.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Registreerimistaotlus(ed) ei ole aktsepteeritud.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Võimalik, et kursusele registreerimise tähtaeg on möödas, Palume kasutada \"võtke meiega ühendust\" vormi küsimuste või soovide korral.",
	TimeSlotPicker_DateFormat_Title: "Kuupäeva formaat",
	UpdateAccountSettingsConfirmationModal_Title: "Olete muutmas kuupäeva formaati!",
	UpdateAccountSettingsConfirmationModal_Message1: "Kuupäeva kuvatakse valitud formaadis ja seda saab muuta igal ajal.",
	UpdateAccountSettingsConfirmationModal_Message2: "Kas soovite jätkata?",
	ProfileSummary_Settings_Title: "Asetused",
	UpdateAccountSettings_DateFormat_Title: "Kuupäeva formaadi asetused",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Kuupäeva formaati ei õnnestunud muuta.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Kuupäeva formaat edukalt muudetud.",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Koolitusprogrammi sertifikaat",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Dokumendi allalaadimine ebaõnnestus. Palume proovida uuesti või võtta ühendust oma ettevõtte administraatoriga abi saamiseks.",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Tühistamise taotlus on ootel, kuna tühistamine on tehtud peale tasuta tühistamise perioodi lõppu, õpilane saab lisainformatsiooni e-posti teel kui taotlus on kinnitatud/tagasi lükatud.",
	TrainingCourse_Course_NotAvailable_Title: "Koolitus ei ole saadaval.",
	TrainingCourse_Course_NotAvailable_Message: "Koolitus ei ole saadaval, palun proovige uuesti või võtke ühendust oma ettevõtte administraatoriga abi saamiseks.",
	TrainingCourse_NotAvailable_Message_1: "Valitud koolitus ei ole saadaval.",
	TrainingCourse_NotAvailable_Message_2: "Palume proovida uuesti või valida [koolitused] lisakoolituste leidmiseks.",
	LanguageSelection_SelectALanguage: "Keele valik",
	LanguageSelection_SelectYourCountry: "Maa valik",
	Checkout_PaymentMethod_Headline: "makseviis",
	Download_Categories_Catalogue: "kataloog",
	Download_Categories_Calendar: "kalender",
	Download_Categories_General: "üldine",
	Download_Categories_Information: "informatsioon",
	Download_Categories_Userguides: "juhis kasutajale",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "tühistamispoliitika",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Tühista",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Jätka",
	Duration_Seconds: "sekundid",
	Duration_Day: "päev",
	Duration_Hour: "tund",
	Duration_Minute: "Minutit",
	Duration_Second: "sekund",
	EventCalendar_ExternalLink_Tooltips: "ost [externalRegistrationName] kaudu",
	EventCalendar_ExternalLink_Seat_NA: "puudub",
	EventCalendar_ExternalLink_Cancel: "katkesta",
	EventCalendar_ExternalLink_Continue: "edasi",
	EventCalendar_ExternalLinkModal_Title: "Teid suunatakse edasi…",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "valitud koolitust müüakse  [externalRegistrationName] kaudu.",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Jätkamisel suunatakse teid välisele veebilehele",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Kas soovite jätkata?",
	Team_ContactPerson_FirstName_Tooltips: "Õpilaste nimesid saab muuta vaid Bosch koolituskeskkonna administreerimismeeskond. Palume võtta ühendust kontaktvormi kaudu antud lehel edasise toe saamiseks.",
	Team_ContactPerson_LastName_Tooltips: "Õpilaste nimesid saab muuta vaid Bosch koolituskeskkonna administreerimismeeskond. Palume võtta ühendust kontaktvormi kaudu antud lehel edasise toe saamiseks.",
	Withdraw_ExternalLink_Cancel: "Katkesta",
	Withdraw_ExternalLink_Confirm: "Kinnita",
	Withdraw_ExternalLinkModal_Title: "Teid suunatakse edasi",
	Withdraw_ExternalLinkModal_Message: "Selle koolituse makseid käsitleb väline pakkuja",
	Withdraw_ExternalLinkModal_Confirmation: "Klikates \"Kinnita\" avaneb uus vaheleht ja teid suunatakse välisele veebilehele.",
	Button_AddToWaitingList_Tooltips: "Lisamine ootelehele",
	Button_AddToCart_Tooltips: "Lisamine ostukorvi",
	Button_TrainingCourseDetail_Tooltips: "Vaata täpsemalt",
	Button_TrainingProgramDetail_Tooltips: "Vaata täpsemalt",
	Button_AssignTrainingProgram_Tooltips: "Koolitusprogrammi määramine",
	Button_DeleteFromCart_Tooltips: "Ostukorvist kustutamine",
	Button_RemoveParticipant_Tooltips: "Osaleja eemaldamine",
	Button_DownloadBillingTransaction_Tooltips: "Lae alla",
	Button_RemoveExternal_Tooltips: "eemalda",
	Breadcrumb_TrainingServices: "Koolitusteenused",
	Breadcrumb_Support: "Tugi",
	Breadcrumb_FAQs: "KKK",
	Breadcrumb_TrainingProgramDetail: "Koolitusprogrammi sisu",
	Breadcrumb_MyTraining_MyTrainingEvents: "Minu koolitused",
	Breadcrumb_MyTraining_External: "Väline",
	Breadcrumb_MyTraining_Wallet: "Rahakott",
	Breadcrumb_MyTraining_Workshop: "Töökoda",
	Breadcrumb_MyTraining_Workshop_Team: "Meeskond",
	Breadcrumb_MyTraining_Team_Profile: "Profiil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Profiili muutmine",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Töötaja loomine",
	SideNavigation_Title: "Koolituslahendused",
	SideNavigation_AccountButton_RegisterOrLogin: "Registreeri / logi sisse",
	SideNavigation_Language: "Keel",
	SideNavigation_SelectLanguage: "Keele valik",
	SideNavigation_AccountButton_Logout: "Logi välja",
	SideNavigation_ConntactUs: "Võtke meiega ühendust",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Korporatiivne info",
	FooterAdditionalLinks_Navigation_LegalNotice: "Õiguslikud teated",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Andmekaitse teatis",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Privaatsusseaded",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Kasutustingimused",
	Common_Version_Text: "Versioon: {{version}}",
	Content_Header_Dashboard_MyTrainingEvents: "Minu koolitused",
	Content_Header_Dashboard_MyTrainingPrograms: "Minu koolitusprogrammid",
	Content_Header_Dashboard_Team: "Meeskond",
	Content_Header_Dashboard_Workshops: "Töökojad",
	Content_Header_Dashboard_External: "Väline",
	Content_Header_Dashboard_Wallet: "Rahakott",
	Content_Header_Dashboard_MyProfile: "Minu profiil",
	RedeemVoucher_RemoveButton: "Eemalda",
	RedeemVoucher_ApplyButton: "Lisa",
	Checkout_VoucherApplied_Label: "Sinu kood",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Kehtetu vautšeri kood",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Sisestatud vautšeri kood on kehtetu.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Palume proovida uuesti või jätkata ilma vautšeri koodita.",
	RedeemVoucherModal_CancelButton: "Katkesta",
	RedeemVoucherModal_TryAgainButton: "Proovi uuesti",
	CreditsConfirmationModal_ContinueButton: "Edasi",
	CreditsConfirmationModal_CancelButton: "Katkesta",
	CreditsConfirmationModal_Title: "Vautšer lisatud",
	CreditsConfirmationModal_Message_1: "Makse teenuse krediidiga ei ole võimalik, kui ostukorvi on lisatud kehtetu vautšer.",
	CreditsConfirmationModal_Message_2: "Klikake \"Katkesta\" tagasi minemiseks või klikake \"Edasi\" vautšeri eemaldamiseks ostukorvist."
};