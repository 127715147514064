import { User } from "oidc-client-ts";
import { useEffect, useState } from "react";
import { CreateUpdateAccountInput } from "../../models/Account";
import {
  updateAccount,
  getAccountSimpleForEditProfileAsync,
} from "../../redux-toolkit/accountReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { AuthService } from "../../services/AuthService";
import { useTranslation } from "react-i18next";
import "../../localization/i18n";
import { useSelector } from "react-redux";
import { selectAuthState } from "../../redux-toolkit/authReducer";
import { CommonHelper } from "../../helpers/CommonHelper";
import { UserProfileConstants } from "../../helpers/UserProfileConstants";

const EditProfileCallback = () => {
  const { t } = useTranslation();
  const accountService = AuthService.getInstance();
  const authState = useSelector(selectAuthState);
  const [status, setStatus] = useState("");
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const updateAccountAsync = async (resp: User, ciamId: string) => {
    let response = await dispatchReduxToolkit(
      getAccountSimpleForEditProfileAsync(ciamId)
    );
    if (
      getAccountSimpleForEditProfileAsync.fulfilled.match(response) &&
      !!response.payload &&
      response.payload.success &&
      response.payload.dataObject
    ) {
      if (resp.profile) {
        let profile = resp.profile;
        const countryCodeLower = profile.countrycode as string;
        const account: CreateUpdateAccountInput = {
          ciamId: profile.ciam_id as string,
          customerId: 0,
          country: !!authState.countryCode ? authState.countryCode : "",
          salesRegion: "",
          language: !!authState.language ? authState.language : "",
          timeZoneId: !!authState.timeZoneId ? authState.timeZoneId : "",
          ciamEmail: !!profile.email ? profile.email : "",
          customerNumber: 0,
          companyPhone:
            !!profile.company && !!(profile.company as any).phone
              ? (profile.company as any).phone
              : "",
          companyEmail: "",
          companyName:
            !!profile.company && !!(profile.company as any).companyName
              ? (profile.company as any).companyName
              : "",
          companyAddress:
            !!profile.company && !!(profile.company as any).address1
              ? (profile.company as any).address1
              : "",
          companyCity:
            !!profile.company && !!(profile.company as any).city
              ? (profile.company as any).city
              : "",
          companyPostCode:
            !!profile.company && !!(profile.company as any).zipCode
              ? (profile.company as any).zipCode
              : "",
          contactFirstName: !!profile.given_name ? profile.given_name : "",
          contactLastName: !!profile.family_name ? profile.family_name : "",
          ciamRole: !!(profile.company as any)?.position
            ? (profile.company as any).position
            : "",
          vat: !!(profile.company as any).taxNumber
            ? (profile.company as any).taxNumber.substring(0, 25)
            : "",
          termAndConditions:  CommonHelper.getUserProfileAttribute(
            UserProfileConstants.PROFILE_ATTRIBUTE_TERMS_AND_CONDITIONS,
            countryCodeLower,
            profile
          ),
          mobile: !!profile.mobile ? (profile.mobile as string) : "",
        };
        let resUpdateAccount = await dispatchReduxToolkit(
          updateAccount(account)
        );
        if (updateAccount.fulfilled.match(resUpdateAccount)) {
        }
        if (updateAccount.rejected.match(resUpdateAccount)) {
          setStatus(t("Error_Common"));
        }
      }
    }
  };

  useEffect(() => {
    accountService.editProfileCallbackAsync().then(async (resp) => {
      if (!!resp && !!!!resp.profile && !!resp.profile.ciam_id) {
        await updateAccountAsync(resp, resp.profile.ciam_id as string);
      }
      setTimeout(() => {
        if (!!(resp.state as any)?.redirectUrl)
          window.location.href = (resp.state as any).redirectUrl;
        else window.location.href = "/";
      }, 2000);
    });
  }, []);

  return <div>{status && <p>Error: {status}</p>}</div>;
};

export default EditProfileCallback;
