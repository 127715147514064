import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState = {
 searchKey: "",
 searchSignal: false
};

export const searchSlice = createSlice({
  name: 'searchReducer',
  initialState,
  reducers: {
    performSearch: (state, action) => {
      return {
        ...state,
        searchKey: action.payload,
        searchSignal: !state.searchSignal
      }
    },
    clearSearch: (state) => {
      return {
        ...state,
        searchKey: "",
        searchSignal: false
      }
    }
  }
})

export const selectSearchKey = (rootState: RootState) => rootState.searchStateBats.searchKey;
export const selectSearchSignal = (rootState: RootState) => rootState.searchStateBats.searchSignal;
export const { performSearch, clearSearch} = searchSlice.actions;
export default searchSlice.reducer;