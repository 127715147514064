import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CurrencyHelper, NoPriceDisplayType } from "../helpers/CurrencyHelper";
import { ShoppingCartItem } from "../models/CartItem.Model";
import { Cart } from "../models/payment/EnrollEventRequest.model";
import {
  DeleteCartEventItemDetail,
  DeleteCartItemRequestModel,
} from "../models/ShoppingCartUser.Model";
import {
  DisplayPriceType,
  selectAccount,
  selectCiamId,
  selectCustomerId,
  selectDisplayCredit,
} from "../redux-toolkit/accountReducer";
import {
  selectCountryCode,
  selectLanguage,
} from "../redux-toolkit/authReducer";
import {
  removeEventIdFromCart,
  selectShoppingCart,
  selectShoppingCartModel,
} from "../redux-toolkit/shoppingCartReducer";
import { deleteFromCartAsync } from "../redux-toolkit/shoppingCartUserReducer";
import { AppDispatch, useAppDispatch } from "../redux-toolkit/store";

export const useShoppingCart = () => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const customerId = useSelector(selectCustomerId);
  const language = useSelector(selectLanguage);
  const country = useSelector(selectCountryCode);
  const shoppingCart = useSelector(selectShoppingCart);
  const shoppingCartModel = useSelector(selectShoppingCartModel);
  const displayPriceType = useSelector(selectDisplayCredit);
  const [cartItems, setCartItems] = useState<ShoppingCartItem[]>([]);
  const [cartItemsEnrolment, setCartItemsEnrolment] = useState<Cart[]>();
  const [itemCount, setItemCount] = useState(0);
  const [cartCurrency, setCartCurrency] = useState("");
  const account = useSelector(selectAccount);
  const removeEventCart = (eventId: number) => {
    return dispatchReduxToolkit(removeEventIdFromCart(eventId));
  };

  const removeCartItems = (
    participants: number[],
    cartItem: ShoppingCartItem,
    isRemoveFromStorage: boolean,
    onComplete?: Function
  ) => {
    if (participants.length > 0) {
      const deleteModel: DeleteCartItemRequestModel = {
        ciamId: ciamId || "",
        customerId: customerId || 0,
        language: language,
        country: country,
        cartItems: participants.map((x) => {
          const removeItem: DeleteCartEventItemDetail = {
            courseId: cartItem.courseId,
            customerId: cartItem.customerId,
            eventId: cartItem.eventId,
            participantId: +x,
          };
          return removeItem;
        }),
      };

      const deleteResponse = dispatchReduxToolkit(
        deleteFromCartAsync(deleteModel)
      );
      deleteResponse.then((data) => {
        if (
          isRemoveFromStorage &&
          participants.length === (cartItem.cartItemParticipants?.length || 0)
        ) {
          removeEventCart(cartItem.eventId);
        }
        if (onComplete) {
          onComplete();
        }
      });
    } else {
      removeEventCart(cartItem.eventId);
    }
  };

  const formatCurrency = (
    value: number,
    symbol: string,
    country: string | null,
    noPriceDisplayType: NoPriceDisplayType
  ) => {
    if (displayPriceType === "credit")
      return CurrencyHelper.format(
        value,
        ` ${
          value > 1
            ? t("ShoppingCart_CreditsPriceUnit")
            : t("ShoppingCart_CreditPriceUnit")
        }`,
        country,
        noPriceDisplayType,
        {
          significantDigits: 0,
        },
        true
      );

    return CurrencyHelper.format(value, symbol, country, noPriceDisplayType);
  };

  const formatCurrencyNotCredit = (
    value: number,
    symbol: string,
    country: string | null,
    noPriceDisplayType: NoPriceDisplayType
  ) => {
    return CurrencyHelper.format(value, symbol, country, noPriceDisplayType);
  };

  const getVenueFeeForCartItemWithCurrency = (cartItem: ShoppingCartItem) => {
    if (displayPriceType === "credit")
      return formatCurrency(
        cartItem.venueCreditPrice,
        cartItem.currencySymbol,
        country,
        "ZERO"
      );
    return formatCurrency(
      cartItem.venuePrice,
      cartItem.currencySymbol,
      country,
      "ZERO"
    );
  };

  const getCartItemPrice = (cartItem: ShoppingCartItem) => {
    if (displayPriceType === "currency") {
      let price = cartItem.price;
      return price;
    }

    //TODO: Include venue fee in credit
    return cartItem.creditPrice;
  };

  const [voucherIsApplied, setVoucherIsApplied] = useState(false);

  useEffect(() => {
    if (
      shoppingCart?.voucherCode !== null &&
      shoppingCart?.voucherCode !== "" &&
      shoppingCart?.isAppliedVoucherCode
    ) {
      setVoucherIsApplied(true);
    } else {
      setVoucherIsApplied(false);
    }
  }, [
    displayPriceType,
    shoppingCart?.voucherCode,
    shoppingCart?.isAppliedVoucherCode,
    shoppingCart,
  ]);

  const isAppliedVoucher = () => {
    return (
      displayPriceType === "currency" &&
      shoppingCart?.voucherCode !== null &&
      shoppingCart?.voucherCode !== "" &&
      shoppingCart?.isAppliedVoucherCode
    );
  };

  const isAnyItemCartAppliedVoucher = () => {
    const hasDiscount = !!cartItems.find((x) => x.isAppliedVoucherCode  );
    return hasDiscount;
  };

  const isDiscountedSubtotal = () => {
    const totalPrice = shoppingCart?.shoppingCart?.subTotal;
    const totalPriceAfterVoucher =
      shoppingCart?.shoppingCart?.subTotalAfterVoucher;
    return totalPrice !== totalPriceAfterVoucher;
  };

  const getCartItemPriceAfterVoucher = (cartItem: ShoppingCartItem) => {
    if (displayPriceType === "currency") {
      let price = cartItem.priceAfterVoucher;
      return price;
    }

    //TODO: Include venue fee in credit
    return cartItem.creditPrice;
  };

  const getCartItemPriceWithCurrencyAfterVoucher = (
    cartItem: ShoppingCartItem
  ) => {
    const price = getCartItemPriceAfterVoucher(cartItem);
    return formatCurrency(price, cartItem.currencySymbol, country, "ZERO");
  };

  const getCartItemPriceWithCurrency = (cartItem: ShoppingCartItem) => {
    const price = getCartItemPrice(cartItem);
    return formatCurrency(price, cartItem.currencySymbol, country, "FREE");
  };

  const getCartItemTotalPriceAfterVoucher = (cartItem: ShoppingCartItem) => {
    if (displayPriceType === "currency") {
      let price = cartItem.totalPriceAfterVoucher;
      return price;
    }

    //TODO: Include venue fee in credit
    return cartItem.totalCreditPrice;
  };

  const getCartItemTotalPrice = (cartItem: ShoppingCartItem) => {
    if (displayPriceType === "currency") {
      let price = cartItem.totalPrice;
      return price;
    }

    //TODO: Include venue fee in credit
    return cartItem.totalCreditPrice;
  };

  const getCartItemTotalPriceWithCurrency = (cartItem: ShoppingCartItem) => {
    const totalPrice = getCartItemTotalPrice(cartItem);
    const hasParticipants = (cartItem?.cartItemParticipants?.length || 0) > 0;
    return formatCurrency(
      totalPrice,
      cartItem.currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCartItemTotalPriceWithCurrencyAfterVoucher = (
    cartItem: ShoppingCartItem
  ) => {
    const totalPriceAfterVoucher = getCartItemTotalPriceAfterVoucher(cartItem);
    const hasParticipants = (cartItem?.cartItemParticipants?.length || 0) > 0;
    return formatCurrency(
      totalPriceAfterVoucher,
      cartItem.currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCartSubTotalPriceWithCurrency = () => {
    if (!shoppingCart?.shoppingCart?.items?.length) return "-";
    const cartItems = shoppingCart.shoppingCart.items;
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const currencySymbol = cartItems[0].currencySymbol;
    const totalPrice = shoppingCart.shoppingCart.subTotal;
    return formatCurrency(
      totalPrice,
      currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCartSubTotalPriceWithCurrencyAfterVoucher = () => {
    if (!shoppingCart?.shoppingCart?.items?.length) return "-";
    const cartItems = shoppingCart.shoppingCart.items;
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const currencySymbol = cartItems[0].currencySymbol;
    const totalPriceAfterVoucher =
      shoppingCart.shoppingCart.subTotalAfterVoucher;
    return formatCurrency(
      totalPriceAfterVoucher,
      currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCartTotalTaxesWithCurrency = () => {
    if (!shoppingCart?.shoppingCart?.items?.length) return "-";
    const cartItems = shoppingCart.shoppingCart.items;
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const currencySymbol = cartItems[0].currencySymbol;
    const totalPrice = shoppingCart.shoppingCart.taxes;
    return formatCurrency(
      totalPrice,
      currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCartTotalTaxesWithCurrencyAfterVoucher = () => {
    if (!shoppingCart?.shoppingCart?.items?.length) return "-";
    const cartItems = shoppingCart.shoppingCart.items;
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const currencySymbol = cartItems[0].currencySymbol;
    const totalPrice = shoppingCart.shoppingCart.taxes;
    return formatCurrency(
      totalPrice,
      currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCartTotalVenueFee = () => {
    return shoppingCart?.shoppingCart?.venuePrice || 0;
  };

  const getCartTotalVenueFeeWithCurrency = () => {
    if (!shoppingCart?.shoppingCart?.items?.length) return "-";
    const cartItems = shoppingCart.shoppingCart.items;
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const currencySymbol = cartItems[0].currencySymbol;
    const totalPrice = getCartTotalVenueFee();
    return formatCurrency(
      totalPrice,
      currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCartTotal = (priceType: DisplayPriceType) => {
    if (priceType === "currency") {
      return shoppingCart?.shoppingCart?.total ?? 0;
    }

    return shoppingCart?.shoppingCart?.totalCreditPrice ?? 0;
  };

  const getCartTotalAfterVoucher = (priceType: DisplayPriceType) => {
    if (priceType === "currency") {
      return shoppingCart?.shoppingCart?.totalAfterVoucher ?? 0;
    }

    return shoppingCart?.shoppingCart?.totalCreditPrice ?? 0;
  };

  const getCartTotalForSecondPayment = () => {
    const convertToCurrency = Number(
      shoppingCart?.shoppingCart?.subTotalIncludeTax ?? 0
    );
    return convertToCurrency;
  };

  const getCurrentCreditsNumber = () => {
    const availableCredits = account?.availableCredits?.credits ?? 0;
    return availableCredits;
  };

  const getCartTotalWithCurrency = () => {
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    if (displayPriceType === "currency") {
      if (!shoppingCart?.shoppingCart?.items?.length) return "-";
      const cartItems = shoppingCart.shoppingCart.items;
      const currencySymbol = cartItems[0].currencySymbol;
      const total = getCartTotal(displayPriceType);
      return formatCurrency(
        total,
        currencySymbol,
        country,
        hasParticipants ? "ZERO" : "MINUS"
      );
    }

    const totalPrice = getCartTotal(displayPriceType);
    return formatCurrency(
      totalPrice,
      "",
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCartTotalWithCurrencyAfterVoucher = () => {
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    if (displayPriceType === "currency") {
      if (!shoppingCart?.shoppingCart?.items?.length) return "-";
      const cartItems = shoppingCart.shoppingCart.items;
      const currencySymbol = cartItems[0].currencySymbol;
      const total = getCartTotalAfterVoucher(displayPriceType);
      return formatCurrency(
        total,
        currencySymbol,
        country,
        hasParticipants ? "ZERO" : "MINUS"
      );
    }

    const totalPrice = getCartTotal(displayPriceType);
    return formatCurrency(
      totalPrice,
      "",
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getCurrentAvailableCredits = () => {
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const availableCredits = account?.availableCredits?.credits || 0;
    return formatCurrency(
      availableCredits,
      "",
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getDifferenceCredits = () => {
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const diffCredits = Number(
      shoppingCart?.shoppingCart?.differenceCredits || 0
    );
    return formatCurrency(
      diffCredits,
      "",
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getSubTotalExTax = () => {
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const convertToCurrency = Number(
      shoppingCart?.shoppingCart?.subTotalExcludeTax || 0
    );
    const currencySymbol = cartItems[0]?.currencySymbol;
    return formatCurrencyNotCredit(
      convertToCurrency,
      currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getSubTotalIncTax = () => {
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const convertToCurrency = Number(
      shoppingCart?.shoppingCart?.subTotalIncludeTax || 0
    );
    const currencySymbol = cartItems[0]?.currencySymbol;
    return formatCurrencyNotCredit(
      convertToCurrency,
      currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const getTaxBySubTotal = () => {
    const hasParticipants = !!cartItems.find(
      (x) => x.cartItemParticipants && x.cartItemParticipants.length > 0
    );
    const convertToCurrency = Number(
      shoppingCart?.shoppingCart?.taxBySubTotal || 0
    );
    const currencySymbol = cartItems[0]?.currencySymbol;
    return formatCurrencyNotCredit(
      convertToCurrency,
      currencySymbol,
      country,
      hasParticipants ? "ZERO" : "MINUS"
    );
  };

  const isCreditAccountDisabled = () => {
    const accountCredit = account?.availableCredits?.credits || 0;
    const cartTotalCredit = shoppingCartModel?.totalCreditPrice || 0;
    return accountCredit < cartTotalCredit;
  };

  const getAdditionalPaymentCurrency = () => {
    let additionalPaymentCurrency = {
      country: country || "",
      currency: shoppingCart?.shoppingCart?.currency || "",
      symbol: shoppingCart?.shoppingCart?.currencySymbol || "",
    };
    return additionalPaymentCurrency;
  };

  const convertCartItems = () => {
    if (!!shoppingCart?.shoppingCart?.items) {
      const convertedItems = shoppingCart.shoppingCart.items
        .map(({ cartItemParticipants, ...cartItem }) =>
          cartItemParticipants.map((cartItemParticipant) => ({
            id: +(cartItemParticipant.cartItemId || 0),
            customerId: cartItem.customerId,
            participantId: +(cartItemParticipant?.participantId || 0),
            eventId: cartItem.eventId,
            courseId: cartItem.courseId,
            note: "",
            registrationThreshold: 0,
          }))
        )
        .flat();
      setCartItemsEnrolment(convertedItems);
      return;
    }

    setCartItemsEnrolment([]);
  };

  const populateCartCurrency = () => {
    if (
      !!shoppingCart?.shoppingCart?.items?.length &&
      (shoppingCart?.shoppingCart?.items?.length || 0) > 0
    ) {
      const currency =
        shoppingCart.shoppingCart.items.find((x) => !!x.currency)?.currency ||
        "";
      setCartCurrency(currency);
    }
  };

  const isFreeOfCharge = () => {
    if (displayPriceType === "credit") {
      return (shoppingCart?.shoppingCart?.totalCreditPrice || 0) === 0;
    }

    return (shoppingCart?.shoppingCart?.total || 0) === 0;
  };

  useEffect(() => {
    if (!!shoppingCart?.shoppingCart?.items) {
      const count =
        shoppingCart?.shoppingCart?.items?.reduce(
          (sum, current) => sum + (current.cartItemParticipants?.length || 0),
          0
        ) || 0;
      setItemCount(count);
      setCartItems(shoppingCart.shoppingCart.items);
      populateCartCurrency();
      convertCartItems();
      return;
    }

    setItemCount(0);
    setCartItems([]);
    setCartItemsEnrolment([]);
  }, [shoppingCart?.shoppingCart?.items]);

  return {
    itemCount,
    cartItems,
    cartItemsEnrolment,
    cartCurrency,
    isFreeOfCharge,
    removeCartItems,
    getVenueFeeForCartItemWithCurrency,
    getCartItemPriceWithCurrency,
    getCartItemPriceWithCurrencyAfterVoucher,
    getCartItemTotalPriceWithCurrency,
    getCartItemTotalPriceWithCurrencyAfterVoucher,
    getCartSubTotalPriceWithCurrency,
    getCartSubTotalPriceWithCurrencyAfterVoucher,
    getCartTotalTaxesWithCurrency,
    getCartTotalTaxesWithCurrencyAfterVoucher,
    getCartTotal,
    getCartTotalAfterVoucher,
    getCartTotalWithCurrency,
    getCartTotalWithCurrencyAfterVoucher,
    getCartTotalVenueFeeWithCurrency,
    getCurrentAvailableCredits,
    getDifferenceCredits,
    getSubTotalExTax,
    isCreditAccountDisabled,
    getSubTotalIncTax,
    getTaxBySubTotal,
    getCartTotalForSecondPayment,
    getCurrentCreditsNumber,
    getAdditionalPaymentCurrency,
    isAppliedVoucher,
    isDiscountedSubtotal,
    voucherIsApplied, isAnyItemCartAppliedVoucher
  };
};
