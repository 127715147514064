import { SideNavItem, SideNavItemKey } from "../types/sideNavigation.type";
import {
  CHECK_OUT_SHARED_ROUTE,
  CONTACT_US_SHARED_ROUTE,
  COURSE_DETAIL_SHARED_ROUTE,
  DASHBOARD_CURRICULA_SHARED_ROUTE,
  DASHBOARD_EXTERNAL_SHARED_ROUTE,
  DASHBOARD_SHARED_ROUTE,
  DASHBOARD_TEAM_CREATE_SHARED_ROUTE,
  DASHBOARD_TEAM_EDIT_SHARED_ROUTE,
  DASHBOARD_TEAM_SHARED_ROUTE,
  DASHBOARD_WALLET_SHARED_ROUTE,
  DASHBOARD_WORKSHOP_SHARED_ROUTE,
  DOWNLOAD_AREA_SHARED_ROUTE,
  EVENT_CALENDAR_SHARED_ROUTE,
  FAQ_SHARED_ROUTE,
  HOME_SHARED_ROUTE,
  MY_PROFILE_SHARED_ROUTE,
  MY_SHOPPING_CART_SHARED_ROUTE,
  MY_TRAINING_COURSE_DETAIL_SHARED_ROUTE,
  MY_TRAINING_PROGRAM_DETAIL_SHARED_ROUTE,
  RECOMMEND_HOTELS_SHARED_ROUTE,
  SUPPORT_SHARED_ROUTE,
  TRAINING_CENTERS_SHARED_ROUTE,
  TRAINING_COURSE_CATALOGUE_SHARED_ROUTE,
  TRAINING_PROGRAM_DETAIL_SHARED_ROUTE,
  TRAINING_PROGRAM_SHARED_ROUTE,
  TRAINING_SERVICES_SHARED_ROUTE,
} from "./routes/sharedRoutes.constants";
import { MenuItemConstants } from "./menuItems/MenuItemConstants";
import { Constants } from "../helpers/Constants";

export const SIDE_NAV_HOME_ITEM: SideNavItem = {
  key: SideNavItemKey.HOME,
  icon: "grid-view",
  path: HOME_SHARED_ROUTE.path,
  label: "Breadcrumb_Home",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_TRAINING_SERVICES_ITEM: SideNavItem = {
  key: SideNavItemKey.TRAINING_SERVICES,
  icon: "business-training",
  path: TRAINING_SERVICES_SHARED_ROUTE.path,
  label: "MenuHeader_TrainingServices",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_TRAINING_COURSE_CATALOGUE_ITEM: SideNavItem = {
  key: SideNavItemKey.TRAINING_COURSE_CATALOGUE,
  icon: "",
  path: TRAINING_COURSE_CATALOGUE_SHARED_ROUTE.path,
  label: "MenuHeader_Catalog",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_TRAINING_COURSE_DETAIL_ITEM: SideNavItem = {
  key: SideNavItemKey.TRAINING_COURSE_DETAIL,
  icon: "",
  path: COURSE_DETAIL_SHARED_ROUTE.path,
  label: "Breadcrumb_TrainingCourseDetails",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_TRAINING_EVENT_CALENDAR_ITEM: SideNavItem = {
  key: SideNavItemKey.EVENT_CALENDAR,
  icon: "",
  path: EVENT_CALENDAR_SHARED_ROUTE.path,
  label: "Breadcrumb_EventCalendar",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_TRAINING_PROGRAM_ITEM: SideNavItem = {
  key: SideNavItemKey.TRAINING_PROGRAM,
  icon: "cubes",
  path: TRAINING_PROGRAM_SHARED_ROUTE.path,
  label: "Breadcrumb_TrainingProgram",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_TRAINING_PROGRAM_DETAIL_ITEM: SideNavItem = {
  key: SideNavItemKey.TRAINING_PROGRAM_DETAIL,
  icon: "",
  path: TRAINING_PROGRAM_DETAIL_SHARED_ROUTE.path,
  label: "Breadcrumb_TrainingProgramDetail",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_TRAINING_CENTERS_ITEM: SideNavItem = {
  key: SideNavItemKey.TRAINING_CENTERS,
  icon: "map-city",
  path: TRAINING_CENTERS_SHARED_ROUTE.path,
  label: "MenuHeader_OurLocations",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_RECOMMEND_HOTELS_ITEM: SideNavItem = {
  key: SideNavItemKey.RECOMMEND_HOTELS,
  icon: "",
  path: RECOMMEND_HOTELS_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
  isHideOnSideNavigation: true,
};

// DASH BOARD

export const SIDE_NAV_MY_TRAINING_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_TRAINING,
  icon: "desktop-dashboard",
  path: DASHBOARD_SHARED_ROUTE.path,
  label: "Breadcrumb_MyTraining",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: MenuItemConstants.FullRolesSupported,
  isAccountMappedRequired: false,
};

export const SIDE_NAV_MY_TRAINING_EVENTS_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_TRAINING_EVENTS,
  icon: "desktop-apps",
  path: DASHBOARD_SHARED_ROUTE.path,
  label: "Dashboard_Headline_YourOverview",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_MY_TRAINING_COURSE_DETAIL_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_TRAINING_COURSE_DETAIL,
  icon: "",
  path: MY_TRAINING_COURSE_DETAIL_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: MenuItemConstants.FullRolesSupported,
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_CURRICULA_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_CURRICULA,
  icon: "vci",
  path: DASHBOARD_CURRICULA_SHARED_ROUTE.path,
  label: "Breadcrumb_MyTraining_Curricula",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_MY_TRAINING_PROGRAM_DETAIL_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_TRAINING_PROGRAM_DETAIL,
  icon: "",
  path: MY_TRAINING_PROGRAM_DETAIL_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: MenuItemConstants.FullRolesSupported,
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_TEAM_ITEM: SideNavItem = {
  key: SideNavItemKey.TEAM,
  icon: "team-5",
  path: DASHBOARD_TEAM_SHARED_ROUTE.path,
  label: "Breadcrumb_MyTraining_Team",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
};

export const SIDE_NAV_TEAM_CREATE_ITEM: SideNavItem = {
  key: SideNavItemKey.TEAM_CREATE,
  icon: "",
  path: DASHBOARD_TEAM_CREATE_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_TEAM_EDIT_ITEM: SideNavItem = {
  key: SideNavItemKey.TEAM_EDIT,
  icon: "",
  path: DASHBOARD_TEAM_EDIT_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [
    Constants.USER_ROLE_MANAGER_ID,
    Constants.USER_ROLE_WHOLESALER_ID,
  ],
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_WORKSHOP_ITEM: SideNavItem = {
  key: SideNavItemKey.WORKSHOP,
  icon: "coins",
  path: DASHBOARD_WORKSHOP_SHARED_ROUTE.path,
  label: "Breadcrumb_MyTraining_Workshop",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_WHOLESALER_ID],
};

export const SIDE_NAV_EXTERNAL_ITEM: SideNavItem = {
  key: SideNavItemKey.EXTERNAL,
  icon: "coins",
  path: DASHBOARD_EXTERNAL_SHARED_ROUTE.path,
  label: "Breadcrumb_MyTraining_External",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
};

export const SIDE_NAV_WALLET_ITEM: SideNavItem = {
  key: SideNavItemKey.WALLET,
  icon: "coins",
  path: DASHBOARD_WALLET_SHARED_ROUTE.path,
  label: "Breadcrumb_MyTraining_Wallet",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [
    Constants.USER_ROLE_MANAGER_ID,
    Constants.USER_ROLE_WHOLESALER_ID,
  ],
};

export const SIDE_NAV_MY_BOSCH_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_BOSCH,
  icon: "my-brand-frame",
  path: MY_PROFILE_SHARED_ROUTE.path,
  label: "MyProfile_Profile",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_SUPPORT_ITEM: SideNavItem = {
  key: SideNavItemKey.SUPPORT,
  icon: "customerservice",
  path: SUPPORT_SHARED_ROUTE.path,
  label: "MenuHeader_Support",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_SUPPORT_FAQ_ITEM: SideNavItem = {
  key: SideNavItemKey.FAQ,
  icon: "customerservice",
  path: FAQ_SHARED_ROUTE.path,
  label: "Breadcrumb_FAQs",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_SUPPORT_DOWNLOAD_AREA_ITEM: SideNavItem = {
  key: SideNavItemKey.DOWNLOAD_AREA,
  icon: "customerservice",
  path: DOWNLOAD_AREA_SHARED_ROUTE.path,
  label: "Breadcrumb_Download_Page",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_SHOPPING_CART_ITEM: SideNavItem = {
  key: SideNavItemKey.SHOPPING_CART,
  icon: "shoppingcart",
  path: MY_SHOPPING_CART_SHARED_ROUTE.path,
  label: "Breadcrumb_ShoppingCart",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const SIDE_NAV_CHECKOUT_ITEM: SideNavItem = {
  key: SideNavItemKey.CHECKOUT,
  icon: "",
  path: CHECK_OUT_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_CONTACT_US_ITEM: SideNavItem = {
  key: SideNavItemKey.CONTACT_US,
  icon: "customerservice",
  path: CONTACT_US_SHARED_ROUTE.path,
  label: "Breadcrumb_Contact",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: MenuItemConstants.FullRolesSupported,
};

export const STATIC_SIDE_NAV_ITEM = "static_side_nav_item";
