import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { CartEventItemAdded, ShoppingCart } from "../models/CartItem.Model";
import ResponseResultBase from "../models/ResponseResultBase";
import {
  DeleteCartItemRequestModel,
  DeleteVoucherRequest,
  GetCartDetailRequest,
  GetPaymentMethodsRequest,
  GetPaymentMethodsResponse,
  ValidateVoucherRequest,
} from "../models/ShoppingCartUser.Model";
import { http } from "../services/HttpService";
import { AppDispatch, RootState } from "./store";

const baseUrl = `/api/v1/shoppingCart`;

export const getDisplayCartAsync = createAsyncThunk<
  ResponseResultBase<ShoppingCart> | null,
  GetCartDetailRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("shoppingCart/GetCartDetail", async (req: GetCartDetailRequest, thunkApi) => {
  try {
    let response = await http.post<ResponseResultBase<ShoppingCart>>(
      `${baseUrl}/GetCartDetail`,
      req
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const addToCartAsync = createAsyncThunk(
  "shoppingCart/addToCart",
  async (req: CartEventItemAdded, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `${baseUrl}/addToCart`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const doEnrollCartAsync = createAsyncThunk<
  ResponseResultBase<number> | null,
  CartEventItemAdded,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("shoppingCart/addToCart", async (req: CartEventItemAdded, thunkApi) => {
  try {
    let response = await http.post<ResponseResultBase<number>>(
      `${baseUrl}/doEnroll`,
      req
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const deleteFromCartAsync = createAsyncThunk(
  `${baseUrl}/DeleteFromCart`,
  async (req: DeleteCartItemRequestModel, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `${baseUrl}/deleteFromCart`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getPaymentMethodsAsync = createAsyncThunk(
  "shoppingCart/GetPaymentMethods",
  async (req: GetPaymentMethodsRequest, thunkApi) => {
    try {
      let response = await http.post<
        ResponseResultBase<GetPaymentMethodsResponse[]>
      >(`${baseUrl}/GetPaymentMethods`, req);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const addToCartWithVoucherCodeApplyAsync = createAsyncThunk(
  "shoppingCart/addToCart",
  async (req: CartEventItemAdded, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `${baseUrl}/addToCart`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const validateVoucherAsync = createAsyncThunk(
  "Voucher/ValidateVoucher",
  async (req: ValidateVoucherRequest, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<number>>(
        `/api/v1/Voucher/ValidateVoucher`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const deleteVoucherAsync = createAsyncThunk(
  "Voucher/DeleteVoucherInCart",
  async (req: DeleteVoucherRequest, thunkApi) => {
    try {
      let response = await http.put<ResponseResultBase<number>>(
        `/api/v1/Voucher/DeleteVoucherInCart`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);
