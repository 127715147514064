import Loadable from "react-loadable";
import GlobalLoader from "./Loading/GlobalLoader";

function Loading() {
  return <GlobalLoader showLoadingDirect />;
}

const LoadableComponent = (component: any) =>
  Loadable({
    loader: component,
    loading: Loading,
  });

export default LoadableComponent;
