import { useSelector } from "react-redux";
import {
  selectBatsVersion,
  selectIsSignIn,
} from "../../redux-toolkit/authReducer";
import { selectAccount } from "../../redux-toolkit/accountReducer";
import { useEffect, useState } from "react";
import {
  MinimalSideNavItem,
  SideNavItem,
} from "../../types/sideNavigation.type";
import { BATSVersion } from "../../models/pageConfiguration/BATSVersion";
import { SIDE_NAV_CONFIG } from "../../components/views/Configuration/SideNavigationConfig/SideNavConfig";
import { cleanSystemRoutePath } from "../../utils/routing.util";

export const useSideNavConfig = () => {
  const batsVersion = useSelector(selectBatsVersion);
  const isLoggedIn = useSelector(selectIsSignIn);
  const account = useSelector(selectAccount);
  const [sideNavItems, setSideNavItems] = useState<MinimalSideNavItem[]>([]);

  useEffect(() => {
    const menuHeaderConfig = SIDE_NAV_CONFIG[batsVersion as BATSVersion];

    setSideNavItems(
      minifySideNavItemList(
        menuHeaderConfig
          ? menuHeaderConfig
          : SIDE_NAV_CONFIG[BATSVersion.BATSv4]
      )
    );
  }, [batsVersion, isLoggedIn, account?.cdbId, account?.userRole]);

  const minifySideNavItemList = (
    items: SideNavItem[]
  ): MinimalSideNavItem[] => {
    if (!items || items.length === 0) return [];

    const result: MinimalSideNavItem[] = [];

    for (const currentItem of items) {
      let minifiedItem = minifySideNavItem(currentItem) as MinimalSideNavItem;

      if (!!minifiedItem.key) {
        if (currentItem.childItems && currentItem.childItems.length > 0) {
          const tempChildren = minifySideNavItemList(currentItem.childItems);

          minifiedItem = {
            ...minifiedItem,
            childItems: tempChildren as SideNavItem[],
          };
        }

        result.push(minifiedItem);
      }
    }

    return result;
  };

  const minifySideNavItem = (item: SideNavItem): MinimalSideNavItem | {} => {
    if (!validateSideNavItem(item)) return {};

    const {
      isLoginRequired,
      supportedRoles,
      isActive,
      isAccountMappedRequired,
      ...rest
    } = item;

    const restWithFormattedPath: MinimalSideNavItem = {
      ...rest,
      path: cleanSystemRoutePath(item.path),
    };

    if (!item.childItems || item.childItems.length === 0)
      return restWithFormattedPath as MinimalSideNavItem;

    const itemChildren = item.childItems.map((childItem) =>
      minifySideNavItem(childItem)
    );

    return {
      ...restWithFormattedPath,
      childItems: itemChildren,
    };
  };

  const validateSideNavItem = (item: SideNavItem): boolean => {
    const {
      isLoginRequired,
      isActive,
      supportedRoles,
      isAccountMappedRequired,
    } = item;

    if (!isActive) return false;

    if (isLoginRequired && !isLoggedIn) return false;

    if (isAccountMappedRequired && !(account && account.cdbId > 0))
      return false;

    const currentUserRoleId = account?.userRole?.id;

    if (isLoginRequired && !currentUserRoleId) return false;

    if (isLoggedIn && !supportedRoles.includes(currentUserRoleId as number))
      return false;

    return true;
  };

  return {
    sideNavItems,
  };
};
