export const TRANSLATIONS_LT = {
	LandingPage_Title: "Sveiki, čia „Bosch Automotive Training Solutions“",
	LandingPage_SubTitle_ChooseCountry: "Pasirinkite savo šalį / regioną",
	Header_ContactUs: "Susisiekite su mumis",
	Header_Search: "Paieška",
	Header_Menu: "Meniu",
	Header_LogInOrCreateYourAccount: "Prisijunkite arba susikurkite paskyrą",
	Header_LogIn: "Prisijungti",
	Header_Cart: "Krepšelis",
	Header_ShoppingCart: "Pirkinių krepšelis",
	Header_LogOut: "Atsijungti",
	Header_ChangeDetails: "Keisti informaciją",
	Footer_ExploreOurTraining: "Peržiūrėkite mūsų mokymus",
	Footer_TrainingAtYourFingertips: "Patogiai pasiekiami mokymai",
	Footer_DontMissAnyStory: "Nepraleiskite nė vienos istorijos",
	Footer_ExploreOurStories: "Peržiūrėkite mūsų istorijas",
	Footer_GetInTouchWithUs: "Susisiekite su mumis",
	Footer_SendUsAMessage: "Siųskite mums pranešimą",
	Footer_ToContactForm: "Norėdami susisiekti su forma",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "Bendra kontaktinė informacija",
	Footer_ProductSecurity_Psirt: "Produkto sauga (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Inovacijų patentai ir licencijos",
	Footer_PurchasingLogistics: "Pirkimai ir logistika",
	Footer_CorporateInformation: "Informacija apie įmones",
	Footer_LegalNotice: "Teisinis pranešimas",
	Footer_DataProtectionNotice: "Duomenų apsaugos pranešimas",
	Footer_PrivacySettings: "Privatumo nustatymai",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch UAB, visos teisės saugomos",
	Footer_StillLookingForSomething: "Vis dar ieškote informacijos",
	Footer_TrainingServices: "Service Training",
	Footer_Courses: "Kursai",
	Footer_Programs: "Programos",
	Footer_EventsCalendar: "Suplanuoti įvykiai",
	Footer_TrainingCenters: "Mokymo centrai",
	Footer_Dashboard: "Ataskaitų sritis",
	Footer_MyDashboard: "Mano ataskaitų sritis",
	Footer_ShoppingCart: "Pirkinių krepšelis",
	Footer_YourShoppingCart: "Jūsų pirkinių krepšelis",
	Footer_Administration: "Administravimas",
	Footer_Employees: "Darbuotojai",
	Footer_Wholesalers: "Didmenininkai",
	Footer_Transactions: "Operacijos",
	Footer_Workshops: "Dirbtuvės",
	Footer_WorkLikeABoschApplyNow: "Darbas  – kreipkitės dabar.",
	Footer_BoschGlobalCareers: "„Bosch“ pasaulinė karjera",
	Footer_PurchasingAndLogistics: "Pirkimas ir logistika",
	Footer_BecomeABusinessPartner: "Tapkite verslo partneriu.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Kaip mūsų mokymai vystosi, kad padėtų jūsų verslui",
	Footer_Language: "Šalies pasirinkimas",
	MenuPopup_MyCart: "Mano krepšelis",
	Stories_OurTrainingStories: "Mūsų mokymų istorijos",
	Stories_ConnectingExperts: "Skatiname bendrauti ekspertus, entuziastus, svajotojus, kūrėjus, novatorius ir nuomonės formuotojus – tyrinėkite ir patirkite",
	Stories_TopStories: "Populiariausios istorijos",
	Stories_AtHome: "Namie",
	Stories_Careers: "Karjera",
	Stories_History: "Istorija",
	Stories_IndustryAndTrades: "Pramonė ir prekyba",
	Stories_Mobility: "Mobilumas",
	Stories_Research: "Tyrimai",
	Stories_Sustainability: "Tvarumas",
	Stories_AllStories: "Visos istorijos",
	Stories_BlogATrainersDay: "INTERNETINIS DIENORAŠTIS | Instruktoriaus diena",
	Stories_ADayinLifeOfATrainer: "Diena iš instruktoriaus gyvenimo",
	Stories_BoschServiceTraining: "„Bosch“ techninės priežiūros mokymas",
	Stories_StoryWhatDrivesYouDrivesUs: "ISTORIJA | Tai, kas skatina jus, veda pirmyn ir mus",
	Stories_StoryHowCanWeBenefitYourBusiness: "ISTORIJA | Kuo galime būti naudingi jūsų verslui",
	Stories_HowOurTrainingEvolves: "Kaip vystosi mūsų mokymai",
	Stories_AModernTrainingApproach: "Internetinių mokymų pasaulis",
	Stories_StoryAVirtualOnlineTrainingWorld: "ISTORIJA | Jūsų internetinių mokymų galimybės",
	Stories_SubscribeToRSSFeed: "Prenumeruokite RSS kanalą",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Apie mus",
	Homepage_AutomotiveAftermarket: "Automobilių antrinė rinka",
	Homepage_GetInTouch: "Susisiekite",
	Homepage_LatestNews: "Sužinokite, kodėl mus skatina žinių troškimas",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Mūsų atviros pozicijos",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Kas įkvepia jus, tas įkvepia ir mus",
	BoschServiceTraining_BoschServiceTraining: "„Bosch“ techninės priežiūros mokymai",
	BoschServiceTraining_ShareThisOn: "Bendrinti",
	BoschServiceTraining_TheChallenge: "Iššūkis: Naujų technologijų įsisavinimas",
	BoschServiceTraining_WorldwidePresence: "Dalyvavimas visame pasaulyje",
	BoschServiceTraining_TechnicalTraining: "„Bosch“ techninių mokymų kursai",
	BoschServiceTraining_TheOffersAndOpportunities: "„Bosch“ teikiami pasiūlymai ir galimybės",
	BoschServiceTraining_TechnicalCoursesDesc: "Tinkami mokymo kursai teikiami kiekvienai tikslinei grupei, tiek pradedantiesiems, tiek profesionalams. Pasiūlymą sudaro vienos dienos mokymo kursai ir kelių dienų mokymo kursai. Mokymo kursai siūlomi įvairiose techninėse srityse ir mokymų serijose (pvz., benzino įpurškimo, dyzelino įpurškimo, elektros sistemų). Techninių mokymų kursuose „Bosch“ suteikia daug žinių apie visas standartines transporto priemonių sistemas, kursuose aptariamos Bosch“ ir kitų gamintojų sistemos. Turinys specialiai priderintas prie dirbtuvių darbuotojų reikalavimų.",
	BoschServiceTraining_TrainedEmployeesDesc: "Išmokyti darbuotojai teikia automobilių dirbtuvėms akivaizdžią naudą. Šiuolaikinės transporto priemonės tampa vis sudėtingesnės, todėl naujos technologijos nuolat kelia naujų iššūkių. Tik reikiamos žinios leidžia dirbtuvių darbuotojams efektyviai ir ekonomiškai atlikti esamų transporto priemonių modelių diagnostikos priežiūros ir remonto darbus.",
	BoschServiceTraining_RegardlessOfHow: "Kad ir kokie geri ir patyrę būtų automobilių specialistai – visada pasitaiko situacijų, kai jie susiduria su naujomis transporto priemonių technologijų sistemomis ar nežinomais gedimais. Šiuo atžvilgiu trikčių šalinimas ir problemų sprendimas gali užimti ypač daug laiko arba neduoti sėkmingų rezultatų. Blogiausiu atveju klientai turi apsieiti be savo transporto priemonės žymiai ilgiau, nei tikėtasi.",
	BoschServiceTraining_ItIsPrecisely: "Kaip tik šiuo metu „Bosch“ įgyvendina savo paslaugų mokymus: gerina paslaugų kokybę ir spartina procesus. Dabartiniai „Bosch“ mokymai padeda greičiau lokalizuoti problemas, tuo pačiu metu suremontuojant daugiau transporto priemonių ir sutrumpinant klientų laukimo laiką. Taigi sistemingos procedūros, kurių mokomasi Bosch, užtikrina greitesnį ir produktyvesnį darbą.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Daugiau nei 125 metus „Bosch“ buvo prioritetinė daugelio pirmaujančių automobilių gamintojų originalių dalių komponentų ir sistemų tiekėja visame pasaulyje. Ši patirtis suteikia unikalių žinių apie naujausias technologijas, kurios yra „Bosch“ aptarnavimo pagrindas.",
	BoschServiceTraining_WorldwidePresenceDesc2: "„Bosch“ techninės priežiūros mokymo centrų galima rasti visame pasaulyje: nuo Ispanijos iki Turkijos, Pietų Afrikos Respublikos, Australijos ir Brazilijos – „Bosch“ siūlo savo darbuotojams į praktiką orientuotus mokymus, kurie visada susiję su naujausiomis technologijomis.",
	BoschServiceTraining_AgileCompany: "Efektyvi bendrovė",
	BoschServiceTraining_Collaboration: "Bendradarbiavimas",
	BoschServiceTraining_ConnectedMobility: "Jungtinis mobilumas",
	BoschServiceTraining_ArtificialIntelligence: "Dirbtinis intelektas",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Bendraujantys lyderiai",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostinės įrangos naudojimas",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Automobilių technologjų pagrindai",
	BoschServiceTraining_Icon_Gasoline: "Benzinas",
	BoschServiceTraining_Icon_Diesel: "Dyzelinas",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternatyvios pavaros",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektra ir elektronika",
	BoschServiceTraining_Icon_BodyAndComfort: "Kėbulas ir komfortaas",
	BoschServiceTraining_Icon_ChassisAndBraking: "Pakaba ir stabdžiai",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmisija",
	BoschServiceTraining_Icon_CommercialVehicles: "Komerciniai automobiliai",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Nuotoliniai mokymai",
	BoschServiceTraining_Icon_BusinessSkills: "Verslo įgūdžiai",
	BoschServiceTraining_Icon_Accreditations: "Akreditacija",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Mokymų programos",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "Mokytojo VardasPavardė",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Diena trenerio gyvenime",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Kasdien yra nuotykis",
	ADayInTheLifeOfATrainer_Summary: "Apibendrinimas",
	ADayInTheLifeOfATrainer_TheDayJourney: "Dienos kelionė",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Kiekviena diena yra įtempta, bet visada naudinga. Malonumas mokyti studentus žinant, kad jie turi raktą į mūsų ateitį. Matydami kaip mokiniai auga su kiekviena pamoka kasdien ir kas savaitę, mes galime būti tikri, kad siekiame bendro tikslo tobulinti jų žinias, kartu įtraukdami modernias technologijas, kurios lemia ateitį!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Kiekvienos dienos pabaigoje susėdame ir apmąstome dienos darbus. Dienos bėga labai greitai, bet tai yra įrodymas, kad tai, ką mes pasiekiame, yra naudinga ne tik studentams, bet ir mums, kaip treneriams.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Dienos pabaigoje galime grįžti namo patenkinti, kad atlikome puikų darbą!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Pradžia",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Mokomosios medžiagos paruošimas ir baigiamasis patikrinimas. Patikrinkite svarbius laiškus",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Mokymai prasideda",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Dalyvis laukiamas. Dienos tvarkaraščio pristatymas. Pažinkite grupę suprasdami kiekvieno individo dinamiką.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teorija",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Tam, kad mokymai būtų kuo įdomesni, reikia tam tikro bendravimo su grupe. Įsitikinkite, kad yra užduočių, susietų su teorija, kad toliau įtrauktumėte grupę",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Pietų pertrauka",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktika",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Mokymai tęsiami dirbtuvėse su keletu praktinių užduočių. Kaip treneris visada privalo būti šalia ir kaip visada pasiekiamas bet kokiai situacijai ir patarti",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Santrauka",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Grįžkite į klasę trumpam aptarti dienos mokymus. Atsakykite į visus klausimus, kurie gali kilti. Pradėkite ruoštis kitiems mokymams.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Paskutinė diena",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Dalyviams įteikiami dalyvavimo pažymėjimai.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Namai,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Kaip galime padėti jūsų verslui",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Kaip vystosi mūsų mokymai",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Mes nuolat tobulėjame, kad galėtume teikti kokybiškus ir naujausius mokymus",
	BoschTrainingBenefits_TheBoschServiceTraining: "„Bosch“ mokymo centras norėtų padėti jums plėtoti jūsų verslą, naudojant platų bei aktualų techninių ir verslo mokymo kursų, turinčių sertifikuotą kvalifikaciją, portfelį. Tolesniuose puslapiuose pateikiama mūsų mokymo programos apžvalga ir dabartinės tolesnio mokymosi galimybės. Laukiame Jūsų ir Jūsų darbuotojų mūsų mokymo kursuose ir seminaruose.",
	BoschTrainingBenefits_LongStandingExperience: "Ilgametė patirtis dirbant su visomis sistemomis automobilių technologijų srityje.",
	BoschTrainingBenefits_QuickAndEfficient: "Greiti ir efektyvūs daugelio problemų sprendimai.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Privalumai",
	BoschTrainingBenefits_LMS: "Mokymosi valdymo sistema - Bosch Training solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "„Bosch Training Solutions“ apima visas automobilių pramonės sritis. Specializuokite tam tikrose srityse arba praplėskite savo kompetenciją naudodamiesi Bosch mokymo pasiūlymais.",
	BoschTrainingBenefits_AcquiredKnowledge: "Įgytos žinios leidžia atlikti remonto, priežiūros ir aptarnavimo darbus.",
	BoschTrainingBenefits_TheHighDegree: "Aukštas praktinio turinio lygis ir mažos grupės garantuoja kryptingą mokymąsi.",
	BoschTrainingBenefits_ParticipationInTraining: "Dalyvavimas mokymuose didina darbuotojų motyvaciją ir pasitenkinimą, o papildomos kvalifikacijos įtikina klientus",
	BoschTrainingBenefits_IncreasedSales: "Padidėjęs pardavimas dėl sisteminių žinių.",
	BoschTrainingBenefits_MethodicalProcedures: "Metodinės procedūros sumažina klientų laukimo laiką",
	BoschTrainingBenefits_HighlyQualified: "Aukštos kvalifikacijos instruktoriai perteikia sistemingas procedūras",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Jums ar jūsų verslui tinkamo mokymo sprendimo identifikavimas",
	BoschTrainingBenefits_DirectOnlineBooking: "Tiesioginis internetinis kursų užsakymas ir valdymas jums arba jūsų darbuotojams",
	BoschTrainingBenefits_OnlineAccess: "Internetinė prieiga prie mokymosi dokumentų",
	BoschTrainingBenefits_AccessToYourOwn: "Prieiga prie savo asmeninės mokymų istorijos ir sertifikatų",
	BoschTrainingBenefits_ToolsForManagement: "Karjeros plėtros valdymo įrankiai",
	BoschTrainingBenefits_ManagerHasFull: "Vadovas turi prieigą prie visos savo darbuotojų kursų apžvalgos sistemos viduje",
	BoschTrainingBenefits_Access24hours: "Prieiga 24 valandas per parą 7 dienas per savaitę",
	ModernTrainingApproach_KnowledgeIsKey: "Žinios yra pagrindinis visų dirbtuvių sėkmės veiksnys. Štai kodėl „Bosch“ siūlo išsamią profesinio mokymo programą. Tai leidžia besimokantiems įgyti reikiamų žinių tinkamu laiku ir tinkamoje vietoje,kurios būtinos siekiant profesionaliai prižiūrėti ir remontuoti klientų transporto priemones. „Bosch“ į jau siūlomą el. mokymosi paketą kaip naują modulio dalį įtraukė transliacijas internetu. Jas sudaro techninės priežiūros mokymo seansai, specialiai sukurtos srautiniam perdavimui.",
	ModernTrainingApproach_ANewLearning: "Nauja mokymosi galimybė",
	ModernTrainingApproach_YourOnlineTraining: "Jūsų internetinių mokymų galimybės",
	ModernTrainingApproach_RecentlyTheWorld: "Pastaruoju metu pasaulis pasikeitė, pažiūrėkite, ką mes darome siekdami padėti",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Internetinių mokymų pasaulis",
	ModernTrainingApproach_NewTeachingMethod: "Naujas mokymo metodas",
	ModernTrainingApproach_InAdditionTo: "Be jau vykstančių tiesioginių mokymų, į mokymo programą įtraukėme tiesioginių transliacijų internetu mokymus. Mūsų instruktorius dalyvauja pateikdamas daugybę teorinių žinių arba praktinių pavyzdžių virtualioje klasėje. Kaip įprasta, gausite daug informacijos apie transporto priemonių sistemas ir atitinkamus „Bosch“ gaminius. Be abejo, turite galimybę užduoti klausimus. Į šiuos klausimus bus atsakyta tiesiogiai tiesioginėje internetinėje transliacijoje. Tiesioginės transliacijos internetu trunka nuo 60 iki 120 minučių. Būtinas stabilus interneto ryšys. Ieškokite tinkamos internetinės transliacijos tiesiogiai mūsų mokymų katalogo paieškos laukelyje įvesdami \Webcast\.",
	ModernTrainingApproach_BoschWebcastingHeader: "„Bosch“ transliacijos internetu-pažangu ir praktiška",
	ModernTrainingApproach_BoschWebcastingText: "Norėdamas dalyvauti „Bosch“ internetinėje transliacijoje dalyvis turi prisijungti. Prieš seansą išsiunčiamas individualus prieigos kodas, kad būtų galima prisijungti. Vienu metu gali dalyvauti iki 200 dalyvių iš bet kokio vietų skaičiaus. Mokymus veda instruktorius, o klausimus galima pateikti bet kuriuo metu per tiesioginio pokalbio funkciją.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Skaitmeninis mokymo klasėje papaildymas",
	ModernTrainingApproach_TheDigitalSupplementText: "Transliacijos internetu ir mokymas klasėje puikiai papildo vienas kitą. Abiem atvejais mokymą veda kvalifikuotas instruktorius. Interneto transliacijos tinka temoms, kurias galima dėstyti skaitmeniniu būdu ir nereikia dirbti su transporto priemone. Pavyzdžiui, susipažinti su tam tikromis naujos pagalbos vairuotojui sistemos ESI[tronic] funkcijomis. Kalbant apie efektyvų komandinį darbą dirbtuvėse ar praktinį darbą su transporto priemonės technologijomis, tiesioginis mokymas vis dar yra pagrindinis pasirinkimas.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Mokymai internetu gali būti pigesni",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Vieno dalyvio išlaidos paprastai yra mažesnės naudojant internetinę transliaciją nei panašaus kurso klasėje. Priežastis ta, kad daugiau dalyvių gali gauti naudos, o su mokymu susijusios pastangos yra žymiai mažesnės (nereikia keliauti į vietą ir iš jos, skiriama mažiau laiko).",
	ModernTrainingApproach_BookAndProfitHeading: "Registravimas ir nauda",
	ModernTrainingApproach_BookAndProfitText: "Interneto transliacijas galima užsisakyti mūsų svetainėje, kaip ir kitus mokymo kursus ir mokymosi pasiūlymus. Galite pasirinkti mokėti individualų mokestį už kiekvieną internetinę transliaciją arba prenumeruoti tam tikrą internetinių transliacijų skaičių.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "„Bosch Webcasting“ ir tiesioginių mokymų klasėje palyginimas",
	ModernTrainingApproach_Webcasting: "Transliacijos internetu",
	ModernTrainingApproach_Classroom: "Klasė",
	ModernTrainingApproach_WebcastingP1: "Vienu metu gali dalyvauti iki 200 dalyvių iš bet kurios pasaulio vietos",
	ModernTrainingApproach_WebcastingP2: "Mažiau sudėtingų dalykų ir palyginti mažos išlaidos, nereikia keliauti",
	ModernTrainingApproach_WebcastingP3: "Idealiai tinka konkrečioms ir glaustoms atskiroms temoms",
	ModernTrainingApproach_WebcastingP4: "Dirbtuvės gali vykdyti internetinę transliaciją savo darbuotojams bet kur ir bet kada",
	ModernTrainingApproach_WebcastingP5: "Reikia 50–70 % mažiau laiko nei mokymams klasėje",
	ModernTrainingApproach_ClassroomTrainingP1: "Vienu metu susirenka iki 16 dalyvių",
	ModernTrainingApproach_ClassroomTrainingP2: "Kelionės išlaidos ir apskritai didesnės išlaidos vienam asmeniui",
	ModernTrainingApproach_ClassroomTrainingP3: "Idealiai tinka optimizuoti bendradarbiavimą ar mokymąsi tiesiogiai dirbant su transporto priemone",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktiniai pratimai ir bendravimas su instruktoriumi",
	ModernTrainingApproach_ClassroomTrainingP5: "Instruktorius gali geriau pritaikyti dėstomas žinias pagal tikruosius dalyvių poreikius",
	ModernTrainingApproach_OnlineTrainingOfferings: "Internetinių mokymų pasiūlymai (WBT / VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Šiandien savarankiškas mokymasis prie kompiuterio yra neatsiejama žinių perdavimo dalis. Laisvė pasirinkti laiką ir vietą, neribotas kartojimų skaičius, individualus mokymosi greitis ir interaktyvus mokymosi turinys daro šį metodą nepakeičiamu mokymosi įrankiu.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Kiekvienas žiniatinkliu pagrįsto mokymosi (WBT) elementas buvo kruopščiai struktūrizuotas. Šie kursai ne tik užtikrina išsamią žinių bazę, bet ir padeda efektyviai ruoštis mokymo programoms „Bosch“ mokymo centruose.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Virtualus diagnostikos modeliavimas siūlo vartotojui vaidmenų atlikimo galimybes dirbtuvėse, pvz., atliekant trikčių šalinimo procedūras, naudojant „Bosch“ diagnostikos įrenginius virtualioje dirbtuvėje.",
	ModernTrainingApproach_Advantages: "Pranašumai",
	ModernTrainingApproach_AdvantagesP1: "Greitas ir efektyvus žinių perteikimo būdas",
	ModernTrainingApproach_AdvantagesP2: "Mokymo įvairiapusiškumas",
	ModernTrainingApproach_AdvantagesP3: "Galima naudoti reikiamu dažnumu",
	ModernTrainingApproach_AdvantagesP4: "Minimalios laiko ir pinigų sąnaudos",
	ModernTrainingApproach_AdvantagesP5: "Individualus mokymosi greitis",
	ModernTrainingApproach_AdvantagesP6: "Lankstus studijų laikas",
	ModernTrainingApproach_AdvantagesP7: "Kartojamumas",
	ModernTrainingApproach_AdvantagesP8: "Nėra kelionės išlaidų",
	ModernTrainingApproach_AdvantagesP9: "Nėra pajėgumų ar planavimo konfliktų",
	Dashboard_CoursesViewAndManageYourCourses: "KURSAI | Peržiūrėkite ir tvarkykite savo kursus",
	Dashboard_MyCourses: "Mano kursai",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "MOKYMO PROGRAMA | Peržiūrėkite savo mokymo programas",
	Dashboard_MyTrainingProgram: "Mano mokymo programa",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "EL. MOKYMASIS | Peržiūrėkite ir valdykite savo el. mokymosi aplanką",
	Dashboard_MyELearning: "Mano el. mokymasis",
	Dashboard_AccountManageYourAccount: "PASKYRA | Valdykite savo paskyrą",
	Dashboard_MyAccount: "Mano paskyra",
	Dashboard_MyDashboard: "Ataskaitų sritis",
	Dashboard_ManageYourAccount: "Valdykite savo paskyrą",
	Dashboard_Workshops: "Dirbtuvės",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRAVIMAS | Tvarkykite savo dirbtuves",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRAVIMAS | Tvarkykite savo komandą",
	Dashboard_Administration: "Administravimas",
	Dashboard_ViewYourCourses: "Peržiūrėkite savo kursus",
	Dashboard_ViewYourTrainingPrograms: "Peržiūrėkite savo mokymosi programas",
	Dashboard_TrainingPrograms: "Mokymosi programos",
	Dashboard_ViewAndEditYourDetails: "Peržiūrėkite ir redaguokite savo detalią informaciją",
	Dashboard_ViewYourELearningCourses: "Peržiūrėkite savo nuotolinio mokymosi kursus",
	Dashboard_Pending: "Laukiama",
	Dashboard_RequiresAdministratorSignOff: "(būtinas administratoriaus prisijungimas)",
	Dashboard_HistoricalRecords: "Istoriniai įrašai",
	Dashboard_Registered: "Registruota",
	Dashboard_WaitingList: "Laukiančiųjų sąrašas",
	Dashboard_RecommendedForYou: "Rekomenduojama jums",
	Dashboard_AccountDetails: "Paskyros informacija",
	Dashboard_ChangeProfileImage: "keisti profilio vaizdą",
	Dashboard_Title: "Pareigos",
	Dashboard_Role: "Vaidmuo",
	Dashboard_FirstName: "Vardas",
	Dashboard_LastName: "Pavardė",
	Dashboard_Telephone: "Telefono Nr.",
	Dashboard_Mobile: "Mobiliojo tel. Nr.",
	Dashboard_Email: "El. pašto adresas",
	Dashboard_Language: "Kalba",
	Dashboard_Save: "Išsaugoti",
	Dashboard_Student: "Studentas",
	Dashboard_WorkshopTrainingAdministrator: "Įmonės mokymų administratorius",
	Dashboard_WholesalerTrainingAdministrator: "Didmeninės prekybos mokymų administratorius",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Įmonės administracija",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Tvarkykite savo dirbtuves...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Valdykite savo darbuotojus...",
	Dashboard_CompanyAdministration_Workshop: "Dirbtuvės",
	Dashboard_CompanyAdministration_Address: "Adresas",
	Dashboard_CompanyAdministration_Zip: "Pašto kodas",
	Dashboard_CompanyAdministration_City: "Miestas",
	Dashboard_CompanyAdministration_Manage: "Tvarkyti",
	Dashboard_AdministrationSection_AdministrationTitle: "Administravimas",
	Dashboard_AdministrationSection_ManageTheTeam: "Valdyti komandą",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRAVIMAS | Valdykite savo darbuotojus",
	Dashboard_AdministrationSection_Employees: "Darbuotojai",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRAVIMAS | Valdykite savo didmenininkus",
	Dashboard_AdministrationSection_Wholesalers: "Didmenininkai",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRAVIMAS | Tvarkykite savo operacijas",
	Dashboard_AdministrationSection_Transactions: "Operacijos",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Darbuotojų administravimas",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Valdykite savo darbuotojus...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registruotis",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Įrašai",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Redaguoti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Darbuotojas",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Įtraukti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Kurti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registruota",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Laukiama",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(būtinas administratoriaus prisijungimas)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Istoriniai įrašai",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Laukiančiųjų sąrašas",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Dabartinis darbuotojų sąrašas",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Darbuotojų valdymas",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Vardas",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Darbuotojo paskyra",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Peržiūrėkite ir redaguokite darbuotojo informaciją...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Nustatymai",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Leisti asmeninę paskyrą",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Darbuotojų dirbtuvių administravimo įgaliotinio atnaujinimas",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Leisti darbuotojui registruotis į mokymo renginius",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Siųsti kvietimą el. paštu",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Išjungti paskyrą",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Išsaugoti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Siųsti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Redaguoti",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Didmenininko valdymas",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Tvarkykite savo didmenininkus...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Patvirtintas didmenininkų sąrašas",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Vardas",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Šalinti",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Kurti",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Įtraukti",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Naujos darbuotojo paskyros kūrimas",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Sukurkite informaciją apie naujus darbuotojus...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Įtraukti naują patvirtintą didmenininkų administratorių",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Kurkite informaciją apie naujus didmenininkus...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Išskleidžiamajame meniu pasirinkite patvirtintą didmenininką...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Operacijos",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Peržiūrėkite ir valdykite savo operacijas...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Atgal",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Sąskaitos numeris",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Aprašymas",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Mokėjimo būdas",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Data",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Suma",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Atsisiųsti",
	TrainingCentres_TrainingCentres: "Mokymo centrai",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Mūsų mokymo centrai yra visame pasaulyje",
	TrainingCentres_CountryCategory: "Šalies kategorija",
	TrainingCentres_CityCategory: "Miesto kategorija",
	TrainingCentres_Country: "Šalis",
	TrainingCentres_City: "Miestas",
	TrainingCatalogue_TrainingCatalogue: "Mokymo kursų katalogas",
	TrainingCatalogue_LearningAtTheHighestLevel: "Mokymasis aukščiausiu lygiu",
	TrainingCatalogue_StartDate: "Pradžios data",
	TrainingCatalogue_EndDate: "Pabaigos data",
	TrainingCatalogue_TrainingName: "Mokymų pavadinimas",
	TrainingCatalogue_Language: "Kalba",
	TrainingCatalogue_Days: "Dienų skaičius",
	TrainingCatalogue_Seats: "Vietų skaičius",
	TrainingCatalogue_Price: "Kaina",
	TrainingCatalogue_Location: "Vieta",
	TrainingCatalogue_PartNumber: "Užsakymo kodas",
	TrainingCatalogue_TrainingCourse: "Mokymo kursai",
	TrainingCatalogue_Category: "Kategorija",
	TrainingCatalogue_TrainingCategory: "Mokymų kategorija",
	TrainingCatalogue_CourseName: "Kursų pavadinimas",
	TrainingCatalogue_TotalDays: "Iš viso dienų",
	TrainingCatalogue_Venue: "Vieta",
	TrainingCatalogue_Details: "Informacija",
	TrainingCatalogue_Prerequisites: "Būtinos sąlygos",
	TrainingCatalogue_CourseType: "Kurso tipas",
	TrainingCatalogue_LearningMethod: "Mokymosi metodas",
	TrainingCatalogue_AddToWaitingList: "Įtraukti į laukiančiųjų sąrašą",
	ShoppingCart_ShoppingCart: "Pirkinių krepšelis",
	ShoppingCart_ReviewAndManage: "Peržiūrėkite ir tvarkykite savo prenumeratas",
	ShoppingCart_YouHaveAddedItem: "Į krepšelį įdėjote šias prekes",
	ShoppingCart_Item: "Prekė",
	ShoppingCart_Quantity: "Kiekis",
	ShoppingCart_PricePerItem: "Prekės kaina",
	ShoppingCart_TotalPrice: "Bendra kaina",
	ShoppingCart_VatIsIncluded: "Be PVM",
	ShoppingCart_Redeem: "Jei neturite kredito, spustelėkite čia, kad jį gautumėte",
	ShoppingCart_Purchase: "Jei neturite kredito, spustelėkite čia, kad jį įsigytumėte",
	ShoppingCart_Checkout: "Atsiskaitymas",
	ShoppingCart_ContinueShopping: "Tęsti apsipirkimą",
	ShoppingCart_TotalPriceInclVat: "Bendra kaina su PVM",
	ShoppingCart_Apply: "Taikyti",
	ShoppingCart_ShoppingCartEmpty: "Jūsų pirkinių krepšelis tuščias",
	ShoppingCart_VatIncluded: "PVM",
	ShoppingCart_ManageYourShopping: "Tvarkykite savo apsipirkimą",
	ShoppingCart_Participant: "Dalyvis",
	ShoppingCart_Complete: "Baigti",
	ShoppingCart_NetPrice: "Grynoji kaina",
	ShoppingCart_Workshop: "Dirbtuvės",
	CheckOut_PreviewYourOrder: "Peržiūrėkite savo užsakymą",
	CheckOut_YourShoppingCart: "Krepšelis",
	CheckOut_Preview: "Peržiūra",
	CheckOut_Payment: "Mokėjimas",
	CheckOut_Summary: "Santrauka",
	CheckOut_Back: "Atgal",
	CheckOut_PleaseReadAndAgreeOurTerms: "Perskaitykite ir sutikite su mūsų sąlygomis",
	CheckOut_IHaveReadTheTermsAndCondition: "[Perskaičiau sąlygas ir jas suprantu bei su jomis sutinku]",
	CheckOut_YesIAgree: "Taip – sutinku su sąlygomis",
	CheckOut_Continue: "Tęsti",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Sveikiname! Gavome jūsų užsakymą",
	CheckOut_OrderConfirmation: "Kai jūsų užklausa bus sėkmingai apdorota, gausite galutinį užsakymo patvirtinimą",
	CheckOut_WeWillContactYouDirectly: "Jei kils klausimų, susijusių su jūsų užklausa, susisieksime su jumis tiesiogiai",
	CheckOut_AddToCart: "Įtraukti į krepšelį",
	CheckOut_CreditCart: "Kredito krepšelis",
	CheckOut_DirectDebit: "Tiesioginis debetas",
	CheckOut_TrainingCredits: "Mokymo kreditai",
	CheckOut_PreferredWholesaler: "Pageidaujamas didmenininkas",
	EventCalendar_EventCalendar: "Renginių kalendorius",
	EventCalendar_TrainingEventsForAllYourNeeds: "Mokymų renginiai visiems jūsų poreikiams",
	TrainingCourse_TrainingCourse: "Mokymo kursų katalogas",
	TrainingCourse_LearningAtTheHighestLevel: "Mokymasis aukščiausiu lygiu",
	TrainingProgram_Title: "Mokymų programų katalogas",
	TrainingProgram_SubTitle: "Mokymasis aukščiausiu lygiu",
	AccountDeactivationModal_Title: "Ar tikrai norite išjungti šią paskyrą?",
	AccountDeactivationModal_Line1: "Paskyra išjungiama visam laikui ir bus pašalintas visas turinys, įskaitant užklausų operacijas ir likučius, komandos narius bei profilio nustatymus.",
	AccountDeactivationModal_Line2Part1: "Jei tai atliekama per aktyvų prenumeratos laikotarpį, žr. atšaukimo strategiją mūsų",
	AccountDeactivationModal_Line2Part2: "sąlygose",
	AccountDeactivationModal_Line2Part3: "prieš pateikdami savo prašymą.",
	AccountDeactivationModal_Line3: "Ar tikrai norite pateikti užklausą išjungti šią paskyrą?",
	AccountDeactivationModal_Cancel: "Atšaukti",
	AccountDeactivationModal_Send: "Siųsti",
	CurrentUserAccountDeactivationModal_Title: "Ar tikrai norite išjungti savo paskyrą?",
	CurrentUserAccountDeactivationModal_Line1: "Jūsų paskyra išjungiama visam laikui ir bus pašalintas visas turinys, įskaitant techninės priežiūros užklausų operacijas ir likučius, komandos narius bei profilio nustatymus.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Jei tai atliekama per aktyvų prenumeratos laikotarpį, žr. atšaukimo strategiją mūsų",
	CurrentUserAccountDeactivationModal_Line3: "Ar tikrai norite pateikti užklausą išjungti savo paskyrą?",
	CurrentUserAccountDeactivationModal_Yes: "Taip",
	CurrentUserAccountDeactivationModal_No: "Ne",
	CourseWithdrawalModal_Title: "Kursų atšaukimas",
	CourseWithdrawalModal_Line1: "Ar tikrai norite pateikti atšaukimo prašymą?",
	CourseWithdrawalModal_Line2Part1: "Kursų atšaukimas yra susiję su papildomais mokesčiais",
	CourseWithdrawalModal_Line2Part2Link: "pagal mūsų",
	CourseWithdrawalModal_Line2Part3: "sąlygas.",
	CourseWithdrawalModal_Yes: "Taip, atšaukti.",
	CourseWithdrawalModal_No: "Ne, nutraukti.",
	Settings_Settings: "Nustatymai",
	Settings_ManageYourSettings: "Tvarkykite savo nustatymus",
	Settings_Profile: "Profilis",
	Settings_MyBoschGlobalIdLoginInformation: "Mano „Bosch“ visuotinio ID prisijungimo informacija",
	Settings_Edit: "Redaguoti",
	Settings_CompanyDetails: "Įmonės informacija",
	Settings_RequestAccountDeletion: "Prašyti pašalinti paskyrą",
	Settings_AccountDeletionText: "Jei pašalinsite paskyrą, visi jūsų duomenys (įskaitant adresą ir mokėjimo duomenis) bus pašalinti ir nebebus pasiekiami.",
	Settings_DeleteAccountRequestConfirmation: "Paskyros šalinimo užklausos patvirtinimas",
	Settings_DeleteAccountRequestConfirmationText: "Gavome jūsų paskyros šalinimo prašymą ir susisieksime su jumis el. paštu arba telefonu dėl galutinio patvirtinimo",
	RecommendedHotels_RecommendedHotels: "Rekomenduojami viešbučiai",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Mūsų mokymo centrai yra visame pasaulyje",
	Error_Common: "Kažkas nepavyko!",
	Error_404: "Puslapio, kuriame lankėtės, nebėra",
	Error_401: "Neturite prieigos prie šio puslapio",
	Error_500: "Serveris praneša apie klaidą",
	Dashboard_Courses_Table_Course: "Kursai",
	Dashboard_Courses_Table_Date: "Data[trukmė]",
	Dashboard_Courses_Table_Venue: "Vieta",
	Dashboard_Courses_Table_TrainingType: "Mokymų tipas",
	Dashboard_Courses_Table_Action: "Veiksmai",
	Dashboard_Courses_Table_TasksToComplete: "Jus turite baigti šias užduotis",
	Dashboard_Courses_Table_Withdraw: "Išbraukti",
	Dashboard_Courses_Table_Remove: "Pašalinti",
	Dashboard_Courses_Table_View: "Peržiūra",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Įrašų nėra",
	Dashboard_Courses_Table_CompletionDate: "Paugimo data",
	Dashboard_Courses_Table_Actions_Assignments: "Užduotys",
	Dashboard_Courses_Table_Actions_Certification: "Sertifikatai",
	Dashboard_Courses_Table_Actions_Evaluations: "Analizė",
	Dashboard_Courses_Table_Actions_Assessments: "Vertinimas",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Įtraukti į kalendorių",
	Dashboard_Courses_Table_CourseDetails_Task: "Užduotis",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Atlikimo data",
	ContactUs_Headline: "Kreipkitės į mus",
	ContactUs_HowCanWeHelp: "Kaip galime padėti?",
	ContactUs_EmailTab: "El. laiškas",
	ContactUs_PhoneTab: "Tel. Nr.",
	ContactUs_LetterTab: "Laiškas",
	ContactUs_Phone_Headline: "Skambinkite mums!",
	ContactUs_Phone_Description: "Laukiame jūsų skambučio ir mielai jums padėsime.",
	ContactUs_Phone_PhoneNumber: "Norėdami išsiųsti mums žinutę, naudokite kontaktinę formą.",
	ContactUs_Phone_ChargeNotice: "Mokesčiai skiriasi priklausomai nuo paslaugų teikėjo ir šalies.",
	ContactUs_Letter_Headline: "Siųsti laišką!",
	ContactUs_Letter_Description: "Turite klausimų, pageidavimų ar pasiūlymų? Laukiame jūsų užklausos.",
	ContactUs_Email_Headline: "Siųsti el. laišką!",
	ContactUs_Email_Description: "Atsiųskite mums savo pranešimą užpildydami žemiau esančią formą. Vienas iš mūsų darbuotojų mielai su jumis susisieks.",
	ContactUs_Email_InputName: "Vardas*",
	ContactUs_Email_InputCompany: "Įmonė",
	ContactUs_Email_InputCountry: "Šalis*",
	ContactUs_Email_InputEmail: "El. paštas*",
	ContactUs_Email_InputMessage: "Žinutė*",
	ContactUs_Email_DataProtection: "Duomenų apsaugos pranešimas*",
	ContactUs_Email_DataProtectionConfirm: "Aš susipažinau su Privatumo Sąlygomis.*",
	ContactUs_Email_SecurityCheck: "Saugos patikra",
	ContactUs_Email_SecurityCodeConfirm: "Įveskite teisingus simbolius, kuriuos matote aukščiau esančiame laukelyje.",
	ContactUs_Email_SecurityCodeInvalid: "Captcha teksto atsakymas, kurį įvedėte, yra neteisingas.",
	ContactUs_Email_MandatoryNotice: "Prašome užpildyti privalomus laukus.",
	ContactUs_Email_SuccessMessage: "Jūsų žinutė išsiųsta",
	ContactUs_Email_TakenNoteDataProtection1: "Aš perskaičiau",
	ContactUs_Email_TakenNoteDataProtection2: "Privatumo Sąlygas.*",
	ContactUs_Email_SubmitButton: "Pateikti",
	ContactUs_Email_FieldErrorMessage: "Prašome užpildyti privalomus laukus.",
	ContactUs_Email_SuccessModal_OK: "Gerai",
	AccountInReviewModal_Title: "Jūsų paskyra šiuo metu peržiūrima!",
	AccountInReviewModal_Paragraph1: "Dėkojame, kad užregistravote asmeninę paskyrą Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Mūsų darbuotojai šiuo metu peržiūri ir konfigūruoja jūsų paskyrą. Sėkmingai sukonfigūravus paskyrą, gausite galutinį patvirtinimą paštu.",
	AccountInReviewModal_Paragraph3: "Tuo tarpu nedvejodami naršykite mūsų svetainėje su ribota prieiga.",
	AccountInReviewModal_Paragraph4: "Jei turite klausimų arba jūsų paskyra neapdorojama per 48 valandas, išskyrus savaitgalius ir šventes, prašome susisiekti su mumis",
	AccountInReviewModal_ContactUsLink: "čia",
	AccountInReviewModal_OKButton: "Gerai, įleiskite mane!",
	Header_YesButton: "Taip",
	Header_NoButton: "Ne",
	Header_LogOutText: "Ar norite atsijungti?",
	Footer_EnquiryText: "Laukiame jūsų užklausos,",
	Footer_Telephone: "Telefonas",
	Footer_TermsAndConditions: "Sąlygos ir nuostatos",
	Footer_HowOurTrainingEvolves: "Kaip vystosi mūsų mokymai",
	FooterSearch_SearchFieldPlaceholder: "Paieška",
	TrainingCatalogue_Action: "Veiksmai",
	TrainingCatalogue_Duration: "Trukmė",
	TrainingCatalogue_Information: "Informacija",
	Settings_PersonalDetails: "Asmeninė informacija",
	Reset_All_Filters: "Iš naujo nustatykite visus filtrus",
	EventTable_Days: "Dienų skaičius",
	SearchField_AllResults: "Visi rezultatai",
	TrainingCentresLayout_ViewDetails: "Peržiūrėti detalią informaciją",
	Dropdown_Placeholder: "Pasirinkti...",
	WaitlistPopup_SelectButtonText: "Rinktis",
	WaitlistPopup_StartDate: "Pradžios data",
	WaitlistPopup_EndDate: "Pabaigos data",
	WaitlistPopup_Language: "Kalba",
	WaitlistPopup_City: "Miestas",
	WaitlistPopup_Workshop: "Įmonė",
	WaitlistPopup_Student: "Studentas",
	WaitlistPopup_PreferredLocation: "Pageidaujama vieta",
	WaitlistPopup_SignUpCourseWaitingList: "Užsiregistruokite į kursų laukiančiųjų sąrašą",
	WaitlistPopup_SignUpWithoutSelectingDate: "Nesirinkdami datos užsiregistruokite prie laukiančiųjų sąrašo",
	WaitlistPopup_GeneralWaitList: "Bendras laukiančiųjų sąrašas",
	WatinglistPopup_CourseLabel: "Kursai",
	Common_ContinueButton: "Tęsti",
	UserAccountDeactivationModal_ConfirmLabel: "Patvirtinti",
	Filter_FilterLabel: "Filtras",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Jūs jau esate laukiančiųjų sąraše, ar norite pašalinti esamą registraciją ir atlikti naują?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Taip, pašalinti",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Ne, nutraukti.",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Kursų atšaukimas",
	WaitinglistPopup_SignUpButtonText: "Prisijungti",
	MyAccount_FirstNameRequied: "Vardas būtinas",
	MyAccount_LastNameRequired: "Pavardė būtina",
	MyAccount_RoleRequired: "Vaidmuo",
	MyAccount_MobileRequired: "Mob. Tel. būtinas",
	MyAccount_EmailRequired: "El. paštas būtinas",
	MyAccount_LanguageRequired: "Kalba būtina",
	MyAccount_UpdateSuccessMessage: "Jūsų paskyros informacija sėkmingai atnaujinta!",
	MyAccount_UpdateSuccessModal_ButtonText: "Taip, uždaryti",
	Infor_CorporateInformation_Header: "Informacija apie įmonę",
	Infor_LegalNotice_Header: "Teisinis pranešimas",
	Infor_PrivacyPolicy_Header: "Duomenų apsaugos informacija",
	CreateEmployeeAccountModification_FormValidation_Role: "Vaidmuo būtinas",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Vardas būtinas",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Pavardė būtina",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mob. Tel. būtinas",
	CreateEmployeeAccountModification_FormValidation_Email: "El. paštas būtinas",
	CreateEmployeeAccountModification_FormValidation_Language: "Kalba būtina",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Kvietimas prisijungti prie „Bosch Automotive Training Solutions“.",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Jūs sėkmingai įtraukėte naują narį į savo komandą!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Ar norėtumėte, kad el. paštu nusiųstume kvietimą jūsų naujajam komandos nariu su instrukcijomis, kaip susikurti savo asmeninę Bosch Automotive Training Solutions paskyrą?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Ne",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Taip, nusiųskite kvietimą",
	ShoppingCart_Venue_Fee: "Vietos mokestis",
	ShoppingCart_Trainning_Name: "Kursų pavadinimas",
	ShoppingCart_Available_Credits: "Prieinami kreditai",
	ConfirmItsMeModal_Hi: "Sveiki.",
	ConfirmItsMeModal_Description1: "Sėkmingai įvykdėte įmonės administratoriaus pateiktą kvietimą susikurti savo asmeninę paskyrą Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Prieš tęsdami patvirtinkite arba atnaujinkite savo paskyros informaciją.",
	ConfirmItsMeModal_Description3: "Patvirtinus turėsite vėl prisijungti, kad užbaigtumėte paskyros kūrimą.",
	ConfirmItsMeModal_CompanyDetails: "Įmonės informacija",
	ConfirmItsMeModal_YourDetails: "Jūsų informacija",
	ConfirmItsMeModal_YourDetails_Title: "Pavadinimas",
	ConfirmItsMeModal_YourDetails_Function: "Funkcija",
	ConfirmItsMeModal_YourDetails_Name: "Vardas",
	ConfirmItsMeModal_YourDetails_Mobile: "Mob. Telefonas",
	ConfirmItsMeModal_YourDetails_Email: "El. paštas",
	ConfirmItsMeModal_YourDetails_Language: "Kalba",
	ConfirmItsMeModal_ConfirmButton: "Patvirtinti",
	SelectWholesalerModal_WholesalerGroupLabel: "Didmenininkas",
	SelectWholesalerModal_WholesalerBranchLabel: "Skyrius",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Prašome pasirinkti",
	ApplyButton: "Taikyti",
	SelectWholesalerModal_Title: "Pasirinkite didmenininką",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Ar norite pašalinti savo registraciją?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Jūsų registracija pašalinta sėkmingai!",
	WaitlistPopup_AddToWaitListSuccess: "Jūsų registracija buvo sėkminga!",
	ErrorCommon_Header: "KLAIDA",
	CommonButton_Close: "Uždaryti",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Nuotoliniai Mokymai",
	MyELearning_VideoPlayerClosingMessage: "Norėdami tęsti, uždarykite šį langą!",
	EmbeddedVideoPlayer_StartButtonText: "Pradžia",
	EmbeddedVideoPlayer_RefresherButtonText: "Atnaujinti",
	DeactivateWholesalerModal_ConfirmMessage: "Ar tikrai norite pašalinti šį didmenininką?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Kvietimo laiškas išsiųstas sėkmingai!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Kviesti pakartotinai",
	ContactUs_Mobile_LT: "+370 37 713350",
	ContactUs_Address_LT: "UAB „Robert Bosch“,Ateities pl. 79A,LT-52104,Kaunas,Lietuva",
	ConfirmItsMeModal_CompanyDetails_Name: "Įmonės pavadinimas",
	ConfirmItsMeModal_CompanyDetails_Address: "Adresas",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Įmonės pašto kodas",
	ConfirmItsMeModal_CompanyDetails_Phone: "Tel",
	ConfirmItsMeModal_CompanyDetails_Email: "El.paštas",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Pasitraukimas iš kursų sėkmingas!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Paskyros sukurti nepavyko. Paskyra jau yra!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Nepavyko atnaujinti paskyros. Paskyra jau yra!",
	Footer_ExploreTrainingStories: "Peržiūrėkite visas mūsų mokymo istorijas",
	Breadcrumb_Home: "Pradžia",
	Breadcrumb_Contact: "Kontaktai",
	Breadcrumb_Stories: "Istorijos",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Serviso mokymai",
	Breadcrumb_Stories_ADayInLife: "Diena trenerio gyvenime",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch mokymų privalumai",
	Breadcrumb_Stories_TrainingApproach: "Šiuolaikinis mokymo metodas",
	Breadcrumb_Settings: "Nustatymai",
	Breadcrumb_ShoppingCart: "Apsipirkimo krepšelis",
	Breadcrumb_Checkout: "Išsiregistruokite",
	Breadcrumb_OrderSummary: "Užsakymo detalės",
	Breadcrumb_Exception: "Išimtys",
	Breadcrumb_Dashboard: "Ataskaitų sritis",
	Breadcrumb_MyCourses: "Mano kursai",
	Breadcrumb_MyELearning: "Mano nuotoliniai kursai",
	Breadcrumb_CompanyAdministration: "Įmonės administravimas",
	Breadcrumb_EmployeeManagement: "Darbuotojo valdymas",
	Breadcrumb_EmployeeAccount: "Darbuotojo paskyra",
	Breadcrumb_EmployeeCreation: "Įtraukti naują darbuotoją",
	Breadcrumb_Administration: "Administravimas",
	Breadcrumb_EmployeeAdministration: "Darbuotojo admininstravimas",
	Breadcrumb_WholesalerManagement: "Didmenininko valdymas",
	Breadcrumb_NewWholesalerAccountCreation: "Sukurti naują didmenininko paskyrą",
	Breadcrumb_Transactions: "Transakcijos",
	Breadcrumb_MyAccount: "Mano paskyra",
	Breadcrumb_TrainingCentres: "Mokymų centrai",
	Breadcrumb_EventCalendar: "Renginių kalendorius",
	Breadcrumb_TrainingCourseCatalogue: "Kursų katalogas",
	Breadcrumb_RecommendedHotels: "Rekomenduojami viešbučiai",
	Breadcrumb_CorporateInformation: "Įmonės informacija",
	Breadcrumb_LegalNotice: "Teisinis pranešimas",
	Breadcrumb_DataProtectionNotice: "Duomenų apsaugos pranešimas",
	Breadcrumb_PrivacyPolicy: "Privatumo apsauga",
	Breadcrumb_TermCondition: "Sąlygos ir nuostatos",
	Breadcrumb_Terms: "Sąlygos",
	Breadcrumb_PrivacyStatement: "Privatumo pareiškimas",
	Breadcrumb_Cookies: "Slapukai",
	Breadcrumb_Provider: "Tiekėjas",
	Breadcrumb_RegisterAccount: "Registruoti paskyrą",
	Stories_ExploreMore: "Atrask daugiau",
	CheckOutLayout_ShoppingCartHeader: "Apsipirkimo krepšelis",
	CheckOutLayout_AddedItemCartMessage: "Į krepšelį įdėjote šiuos dalykus",
	CheckOutLayout_CheckYourCartMessage: "Patikrinkite krepšelį",
	ModernTrainingApproach_DateText: "2/28/2022 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Ieškoti darbuotojų",
	CheckOutLayout_OrderWasNotPlaced_Message: "Atsiprašome! Jūsų užsakymas nebuvo pateiktas",
	CheckOutLayout_RefusalReason: "Atsisakymo priežastis",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Mokėjimas nepavyko. Bandykite dar kartą naudodami kitą mokėjimo metodą",
	CheckOutLayout_SubmittingApiFailed_Message: "Nepavyko pateikti API",
	CartItem_ChooseWorkshop_Message: "Norėdami pasirinkti dalyvį pirmiau pasirinkite dirbtuves",
	CartItem_ChooseParticipant_Message: "Norėdami tęsti, pasirinkite dalyvį",
	CompanyAdministrationTable_SearchBox_Placeholder: "Dirbtuvių paieška",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Function",
	AddToCartSuccessModal_Message: "Ar norite išimti šią prekę iš krepšelio?",
	AddToCartSuccessModal_ContinueButtonText: "Tęsti apsipirkimą",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Mano pirkinių krepšelis",
	AddVoucher_Header: "Pridėti vaučerį",
	AddVoucher_InputPlaceholder: "Įveskite kodą ir tęskite",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Deja, šiuo metu neturime suplanuotų šių mokymo kursų. Prašome užsiregistruoti į laukiančiųjų sąrašą ir mes jus informuosime, kai tik atsiras suplanuoti kursai.",
	ADYEN_ERR_2_: "Sandorio buvo atsisakyta.",
	ADYEN_ERR_3_: "Persiuntimo klaida",
	ADYEN_ERR_4_: "Sandoris nebuvo įvykdytas dėl klaidos, kuri įvyko pirkėjo pusėje.",
	ADYEN_ERR_5_: "Operacijai naudota kortelė užblokuota, todėl netinkama naudoti.",
	ADYEN_ERR_6_: "Operacijai naudotos kortelės galiojimo laikas baigėsi. Todėl yra netinkama naudoti.",
	ADYEN_ERR_7_: "Operacijos proceso metu įvyko sumos neatitikimas.",
	ADYEN_ERR_8_: "Nurodytas kortelės numeris yra neteisingas arba negaliojantis.",
	ADYEN_ERR_9_: "Neįmanoma susisiekti su pirkėjo banku, kad būtų patvirtinta operacija.",
	ADYEN_ERR_10_: "Pirkėjo bankas nepalaiko arba neleidžia atlikti tokio tipo operacijų.",
	ADYEN_ERR_11_: "3D Secure autentifikavimas nebuvo atliktas arba jis nebuvo sėkmingai vykdomas.",
	ADYEN_ERR_12_: "Kortelėje nėra pakankamai pinigų mokėtinai sumai padengti. Ši problema gali kilti dėl jūsų kortelės mėnesio mokėtinos sumos limito. Pabandykite naudoti kitą kortelę, pvz., „MasterCard“, arba susisiekite su banku, kad iš naujo nustatytumėte kortelės mėnesio limitą.",
	ADYEN_ERR_14_: "Galimas sukčiavimas.",
	ADYEN_ERR_15_: "Sandoris buvo atšauktas.",
	ADYEN_ERR_16_: "Pirkėjas atšaukė sandorį prieš jį užbaigdamas.",
	ADYEN_ERR_17_: "Nurodytas PIN yra neteisingas arba negaliojantis.",
	ADYEN_ERR_18_: "Pirkėjas daugiau nei tris kartus iš eilės nurodė neteisingą PIN kodą.",
	ADYEN_ERR_19_: "Neįmanoma patvirtinti nurodyto PIN numerio.",
	ADYEN_ERR_20_: "Galimas sukčiavimas.",
	ADYEN_ERR_21_: "Operacija nebuvo tinkamai pateikta apdoroti.",
	ADYEN_ERR_22_: "Rizikos patikrinimo metu sandoris buvo pažymėtas kaip nesąžiningas (rizikos balas >= 100); todėl operacija atšaukiama.",
	ADYEN_ERR_23_: "Šie atmesti kodai yra susieti su šia atsisakymo priežastimi: Operacija neleidžiama išdavėjui / kortelės turėtojui.",
	ADYEN_ERR_24_: "Nurodytas CVC (kortelės saugos kodas) neteisingas.",
	ADYEN_ERR_25_: "Šie atmetimo kodai yra susieti su šia atsisakymo priežastimi: netinkama kortelė šioje šalyje",
	ADYEN_ERR_26_: "R1: Autorizacijos nurodymo atšaukimas / „R3: Visų įgaliojimų įsakymo atšaukimas“ / „R0: Sustabdyti mokėjimą“",
	ADYEN_ERR_27_: "Šis atsakymas atvaizduoja visus atsakymų kodus, kurių negalima patikimai susieti. Taip lengviau atskirti bendruosius atmetimus (pavyzdžiui, Mastercard atsakymą „05: Negerbti“) nuo konkretesnių.",
	ADYEN_ERR_28_: "Viršijo pirkėjo kortelės išgryninimo sumą.",
	ADYEN_ERR_29_: "Viršytas pirkėjo kortelės išėmimų skaičius.",
	ADYEN_ERR_31_: "Emitentas pranešė apie sandorį kaip įtariamą sukčiavimą.",
	ADYEN_ERR_32_: "Pirkėjo įvesti adreso duomenys yra neteisingi.",
	ADYEN_ERR_33_: "Pirkėjo bankas reikalauja, kad pirkėjas įvestų internetinį PIN kodą.",
	ADYEN_ERR_34_: "Pirkėjo bankas reikalauja einamosios sąskaitos, kad užbaigtų pirkimą.",
	ADYEN_ERR_35_: "Pirkėjo bankas turi turėti taupomąją sąskaitą, kad užbaigtų pirkinį.",
	ADYEN_ERR_36_: "Pirkėjo bankas reikalauja, kad pirkėjas įvestų mobiliojo telefono PIN kodą.",
	ADYEN_ERR_37_: "Pirkėjas atsisakė operacijos po to, kai bandė atlikti nekontaktinį mokėjimą ir buvo paragintas išbandyti kitą kortelės įvedimo būdą (PIN kodą arba perbraukimą).",
	ADYEN_ERR_38_: "Išdavėjas atmetė autentifikavimo išimties užklausą ir reikalauja operacijos autentifikavimo. Bandykite dar kartą naudodami 3D Secure.",
	ADYEN_ERR_39_: "Emitentas arba schema negalėjo pranešti rezultato per RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Varde negali būti specialiųjų simbolių (pvz., @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Vardas turi būti trumpesnis nei 75 simboliai",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Pavardėje neturi būti specialiųjų simbolių (pvz., @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Pavardė turi būti trumpesnė nei 75 simboliai",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobiliojo telefono numeriuose negali būti specialiųjų simbolių (pvz., @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobiliojo telefono numeris turi būti mažesnis nei 45 simboliai",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telefono numeryje neturi būti specialiųjų simbolių (pvz., @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telefono numeris turi būti trumpesnis nei 45 simboliai",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "El. pašto adresas neteisingas",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "El. pašto adresas turi būti trumpesnis nei 100 simboliai",
	ConfirmGeolocationModal_ConfirmButton: "Tęsti",
	ConfirmGeolocationModal_CancelButton: "Keisti",
	ConfirmGeolocationModal_CurrentCountrySelection: "Dabartinis jūsų šalies pasirinkimas yra:",
	ConfirmGeolocationModal_AskChangeCountry: "Ar norite likti šioje svetainėje ar pakeisti savo šalies pasirinkimą?",
	Addtocart_Modal_AddToCartFailTitle: "Nepavyko pridėti dalyvio",
	Addtocart_Modal_AlreadyInCart: "Dalyvis jau užsiregistravo į šiuos kursus",
	TrainingCatalogue_DateRange: "Laiko intervalas",
	TrainingCatalogue_DateRange_placeholder: "Pasirinkti laiko intervalo filtrą",
	Dashboard_MyPendingCourses: "Mano laukiami kursai",
	Dashboard_MyWaitingList: "Mano laukiamas sąrašas",
	Dashboard_LatestNews: "Naujienos",
	SettingsMainPage_EditAccountButton: "Redaguoti paskyrą",
	SettingsMainPage_EditBoschId: "Redaguoti Bosch ID",
	EventInfoView_EventDetailTab: "Renginio informacija",
	EventInfoView_AssignmentsTab: "Užduotys",
	EventInfoView_EvaluationTab: "Vertinimai",
	EventInfoView_AttachmentTab: "Priedai",
	EventInfoView_CourseDescriptionTab: "Aprašymas",
	EventInfoView_DescriptionTab_General: "Bendra informacija",
	EventInfoView_DescriptionTab_PartLabel: "Dalis",
	AssignmentTab_Description: "Čia rasite visą informaciją, kurios jums prireiks norint baigti šį kursą.",
	AssignmentTab_Columns_Download: "Atsisiųsti",
	AssignmentTab_Columns_Upload: "Įkelti",
	AssignmentTab_Columns_TaskComplete: "Užduotis baigta",
	EventDetailTab_Location_Message_Video: "Tai internetinis įvykis, kurį galima pasiekti naudojant nuorodą \"Paleisti vaizdo įrašą\".",
	EventDetailTab_Location_Message_WebBased: "Tai internetinis įvykis, kurį galima pasiekti naudojant nuorodą \"Launch WBT\".",
	EventDetailTab_Location_Message_WebCast: "Tai internetinis įvykis, kurį galima pasiekti naudojant nuorodą  \"Join Now\".",
	EventDetailTab_Link_Video: "Paleisti vaizdo įrašą",
	EventDetailTab_Link_Video_Remark: "*Paleidus vaizdo įrašą atsidarys naujas langas. Kai langas bus uždarytas, būsite nukreipti atgal į šį puslapį.",
	EventDetailTab_Link_WebBased: "Paleisti WBT",
	EventDetailTab_Link_WebBased_Remark: "*Paleidus WBT atsidarys naujas langas. Kai langas bus uždarytas, būsite nukreipti atgal į šį puslapį.",
	EventDetailTab_Link_WebCast: "Prisijungti dabar",
	EventDetailTab_Link_WebCast_Remark: "*Prisijungimo nuoroda bus aktyvi likus 30 minučių iki renginio pradžios.",
	EventDetailTab_Title_Status: "Statusas",
	EventDetailTab_Title_Duration: "Trukmė (h)",
	EventDetailTab_Title_Trainers: "Mokytojas (ai)",
	EventDetailTab_Title_Language: "Kalba",
	EventDetailTab_Title_Score: "Įvertinimas (%)",
	EventDetailTab_Title_Calendar: "Įtraukti į kalendorių",
	EventDetailTab_Title_Certificate: "Atsisiųsti sertifikatą",
	EventDetailTab_Title_Messages: "Pranešimai",
	EventDetailTab_Title_StartDate: "Pradžios data",
	EventDetailTab_Title_AvailableFrom: "Prieinama nuo",
	EventDetailTab_Title_Time: "Laikas",
	EventDetailTab_Title_EndDate: "Pabaigos data",
	EventDetailTab_Title_ExpiresOn: "Nustoja galioti",
	EventDetailTab_Title_Room: "Klasė",
	EventDetailTab_Title_Method: "Metodas",
	EventDetailTab_Title_Link: "Nuoproda*",
	EventDetailTab_Title_Location: "Vieta",
	AttachmentTab_EmptyScreenDescription: "Nėra šio įvykio priedų.",
	AttachmentTab_Columns_Download: "Atsisiųsti",
	AttachmentTab_Description: "Čia rasite svarbios informacijos, kuri jums padės šiame kurse",
	EvaluationTab_Annotation: "Vertiname jūsų atsiliepimus ir norėtume sužinoti, kaip mums sekasi. Skirkite laiko ir užpildykite šį įvertinimą, kad galėtume nuolat tobulinti jums teikiamas paslaugas. Ačiū.",
	EvaluationTab_Notice: "Įvertinimai yra tokie",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Prieš pateikdami renginio vertinimą, atsakykite į visus privalomus klausimus",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Jūsų atsiliepimas buvo sėkmingai pateiktas",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Vertinimo pateikti nepavyko",
	TrainingCatalogueLayout_ExportButtonText: "Eksportuoti",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Vertinimas buvo baigtas arba nebegalioja.",
	EventDetailTab_Link_CopiedTooltip: "Nukopijuota į mainų sritį",
	EventDetailTab_QRCode_ExplanationText_WBT: "Nuskaitykite, kad paleistumėte WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Nuskaitykite, kad paleistumėte vaizdo įrašą",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Nuskaitykite, kad paleistumėte internetinę transliaciją",
	Footer_TrainingCoure_Headline: "„Bosch“ organizuojami mokymo kursai",
	Footer_TrainingCourse_Content: "Kaip visame pasaulyje žinomas „Superbrand“ ir daugiau nei šimtmetį kaupęs inovacijų patirtį, „Bosch“ nustatė pasaulinius standartus kaip automobilių sistemų, dalių ir techninių sprendimų tiekėja. Didžiąją mūsų stiprybės dalį sudaro glaudus bendradarbiavimas su transporto priemonių gamintojais, kuriems visame pasaulyje kuriame ir gaminame pažangiausias sistemas ir komponentus. Todėl „Bosch“ sutvirtino stiprią automobilių antrinės rinkos produktų ir techninės priežiūros istoriją su įvairiu dalių, diagnostikos sprendimų ir dirbtuvių paslaugų asortimentu. Mokymasis ir tobulėjimas yra tikri raktai į sėkmę, o ši filosofija remiasi įvairiais mokymo kursais visose automobilių technologijų, transporto priemonių sistemų ir diagnostikos srityse.",
	SearchField_NoRecordsFound: "Įrašų nerasta",
	TrainingCatalogue_FreeTextSearch: "Laisvai pasirenkamas tekstas",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Paieška",
	CheckoutLayout_WholeSalerGroup: "Pasirinkite didmenininkų grupę",
	CreditInsufficientModal_Message: "Neturite pakankamai kredito, kad galėtumėte naudoti šį mokėjimo metodą. Bandykite dar kartą naudodami kitą mokėjimo metodą.",
	ShoppingCart_Required_Credits: "Visa kaina",
	MaintainingInfo_Message1: "Svetainė veikia priežiūros režimu.",
	MaintainingInfo_Message2: "Greitai grįšime.",
	CountryValidationModal_Message: "Negalėjote prisijungti prie [CountryA], nes jūsų paskyra buvo sukurta [CountryB].",
	CountryValidationModal_ButtonText: "Toliau į [Country]",
	EventTable_LoginRequired: "Prisijunkite norėdami užsiregistruoti",
	TrainingPartTableLayout_Date: "Data",
	TrainingPartTableLayout_StartTime: "Pradžios laikas",
	TrainingPartTableLayout_EndTime: "Pabaigos laikas",
	TrainingPartTableLayout_Duration: "Trukmė (val.)",
	TrainingPartTableLayout_TrainingMethod: "Mokymo metodas",
	MyCoursesTableContents_ActiveHeader: "Aktyvūs kursai",
	MyCoursesTableContents_ActiveTabHeader: "Aktyvus",
	MyCoursesTableContents_ScheduledHeader: "Suplanuoti kursai",
	MyCoursesTableContents_ScheduledTabHeader: "Suplanuota",
	MyCoursesTableContents_OnlineHeader: "Internetiniai kursai",
	MyCoursesTableContents_OnlineTabHeader: "Internetiniai",
	MyCoursesTableContents_CancelledHeader: "Atšaukti kursai",
	MyCoursesTableContents_CancelledTabHeader: "Atšauktas",
	OnlineCoursesTable_Title_Progress: "Progresas",
	ScheduledCoursesTable_Title_Date: "Data [dienos]",
	CoursesTable_Title_Completed: "Baigta",
	Footer_TrainingProgram: "Mokymo programos",
	Breadcrumb_TrainingProgram: "Mokymo programos",
	TrainingProgram_Title_Table: "Pavadinimas",
	TrainingProgram_TrainingProgram: "Mokymo programos",
	TrainingProgram_TargetGroup: "Tikslinė grupė",
	TrainingProgram_MaxLength: "Ilgis",
	TrainingProgram_Information: "Informacija",
	TrainingProgram_Detail: "Detalės",
	TrainingProgram_Modules: "Moduliai",
	TrainingProgram_Course: "Kursai",
	TrainingProgram_PartNumber: "Dalies numeris",
	TrainingProgram_Max_Length: "Maksimalus ilgis",
	TrainingProgram_Duration: "Trukmė",
	TrainingProgram_Exam: "Egzaminavimas",
	TrainingProgram_Assign_Popup: "Registruokitės į mokymo programą",
	TrainingProgram_Student: "Studentas",
	TrainingProgram_Assign: "Priskirti",
	TrainingProgram_Popup_Remove_Title: "Kursų atšaukimas?",
	TrainingProgram_Description: "Apibūdinimas",
	TrainingProgram_Days: "Dienos",
	TrainingProgram_Type: "Tipas",
	TrainingProgram_Action: "Veiksmas",
	TrainingProgram_Enrolled: "jau buvo įtrauktas į šią mokymo programą",
	TrainingProgram_SelfAssign_Enrolled: "Jau esate priskirti šiai mokymo programai",
	TrainingProgram_Warning: "Užregistruokite kitą studentą arba pasirinkite kitą mokymo programą.",
	TrainingProgram_Workshop: "Dirbtuvės",
	TrainingProgram_Durations: "metų",
	TrainingProgram_Duration_DaysLabel: "dienų",
	TrainingProgram_HasExam_Yes: "Taip",
	TrainingProgram_HasExam_No: "Nr",
	AssignModal_SuccessModal_Header: "Sveikiname!",
	AssignModal_SuccessModal_Message: "buvo sėkmingai įtrauktas į šią mokymo programą.",
	AssignModal_SelfAssign_SuccessModal_Message: "Buvote sėkmingai priskirtas mokymo programai.",
	Dashboard_MyTraningProgram: "Mano mokymo programa",
	Dashboard_MyTraningProgram_Title: "Pavadinimas",
	Dashboard_MyTraningProgram_Status: "Būsena",
	Dashboard_MyTraningProgram_Table_View: "Žiūrėti",
	Dashboard_MyTraningProgram_Register: "Registruotis",
	Dashboard_MyTraningProgram_Records: "Istoriniai įrašai",
	Dashboard_MyTrainingProgram_In_Progress: "Vykdoma",
	Dashboard_MyTrainingProgram_Accredited: "Akredituota",
	Dashboard_Program_Modal_Withdraw: "Atšaukti",
	Dashboard_Program_Modal_Grade: "Įvertinimas",
	Dashboard_MyTrainingProgram_Registered: "Registruotas",
	Dashboard_MyTrainingProgram_Completedd: "Baigta",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Taip",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nr",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Atminkite, kad atšaukimas reiškia, kad pasitrauksite iš visos mokymo programos, o ne tik iš atskiro mokymo kurso.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Ar vis dar norite pasitraukti iš mokymo programos?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Laukiama",
	WithdrawProgramModal_Withdraw_Success_Message: "Jūs sėkmingai pasitraukėte iš mokymo programos!",
	Common_Warning_Title: "Įspėjimas!",
	Common_Error_Title: "Ups, kažkas nutiko!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Į krepšelį",
	AddEventFromCatalogToCartModal_HoursText: "valandų",
	AddElearningToCartSuccessModal_Message: "buvo įtraukta į jūsų pirkinių krepšelį!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Pasirinktas dalyvis yra užsiregistravęs šiame kurse arba kursas jau buvo įtrauktas į krepšelį.",
	EventDetailTab_SubjectForChange_Message: "Keitimo objektas",
	Dashboard_MyTrainingProgram_Status_Failed: "Nepraėjo",
	Dashboard_MyTrainingProgram_Status_Passed: "Praėjo",
	Dashboard_MyTrainingProgram_Status_Merit: "Nuopelnas",
	Dashboard_MyTrainingProgram_Status_Distinction: "Excellence",
	Dashboard_Employee_Courses: "Kursai",
	Dashboard_Employee_Program: "Treniravimosi programa",
	Dashboard_Employee_WaitList: "Laukimo sąrašas",
	Dashboard_Employee_Account: "sąskaita",
	Course_Price_Free: "Laisvas",
	Dashboard_Employee_Account_Update_Success_Message: "Sėkmingai atnaujinote savo darbuotojo informaciją!",
	Dashboard_Supplier: "Tiekėjas",
	Event_Detail_Comment: "Šiuo metu pranešimų nėra",
	DownLoad_PDF: "Atsisiųsti PDF",
	TrainingCatalogue_Currency: "Valiuta",
	My_Wallet: "Mano piniginė",
	Wallet: "Wallet",
	Credits_Balance: "Kredito balansas",
	MyFullCalendar_Today: "Šiandien",
	Header_Available_Languages: "Kalbų pasirinkimas",
	Header_CurrentRegion: "Šiuo metu esate",
	Header_ChangeRegions: "Ar norite pakeisti šalį/kalbą?",
	Header_Anonymous: "Techniniai mokymai",
	Header_Anonymous_Login: "Prisijugti / Registruotis",
	Header_RegisterCourse: "Registruotis į mokymus",
	Homepage_Start: "Pradėti",
	Homepage_Login: "Prisijungti",
	MainPage_Title: "Galutinis dirbtuvių sprendimas",
	MainPage_Content: "Jūsų dirbtuvės, Jūsų pasirinkimas.",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Leidžia šiuolaikinėms dirbtuvėms profesionaliai, patikimai ir efektyviai atlikti visų tipų transporto priemonių diagnostikos procedūras, gedimų šalinimą, remontą ir aptarnavimą.",
	BoschNews_TrainingNews: "Mokymų naujienos",
	Service_Assist_Workshop: "Išplėskite savo patirtį naudodami dirbtuvių aptarnavimo asistentą",
	Enhance_Title_CDM3_v2: "Ką galiu atlikti su programa?",
	Enhance_Body_CDM3_Item_1: "Užsisakykite mokymus ir ugdykite kompetencijas",
	Enhance_Body_CDM3_Item_2: "Atsisiųskite mokymo medžiagą ir mokymų sertifikatus",
	Enhance_Body_CDM3_Item_3: "Pradėti nuotolinio mokymosi kursus",
	Enhance_Body_CDM3_Item_4: "Pasiekite įvairias paslaugas naudodami mobiliąją programą",
	Enhance_Body_CDM3_Item_5: "Tiesioginis vaizdo perdavimas naudojant „Visual Connect Pro“.",
	Enhance_Body_CDM3_Item_6: "Nemokamas įrankis automatiniam transporto priemonių nuskaitymui",
	Training_Stories: "Mokymosi istorijos",
	SettingsMyAccount_JoinCompanyButton: "Prisijunkite prie įmonės",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Prisijunkite prie įmonės",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Įveskite savo aktyvinimo kodą žemiau",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Pastaba! Norėdami gauti aktyvavimo kodą, turite susisiekti su savo įmonės administratoriumi.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Jūsų aktyvavimas buvo sėkmingai apdorotas, o paskyra  susieta su Jūsų įmone.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Norėdami patekti į svetainę, spustelėkite tęsti.",
	ContinueButton: "Tęsti",
	WelcomeBatsModal_Title: "Jūsų paskyra šiuo metu peržiūrima!",
	WelcomeBatsModal_ActivationLink: "Spustelėkite čia, jei turite aktyvaviimo kodą, kad galėtumėte prisijungti prie įmonės!",
	WelcomeBatsModal_ActivationLinkNotes: "Pastaba! Norėdami gauti aktyvavimo kodą, turite susisiekti su administratoriumi.",
	AccountInReviewModal_Description_Paragraph1: "Dėkojame, kad užregistravote Bosch Automotive Training Solutions sistemoje.",
	AccountInReviewModal_Description_Paragraph2: "Mūsų specialistai šiuo metu peržiūri ir konfigūruoja jūsų paskyrą. Kai paskyra bus sėkmingai sukonfigūruota, gausite galutinį patvirtinimą paštu.",
	AccountInReviewModal_CloseButton: "Gerai, įleisk mane!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Negalėjome patvirtinti jūsų aktyvinimo kodo ir el. pašto adreso.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Bandykite dar kartą arba kreipkitės pagalbos į įmonės administratorių.",
	TryAgain_Button: "Bandykite dar kartą",
	Error_Title: "Oi! Kažkas negerai!",
	SettingsMyProfileActivationSuccessModal_Title: "Sėkmė!",
	AccountInReviewModal_Description_Paragraph3: "Tuo tarpu naršykite mūsų svetainėje su ribota prieiga.",
	AccountInReviewModal_Description_Paragraph4: "Jei turite klausimų arba jūsų paskyra neapdorojama per 48 valandas, išskyrus savaitgalius ir šventes, galite susisiekti su mumis [čia]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Keletas patarimų, kurie jums padės...",
	Support_FAQ_Headline: "Dažnai užduodami klausimai",
	Support_FAQs_section1_title: "Visuotinis",
	Support_FAQs_section1_question1: "Kas yra  Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "„Bosch Training Solutions“ yra platforma, kuri suteikia išsamią techninių mokymų apžvalgą ir leidžia rezervuoti mokymus bei valdyti jų istoriją.",
	Support_FAQs_section1_question2: "Ar galiu naudoti turimą „Bosch“ paskyrą (vieną rakto ID) prisijungti prie „Bosch Training Solutions“?",
	Support_FAQs_section1_question2_answer1: "Taip, jis pasiekiamas su visomis paskyromis, sukurtomis naudojant \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Ar galiu naudoti „Bosch Training Solutions“ sukurtą paskyrą su kitomis „Bosch“ paslaugomis ar programomis?",
	Support_FAQs_section1_question3_answer1: "Taip, savo asmeninį Bosch ID taip pat galite naudoti įvairioms kitoms Bosch programoms – tiek profesionaliai, tiek privačiai, pavyzdžiui, el. dviračiui ar išmaniesiems namams.",
	Support_FAQs_section2_title: "Mokymai",
	Support_FAQs_section2_question1: "Kaip galiu užsisakyti mokymus?",
	Support_FAQs_section2_question1_answer1: "Prisijunkite naudodami savo turimą paskyrą ARBA užregistruokite paskyrą portale. Eikite į \"Mokymo paslaugos\" ARBA \"Renginių kalendorius\" pasirinkite mokymą ir spustelėkite mygtuką įtraukti į kortelę.",
	Support_FAQs_section2_question2: "Nėra tinkamos mokymo datos. Ką aš galiu padaryti?",
	Support_FAQs_section2_question2_answer1: "Jūs turite galimybę įrašyti save į \"bendrąjį\" laukiančiųjų sąrašą (nepasirinkdami datos), o mūsų komanda jus informuos, kai tik bus galima pradėti mokytis.",
	Support_FAQs_section2_question3: "Kur galiu rasti mano užsakytų kursų apžvalgą?",
	Support_FAQs_section2_question3_answer1: "Visą visų užsakytų ir baigtų kursų apžvalgą galite rasti prisijungę prie savo paskyros.",
	Support_FAQs_section3_title: "Nustatymai",
	Support_FAQs_section3_question1: "Kaip galiu pakeisti savo el. paštą ir slaptažodį?",
	Support_FAQs_section3_question1_answer1: "Turite prisijungti prie portalo ir pakeisti asmeninį profilį. (Informacijos suvestinėje galite rasti savo profilio informaciją arba spustelėję toliau esantį mygtuką antraštėje)",
	Support_FAQs_section3_question1_answer3: "Čia turite galimybę pakeisti savo Bosch ID duomenis",
	Support_FAQs_section3_question2: "Ką daryti, kai pamiršau slaptažodį?",
	Support_FAQs_section3_question2_answer1: "Naudokite prisijungimo/registracijos mygtuką ir įveskite savo el. pašto adresą",
	Support_FAQs_section3_question2_answer2: "Jei paspausite tęsti, turėsite galimybę spustelėti \"Pamiršau slaptažodį\"",
	Support_FAQs_section3_question3: "Kaip susikurti dirbtuvių paskyrą?",
	Support_FAQs_section3_question3_answer1: "Jei norite sukurti seminarą, susisiekite su mokymo administracija (galite naudoti svetainėje esančią kontaktinę formą).",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Pasirinkite šalį",
	MaintenanceBanner_Message_1: "„Bosch Automotive Training Solutions“ portalas atnaujinamas [maintenance_schedule]. Numatoma, kad atnaujinimas užtruks 30 minučių. Atnaujinimo metu portalas nebus pasiekiamas.",
	MaintenanceBanner_Message_2: "Jei reikia pagalbos, susisiekite su mumis el. paštu [support_email]",
	MaintenancePage_Message_1: "Šiuo metu atnaujinamas Bosch Training Solutions automobilių mokymo  portalas",
	MaintenancePage_Message_2: "Tikimės, kad portalas vėl bus pasiekiamas [maintenance_online_date] [maintenance_online_time]",
	MenuHeader_Support: "Palaikymas",
	Course_Detail_No_Prerequisites: "Iš būtinųjų sąlygų",
	TableCell_Hours: "Valandos",
	Dashboard_MenuItem_Dashboard: "Prietaisų skydelis",
	Dashboard_MenuItem_Curricula: "Mokymų planas",
	Dashboard_MenuItem_Certificates: "Sertifikatai",
	Dashboard_MenuItem_Team: "Komanda",
	Dashboard_MenuItem_Workshops: "Dirbtuvės",
	Dashboard_MenuItem_External: "Išorinis",
	Dashboard_MenuItem_Wallet: "Piniginė",
	Dashboard_MenuItem_Profile: "Profilis",
	Dashboard_Headline_YourUpcomingCourses: "Jūsų būsimi kursai",
	Dashboard_Headline_YourELearningCourses: "Jūsų būsimi kursai",
	Dashboard_Headline_TeamOverview: "Komandos apžvalga",
	Dashboard_Headline_YourOverview: "Jūsų apžvalga",
	Dashboard_Headline_YourWishlist: "Jūsų pageidavimų sąrašas",
	Dashboard_Headline_ActivePrograms: "Aktyvi programa",
	Dashboard_Headline_CompletedPrograms: "Baigtos programos",
	Dashboard_Headline_YourCertificates: "Jūsų sertifikatai",
	Dashboard_Headline_Transactions: "Sandoriai",
	Dashboard_Table_NoRecordsToDisplay: "Nėra įrašų",
	Dashboard_TableColumn_Location_Online: "Prisijungęs",
	Dashboard_TableColumn_Duration_Hours: "valandos",
	Dashboard_ELearningCourses_TableHeader_Title: "Pavadinimas",
	Dashboard_ELearningCourses_TableHeader_Duration: "Trukmė (val.)",
	Dashboard_ELearningCourses_ActionButton_View: "Žiūrėti",
	Dashboard_UpcomingCourses_TableHeader_Title: "Pavadinimas",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Data [dienos]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Vieta",
	Dashboard_UpcomingCourses_ActionButton_View: "Žiūrėti",
	Dashboard_YourOverview_TableHeader_Title: "Pavadinimas",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Data / Trukmė",
	Dashboard_YourOverview_TableHeader_Location: "Vieta",
	Dashboard_YourOverview_TableHeader_Status: "Būsena",
	Dashboard_YourOverview_ActionButton_View: "Žiūrėti",
	Dashboard_YourWishlist_TableHeader_Title: "Pavadinimas",
	Dashboard_YourWishlist_TableHeader_Action: "Veiksmas",
	Dashboard_YourWishlist_ViewAllRecords: "Žiūrėti visus įrašus",
	Dashboard_TeamOverview_TableHeader_Employee: "Darbuotojas",
	Dashboard_TeamOverview_TableHeader_Title: "Pavadinimas",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Data / Trukmė",
	Dashboard_TeamOverview_TableHeader_Location: "Vieta",
	Dashboard_TeamOverview_TableHeader_Status: "Būsena",
	Dashboard_TeamOverview_ActionButton_View: "Žiūrėti",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Ieškoti",
	Dashboard_ActiveProgram_TableHeader_Title: "Pavadinimas",
	Dashboard_ActiveProgram_TableHeader_Status: "Būsena",
	Dashboard_ActiveProgram_ActionButton_View: "Žiūrėti",
	Dashboard_CompletedProgram_TableHeader_Title: "Pavadinimas",
	Dashboard_CompletedProgram_TableHeader_Status: "Būsena",
	Dashboard_CompletedProgram_ActionButton_View: "Žiūrėti",
	Dashboard_Certificates_TableHeader_Title: "Pavadinimas",
	Dashboard_Certificates_TableHeader_Date: "Data",
	Dashboard_Certificates_TableHeader_Status: "Būsena",
	Dashboard_Certificates_TableHeader_Action: "Veiksmas",
	Dashboard_Certificates_ActionButton_Download: "Atsisiųsti",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Sąskaitos numeris",
	Dashboard_Transactions_TableHeader_Description: "Apibūdinimas",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Mokėjimo būdas",
	Dashboard_Transactions_TableHeader_Date: "Data",
	Dashboard_Transactions_TableHeader_Amount: "Suma",
	Dashboard_Transactions_ActionButton_Download: "Atsisiųsti",
	Breadcrumb_MyTraining: "Mano mokymai",
	Breadcrumb_MyTraining_CourseDetails: "Mokymo detalės",
	Breadcrumb_MyTraining_Curricula: "Mokymo planas",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Mokymo programos detalės",
	Breadcrumb_MyTraining_Team: "Komanda",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Žiūrėti darbuotoją",
	MyTraining_Headerbar_CourseDetails: "Mokymo detalės",
	MyTraining_Headerbar_TrainingProgramDetails: "Mokymo programos detalės",
	TrainingProgramDetails_Label_Status: "Būsena",
	TrainingProgramDetails_Label_OverallProgress: "Bendras progresas",
	TrainingProgramDetails_ProgramItems_Headline: "Programos elementai",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Pavadinimas",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Būsena",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Įvertinimas",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Data",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Žiūrėti",
	TrainingProgramDetails_Status_InProgress: "Vykdoma",
	TrainingProgramDetails_Status_Completed: "Įvykdyta",
	CourseDetails_Label_Status: "Būsena",
	CourseDetails_Label_Duration: "Trukmė",
	CourseDetails_Label_Trainer: "Mokytojas",
	CourseDetails_Label_Language: "Kalba",
	CourseDetails_Label_Score: "Rezultatas:",
	CourseDetails_Label_StartDate: "Pradžios data",
	CourseDetails_Label_EndDate: "Pabaigos data",
	CourseDetails_Label_Venue: "Vieta",
	CourseDetails_Label_Address: "Adresas",
	CourseDetails_Label_Grade: "Įvertinimas",
	CourseDetails_Action_Withdraw: "Atsitraukti",
	CourseDetails_Action_Evaluate: "Įvertinti mokymus",
	CourseDetails_Action_Evaluate_Description: "Įvertinkite mokymus, kad gautumėte sertifikatą!",
	CourseDetails_Action_DownloadCertificate: "Atsisiųsti sertifikatą",
	CourseDetails_Action_JoinWebcast: "Prisijunkite prie internetinės transliacijos",
	CourseDetails_Action_StartCourse: "Pradėti mokymus",
	CourseDetails_Action_RestartCourse: "Mokymus pradėti iš naujo",
	CourseDetails_Action_RepeatCourse_Description: "Pakartokite mokymus, kad atsisiųstumėte sertifikatą!",
	CourseDetails_PartSpecificDetails_Headline: "Konkrečios dalies detalės",
	CourseDetails_PartSpecificDetails_Tab_Part: "Dalis",
	CourseDetails_PartSpecificDetails_Description: "Apibūdinimas",
	CourseDetails_PartSpecificDetails_StartDate: "Pradžios data",
	CourseDetails_PartSpecificDetails_EndDate: "Pabaigos data",
	CourseDetails_PartSpecificDetails_StartTime: "Pradžios data",
	CourseDetails_PartSpecificDetails_EndTime: "Pabaigos data",
	CourseDetails_PartSpecificDetails_Method: "Metodas",
	Dashboard_Team_TableHeader_Name: "Vardas",
	Dashboard_Team_TableHeader_Role: "Vaidmuo",
	Dashboard_Team_TableHeader_Email: "El. paštas",
	Dashboard_Team_TableHeader_PersonalAccount: "Asmeninė paskyra",
	Dashboard_Team_ActionButton_Select: "Pasirinkite",
	Dashboard_Team_PersonalAccount_Activated: "Aktyvuota",
	Dashboard_Team_PersonalAccount_NotActivated: "Neaktyvuota",
	Dashboard_Team_ActionButton_AddMember: "Pridėti narį",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Ieškoti",
	Dashboard_External_TableHeader_Name: "Vardas",
	Dashboard_External_ActionButton_AddNew: "Pridėti naują",
	Dashboard_External_Headline: "Patvirtinta išorinė prieiga",
	Dashboard_Workshops_Headline: "Dirbtuvės",
	Dashboard_Workshops_TableHeader_Company: "Įmonė",
	Dashboard_Workshops_TableHeader_Address: "Adresas",
	Dashboard_Workshops_TableHeader_Postcode: "Pašto kodas",
	Dashboard_Workshops_TableHeader_City: "Miestas",
	Dashboard_Workshops_ActionButton_Select: "Pasirinkti",
	Wallet_ServiceCredits: "Paslaugų kreditai",
	Wallet_Headline_CreditBalance: "Kredito balansas",
	Wallet_Headline_CreditActivity: "Kredito veikla",
	Wallet_Headline_BillingTransactions: "Atsiskaitymo operacijos",
	Wallet_BillingTransaction_TableHeader_Order: "Įsakymas",
	Wallet_BillingTransaction_TableHeader_Date: "Data",
	Wallet_BillingTransaction_TableHeader_Amount: "Suma",
	Wallet_BillingTransaction_TableHeader_Action: "Veiksmas",
	Wallet_CreditActivity_TableHeader_Date: "Data",
	Wallet_CreditActivity_TableHeader_Description: "Apibūdinimas",
	Wallet_CreditActivity_TableHeader_User: "Vartotojas",
	Wallet_CreditActivity_TableHeader_Amount: "Suma",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, visos teisės saugomos",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Produkto sauga (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovacijos, patentai ir licencijos",
	FooterAdditionalLinks_Navigation_Logistics: "Pirkimas ir logistika",
	MenuHeader_MyTraining: "Mano mokymai",
	MenuHeader_TrainingServices: "Mokymų paslaugos",
	MenuHeader_OurLocations: "Mūsų vietos",
	MenuHeader_ContactsUs: "Susisiekite su mumis",
	MenuHeader_Logout: "Atsijungti",
	MenuHeader_FAQ: "Dažnai užduodami klausimai",
	MyProfile_MyBoschId: "Mano Bosch ID",
	MyProfile_AccountDeletion: "Paskyros panaikinimas",
	MyProfile_Edit: "Keisti el. paštą ar slaptažodį",
	MyProfile_Profile: "Mano Bosch",
	MyProfile_EditUserProfile: "Redaguoti profilį",
	BackButton: "Atgal",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Laukiame jūsų skambučio ir mielai jums padėsime 24 valandas per parą, 7 dienas per savaitę.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Įveskite teisingus simbolius, kaip nurodyta aukščiau esančiame laukelyje.",
	ContactUs_Email_MandatoryNotice_V3: "Užpildykite visus privalomus laukus.",
	ContactUs_Email_SendButton: "Siųsti",
	Breadcrumb_TrainingCenters: "Mokymo centrai",
	Training_Centres_Book_Btn: "Registruotis į mokymus",
	Training_centres_booking_code: "Užsakymo kodas",
	Training_centers_header_bar_title: "Mūsų mokymo centrai",
	Training_centers_filter_item: "Centras",
	TeamEdit_PersonalAccountToolTop: "Įjungus šią funkciją darbuotojas gali pasiekti šią svetainę. Vartotojas galės susikurti asmeninę paskyrą ir būti jūsų įmonės paskyros dalimi. Įjungę, galite išsiųsti darbuotojui kvietimą su aktyvinimo kodu. Kvietimas bus išsiųstas el. pašto adresu.",
	TeamEdit_Update: "Įjungus įmonės administratoriaus funkciją, šiam darbuotojui automatiškai suteikiama visa prieiga prie jūsų įmonės paskyros. Norint įjungti šią funkciją, vartotojas turi atsijungti ir grįžti į svetainę, jei vartotojas jau yra prisijungęs.",
	TeamEdit_PersonalAccount: "Leisti asmeninę Bosch Training Solutions puslapio paskyrą",
	TeamEdit_SendInvitation: "Siųsti pakvietimą",
	TeamEdit_Deactivate: "Išjungti",
	TeamEdit_Telephone: "Telefonas",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Mobilusis turi būti skaičiai",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telephonas turi būti skaičiai",
	TeamEdit_Activate: "Aktyvus",
	TeamEdit_NotActivated: "Neaktyvus",
	TeamEdit_StatusPopup: "Būsena bus rodoma kaip „Aktyvus“, jei vartotojas sėkmingai sukūrė asmeninę paskyrą. Jei būsena nustatyta kaip „Neaktyvus“, tai reiškia, kad vartotojas nepasinaudojo Jūsų kvietimu.",
	Training_Catalog_Courses: "Mokymai",
	Training_Catalog_Course: "Mokymai",
	Reset_Filter: "Iš naujo nustatyti visus filtrus",
	TrainingCatalogue_CourseCatalogue: "Mokymų sąrašas",
	CheckoutHeader_CartItemCount_Text: "Jūsų pirkinių krepšelyje yra [count] prekė",
	CheckoutHeader_CartItemsCount_Text: "Jūsų pirkinių krepšelyje yra [count] prekių",
	ShoppingCart_PriceItem: "Prekės kaina",
	ShoppingCartItemParticipants_AddParticipant_Button: "Pridėti dalyvių",
	ShoppingCartTotalPrice_SubTotalLabel: "Tarpinė suma",
	ShoppingCartTotalPrice_TaxesLabel: "Mokesčiai",
	ShoppingCartTotalPrice_TotalLabel: "Viso",
	ShoppingCartTotalPrice_IncludedVATLabel: "Su PVM [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Galimi mokėjimo būdai internetu",
	ShoppingCartItemRow_VenueFeeLabel: "Kaina vienam dalyviui [fee_amount]",
	ShoppingCartItemRow_OnlineLabel: "Prisijungęs",
	AddParticipantsModal_Title: "Pridėti dalyvį",
	AddParticipantsModal_SelectEmployeeLabel: "Pridėti dalyvį",
	AddParticipantsModal_SelectWorkshopLabel: "Pasirinkti dirbtuves",
	ShoppingCartTotalPrice_ContinueButtonText: "Tęsti atsiskaitymą",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Dalyvis nepridėtas!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Jūsų pirkinių krepšelyje yra vienas ar keli kursai be dalyvio.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Jei norite tęsti, pridėkite dalyvį.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Redaguoti dalyvius ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Vardas",
	AddParticipantsModal_ParticipantTable_Email: "El. paštas",
	AddParticipantsModal_ParticipantTable_Action: "Veiksmas",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Dalyvių nėra...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "El. pašto adresas nesukonfigūruotas pasirinktam dalyviui",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Pasirinkto dalyvio profilyje nėra sukonfigūruoto el. pašto adreso. Elektroninio pašto adresas yra privalomas registruojantis į mokymus..",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Jei norite tęsti, pridėkite el. pašto adresą arba atšaukite, kad pasirinktumėte kitą dalyvį.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Pridėti el. paštą",
	CancelButton: "Atšaukti",
	AddParticipantsModal_AddEmployeeButtonText: "Pridėti darbuotoją",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Jūs ketinate ištrinti kursą iš krepšelio",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Bandote pašalinti kursą su pridėtais dalyviais iš savo krepšelio.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Spustelėkite \"Tęsti\", jei norite tęsti, arba spustelėkite \"Atšaukti\".",
	ShoppingCartItemHeader_Participants: "Dalyvis (-iai)",
	ShoppingCartItemHeader_ParticipantPrice: "Kaina asmeniui",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Rodyti [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Rodyti kreditais",
	ShoppingCart_CreditsPriceUnit: "Kreditai",
	CheckOutHeader_Step1_Question: "Kaip norėtumėte mokėti?",
	CheckOutHeader_Step2_Question: "Peržiūrėkite savo užsakymą",
	CheckOutHeader_Step3_Question: "Užpildykite užsakymą",
	CheckOutHeader_Step4_Question: "Jūsų užsakymas įvykdytas",
	CheckOutHeader_Step_Payment: "Mokėjimas",
	CheckOutHeader_Step_Review: "Apžvalga",
	CheckOutHeader_Step_Place_Order: "Pateikti užsakymą",
	CheckOutRightPanel_Review_Order_Btn: "Peržiūrėti užsakymą",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Pateikti užsakymą",
	CheckoutRightPanelContent_OrderSummary_Title: "Užsakymo santrauka",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Redaguoti pirkinių krepšelį",
	ShoppingCart_Participants: "Dalyviai",
	CheckOutStepPayment_Payment_Methods_Title: "Pasirinkite mokėjimo būdą",
	CheckOutStepPayment_Credit_Title: "Kreditas arba debetas",
	CheckOutStepPayment_Wholesaler_Title: "Didmenininkas",
	CheckOutStepPayment_ServiceCredit_Title: "Paslaugų kreditai",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Turite [available_credit] kreditų",
	CheckOutStepPayment_BoschAccount_Title: "Bosch paskyra",
	Checkout_Second_PaymentMethod_Headline: "Apmokėjimo būdas",
	CheckOutStepReview_Edit: "Redaguoti",
	CheckOutStepReview_Terms_Label: "Sąlygos ir atšaukimo politika",
	CheckOutStepReview_Terms_Text: "Perskaičiau, supratau ir sutinku su sąlygomis [pardavimo sąlygos ir atšaukimo politika].",
	SelectSecondPaymentMethodModal_Title: "Pasirinkite antrinį mokėjimo būdą",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Mokėti [amount]",
	StepPaymentWholesaler_Title: "Pasirinkti didmenininką",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Sutinku su sąlygomis ir taisyklėmis",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Prieš tęsdami perskaitykite ir sutikite su mūsų sąlygomis ir atšaukimo taisyklėmis.",
	CheckoutDescription_ThankYou: "Dėkojame, kad pateikėte Bosch Automotive Training Solutions užsakymą!",
	CheckoutDescription_Message: "Mūsų sistema jau pradėjo apdoroti jūsų užsakymą.",
	CheckoutDesctiption_Text: "Mes atsiųsime jums galutinį patvirtinimą paštu, kuriame bus visa informacija apie jūsų užsakymą.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Ketinate pašalinti pasirinktus mokymus",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Pašalinus pasirinktus mokymus, visi pridėti dalyviai bus nustatyti iš naujo. Šis pakeitimas turės įtakos visiems kursams jūsų pirkinių krepšelyje.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Spustelėkite \"Tęsti\", jei norite tęsti, arba spustelėkite \"Atšaukti\", kad sustabdytumėte šį veiksmą.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Jūs neturite pakankamai kreditų. Jūsų kredito likutis yra [available_credit] kreditai, bet jums reikia [cart_total_credits] kreditų, kad galėtumėte tęsti patikrą. Pasirinkite kitą mokėjimo būdą arba išimkite prekes iš pirkinių krepšelio.",
	Training_centers_filter_items: "Centrai",
	Training_Programs_Filter_Item: "Programos",
	Training_Program_Filter_Item: "Programa",
	Training_Program_Year: "Metai",
	Training_Program_Detail_Title: "Mokymų programos",
	AccountButton_Workshops_MenuItem: "Seminarai",
	AccountButton_Wallet_MenuItem: "Piniginė",
	Header_MyBoschProfile_Header: "Mano Bosch profilis",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Yra",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Pridėti / redaguoti dalyvį",
	ShoppingCart_CreditPriceUnit: "Kreditas",
	Common_IncludedVAT: "Įsk. PVM",
	TrainingCatalogue_Length: "Ilgis",
	TrainingCatalogue_Method: "Metodas",
	Course_Price_Credits: "Kreditai",
	Catalogue_Grid_Register_Button: "Registruotis",
	Catalogue_Credit_Tilte: "Spustelėkite čia norėdami pamatyti kainas kreditais",
	Catalogue_Price_Tilte: "Spustelėkite čia norėdami pamatyti kainas Eur",
	Catalogue_Grid_Vat: "Be PVM",
	EventCalendar_Seats: "Vietos",
	MenuHeader_Calendar: "Kalendorius",
	MenuHeader_Catalog: "Katalogas",
	TableCell_Minutes: "Minutės",
	ListFilter_NoResult: "Be rezultato",
	Course_Description: "Mokymų aprašymas",
	Training_course_detail: "Socialinės medijos pagrindai",
	Training_course_detail_add_waiting: "Įtraukti į bendrą laukiančiųjų sąrašą",
	Register_now_btn: "Registruotis dabar!",
	Register_now_btn_add_event_to_cart: "Registruotis dabar!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Peržiūrėti visus įrašus",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Rodyti mažiau",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Peržiūrėti visus įrašus",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Rodyti mažiau",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Peržiūrėti visus įrašus",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Rodyti mažiau",
	TeamCreate_TitleError: "Pavadinimas būtinas",
	Dashboard_Headerbar_BookCourse: "Užsisakyti mokymus",
	Dashboard_Headerbar_ExitProxy: "Išeiti iš tarpinio serverio",
	Dashboard_Headerbar_EvaluateCourse: "Įvertinti mokymus",
	Dashboard_Headerbar_EvaluateCourse_Note: "Įvertinkite mokymus, kad gautumėte sertifikatą!",
	Dashboard_Headerbar_DownloadCertificate: "Atsisiųsti sertifikatą",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Atsitraukti",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Atsitraukti",
	Dashboard_Headerbar_WelcomeMessage: "Sveiki atvykę į mokymosi informacijos suvestinę",
	Dashboard_Headerbar_ProxingAs: "Tarpinis serveris",
	Dashboard_TrainingProgram_Status_Completed: "Baigta",
	Dashboard_TrainingProgram_Status_InProgress: "Vykdoma",
	External_SelectWholesalerModal_Title: "Pridėti išorinio vartotojo prieigą",
	External_SelectWholesalerModal_Company_Label: "Įmonė",
	External_SelectWholesalerModal_Outlet_Label: "Išėjimas",
	External_SelectWholesalerModal_Company_Placeholder: "Pasirinkti",
	External_SelectWholesalerModal_Outlet_Placeholder: "Pasirinkti",
	External_SelectWholesalerModal_ApplyButton: "Taikyti",
	External_SelectWholesalerModal_CancelButton: "Atšaukti",
	External_RemoveWholesalerModal_ConfirmLabel: "Patvirtinimas",
	External_SelectWholesalerModal_ConfirmMessage: "Ar tikrai norite pašalinti šį didmenininką?",
	External_SelectWholesalerModal_YesButton: "Taip",
	External_SelectWholesalerModal_NoButton: "Ne",
	Training_program_team_member: "Komandos narys",
	Training_program_assign_training_program: "Priskirti mokymo programą",
	Assign_modal_student_confirmation_text1: "Jūs ketinate priskirti [Training Program Name] savo mokymų sąrašui.",
	Assign_modal_student_confirmation_text2: "Ar norite tęsti?",
	MyFullCalendar_Jan: "Sausis",
	MyFullCalendar_Feb: "Vasaris",
	MyFullCalendar_Mar: "Kovas",
	MyFullCalendar_Apr: "Balandis",
	MyFullCalendar_May: "Gegužė",
	MyFullCalendar_Jun: "Birželis",
	MyFullCalendar_Jul: "Liepa",
	MyFullCalendar_Aug: "Rugpjūtis",
	MyFullCalendar_Sep: "Rugsėjis",
	MyFullCalendar_Oct: "Spalis",
	MyFullCalendar_Nov: "Lapkritis",
	MyFullCalendar_Dec: "Gruodis",
	MenuHeader_CourseCatalog: "Mokymų katalogas",
	MenuHeader_Event: "Įvykių kalendorius",
	MenuHeader_Program: "Mokymo programa",
	MenuHeader_Services: "Mokymų paslaugos",
	MenuHeader_Log_out: "Atsijungti",
	Filter_Location: "Vieta",
	TeamEdit_UpgradeToCompanyAdministrator: "Redaguoti į įmonės administratorių",
	TeamEdit_PersonalWebAccountStatusIs: "Asmeninės žiniatinklio paskyros būsena yra",
	Header_CreateNewEmployee: "Įtraukti naują darbuotoją",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Nėra vietų!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Pasirinktame renginyje nėra laisvų vietų.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Jei norite tęsti, pasirinkite kitą įvykį arba pašalinkite jau pridėtą dalyvį.",
	Common_SuccessModal_Title: "Sveikiname!",
	TrainingProgramDetails_Merit_StatusText: "Nuopelnai",
	TrainingProgramDetails_Resit_StatusText: "Kvitas",
	EmployeeProfile_UserExistErrorMessage: "Paskyra jau yra!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Mokėjimas nepavyko. Bandykite dar kartą arba naudokite kitą mokėjimo metodą.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Sėkmingai išregistravote pasirinktą darbuotoją!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Išsaugota!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Jūsų pakeitimai išsaugoti.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Atštaukti nuotolinių mokymų negalima. Susisiekite su mumis, jei reikia papildomos informacijos.",
	Dashboard_Team_PersonalAccount_Pending: "Laukiama",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Darbuotojo paskyra sukurta!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Jūsų darbuotojo paskyros kūrimas baigtas.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Šis nustatymas leidžia apriboti darbuotojo registraciją į mokymus. Po šio nustatymo pakeitimo tik įmonės administratoriai galės registruoti darbuotoją į mokymus.",
	Training_catalog_sort_by: "Rūšiuoti pagal",
	CourseDetail_EvaluationRestriction_Title: "Mokymų vertinimas neįmanomas!",
	CourseDetail_EvaluationRestriction_Message: "Mokymus vertinti gali pateikti tik mokymų dalyvis. \n Pakvieskite dalyvį prisijungti prie „Bosch Automotive Training Solutions“ ir užbaigti mokymo vertinimą.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Jei kursų dalyvis neturi paskyros, įmonės vartotojo profilio nustatymuose gali būti bendrinamas kvietimas.",
	Breadcrumb_Download_Page: "Parsisiųsti",
	Download_Table_Category_Cell: "Kategorija",
	Download_Table_Title_Cell: "Pavadinimas",
	Download_File_Filter_Item: "Failas",
	Download_File_Filter_Items: "Failai",
	Download_Header: "Atsisiųsti sritį",
	DownloadArea_Button_Download: "Parsisiųsti",
	Popup_Title_Error: "Klaida",
	Popup_Title_Success: "Sėkmingai",
	Popup_Title_Info: "Informacija",
	Popup_Title_Warning: "Įspėjimas!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Po galimų įvykių registracijos nebegalima panaikinti ir turės būti atšaukta atskirai.",
	TrainingProgram_PDF_Export: "PDF eksportas",
	TrainingProgram_PDF_Export_Participant: "Dalyvis:",
	TrainingProgram_PDF_Export_Status: "Būsena:",
	TrainingProgram_PDF_Export_Progress: "Bendras progresas:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Mokymų pavadinimas",
	TrainingProgram_PDF_Export_TableHeader_Status: "Būsena",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Pažymys",
	TrainingProgram_PDF_Export_TableHeader_Date: "Data",
	AccountButton_CompanyChange_MenuItem: "Bendrovės pokyčiai",
	SelectCompanyModal_Title: "Pasirinkite įmonę",
	CloseButton: "Uždaryti",
	Change_Button: "Pakeisti",
	ChangeManagerModal_Content: "Vartotojo pasirinkimas",
	ChangeCompanyModal_HeadOffice: "(Pagrindinis biuras)",
	SelectCompanyModal_Content: "Norėdami prisijungti, pasirinkite įmonę ...",
	AlertSuccess_Title: "Sėkmingai",
	CheckOutStepPayment_SecondaryPayment_Title: "Pasirinkite antrinį mokėjimo būdą",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Pridėkite antrinį mokėjimo būdą",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Žemiau pridėkite antrinį mokėjimo būdą arba pasirinkite kitą mokėjimo būdą.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Savo sąskaitoje turite nepakankamai paslaugų kreditų, kad galėtumėte sumokėti už krepšelio prekes. Norėdami toliau naudoti pasirinktą mokėjimo metodą, turite sumokėti skirtumą. Skirtumas yra [difference_amount] (be PVM).",
	ShoppingCartTotalPrice_TotalInCredit: "Bendra kreditų kaina",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Jūsų turimi kreditai",
	ShoppingCartTotalPrice_YourDifference: "Skirtumas",
	ShoppingCartTotalPrice_SubtotalExTax: "Suma be mokesčių",
	ShoppingCartTotalPrice_SubtotalInTax: "Suma su mokesčiais",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Antrinis mokėjimo būdas",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Redaguoti mokėjimo būdą",
	Checkout_Second_PaymentMethod: "Antrasis mokėjimo būdas",
	Breadcrumb_TrainingCourseDetails: "Mokymo kursų detalės",
	DownloadArea_FailedToDownload_ErrorMessage: "Nepavyko atsisiųsti dokumento. Pabandykite dar kartą arba susisiekite su savo įmonės administratoriumi.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Nėra galimybės atsisiųsti failą. Pabandykite dar kartą arba susisiekite su savo įmonės administratoriumi.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Atsisiųsti failo neįmanoma. Pabandykite dar kartą arba susisiekite su savo įmonės administratoriumi.",
	SSO_Redirecting_Message: "Nukreipiama į Bosch mokymų portalą...",
	SSO_Redirecting_ParameterErrorMessage: "Šalis ir (arba) kalba nenurodytos. Bandykite dar kartą arba kreipkitės pagalbos į administratorių.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Registracijos užklausa atmesta.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Taip galėjo nutikti dėl neatitikimų dėl registracijos termino neatitikimo mokymams. Jei turite klausimų ar pageidavimų, spauskite „Susisiekite su mumis“.",
	TimeSlotPicker_DateFormat_Title: "Datos formatas",
	UpdateAccountSettingsConfirmationModal_Title: "Ketinate pakeisti datos formatą!",
	UpdateAccountSettingsConfirmationModal_Message1: "Datos formatas bus rodomas pagal jūsų pasirinkimą ir gali būti bet kada pakeistas.",
	UpdateAccountSettingsConfirmationModal_Message2: "Ar norite tęsti?",
	ProfileSummary_Settings_Title: "Nustatymai",
	UpdateAccountSettings_DateFormat_Title: "Datos formato nustatymai",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Nepavyko atnaujinti datos formato.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Datos formato nustatymas sėkmingai atnaujintas.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Atsisiųsti sertifikatą",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Mokymo programos sertifikatas",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Nepavyko atsisiųsti dokumento. Bandykite dar kartą arba kreipkitės pagalbos  administratorių.",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Laukiamas atšaukimo patvirtinimas, nes atšaukimo užklausa peteikta per vėlai. Informacija bus atsiųsta el. paštu, kai prašymas bus patvirtintas arna atmestas.",
	TrainingCourse_Course_NotAvailable_Title: "Mokymo kursų nėra.",
	TrainingCourse_Course_NotAvailable_Message: "Mokymo kursai nepasiekiami. Bandykite dar kartą arba kreipkitės pagalbos į administratorių.",
	TrainingCourse_NotAvailable_Message_1: "Jūs prisijungiate prie mokymo kurso, kuris nepasiekiamas.",
	TrainingCourse_NotAvailable_Message_2: "Bandykite dar kartą arba eikite į [mokymo kursai], kad sužinotumėte daugiau.",
	LanguageSelection_SelectALanguage: "Pasirinkti kalbą",
	LanguageSelection_SelectYourCountry: "Pasirinkti šalį",
	Checkout_PaymentMethod_Headline: "Apmokėjimo būdas",
	Download_Categories_Catalogue: "Katalogas",
	Download_Categories_Calendar: "Kalendorius",
	Download_Categories_General: "Bendra",
	Download_Categories_Information: "Informacija",
	Download_Categories_Userguides: "Vartotojo vadovai",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "atšaukimo politika",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Atšaukti",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Tęsti",
	Duration_Seconds: "Sekundės",
	Duration_Day: "Diena",
	Duration_Hour: "Valanda",
	Duration_Minute: "Minutė",
	Duration_Second: "Antra",
	EventCalendar_ExternalLink_Tooltips: "Pirkite per [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Atšaukti",
	EventCalendar_ExternalLink_Continue: "Tęsti",
	EventCalendar_ExternalLinkModal_Title: "Netrukus būsite peradresuotas...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Pasirinktas įvykis parduodamas per [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Tęsdami būsite nukreipti į išorinę svetainę.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Ar norėtumėte tęsti?",
	Team_ContactPerson_FirstName_Tooltips: "Studentų vardus gali keisti tik Bosch mokymo administravimo komanda. Norėdami gauti daugiau pagalbos, susisiekite su mumis naudodami šioje svetainėje esančią kontaktinę formą.",
	Team_ContactPerson_LastName_Tooltips: "Studentų vardus gali keisti tik Bosch mokymo administravimo komanda. Norėdami gauti daugiau pagalbos, susisiekite su mumis naudodami šioje svetainėje esančią kontaktinę formą.",
	Withdraw_ExternalLink_Cancel: "Atšaukti",
	Withdraw_ExternalLink_Confirm: "Patvirtinti",
	Withdraw_ExternalLinkModal_Title: "Netrukus būsite nukreipti",
	Withdraw_ExternalLinkModal_Message: "Šio kurso at6aukimus tvarko išorinis teikėjas.",
	Withdraw_ExternalLinkModal_Confirmation: "Spustelėjus \"Patvirtinti\", bus atidarytas naujas skirtukas ir nukreipiamas į išorinę svetainę.",
	Button_AddToWaitingList_Tooltips: "Pridėti į laukiančiųjų sąrašą",
	Button_AddToCart_Tooltips: "Pridėti į krepšelį",
	Button_TrainingCourseDetail_Tooltips: "Peržiūrėti išsamią informaciją",
	Button_TrainingProgramDetail_Tooltips: "Peržiūrėti išsamią informaciją",
	Button_AssignTrainingProgram_Tooltips: "Priskirkite mokymo programą",
	Button_DeleteFromCart_Tooltips: "Ištrinti iš krepšelio",
	Button_RemoveParticipant_Tooltips: "Pašalinti dalyvį",
	Button_DownloadBillingTransaction_Tooltips: "Atsisiųsti",
	Button_RemoveExternal_Tooltips: "Pašalinti",
	Breadcrumb_TrainingServices: "Mokymo paslaugos",
	Breadcrumb_Support: "Palaikymas",
	Breadcrumb_FAQs: "DUK",
	Breadcrumb_TrainingProgramDetail: "Detaliau apie mokymo programą",
	Breadcrumb_MyTraining_MyTrainingEvents: "Mano mokymo įvykiai",
	Breadcrumb_MyTraining_External: "Išorinis",
	Breadcrumb_MyTraining_Wallet: "Piniginė",
	Breadcrumb_MyTraining_Workshop: "Dirbtuvės",
	Breadcrumb_MyTraining_Workshop_Team: "Komanda",
	Breadcrumb_MyTraining_Team_Profile: "Profilis",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Redaguoti profilį",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Darbuotojų kūrimas",
	SideNavigation_Title: "Mokymo sprendimai",
	SideNavigation_AccountButton_RegisterOrLogin: "Registruotis / Prisijungti",
	SideNavigation_Language: "Kalba",
	SideNavigation_SelectLanguage: "Pasirinkite kalbą",
	SideNavigation_AccountButton_Logout: "Atsijungti",
	SideNavigation_ConntactUs: "Susisiekite su mumis",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Įmonės informacija",
	FooterAdditionalLinks_Navigation_LegalNotice: "Teisinis pranešimas",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Duomenų apsaugos pranešimas",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Privatumo nustatymai",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Taisyklės ir sąlygos",
	Common_Version_Text: "Versija: {{version}}",
	Content_Header_Dashboard_MyTrainingEvents: "Mano mokymo įvykiai",
	Content_Header_Dashboard_MyTrainingPrograms: "Mano mokymo programos",
	Content_Header_Dashboard_Team: "Komanda",
	Content_Header_Dashboard_Workshops: "Dirbtuvės",
	Content_Header_Dashboard_External: "Išorinis",
	Content_Header_Dashboard_Wallet: "Piniginė",
	Content_Header_Dashboard_MyProfile: "Mano profilis",
	RedeemVoucher_RemoveButton: "Pašalinti",
	RedeemVoucher_ApplyButton: "Taikyti",
	Checkout_VoucherApplied_Label: "Jūsų kodas",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Neteisingas kupono kodas",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Pritaikytas kupono kodas yra neteisingas.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Bandykite dar kartą arba tęskite be kupono kodo.",
	RedeemVoucherModal_CancelButton: "Atšaukti",
	RedeemVoucherModal_TryAgainButton: "Bandykite dar kartą",
	CreditsConfirmationModal_ContinueButton: "Tęsti",
	CreditsConfirmationModal_CancelButton: "Atšaukti",
	CreditsConfirmationModal_Title: "Buvo pritaikytas kuponas",
	CreditsConfirmationModal_Message_1: "Atsiskaityti Paslaugų kreditais negalima, kai į pirkinių krepšelį buvo pritaikytas kuponas su nuolaida.",
	CreditsConfirmationModal_Message_2: "Spustelėkite \"Atšaukti\", kad grįžtumėte atgal, arba spustelėkite \"Tęsti\", kad išimtumėte kuponą iš krepšelio."
};