import axios from "axios";
import {
  Log,
  SignoutResponse,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client-ts";
import RedirectHelper from "../helpers/RedirectHelper";
import { Account } from "../models/Account";
import { RBGeolocation } from "../models/geolocation/RBGeolocation";
import { Region } from "../models/RegionState";
import { AuthState, initialState } from "../redux-toolkit/authReducer";

interface CIAMCallbackData {
  redirectUrl: string;
  guid: string;
}

export class AuthService {
  private static instance: AuthService;

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  public static getNewInstance(): AuthService {
    AuthService.instance = new AuthService();
    return AuthService.instance;
  }

  public userManagerLogin: UserManager;
  public userManagerEditProfile: UserManager;

  constructor() {
    this.userManagerLogin = new UserManager({} as UserManagerSettings);
    this.userManagerEditProfile = new UserManager({} as UserManagerSettings);
    this.SetManagerProfileSettings();
    //Log.logger = console;
    //Log.level = Log.INFO;
  }

  private SetManagerProfileSettings() {
    const authState = AuthService.getAuthState();
    //const clientRoot = RedirectHelper.getClientRoot(authState);
    const clientRoot = process.env.REACT_APP_DEFAULT_CLIENTROOT;
    //const clientId = RedirectHelper.getClientId(authState);
    const keycloakClientScope =
      "profile-dataprotectionnotice-" +
      authState.countryCode.trim().toLowerCase();
    const settings = {
      authority: String(process.env.REACT_APP_DEFAULT_AUTHORITY),
      client_id: String(process.env.REACT_APP_DEFAULT_CLIENTID),
      redirect_uri: `${clientRoot}/signincallback`,
      silent_redirect_uri: `${clientRoot}/silent-refresh`,
      popup_redirect_uri: `${clientRoot}/signin-popup-callback.html`,
      automaticSilentRenew: true,
      post_logout_redirect_uri: `${clientRoot}/signoutcallback`,
      response_type: "code",
      scope: "openid profile " + keycloakClientScope,
      userStore: new WebStorageStateStore({
        prefix: `oidc.bats.`,
        store: sessionStorage,
      }),
      extraQueryParams: {
        kc_idp_hint: String(process.env.REACT_APP_IDPHINT_LOGIN),
      },
    };
    this.userManagerLogin = new UserManager(settings);

    const settingsProfile = {
      authority: String(process.env.REACT_APP_DEFAULT_AUTHORITY),
      client_id: String(process.env.REACT_APP_DEFAULT_CLIENTID),
      redirect_uri: `${clientRoot}/editprofilecallback`,
      silent_redirect_uri: `${clientRoot}/editprofile-silent-refresh`,
      automaticSilentRenew: false,
      post_logout_redirect_uri: `${clientRoot}/signoutcallback`,
      response_type: "code",
      scope: process.env.REACT_APP_DEFAULT_CLIENTSCOPE,
      userStore: new WebStorageStateStore({
        prefix: `oidc.batsProfile.`,
        store: sessionStorage,
      }),
      extraQueryParams: {
        kc_idp_hint: String(process.env.REACT_APP_IDPHINT_EDITPROFILE),
      },
    };
    this.userManagerEditProfile = new UserManager(settingsProfile);

    Log.setLogger(console);
    Log.setLevel(Log.INFO);
  }

  public ReInitialManagerSettings() {
    this.SetManagerProfileSettings();
  }

  public getUser(): Promise<User | null> {
    return this.userManagerLogin.getUser();
  }

  public getUserEditProfile(): Promise<User | null> {
    return this.userManagerEditProfile.getUser();
  }

  public async loginAsync(
    dataRedirect?: CIAMCallbackData | undefined
  ): Promise<void> {
    this.userManagerLogin.clearStaleState();
    return !!dataRedirect
      ? await this.userManagerLogin.signinRedirect({ state: dataRedirect })
      : await this.userManagerLogin.signinRedirect();
  }

  public async loginCallbackAsync(): Promise<User> {
    return await this.userManagerLogin.signinRedirectCallback();
  }

  public renewToken(): Promise<User | null> {
    return this.userManagerLogin.signinSilent();
  }

  public renewTokenCallback(): Promise<User | undefined | void> {
    return this.userManagerLogin.signinSilentCallback();
  }

  public renewTokenEditProfile(): Promise<User | null> {
    return this.userManagerEditProfile.signinSilent();
  }

  public renewTokenEditProfileCallback(): Promise<User | undefined | void> {
    return this.userManagerEditProfile.signinSilentCallback();
  }

  public async logoutAsync(): Promise<void> {
    this.userManagerLogin
      .signinSilent()
      .then(() => {
        this.userManagerLogin.signoutRedirect();
      })
      .catch(() => {
        this.userManagerLogin.signoutRedirect();
      });
  }

  public async logoutCallbackAsync(): Promise<SignoutResponse> {
    return await this.userManagerLogin.signoutRedirectCallback();
  }

  public async editProfileAsync(
    dataRedirect?: CIAMCallbackData | undefined
  ): Promise<void> {
    this.userManagerEditProfile.clearStaleState();
    return !!dataRedirect
      ? await this.userManagerEditProfile.signinRedirect({
          state: dataRedirect,
        })
      : await this.userManagerEditProfile.signinRedirect();
  }

  public async editProfileCallbackAsync(): Promise<User> {
    return await this.userManagerEditProfile.signinRedirectCallback();
  }

  public async clearLoginUserState(): Promise<void> {
    await this.userManagerLogin.clearStaleState();
    await this.userManagerLogin.removeUser();
    await this.userManagerEditProfile.clearStaleState();
    return this.userManagerEditProfile.removeUser();
  }

  public getSimpleAccount(ciamid: string) {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API_KEY}/api/v1/account/GetAccountSimple/${ciamid}`,
      responseType: "stream",
    });
  }

  public createAccount(account: Account) {
    return axios.post(
      `${process.env.REACT_APP_API_KEY}/api/v1/account`,
      account
    );
  }

  public static getAuthState(): AuthState {
    const regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : "REGIONS_STORE";
    let auth = initialState;
    if (!!localStorage.getItem(regionStorageKey))
      auth = JSON.parse(localStorage.getItem(regionStorageKey) as string);
    return auth;
  }

  public static setAuthState(auth: AuthState) {
    let regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : "REGIONS_STORE";
    localStorage.setItem(regionStorageKey, JSON.stringify(auth));
  }

  public static removeAuthState() {
    let regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : "REGIONS_STORE";
    localStorage.removeItem(regionStorageKey);
  }

  public static getRegionsLanguages(): Region[] {
    const regionLanguagesStorageKey = process.env.REACT_APP_REGIONLANGUAGES
      ? process.env.REACT_APP_REGIONLANGUAGES
      : "REGIONSLANGUAGES_STORE";
    let regionLanguages: Region[] = [];
    if (!!localStorage.getItem(regionLanguagesStorageKey))
      regionLanguages = JSON.parse(
        localStorage.getItem(regionLanguagesStorageKey) as string
      );
    return regionLanguages;
  }

  public static setRegionsLanguages(regionlangues: Region[]) {
    let regionLanguagesStorageKey = process.env.REACT_APP_REGIONLANGUAGES
      ? process.env.REACT_APP_REGIONLANGUAGES
      : "REGIONS_STORE";
    localStorage.setItem(
      regionLanguagesStorageKey,
      JSON.stringify(regionlangues)
    );
  }

  public static removeRegionsLanguages() {
    let regionLanguagesStorageKey = process.env.REACT_APP_REGIONLANGUAGES
      ? process.env.REACT_APP_REGIONLANGUAGES
      : "REGIONS_STORE";
    localStorage.removeItem(regionLanguagesStorageKey);
  }

  public static setGeolocation(geolocation: RBGeolocation) {
    let geolocationKey = process.env.REACT_APP_GEOLOCATION
      ? process.env.REACT_APP_GEOLOCATION
      : "BATS_GEOLOCATION_EMPTY";
    localStorage.setItem(geolocationKey, JSON.stringify(geolocation));
  }

  public static getGeolocation(): RBGeolocation | null {
    const geolocationKey = process.env.REACT_APP_GEOLOCATION
      ? process.env.REACT_APP_GEOLOCATION
      : "BATS_GEOLOCATION_EMPTY";
    let geolocation: RBGeolocation | null = null;
    if (!!localStorage.getItem(geolocationKey))
      geolocation = JSON.parse(localStorage.getItem(geolocationKey) as string);
    return geolocation;
  }

  public static removeGeolocation() {
    let geolocationKey = process.env.REACT_APP_GEOLOCATION
      ? process.env.REACT_APP_GEOLOCATION
      : "BATS_GEOLOCATION_EMPTY";
    localStorage.removeItem(geolocationKey);
  }
}
