import axios, { AxiosError } from "axios";
import { AuthService } from "./AuthService";
const qs = require("qs");

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: AxiosError | null, token: string) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

http.interceptors.request.use(
  (config) => {
    const authService = AuthService.getInstance();
    return authService.getUser().then((user) => {
      if (user && user.access_token) {
        config.headers.common["Authorization"] = "Bearer " + user.access_token;
      } else {
        authService.loginAsync();
      }
      return config;
    });
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const authService = AuthService.getInstance();
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return http(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        let isLoginAgain = false;
        authService
          .renewToken()
          .then((newUser) => {
            originalRequest.headers["Authorization"] =
              "Bearer " + newUser?.access_token;
            processQueue(null, newUser?.access_token as string);
            resolve(http(originalRequest));
          })
          .catch((err) => {
            processQueue(err, "");
            reject(err);
            isLoginAgain = true;
          })
          .finally(() => {
            isRefreshing = false;
            if (isLoginAgain) {
              authService.loginAsync();
            }
          });
      });
    }
    if (error.response?.status === 403) {
      authService.loginAsync();
    }
    return Promise.reject(error);
  }
);

/*http.interceptors.response.use(
  response => {
    const authService = AuthService.getInstance();    
    if(response.status === 403 || response.status === 401) {
      return authService.renewToken().then(reNewUser => {              
        response.config.headers.common['Authorization'] = 'Bearer ' + reNewUser.access_token;
        return http(response.config);
      });   
    }
    return response;
  },
  error => {
    const authService = AuthService.getInstance();
    const originalRequest = error.config;    
    if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true; 
      try {        
        if(authService.userManagerLogin)
        return authService.renewToken().then(reNewUser => {          
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + reNewUser.access_token;
          return http(originalRequest);
        });        
      }
      catch (e) {        
        window.location.href = "/sessiontimeout";
      }     
    }
    return Promise.reject(error);
  }
);
*/

// export default http;
