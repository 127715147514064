import { FC, useEffect } from "react";
import "./CustomLoader.css";

const CustomLoader: FC<any> = (props: any) => {
  useEffect(() => {
    //  getActiveTickets();
    if (props.enable === true) {
      showLoadPanel();
    } else {
      hideLoadPanel();
    }
  }, [props]);

  function showLoadPanel() {
    let myTarget: any = document.getElementById("loadingPanel");
    myTarget.classList.add("show");
  }

  function hideLoadPanel() {
    let myTarget: any = document.getElementById("loadingPanel");
    myTarget.classList.remove("show");
  }

  return (
    <div id="loadingPanel">
      <div className="a-activity-indicator" aria-live="off">
        <div className="a-activity-indicator__top-box"></div>
        <div className="a-activity-indicator__bottom-box"></div>
      </div>
    </div>
  );
};

export default CustomLoader;
