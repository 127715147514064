import { FC, useState } from "react";
import AssignModal from "./AssignModal";
import { useSelector } from "react-redux";
import {
  selectAllowSelfEnroll,
  selectOnSubWorkshopBranch,
} from "../../redux-toolkit/accountReducer";
import { selectIsSignIn } from "../../redux-toolkit/authReducer";
import {
  selectProgram,
  setIsForceReloadAssignedTrainingPrograms,
} from "../../redux-toolkit/trainingProgramReducer";
import { useTranslation } from "react-i18next";
import InformationModal from "../InformationModal/InformationModal";
import { AuthService } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import style from "./AssignButton.module.css";
import Boschicon from "../BoschIcon/Boschicon";
import RDButton from "../RDComponents/RDButton";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { Popup } from "semantic-ui-react";
interface IAssignButtonProps {
  isHeader: boolean;
}

const AssignButton: FC<IAssignButtonProps> = (props) => {
  const allowSelfEnroll = useSelector(selectAllowSelfEnroll);
  const onSubWorkshopBranch = useSelector(selectOnSubWorkshopBranch);
  const program = useSelector(selectProgram);
  const isSignIn = useSelector(selectIsSignIn);
  const [isShowError, setIsShowError] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [assign, setAssign] = useState(false);
  const history = useHistory();
  const authService: AuthService = AuthService.getInstance();
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const closeSuccessModal = () => {
    setIsShowSuccessModal(false);
  };

  const assignProgram = async () => {
    if (isSignIn) {
      setAssign(true);
    } else {
      await authService.loginAsync({
        redirectUrl: "/training-program",
        guid: "",
      });
    }
  };

  const closeAssignModal = (isRedirect: boolean) => {
    if (isRedirect) {
      history.push("/dashboard/my-training-program");
    }
    setAssign(false);

    dispatchReduxToolkit(setIsForceReloadAssignedTrainingPrograms(true));
  };
  return (
    <>
      {allowSelfEnroll && (
        <Popup
          size="tiny"
          content={t("Button_AssignTrainingProgram_Tooltips")}
          trigger={
            <RDButton
              primary
              onClick={assignProgram}
              disabled={!allowSelfEnroll || onSubWorkshopBranch}
              title={
                onSubWorkshopBranch ? t("EventTable_HeadOfficeRequire") : ""
              }
              className={`${style.button} ${style.ui} ${
                props.isHeader
                  ? style.assign_button_header
                  : style.assign_button
              }`}
            >
              <Boschicon name="shopping-cart" />
              {props.isHeader && <div>{t("TrainingProgram_Assign")}</div>}
            </RDButton>
          }
        />
      )}
      <AssignModal
        item={program}
        showAssignDialogBox={assign}
        closePopup={closeAssignModal}
      />
      {isShowError && (
        <InformationModal
          type="error"
          informationMessage={t("TrainingProgram_SelfAssign_Enrolled")}
          popupTitle={t("Common_Error_Title")}
          isDisplayModal={isShowError}
          setCloseModalTrigger={() => setIsShowError(false)}
        />
      )}
      {isShowSuccessModal && (
        <InformationModal
          type="success"
          informationMessage={`${t(
            "AssignModal_SelfAssign_SuccessModal_Message"
          )}`}
          popupTitle={t("AssignModal_SuccessModal_Header")}
          isDisplayModal={isShowSuccessModal}
          setCloseModalTrigger={closeSuccessModal}
        />
      )}
    </>
  );
};

export default AssignButton;
