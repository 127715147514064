import { SharedRouteConfigure } from "../../models/Route";

/**
 * SHARED ROUTES
 * Do not import Shared routes to any routing configures
 */

export const HOME_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/",
  name: "Home",
  title: "Bosch Automotive Training Solutions",
  crumb: "Breadcrumb_Home",
  icon: "Home",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
    },
  ],
};

export const TRAINING_SERVICES_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/training-services",
  name: "Training Services",
  title: "Training Services",
  crumb: "Breadcrumb_TrainingServices",
  icon: "Training Services",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TrainingServices",
    },
  ],
};

export const TRAINING_COURSE_CATALOGUE_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/training-course-catalogue",
  name: "Training Course Catalogue",
  title: "Training Course Catalogue",
  crumb: "Breadcrumb_TrainingCourseCatalogue",
  icon: "Training Course Catalogue",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TrainingCourseCatalogue",
    },
  ],
};

export const COURSE_DETAIL_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/course-detail",
  name: "Training Course Details",
  title: "Training Course Details",
  crumb: "Breadcrumb_TrainingCourseDetails",
  icon: "Training Course Details",
  exact: false,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TrainingCourseCatalogue",
      path: TRAINING_COURSE_CATALOGUE_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_TrainingCourseDetails",
    },
  ],
};

export const TRAINING_COURSE_DETAIL_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/course/:country/:id",
  name: "Training Course Details",
  title: "Training Course Details",
  crumb: "Breadcrumb_TrainingCourseDetails",
  icon: "Training Course Details",
  exact: false,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TrainingCourseCatalogue",
      path: TRAINING_COURSE_CATALOGUE_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_TrainingCourseDetails",
    },
  ],
};

export const EVENT_CALENDAR_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/event-calendar",
  name: "Event Calendar",
  title: "Event Calendar",
  crumb: "Breadcrumb_EventCalendar",
  icon: "Event Calendar",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_EventCalendar",
    },
  ],
};

export const TRAINING_PROGRAM_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/training-program",
  name: "Training Program",
  title: "Training Program",
  crumb: "Breadcrumb_TrainingProgram",
  icon: "Training Program",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TrainingProgram",
    },
  ],
};

export const TRAINING_PROGRAM_DETAIL_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/training-program/training-program-detail",
  name: "Training Program Detail",
  title: "Training Program Detail",
  crumb: "Breadcrumb_TrainingProgramDetail",
  icon: "Training Program Detail",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TrainingProgram",
      path: TRAINING_PROGRAM_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_TrainingProgramDetail",
    },
  ],
};

export const TRAINING_CENTERS_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/training-centers",
  name: "Training Centers",
  title: "Training Centers",
  crumb: "Breadcrumb_TrainingCenters",
  icon: "Training Centers",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TrainingCenters",
    },
  ],
};

export const RECOMMEND_HOTELS_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/training-centers/recommended-hotel",
  name: "Recommended Hotels",
  title: "Recommended Hotels",
  crumb: "Breadcrumb_RecommendedHotels",
  icon: "Recommended Hotels",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_TrainingCenters",
      path: TRAINING_CENTERS_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_RecommendedHotels",
    },
  ],
};

export const CONTACT_US_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/contacts",
  name: "Contact",
  title: "Contact",
  crumb: "Breadcrumb_Contact",
  icon: "Contact",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Contact",
    },
  ],
};

export const STORIES_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/stories",
  name: "Stories",
  title: "stories",
  crumb: "Breadcrumb_Stories",
  icon: "stories",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Stories",
    },
  ],
};

export const STORIES_BOSCH_SERVICE_TRAINING_SHARED_ROUTE: SharedRouteConfigure =
  {
    path: "/stories/bosch-service-training",
    name: "Bosch Service Training",
    title: "Bosch Service Training",
    crumb: "Breadcrumb_Stories_BoschServiceTraining",
    icon: "bosch-training-service",
    exact: true,
    isProtected: false,
    permission: "",
    breadcrumbs: [
      {
        label: "Breadcrumb_Home",
        path: "/",
      },
      {
        label: "Breadcrumb_Stories",
        path: STORIES_SHARED_ROUTE.path,
      },
      {
        label: "Breadcrumb_Stories_BoschServiceTraining",
      },
    ],
  };

export const STORIES_A_DAY_IN_LIFE_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/stories/a-day-in-the-life-of-a-trainer",
  name: "A day in the life of a trainer",
  title: "A day in the life of a trainer",
  crumb: "Breadcrumb_Stories_ADayInLife",
  icon: "A day in the life of a trainer",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Stories",
      path: STORIES_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_Stories_ADayInLife",
    },
  ],
};

export const STORIES_TRAINING_BENEFIT_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/stories/bosch-training-benefits",
  name: "Bosch Training Benefits",
  title: "Bosch Training Benefits",
  crumb: "Breadcrumb_Stories_BoschTrainingBenefits",
  icon: "Bosch Training Benefits",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Stories",
      path: STORIES_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_Stories_BoschTrainingBenefits",
    },
  ],
};

export const STORIES_MODERN_TRAINING_APPROACH_SHARED_ROUTE: SharedRouteConfigure =
  {
    path: "/stories/a-modern-training-approach",
    name: "A modern training approach",
    title: "A modern training approach",
    crumb: "Breadcrumb_Stories_TrainingApproach",
    icon: "A modern training approach",
    exact: true,
    isProtected: false,
    permission: "",
    breadcrumbs: [
      {
        label: "Breadcrumb_Home",
        path: "/",
      },
      {
        label: "Breadcrumb_Stories",
        path: STORIES_SHARED_ROUTE.path,
      },
      {
        label: "Breadcrumb_Stories_TrainingApproach",
      },
    ],
  };

export const SSO_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/sso",
  name: "SSO",
  title: "SSO",
  crumb: "SSO",
  icon: "SSO",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "SSO",
    },
  ],
};

export const SUPPORT_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/support",
  name: "Support",
  title: "Support",
  crumb: "Breadcrumb_Support",
  icon: "Support",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Support",
    },
  ],
};

export const FAQ_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/support",
  name: "FAQs",
  title: "FAQs",
  crumb: "Breadcrumb_FAQs",
  icon: "FAQs",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_FAQs",
    },
  ],
};

export const DOWNLOAD_AREA_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/download-files",
  name: "Download Page",
  title: "Download Page",
  crumb: "Breadcrumb_Download_Page",
  icon: "Download Page",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Download_Page",
    },
  ],
};

export const MY_PROFILE_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/myprofile",
  name: "My Profile",
  title: "My Profile",
  crumb: "MyProfile_Profile",
  icon: "My Profile",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "MyProfile_Profile",
    },
  ],
};

export const JOIN_COMPANY_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/my-dashboard/activate-account",
  name: "Active Account",
  title: "Active Account",
  crumb: "Active Account",
  icon: "Active Account",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_CompanyAdministration",
    },
  ],
};

export const MY_SHOPPING_CART_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/shopping-cart",
  name: "Shopping Cart",
  title: "Shopping Cart",
  crumb: "Breadcrumb_ShoppingCart",
  icon: "Shopping Cart",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_ShoppingCart",
    },
  ],
};

export const CHECK_OUT_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/checkout",
  name: "Check Out",
  title: "Check Out",
  crumb: "Breadcrumb_Checkout",
  icon: "Check Out",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Checkout",
    },
  ],
};

/**
 * DASHBOARD ROUTES
 * MyTraining
 */
export const DASHBOARD_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/:activetab?",
  name: "MyTraining",
  title: "My Dashboard",
  crumb: "Breadcrumb_MyTraining",
  icon: "My Dashboard",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
    },
  ],
};

export const DASHBOARD_MY_TRAINING_EVENT_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/my-training-events",
  name: "MyTraining",
  title: "My Dashboard",
  crumb: "Breadcrumb_MyTraining",
  icon: "My Dashboard",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_MyTrainingEvents",
    },
  ],
};

export const DASHBOARD_CURRICULA_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/my-training-program",
  name: "Curricula",
  title: "Curricula",
  crumb: "Breadcrumb_MyTraining_Curricula",
  icon: "Curricula",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Curricula",
    },
  ],
};

export const DASHBOARD_TEAM_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/my-employees",
  name: "Team",
  title: "Team",
  crumb: "Breadcrumb_MyTraining_Team",
  icon: "Team",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Team",
    },
  ],
};

export const DASHBOARD_TEAM_CREATE_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/my-employees/employee-creation",
  name: "Employee Administration",
  title: "Employee Administration",
  crumb: "Breadcrumb_MyTraining_Workshop_Team_CreateEmployee",
  icon: "Employee Administration",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Team",
      path: DASHBOARD_TEAM_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Workshop_Team_CreateEmployee",
    },
  ],
};

export const DASHBOARD_TEAM_EDIT_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/edit-profile",
  name: "Profile",
  title: "Profile",
  crumb: "Breadcrumb_MyTraining_Team_Profile",
  icon: "Profile",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Team",
      path: DASHBOARD_TEAM_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Team_Profile",
    },
  ],
};

export const DASHBOARD_EXTERNAL_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/my-suppliers",
  name: "External",
  title: "External",
  crumb: "Breadcrumb_MyTraining_External",
  icon: "External",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_External",
    },
  ],
};

export const DASHBOARD_WORKSHOP_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/my-workshops",
  name: "Workshop",
  title: "Workshop",
  crumb: "Breadcrumb_MyTraining_Workshop",
  icon: "Workshop",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Workshop",
    },
  ],
};

export const DASHBOARD_WORKSHOP_TEAM_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/workshop/team",
  name: "Workshop Team",
  title: "Workshop Team",
  crumb: "Breadcrumb_MyTraining_Workshop_Team",
  icon: "Workshop Team",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Workshop",
      path: DASHBOARD_WORKSHOP_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Workshop_Team",
    },
  ],
};

export const DASHBOARD_WORKSHOP_TEAM_ADD_MEMBER_SHARED_ROUTE: SharedRouteConfigure =
  {
    path: "/dashboard/my-workshops/my-employees/employee-creation",
    name: "Employee Administration",
    title: "Employee Administration",
    crumb: "Breadcrumb_MyTraining_Workshop_Team_CreateEmployee",
    icon: "Employee Administration",
    exact: true,
    isProtected: true,
    permission: "",
    breadcrumbs: [
      {
        label: "Breadcrumb_Home",
        path: "/",
      },
      {
        label: "Breadcrumb_MyTraining",
        path: DASHBOARD_SHARED_ROUTE.path,
      },
      {
        label: "Breadcrumb_MyTraining_Workshop",
        path: DASHBOARD_WORKSHOP_SHARED_ROUTE.path,
      },
      {
        label: "Breadcrumb_MyTraining_Workshop_Team",
        path: DASHBOARD_WORKSHOP_TEAM_SHARED_ROUTE.path,
      },
      {
        label: "Breadcrumb_MyTraining_Workshop_Team_CreateEmployee",
      },
    ],
  };

export const DASHBOARD_WORKSHOP_TEAM_EDIT_PROFILE_SHARED_ROUTE: SharedRouteConfigure =
  {
    path: "/dashboard/edit-profile",
    name: "Edit Account",
    title: "Edit Account",
    crumb: "Breadcrumb_MyTraining_Workshop_Team_EditProfile",
    icon: "Edit Account",
    exact: true,
    isProtected: true,
    permission: "",
    breadcrumbs: [
      {
        label: "Breadcrumb_Home",
        path: "/",
      },
      {
        label: "Breadcrumb_MyTraining",
        path: DASHBOARD_SHARED_ROUTE.path,
      },
      {
        label: "Breadcrumb_MyTraining_Workshop",
        path: DASHBOARD_WORKSHOP_SHARED_ROUTE.path,
      },
      {
        label: "Breadcrumb_MyTraining_Workshop_Team",
        path: DASHBOARD_WORKSHOP_TEAM_SHARED_ROUTE.path,
      },
      {
        label: "Breadcrumb_MyTraining_Workshop_Team_EditProfile",
      },
    ],
  };

export const DASHBOARD_WALLET_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/my-wallet",
  name: "Wallet",
  title: "Wallet",
  crumb: "Breadcrumb_MyTraining_Wallet",
  icon: "Wallet",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Wallet",
    },
  ],
};

export const MY_TRAINING_COURSE_DETAIL_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/mytraining/course-details",
  name: "Course details",
  title: "Course details",
  crumb: "Breadcrumb_MyTraining_CourseDetails",
  icon: "Course details",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_CourseDetails",
    },
  ],
};

export const MY_TRAINING_PROGRAM_DETAIL_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/mytraining/curricula/training-program-details",
  name: "Training program details",
  title: "Training program details",
  crumb: "Breadcrumb_MyTraining_Curricula_TrainingProgramDetails",
  icon: "Training program details",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "Breadcrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_MyTraining",
      path: DASHBOARD_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Curricula",
      path: DASHBOARD_CURRICULA_SHARED_ROUTE.path,
    },
    {
      label: "Breadcrumb_MyTraining_Curricula_TrainingProgramDetails",
    },
  ],
};
