import AccountButton from "../../features/header/accountButton/AccountButton";
import CartButton from "../../features/header/cartButton/CartButton";
import ContactButton from "../../features/header/contactButton/ContactButton";

const HeaderActions = () => {
  return (
    <>
      <ContactButton />
      <CartButton />
      <AccountButton />
    </>
  );
};

export default HeaderActions;
