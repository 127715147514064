import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnyARecord } from 'dns';
import { Account, EmployeeAccount } from '../models/Account';
import { TrainingEvent, TrainingModule, TrainingProgram } from '../models/TrainingEvent';
import { getMyTrainingEventsAsync } from './dashboardReducer';
import { RootState } from './store';
import { getAssignedTrainingProgramsByStudentAsync } from './trainingProgramReducer';
import { AdminWorkshopDisplayProps } from '../Common-components/CustomTable/CompanyAdministrationTable';
import { logoutCallbackAsync } from './accountReducer';
import { isEnrollmentValid } from '../hooks/useEnrollmentStatus';

interface TrainingProgramState{
    trainingPrograms: TrainingProgram[],
    selectedTrainingProgram: TrainingProgram | null,
    activeModule: TrainingModule | null
}

interface ContactTypeState{
    contactType: string,
    id: number,
    language: string,
    localized: string,
}

interface EmployeeDashboardState {
    employee: EmployeeAccount|null;
    [x: string]: any;
    trainingEvents: TrainingEvent[],
    myCoursesActiveTab: number,
    trainingProgramState: TrainingProgramState,
    employeeId:number|null,
    guid:string;
    customerId:number|null,
    contactType: ContactTypeState|null,
    firstName: string,
    lastName: string,
    proxyMode: boolean,
    wholesalerProxyMode: boolean,
    employeeCiamId: string,
    isActive: boolean,
    selectedWorkshop: AdminWorkshopDisplayProps|null
}

const initialState: EmployeeDashboardState = {
    employee: null,
    trainingEvents: [],
    myCoursesActiveTab: 0,
    trainingProgramState: {
      trainingPrograms: [],
      activeModule: null,
      selectedTrainingProgram: null
    },
    employeeId:null,
    guid:'',
    customerId:null,
    contactType:null,
    firstName:'',
    lastName:'',
    proxyMode: false,
    wholesalerProxyMode: false,
    selectedWorkshop: null,
    employeeCiamId: "",
    isActive: false,
};

export const DashboardEmployeeSlice = createSlice({
    name:'dashboardEmployee',
    initialState,
    reducers:{
        updateEmployee:(state,action:any)=>{
            return {
                ...state,
                employee: action?.payload.employee,
                employeeId: (action?.payload?.id || null),
                employeeCiamId: (action?.payload?.ciamId || null),
                guid: (action?.payload?.guid || null),
                customerId: (action?.payload?.customerId || null),
                contactType: (action?.payload?.contactType || null),
                firstName: action?.payload.firstName,
                lastName: action?.payload.lastName,
                isActive: action?.payload.isActive,
            }
        },
        switchProxyMode: (state, action) => {
          return {
            ...state,
            proxyMode: (action?.payload)
          }
        },
        switchWholesalerProxyMode: (state, action) => {
          return {
            ...state,
            wholesalerProxyMode: (action?.payload)
          }
        },
        switchMyCoursesActiveTab: (state, action) => {
            return {
              ...state,
              myCoursesActiveTab: (action?.payload || 0)
            }
          },
        updateMyCourses: (state, action) => {
          return {
            ...state,
            trainingEvents: (action?.payload || [])
          }
        },
        updateSelectedProgram: (state, action) => {
          return {
            ...state,
            trainingProgramState: {
              ...state.trainingProgramState,
              selectedTrainingProgram: action?.payload
            }
          }
        },
        updateActiveModule: (state, action) => {
          return {
            ...state,
            trainingProgramState: {
              ...state.trainingProgramState,
              activeModule: action?.payload
            }
          }
        },
        turnOfAllProxy: (state) => {
          return {
            ...state,
            wholesalerProxyMode: false,
            proxyMode: false,
          }
        },
        setSelectedWorkshop: (state, action) => {
          return {
            ...state,
            selectedWorkshop: (action?.payload)
          }
        },
    },
    extraReducers: (builder) => {
        builder
          .addCase(getMyTrainingEventsAsync.fulfilled, (state, { payload }) => {
            if (payload != null && payload.httpStatusCode === 200) {
              const trainingEvents = payload.dataObject as TrainingEvent[];
              return {
                ...state,
                trainingEvents: trainingEvents.filter(x => isEnrollmentValid(x))
              }
            }
          })
          .addCase(getAssignedTrainingProgramsByStudentAsync.fulfilled, (state, { payload }) => {
            if (!!payload) {
              const trainingPrograms = payload as TrainingProgram[];
              return {
                ...state,
                trainingProgramState: {
                  trainingPrograms: trainingPrograms,
                  activeModule: state?.trainingProgramState?.activeModule,
                  selectedTrainingProgram: state?.trainingProgramState?.selectedTrainingProgram
                }
              }
            }
          })
          .addCase(logoutCallbackAsync.fulfilled, (state, { payload }) => {
            return {
              ...initialState
            }
          });
      },
})


export const selectSelectedEmployee = (rootState: RootState) => rootState.dashboardEmployeeReducer.employee;
export const selectEmployeeContactType = (rootState: RootState) => rootState.dashboardEmployeeReducer.contactType;
export const selectIsEmployeeActive = (rootState: RootState) => rootState.dashboardEmployeeReducer.isActive;
export const selectEmployeeId = (rootState: RootState) => rootState.dashboardEmployeeReducer.employeeId;
export const selectEmployeeCiamId = (rootState: RootState) => rootState.dashboardEmployeeReducer.employeeCiamId;
export const selectProxyMode = (rootState: RootState) => rootState.dashboardEmployeeReducer.proxyMode;
export const selectWholesalerProxyMode = (rootState: RootState) => rootState.dashboardEmployeeReducer.wholesalerProxyMode;
export const selectGuid = (rootState: RootState) => rootState.dashboardEmployeeReducer.guid;
export const selectCustomerId = (rootState: RootState) => rootState.dashboardEmployeeReducer.customerId;
export const selectSelectedTrainingProgram = (rootState: RootState) => rootState.dashboardEmployeeReducer.trainingProgramState.selectedTrainingProgram;
export const selectTrainingPrograms = (rootState: RootState) => rootState.dashboardEmployeeReducer.trainingProgramState?.trainingPrograms;
export const selectFullEmployeeName = (rootState: RootState) => rootState.dashboardEmployeeReducer.firstName + " " + rootState.dashboardEmployeeReducer.lastName;
export const selectSelectedWorkshop = (rootState: RootState) => rootState.dashboardEmployeeReducer.selectedWorkshop;
export const {turnOfAllProxy, updateEmployee, switchProxyMode, switchWholesalerProxyMode, switchMyCoursesActiveTab, updateMyCourses, updateSelectedProgram, updateActiveModule, setSelectedWorkshop} = DashboardEmployeeSlice.actions;
export default DashboardEmployeeSlice.reducer;