import { FC } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import Boschicon from "../BoschIcon/Boschicon";
import Styles from "./RDDropdown.module.css";

interface RDDropdownProps extends DropdownProps {
  title?: string;
  errorMessage?: string;
  testId?: string;
}

const RDDropdown: FC<RDDropdownProps> = (props) => {
  const { title, errorMessage, testId, ...restProps } = props;

  return (
    <>
      <div className={Styles.rd_input_group}>
        {!!title ? <span>{title}</span> : <></>}
        <Dropdown
          {...restProps}
          selectOnBlur={false}
          header={
            props.header && <div className={Styles.header}>{props.header}</div>
          }
          className={`${Styles.dropdown} ${
            !!title ? Styles.dropdown_with_title : ""
          } ${props.className ?? ""}`}
          icon={
            <div className={Styles.rd_icon}>
              <Boschicon name="down" />
            </div>
          }
          data-testid={testId || ""}
        />
      </div>
      {!!errorMessage && (
        <span className={Styles.error}>
          <Boschicon name="bosch-ic-alert-error" />
          {errorMessage}
        </span>
      )}
    </>
  );
};

export default RDDropdown;
