import { RouteConfigure } from "../models/Route";
import {
  EXCEPTION_ROUTE,
  TERM_AND_CONDITION_ROUTE,
  CORPORATION_INFO_ROUTE,
  LEGAL_NOTICE_ROUTE,
  DATA_PROTECTION_NOTICE_ROUTE,
  EDIT_PROFILE_ROUTE,
} from "../constants/routes/defaultRoutes.constants";

export const defaultRouterConfig: RouteConfigure[] = [
  EXCEPTION_ROUTE,
  TERM_AND_CONDITION_ROUTE,
  CORPORATION_INFO_ROUTE,
  LEGAL_NOTICE_ROUTE,
  DATA_PROTECTION_NOTICE_ROUTE,
  EDIT_PROFILE_ROUTE,
];
