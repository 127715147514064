import { FC, Fragment, useEffect, useState } from "react";
import style from "./SSOPage.module.css";
import { AuthService } from "../../../services/AuthService";
import RedirectHelper from "../../../helpers/RedirectHelper";
import { AppDispatch, useAppDispatch } from "../../../redux-toolkit/store";
import {
  getGeolocationAsync,
  getRegionsAsync,
  setBatsRegions,
} from "../../../redux-toolkit/regionReducer";
import {
  AuthState,
  authFetchState,
  authSetNewState,
} from "../../../redux-toolkit/authReducer";
import i18n from "i18next";
import { Region } from "../../../models/RegionState";
import { findWindows } from "windows-iana";
import ct from "countries-and-timezones";
import { useTranslation } from "react-i18next";

const SSOPage: FC<any> = () => {
  const { t } = useTranslation();
  const [regions, setRegions] = useState<Region[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const country = window.location.search !== "" ? urlParams.get("c") : "";
  const originCountry =
    window.location.search !== "" ? urlParams.get("oc") : "";
  const lang = window.location.search !== "" ? urlParams.get("l") : "";
  const authService: AuthService = AuthService.getInstance();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const onSetAuthState = async (region: Region | undefined) => {
    if (!!country && country !== "" && !!region) {
      let timeZoneId = "";
      if (country.trim().toLowerCase() === "uk") {
        const countryName = ct.getCountry("GB");
        const timeZoneName = countryName?.timezones[0];
        if (timeZoneName !== undefined) {
          timeZoneId = findWindows(timeZoneName)[0];
        }
      } else {
        const countryName = ct.getCountry(country);
        const timeZoneName = countryName?.timezones[0];
        if (timeZoneName !== undefined) {
          timeZoneId = findWindows(timeZoneName)[0];
        }
      }
      let defaultLanguage = !!region
        ? region.languages.find((lan) => lan.defaultLanguage)
        : null;
        let authLanguage = !!defaultLanguage ? defaultLanguage.lcid : lang;
      if (!!originCountry && originCountry.trim().toLowerCase() === "sl")
        authLanguage = "hr-SL";

      const newAuthState = {
        domain: region.url,
        language: authLanguage,
        countryCode: country,
        originCountryCode: originCountry,
        countryName: region.countryName,
        clientId: region.clientId,
        timeZoneId: timeZoneId,
        languages: region.languages,
        dateFormat: region.dateFormat,
      } as AuthState;
      AuthService.setAuthState(newAuthState);
      await dispatchReduxToolkit(authSetNewState(newAuthState));
      i18n.changeLanguage(!!authLanguage ? authLanguage : "EN");
    } else {
      await dispatchReduxToolkit(authFetchState());
    }
  };

  useEffect(() => {
    if (!!country && !!lang) {
      const fetchRegions = async () => {
        //Get Regions Languages
        let regions = AuthService.getRegionsLanguages();
        let regionSelection = null;
        if (regions.length === 0) {
          let resGetRegions = await dispatchReduxToolkit(getRegionsAsync());
          if (getRegionsAsync.fulfilled.match(resGetRegions)) {
            if (!!resGetRegions.payload && !!resGetRegions.payload.dataObject) {
              AuthService.setRegionsLanguages(
                !!resGetRegions.payload.dataObject
                  ? resGetRegions.payload.dataObject
                  : []
              );
              regions = resGetRegions.payload.dataObject;
              await dispatchReduxToolkit(
                setBatsRegions(resGetRegions.payload.dataObject)
              );
            }
          }
        }
        let authState = AuthService.getAuthState();
        //First Time Access The Website
        if (
          authState.countryCode === "" &&
          country?.trim().toLowerCase().length === 0 &&
          !window.location.href.toLowerCase().includes("/regions") &&
          !window.location.href
            .toLowerCase()
            .includes("/registerpersonalaccount")
        ) {
          let resGeoLocation = await dispatchReduxToolkit(
            getGeolocationAsync()
          );
          if (getGeolocationAsync.fulfilled.match(resGeoLocation)) {
            if (
              !!resGeoLocation.payload &&
              resGeoLocation.payload.success &&
              !!resGeoLocation.payload.dataObject
            ) {
              AuthService.setGeolocation(resGeoLocation.payload.dataObject);
              let countryCodeGeolocation =
                resGeoLocation.payload.dataObject.countryCode;
              regionSelection = regions.find(
                (x) =>
                  x.country.trim().toLowerCase() ===
                  countryCodeGeolocation.trim().toLowerCase()
              );
              if (!!regionSelection) {
                const countryCodeCheck = !!regionSelection
                  ? regionSelection.country.trim().toUpperCase()
                  : "";
                const language = !!regionSelection
                  ? regionSelection.languages.find((l) => l.defaultLanguage)
                  : null;
                if (countryCodeCheck.length > 0) {
                  switch (countryCodeCheck) {
                    case "BA":
                      RedirectHelper.redirectBasedOnGeolocation("HR", "hr");
                      break;
                    case "SL":
                      RedirectHelper.redirectBasedOnGeolocation("HR", "sl");
                      break;
                    default:
                      RedirectHelper.redirectBasedOnGeolocation(
                        countryCodeCheck,
                        !!language ? language.lcid : ""
                      );
                      break;
                  }
                }
              } else {
                window.location.href = "/regions";
              }
            }
          }
        }

        //Setup based on country in url
        if (
          country?.trim().toLowerCase().length !== 0 ||
          authState.countryCode.trim().toLowerCase().length !== 0
        ) {
          let selectedCountry = country?.trim().toLowerCase();
          if (
            selectedCountry?.length === 0 &&
            authState.countryCode.trim().toLowerCase().length > 0
          )
            selectedCountry = authState.countryCode.trim().toLowerCase();
          regionSelection = regions.find(
            (x) => x.country.trim().toLowerCase() === selectedCountry
          );
          await onSetAuthState(regionSelection);
        }

        setRegions(regions);
        window.history.scrollRestoration = "manual";
      };

      fetchRegions();

      authService.loginAsync();
    }
  }, []);

  return (
    <Fragment>
      <div className="grid-responsive-width">
        <div className={`${style.sso_message}`}>
          <p className={`${style.info_message}`}>
            {(!country || !lang) && t("SSO_Redirecting_ParameterErrorMessage")}
            {!!country && !!lang && t("SSO_Redirecting_Message")}
          </p>
          <div className={`${style.maintenance_image}`}></div>
        </div>
      </div>
    </Fragment>
  );
};

export default SSOPage;
