export enum SideNavItemKey {
  _, // To avoid index 0
  HOME,

  TRAINING_SERVICES, 
  TRAINING_COURSE_CATALOGUE,
  TRAINING_COURSE_DETAIL,
  EVENT_CALENDAR,
  TRAINING_PROGRAM,
  TRAINING_PROGRAM_DETAIL,
  TRAINING_CENTERS,
  RECOMMEND_HOTELS,
 
  SUPPORT,
  DOWNLOAD_AREA,
  FAQ,
 
  MY_TRAINING, // Dashboard
  MY_TRAINING_EVENTS,
  MY_TRAINING_COURSE_DETAIL,
  MY_CURRICULA,
  MY_TRAINING_PROGRAM_DETAIL,
  TEAM,
  TEAM_EDIT,
  TEAM_CREATE,
  EXTERNAL,
  WORKSHOP,
  WALLET,

  MY_BOSCH,
  SHOPPING_CART,
  CHECKOUT,
  CONTACT_US,
}

export interface SideNavItem {
  key: SideNavItemKey;
  icon: string;
  label: string;
  path: string;
  isLoginRequired: boolean;
  supportedRoles: number[];
  isActive: boolean;
  childItems?: SideNavItem[];
  isAccountMappedRequired?: boolean;
  isHideOnSideNavigation?: boolean;
}

export type MinimalSideNavItem = Omit<
  SideNavItem,
  "isLoginRequired" | "supportedRoles" | "isActive" | "isAccountMappedRequired"
>;
