import { RBGeolocation } from "../models/geolocation/RBGeolocation";
import { Region } from "../models/RegionState";
import ResponseResultBase from "../models/ResponseResultBase";
import { RequestGetSignedLaunchLinkModel } from "../models/trainingCourse/request.getSignedLaunchLink.model";
import { httpNoneSecure } from "./HttpServiceNoneSecure";

export class CommonService {
  constructor() {}

  public static isGrantPermission(permission: string): boolean {
    return false;
  }

  public static async getAllLanguages(): Promise<ResponseResultBase<Region[]>> {
    let response = await httpNoneSecure.get<ResponseResultBase<Region[]>>(
      `/api/v1/supports/languages`
    );
    let isDev = CommonService.isDev();
    if (isDev) {
      if (!!response.data && !!response.data.dataObject) {
        response.data.dataObject.forEach((element) => {
          element.url = "http://localhost:3000/";
        });
      }
    }
    return response.data;
  }

  public static async getGeolocation(): Promise<
    ResponseResultBase<RBGeolocation>
  > {
    let response = await httpNoneSecure.get<ResponseResultBase<RBGeolocation>>(
      `/api/v1/Geolocation/GetGeolocation`
    );
    return response.data;
  }

  public static async getSignedLaunchLinkAsync(
    req: RequestGetSignedLaunchLinkModel
  ): Promise<ResponseResultBase<string>> {
    let response = await httpNoneSecure.get<ResponseResultBase<string>>(
      `/api/v1/RusticiListener/GetSignedLaunchLink?registrationID=${req.registrationID}&redirectOnExitUrl=${req.redirectOnExitUrl}&language=${req.language}`
    );
    return response.data;
  }

  public static isDev() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  }

  public static getCIAMEditProfileLInk(): string {
    return process.env.REACT_APP_CIAM_EDIT_PROFILE_URL !== undefined
      ? process.env.REACT_APP_CIAM_EDIT_PROFILE_URL
      : "";
  }

  public static getUserType = () => {
    return "wholesaler";
    //student
    //workshop_admin
    //wholesaler
    //visitor
  };
}
