import { FrokBreadCrumb } from "../../../types/component.type";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Breadcrumbs, Icon, Link } from "@bosch/react-frok";
import style from "./RDFrokBreacrumbs.module.scss";
import { capitalizeString } from "../../../utils/text.util";

type Props = {
  crumbs: FrokBreadCrumb[];
};

const RDFrokBreacrumbs = ({ crumbs }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onMove = (item: FrokBreadCrumb) => {
    if (item.path) history.push(item.path);
  };

  return (
    <Breadcrumbs className={style.container}>
      {crumbs.map((item) => (
        <Link className={style.link} onClick={() => onMove(item)}>
          {capitalizeString(t(item.label))}
          <Icon iconName="forward-right-small" className={style.icon} />
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default RDFrokBreacrumbs;
