export const TRANSLATIONS_NB_NO = {
	LandingPage_Title: "Velkommen til Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Velg region/land",
	Header_ContactUs: "Kontakt oss",
	Header_Search: "Søk",
	Header_Menu: "Meny",
	Header_LogInOrCreateYourAccount: "Logg inn eller opprett en konto",
	Header_LogIn: "Logg inn",
	Header_Cart: "Handlevogn",
	Header_ShoppingCart: "Handlevogn",
	Header_LogOut: "Logg ut",
	Header_ChangeDetails: "Endre informasjon",
	Footer_ExploreOurTraining: "Utforsk kursene våre",
	Footer_TrainingAtYourFingertips: "Enkel tilgang til kurs",
	Footer_DontMissAnyStory: "Ikke gå glipp av nyhetene",
	Footer_ExploreOurStories: "Utforsk historiene våre",
	Footer_GetInTouchWithUs: "Kontakt oss",
	Footer_SendUsAMessage: "Send oss en melding",
	Footer_ToContactForm: "Til kontaktskjema",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "Generell kontaktinformasjon",
	Footer_ProductSecurity_Psirt: "Produktsikkerhet (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innovasjoner, patenter og lisenser",
	Footer_PurchasingLogistics: "Innkjøp og logistikk",
	Footer_CorporateInformation: "Firmainformasjon",
	Footer_LegalNotice: "Juridisk informasjon",
	Footer_DataProtectionNotice: "Personvernerklæring",
	Footer_PrivacySettings: "Personverninnstillinger",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Alle rettigheter tilhører Robert Bosch AS",
	Footer_StillLookingForSomething: "Leter du fortsatt etter noe?",
	Footer_TrainingServices: "Service Training",
	Footer_Courses: "Kurs",
	Footer_Programs: "Programmer",
	Footer_EventsCalendar: "Kalender",
	Footer_TrainingCenters: "Kurssenter",
	Footer_Dashboard: "Dashbord",
	Footer_MyDashboard: "Dashbordet mitt",
	Footer_ShoppingCart: "Handlevogn",
	Footer_YourShoppingCart: "Handlevognen din",
	Footer_Administration: "Administrasjon",
	Footer_Employees: "Ansatte",
	Footer_Wholesalers: "Grossister",
	Footer_Transactions: "Transaksjoner",
	Footer_Workshops: "Verksteder",
	Footer_WorkLikeABoschApplyNow: "Jobb  – søk nå.",
	Footer_BoschGlobalCareers: "Bosch Global Careers",
	Footer_PurchasingAndLogistics: "Innkjøp og logistikk",
	Footer_BecomeABusinessPartner: "Bli bedriftspartner.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Slik utvikler vi kursene for å hjelpe bedriften din",
	Footer_Language: "Velg land",
	MenuPopup_MyCart: "Handlevognen min",
	Stories_OurTrainingStories: "Våre kurshistorier",
	Stories_ConnectingExperts: "Vi knytter sammen eksperter og entusiaster, drømmere og de som handler, innovatører og influensere – utforsk og opplev",
	Stories_TopStories: "Topphistorier",
	Stories_AtHome: "Hjemme",
	Stories_Careers: "Stillinger",
	Stories_History: "Historikk",
	Stories_IndustryAndTrades: "Industri og bransjer",
	Stories_Mobility: "Mobilitet",
	Stories_Research: "Forskning",
	Stories_Sustainability: "Bærekraft",
	Stories_AllStories: "Alle historier",
	Stories_BlogATrainersDay: "BLOGG | Instruktørens dag",
	Stories_ADayinLifeOfATrainer: "En dag i livet til en instruktør",
	Stories_BoschServiceTraining: "Bosch Service Training",
	Stories_StoryWhatDrivesYouDrivesUs: "HISTORIE | Det som driver deg, driver oss",
	Stories_StoryHowCanWeBenefitYourBusiness: "HISTORIE | Hvordan kan vi hjelpe bedriften din",
	Stories_HowOurTrainingEvolves: "Slik utvikler kursene seg",
	Stories_AModernTrainingApproach: "En verden av nettkurs",
	Stories_StoryAVirtualOnlineTrainingWorld: "HISTORIE | Dine muligheter med nettkurs",
	Stories_SubscribeToRSSFeed: "Abonner på RSS-feed",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Om oss",
	Homepage_AutomotiveAftermarket: "Mobility Aftermarket",
	Homepage_GetInTouch: "Ta kontakt",
	Homepage_LatestNews: "Finn ut hvorfor vi drives av en tørst etter kunnskap",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Våre ledige stillinger",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Det som driver deg, driver oss",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service Training",
	BoschServiceTraining_ShareThisOn: "Del dette på",
	BoschServiceTraining_TheChallenge: "Utfordringen: Mestre ny teknologi",
	BoschServiceTraining_WorldwidePresence: "Tilstedeværelse over hele verden",
	BoschServiceTraining_TechnicalTraining: "Tekniske kurs fra Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Tilbudene og mulighetene som Bosch tilbyr",
	BoschServiceTraining_TechnicalCoursesDesc: "Det finnes kurs for alle målgrupper – fra nybegynnere til profesjonelle. Tilbudene inkluderer endagskurs og kurs som går over flere dager. Vi har kurs innen forskjellige tekniske områder og serier (for eksempel bensininnsprøytning, dieselinnsprøytning og elektriske systemer). I de tekniske kursene deler Bosch omfattende kunnskap om alle standard bilsystemer fra Bosch samt systemer fra andre produsenter. Innholdet er spesialtilpasset for de verkstedansattes behov.",
	BoschServiceTraining_TrainedEmployeesDesc: "Ansatte med riktig kunnskap er en klar fordel for bilverksteder. Dagens biler blir stadig mer komplekse, og ny teknologi fører til nye utfordringer. De ansatte må ha nødvendig kunnskap for å kunne utføre diagnoser og reparasjoner på nye bilmodeller på en effektiv og kostnadsbesparende måte.",
	BoschServiceTraining_RegardlessOfHow: "Uansett hvor dyktige og erfarne kjøretøyspesialistene er, vil de alltid oppleve situasjoner der de møter på ny teknologi eller ukjente feil. Da kan feilsøking og problemløsning bli ekstra tidkrevende, og det kan også føre til at man ikke oppnår de ønskede resultatene. I verste fall står kundene uten bil mye lenger enn forventet.",
	BoschServiceTraining_ItIsPrecisely: "Det er dette som er hovedfokuset for Bosch Service Training: forbedre kvaliteten og redusere tidsbruken. Bosch’ kurs gjør det enklere å finne problemer raskere, slik at flere biler kan repareres på kortere tid, og slik at kundene slipper å vente. Derfor vil de systematiske rutinene som du lærer hos Bosch, sikre raskere og mer produktivt arbeid.",
	BoschServiceTraining_WorldwidePresenceDesc1: "I mer enn 125 år har Bosch vært den foretrukne leverandøren av originalutstyr, deler, komponenter og systemer for mange av verdens ledende bilprodusenter. Denne erfaringen ledsages av unik kunnskap om den nyeste teknologien, noe som utgjør grunnlaget for Bosch’ kundestøtte.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Det er Bosch Service Training Centers over hele verden – fra Spania og Tyrkia til Sør-Afrika, Australia og Brasil. Bosch tilbyr de ansatte praktiske kurs som alltid er i tråd med den nyeste teknologien.",
	BoschServiceTraining_AgileCompany: "Agilt selskap",
	BoschServiceTraining_Collaboration: "Samarbeid",
	BoschServiceTraining_ConnectedMobility: "Tilkoblet mobilitet",
	BoschServiceTraining_ArtificialIntelligence: "Kunstig intelligens",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Ledende på dialog",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnose & Testutstyr",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Grunnleggende teknologi",
	BoschServiceTraining_Icon_Gasoline: "Bensin",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternative drivlinjer",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektrisk og elektronikk",
	BoschServiceTraining_Icon_BodyAndComfort: "Komfort og sikkerhet",
	BoschServiceTraining_Icon_ChassisAndBraking: "Chassis og bremser",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmisjon & Drivlinje",
	BoschServiceTraining_Icon_CommercialVehicles: "Tungbil",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Onlinekurs",
	BoschServiceTraining_Icon_BusinessSkills: "Forretningskunnskap",
	BoschServiceTraining_Icon_Accreditations: "Akkrediteringer",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Utdanningsprogrammer",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerFirstNameLastName",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "A day in the life of a trainer",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Everyday is an adventure",
	ADayInTheLifeOfATrainer_Summary: "Summary",
	ADayInTheLifeOfATrainer_TheDayJourney: "The day's journey",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Everyday is a busy day but it is always a rewarding experience. The pleasure of training students knowing that they hold the key to our future is a responsibilty but at the same time so exciting. Seeing the students growing with each lesson on a daily and weekly basis proves to us that we are working towards a comon goal to improve their knowledge whilst incorporating the mordern technology that drives the future!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "At the end of each day we have a sit down and reflect on the days work. The days go so quickly but this is proof itself that what we are achieving is not only rewarding for the students but for us as trainers too.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "We can go home at the end of day satisfied that we have done a great job!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Start",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Forberedelse og siste kontroll av opplæringsmateriell. Sjekk e-post",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Kurset starter",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Velkommen til kurs. Dagsplanoversikt. Bli kjent med elevene fra og enkelte.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teori",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Det kreves en viss grad av samhandling med kursdeltakerne for å gjøre opplæringen så interessant som mulig. Sørg for at det er oppgaver flettet sammen med teorien for å engasjere gruppen ytterligere.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Lunsj",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Det praktiske",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Opplæringen fortsetter i verkstedet med noen praktiske oppgaver. Som instruktør er det alltid et krav å være tilstede og tilgjengelig for enhver eventualitet og veiledning",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Sammendrag",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Tilbake til teorirommet for en kort prat for å diskutere dagens trening. Svar på spørsmål som måtte være. Begynn å forberede deg til de neste treningsøktene.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Siste dag",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Deltakerbevis deles ut til deltakerne",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Hjem",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Hvordan kan vi være til nytte for din virksomhet",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Hvordan vår trening utvikler seg",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Vi er i stadig utvikling for å sikre at vi leverer en høy kvalitet og en utdanning som er \"up-to-date\"",
	BoschTrainingBenefits_TheBoschServiceTraining: "Bosch Service Training Center vil hjelpe deg med å utvikle virksomheten din gjennom en omfattende og svært relevant portefølje av teknisk/bedriftsopplæring og videreutdanningskurs med sertifiserte kvalifikasjoner. De følgende sidene gir deg en oversikt over utdanningsprogrammet vårt samt aktuelle etterutdanningsmuligheter. Vi ser frem til å møte deg og dine ansatte på våre kurs og seminarer.",
	BoschTrainingBenefits_LongStandingExperience: "Lang erfaring med og omfattende kunnskap om alle systemer innen bilteknologi.",
	BoschTrainingBenefits_QuickAndEfficient: "Raske og effektive løsninger på mange problemer.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Fordelene",
	BoschTrainingBenefits_LMS: "Learning Management System - Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions dekker hele spekteret av kjøretøytemaer. Spesialiser deg på spesifikke områder eller utvid din kompetanse med opplæringstilbudene fra Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Tilegnet seg kunnskap om reparasjon, vedlikehold og servicearbeid.",
	BoschTrainingBenefits_TheHighDegree: "Det høye nivået på praktisk innhold og små grupper garanterer målrettet læring",
	BoschTrainingBenefits_ParticipationInTraining: "Deltakelse i opplæring øker de ansattes motivasjon og tilfredshet, samt overbeviser kvalitetsbevisste kunder",
	BoschTrainingBenefits_IncreasedSales: "Økt salg gjennom systemkunnskap på tvers av bilmerker",
	BoschTrainingBenefits_MethodicalProcedures: "Metodiske prosedyrer reduserer ventetiden for kundene",
	BoschTrainingBenefits_HighlyQualified: "Høyt kvalifiserte instruktører formidler systematiske prosedyrer",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identifisering av kurs som passer for deg eller bedriften din",
	BoschTrainingBenefits_DirectOnlineBooking: "Nettbasert bestilling og administrasjon av kurs for deg selv eller for de ansatte",
	BoschTrainingBenefits_OnlineAccess: "Nettbasert tilgang til kursdokumentasjon",
	BoschTrainingBenefits_AccessToYourOwn: "Tilgang til din egen kurshistorikk og sertifiseringer",
	BoschTrainingBenefits_ToolsForManagement: "Verktøy for administrasjon av din egen karriereutvikling",
	BoschTrainingBenefits_ManagerHasFull: "Lederen har full oversikt over de ansattes kurs i systemet",
	BoschTrainingBenefits_Access24hours: "Tilgang 24 timer i døgnet 7 dager i uken",
	ModernTrainingApproach_KnowledgeIsKey: "Kunnskap er en viktig suksessfaktor for ethvert verksted. Derfor tilbyr Bosch et omfattende profesjonelt kursprogram. Det sørger for at elevene får den riktige kunnskapen til riktig tidspunkt – kunnskap de trenger for å kunne vedlikeholde og reparere kundenes biler på en profesjonell måte. Bosch har inkludert webcasting som en ny modul i nettkurspakken. De består av kursøkter som er spesialutviklet for strømming.",
	ModernTrainingApproach_ANewLearning: "En ny læringsmulighet",
	ModernTrainingApproach_YourOnlineTraining: "Dine muligheter med nettkurs",
	ModernTrainingApproach_RecentlyTheWorld: "Omverdenen har endret seg – se hva vi gjør for å hjelpe",
	ModernTrainingApproach_TheOnlineTrainingWorld: "En verden av nettkurs",
	ModernTrainingApproach_NewTeachingMethod: "Ny undervisningsmetode",
	ModernTrainingApproach_InAdditionTo: "I tillegg til de eksisterende fysiske kursene har vi inkludert live webcastkurs i kursprogrammet vårt. Instruktørene våre deler omfattende teoretisk kunnskap i det virtuelle klasserommet eller i form av praktiske eksempler. Som vanlig får du mye informasjon om kjøretøysystemer og tilhørende Bosch-produkter. Du kan naturligvis også stille spørsmål. Spørsmålene besvares under det direktesendte kurset. De direktesendte kursene varer mellom 60 og 120 minutter. Du må ha en stabil internettforbindelse. Søk etter egnede direktesendte kurs i kurskatalogen ved å skrive \Webcast\ i søkefeltet.",
	ModernTrainingApproach_BoschWebcastingHeader: "Direktesendte kurs fra Bosch: Innovativt og praktisk",
	ModernTrainingApproach_BoschWebcastingText: "For å delta på et direktesendt kurs fra Bosch må deltakerne ringe inn. Det sendes en individuell kode i forkant av kurset, som deltakeren bruker for å logge seg inn. Inntil 200 deltakere kan delta samtidig og fra hvor som helst. Kurset ledes av en instruktør, og deltakerne kan sende inn spørsmål via chatfunksjonen.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Det digitale supplementet til undervisning i klasserommet",
	ModernTrainingApproach_TheDigitalSupplementText: "Direktesendte kurs og undervisning i klasserommet utfyller hverandre. Begge deler ledes av en dyktig instruktør. Det finnes direktesendte kurs for temaer som kan gjennomføres digitalt uten at man må arbeide på bilen. For eksempel kan man bli kjent med utvalgte ESI[tronic]-funksjoner i et nytt førerassistentsystem. Når det gjelder effektivt samarbeid i verkstedet eller praktisk arbeid med teknologien i en bil, er fysisk opplæring fortsatt førstevalget.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Strømming kan være rimeligere",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Kostnaden per deltaker er vanligvis lavere ved direktesendte kurs enn ved tilsvarende fysisk undervisning i klasserom. Det skyldes at flere deltakere kan delta, og at det kreves betydelig mindre arbeid for å gjennomføre kurset (man slipper å reise til og fra kursstedet, og man bruker også kortere tid).",
	ModernTrainingApproach_BookAndProfitHeading: "Bestill og benytt deg av fordelene",
	ModernTrainingApproach_BookAndProfitText: "Du kan melde deg på direktesendte kurs på nettstedet vårt, på samme måte som andre kurs og opplæringer. Du kan velge å enten betale for hvert direktesendte kurs eller kjøpe et årsabonnement for et visst antall kurs.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Sammenligning av Bosch Webcasting og fysisk undervisning i klasserom",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Klasserom",
	ModernTrainingApproach_WebcastingP1: "Opptil 200 deltakere kan delta samtidig, fra hvor som helst i verden",
	ModernTrainingApproach_WebcastingP2: "Enkelt, relativt lav kostnad, krever ikke reisevirksomhet",
	ModernTrainingApproach_WebcastingP3: "Perfekt for konkrete og konsise temaer",
	ModernTrainingApproach_WebcastingP4: "Verksteder kan gjennomføre en webcast for de ansatte hvor som helst og når som helst",
	ModernTrainingApproach_WebcastingP5: "Krever mellom 50 og 70 % mindre tid enn undervisning i klasserom",
	ModernTrainingApproach_ClassroomTrainingP1: "Opptil 16 deltakere møtes samtidig",
	ModernTrainingApproach_ClassroomTrainingP2: "Reiseutgifter og generelt høyere kostnader per person",
	ModernTrainingApproach_ClassroomTrainingP3: "Perfekt for å optimalisere samarbeid eller læring på en bil",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktiske øvelser og interaksjon med instruktøren",
	ModernTrainingApproach_ClassroomTrainingP5: "Det er enklere for instruktøren å tilpasse kunnskapen som skal formidles basert på deltakernes faktiske behov",
	ModernTrainingApproach_OnlineTrainingOfferings: "Tilbud om nettbaserte kurs (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "I dag er uavhengig læring ved hjelp av datamaskiner en integrert del av kunnskapsformidlingen. Friheten til å velge tid og sted, muligheten til å gjenta kurset ved behov og i sitt eget tempo samt interaktivt innhold gjør denne metoden til et uunnværlig verktøy.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Alle deler av nettbaserte kurs (WBT) er grundig strukturert. I tillegg til å formidle omfattende kunnskap kan disse kursene være et effektivt hjelpemiddel i forberedelser for kurs ved Bosch’ kurssenter.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Med den virtuelle diagnosesimuleringen kan brukerne rollespille daglige situasjoner i verkstedet, som arbeidsflyten ved feilsøking med Bosch’ diagnoseenheter, i et virtuelt verksted.",
	ModernTrainingApproach_Advantages: "Fordeler",
	ModernTrainingApproach_AdvantagesP1: "En rask og effektiv måte å formidle kunnskap på",
	ModernTrainingApproach_AdvantagesP2: "Variert læring",
	ModernTrainingApproach_AdvantagesP3: "Kan brukes så ofte man vil",
	ModernTrainingApproach_AdvantagesP4: "Minimalt med tid og penger",
	ModernTrainingApproach_AdvantagesP5: "Individuelt læringstempo",
	ModernTrainingApproach_AdvantagesP6: "Fleksibel studietid",
	ModernTrainingApproach_AdvantagesP7: "Kan gjentas",
	ModernTrainingApproach_AdvantagesP8: "Ingen reisekostnader",
	ModernTrainingApproach_AdvantagesP9: "Ingen konflikter med kapasitet eller planlegging",
	Dashboard_CoursesViewAndManageYourCourses: "KURS | Se og administrer kursene dine",
	Dashboard_MyCourses: "Kursene mine",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "KURSPROGRAM | Se kursprogrammene dine",
	Dashboard_MyTrainingProgram: "Kursprogrammet mitt",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "NETTKURS | Se og administrer nettkursene dine",
	Dashboard_MyELearning: "Nettkursene mine",
	Dashboard_AccountManageYourAccount: "KONTO | Administrer kontoen din",
	Dashboard_MyAccount: "Kontoen min",
	Dashboard_MyDashboard: "Dashbord",
	Dashboard_ManageYourAccount: "Administrer kontoen din",
	Dashboard_Workshops: "Verksteder",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRASJON | Administrer verkstedet ditt",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRASJON | Administrer teamene dine",
	Dashboard_Administration: "Administrasjon",
	Dashboard_ViewYourCourses: "Se kursene dine",
	Dashboard_ViewYourTrainingPrograms: "Se kursprogrammene dine",
	Dashboard_TrainingPrograms: "Kursprogrammer",
	Dashboard_ViewAndEditYourDetails: "Se og rediger informasjonen din",
	Dashboard_ViewYourELearningCourses: "Se nettkursene dine",
	Dashboard_Pending: "Venter",
	Dashboard_RequiresAdministratorSignOff: "(krever godkjenning fra administrator)",
	Dashboard_HistoricalRecords: "Historikk",
	Dashboard_Registered: "Registrert",
	Dashboard_WaitingList: "Venteliste",
	Dashboard_RecommendedForYou: "Anbefalt for deg",
	Dashboard_AccountDetails: "Kontoinformasjon",
	Dashboard_ChangeProfileImage: "bytt profilbilde",
	Dashboard_Title: "Tittel",
	Dashboard_Role: "Rolle",
	Dashboard_FirstName: "Fornavn",
	Dashboard_LastName: "Etternavn",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobiltelefon",
	Dashboard_Email: "E-post",
	Dashboard_Language: "Språk",
	Dashboard_Save: "Lagre",
	Dashboard_Student: "Elev",
	Dashboard_WorkshopTrainingAdministrator: "Kursansvarlig for verksted",
	Dashboard_WholesalerTrainingAdministrator: "Kursansvarlig for grossist",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administrasjon av bedrift",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Administrer verkstedene dine …",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Administrer de ansatte …",
	Dashboard_CompanyAdministration_Workshop: "Verksted",
	Dashboard_CompanyAdministration_Address: "Adresse",
	Dashboard_CompanyAdministration_Zip: "Postnummer",
	Dashboard_CompanyAdministration_City: "Poststed",
	Dashboard_CompanyAdministration_Manage: "Administrer",
	Dashboard_AdministrationSection_AdministrationTitle: "Administrasjon",
	Dashboard_AdministrationSection_ManageTheTeam: "Administrer teamet",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRASJON | Administrer de ansatte",
	Dashboard_AdministrationSection_Employees: "Ansatte",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRASJON | Administrer grossistene",
	Dashboard_AdministrationSection_Wholesalers: "Grossister",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRASJON | Administrer transaksjonene dine",
	Dashboard_AdministrationSection_Transactions: "Transaksjoner",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administrasjon av ansatte",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Administrer de ansatte …",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registrer",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Historikk",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Rediger",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Ansatt",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Legg til",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Opprett",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrert",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Venter",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(krever godkjenning fra administrator)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historiske kurs",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Venteliste",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Oppdatert oversikt over ansatte",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Administrasjon av ansatte",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Navn",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Ansattkonto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Vis og rediger informasjon om ansatte …",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Innstillinger",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Tillat personlig Bosch Automotive Training Solutions konto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Oppgrader ansatt til bedriftsadministrator",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "La ansatt melde seg på kurs",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Send invitasjon som e-post",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Deaktiver konto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Lagre",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Invitere",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Rediger",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Administrasjon av leverandør",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Administrer leverandørene dine …",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Oversikt over godkjente leverandører",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Navn",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Slett",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Opprett",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Legg til",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Opprette ny ansattkonto",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Opprett informasjon for nye ansatte …",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Legg til ny administrator for godkjent leverandør",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Opprett informasjon for nye leverandør …",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Velg godkjent leverandør i nedtrekksmenyen …",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transaksjoner",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Vis og administrer transaksjonene dine …",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Tilbake",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Fakturanummer",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Beskrivelse",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Betalingsmåte",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Dato",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Beløp",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Last ned",
	TrainingCentres_TrainingCentres: "Kurssenter",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Vi har kurssentre over hele verden",
	TrainingCentres_CountryCategory: "Land Kategori",
	TrainingCentres_CityCategory: "Sted Kategori",
	TrainingCentres_Country: "Land",
	TrainingCentres_City: "Sted",
	TrainingCatalogue_TrainingCatalogue: "Kurskatalog",
	TrainingCatalogue_LearningAtTheHighestLevel: "Læring på høyeste nivå",
	TrainingCatalogue_StartDate: "Startdato",
	TrainingCatalogue_EndDate: "Sluttdato",
	TrainingCatalogue_TrainingName: "Kursnavn",
	TrainingCatalogue_Language: "Språk",
	TrainingCatalogue_Days: "Dager",
	TrainingCatalogue_Seats: "Ledige plasser",
	TrainingCatalogue_Price: "Pris",
	TrainingCatalogue_Location: "Sted",
	TrainingCatalogue_PartNumber: "Artikkelnummer",
	TrainingCatalogue_TrainingCourse: "Kurs",
	TrainingCatalogue_Category: "Kategori",
	TrainingCatalogue_TrainingCategory: "Kurskategori",
	TrainingCatalogue_CourseName: "Kursnavn",
	TrainingCatalogue_TotalDays: "Totalt antall dager",
	TrainingCatalogue_Venue: "Sted",
	TrainingCatalogue_Details: "Detaljer",
	TrainingCatalogue_Prerequisites: "Forutsetninger",
	TrainingCatalogue_CourseType: "Kurstype",
	TrainingCatalogue_LearningMethod: "Læringsmetode",
	TrainingCatalogue_AddToWaitingList: "Legg til på venteliste",
	ShoppingCart_ShoppingCart: "Handlevogn",
	ShoppingCart_ReviewAndManage: "Se og administrer abonnementene dine",
	ShoppingCart_YouHaveAddedItem: "Du har lagt følgende i handlevognen",
	ShoppingCart_Item: "Artikkel",
	ShoppingCart_Quantity: "Antall",
	ShoppingCart_PricePerItem: "Pris per artikkel",
	ShoppingCart_TotalPrice: "Totalpris",
	ShoppingCart_VatIsIncluded: "Eks. mva.",
	ShoppingCart_Redeem: "Hvis du ikke har kreditt, klikker du her for å løse den inn",
	ShoppingCart_Purchase: "Hvis du ikke har kreditt, klikker du her for å kjøpe det",
	ShoppingCart_Checkout: "Til kassen",
	ShoppingCart_ContinueShopping: "Fortsett å handle",
	ShoppingCart_TotalPriceInclVat: "Sum inkl. mva.",
	ShoppingCart_Apply: "Bruk",
	ShoppingCart_ShoppingCartEmpty: "Handlevognen din er tom",
	ShoppingCart_VatIncluded: "Mva.",
	ShoppingCart_ManageYourShopping: "Administrer shoppingen din",
	ShoppingCart_Participant: "Deltaker",
	ShoppingCart_Complete: "Fullfør",
	ShoppingCart_NetPrice: "Nettopris",
	ShoppingCart_Workshop: "Verksted",
	CheckOut_PreviewYourOrder: "Bestillingsoversikt",
	CheckOut_YourShoppingCart: "Handlevogn",
	CheckOut_Preview: "Forhåndsvisning",
	CheckOut_Payment: "Betaling",
	CheckOut_Summary: "Oppsummering",
	CheckOut_Back: "Tilbake",
	CheckOut_PleaseReadAndAgreeOurTerms: "Les og godta vilkårene",
	CheckOut_IHaveReadTheTermsAndCondition: "Jeg har lest og godtar [vilkårene]",
	CheckOut_YesIAgree: "Ja – jeg godtar vilkårene",
	CheckOut_Continue: "Fortsett",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Gratulerer! Vi har mottatt bestillingen din",
	CheckOut_OrderConfirmation: "Du får en bestillingsbekreftelse når bestillingen er behandlet",
	CheckOut_WeWillContactYouDirectly: "Vi kontakter deg hvis vi har spørsmål",
	CheckOut_AddToCart: "Legg i handlevognen",
	CheckOut_CreditCart: "Kredittkort",
	CheckOut_DirectDebit: "Direkte trekk",
	CheckOut_TrainingCredits: "Kurskreditt",
	CheckOut_PreferredWholesaler: "Foretrukket grossist",
	EventCalendar_EventCalendar: "Arrangementskalender",
	EventCalendar_TrainingEventsForAllYourNeeds: "Arrangementer for ethvert behov",
	TrainingCourse_TrainingCourse: "Kurskatalog",
	TrainingCourse_LearningAtTheHighestLevel: "Læring på høyeste nivå",
	TrainingProgram_Title: "Kursprogramkatalog",
	TrainingProgram_SubTitle: "Læring på høyeste nivå",
	AccountDeactivationModal_Title: "Vil du deaktivere denne kontoen?",
	AccountDeactivationModal_Line1: "Hvis kontoen deaktiveres, kan den ikke aktiveres igjen, og alt innhold, inkludert serviceforespørsler, transaksjoner, saldo, teammedlemmer og profilinnstillinger, fjernes.",
	AccountDeactivationModal_Line2Part1: "Hvis abonnementsperioden er aktiv, må du se avbestillingsreglene i",
	AccountDeactivationModal_Line2Part2: "vilkårene",
	AccountDeactivationModal_Line2Part3: "før du sender forespørselen.",
	AccountDeactivationModal_Line3: "Vil du sende en forespørsel om å deaktivere denne kontoen?",
	AccountDeactivationModal_Cancel: "Avbryt",
	AccountDeactivationModal_Send: "Send",
	CurrentUserAccountDeactivationModal_Title: "Vil du deaktivere kontoen din?",
	CurrentUserAccountDeactivationModal_Line1: "Hvis kontoen deaktiveres, kan den ikke aktiveres igjen, og alt innhold, inkludert serviceforespørsler, transaksjoner, saldo, teammedlemmer og profilinnstillinger, fjernes.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Hvis abonnementsperioden er aktiv, må du se avbestillingsreglene i",
	CurrentUserAccountDeactivationModal_Line3: "Vil du sende en forespørsel om å deaktivere kontoen din?",
	CurrentUserAccountDeactivationModal_Yes: "Ja",
	CurrentUserAccountDeactivationModal_No: "Nei",
	CourseWithdrawalModal_Title: "Avbestillingen er ikke gyldig før den er godkjent av kursadministrasjonen.",
	CourseWithdrawalModal_Line1: "Du får en endelig bekreftelse på e-post når den er godkjent. Vi kontakter deg hvis vi har spørsmål. Vil du sende inn avbestillingsforespørselen?",
	CourseWithdrawalModal_Line2Part1: "Les våre",
	CourseWithdrawalModal_Line2Part2Link: "avbestillingsregler",
	CourseWithdrawalModal_Line2Part3: "Vil du fortsette med avbestillingsforespørselen?",
	CourseWithdrawalModal_Yes: "Ja",
	CourseWithdrawalModal_No: "Nei",
	Settings_Settings: "Innstillinger",
	Settings_ManageYourSettings: "Administrer innstillingene dine",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Min Bosch Global ID Login-informasjon",
	Settings_Edit: "Rediger",
	Settings_CompanyDetails: "Firmainformasjon",
	Settings_RequestAccountDeletion: "Be om sletting av konto",
	Settings_AccountDeletionText: "Hvis du sletter kontoen din, vil alle dataene dine (inkludert adressen din og betalingsinformasjonen) slettes. De kan ikke gjenopprettes.",
	Settings_DeleteAccountRequestConfirmation: "Bekreftelse på forespørsel om sletting av konto",
	Settings_DeleteAccountRequestConfirmationText: "Vi har mottatt din forespørsel om sletting av konto, og vi kontakter deg via e-post eller telefon for å få endelig bekreftelse",
	RecommendedHotels_RecommendedHotels: "Anbefalte hoteller",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Vi har kurssentre over hele verden",
	Error_Common: "Noe gikk galt.",
	Error_404: "Siden finnes ikke lenger",
	Error_401: "Du har ikke tilgang til denne siden",
	Error_500: "Det er en feil på serveren",
	Dashboard_Courses_Table_Course: "Kurs",
	Dashboard_Courses_Table_Date: "Dato [dager]",
	Dashboard_Courses_Table_Venue: "Sted",
	Dashboard_Courses_Table_TrainingType: "Kurstype",
	Dashboard_Courses_Table_Action: "Handling",
	Dashboard_Courses_Table_TasksToComplete: "Du må utføre følgende oppgaver",
	Dashboard_Courses_Table_Withdraw: "Avbestill",
	Dashboard_Courses_Table_Remove: "Fjern",
	Dashboard_Courses_Table_View: "Oversikt",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Ingen historikk",
	Dashboard_Courses_Table_CompletionDate: "Sluttdato",
	Dashboard_Courses_Table_Actions_Assignments: "Oppgaver",
	Dashboard_Courses_Table_Actions_Certification: "Sertifisering",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluering",
	Dashboard_Courses_Table_Actions_Assessments: "Test",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Legg til kalender",
	Dashboard_Courses_Table_CourseDetails_Task: "Oppgave",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Tidsfrist",
	ContactUs_Headline: "Kontakt oss",
	ContactUs_HowCanWeHelp: "Hvordan kan vi hjelpe deg?",
	ContactUs_EmailTab: "E-post",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Brev",
	ContactUs_Phone_Headline: "Ring oss!",
	ContactUs_Phone_Description: "Vi ser frem til din samtale og til å hjelpe deg.",
	ContactUs_Phone_PhoneNumber: "Vennligst bruk kontaktskjemaet for å sende oss en melding.",
	ContactUs_Phone_ChargeNotice: "Gebyrene varierer avhengig av tjenesteleverandøren og landet.",
	ContactUs_Letter_Headline: "Send et brev!",
	ContactUs_Letter_Description: "Har du spørsmål, ønsker eller forslag? Vi ser frem til din henvendelse.",
	ContactUs_Email_Headline: "Send en epost!",
	ContactUs_Email_Description: "Send oss ​​din melding ved å fylle ut skjemaet nedenfor. En av våre rådgivere vil deretter kontakte deg.",
	ContactUs_Email_InputName: "Navn*",
	ContactUs_Email_InputCompany: "Firma",
	ContactUs_Email_InputCountry: "Land*",
	ContactUs_Email_InputEmail: "E-post*",
	ContactUs_Email_InputMessage: "Beskjed*",
	ContactUs_Email_DataProtection: "Merknad om databeskyttelse*",
	ContactUs_Email_DataProtectionConfirm: "Jeg har lest personvernreglene*",
	ContactUs_Email_SecurityCheck: "Sikkerhetskontroll",
	ContactUs_Email_SecurityCodeConfirm: "Vennligst skriv inn de riktige tegnene du ser i boksen ovenfor.",
	ContactUs_Email_SecurityCodeInvalid: "Captcha-tekstsvaret du skrev inn er feil.",
	ContactUs_Email_MandatoryNotice: "Vennligst fyll ut obligatoriske felt.",
	ContactUs_Email_SuccessMessage: "Din melding har blitt sendt",
	ContactUs_Email_TakenNoteDataProtection1: "Jeg har lest",
	ContactUs_Email_TakenNoteDataProtection2: "Personvernerklæring*",
	ContactUs_Email_SubmitButton: "Send inn",
	ContactUs_Email_FieldErrorMessage: "Vennligst fyll ut obligatoriske felt.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Kontoen din er for øyeblikket til vurdering!",
	AccountInReviewModal_Paragraph1: "Takk for at du registrerte en personlig konto hos Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Vårt opplæringspersonale vurderer og konfigurerer for øyeblikket kontoen din. Du vil motta en endelig bekreftelse på e-post når kontoen din er konfigurert.",
	AccountInReviewModal_Paragraph3: "I mellomtiden kan du gjerne bla gjennom nettsiden vår med begrenset tilgang.",
	AccountInReviewModal_Paragraph4: "Hvis du har spørsmål eller hvis kontoen din ikke har blitt behandlet innen 48 timer, unntatt helger og helligdager, vennligst kontakt oss",
	AccountInReviewModal_ContactUsLink: "her",
	AccountInReviewModal_OKButton: "Ok, la meg få tilgang!",
	Header_YesButton: "Ja",
	Header_NoButton: "Nei",
	Header_LogOutText: "Vil du logge ut?",
	Footer_EnquiryText: "Vi ser frem til din henvendelse,",
	Footer_Telephone: "Telefon,",
	Footer_TermsAndConditions: "Betingelser og vilkår",
	Footer_HowOurTrainingEvolves: "Hvordan vår trening utvikler seg",
	FooterSearch_SearchFieldPlaceholder: "Søk",
	TrainingCatalogue_Action: "Handling",
	TrainingCatalogue_Duration: "Varighet",
	TrainingCatalogue_Information: "Informasjon",
	Settings_PersonalDetails: "Personlige opplysninger",
	Reset_All_Filters: "Tilbakestill alle filtre",
	EventTable_Days: "Dager",
	SearchField_AllResults: "Alle resultater",
	TrainingCentresLayout_ViewDetails: "Vis detaljer",
	Dropdown_Placeholder: "Velg…",
	WaitlistPopup_SelectButtonText: "Velg",
	WaitlistPopup_StartDate: "Startdato",
	WaitlistPopup_EndDate: "Sluttdato",
	WaitlistPopup_Language: "Språk",
	WaitlistPopup_City: "By",
	WaitlistPopup_Workshop: "Verksted",
	WaitlistPopup_Student: "Student",
	WaitlistPopup_PreferredLocation: "Foretrukket sted",
	WaitlistPopup_SignUpCourseWaitingList: "Meld deg på kursventeliste",
	WaitlistPopup_SignUpWithoutSelectingDate: "Meld deg på venteliste uten å velge dato",
	WaitlistPopup_GeneralWaitList: "Generell venteliste",
	WatinglistPopup_CourseLabel: "Kurs",
	Common_ContinueButton: "Fortsett",
	UserAccountDeactivationModal_ConfirmLabel: "Bekreft",
	Filter_FilterLabel: "Filter",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Du er allerede på venteliste, vil du fjerne din eksisterende registrering og opprette en ny?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Ja, fjern",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nei, avbryt",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Kursavlysning",
	WaitinglistPopup_SignUpButtonText: "Påmelding",
	MyAccount_FirstNameRequied: "Fornavn er påkrevd",
	MyAccount_LastNameRequired: "Etternavn er påkrevd",
	MyAccount_RoleRequired: "Stilling er påkrevd",
	MyAccount_MobileRequired: "Mobil er påkrevd",
	MyAccount_EmailRequired: "E-post er påkrevd",
	MyAccount_LanguageRequired: "Språk er påkrevd",
	MyAccount_UpdateSuccessMessage: "Kontoinformasjonen din er oppdatert!",
	MyAccount_UpdateSuccessModal_ButtonText: "Ja, lukk",
	Infor_CorporateInformation_Header: "Firmainformasjon",
	Infor_LegalNotice_Header: "Juridisk varsel",
	Infor_PrivacyPolicy_Header: "Databeskyttelseserklæring (personvernerklæring)",
	CreateEmployeeAccountModification_FormValidation_Role: "Stilling er påkrevd",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Fornavn er påkrævet",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Etternavn er påkrevd",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobil er påkrevd",
	CreateEmployeeAccountModification_FormValidation_Email: "E-post er påkrevd",
	CreateEmployeeAccountModification_FormValidation_Language: "Språk er påkrevd",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Invitasjon til å delta i Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Du har lagt til et nytt medlem til laget ditt!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Vil du at vi skal dele en e-postinvitasjon med ditt nye teammedlem med instruksjoner om hvordan hen oppretter sin egen personlige konto hos Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Nei",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Ja, send invitasjon",
	ShoppingCart_Venue_Fee: "Stedsavgift",
	ShoppingCart_Trainning_Name: "Navn på kurs",
	ShoppingCart_Available_Credits: "Tilgjengelige Credits",
	ConfirmItsMeModal_Hi: "Hei",
	ConfirmItsMeModal_Description1: "Du har fullført invitasjonen, som er delt av bedriftsadministratoren din, om å opprette din egen personlige konto hos Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Før du fortsetter, bekreft eller oppdater kontoinformasjonen din.",
	ConfirmItsMeModal_Description3: "Etter bekreftelse vil du bli bedt om å logge på igjen for å fullføre opprettelsen av kontoen din.",
	ConfirmItsMeModal_CompanyDetails: "Firmainformasjon",
	ConfirmItsMeModal_YourDetails: "Dine detaljer",
	ConfirmItsMeModal_YourDetails_Title: "Tittel",
	ConfirmItsMeModal_YourDetails_Function: "Funksjon",
	ConfirmItsMeModal_YourDetails_Name: "Navn",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobil",
	ConfirmItsMeModal_YourDetails_Email: "E-post",
	ConfirmItsMeModal_YourDetails_Language: "Språk",
	ConfirmItsMeModal_ConfirmButton: "Bekreft",
	SelectWholesalerModal_WholesalerGroupLabel: "Leverandør",
	SelectWholesalerModal_WholesalerBranchLabel: "Outlet",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Vennligst velg",
	ApplyButton: "Søk",
	SelectWholesalerModal_Title: "Velg leverandør",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Vil du fjerne registreringen din?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Registreringen din er fjernet!",
	WaitlistPopup_AddToWaitListSuccess: "Registreringen din er lagt til med suksess!",
	ErrorCommon_Header: "FEIL",
	CommonButton_Close: "Lukk",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Vennligst lukk dette vinduet for å fortsette!",
	EmbeddedVideoPlayer_StartButtonText: "Start",
	EmbeddedVideoPlayer_RefresherButtonText: "Oppdater",
	DeactivateWholesalerModal_ConfirmMessage: "Er du sikker på at du vil fjerne denne leverandøren?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Send invitasjons-epost vellykket!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Inviter på nytt",
	ContactUs_Mobile_NO: "+47 64 87 89 00",
	ContactUs_Address_NO: "Robert Bosch AS,Rosenholmveien 25,1414 Trollåsen,Norge",
	ConfirmItsMeModal_CompanyDetails_Name: "Firmanavn",
	ConfirmItsMeModal_CompanyDetails_Address: "Adresse",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Postnummer",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-post",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Avmelding vellykket",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Kontoopprettelsen mislyktes. Kontoen eksisterer allerede!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Oppdatering av kontoen mislyktes. Kontoen eksisterer allerede!",
	Footer_ExploreTrainingStories: "Utforsk alle treningshistoriene våre",
	Breadcrumb_Home: "Hjem",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Historier",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "En dag i livet til en trener",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch treningsfordeler",
	Breadcrumb_Stories_TrainingApproach: "En moderne treningsmetode",
	Breadcrumb_Settings: "Innstillinger",
	Breadcrumb_ShoppingCart: "Handlevogn",
	Breadcrumb_Checkout: "Utsjekk",
	Breadcrumb_OrderSummary: "Bestillingssammendrag",
	Breadcrumb_Exception: "Unntak",
	Breadcrumb_Dashboard: "Dashbord",
	Breadcrumb_MyCourses: "Mine kurs",
	Breadcrumb_MyELearning: "Min e-læring",
	Breadcrumb_CompanyAdministration: "Firmaadministrasjon",
	Breadcrumb_EmployeeManagement: "Håndtering av ansatte",
	Breadcrumb_EmployeeAccount: "Ansattkonto",
	Breadcrumb_EmployeeCreation: "Opprettelse av ansatte",
	Breadcrumb_Administration: "Administrasjon",
	Breadcrumb_EmployeeAdministration: "Administrasjon av ansatte",
	Breadcrumb_WholesalerManagement: "Håndtering av leverandører",
	Breadcrumb_NewWholesalerAccountCreation: "Oppretting av ny leverandørkonto",
	Breadcrumb_Transactions: "Transaksjoner",
	Breadcrumb_MyAccount: "Min konto",
	Breadcrumb_TrainingCentres: "Treningssentre",
	Breadcrumb_EventCalendar: "Kurskalender",
	Breadcrumb_TrainingCourseCatalogue: "Kurskatalog",
	Breadcrumb_RecommendedHotels: "Anbefalte hoteller",
	Breadcrumb_CorporateInformation: "Leverandør",
	Breadcrumb_LegalNotice: "Juridiske rettighetsforhold",
	Breadcrumb_DataProtectionNotice: "Databeskyttelseserklæring",
	Breadcrumb_PrivacyPolicy: "Personvernerklæring",
	Breadcrumb_TermCondition: "Vilkår og betingelser",
	Breadcrumb_Terms: "Villkår",
	Breadcrumb_PrivacyStatement: "Personvernerklæring",
	Breadcrumb_Cookies: "Informasjonskapsler",
	Breadcrumb_Provider: "Leverandør",
	Breadcrumb_RegisterAccount: "Registrer konto",
	Stories_ExploreMore: "Utforsk mer",
	CheckOutLayout_ShoppingCartHeader: "Handlevogn",
	CheckOutLayout_AddedItemCartMessage: "Du har lagt til følgende i handlekurven",
	CheckOutLayout_CheckYourCartMessage: "Sjekk handlekurven din",
	ModernTrainingApproach_DateText: "2/28/2022",
	ADayInLifeofATrainer_DateText: "2/28/2022",
	BoschTrainingBenefits_DateText: "2/28/2022",
	BoschServiceTraining_DateText: "2/28/2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Søk etter ansatte",
	CheckOutLayout_OrderWasNotPlaced_Message: "Beklager! Bestillingen din ble ikke gjennomført",
	CheckOutLayout_RefusalReason: "Årsak til avslag",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Betalingen feilet. Prøv på nytt med en annen betalingsmetode",
	CheckOutLayout_SubmittingApiFailed_Message: "Innsending av API mislyktes",
	CartItem_ChooseWorkshop_Message: "Begynn med å velge et verksted",
	CartItem_ChooseParticipant_Message: "Velg deltaker",
	CompanyAdministrationTable_SearchBox_Placeholder: "Søk verksteder",
	CartItem_RemoveCartItem_ConfirmText: "Vil du fjerne denne varen fra handlekurven?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funksjon",
	AddToCartSuccessModal_Message: "Handlevognen din har blitt oppdatert!",
	AddToCartSuccessModal_ContinueButtonText: "Fortsett å handle",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Min handlevogn",
	AddVoucher_Header: "Legg til voucher",
	AddVoucher_InputPlaceholder: "Skriv inn koden og lagre",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Dessverre har vi ingen planlagte arrangementer på dette kurset for øyeblikket. Registrer deg på venteliste, så gir vi deg beskjed når et arrangement blir tilgjengelig.",
	ADYEN_ERR_2_: "Transaksjonen har blitt avslått",
	ADYEN_ERR_3_: "Henvisningsfeil",
	ADYEN_ERR_4_: "Transaksjonen ble ikke gjennomført på grunn av en feil som oppsto på kjøpers side.",
	ADYEN_ERR_5_: "Kortet som ble brukt for transaksjonen er blokkert og kan ikke brukes.",
	ADYEN_ERR_6_: "Kortet som ble brukt for transaksjonen er utløpt. Derfor kan det ikke brukes.",
	ADYEN_ERR_7_: "Det oppstod et uoverensstemmelse mellom beløpene under transaksjonen.",
	ADYEN_ERR_8_: "Det angitte kortnummeret er feil eller ugyldig.",
	ADYEN_ERR_9_: "Det er ikke mulig å kontakte kjøperens bank for å godkjenne transaksjonen.",
	ADYEN_ERR_10_: "Kjøperens bank støtter ikke eller tillater ikke denne typen transaksjoner.",
	ADYEN_ERR_11_: "3D Secure-godkjenning ble ikke utført, eller den ble ikke utført vellykket.",
	ADYEN_ERR_12_: "Det er ikke tilstekkelig dekning på kortet.",
	ADYEN_ERR_14_: "Mulig svindel.",
	ADYEN_ERR_15_: "Transaksjonen ble kansellert.",
	ADYEN_ERR_16_: "Kjøperen kansellerte transaksjonen før den ble fullført.",
	ADYEN_ERR_17_: "Den angitte PIN-koden er feil eller ugyldig.",
	ADYEN_ERR_18_: "Kjøperen angav feil PIN-kode mer enn tre ganger på rad.",
	ADYEN_ERR_19_: "Det er ikke mulig å validere det angitte PIN-nummeret.",
	ADYEN_ERR_20_: "Mulig svindel.",
	ADYEN_ERR_21_: "Transaksjonen ble ikke sendt riktig for behandling.",
	ADYEN_ERR_22_: "Risikokontrollen markerte transaksjonen som uredelig (risikoscore> = 100); derfor blir operasjonen avbrutt.",
	ADYEN_ERR_23_: "Følgende avviste koder er tilordnet denne grunnen til avslaget: Transaksjon er ikke tillatt for utsteder/kortholder",
	ADYEN_ERR_24_: "Den angitte CVC (kortets sikkerhetskode) er ugyldig.",
	ADYEN_ERR_25_: "Følgende tilbakekallingskoder er tilordnet denne grunnen til avslaget: Ugyldig kort i dette landet",
	ADYEN_ERR_26_: "R1: Tilbakekallelse av autorisasjonsordre / \"R3: Tilbakekallelse av alle autorisasjonsordre\" / \"R0: Stopp betalingsordre\"",
	ADYEN_ERR_27_: "Dette svaret kartlegger alle de responskodene som ikke kan kartlegges pålitelig. Dette gjør det lettere å fortelle generelle avslag (f. eks. Mastercard \"05: Do not honor\" svar) fra mer spesifikke.",
	ADYEN_ERR_28_: "Uttaksbeløpet som er tillatt for kjøperens kort er overskredet.",
	ADYEN_ERR_29_: "Antall uttak som er tillatt for kjøperens kort er overskredet.",
	ADYEN_ERR_31_: "Utsteder rapporterte transaksjonen som mulig svindel.",
	ADYEN_ERR_32_: "Adressedataene som kjøperen skrev inn er feil.",
	ADYEN_ERR_33_: "Kjøperens bank krever at kjøperen angir en online PIN-kode.",
	ADYEN_ERR_34_: "Kjøperens bank krever en brukskonto for å fullføre kjøpet.",
	ADYEN_ERR_35_: "Kjøperens bank krever en sparekonto for å fullføre kjøpet.",
	ADYEN_ERR_36_: "Kjøperens bank krever at kjøperen angir en mobil-PIN.",
	ADYEN_ERR_37_: "Kjøperen forlot transaksjonen etter at de forsøkte en kontaktløs betaling og ble bedt om å prøve en annen kortinnføringsmetode (PIN -kode eller sveip).",
	ADYEN_ERR_38_: "Utstederen avviste forespørselen om godkjenningsfritak og krever autentisering for transaksjonen. Prøv igjen med 3D Secure.",
	ADYEN_ERR_39_: "Utstederen eller ordningen klarte ikke å kommunisere utfallet via RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Fornavnet må ikke inneholde spesialtegn (f.eks: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Fornavnet må være mindre enn 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Etternavnet må ikke inneholde spesialtegn (f.eks: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Etternavnet må være mindre enn 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobilnummer må ikke inneholde spesialtegn (f.eks. @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobilnummer må være mindre enn 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telefonnummeret må ikke inneholde spesialtegn (f.eks: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telefonnummeret må være mindre enn 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "E-postadressen er ugyldig",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-post må være mindre enn 100",
	ConfirmGeolocationModal_ConfirmButton: "Fortsett",
	ConfirmGeolocationModal_CancelButton: "Endring",
	ConfirmGeolocationModal_CurrentCountrySelection: "Ditt nåværende landvalg er:",
	ConfirmGeolocationModal_AskChangeCountry: "Ønsker du å forbli på denne siden eller endre landvalg?",
	Addtocart_Modal_AddToCartFailTitle: "Kunne ikke legge til deltaker",
	Addtocart_Modal_AlreadyInCart: "Deltakeren er allerede registrert på dette kurset",
	TrainingCatalogue_DateRange: "Datointervall",
	TrainingCatalogue_DateRange_placeholder: "Velg datointervall",
	Dashboard_MyPendingCourses: "Mine ventende kurs",
	Dashboard_MyWaitingList: "Min venteliste",
	Dashboard_LatestNews: "Siste nytt",
	SettingsMainPage_EditAccountButton: "Rediger bruker",
	SettingsMainPage_EditBoschId: "Rediger Bosch ID",
	EventInfoView_EventDetailTab: "Begivenhetsdetaljer",
	EventInfoView_AssignmentsTab: "Oppgaver",
	EventInfoView_EvaluationTab: "Evaluering",
	EventInfoView_AttachmentTab: "Vedlegg",
	EventInfoView_CourseDescriptionTab: "Beskrivelse",
	EventInfoView_DescriptionTab_General: "Generell",
	EventInfoView_DescriptionTab_PartLabel: "Del",
	AssignmentTab_Description: "Her finner du all informasjon du trenger for å fullføre dette kurset.",
	AssignmentTab_Columns_Download: "Nedlasting",
	AssignmentTab_Columns_Upload: "Last opp",
	AssignmentTab_Columns_TaskComplete: "Oppgave fullført",
	EventDetailTab_Location_Message_Video: "Dette er et nettbasert arrangement som kan nås ved å bruke koblingen \"Launch Video\".",
	EventDetailTab_Location_Message_WebBased: "Dette er et nettbasert arrangement som kan nås ved å bruke koblingen \"Launch WBT\".",
	EventDetailTab_Location_Message_WebCast: "Dette er et online arrangement som kan nås ved å bruke koblingen \"Bli med nå\".",
	EventDetailTab_Link_Video: "Start video",
	EventDetailTab_Link_Video_Remark: "*Hvis du starter videoen, åpnes et nytt vindu. Du vil bli omdirigert tilbake til denne siden når vinduet er lukket.",
	EventDetailTab_Link_WebBased: "Start WBT",
	EventDetailTab_Link_WebBased_Remark: "*Hvis du starter WBT, åpnes et nytt vindu. Du vil bli omdirigert tilbake til denne siden når vinduet er lukket.",
	EventDetailTab_Link_WebCast: "Bli med nå",
	EventDetailTab_Link_WebCast_Remark: "*Bli med nå-lenken vil være aktiv 30 minutter før arrangementets start.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Varighet (timer)",
	EventDetailTab_Title_Trainers: "Trener(e)",
	EventDetailTab_Title_Language: "Språk",
	EventDetailTab_Title_Score: "Poengsum (%)",
	EventDetailTab_Title_Calendar: "Legg til i kalenderen",
	EventDetailTab_Title_Certificate: "Last ned sertifikat",
	EventDetailTab_Title_Messages: "Meldinger",
	EventDetailTab_Title_StartDate: "Startdato",
	EventDetailTab_Title_AvailableFrom: "Tilgjengelig fra",
	EventDetailTab_Title_Time: "Tid",
	EventDetailTab_Title_EndDate: "Sluttdato",
	EventDetailTab_Title_ExpiresOn: "Går ut på dato den",
	EventDetailTab_Title_Room: "Rom",
	EventDetailTab_Title_Method: "Metode",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "plassering",
	AttachmentTab_EmptyScreenDescription: "Det er ingen vedlegg for dette arrangementet.",
	AttachmentTab_Columns_Download: "Last ned",
	AttachmentTab_Description: "Her finner du viktig informasjon som kan hjelpe deg med dette kurset",
	EvaluationTab_Annotation: "Vi setter pris på tilbakemeldingene dine og vil gjerne høre fra deg for å forstå hvordan vi presterer. Ta deg tid til å fylle ut følgende evaluering for å hjelpe oss med å kontinuerlig forbedre tjenesten vår til deg. Takk for hjelpen!",
	EvaluationTab_Notice: "Rangeringene er som følger",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Vennligst svar på alle obligatoriske spørsmål før du sender inn arrangementsevalueringen",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Tilbakemeldingen din har blitt sendt inn",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Evalueringsinnsendingen mislyktes",
	TrainingCatalogueLayout_ExportButtonText: "Eksport",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Evalueringen ble fullført eller har utløpt.",
	EventDetailTab_Link_CopiedTooltip: "Kopiert til utklippstavlen",
	EventDetailTab_QRCode_ExplanationText_WBT: "Skann for å starte WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Skann for å starte video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Skann for å starte webcast",
	Footer_TrainingCoure_Headline: "Opplæringskurs levert av Bosch",
	Footer_TrainingCourse_Content: "Som et verdenskjent supermerke og med over hundre års erfaring innen innovasjon, har Bosch satt standarden globalt som leverandør av kjøretøysystemer, deler og tekniske løsninger. Mye av styrken vår kommer fra våre nære partnerskap med kjøretøyprodusenter, for hvem vi designer, utvikler og produserer toppmoderne systemer og komponenter over hele verden. Følgelig har Bosch sementert en sterk historie innen kjøretøyettermarkedsprodukter og støtte med en mangfoldig portefølje av deler, diagnoseløsninger og verkstedtjenester. Læring og utvikling er sikre nøkler til suksess, og denne filosofien er underbygget av et bredt spekter av opplæringskurs, innen alle områder innen bilteknologi, kjøretøysystemer og diagnostisk ekspertise.",
	SearchField_NoRecordsFound: "Ingen opptak funnet",
	TrainingCatalogue_FreeTextSearch: "Fri tekst",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Søk",
	CheckoutLayout_WholeSalerGroup: "Velg grossistgruppering",
	CreditInsufficientModal_Message: "Du har ikke tilstrekkelig kreditt for å bruke denne betalingsmåten. Prøv igjen med en annen betalingsmetode.",
	ShoppingCart_Required_Credits: "Totalpris",
	MaintainingInfo_Message1: "Nettstedet er i vedlikeholdsmodus.",
	MaintainingInfo_Message2: "Vi kommer snart tilbake.",
	CountryValidationModal_Message: "Du kunne ikke logge på [CountryA] ettersom kontoen din er opprettet i [CountryB].",
	CountryValidationModal_ButtonText: "Fortsett til [Country]",
	EventTable_LoginRequired: "Logg inn for å registrere deg",
	TrainingPartTableLayout_Date: "Dato",
	TrainingPartTableLayout_StartTime: "Starttid",
	TrainingPartTableLayout_EndTime: "Sluttid",
	TrainingPartTableLayout_Duration: "Varighet (timer)",
	TrainingPartTableLayout_TrainingMethod: "Opplæringsmetode",
	MyCoursesTableContents_ActiveHeader: "Aktive kurs",
	MyCoursesTableContents_ActiveTabHeader: "Aktiv",
	MyCoursesTableContents_ScheduledHeader: "Planlagte kurs",
	MyCoursesTableContents_ScheduledTabHeader: "Planlagt",
	MyCoursesTableContents_OnlineHeader: "Nettkurs",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Avlyste kurs",
	MyCoursesTableContents_CancelledTabHeader: "Avlyst",
	OnlineCoursesTable_Title_Progress: "Fremdrift",
	ScheduledCoursesTable_Title_Date: "Dato [dager]",
	CoursesTable_Title_Completed: "Fullført",
	Footer_TrainingProgram: "Treningsprogrammer",
	Breadcrumb_TrainingProgram: "Treningsprogrammer",
	TrainingProgram_Title_Table: "Tittel",
	TrainingProgram_TrainingProgram: "Treningsprogrammer",
	TrainingProgram_TargetGroup: "Målgruppe",
	TrainingProgram_MaxLength: "Lengde",
	TrainingProgram_Information: "Informasjon",
	TrainingProgram_Detail: "Detaljer",
	TrainingProgram_Modules: "Moduler",
	TrainingProgram_Course: "Kurs",
	TrainingProgram_PartNumber: "Artikkelnummer",
	TrainingProgram_Max_Length: "Maksimal lengde",
	TrainingProgram_Duration: "Varighet",
	TrainingProgram_Exam: "Eksamen",
	TrainingProgram_Assign_Popup: "Meld deg på treningsprogram",
	TrainingProgram_Student: "Student",
	TrainingProgram_Assign: "Tildele",
	TrainingProgram_Popup_Remove_Title: "Kansellere kurset?",
	TrainingProgram_Description: "Beskrivelse",
	TrainingProgram_Days: "Dager",
	TrainingProgram_Type: "Type",
	TrainingProgram_Action: "Handling",
	TrainingProgram_Enrolled: "har allerede blitt påmeldt dette opplæringsprogrammet",
	TrainingProgram_SelfAssign_Enrolled: "Du er allerede tildelt dette treningsprogrammet",
	TrainingProgram_Warning: "Registrer en annen student eller velg et annet opplæringsprogram.",
	TrainingProgram_Workshop: "Verksted",
	TrainingProgram_Durations: "år",
	TrainingProgram_Duration_DaysLabel: "dager",
	TrainingProgram_HasExam_Yes: "Ja",
	TrainingProgram_HasExam_No: "Nei",
	AssignModal_SuccessModal_Header: "Gratulerer!",
	AssignModal_SuccessModal_Message: "har vellykket blitt påmeldt dette treningsprogrammet.",
	AssignModal_SelfAssign_SuccessModal_Message: "Du har blitt tildelt opplæringsprogrammet.",
	Dashboard_MyTraningProgram: "Mitt treningsprogram",
	Dashboard_MyTraningProgram_Title: "Tittel",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Se",
	Dashboard_MyTraningProgram_Register: "Registrere",
	Dashboard_MyTraningProgram_Records: "Historiske poster",
	Dashboard_MyTrainingProgram_In_Progress: "Pågående",
	Dashboard_MyTrainingProgram_Accredited: "Akkreditert",
	Dashboard_Program_Modal_Withdraw: "Avbestille",
	Dashboard_Program_Modal_Grade: "Karakter",
	Dashboard_MyTrainingProgram_Registered: "Registrert",
	Dashboard_MyTrainingProgram_Completedd: "Fullført",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Ja",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nei",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Vær oppmerksom på at avbestilling betyr at du vil trekke deg fra hele treningsprogrammet, ikke bare fra et individuelt opplæringskurs.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Ønsker du fortsatt å trekke deg fra treningsprogrammet?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Avventer",
	WithdrawProgramModal_Withdraw_Success_Message: "Du har trukket deg fra treningsprogrammet!",
	Common_Warning_Title: "Advarsel!",
	Common_Error_Title: "Ups, noe gikk galt!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Legg i handlekurv",
	AddEventFromCatalogToCartModal_HoursText: "timer",
	AddElearningToCartSuccessModal_Message: "har blitt lagt til i handlekurven din!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Den valgte deltakeren er registrert på dette kurset, eller kurset er allerede lagt i handlekurven.",
	EventDetailTab_SubjectForChange_Message: "Med forbehold om endring",
	Dashboard_MyTrainingProgram_Status_Failed: "Ikke bestått",
	Dashboard_MyTrainingProgram_Status_Passed: "Bestått",
	Dashboard_MyTrainingProgram_Status_Merit: "Bragd",
	Dashboard_MyTrainingProgram_Status_Distinction: "Excellence",
	Dashboard_Employee_Courses: "Kurs",
	Dashboard_Employee_Program: "Utdanningssprogram",
	Dashboard_Employee_WaitList: "Venteliste",
	Dashboard_Employee_Account: "Konto",
	Course_Price_Free: "Gratis",
	Dashboard_Employee_Account_Update_Success_Message: "Du har oppdatert medarbeiderinformasjonen din!",
	Dashboard_Supplier: "Leverandør",
	Event_Detail_Comment: "Det er for øyeblikket ingen meldinger",
	DownLoad_PDF: "PDF download",
	TrainingCatalogue_Currency: "Valuta",
	My_Wallet: "Min Wallet",
	Wallet: "Wallet",
	Credits_Balance: "Credits balanse",
	MyFullCalendar_Today: "I dag",
	Header_Available_Languages: "Tilgjengelige språk",
	Header_CurrentRegion: "Du er nå i",
	Header_ChangeRegions: "Vil du skifte land?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Logg inn / Registrer",
	Header_RegisterCourse: "Påmelding",
	Homepage_Start: "Kom i gang",
	Homepage_Login: "Logg inn",
	MainPage_Title: "Den ultimate verkstedsløsningen",
	MainPage_Content: "Ditt verksted, ditt valg",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Gjør det mulig for et moderne verksted å jobbe profesjonelt, pålitelig og effektivt innen diagnose, feilsøking, reparasjoner og service på alle typer kjøretøy.",
	BoschNews_TrainingNews: "Training News",
	Service_Assist_Workshop: "Forbedre erfaringen din med Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Hva kan jeg gjøre med appen?",
	Enhance_Body_CDM3_Item_1: "Bestill kurs og vedlikehold din opplæringsplan",
	Enhance_Body_CDM3_Item_2: "Last ned kursmateriell og diplomer",
	Enhance_Body_CDM3_Item_3: "Start eLæringskurs",
	Enhance_Body_CDM3_Item_4: "Få adgang til forskjellge kjøretøytjenester i én mobilapp",
	Enhance_Body_CDM3_Item_5: "Live videooverføring med Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Gratis verktøy til automatisk kjøretøyskanning",
	Training_Stories: "Kurshistorier",
	SettingsMyAccount_JoinCompanyButton: "Bli med i firma",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Bli med i firma",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Vennligst legg inn aktiveringskoden under",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "NB! Du må kontakte administratoren for ditt firma for å motta en aktiveringskode.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Aktiveringen din har blitt behandlet og kontoen din er nå knyttet til firmaet din.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Klikk på Fortsett for å gå inn på nettstedet.",
	ContinueButton: "Fortsett",
	WelcomeBatsModal_Title: "Kontoen din er for øyeblikket til vurdering!",
	WelcomeBatsModal_ActivationLink: "Klikk her hvis du har en aktiveringskode til å bli med i et firma!",
	WelcomeBatsModal_ActivationLinkNotes: "NB! Du må kontakte administratoren for ditt firma for å motta en aktiveringskode.",
	AccountInReviewModal_Description_Paragraph1: "Takk for at du registrerte en personlig konto hos Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Vårt dedikerte opplæringspersonell gjennomgår og konfigurerer for øyeblikket kontoen din. Du vil motta endelig bekreftelse via e-post når kontoen din er konfigurert.",
	AccountInReviewModal_CloseButton: "Ok, slipp meg inn!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Vi kunne ikke validere aktiveringskoden og e-postadressen din.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Vennligst prøv igjen eller kontakt administratoren i ditt firma for assistanse.",
	TryAgain_Button: "Prøv igjen",
	Error_Title: "Ojda, noe gikk galt!",
	SettingsMyProfileActivationSuccessModal_Title: "Suksess!",
	AccountInReviewModal_Description_Paragraph3: "I mellomtiden kan du gjerne bla gjennom nettstedet vårt med begrenset tilgang.",
	AccountInReviewModal_Description_Paragraph4: "Hvis du har spørsmål, eller hvis kontoen din ikke blir behandlet innen 48 timer (unntatt helger og helligdager), kan du kontakte oss [her]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Litt veiledning for å hjelpe deg...",
	Support_FAQ_Headline: "Ofte stilte spørsmål",
	Support_FAQs_section1_title: "Generell",
	Support_FAQs_section1_question1: "Hva er Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions er en plattform som gir en omfattende oversikt over teknisk opplæring og gjør det mulig å bestille og administrere opplæring.",
	Support_FAQs_section1_question2: "Kan jeg bruke min eksisterende Bosch-konto (singleKey ID) for å logge på Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Ja, den er tilgjengelig med alle kontoer som er opprettet med \"Min Bosch ID\".",
	Support_FAQs_section1_question3: "Kan jeg bruke en konto opprettet for Bosch Training Solutions med andre Bosch-tjenester eller -applikasjoner?",
	Support_FAQs_section1_question3_answer1: "Ja, du kan bruke din personlige Bosch ID også til en rekke andre Bosch-applikasjoner - både profesjonelt og privat, for eksempel elsykkel eller smarthus.",
	Support_FAQs_section2_title: "Training",
	Support_FAQs_section2_question1: "Hvordan bestiller jeg et kurs?",
	Support_FAQs_section2_question1_answer1: "Logg på med din eksisterende konto ELLER registrer en konto i portalen. Gå til \"Training Services\" ELLER \"Aktivitetskalender\" velg en trening og klikk på legg til på handlekurv-knappen.",
	Support_FAQs_section2_question2: "Ingen passende kursdato er tilgjengelig. Hva kan jeg gjøre?",
	Support_FAQs_section2_question2_answer1: "Du har muligheten til å sette deg selv på en \"generell\" venteliste (uten å velge en dato), og teamet vårt vil informere deg så snart et nytt kurs er tilgjengelig.",
	Support_FAQs_section2_question3: "Hvor finner jeg en oversikt over mine bestilte kurs?",
	Support_FAQs_section2_question3_answer1: "Du finner en fullstendig oversikt over alle bestilte og fullførte kurs etter innlogging i dashbordet ditt.",
	Support_FAQs_section3_title: "Innstillinger",
	Support_FAQs_section3_question1: "Hvordan kan jeg endre e-post og passord?",
	Support_FAQs_section3_question1_answer1: "Du må logge inn på portalen og endre til din personlige profil. (I dashbordet kan du finne profilinformasjonen din eller hvis du klikker på følgende knapp i overskriften",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Der har du muligheten til å endre detaljene til din Bosch ID",
	Support_FAQs_section3_question2: "Hva kan jeg gjøre hvis jeg har glemt passordet mitt?",
	Support_FAQs_section3_question2_answer1: "Vennligst bruk påloggings-/registrer-knappen og fyll inn e-postadressen din",
	Support_FAQs_section3_question2_answer2: "Hvis du klikker på fortsett har du muligheten til å klikke på \"Glemt passord\"",
	Support_FAQs_section3_question3: "Hvordan sette opp en verkstedkonto?",
	Support_FAQs_section3_question3_answer1: "Hvis du ønsker å kunne få en verkstedrolle, ta kontakt med din lokale opplæringsadministrasjon, f.eks. kan du bruke kontaktskjemaet på nettsiden.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Velg ditt land/region",
	MaintenanceBanner_Message_1: "Bosch Automotive Training Solutions Portal vil bli oppdatert [maintenance_schedule]. Oppdateringen forventes å ta 30 minutter. Portalen vil ikke være tilgjengelig under oppdateringen.",
	MaintenanceBanner_Message_2: "\nFor ytterligere hjelp, vennligst kontakt oss på e-post [support_email]",
	MaintenancePage_Message_1: "Bosch Automotive Training Solutions Portal blir for tiden oppdatert",
	MaintenancePage_Message_2: "Vi forventer at portalen vil være tilgjengelig igjen  [maintenance_online_date] kl. [maintenance_online_time]",
	MenuHeader_Support: "Support",
	Course_Detail_No_Prerequisites: "Ingen forkunnskaper",
	TableCell_Hours: "Timer",
	Dashboard_MenuItem_Dashboard: "Dashboard",
	Dashboard_MenuItem_Curricula: "Læreplaner",
	Dashboard_MenuItem_Certificates: "Sertifikater",
	Dashboard_MenuItem_Team: "Team",
	Dashboard_MenuItem_Workshops: "Verksteder",
	Dashboard_MenuItem_External: "Ekstern",
	Dashboard_MenuItem_Wallet: "Lommebok",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Dine kommende kurs",
	Dashboard_Headline_YourELearningCourses: "Dine eLæringskurs",
	Dashboard_Headline_TeamOverview: "Teamoversikt",
	Dashboard_Headline_YourOverview: "Din oversikt",
	Dashboard_Headline_YourWishlist: "Din ønskeliste",
	Dashboard_Headline_ActivePrograms: "Aktive programmer",
	Dashboard_Headline_CompletedPrograms: "Fullførte programmer",
	Dashboard_Headline_YourCertificates: "Dine sertifikater",
	Dashboard_Headline_Transactions: "Transaksjoner",
	Dashboard_Table_NoRecordsToDisplay: "Ingen post å vise",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "timer",
	Dashboard_ELearningCourses_TableHeader_Title: "Tittel",
	Dashboard_ELearningCourses_TableHeader_Duration: "Varighet (timer)",
	Dashboard_ELearningCourses_ActionButton_View: "Vis",
	Dashboard_UpcomingCourses_TableHeader_Title: "Tittel",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Dato [dager]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Sted",
	Dashboard_UpcomingCourses_ActionButton_View: "Vis",
	Dashboard_YourOverview_TableHeader_Title: "Tittel",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Dato/varighet",
	Dashboard_YourOverview_TableHeader_Location: "Sted",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Vis",
	Dashboard_YourWishlist_TableHeader_Title: "Tittel",
	Dashboard_YourWishlist_TableHeader_Action: "Handling",
	Dashboard_YourWishlist_ViewAllRecords: "Vis alle poster",
	Dashboard_TeamOverview_TableHeader_Employee: "Ansatt",
	Dashboard_TeamOverview_TableHeader_Title: "Tittel",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Dato/varighet",
	Dashboard_TeamOverview_TableHeader_Location: "Sted",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Vis",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Søk",
	Dashboard_ActiveProgram_TableHeader_Title: "Tittel",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Vis",
	Dashboard_CompletedProgram_TableHeader_Title: "Tittel",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Vis",
	Dashboard_Certificates_TableHeader_Title: "Tittel",
	Dashboard_Certificates_TableHeader_Date: "Dato",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Handling",
	Dashboard_Certificates_ActionButton_Download: "Last ned",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Fakturanummer",
	Dashboard_Transactions_TableHeader_Description: "Beskrivelse",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Betalingsmetode",
	Dashboard_Transactions_TableHeader_Date: "Dao",
	Dashboard_Transactions_TableHeader_Amount: "Beløp",
	Dashboard_Transactions_ActionButton_Download: "Last ned",
	Breadcrumb_MyTraining: "MyTraining",
	Breadcrumb_MyTraining_CourseDetails: "Kursdetaljer",
	Breadcrumb_MyTraining_Curricula: "Læreplaner",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Kursprogramdetaljer",
	Breadcrumb_MyTraining_Team: "Team",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Vis ansatt",
	MyTraining_Headerbar_CourseDetails: "Kursdetaljer",
	MyTraining_Headerbar_TrainingProgramDetails: "Kursprogramdetaljer",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Samlet fremdrift",
	TrainingProgramDetails_ProgramItems_Headline: "Programelementer",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Tittel",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Karakter",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Dato",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Vis",
	TrainingProgramDetails_Status_InProgress: "Pågår",
	TrainingProgramDetails_Status_Completed: "Fullført",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Varighet",
	CourseDetails_Label_Trainer: "Kursinstruktør",
	CourseDetails_Label_Language: "Språk",
	CourseDetails_Label_Score: "Poengsum:",
	CourseDetails_Label_StartDate: "Startdato",
	CourseDetails_Label_EndDate: "Sluttdato",
	CourseDetails_Label_Venue: "Sted",
	CourseDetails_Label_Address: "Adresse",
	CourseDetails_Label_Grade: "Karakter",
	CourseDetails_Action_Withdraw: "Trekk tilbake",
	CourseDetails_Action_Evaluate: "Evaluer kurs",
	CourseDetails_Action_Evaluate_Description: "Vennligst evaluer dette kurset for å generere ditt sertifikat!",
	CourseDetails_Action_DownloadCertificate: "Last ned sertifikat",
	CourseDetails_Action_JoinWebcast: "Bli med i webcast",
	CourseDetails_Action_StartCourse: "Start kurs",
	CourseDetails_Action_RestartCourse: "Restart kurs",
	CourseDetails_Action_RepeatCourse_Description: "Gjenta og bestå dette kurset for å laste ned sertifikatet ditt!",
	CourseDetails_PartSpecificDetails_Headline: "Delspesifikke detaljer",
	CourseDetails_PartSpecificDetails_Tab_Part: "Del",
	CourseDetails_PartSpecificDetails_Description: "Beskrivelse",
	CourseDetails_PartSpecificDetails_StartDate: "Startdato",
	CourseDetails_PartSpecificDetails_EndDate: "Sluttdato",
	CourseDetails_PartSpecificDetails_StartTime: "Starttid",
	CourseDetails_PartSpecificDetails_EndTime: "Sluttid",
	CourseDetails_PartSpecificDetails_Method: "Metode",
	Dashboard_Team_TableHeader_Name: "Navn",
	Dashboard_Team_TableHeader_Role: "Rolle",
	Dashboard_Team_TableHeader_Email: "Epost",
	Dashboard_Team_TableHeader_PersonalAccount: "Personlig konto",
	Dashboard_Team_ActionButton_Select: "Velg",
	Dashboard_Team_PersonalAccount_Activated: "Aktivert",
	Dashboard_Team_PersonalAccount_NotActivated: "Ikke aktivert",
	Dashboard_Team_ActionButton_AddMember: "Legg til medlem",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Søk",
	Dashboard_External_TableHeader_Name: "Navn",
	Dashboard_External_ActionButton_AddNew: "Legg til ny",
	Dashboard_External_Headline: "Godkjent ekstern tilgang",
	Dashboard_Workshops_Headline: "Verksteder",
	Dashboard_Workshops_TableHeader_Company: "Firma",
	Dashboard_Workshops_TableHeader_Address: "Adresse",
	Dashboard_Workshops_TableHeader_Postcode: "Postnummer",
	Dashboard_Workshops_TableHeader_City: "Sted",
	Dashboard_Workshops_ActionButton_Select: "Velg",
	Wallet_ServiceCredits: "Service Credits",
	Wallet_Headline_CreditBalance: "Credit balanse",
	Wallet_Headline_CreditActivity: "Credit aktivitet",
	Wallet_Headline_BillingTransactions: "Faktureringstransaksjoner",
	Wallet_BillingTransaction_TableHeader_Order: "Ordre",
	Wallet_BillingTransaction_TableHeader_Date: "Dato",
	Wallet_BillingTransaction_TableHeader_Amount: "Beløp",
	Wallet_BillingTransaction_TableHeader_Action: "Handling",
	Wallet_CreditActivity_TableHeader_Date: "Dato",
	Wallet_CreditActivity_TableHeader_Description: "Beskrivelse",
	Wallet_CreditActivity_TableHeader_User: "Bruker",
	Wallet_CreditActivity_TableHeader_Amount: "Beløp",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, all rights reserved",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Produktsikkerhet (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovasjoner, patenter og lisenser",
	FooterAdditionalLinks_Navigation_Logistics: "Kjøp og logistikk",
	MenuHeader_MyTraining: "My Training",
	MenuHeader_TrainingServices: "Training Services",
	MenuHeader_OurLocations: "Våre steder",
	MenuHeader_ContactsUs: "Kontakt oss",
	MenuHeader_Logout: "Logg ut",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "My Bosch Id",
	MyProfile_AccountDeletion: "Slett konto",
	MyProfile_Edit: "Endre epost eller passord",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Endre brukerprofil",
	BackButton: "Tilbake",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Vi ser frem til samtalen din og hjelper deg gjerne. Vi er tilgjengelige for deg 24 timer, 7 dager i uken",
	ContactUs_Email_SecurityCodeConfirm_V3: "Skriv inn de riktige tegnene, som angitt i boksen ovenfor.",
	ContactUs_Email_MandatoryNotice_V3: "Fyll ut alle påkrevde felter.",
	ContactUs_Email_SendButton: "Send",
	Breadcrumb_TrainingCenters: "Kurssentre",
	Training_Centres_Book_Btn: "Bestill et kurs",
	Training_centres_booking_code: "Bestillingsreferanse",
	Training_centers_header_bar_title: "Våre kurssentre",
	Training_centers_filter_item: "Senter",
	TeamEdit_PersonalAccountToolTop: "Aktivering av denne funksjonen gir den ansatte tilgang til denne nettsiden. Brukeren vil kunne opprette en personlig konto og være en del av bedriftskontoen din. Når den er aktivert, kan du sende en invitasjon med en aktiveringskode til den ansatte. Invitasjonen sendes til e-postadressen.",
	TeamEdit_Update: "Aktivering av bedriftsadministratorfunksjonen gir automatisk denne ansatte full tilgang til bedriftskontoen din. Aktivering av denne funksjonen krever at brukeren logger ut og inn på nettstedet, hvis brukeren allerede er pålogget.",
	TeamEdit_PersonalAccount: "Tillat personlig Bosch Training Solutions konto",
	TeamEdit_SendInvitation: "Send invitasjon",
	TeamEdit_Deactivate: "Deaktiver",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Mobil må være tall",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telefon må være tall",
	TeamEdit_Activate: "Aktive",
	TeamEdit_NotActivated: "Ikke aktivér",
	TeamEdit_StatusPopup: "Statusen vil vises som \"Aktiv\" hvis brukeren har opprettet en personlig konto. Hvis statusen er satt til \"Ikke aktiv\", betyr det at brukeren ikke har fullført invitasjonen din.",
	Training_Catalog_Courses: "Kurs",
	Training_Catalog_Course: "Kurs",
	Reset_Filter: "Nullstill filter",
	TrainingCatalogue_CourseCatalogue: "Kurskatalog",
	CheckoutHeader_CartItemCount_Text: "Du har [count] produkter i handlevognen",
	CheckoutHeader_CartItemsCount_Text: "Du har [count] produkter i handlevognen",
	ShoppingCart_PriceItem: "Produktpris",
	ShoppingCartItemParticipants_AddParticipant_Button: "Legg til deltakere",
	ShoppingCartTotalPrice_SubTotalLabel: "Delsum",
	ShoppingCartTotalPrice_TaxesLabel: "Avgifter",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Inkludert [vat_amount] mva.",
	AddVoucher_AvailablePaymentMethods: "Tilgjengelige online betalingsmåter",
	ShoppingCartItemRow_VenueFeeLabel: "Stedsavgift [fee_amount] pr. deltaker",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Legg til deltaker",
	AddParticipantsModal_SelectEmployeeLabel: "Legg til deltaker",
	AddParticipantsModal_SelectWorkshopLabel: "Velg verksted",
	ShoppingCartTotalPrice_ContinueButtonText: "Fortsett til utsjekk",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Deltaker har ikke blitt lagt til!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Du har ett eller flere kurs i handlevognen uten deltaker",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Vennligst legg til en deltaker for å fortsette utsjekkingen",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Endre deltakere ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Navn",
	AddParticipantsModal_ParticipantTable_Email: "Epost",
	AddParticipantsModal_ParticipantTable_Action: "Handling",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Ingen deltakere…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Epost er ikke konfigurert på valgt deltaker",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Den valgte deltakeren har ikke en epostadresse registrert på profilen sin. Epostadresse er obligatorisk for å melde seg på et kurs.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Legg til epostadresse for å fortsette eller avbryt for å velge en annen deltaker",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Legg til epost",
	CancelButton: "Avbryt",
	AddParticipantsModal_AddEmployeeButtonText: "Legg til ansatt",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Du er i ferd med å slette et kurs fra handlevognen",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Du prøver å fjerne et kurs med deltakere fra handlevognen din.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Klikk \"Continue\" hvis du vil fortsette eller klikk \"Cancel\".",
	ShoppingCartItemHeader_Participants: "Deltaker€",
	ShoppingCartItemHeader_ParticipantPrice: "Pris pr. deltaker",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Vis i [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Vis i Credits",
	ShoppingCart_CreditsPriceUnit: "Credits",
	CheckOutHeader_Step1_Question: "Hvordan vil du betale?",
	CheckOutHeader_Step2_Question: "Se over bestillingen din",
	CheckOutHeader_Step3_Question: "Fullfør bestillingen din",
	CheckOutHeader_Step4_Question: "Bestillingen din var vellykket!",
	CheckOutHeader_Step_Payment: "Betaling",
	CheckOutHeader_Step_Review: "Se over",
	CheckOutHeader_Step_Place_Order: "Legg inn bestilling",
	CheckOutRightPanel_Review_Order_Btn: "Se over bestillingen",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Legg inn bestilling",
	CheckoutRightPanelContent_OrderSummary_Title: "Ordresammendrag",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Endre handlevogn",
	ShoppingCart_Participants: "Deltakere",
	CheckOutStepPayment_Payment_Methods_Title: "Velg betalingsmetode",
	CheckOutStepPayment_Credit_Title: "Kredit eller debet",
	CheckOutStepPayment_Wholesaler_Title: "Grossist",
	CheckOutStepPayment_ServiceCredit_Title: "Service Credits",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Du har [available_credit] Credits",
	CheckOutStepPayment_BoschAccount_Title: "Bosch Account",
	Checkout_Second_PaymentMethod_Headline: "Betalingsmetode",
	CheckOutStepReview_Edit: "Endre",
	CheckOutStepReview_Terms_Label: "Vilkår, betingelser og avbestillingsregler",
	CheckOutStepReview_Terms_Text: "Jeg har lest, forstått og godtar [[terms, conditions and cancellation policy] for salg.",
	SelectSecondPaymentMethodModal_Title: "Velg en sekundær betalingsmetode",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Betal [amount]",
	StepPaymentWholesaler_Title: "Velg grossist",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Godta vilkår og betingelser",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Vennligst les og godta våre vilkår, betingelser og kanselleringspolicy før du fortsetter.",
	CheckoutDescription_ThankYou: "Takk for at du sendte inn en bestilling på Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Våre bits og bytes har allerede begynt å behandle bestillingen din. I mellomtiden, vennligst hold motoren din på tomgang.",
	CheckoutDesctiption_Text: "Vi vil sende deg en endelig bekreftelse via e-post, inkludert alle detaljene for bestillingen din.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Du er i ferd med å fjerne det valgte verkstedet",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Ved å fjerne det valgte verkstedet vil alle tillagte deltakere bli tilbakestilt. Denne endringen vil påvirke alle kursene i handlekurven din.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klikk \"Continue\" hvis du vil fortsette, eller klikk \"Cancel\" for å stoppe denne handlingen.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Du har ikke nok Credits. Saldoen din er [available_credit] Credits, men du trenger [cart_total_credits] Credits for å fortsette utsjekkingen. Velg en annen betalingsmåte eller fjern varer fra handlekurven.",
	Training_centers_filter_items: "Sentre",
	Training_Programs_Filter_Item: "Programmer",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "År",
	Training_Program_Detail_Title: "Kursprogram",
	AccountButton_Workshops_MenuItem: "Verksteder",
	AccountButton_Wallet_MenuItem: "Lommebok",
	Header_MyBoschProfile_Header: "MyBosch profil",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Tilgjengelig",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Legg til/endre deltaker",
	ShoppingCart_CreditPriceUnit: "Credit",
	Common_IncludedVAT: "Inkl. mva.",
	TrainingCatalogue_Length: "Lengde",
	TrainingCatalogue_Method: "Metode",
	Course_Price_Credits: "Credits",
	Catalogue_Grid_Register_Button: "Registrere",
	Catalogue_Credit_Tilte: "Klikk her for å vise priser i Credits",
	Catalogue_Price_Tilte: "Klikk her for å vise priser i EUR",
	Catalogue_Grid_Vat: "Eks. mva.",
	EventCalendar_Seats: "Plasser",
	MenuHeader_Calendar: "Kalender",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Minutter",
	ListFilter_NoResult: "Ingen resultat",
	Course_Description: "Kursbeskrivelse",
	Training_course_detail: "Grunnleggende om sosiale medier",
	Training_course_detail_add_waiting: "Legg til på generell venteliste",
	Register_now_btn: "Registrer nå!",
	Register_now_btn_add_event_to_cart: "Registrer nå!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Vis alle poster",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Vis færre",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Vis alle poster",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Vis færre",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Vis alle poster",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Vis færre",
	TeamCreate_TitleError: "Tittel er påkrevet",
	Dashboard_Headerbar_BookCourse: "Bestill kurs",
	Dashboard_Headerbar_ExitProxy: "Avslutt proxy",
	Dashboard_Headerbar_EvaluateCourse: "Evaluer kurs",
	Dashboard_Headerbar_EvaluateCourse_Note: "Vennligst evaluer dette kurset for å generere ditt sertifikat!",
	Dashboard_Headerbar_DownloadCertificate: "Last ned sertifikat",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Trekk tilbake",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Trekk tilbake",
	Dashboard_Headerbar_WelcomeMessage: "Velkommen til ditt lærings-dashbord",
	Dashboard_Headerbar_ProxingAs: "Fullmakt som",
	Dashboard_TrainingProgram_Status_Completed: "Fullført",
	Dashboard_TrainingProgram_Status_InProgress: "Pågår",
	External_SelectWholesalerModal_Title: "Legg til adgang for ekstern bruker",
	External_SelectWholesalerModal_Company_Label: "Firma",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Vennligst velg",
	External_SelectWholesalerModal_Outlet_Placeholder: "Vennligst velg",
	External_SelectWholesalerModal_ApplyButton: "Søk om",
	External_SelectWholesalerModal_CancelButton: "Avbryt",
	External_RemoveWholesalerModal_ConfirmLabel: "Bekreftelse",
	External_SelectWholesalerModal_ConfirmMessage: "Vil du fjerne denne grossisten?",
	External_SelectWholesalerModal_YesButton: "Ja",
	External_SelectWholesalerModal_NoButton: "Nei",
	Training_program_team_member: "Team-medlem",
	Training_program_assign_training_program: "Tildel kursprogram",
	Assign_modal_student_confirmation_text1: "Du er i ferd med å tilordne [Training Program Name] til læringsreisen din.",
	Assign_modal_student_confirmation_text2: "Vil du fortsette?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "Mai",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Jul",
	MyFullCalendar_Aug: "Aug",
	MyFullCalendar_Sep: "Sep",
	MyFullCalendar_Oct: "Okt",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Des",
	MenuHeader_CourseCatalog: "Kurskatalog",
	MenuHeader_Event: "Kurskalender",
	MenuHeader_Program: "Kursprogram",
	MenuHeader_Services: "Kurstjenester",
	MenuHeader_Log_out: "Logg ut",
	Filter_Location: "Sted",
	TeamEdit_UpgradeToCompanyAdministrator: "Oppgradér til firmaadministrator",
	TeamEdit_PersonalWebAccountStatusIs: "Status for personlig konto er",
	Header_CreateNewEmployee: "Opprett ny ansatt",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Ingen plasser ledig!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Det er ingen ledige plasser på det valgte arrangementet.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Velg et annet arrangement eller fjern en allerede lagt til deltaker for å fortsette.",
	Common_SuccessModal_Title: "Gratulerer!",
	TrainingProgramDetails_Merit_StatusText: "Meritt",
	TrainingProgramDetails_Resit_StatusText: "Gjenta",
	EmployeeProfile_UserExistErrorMessage: "Kontoen eksisterer allerede!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Betalingen feilet. Prøv igjen eller bruk en annen betalingsmåte.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Du har deaktivert den valgte medarbeideren!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Lagret!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Dine endringer ble lagret.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Tilbaketrekking ikke mulig på eLæringskurs. Ta kontakt med oss hvis ytterligere detaljer er nødvendig.",
	Dashboard_Team_PersonalAccount_Pending: "Venter",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Ansatt opprettet!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Ansatt opprettet.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Denne innstillingen lar deg begrense en ansatt fra å registrere seg til kurs. Kun firmaadministratorer vil kunne registrere den ansatte til opplæringsarrangementer når denne innstillingen er fjernet.",
	Training_catalog_sort_by: "Sorter etter",
	CourseDetail_EvaluationRestriction_Title: "Kursevaluering ikke mulig!",
	CourseDetail_EvaluationRestriction_Message: "Evalueringer kan kun sendes inn av studenten selv. \nInstruer studenten om å logge på Bosch Automotive Training Solutions og fullføre evalueringen.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Hvis studenten ikke allerede har en konto, kan en invitasjon deles i brukerprofilinnstillingene til teammedlemmet.",
	Breadcrumb_Download_Page: "Last ned",
	Download_Table_Category_Cell: "Kategori",
	Download_Table_Title_Cell: "Tittel",
	Download_File_Filter_Item: "Fil",
	Download_File_Filter_Items: "Filer",
	Download_Header: "Nedlastningsområde",
	DownloadArea_Button_Download: "Last ned",
	Popup_Title_Error: "Feil",
	Popup_Title_Success: "Suksess",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Advarsel!",
	TrainingProgram_PDF_Export: "PDF eksport",
	TrainingProgram_PDF_Export_Participant: "Deltaker:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Total fremdrift:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Kurstittel",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Karakter",
	TrainingProgram_PDF_Export_TableHeader_Date: "Dato",
	AccountButton_CompanyChange_MenuItem: "Endre firma",
	SelectCompanyModal_Title: "Velg firma",
	CloseButton: "Lukk",
	Change_Button: "Endre",
	ChangeManagerModal_Content: "Velg bruker",
	ChangeCompanyModal_HeadOffice: "(Hovedkontor)",
	SelectCompanyModal_Content: "Vennligst velg firma for å logge inn",
	AlertSuccess_Title: "Suksess",
	CheckOutStepPayment_SecondaryPayment_Title: "Velg en sekundær betalingsmåte",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Legg til sekundær betalingsmåte",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Legg til sekundær betalingsmåte nedenfor eller velg en annen betalingsmåte ovenfor.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Du har ikke nok Service Credits på kontoen din til å betale for handlekurven. For å fortsette å bruke den valgte betalingsmetoden, må du betale differansen. Forskjellen er [difference_amount] (eks. mva).",
	ShoppingCartTotalPrice_TotalInCredit: "Totalpris i Credits",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Dine tilgjengelige Credits",
	ShoppingCartTotalPrice_YourDifference: "Differanse",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal eks. mva.",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal inkl. mva.",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Sekundær betalingsmåte",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Rediger betalingsmåte",
	Checkout_Second_PaymentMethod: "Andre betalingsmåte",
	Breadcrumb_TrainingCourseDetails: "Kursdetaljer",
	DownloadArea_FailedToDownload_ErrorMessage: "Det mislyktes å laste ned dokumentet. Vennligst prøv igjen eller kontakt din firmaadministrator for å få hjelp.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Nedlasting av fil er ikke tilgjengelig. Vennligst prøv igjen eller kontakt din firmaadministrator for å få hjelp.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Nedlasting av fil er ikke tillatt. Vennligst prøv igjen eller kontakt din firmaadministrator for å få hjelp.",
	SSO_Redirecting_Message: "Du omdirigerer til Bosch Training portal...",
	SSO_Redirecting_ParameterErrorMessage: "Land og/eller språk er ikke spesifisert. Vennligst prøv igjen eller kontakt din firmaadministrator for å få hjelp.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Påmeldingsønske(r) ikke akseptert.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Dette kan skyldes at man har passert påmeldingsfristen for ett eller flere av kursene. Vennligst bruk \"Kontakt oss\"-området hvis du har spørsmål eller ønsker.",
	TimeSlotPicker_DateFormat_Title: "Datoformat",
	UpdateAccountSettingsConfirmationModal_Title: "Du er i ferd med å endre datoformatet!",
	UpdateAccountSettingsConfirmationModal_Message1: "Datoformatet vil vises i henhold til ditt valg og kan endres når som helst.",
	UpdateAccountSettingsConfirmationModal_Message2: "Ønsker du å fortsette?",
	ProfileSummary_Settings_Title: "Innstillinger",
	UpdateAccountSettings_DateFormat_Title: "Datoformatinnstillinger",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Det mislyktes å oppdatere datoformatet.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Datoformatinnstillingen ble oppdatert.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Last ned sertifikat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Sertifikat kursprogram",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Det mislyktes å laste ned dokumentet. Vennligst prøv igjen eller kontakt din firmaadministrator for å få hjelp.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Uttagströskeln har överskridits",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Avbestilling avventer fordi avbestillingsfristen er overskredet.Studenten vil motta ytterligere informasjon på epost når forespørselen er godkjent/avslått.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Se vår [avbestillingspolitikk] for ytterligere detaljer.",
	TrainingCourse_Course_NotAvailable_Title: "Kurset er ikke tilgjengelig.",
	TrainingCourse_Course_NotAvailable_Message: "Kurset er ikke tilgjengelig. Vennligst prøv igjen eller kontakt din firmaadministrator for å få hjelp.",
	TrainingCourse_NotAvailable_Message_1: "Du prøver å få tilgang til et kurs som ikke er tilgjengelig.",
	TrainingCourse_NotAvailable_Message_2: "Vennligst prøv igjen eller gå til [kurskatalog] for å utforske flere opplæringskurs.",
	LanguageSelection_SelectALanguage: "Velg et språk",
	LanguageSelection_SelectYourCountry: "Velg ditt land",
	Checkout_PaymentMethod_Headline: "Betalingsmetode",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalender",
	Download_Categories_General: "Generelt",
	Download_Categories_Information: "Informasjon",
	Download_Categories_Userguides: "Brukerveiledninger",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "avbestillingsregler",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Avbryt",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Fortsett",
	Duration_Seconds: "Sekunder",
	Duration_Day: "Dag",
	Duration_Hour: "Time",
	Duration_Minute: "Minutt",
	Duration_Second: "Sekund",
	EventCalendar_ExternalLink_Tooltips: "Kjøp via [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "Ikke tilgjengelig",
	EventCalendar_ExternalLink_Cancel: "Avbryt",
	EventCalendar_ExternalLink_Continue: "Fortsett",
	EventCalendar_ExternalLinkModal_Title: "Du blir videresendt...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Det valgte arrangementet blir solgt via [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Ved å fortsette vil du bli videresendt til en ekstern nettside.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Vil du fortsette?",
	Team_ContactPerson_FirstName_Tooltips: "Navn på student kan kun endres av kursadministrasjonen i Bosch. Vennligst kontakt oss via kontaktformularet her på siden for videre hjelp.",
	Team_ContactPerson_LastName_Tooltips: "Navn på student kan kun endres av kursadministrasjonen i Bosch. Vennligst kontakt oss via kontaktformularet her på siden for videre hjelp.",
	Withdraw_ExternalLink_Cancel: "Avbryt",
	Withdraw_ExternalLink_Confirm: "Bekreft",
	Withdraw_ExternalLinkModal_Title: "Du blir videresendt",
	Withdraw_ExternalLinkModal_Message: "Kanselleringer for dette kurset håndteres av en ekstern leverandør.",
	Withdraw_ExternalLinkModal_Confirmation: "Klikk på \"Confirm\" vil åpne en ny fane og sende deg til en ekstern nettside.",
	Button_AddToWaitingList_Tooltips: "Legg til på venteliste",
	Button_AddToCart_Tooltips: "Legg i handlekurv",
	Button_TrainingCourseDetail_Tooltips: "Vis detaljer",
	Button_TrainingProgramDetail_Tooltips: "Vis detaljer",
	Button_AssignTrainingProgram_Tooltips: "Tildel kursprogram",
	Button_DeleteFromCart_Tooltips: "Slett fra handlekurv",
	Button_RemoveParticipant_Tooltips: "Fjern deltaker",
	Button_DownloadBillingTransaction_Tooltips: "Last ned",
	Button_RemoveExternal_Tooltips: "Fjern",
	Breadcrumb_TrainingServices: "Trening Service",
	Breadcrumb_Support: "Support",
	Breadcrumb_FAQs: "FAQ",
	Breadcrumb_TrainingProgramDetail: "Trening Service Detaljer",
	Breadcrumb_MyTraining_MyTrainingEvents: "Mine kurs",
	Breadcrumb_MyTraining_External: "Ekstern",
	Breadcrumb_MyTraining_Wallet: "Lommebok",
	Breadcrumb_MyTraining_Workshop: "Verksted",
	Breadcrumb_MyTraining_Workshop_Team: "Team",
	Breadcrumb_MyTraining_Team_Profile: "Profil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Rediger profil",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Legg til medarbeider",
	SideNavigation_Title: "Training Solutions",
	SideNavigation_AccountButton_RegisterOrLogin: "Registrer – Login",
	SideNavigation_Language: "Språk",
	SideNavigation_SelectLanguage: "Velg språk",
	SideNavigation_AccountButton_Logout: "Log ut",
	SideNavigation_ConntactUs: "Kontakt oss",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Legg til informasjon",
	FooterAdditionalLinks_Navigation_LegalNotice: "Juridiske betingelser",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Merknad om databeskyttelse",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Personverninnstillinger",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Vilkår og betingelser",
	Common_Version_Text: "Versjon {{versjon}}",
	Content_Header_Dashboard_MyTrainingEvents: "My Training Events",
	Content_Header_Dashboard_MyTrainingPrograms: "My Training Programs",
	Content_Header_Dashboard_Team: "Team",
	Content_Header_Dashboard_Workshops: "Verksted",
	Content_Header_Dashboard_External: "Ekstern",
	Content_Header_Dashboard_Wallet: "Lommebok",
	Content_Header_Dashboard_MyProfile: "Min profil",
	RedeemVoucher_RemoveButton: "Fjern",
	RedeemVoucher_ApplyButton: "Bruk",
	Checkout_VoucherApplied_Label: "Din kode",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Ugyldig kupongkode",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Kupongkoden som ble brukt er ugyldig.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Prøv igjen eller fortsett uten kupongkode.",
	RedeemVoucherModal_CancelButton: "Annuller",
	RedeemVoucherModal_TryAgainButton: "Prøv igjen",
	CreditsConfirmationModal_ContinueButton: "Fortsett",
	CreditsConfirmationModal_CancelButton: "Annuller",
	CreditsConfirmationModal_Title: "En kupong er brukt",
	CreditsConfirmationModal_Message_1: "Betaling med tjenestekreditter er ikke mulig når en rabattkupong er lagt på handlekurven din.",
	CreditsConfirmationModal_Message_2: "Klikk \"Avbryt\" for å gå tilbake eller klikk \"Fortsett\" for å fjerne kupongen fra handlekurven."
};