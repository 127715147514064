import axios from "axios";
const qs = require('qs');

export const httpNoneSecure = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

httpNoneSecure.interceptors.request.use(
  async (config) => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

httpNoneSecure.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    return Promise.reject(error);
  }
);

// export default httpNoneSecure;