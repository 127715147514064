import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Grid, Modal, Radio, Table, TextArea } from "semantic-ui-react";
import "./CourseEvaluationModal.css";
import {
  CsiQuestion,
  CsiQuestionAnswers,
  ReplyOption,
} from "../../../../models/TrainingEvent";
import {
  selectAuthState,
  selectTimeZoneId,
} from "../../../../redux-toolkit/authReducer";
import { selectAccount } from "../../../../redux-toolkit/accountReducer";
import { Constants } from "../../../../helpers/Constants";
import Boschicon from "../../../BoschIcon/Boschicon";
import { AppDispatch, useAppDispatch } from "../../../../redux-toolkit/store";
import {
  getCsiAnsweredSheetAsync,
  getCsiQuestionsAsync,
  postCsiAnsweredSheet,
} from "../../../../redux-toolkit/trainingEventReducer";
import { RequestCsiAnsweredSheet } from "../../../../models/trainingEvent/RequestCsiAnsweredSheet";
import ResponseResultBase from "../../../../models/ResponseResultBase";
import { RequestCsiQuestions } from "../../../../models/trainingEvent/RequestCsiQuestions";
import { RequestPostCsiAnswers } from "../../../../models/trainingEvent/RequestPostCsiAnswers";
import {
  selectEnrollmentDetail,
  selectEnrollmentId,
} from "../../../../redux-toolkit/dashboardReducer";
import RDAlert from "../../../RDComponents/RDAlert";
import RDButton from "../../../RDComponents/RDButton";
interface CourseEvaluationModalProps {
  onDisplayModal: boolean;
  onCloseModalTrigger: Function;
  employeeCustomerId: number;
  employeeStudentId: number;
}

const CourseEvaluationModal: FC<CourseEvaluationModalProps> = (props) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const currentAccount = useSelector(selectAccount);
  const timeZoneId = useSelector(selectTimeZoneId);
  const authState = useSelector(selectAuthState);
  const selectedEnrollmentDetail = useSelector(selectEnrollmentDetail);
  const selectedEnrollmentId = useSelector(selectEnrollmentId);

  const [questionList, setQuestionList] = useState<CsiQuestion[]>([]);
  const [radioTypeQuestionOptions, setRadioTypeQuestionOptions] = useState<
    ReplyOption[]
  >([]);
  const [modalMessage, setModalMessage] = useState("");
  const [isFormsubmitting, setIsFormSubmitting] = useState(false);
  const [isUserAnswered, setIsUserAnswered] = useState(false);
  const [informationModalType, setInformationModalType] = useState<
    "warning" | "success" | "error"
  >("success");
  const [openAnswerSheetModal, setOpenAnswerSheetModal] = useState(false);
  useEffect(() => {
    if (selectedEnrollmentDetail) {
      //Request Model
      const getCsiAnsweredSheet: RequestCsiAnsweredSheet = {
        ciamId: currentAccount?.ciamId || "",
        customerId: props.employeeCustomerId,
        country: authState?.countryCode || "",
        language: authState?.language || "",
        timeZoneId: timeZoneId,
        eventId: selectedEnrollmentDetail?.eventDetails?.eventId || 0,
        studentId: props.employeeStudentId || 0,
      };

      dispatchReduxToolkit(getCsiAnsweredSheetAsync(getCsiAnsweredSheet)).then(
        (response) => {
          if (!!response.payload) {
            const csiAnsweredSheet =
              response.payload as ResponseResultBase<CsiQuestionAnswers>;
            if (
              csiAnsweredSheet &&
              csiAnsweredSheet.dataObject &&
              csiAnsweredSheet.dataObject.questionAnswers
            ) {
              setIsUserAnswered(true);
              setModalMessage(t("EvaluationTab_FeedbackHasBeenGiven_Message"));
              setInformationModalType("warning");
              let csiQuestion: CsiQuestion[] = csiAnsweredSheet.dataObject
                .questionAnswers as CsiQuestion[];
              setQuestionList(csiQuestion);
              setOpenAnswerSheetModal(false);
            } else {
              loadCsiQuestions();
            }
          } else {
            loadCsiQuestions();
          }
        }
      );
    }
  }, []);

  const loadCsiQuestions = () => {
    if (selectedEnrollmentDetail) {
      const getCsiQuestionsInput: RequestCsiQuestions = {
        ciamId: currentAccount?.ciamId || "",
        customerId: props.employeeCustomerId,
        country: authState?.countryCode || "",
        language: authState?.language || "",
        timeZoneId: timeZoneId,
        eventId: selectedEnrollmentDetail?.eventDetails?.eventId || 0,
      };

      dispatchReduxToolkit(getCsiQuestionsAsync(getCsiQuestionsInput)).then(
        (response) => {
          if (!!response.payload) {
            let questionData = response.payload;
            let csiQuestion: CsiQuestion[] = questionData as CsiQuestion[];

            setQuestionList(csiQuestion);
            const radioTypeQuestion = csiQuestion.find(
              (q) =>
                q.replyTypeId === Constants.CSIQUESTION_DEFAULTLIST_QUESTIONTYPE
            );
            setRadioTypeQuestionOptions(radioTypeQuestion?.replyOptions || []);
            setOpenAnswerSheetModal(true);
          } else {
            setQuestionList([]);
          }
        }
      );
    }
  };

  const RadioInputChangeHandler = (value: any, csiQuestion: CsiQuestion) => {
    let receiveQuestionList = [...questionList];
    csiQuestion.replyInput = value;
    receiveQuestionList = receiveQuestionList.map((q) =>
      q.id === csiQuestion.id ? csiQuestion : q
    );
    setQuestionList(receiveQuestionList);
  };

  const enteredAnsweredHandler = (event: any, csiQuestion: CsiQuestion) => {
    let receiveQuestionList = [...questionList];
    csiQuestion.replyInput = event.target.value;
    receiveQuestionList = receiveQuestionList.map((q) =>
      q.id === csiQuestion.id ? csiQuestion : q
    );
    setQuestionList(receiveQuestionList);
  };

  const validateCsiAnsweredSubmit = () => {
    const inValidQuestion = questionList.find(
      (q) => q.isMandatory && !q.replyInput
    );
    return !inValidQuestion;
  };

  const SubmitAnsweredChangeHandler = (e: any) => {
    e.preventDefault();
    if (validateCsiAnsweredSubmit()) {
      setIsFormSubmitting(true);
      const postModel: RequestPostCsiAnswers = {
        ciamId: currentAccount?.ciamId || "",
        customerId: props.employeeCustomerId || 0,
        country: authState?.countryCode || "",
        language: authState?.language || "",
        timeZoneId: timeZoneId,

        answeredSheet: {
          id: 0,
          trainingCourseId:
            selectedEnrollmentDetail?.eventDetails?.courseId || 0,
          trainingEventId: selectedEnrollmentDetail?.eventDetails?.eventId || 0,
          enrollmentId: selectedEnrollmentId || 0,
          trainingDate: new Date(
            selectedEnrollmentDetail?.eventDetails?.startDateTime
              ? selectedEnrollmentDetail?.eventDetails?.startDateTime
              : ""
          ),
          studentId: props.employeeStudentId || 0,
          trainerId: 0,
          trainingRevision: "",
          questionAnswers: questionList,
        },
        rating: 0,
      };

      dispatchReduxToolkit(postCsiAnsweredSheet(postModel))
        .then((response) => {
          if (response.payload) {
            const result =
              response.payload as ResponseResultBase<number> | null;
            if (result && result.success) {
              setModalMessage(
                t("EvaluationTab_CsiAnsweredSubmit_SuccessMessage")
              );
              setInformationModalType("success");
              setIsUserAnswered(true);
            } else {
              setModalMessage(
                t("EvaluationTab_CsiAnsweredSubmit_ErrorMessage")
              );
              setInformationModalType("error");
            }
          } else {
            setModalMessage(t("EvaluationTab_CsiAnsweredSubmit_ErrorMessage"));
            setInformationModalType("error");
          }
        })
        .finally(() => {
          setIsFormSubmitting(false);
        });
    } else {
      setModalMessage(
        t("EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage")
      );
      setInformationModalType("error");
    }
  };

  const closeInformationModal = () => {
    props.onCloseModalTrigger();
  };

  return (
    <Fragment>
      {openAnswerSheetModal && (
        <Modal
          onClose={closeInformationModal}
          open={openAnswerSheetModal}
          dimmer={{ className: "test-dimmer course-evaluation-modal" }}
          size={"large"}
          style={{ padding: "1rem 0 0 0" }}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            <span>{selectedEnrollmentDetail?.eventDetails?.trainingName}</span>
            <Button
              className="close_button"
              icon={
                <Boschicon
                  name="bosch-close"
                  className="Boschicon close-icon"
                />
              }
              onClick={closeInformationModal}
            ></Button>
          </Modal.Header>
          <Modal.Content className="course-evaluation-modal-content">
            <Grid className="evaluation-wrapper">
              <Grid.Row className="annotations">
                <p>{t("EvaluationTab_Annotation")}</p>
                <p>{t("EvaluationTab_Notice")}:&nbsp;</p>
                <div className="notice">
                  {radioTypeQuestionOptions.map((x, index) => (
                    <p key={index}>
                      {x.replyValue} = {x.replyTextLocalized}
                    </p>
                  ))}
                </div>
              </Grid.Row>
              <Grid.Row className="venue-row" verticalAlign="bottom">
                <Table
                  style={{ border: "none", tableLayout: "fixed" }}
                  className="table-responsive radio-table-header"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        className="radio-table-header non-padding"
                        colSpan={3}
                      ></Table.HeaderCell>
                      <Table.HeaderCell className="radio-table-header non-padding">
                        <Table className="radio-table-header">
                          <Table.Header>
                            <Table.Row>
                              {radioTypeQuestionOptions.map((x, index) => (
                                <Table.HeaderCell
                                  className="radio-table-header"
                                  textAlign="center"
                                  key={index}
                                >
                                  {x.replyValue}
                                </Table.HeaderCell>
                              ))}
                            </Table.Row>
                          </Table.Header>
                        </Table>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {questionList.map((q: CsiQuestion, qindex) => {
                      switch (q.replyTypeId) {
                        case Constants.CSIQUESTION_DEFAULTLIST_QUESTIONTYPE:
                          return (
                            <Table.Row key={qindex}>
                              <Table.Cell className="non-padding" colSpan={3}>
                                {q.questionNumber}. {q.questionLocalized}
                                {!q.isMandatory ? (
                                  <span></span>
                                ) : (
                                  <span style={{ color: "red" }}>(*)</span>
                                )}
                              </Table.Cell>
                              <Table.Cell className="pbottom-none non-padding">
                                <Table
                                  className="radio-table table-responsive"
                                  style={{
                                    tableLayout: "fixed",
                                    border: "none",
                                  }}
                                >
                                  <Table.Body>
                                    <Table.Row>
                                      {radioTypeQuestionOptions.map(
                                        (x, index) => (
                                          <Table.Cell
                                            className="b-none"
                                            key={index}
                                            textAlign="center"
                                          >
                                            <div className="data-cell col-direction">
                                              <label>{x.replyValue}</label>
                                              <Radio
                                                disabled={isUserAnswered}
                                                className="radio-custom-position"
                                                name={`radioGroup-${q.id}`}
                                                value={x.replyValue?.toString()}
                                                style={{ paddingBottom: "0" }}
                                                checked={
                                                  q.replyInput ===
                                                  (x.replyValue?.toString() ||
                                                    "")
                                                }
                                                onChange={(e, { value }) => {
                                                  RadioInputChangeHandler(
                                                    value,
                                                    q
                                                  );
                                                }}
                                              ></Radio>
                                            </div>
                                          </Table.Cell>
                                        )
                                      )}
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                              </Table.Cell>
                            </Table.Row>
                          );
                        case Constants.CSIQUESTION_TEXT_QUESTIONTYPE:
                          return (
                            <Table.Row key={qindex}>
                              <Table.Cell colSpan={4} className="non-padding">
                                <p className="text-question">
                                  {q.questionNumber}. {q.questionLocalized}
                                  {!q.isMandatory ? (
                                    <span></span>
                                  ) : (
                                    <span style={{ color: "red" }}>(*)</span>
                                  )}
                                </p>
                                <TextArea
                                  disabled={isUserAnswered}
                                  onChange={(e) => {
                                    enteredAnsweredHandler(e, q);
                                  }}
                                  rows={3}
                                  className="text-are-custom"
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        default:
                          return <></>;
                      }
                    })}
                  </Table.Body>
                </Table>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <RDButton secondary color="blue" onClick={closeInformationModal}>
              {t("CommonButton_Close")}
            </RDButton>
            {!isUserAnswered && (
              <RDButton
                content="Submit"
                onClick={SubmitAnsweredChangeHandler}
                primary
                className="button-submit-custom"
                loading={isFormsubmitting}
                disabled={isFormsubmitting || isUserAnswered}
              />
            )}
          </Modal.Actions>
        </Modal>
      )}
      {!!modalMessage && (
        <RDAlert
          size="tiny"
          type={informationModalType}
          isOpen={!!modalMessage}
          title={selectedEnrollmentDetail?.eventDetails?.trainingName || ""}
          messages={[modalMessage]}
          acceptButtonText={t("CurrentUserAccountDeactivationModal_Yes")}
          onAccept={() => {
            if (informationModalType === "error") {
              setModalMessage("");
            } else {
              setModalMessage("");
              props.onCloseModalTrigger();
            }
          }}
        />
        /* <InformationModal 
                popupTitle={selectedEnrollmentDetail?.eventDetails?.trainingName || ''}
                type = {informationModalType}
                isDisplayModal = {!!modalMessage}
                informationMessage = {modalMessage}
                setCloseModalTrigger={() => {
                    if(informationModalType === "error"){
                        setModalMessage("");
                    }else{
                        setModalMessage("");
                        props.onCloseModalTrigger();
                    }
            }}/> */
      )}
    </Fragment>
  );
};

export default CourseEvaluationModal;
