import { FC, Fragment } from "react";
import AddElearningEventFromCatalogModal from "./AddElearningEventFromCatalogModal";
import AddScheduledEventFromCatalogToCartModal from "./AddScheduledEventFromCatalogToCartModal";

interface AddEventFromCatalogToCartModalProps {
    onDisplayModal: boolean;
    courseData: any;
    onCloseModalTrigger: Function;
}

const AddEventFromCatalogToCartModal: FC<AddEventFromCatalogToCartModalProps> = (props) => {
    return (
        <Fragment>
            {props.courseData?.isElearning ? (
                <AddElearningEventFromCatalogModal onDisplayModal={props.onDisplayModal} courseData={props.courseData} onCloseModalTrigger={props.onCloseModalTrigger}/>
            ) : (
                <AddScheduledEventFromCatalogToCartModal onDisplayModal={props.onDisplayModal} courseData={props.courseData} onCloseModalTrigger={props.onCloseModalTrigger}/>
            )}
        </Fragment>
    )
}

export default AddEventFromCatalogToCartModal;