import { Icon, MinimalHeader } from "@bosch/react-frok";
import style from "./AppWrapper.module.scss";
import Styles from "./Header.module.css";
import SideNavigation from "../sideNavigation/SideNavigation";
import Footer from "../footer/Footer";
import { useSelector } from "react-redux";
import {
  selectBatsVersion,
  selectCountryCode,
  selectLanguage,
  selectOpenSideNav,
  selectIsSignIn,
  selectTimeZoneId,
  selectMainContentPinned,
} from "../../redux-toolkit/authReducer";
import {
  selectCiamEmail,
  selectAccount,
  selectAllowSelfEnroll,
  selectDisplayCredit,
  selectUserRoleId,
  selectOnSubWorkshopBranch,
  selectCiamId,
} from "../../redux-toolkit/accountReducer";

import RDFrokBreacrumbs from "../../components/RDComponents/RDFrokBreacrumbs/RDFrokBreacrumbs";
import { useEffect, useMemo, useRef, useState } from "react";
import { RouteConfigure } from "../../models/Route";
import { useRouting } from "../../hooks/useRouting";
import {
  selectIsForcedToHideNotificationBanner,
  updateForcedToHideNotificationBanner,
} from "../../redux-toolkit/maintenanceReducer";
import { Message, Popup as PopupNew } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
//import { useAppDispatch } from "../../redux/hooks";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { getHeightOfComponent } from "../../utils/dom.util";
import HeaderActions from "../headerActions/HeaderActions";
import { Constants } from "../../helpers/Constants";
import { TrainingEventHelper } from "../../helpers/TrainingEventHelper";
import PriceTag from "../../Common-components/TrainingCatalogue/PriceTag";
import AddEventFromCatalogToCartComponent from "../../Common-components/ShoppingCart/AddEventFromCatalogToCart.Component";
import RDButton from "../../components/RDComponents/RDButton";
import { AuthService } from "../../services/AuthService";
import { useHistory } from "react-router-dom";

import {
  clearEventIdFromCart,
  clearRegisteredEmployee,
} from "../../redux-toolkit/shoppingCartReducer";

import {
  selectEmployeeId,
  selectProxyMode,
  selectFullEmployeeName,
  switchProxyMode,
  selectWholesalerProxyMode,
  switchWholesalerProxyMode,
  selectSelectedWorkshop,
} from "../../redux-toolkit/dashboardEmployeeReducer";
import {
  getEventCertificateAsync,
  selectEnrollmentId,
  selectEnrollmentDetail,
  selectSelectedTrainingProgram,
  selectMyTrainingEvents,
  resetEventEnrollmentSummaryList,
  resetMyTrainingEvents,
} from "../../redux-toolkit/dashboardReducer";

import {
  getTrainingProgramCertificateAsync,
  resetAssignedTrainingPrograms,
  resetTrainingPrograms,
  selectProgram,
} from "../../redux-toolkit/trainingProgramReducer";

import {
  resetTrainingCourses,
  selectTrainingCourse,
} from "../../redux-toolkit/trainingCourseReducer";

import {
  resetTrainingEvents,
  selectEvent,
} from "../../redux-toolkit/trainingEventReducer";

import Boschicon from "../../Common-components/BoschIcon/Boschicon";
import { resetContactPersons } from "../../redux-toolkit/contactReducer";
import { resetTrainingCenters } from "../../redux-toolkit/trainingCenterReducer";
import { EnrollmentDetails } from "../../models/EventDetail";
import AssignButton from "../../Common-components/TrainingProgramLayout/AssignButton";
import { RequestGetCertificateModel } from "../../models/dashboard/request.GetCertificate.model";
import { EventCertificate } from "../../models/EventCertificate";
import { RequestGetTrainingProgramCertificateModel } from "../../models/trainingProgram/request.getTrainingProgramCertificate.model";
import { TrainingProgramCertificate } from "../../models/trainingProgram/TrainingProgramCertificate";

type Props = {
  route: JSX.Element;
};

const AppWrapper = ({ route }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getCurrentRoute } = useRouting();
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const isOpenSideNav = useSelector(selectOpenSideNav);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const batsVersion = useSelector(selectBatsVersion);
  const isForceToHideNotificationBanner = useSelector(
    selectIsForcedToHideNotificationBanner
  );
  const [currentRoute, setCurrentRoute] = useState<RouteConfigure | undefined>(
    undefined
  );

  const trainingCourseDetail = useSelector(selectTrainingCourse);
  const displayCredit = useSelector(selectDisplayCredit);
  const [headerContent, setHeaderContent] = useState<string>("");
  const isLoggedIn = useSelector(selectIsSignIn);

  const ciamId = useSelector(selectCiamId);
  const country = useSelector(selectCountryCode);
  const timezoneId = useSelector(selectTimeZoneId);

  const allowSelfEnroll = useSelector(selectAllowSelfEnroll);
  const onSubWorkshopBranch = useSelector(selectOnSubWorkshopBranch);
  const history = useHistory();
  const authService: AuthService = AuthService.getInstance();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const proxyMode = useSelector(selectProxyMode);
  const wholesalerProxyMode = useSelector(selectWholesalerProxyMode);
  const selectedEmployee = useSelector(selectFullEmployeeName);
  const selectedWorkshop = useSelector(selectSelectedWorkshop);
  const role = useSelector(selectUserRoleId);
  const account = useSelector(selectAccount);

  const [withdrawing, setWithdrawing] = useState(false);
  const [isTrainingProgramDownloading, setIsTrainingProgramDownloading] =
    useState(false);
  const [
    isTrainingProgramDownloadingFailed,
    setIsTrainingProgramDownloadingFailed,
  ] = useState(false);

  const [isWithdrawSelected, setIsWithdrawSelected] = useState<boolean>(false);
  const ciamEmail = useSelector(selectCiamEmail);

  const myTrainingEvents = useSelector(selectMyTrainingEvents);

  const studentId = useSelector(selectEmployeeId);
  const [isChangingCompany, setIsChangingCompany] = useState<boolean>(false);
  const selectedEnrollmentId = useSelector(selectEnrollmentId);
  const [enableCourseWithdrawalModal, setEnableCourseWithdrawalModal] =
    useState<boolean>(false);

  const trainingEvent = useSelector(selectEvent);
  const [enrollmentDetail, setEnrollmentDetail] =
    useState<EnrollmentDetails | null>(null);
  const [isDownloadCertificate, setIsDownloadCertificate] = useState(false);
  const selectedEnrollmentDetail = useSelector(selectEnrollmentDetail);
  const [openCourseEvaluation, setOpenCourseEvaluation] = useState(false);
  const [openEvaluationRestriction, setOpenEvaluationRestriction] =
    useState(false);

  const program = useSelector(selectProgram);

  const [isWithdrawCourseAllow, setIsWithdrawCourseAllow] =
    useState<boolean>(false);
  const [isTrainingCourseWithdrawVisible, setIsTrainingCourseWithdrawVisible] =
    useState<boolean>(false);
  const [isElearning, setIsElearning] = useState<boolean>(false);

  const selectedTrainingProgram = useSelector(selectSelectedTrainingProgram);
  const [isWithdrawProgramVisible, setIsWithdrawProgramVisible] =
    useState<boolean>(false);

  const [showExternalWithdrawModal, setShowExternalWithdrawModal] =
    useState<boolean>(false);

  const onCourseWithdrawButtonClick = () => {
    if (trainingEvent?.hasExternalRegistration) {
      setShowExternalWithdrawModal(true);
    } else {
      setEnableCourseWithdrawalModal(true);
    }
  };

  const handleDownloadTrainingCourseCertificateClick = () => {
    setIsDownloadCertificate(true);
    let employeeCustomerId = wholesalerProxyMode
      ? selectedWorkshop?.customerId
      : account?.customerId;
    const certInput: RequestGetCertificateModel = {
      enrollmentId: selectedEnrollmentId || 0,
      ciamId: ciamId ?? "",
      customerId: employeeCustomerId ?? 0,
      country: country ?? "",
      language: language ?? "",
      timeZoneId: timezoneId ?? "",
    };
    const getCerts = dispatch(getEventCertificateAsync(certInput));
    getCerts
      .then((data: any) => {
        if (data?.payload?.dataObject) {
          const certificate = data.payload.dataObject as EventCertificate;
          if (certificate) {
            const fileExt = certificate?.fileExt?.replace(".", "");
            const hiddenElement = document.createElement("a");
            hiddenElement.href =
              `data:application/${fileExt};base64,` +
              encodeURI(certificate?.file || "");
            hiddenElement.target = "_blank";
            hiddenElement.download = `${certificate?.fileName}`;
            hiddenElement.click();
          }
        }
      })
      .finally(() => {
        setIsDownloadCertificate(false);
      });
  };

  const handleDownloadTrainingProgramCertificate = () => {
    setIsTrainingProgramDownloading(true);

    let employeeCustomerId = wholesalerProxyMode
      ? selectedWorkshop?.customerId
      : account?.customerId;

    const certInput: RequestGetTrainingProgramCertificateModel = {
      ciamId: ciamId ?? "",
      ciamEmail: ciamEmail ?? "",
      trainingProgramMembersId:
        selectedTrainingProgram?.properties?.trainingProgramMembersId ?? 0,
      customerId: employeeCustomerId ?? 0,
      country: country ?? "",
      language: language ?? "",
      timeZoneId: timezoneId ?? "",
      salesRegion: "",
    };
    const getCerts = dispatch(getTrainingProgramCertificateAsync(certInput));
    getCerts
      .then((data: any) => {
        if (data?.payload?.dataObject) {
          const certificate = data?.payload
            .dataObject as TrainingProgramCertificate;
          if (certificate) {
            const fileExt = certificate?.fileExt?.replace(".", "");
            const hiddenElement = document.createElement("a");
            hiddenElement.href =
              `data:application/${fileExt};base64,` +
              encodeURI(certificate?.file || "");
            hiddenElement.target = "_blank";
            hiddenElement.download = `${certificate?.fileName}`;
            hiddenElement.click();
          }
        } else {
          setIsTrainingProgramDownloadingFailed(true);
        }
      })
      .finally(() => {
        setIsTrainingProgramDownloading(false);
      });
  };

  const handleCourseEvaluationClick = () => {
    if (wholesalerProxyMode || proxyMode) {
      setOpenEvaluationRestriction(true);
      setOpenCourseEvaluation(false);
    } else {
      let selectedEvent = myTrainingEvents?.filter(
        (e) => e.enrollmentId === selectedEnrollmentId
      );
      if (selectedEvent && selectedEvent.length > 0) {
        setOpenCourseEvaluation(true);
        setOpenEvaluationRestriction(false);
      } else {
        setOpenEvaluationRestriction(true);
        setOpenCourseEvaluation(false);
      }
    }
  };

  const closeEvaluationRestriction = () => {
    setOpenEvaluationRestriction(false);
  };

  const closeCourseEvaluation = () => {
    setOpenCourseEvaluation(false);
  };

  const closeCourseWithdrawalModal = () => {
    setEnableCourseWithdrawalModal(false);
  };

  const onCourseWithdrawalSuccess = () => {
    history.goBack();
  };

  const redirectExternalLink = (url: string) => {
    setShowExternalWithdrawModal(false);
    window.open(url, "_blank");
  };

  const login = async () => {
    await authService.loginAsync();
  };

  const logout = async () => {
    dispatchReduxToolkit(clearEventIdFromCart());
    dispatchReduxToolkit(clearRegisteredEmployee());

    await authService.logoutAsync();
  };

  const redirectToPage = (url: string) => {
    history.push(url);
  };

  const switchProxy = () => {
    dispatchReduxToolkit(switchProxyMode(false));
    redirectToPage(`/dashboard/my-employees`);

    reloadPersonalData();
    forceToReloadData();
  };

  const switchWholesalerProxy = () => {
    if (!!wholesalerProxyMode && !!proxyMode) {
      dispatchReduxToolkit(switchProxyMode(false));
      redirectToPage(`/dashboard`);
    } else if (!!wholesalerProxyMode && !proxyMode) {
      dispatchReduxToolkit(switchWholesalerProxyMode(false));
      redirectToPage(`/dashboard/my-workshops`);
    }

    reloadPersonalData();
    forceToReloadData();
  };

  const reloadPersonalData = () => {
    // clear all assigned training programs
    dispatchReduxToolkit(resetAssignedTrainingPrograms(undefined));

    // clear all assigned training events
    dispatchReduxToolkit(resetMyTrainingEvents(undefined));

    // clear all contact persons
    dispatchReduxToolkit(resetContactPersons(undefined));

    // force to reload team EventEnrollmentSummaryList data.
    dispatchReduxToolkit(resetEventEnrollmentSummaryList(undefined));
  };

  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));
  };

  const [isMaintenanceScheduleOn, setIsMaintenanceScheduleOn] = useState(false);
  const [isShowMaintenanceBanner, setIsShowMaintenanceBanner] = useState(false);
  const maintenanceScheduleDatetime = useMemo(
    () => "19/01/2024 5:00 AM (CET)",
    []
  );
  const maintenanceSupportEmail = useMemo(
    () => Constants.NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_DEFAULT,
    []
  );
  const maintenanceBlockRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [maintenanceBannerHeight, setMaintenanceBannerHeight] = useState(0);

  useEffect(() => {
    const t = getCurrentRoute();
    setCurrentRoute(t);
  }, [window.location.pathname]);

  useEffect(() => {
    if (isMaintenanceScheduleOn && !isForceToHideNotificationBanner)
      setIsShowMaintenanceBanner(true);
    else setIsShowMaintenanceBanner(false);
  }, [isMaintenanceScheduleOn, isForceToHideNotificationBanner]);

  useEffect(() => {
    if (!isShowMaintenanceBanner) {
      setMaintenanceBannerHeight(0);
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setMaintenanceBannerHeight(getHeightOfComponent(maintenanceBlockRef));
      window.clearTimeout(timeoutId);
    }, 50);
  }, [isShowMaintenanceBanner]);

  useEffect(() => {
    if (!wrapperRef.current || !maintenanceBlockRef.current) return;

    if (isShowMaintenanceBanner) {
      const maintenanceBannerHeightAsString = maintenanceBannerHeight + "px";
      wrapperRef.current.style.marginTop = maintenanceBannerHeightAsString;

      setHeightForSideNavigation(
        `calc(100vh - ${maintenanceBannerHeightAsString})`
      );
    } else {
      wrapperRef.current.style.marginTop = "0";
      setHeightForSideNavigation("100vh");
    }
  }, [isShowMaintenanceBanner, maintenanceBannerHeight]);

  useEffect(() => {
    window.addEventListener("scroll", toggleMaintenanceNotificationBar);

    return () => {
      window.removeEventListener("scroll", toggleMaintenanceNotificationBar);
    };
  }, [
    maintenanceBannerHeight,
    window.scrollY,
    window.screen.availWidth,
    window.screen.availHeight,
  ]);

  const toggleMaintenanceNotificationBar = () => {
    if (!wrapperRef.current || !maintenanceBlockRef.current) return;

    const screenHeight = window.screen.height;
    const documentHeight = document.body.scrollHeight;

    if (documentHeight - screenHeight <= maintenanceBannerHeight) return;

    if (window.scrollY > maintenanceBannerHeight) {
      maintenanceBlockRef.current.style.display = "none";
      wrapperRef.current.style.marginTop = "0px";
      setHeightForSideNavigation("100vh");
    } else {
      const maintenanceBannerHeightAsString = maintenanceBannerHeight + "px";

      maintenanceBlockRef.current.style.display = "block";
      wrapperRef.current.style.marginTop = maintenanceBannerHeightAsString;
      setHeightForSideNavigation(
        `calc(100vh - ${maintenanceBannerHeightAsString})`
      );
    }
  };

  const setHeightForSideNavigation = (maintenanceBannerHeight: string) => {
    if (!wrapperRef.current) return;

    const sideNavigation =
      wrapperRef.current.querySelector(".m-side-navigation");

    if (!sideNavigation) return;

    (sideNavigation as HTMLElement).style.height = maintenanceBannerHeight;
  };

  const isAbleToShowFooter = () => {
    return !(
      window.location.href.includes("/signincallback") ||
      window.location.href.includes("/signoutcallback") ||
      window.location.href.includes("/sessiontimeout") ||
      window.location.href.includes("/editprofilecallback") ||
      window.location.href.includes("/launchlinkredirect") ||
      window.location.href.includes("/meeting")
    );
  };

  const handleCloseMaintenanceBanner = () => {
    setIsMaintenanceScheduleOn(false);
    dispatch(updateForcedToHideNotificationBanner(true));

    if (!wrapperRef.current) return;

    wrapperRef.current.style.marginTop = "0px";
    setHeightForSideNavigation("100vh");
  };

  const headerBar = () => {
    switch (window.location.pathname) {
      case "/course/:country?/:id?":
      case "/course-detail":
      case "/training-course-catalogue/training-course-detail":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">
                  {/* { course?.localizedName } */}
                  {TrainingEventHelper.getTrainingNameFromCourse(
                    trainingCourseDetail,
                    language,
                    "-"
                  )}
                </span>
              </div>
              <div className="training_course_detail_header">
                <div className="price_container">
                  {displayCredit === "credit" ? (
                    <>
                      {trainingCourseDetail && (
                        <PriceTag
                          price={trainingCourseDetail.batsPrice}
                          creditPrice={trainingCourseDetail.batsCreditPrice}
                        />
                      )}
                    </>
                  ) : (
                    <div className="price-currency">
                      {trainingCourseDetail && (
                        <PriceTag
                          price={trainingCourseDetail.batsPrice}
                          creditPrice={trainingCourseDetail.batsCreditPrice}
                          isDisplayVATText
                        />
                      )}
                    </div>
                  )}
                </div>
                {isLoggedIn ? (
                  <AddEventFromCatalogToCartComponent
                    isDisabled={!allowSelfEnroll || onSubWorkshopBranch}
                    eventInfo={trainingCourseDetail}
                    isHeader={true}
                  />
                ) : (
                  <RDButton primary className="login-button" onClick={login}>
                    {t("Homepage_Login")}
                  </RDButton>
                )}
              </div>
            </div>
          </div>
        );
      case "/training-course-catalogue":
      case "/event-calendar":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{headerContent}</span>
              </div>
              <RDButton
                primary
                onClick={
                  !isLoggedIn
                    ? login
                    : () => {
                        history.push("/training-course-catalogue");
                      }
                }
              >
                {isLoggedIn ? t("Header_RegisterCourse") : t("Homepage_Login")}
              </RDButton>
            </div>
          </div>
        );
      case "/dashboard":
      case "/dashboard/my-training-program":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                  <Boschicon name="bosch-ic-keys-user-access" />
                ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                  wholesalerProxyMode ? (
                  proxyMode ? (
                    <Boschicon name="bosch-ic-keys-user-access" />
                  ) : (
                    <Boschicon name="bosch-ic-garage" />
                  )
                ) : (
                  <Boschicon name="bosch-ic-user-professional" />
                )}
                <span className="anonymous-header">
                  {role === Constants.USER_ROLE_MANAGER_ID && proxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ? selectedEmployee : ""
                      }`
                    : role === Constants.USER_ROLE_WHOLESALER_ID &&
                      wholesalerProxyMode
                    ? proxyMode
                      ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                          selectedEmployee ? selectedEmployee : ""
                        }`
                      : `${t("Dashboard_Headerbar_ProxingAs")} ${
                          selectedWorkshop ? selectedWorkshop?.workshopName : ""
                        }`
                    : `${t("Dashboard_Headerbar_WelcomeMessage")}, ${
                        account?.employee?.firstName
                          ? account?.employee?.firstName + "!"
                          : ""
                      }`}
                </span>
              </div>

              {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                <RDButton
                  isSignal
                  onClick={() => switchProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                wholesalerProxyMode ? (
                <RDButton
                  isSignal
                  onClick={() => switchWholesalerProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              ) : (
                /* (<Button className={`login-button`} onClick={!isLoggedIn ? login : () => { history.push("/training-course-catalogue") }} >{t("Dashboard_Headerbar_BookCourse")} </Button>) */
                <RDButton
                  primary
                  onClick={
                    !isLoggedIn
                      ? login
                      : () => {
                          history.push("/training-course-catalogue");
                        }
                  }
                  content={t("Dashboard_Headerbar_BookCourse")}
                />
              )}
            </div>
          </div>
        );
      case "/dashboard/edit-profile":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                  <Boschicon name="bosch-ic-keys-user-access" />
                ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                  wholesalerProxyMode ? (
                  <Boschicon name="bosch-ic-keys-user-access" />
                ) : (
                  <Boschicon name="bosch-ic-user-professional" />
                )}

                <span className="anonymous-header">
                  {role === Constants.USER_ROLE_MANAGER_ID && proxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ? selectedEmployee : ""
                      }`
                    : role === Constants.USER_ROLE_WHOLESALER_ID &&
                      wholesalerProxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ? selectedEmployee : ""
                      }`
                    : `${t("Dashboard_Headerbar_WelcomeMessage")}, ${
                        account?.employee?.firstName
                          ? account?.employee?.firstName + "!"
                          : ""
                      }`}
                </span>
              </div>

              {role === Constants.USER_ROLE_MANAGER_ID && proxyMode && (
                <RDButton
                  isSignal
                  onClick={() => switchProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              )}
              {role === Constants.USER_ROLE_WHOLESALER_ID &&
                wholesalerProxyMode && (
                  <RDButton
                    isSignal
                    onClick={() => switchWholesalerProxy()}
                    content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                  />
                )}
            </div>
          </div>
        );
      case "/dashboard/my-employees":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                  <Boschicon name="bosch-ic-keys-user-access" />
                ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                  wholesalerProxyMode ? (
                  <Boschicon name="bosch-ic-garage" />
                ) : (
                  <Boschicon name="bosch-ic-user-professional" />
                )}

                <span className="anonymous-header">
                  {role === Constants.USER_ROLE_MANAGER_ID && proxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ? selectedEmployee : ""
                      }`
                    : role === Constants.USER_ROLE_WHOLESALER_ID &&
                      wholesalerProxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedWorkshop ? selectedWorkshop?.workshopName : ""
                      }`
                    : `${t("Dashboard_Headerbar_WelcomeMessage")}, ${
                        account?.employee?.firstName
                          ? account?.employee?.firstName + "!"
                          : ""
                      }`}
                </span>
              </div>

              {role === Constants.USER_ROLE_MANAGER_ID && proxyMode ? (
                <RDButton
                  isSignal
                  onClick={() => switchProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              ) : role === Constants.USER_ROLE_WHOLESALER_ID &&
                wholesalerProxyMode ? (
                <RDButton
                  isSignal
                  onClick={() => switchWholesalerProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              ) : (
                <RDButton
                  primary
                  onClick={
                    !isLoggedIn
                      ? login
                      : () => {
                          history.push("/training-course-catalogue");
                        }
                  }
                >
                  {t("Dashboard_Headerbar_BookCourse")}
                </RDButton>
              )}
            </div>
          </div>
        );
      case "/dashboard/my-certificates":
      case "/dashboard/my-suppliers":
      case "/dashboard/my-workshops":
      case "/dashboard/my-wallet":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <Boschicon name="bosch-ic-user-professional" />
                <span className="anonymous-header">
                  {`${t("Dashboard_Headerbar_WelcomeMessage")}, ${
                    account?.employee?.firstName
                      ? account?.employee?.firstName + "!"
                      : ""
                  }`}
                </span>
              </div>
              <RDButton
                primary
                onClick={
                  !isLoggedIn
                    ? login
                    : () => {
                        history.push("/training-course-catalogue");
                      }
                }
              >
                {t("Dashboard_Headerbar_BookCourse")}
              </RDButton>
            </div>
          </div>
        );
      case "/administration/employee-administration/employee-creation":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <Boschicon name="bosch-ic-user-add" />

                <span className="anonymous-header">
                  {role === Constants.USER_ROLE_MANAGER_ID && proxyMode
                    ? `${t("Dashboard_Headerbar_ProxingAs")} ${
                        selectedEmployee ?? ""
                      }`
                    : role === Constants.USER_ROLE_WHOLESALER_ID &&
                      wholesalerProxyMode
                    ? `${t("Header_CreateNewEmployee")}`
                    : `${t("Header_CreateNewEmployee")}`}
                </span>
              </div>

              {role === Constants.USER_ROLE_MANAGER_ID && proxyMode && (
                <RDButton
                  isSignal
                  onClick={() => switchProxy()}
                  content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                />
              )}

              {role === Constants.USER_ROLE_MANAGER_ID && !proxyMode && (
                <RDButton
                  primary
                  onClick={() => {
                    history.push("/training-course-catalogue");
                  }}
                >
                  {t("Dashboard_Headerbar_BookCourse")}
                </RDButton>
              )}

              {role === Constants.USER_ROLE_WHOLESALER_ID &&
                wholesalerProxyMode && (
                  <RDButton
                    isSignal
                    onClick={() => switchWholesalerProxy()}
                    content={<span>{t("Dashboard_Headerbar_ExitProxy")}</span>}
                  />
                )}
            </div>
          </div>
        );
      case "/mytraining/course-details":
        return (
          <div className={"anonymous-user"}>
            <div className={Styles["header_bar"]}>
              <div className={Styles["training_program_header"]}>
                <span className="anonymous-header">
                  {t("MyTraining_Headerbar_CourseDetails")}
                </span>

                {isTrainingCourseWithdrawVisible && !isElearning && (
                  <RDButton
                    isSignal
                    //className={`${Styles.header_bar_button_with_icon} ${Styles.with_draw_program_btn}`}
                    disabled={!isWithdrawCourseAllow}
                    onClick={() => onCourseWithdrawButtonClick()}
                    /* loading={withdrawing} */
                    // icon={<Boschicon name="bosch-ic-abort-frame"size="large" />}
                    content={
                      <span>
                        {t("Dashboard_Headerbar_TrainingCourse_Withdraw")}
                      </span>
                    }
                  />
                )}

                {isTrainingCourseWithdrawVisible && isElearning && (
                  <PopupNew
                    inverted
                    size="tiny"
                    content={t("CourseDetail_Withdrawal_NotPossible_Tooltips")}
                    trigger={
                      <RDButton
                        isSignal
                        //className={`${Styles.header_bar_button_with_icon} ${Styles.with_draw_program_btn}`}
                        /* loading={withdrawing} */
                        // icon={<Boschicon name="bosch-ic-abort-frame"size="large" />}
                        content={
                          <span>
                            {t("Dashboard_Headerbar_TrainingCourse_Withdraw")}
                          </span>
                        }
                      />
                    }
                  />
                )}

                {enrollmentDetail?.isCertificateAvailable && (
                  <RDButton
                    primary
                    disabled={isDownloadCertificate}
                    // className={Styles.header_bar_button_with_icon}
                    // icon={<Boschicon name="bosch-download-frame" size="large" />}
                    onClick={() =>
                      handleDownloadTrainingCourseCertificateClick()
                    }
                    content={t("Dashboard_Headerbar_DownloadCertificate")}
                  />
                )}

                {enrollmentDetail?.isEvaluationAvailable &&
                  !enrollmentDetail?.isCertificateAvailable && (
                    <>
                      <span className={Styles.evaluate_note}>
                        {t("Dashboard_Headerbar_EvaluateCourse_Note")}{" "}
                      </span>
                      <RDButton
                        primary
                        // className={Styles.header_bar_button_with_icon}
                        // icon={<Boschicon name="bosch-ic-question-frame" size="large" />}
                        onClick={() => handleCourseEvaluationClick()}
                        content={t("Dashboard_Headerbar_EvaluateCourse")}
                      />{" "}
                    </>
                  )}
              </div>
            </div>
          </div>
        );
      case "/mytraining/curricula/training-program-details":
        return (
          <div className={"anonymous-user"}>
            <div className={Styles["header_bar"]}>
              <div className={Styles["training_program_header"]}>
                <span className="anonymous-header">
                  {t("MyTraining_Headerbar_TrainingProgramDetails")}
                </span>
                {isWithdrawProgramVisible && (
                  <RDButton
                    isSignal
                    onClick={() => setIsWithdrawSelected(true)}
                    loading={withdrawing}
                    // className={`${Styles.header_bar_button_with_icon} ${Styles.with_draw_program_btn}`}
                    // icon={<Boschicon name="bosch-ic-abort-frame"size="large" />}
                    content={
                      <span>
                        {t("Dashboard_Headerbar_TrainingProgram_Withdraw")}
                      </span>
                    }
                  />
                )}
                {selectedTrainingProgram?.properties
                  ?.isCertificateAvailable && (
                  <RDButton
                    primary
                    onClick={() => handleDownloadTrainingProgramCertificate()}
                    loading={isTrainingProgramDownloading}
                    // className={`${Styles.header_bar_button_with_icon} ${Styles.with_draw_program_btn}`}
                    // icon={<Boschicon name="bosch-ic-abort-frame"size="large" />}
                    content={
                      <span>
                        {t(
                          "Dashboard_Headerbar_TrainingProgram_DownloadCertificate"
                        )}
                      </span>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        );
      case "/contacts":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{headerContent}</span>
              </div>
            </div>
          </div>
        );
      case "/training-program":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{headerContent}</span>
              </div>
              {!isLoggedIn && (
                <RDButton primary onClick={login}>
                  {t("Homepage_Login")}
                </RDButton>
              )}
            </div>
          </div>
        );
      case "/training-centers":
      case "/training-centers/recommended-hotel":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{headerContent}</span>
              </div>
              <RDButton
                primary
                onClick={
                  !isLoggedIn
                    ? login
                    : () => {
                        history.push("/training-course-catalogue");
                      }
                }
              >
                {isLoggedIn
                  ? t("Training_Centres_Book_Btn")
                  : t("Homepage_Login")}
              </RDButton>
            </div>
          </div>
        );
      case "/training-program/training-program-detail":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{program?.title}</span>
              </div>
              {isLoggedIn ? (
                <AssignButton isHeader={true} />
              ) : (
                <RDButton primary onClick={login}>
                  {t("Homepage_Login")}
                </RDButton>
              )}
            </div>
          </div>
        );
      case "/myprofile":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <Boschicon name="bosch-ic-my-brand-frame" />
                <span className="anonymous-header">
                  {t("Header_MyBoschProfile_Header")}
                </span>
              </div>
              {/* <AssignButton isHeader={true}/> */}
            </div>
          </div>
        );
      case "/download-files":
        return (
          <div className={"anonymous-user"}>
            <div className="header-bar">
              <div className="header-text-container">
                <span className="anonymous-header">{t("Download_Header")}</span>
              </div>
            </div>
          </div>
        );
      default:
        if (window.location.pathname.includes("/course/")) {
          return (
            <div className={"anonymous-user"}>
              <div className="header-bar">
                <div className="header-text-container">
                  <span className="anonymous-header">
                    {/* { course?.localizedName } */}
                    {TrainingEventHelper.getTrainingNameFromCourse(
                      trainingCourseDetail,
                      language,
                      "-"
                    )}
                  </span>
                </div>
                <div className="training_course_detail_header">
                  <div className="price_container">
                    {displayCredit === "credit" ? (
                      <>
                        {trainingCourseDetail && (
                          <PriceTag
                            price={trainingCourseDetail.batsPrice}
                            creditPrice={trainingCourseDetail.batsCreditPrice}
                          />
                        )}
                      </>
                    ) : (
                      <div className="price-currency">
                        {trainingCourseDetail && (
                          <PriceTag
                            price={trainingCourseDetail.batsPrice}
                            creditPrice={trainingCourseDetail.batsCreditPrice}
                            isDisplayVATText
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {isLoggedIn ? (
                    <AddEventFromCatalogToCartComponent
                      isDisabled={!allowSelfEnroll || onSubWorkshopBranch}
                      eventInfo={trainingCourseDetail}
                      isHeader={true}
                    />
                  ) : (
                    <RDButton primary className="login-button" onClick={login}>
                      {t("Homepage_Login")}
                    </RDButton>
                  )}
                </div>
              </div>
            </div>
          );
        }

        return <></>;
    }
  };

  return (
    <div className={style.container}>
      {isShowMaintenanceBanner && (
        <div
          ref={maintenanceBlockRef}
          className={`maintenance_block ${style.maintenance_block}`}
        >
          <Message className="maintenance_message" info size="small">
            <div className="maintenance_detail">
              <Message.Content>
                <Icon iconName="service-time" />
                <p>
                  {isMaintenanceScheduleOn && (
                    <>
                      <span>
                        {" "}
                        {t("MaintenanceSchedule_Message_1").replace(
                          "[maintenance_schedule]",
                          maintenanceScheduleDatetime || ""
                        )}
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("MaintenanceSchedule_Message_2").replace(
                            "[support_email]",
                            "<a href='mailto:" +
                              maintenanceSupportEmail +
                              "' target='_blank'>" +
                              maintenanceSupportEmail +
                              "</a>"
                          ),
                        }}
                      ></span>
                      <br />
                    </>
                  )}
                </p>
                <Icon iconName="close" onClick={handleCloseMaintenanceBanner} />
              </Message.Content>
            </div>
          </Message>
        </div>
      )}
      <div ref={wrapperRef} className={style.wrapper}>
        <MinimalHeader
          actions={<HeaderActions />}
          sideNavigation={<SideNavigation />}
          className={`${style.minimal_header} ${
            isOpenSideNav ? style.open : ""
          } ${
            !isMainContentPinned ? style.header_with_main_content_pinned : ""
          }`}
        >
          {currentRoute?.breadcrumbs ? (
            <RDFrokBreacrumbs crumbs={currentRoute.breadcrumbs} />
          ) : (
            <></>
          )}
        </MinimalHeader>
        <div
          className={`${style.main_content} ${
            !!isMainContentPinned && isOpenSideNav ? style.minimal : ""
          }`}
        >
          <div className={style.route_content}>{route}</div>
        </div>
        <div
          className={`${style.footer_wrapper} ${
            !!isMainContentPinned && isOpenSideNav ? style.minimal : ""
          }`}
        >
          {isAbleToShowFooter() ? <Footer /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default AppWrapper;
