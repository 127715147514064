import { Constants } from "../../helpers/Constants";
import {
  MenuHeaderItem,
  MenuHeaderKey,
} from "../../models/pageConfiguration/MenuHeaderModel";

export class MenuItemConstants {
  /**
   * START MENU HEADER CONSTANTS
   */
  public static readonly FullRolesSupported: number[] = [
    Constants.USER_ROLE_INDIVIDUAL_ID,
    Constants.USER_ROLE_MANAGER_ID,
    Constants.USER_ROLE_WHOLESALER_ID,
  ];

  public static readonly MenuItemMyTraining: MenuHeaderItem = {
    key: MenuHeaderKey.MY_TRAINING,
    label: "Breadcrumb_MyTraining",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/dashboard",
  };

  public static readonly MenuItemYourOverview: MenuHeaderItem = {
    key: MenuHeaderKey.MY_TRAINING_EVENTS,
    label: "Dashboard_Headline_YourOverview",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/dashboard",
  };

  public static readonly MenuItemCurricula: MenuHeaderItem = {
    key: MenuHeaderKey.CURRICULA,
    label: "Breadcrumb_MyTraining_Curricula",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/dashboard/my-training-program",
  };

  public static readonly MenuItemTeam: MenuHeaderItem = {
    key: MenuHeaderKey.TEAM,
    label: "Breadcrumb_MyTraining_Team",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
    isActive: true,
    redirectUrl: "/dashboard/my-employees",
  };

  public static readonly MenuItemExternal: MenuHeaderItem = {
    key: MenuHeaderKey.EXTERNAL,
    label: "Breadcrumb_MyTraining_External",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
    isActive: true,
    redirectUrl: "/dashboard/my-suppliers",
  };

  public static readonly MenuItemWorkshop: MenuHeaderItem = {
    key: MenuHeaderKey.WORKSHOP,
    label: "Breadcrumb_MyTraining_Workshop",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_WHOLESALER_ID],
    isActive: true,
    redirectUrl: "/dashboard/my-suppliers",
  };

  public static readonly MenuItemWallet: MenuHeaderItem = {
    key: MenuHeaderKey.WALLET,
    label: "Breadcrumb_MyTraining_Wallet",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
    isActive: true,
    redirectUrl: "/dashboard/my-wallet",
  };

  public static readonly MenuItemTrainingServices: MenuHeaderItem = {
    key: MenuHeaderKey.TRAINING_SERVICES,
    label: "MenuHeader_TrainingServices",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: true,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "",
  };

  public static readonly MenuItemTrainingCourseCatalogue: MenuHeaderItem = {
    key: MenuHeaderKey.COURSE_CATALOG,
    label: "MenuHeader_Catalog",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: true,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/training-course-catalogue",
  };

  public static readonly MenuItemEventCalendar: MenuHeaderItem = {
    key: MenuHeaderKey.EVENT_CALENDAR,
    label: "Breadcrumb_EventCalendar",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: true,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/event-calendar",
  };

  public static readonly MenuItemTrainingProgam: MenuHeaderItem = {
    key: MenuHeaderKey.TRAINING_PROGRAM,
    label: "Breadcrumb_TrainingProgram",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: true,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/training-program",
  };

  public static readonly MenuItemTrainingCenters: MenuHeaderItem = {
    key: MenuHeaderKey.OUR_LOCATIONS,
    label: "MenuHeader_OurLocations",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: true,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/training-centers",
  };

  public static readonly MenuItemSupport: MenuHeaderItem = {
    key: MenuHeaderKey.SUPPORT,
    label: "MenuHeader_Support",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: false,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/support",
  };

  public static readonly MenuItemDownloadArea: MenuHeaderItem = {
    key: MenuHeaderKey.DOWNLOAD_AREA,
    label: "Breadcrumb_Download_Page",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: true,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/download-files",
  };

  public static readonly MenuItemFAQ: MenuHeaderItem = {
    key: MenuHeaderKey.FAQ,
    label: "Breadcrumb_FAQs",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: true,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/support",
  };

  public static readonly MenuItemMyBosch: MenuHeaderItem = {
    key: MenuHeaderKey.MY_BOSCH,
    label: "MenuItem_MyBosch",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/myprofile",
  };

  public static readonly MenuItemShoppingCart: MenuHeaderItem = {
    key: MenuHeaderKey.SHOPPING_CART,
    label: "Breadcrumb_ShoppingCart",
    isLoginRequired: false,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: true,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/shopping-cart",
  };

  public static readonly MenuItemLogin: MenuHeaderItem = {
    key: MenuHeaderKey.LOG_IN,
    label: "HomeLayoutCDM3_Login_ButtonLink",
    isLoginRequired: false,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    isHideIfLoggedIn: true,
    redirectUrl: "/login-redirect",
  };

  public static readonly MenuItemLogout: MenuHeaderItem = {
    key: MenuHeaderKey.LOG_OUT,
    label: "AccountButton_LogoutButton",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/logout",
  };

  public static readonly MenuItemContactUs: MenuHeaderItem = {
    key: MenuHeaderKey.CONTACT_US,
    label: "Breadcrumb_Contact",
    isLoginRequired: false,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: MenuItemConstants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/contacts",
  };

  /**
   * END MENU HEADER CONSTANTS
   */
}
