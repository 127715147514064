import _ from "lodash";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import AccordionPanel from "../../../Common-components/Accordion/AccordionPanel";
import Header from "../../../Common-components/Header/Header";
import RedirectHelper from "../../../helpers/RedirectHelper";
import { Language } from "../../../models/RegionState";
import {
  getRegionsAsync,
  selectRegionState,
} from "../../../redux-toolkit/regionReducer";
import { AppDispatch, useAppDispatch } from "../../../redux-toolkit/store";
import { AuthService } from "../../../services/AuthService";
import LandingPageBanner from "./LandingPageBanner";
import style from "./LandingPageNew.module.css";
import { resetTrainingCourses } from "../../../redux-toolkit/trainingCourseReducer";
import { resetTrainingPrograms } from "../../../redux-toolkit/trainingProgramReducer";
import { resetTrainingEvents } from "../../../redux-toolkit/trainingEventReducer";
import { resetTrainingCenters } from "../../../redux-toolkit/trainingCenterReducer";

const LandingPageNew: FC<any> = () => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const regionState = useSelector(selectRegionState);
  const [regionsData, setRegionsData] = useState<any>([]);

  useEffect(() => {
    async function performGetRegions() {
      if (!!regionState && !!regionState.regions) {
        AuthService.setRegionsLanguages(regionState.regions);
        const regions = _.groupBy(regionState.regions, "area");
        setRegionsData(regions);
      } else {
        let response = await dispatchReduxToolkit(getRegionsAsync());
        if (getRegionsAsync.fulfilled.match(response)) {
          if (!!response.payload && response.payload.success) {
            if (response.payload.dataObject != null) {
              AuthService.setRegionsLanguages(response.payload.dataObject);
              const regions = _.groupBy(response.payload.dataObject, "area");
              setRegionsData(regions);
            }
          }
        }
      }
    }
    performGetRegions();
  }, [dispatchReduxToolkit, regionState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleSetLanguageClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    countryCode: string,
    language: Language
  ) => {
    event.preventDefault();

    const url = process?.env?.REACT_APP_DEFAULT_CLIENTROOT ?? "";

    /* if (!url) {
      RedirectHelper.redirectToMainSite();
    } */

    const countryCodeCheck = countryCode.trim().toUpperCase();
    switch (countryCodeCheck) {
      case "BA":
        RedirectHelper.redirectToSubDomain(url, "HR", "hr", countryCodeCheck);
        break;
      case "SL":
        RedirectHelper.redirectToSubDomain(url, "HR", "sl", countryCodeCheck);
        break;
      default:
        if (url !== "") {
          RedirectHelper.redirectToSubDomain(
            url,
            countryCode,
            language.lcid,
            countryCodeCheck
          );
        } else {
          window.location.href = "/";
        }
        break;
    }

    // Force to reload data
    forceToReloadData();
  };

  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));
  };

  const items = Object.entries(regionsData)?.map(
    ([key, value]: [string, any]) => {
      const countryContent = value?.map((countryItem: any, index: any) => {
        return (
          <Grid.Column
            key={countryItem.country}
            computer={5}
            tablet={8}
            mobile={16}
            style={{ alignItems: "baseline", paddingRight: "0.5rem" }}
          >
            {countryItem.languages.map((language: Language) => {
              if (language.defaultLanguage) {
                return (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className="country-label"
                    href="#"
                    onClick={(e) => {
                      handleSetLanguageClick(
                        e,
                        countryItem.country,
                        language);
                    }}
                  >
                    {" "}
                    {countryItem.countryName}
                  </a>
                );
              }
            })}
          </Grid.Column>
        );
      });

      const alignedContent = (
        <Grid
          className={`${style.countries_content}`}
          container
          style={{
            alignItems: "baseline",
            display: "flex",
          }}
        >
          {countryContent}
        </Grid>
      );
      return { title: key, content: alignedContent };
    }
  );

  return (
    <Fragment>
      <Header isInLandingPage={true}></Header>
      <div
        className={`${style.landing_wrapper} grid-responsive-width body-container landing-body-container`}
      >
        <Grid className={`${style.banner_section}`}>
          <LandingPageBanner />
        </Grid>
        <Grid className={`${style.country_selector}`}>
          <Grid.Row>
            <h3
              id="countries-selector"
              style={{ textAlign: "center", paddingTop: "3rem" }}
              className="accordion-header-text"
            >
              {t("LandingPage_ChooseCountry_Title")}{" "}
            </h3>
          </Grid.Row>
          <Grid.Row>
            <AccordionPanel
              divider
              items={items}
              activeindex={0}
              titleClass="landing-according-title"
            />
          </Grid.Row>
        </Grid>
      </div>
    </Fragment>
  );
};

export default LandingPageNew;
