import { RouteConfigure } from "../models/Route";
import { defaultRouterConfig } from "./defaultRouter.config";
import { BATS_V4_ROUTERS_CONFIG } from "./batsv4Router.config";
import { BATSVersion } from "../models/pageConfiguration/BATSVersion";

const mixRouters = (
  routerConfigBasedOnBatsVersion: RouteConfigure[]
): RouteConfigure[] => {
  return [...defaultRouterConfig, ...routerConfigBasedOnBatsVersion];
};

export const applicationRouters: Record<BATSVersion, RouteConfigure[]> = {
  [BATSVersion.BATSv4]: mixRouters(BATS_V4_ROUTERS_CONFIG),
};
