import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectCountryCode,
  selectLanguage,
} from "../../redux-toolkit/authReducer";
import { Footer as FrokFooter, Link } from "@bosch/react-frok";
import style from "./Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  const countryCode = useSelector(selectCountryCode);
  const selectedLanguage = useSelector(selectLanguage);
  const termAndConditionCode = () => {
    if (!!selectedLanguage && selectedLanguage.trim() !== "") {
      return selectedLanguage?.toLowerCase().trim();
    }
    return "en";
  };

  return (
    <FrokFooter
      className={style.footer}
      bottomLinks={[
        <Link
          key="0"
          href="/data-protection-notice/0"
          label={t("FooterAdditionalLinks_Navigation_CorporateInformation")}
          level="integrated"
          target="_self"
        />,
        <Link
          key="1"
          href="/data-protection-notice/1"
          label={t("FooterAdditionalLinks_Navigation_LegalNotice")}
          level="integrated"
          target="_self"
        />,
        <Link
          key="2"
          href="/data-protection-notice/2"
          label={t("FooterAdditionalLinks_Navigation_DataProtectionNotice")}
          level="integrated"
          target="_self"
        />,
        <Link
          key="3"
          href={`/term-condition/${termAndConditionCode()}`}
          label={t("FooterAdditionalLinks_Navigation_TermAndCondition")}
          level="integrated"
          target="_self"
        />,
        <div className="a-link a-link--integrated">
          <a href="javascript:BoschPrivacy.show()">
            {t("FooterAdditionalLinks_Navigation_PrivacySettings")}
          </a>
        </div>,
       /*  <Link
          key="4"
          href="/software-licenses"
          label={t("FooterAdditionalLinks_Navigation_FossLicenses")}
          level="integrated"
          target="_self"
        />, */
        <div className="a-link a-link--integrated" style={{ color: "black" }}>
          {t("Common_Version_Text", {
            version: process.env.REACT_APP_VERSION || "",
          }).replace("{{version}}", process.env.REACT_APP_VERSION || "")}
        </div>,
      ]}
      copyright={t("FooterAdditionalLinks_Copyright")}
      variation="minimal"
    />
  );
};

export default Footer;
