import {
  SIDE_NAV_CHECKOUT_ITEM,
  SIDE_NAV_CURRICULA_ITEM,
  SIDE_NAV_EXTERNAL_ITEM,
  SIDE_NAV_HOME_ITEM,
  SIDE_NAV_MY_TRAINING_COURSE_DETAIL_ITEM,
  SIDE_NAV_MY_TRAINING_EVENTS_ITEM,
  SIDE_NAV_MY_TRAINING_ITEM,
  SIDE_NAV_MY_TRAINING_PROGRAM_DETAIL_ITEM,
  SIDE_NAV_RECOMMEND_HOTELS_ITEM,
  SIDE_NAV_SHOPPING_CART_ITEM,
  SIDE_NAV_SUPPORT_DOWNLOAD_AREA_ITEM,
  SIDE_NAV_SUPPORT_FAQ_ITEM,
  SIDE_NAV_SUPPORT_ITEM,
  SIDE_NAV_TEAM_CREATE_ITEM,
  SIDE_NAV_TEAM_EDIT_ITEM,
  SIDE_NAV_TEAM_ITEM,
  SIDE_NAV_TRAINING_CENTERS_ITEM,
  SIDE_NAV_TRAINING_COURSE_CATALOGUE_ITEM,
  SIDE_NAV_TRAINING_EVENT_CALENDAR_ITEM,
  SIDE_NAV_TRAINING_PROGRAM_DETAIL_ITEM,
  SIDE_NAV_TRAINING_PROGRAM_ITEM,
  SIDE_NAV_TRAINING_SERVICES_ITEM,
  SIDE_NAV_WALLET_ITEM,
  SIDE_NAV_WORKSHOP_ITEM,
} from "../../../../constants/sideNav.constant";
import { SideNavItem } from "../../../../types/sideNavigation.type";

const BATS_V4_MY_TRAINING_CHILD_ITEMS: SideNavItem[] = [
  {
    ...SIDE_NAV_MY_TRAINING_EVENTS_ITEM,
    childItems: [SIDE_NAV_MY_TRAINING_COURSE_DETAIL_ITEM],
  },
  {
    ...SIDE_NAV_CURRICULA_ITEM,
    childItems: [SIDE_NAV_MY_TRAINING_PROGRAM_DETAIL_ITEM],
  },
  {
    ...SIDE_NAV_TEAM_ITEM,
    childItems: [SIDE_NAV_TEAM_CREATE_ITEM, SIDE_NAV_TEAM_EDIT_ITEM],
  },
  SIDE_NAV_EXTERNAL_ITEM,
  SIDE_NAV_WORKSHOP_ITEM,
  SIDE_NAV_WALLET_ITEM,
];

const BATS_V4_TRAINING_SERVICES_CHILD_ITEMS: SideNavItem[] = [
  SIDE_NAV_TRAINING_COURSE_CATALOGUE_ITEM,
  SIDE_NAV_TRAINING_EVENT_CALENDAR_ITEM,
  {
    ...SIDE_NAV_TRAINING_PROGRAM_ITEM,
    childItems: [SIDE_NAV_TRAINING_PROGRAM_DETAIL_ITEM],
  },
  // {
  //   ...SIDE_NAV_TRAINING_COURSE_CATALOGUE_ITEM,
  //   childItems: [SIDE_NAV_TRAINING_COURSE_DETAIL_ITEM],
  // },
  // {
  //   ...SIDE_NAV_TRAINING_EVENT_CALENDAR_ITEM,
  //   childItems: [SIDE_NAV_TRAINING_COURSE_DETAIL_ITEM],
  // },
  // {
  //   ...SIDE_NAV_TRAINING_PROGRAM_ITEM,
  //   childItems: [SIDE_NAV_TRAINING_PROGRAM_DETAIL_ITEM],
  // },
];

export const BATS_V4_SIDE_NAV_CONFIG: SideNavItem[] = [
  SIDE_NAV_HOME_ITEM,
  {
    ...SIDE_NAV_MY_TRAINING_ITEM,
    childItems: BATS_V4_MY_TRAINING_CHILD_ITEMS,
  },
  {
    ...SIDE_NAV_TRAINING_SERVICES_ITEM,
    childItems: BATS_V4_TRAINING_SERVICES_CHILD_ITEMS,
  },
  {
    ...SIDE_NAV_TRAINING_CENTERS_ITEM,
    childItems: [SIDE_NAV_RECOMMEND_HOTELS_ITEM],
  },
  {
    ...SIDE_NAV_SUPPORT_ITEM,
    childItems: [
      SIDE_NAV_SUPPORT_DOWNLOAD_AREA_ITEM,
      SIDE_NAV_SUPPORT_FAQ_ITEM,
    ],
  },
  {
    ...SIDE_NAV_SHOPPING_CART_ITEM,
    childItems: [SIDE_NAV_CHECKOUT_ITEM],
  },
];
