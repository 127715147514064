import { FC, Fragment } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCountryName } from "../../redux-toolkit/authReducer";
import RDAlert from "../RDComponents/RDAlert";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { resetTrainingCourses } from "../../redux-toolkit/trainingCourseReducer";
import { resetTrainingPrograms } from "../../redux-toolkit/trainingProgramReducer";
import { resetTrainingEvents } from "../../redux-toolkit/trainingEventReducer";
import { resetTrainingCenters } from "../../redux-toolkit/trainingCenterReducer";

interface SwitchCountryModalProps {
  isShow: boolean;
  onCancel: Function;
}
const SwitchCountryModal: FC<SwitchCountryModalProps> = ({
  isShow,
  onCancel,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const countryName = useSelector(selectCountryName);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  
  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));
  };

  return (
    <Fragment>
      {/* <ConfirmationModal
        type="warning"
        isOpen={isShow}
        title={t("Common_Warning_Title")}
        message={`${t("Header_CurrentRegion")} ${countryName}`}
        additionMessage={t("Header_ChangeRegions")}
        acceptButtonText={t("Header_YesButton")}
        onAccept={() => {
          history.push("/regions");
        }}
        cancelButtonText={t("Header_NoButton")}
        onCancel={onCancel}
      />  */}

      <RDAlert
        size="tiny"
        type="warning"
        isOpen={true}
        messages={[
          `${t("Header_CurrentRegion")} ${countryName}.&nbsp;`,
          t("Header_ChangeRegions"),
        ]}
        acceptButtonText={t("Header_YesButton")}
        onAccept={() => {
          history.push("/regions");
          // Force to reload data
          forceToReloadData();
        }}
        cancelButtonText={t("Header_NoButton")}
        onCancel={() => onCancel()}
      />
    </Fragment>
  );
};

export default SwitchCountryModal;
