import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, Modal } from "semantic-ui-react";
import Boschicon from "../BoschIcon/Boschicon";

import "./InformationModal.css";

interface InformationModalProps {
  type: "warning" | "success" | "error";
  isDisplayModal: boolean;
  informationMessage: string;
  additionMessage?: string;
  popupTitle: string;
  closeButtonText?: string;
  setCloseModalTrigger: Function;
  userName?:string
}

const InformationModal: FC<InformationModalProps> = (
  props: InformationModalProps
) => {
  const { t } = useTranslation();
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const [informationMessage, setInformationMessage] = useState<string>("");
  const [additionMessage, setAdditionMessage] = useState<
    string | null | undefined
  >("");
  const [popupTitle, setPopupTitle] = useState<string>("");
  const [popupUserName, setPopupUserName] = useState<string>("");

  useEffect(() => {
    setInformationMessage(props.informationMessage);
    setOpenInformationModal(props.isDisplayModal);
    setAdditionMessage(props.additionMessage);
    setPopupTitle(props.popupTitle);
    setPopupUserName(props.userName || '')
  }, [props]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    props.setCloseModalTrigger();
  };

  return (
    <Fragment>
      <Modal
        dimmer={{ className: `information-modal ${props.type}` }}
        onClose={closeInformationModal}
        open={openInformationModal}
        closeOnDimmerClick={false}
        size={"tiny"}
      >
        <Modal.Header>
          {props.type === "warning" && (
            <Boschicon name="bosch-ic-alert-warning" />
          )}
          {props.type === "error" && <Boschicon name="bosch-ic-alert-error" />}
          {props.type === "success" && (
            <Boschicon name="bosch-ic-alert-success" />
          )}
          {popupTitle}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <p> <strong>{popupUserName}</strong> {informationMessage}</p>
              {additionMessage && <p>{additionMessage}</p>}
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={closeInformationModal}>
            {props.closeButtonText || t("CommonButton_Close")}
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default InformationModal;
