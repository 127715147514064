import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";
import { Language } from "../models/RegionState";
import { AuthService } from "../services/AuthService";
import {
  createAccount,
  createAccountForEditProfile,
  getAccountExtendedAsync,
  getAccountSimpleAsync,
  logoutCallbackAsync,
} from "./accountReducer";
import { RootState } from "./store";
import { Constants } from "../helpers/Constants";

export interface AuthState {
  user: User | null;
  isSignIn: boolean | undefined;
  countryCode: string;
  originCountryCode: string; //This field only be use for display flag. Do not use this field for calling API (use countryCode instead).
  countryName: string;
  language: string;
  domain: string;
  clientId: string;
  timeZoneId: string;
  languages: Language[] | null;
  dateFormat: string;
  batsVersion: string;
  isOpenSideNav?: boolean;
  redirectURLCallback?: string | null | undefined;
  isMainContentPinned?: boolean;
}

export const initialState: AuthState = {
  user: null,
  isSignIn: undefined,
  countryCode: "",
  originCountryCode: "",
  countryName: "",
  language: "",
  domain: "",
  clientId: "",
  timeZoneId: "",
  languages: [],
  dateFormat: Constants.DEFAULT_DATE_FORMAT,
  batsVersion: "4.9",
  isOpenSideNav: false,
  redirectURLCallback: "",
  isMainContentPinned: false,
};

export const selectAuthState = (rootState: RootState) => rootState.authBats;
export const selectIsSignIn = (rootState: RootState) =>
  selectAuthState(rootState).isSignIn;
export const selectCountryCode = (rootState: RootState) =>
  selectAuthState(rootState).countryCode;
export const selectCountryName = (rootState: RootState) =>
  selectAuthState(rootState).countryName;
export const selectTimeZoneId = (rootState: RootState) =>
  selectAuthState(rootState).timeZoneId;
export const selectLanguage = (rootState: RootState) =>
  selectAuthState(rootState).language;
export const selectDateFormat = (rootState: RootState) =>
  selectAuthState(rootState).dateFormat;
export const selectBatsVersion = (rootState: RootState) =>
  selectAuthState(rootState).batsVersion;
export const selectOpenSideNav = (rootState: RootState) =>
  selectAuthState(rootState).isOpenSideNav;
export const selectRedirectURLCallback = (rootState: RootState) =>
  selectAuthState(rootState).redirectURLCallback;
export const selectMainContentPinned = (rootState: RootState) =>
  selectAuthState(rootState).isMainContentPinned;

const authReducerSlice = createSlice({
  name: "authReducer",
  initialState: initialState,
  reducers: {
    authSetSignIn: (state, action: PayloadAction<boolean>) => {
      return { ...state, isSignIn: action.payload };
    },
    authSetUser: (state, action: PayloadAction<User | null>) => {
      return { ...state, user: action.payload };
    },
    authSetNewState: (state, action: PayloadAction<AuthState>) => {
      return {
        ...state,
        countryCode: action.payload.countryCode,
        countryName: action.payload.countryName,
        originCountryCode: action.payload.originCountryCode,
        language: action.payload.language,
        domain: action.payload.domain,
        timeZoneId: action.payload.timeZoneId,
        languages: action.payload.languages,
        dateFormat: action.payload.dateFormat,
      };
    },
    authSetLanguage: (state, action: PayloadAction<string>) => {
      //let authService = AuthService.getInstance();
      const orginalState = AuthService.getAuthState();
      orginalState.language = action.payload;
      AuthService.setAuthState(orginalState);
      return {
        ...state,
        language: action.payload,
      };
    },
    authSetDateFormat: (state, action: PayloadAction<string>) => {
      const orginalState = AuthService.getAuthState();
      orginalState.dateFormat = action.payload;
      AuthService.setAuthState(orginalState);
      return {
        ...state,
        dateFormat: action.payload,
      };
    },
    authFetchState: (state) => {
      //let authService = AuthService.getInstance();
      const authState = AuthService.getAuthState();
      return {
        ...state,
        clientId: authState.clientId,
        countryCode: authState.countryCode,
        originCountryCode: authState.originCountryCode,
        countryName: authState.countryName,
        domain: authState.domain,
        language: authState.language,
        timeZoneId: authState.timeZoneId,
        languages: authState.languages,
        dateFormat: authState.dateFormat,
      };
    },
    setOpenSideNav: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isOpenSideNav: action.payload,
      };
    },
    setRedirectURLCallback: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        redirectURLCallback: action.payload,
      };
    },
    setMainContentPinned: (state) => {
      return {
        ...state,
        isMainContentPinned: !state.isMainContentPinned,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountSimpleAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          state.isSignIn = true;
        }
      })
      .addCase(getAccountExtendedAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          state.isSignIn = true;
        }
      })
      .addCase(createAccount.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          (payload.httpStatusCode === 200 || payload.httpStatusCode === 201)
        ) {
          state.isSignIn = true;
        }
      })
      .addCase(createAccountForEditProfile.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          (payload.httpStatusCode === 200 || payload.httpStatusCode === 201)
        ) {
          state.isSignIn = true;
        }
      })
      .addCase(logoutCallbackAsync.fulfilled, (state, { payload }) => {
        state.isSignIn = false;
      });
  },
});

export const {
  authSetUser,
  authSetSignIn,
  authFetchState,
  authSetNewState,
  authSetLanguage,
  setOpenSideNav,
  setRedirectURLCallback,
  setMainContentPinned,
} = authReducerSlice.actions;
export default authReducerSlice.reducer;
