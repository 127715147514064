import i18n from "i18next";
export type NoPriceDisplayType = "FREE" | "ZERO" | "MINUS" | "NONE";

interface CurrencyConverterRate {
  name: string;
  rate: number;
  prefixSymbol: string;
  suffixSymbol: string;
}

interface CurrencyConverter {
  id: number;
  countryCode: string;
  rate: CurrencyConverterRate;
}

export class CurrencyConstants {
  public static readonly PRICE_CONVERTED_COUNTRIES = ["SK"];
  public static readonly CurrencyConverterMap: CurrencyConverter[] = [
    {
      id: 1,
      countryCode: "SK",
      rate: {
        name: "KC_TO_EUR",
        rate: 0.04032,
        prefixSymbol: "",
        suffixSymbol: "€",
      },
    },    
  ];
}

export interface CurrencyFormatOptions {
  significantDigits?: number;
  thousandsSeparator?: string;
  decimalSeparator?: string;
}
const defaultOptions: CurrencyFormatOptions = {
  significantDigits: 2,
  thousandsSeparator: ".",
  decimalSeparator: ",",
};

export class CurrencyHelper {
  static format = (
    value: number,
    symbol: string,
    country: string | null,
    noPriceDisplayType: NoPriceDisplayType,
    options?: CurrencyFormatOptions,
    isCredit?: boolean
  ) => {
    // if (typeof value !== "number" || +value <= 0) {
    //   if (isShoppingCart) {
    //     return "-";
    //   } else {
    //     return i18n.t("Course_Price_Free");
    //   }
    // }
    if (!value) {
      switch (noPriceDisplayType) {
        case "FREE":
          return i18n.t("Course_Price_Free");
        case "MINUS":
          return "-";
        default:
          value = 0;
          break;
      }
    }
    let preSymbol = "";
    let suffixSumbol = "";
    let currencyOptions = { ...defaultOptions };
    if (!!options) {
      currencyOptions = {
        ...currencyOptions,
        decimalSeparator:
          options.decimalSeparator ?? defaultOptions.decimalSeparator,
        thousandsSeparator:
          options.thousandsSeparator ?? defaultOptions.thousandsSeparator,
        significantDigits:
          options.significantDigits === null ||
          options.significantDigits === undefined
            ? defaultOptions.significantDigits
            : options.significantDigits,
      };
    }

    let convertedValue = value;

    if (!!country) {
      // Format currency and value
      switch (country.toUpperCase()) {
        case "US":
        case "UK":
        case "AU":
        case "NZ":
        case "IN":
          currencyOptions = {
            ...defaultOptions,
            thousandsSeparator: ",",
            decimalSeparator: ".",
          };
          preSymbol = symbol;
          break;
        case "BE":
        case "NL":
        case "LU":
        case "FR":
          preSymbol = symbol;
          break;
        default:
          suffixSumbol = symbol ?? "";
          break;
      }

      // Convert Slovakia Kc to EUR: 1Kc = 0.04 EUR
      if (CurrencyConstants.PRICE_CONVERTED_COUNTRIES.includes(country)) {
        let currencyConverter = CurrencyConstants.CurrencyConverterMap.find(
          (c) => c.countryCode === country
        );
        if (currencyConverter) {
          let rate = currencyConverter.rate.rate;
          convertedValue = value * rate;
          if (!isCredit) {
            preSymbol = currencyConverter.rate.prefixSymbol;
            suffixSumbol = currencyConverter.rate.suffixSymbol;
          }
        }
      }
    }

    let ret = convertedValue.toFixed(currencyOptions.significantDigits);

    const [currency, decimal] = ret.split(".");
    return `${preSymbol}${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      currencyOptions.thousandsSeparator || ""
    )}${decimal ? currencyOptions.decimalSeparator : ""}${
      decimal || ""
    }${suffixSumbol}`;
  };
}
