import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";
import { RequestGetTrainingCourses } from "../models/trainingCourse/request.getTrainingCourses.model";
import { TrainingCourse, TrainingEvent } from "../models/TrainingEvent";
import { httpNoneSecure } from "../services/HttpServiceNoneSecure";
import { AppDispatch, RootState } from "./store";
import { RequestGetTrainingCourseDetailByIDModel } from "../models/trainingCourse/request.getTrainingCourseDetailByID.model";
import { RequestGetTrainingCoursesSimple } from "../models/trainingCourse/request.getTrainingCoursesSimple.model";

export const getTrainingCourseDetailByIDAsync = createAsyncThunk<
  ResponseResultBase<TrainingCourse> | null,
  RequestGetTrainingCourseDetailByIDModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingCourse/GetTrainingCourseDetailById",
  async (req: RequestGetTrainingCourseDetailByIDModel, thunkApi) => {
    try {
      const model = {
        customerId: req.customerId ?? 0,
        country: req.country ? req.country.toUpperCase() : "",
        language: req.language,
        timeZoneId: req.timeZoneId,
        ciamId: req.ciamId ?? "",
        ciamEmail: req.ciamEmail ?? "",
        courseId: req.courseId ?? 0,
        salesRegion: "",
      };
      let response = await httpNoneSecure.post<
        ResponseResultBase<TrainingCourse>
      >(`/api/v1/Courses/GetTrainingCourseDetailById`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingCoursesAsync = createAsyncThunk<
  TrainingCourse[] | null,
  RequestGetTrainingCourses,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingCourse/getTrainingCourses",
  async (requestModel: RequestGetTrainingCourses, thunkApi) => {
    if (
      requestModel.ciamId === undefined ||
      requestModel.customerId === undefined
    ) {
      requestModel.ciamId = "";
      requestModel.customerId = 0;
    }
    try {
      let model = {
        country: requestModel.countryCode,
        ciamId: requestModel.ciamId,
        customerId: requestModel.customerId,
        language: requestModel.language,
        timeZoneId: requestModel.timeZoneId,
      };
      let response = await httpNoneSecure.post<
        ResponseResultBase<TrainingCourse[]>
      >(`/api/v1/Courses/country`, model);
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingCoursesSimpleAsync = createAsyncThunk<
  ResponseResultBase<TrainingCourse[]> | null,
  RequestGetTrainingCoursesSimple,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingCourse/GetTrainingCourseListSimple",
  async (req: RequestGetTrainingCoursesSimple, thunkApi) => {
    try {
      const model = {
        country: req.country,
        ciamId: req.ciamId ?? "",
        customerId: req.customerId ?? 0,
        language: req.language,
        timeZoneId: req.timeZoneId,
        salesRegion: "",
      };
      let response = await httpNoneSecure.post<
        ResponseResultBase<TrainingCourse[]>
      >(`/api/v1/Courses/GetTrainingCourseListSimple`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

interface TrainingCourseState {
  trainingCourseId: number;
  trainingCourseType: string;
  trainingCourseName: string;
  trainingCourse: TrainingCourse | null;
  course: TrainingCourse | null;
  simpleTrainingCourses: TrainingCourse[] | null;
  isForceReloadTrainingCourses: boolean;
  isTrainingCoursesLoaded: boolean;
}

const initialState: TrainingCourseState = {
  trainingCourseId: 0,
  trainingCourseType: "",
  trainingCourseName: "",
  trainingCourse: null,
  course: null,
  simpleTrainingCourses: [],
  isForceReloadTrainingCourses: true,
  isTrainingCoursesLoaded: false,
};
/* 
const initialState : courseState = {
  course: null
};
  */
export const courseSlice = createSlice({
  name: "trainingCourseReducer",
  initialState,
  reducers: {
    setTraingCourse: (state, action) => {
      return {
        ...state,
        course: {
          ...action.payload,
          trainingEvents: action.payload.trainingEvents.map(
            (x: TrainingEvent) => {
              return {
                ...x,
                trainingCourse: { ...x.trainingCourse, trainingEvents: [] },
              };
            }
          ),
        },
      };
    },
    setIsForceReloadTrainingCourses: (state, action) => {
      return {
        ...state,
        isForceReloadTrainingCourses: action?.payload || false,
      };
    },
    setIsTrainingCoursesLoaded: (state, action) => {
      return {
        ...state,
        isTrainingCoursesLoaded: action?.payload || false,
      };
    },
    setSimpleTrainingCourses: (state, action: any) => {
      return {
        ...state,
        simpleTrainingCourses: action?.payload || null,
      };
    },
    resetTrainingCourses: (state, action: any) => {
      return {
        ...state,
        simpleTrainingCourses: null,
        isForceReloadTrainingCourses: true,
      };
    },
    setTrainingCourse: (state, action) => {
      return {
        ...state,
        trainingCourse: action?.payload || null,
      };
    },
    setTrainingCourseId: (state, action) => {
      return {
        ...state,
        trainingCourse: action?.payload || null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTrainingCourseDetailByIDAsync.fulfilled,
        (state, { payload }) => {
          if (payload?.dataObject !== null && payload?.httpStatusCode === 200) {
            const course = payload?.dataObject as TrainingCourse;
            return {
              ...state,
              trainingCourse: course,
              trainingCourseName: course?.localizedName,
              trainingCourseId: course?.id,
              trainingCourseType: course?.trainingCourseType?.courseTypeName,
            };
          } else {
            return {
              ...state,
              trainingCourse: null,
              trainingCourseId: 0,
              trainingCourseType: "",
              trainingCourseName: "",
            };
          }
        }
      )
      .addCase(
        getTrainingCoursesSimpleAsync.fulfilled,
        (state, { payload }) => {
          if (payload?.dataObject !== null && payload?.httpStatusCode === 200) {
            const trainingCourses = payload?.dataObject;
            return {
              ...state,
              simpleTrainingCourses: trainingCourses,
              isForceReloadTrainingCourses: false,
              isTrainingCoursesLoaded: true,
            };
          } else {
            return {
              ...state,
              simpleTrainingCourses: null,
            };
          }
        }
      );
  },
});

export const selectCourse = (rootState: RootState) =>
  rootState.trainingCourseReducer.course;

export const selectTrainingCourse = (rootState: RootState) =>
  rootState.trainingCourseReducer.trainingCourse;

export const selectTrainingCourseId = (rootState: RootState) =>
  rootState.trainingCourseReducer.trainingCourseId;

export const selectSimpleTrainingCourses = (rootState: RootState) =>
  rootState.trainingCourseReducer.simpleTrainingCourses;

export const selectIsForceReloadTrainingCourses = (rootState: RootState) =>
  rootState.trainingCourseReducer.isForceReloadTrainingCourses;

export const selectIsTrainingCoursesLoaded = (rootState: RootState) =>
  rootState.trainingCourseReducer.isTrainingCoursesLoaded;

export const {
  setTraingCourse,
  setIsForceReloadTrainingCourses,
  setIsTrainingCoursesLoaded,
  setSimpleTrainingCourses,
  resetTrainingCourses,
} = courseSlice.actions;
export default courseSlice.reducer;
