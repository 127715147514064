import { FC } from "react";
import { Button, ButtonProps } from "semantic-ui-react";
import Styles from "./RDButton.module.css";
interface RDButtonProps extends ButtonProps {
  small?: boolean;
  isSignal?: boolean;
  signalSuccess?: boolean;
}
const RDButton: FC<RDButtonProps> = (props) => {
  return (
    <Button
      {...props}
      className={`${Styles.ui} ${Styles.button} ${
        props.primary && Styles.primary
      } ${props.small && Styles.small} ${props.secondary && Styles.secondary} ${
        props.disabled && Styles.disabled
      } 
      ${props.isSignal && Styles.signal_error} 
      ${props.signalSuccess && Styles.signal_success} 

      ${props.className ?? ""}`}
    >
      {props.children}
    </Button>
  );
};

export default RDButton;
