import {
  selectAccount,
  selectImList,
  selectOriginComp,
} from "../redux-toolkit/accountReducer";
import { selectIsSignIn } from "../redux-toolkit/authReducer";
import { useSelector } from "react-redux";

export const useUserAccountStatus = () => {
  const isSignIn = useSelector(selectIsSignIn);
  const account = useSelector(selectAccount);
  const impersonationList = useSelector(selectImList);
  const originAccount = useSelector(selectOriginComp);
  
  const checkUserIsMapped = () => {
    return isSignIn && account?.customerId;
  };

  const checkUserMultipleWorkshop = () => {
    return !!impersonationList && impersonationList.length > 1;
  };

  return {
    checkUserIsMapped,
    checkUserMultipleWorkshop,
  } as const;
};
