import RDDialog from "../RDDialog/RDDialog";
import { DialogProps } from "@bosch/react-frok";
import style from "./RDMessageDialog.module.scss";

type Props = DialogProps & {
  messages: string[];
};

const RDMessageDialog = (props: Props) => {
  const { messages, ...restrictedProps } = props;

  return (
    <RDDialog {...restrictedProps}>
      <div className={style.wrapper}>
        {messages.map((message, index) => (
          <span key={index} className={style.message}>
            {message}
          </span>
        ))}
      </div>
    </RDDialog>
  );
};

export default RDMessageDialog;
