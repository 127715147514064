import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import RedirectHelper from "../helpers/RedirectHelper";
import { CommonService } from "../services/Common";

interface IProtectRouteProps extends RouteProps {
  isSignIn: boolean | undefined;
  countryCode: string | null;
  permission?: string;
  domain: string;
}

const ProtectedRoute: React.FC<IProtectRouteProps> = ({
  component: Component,
  isSignIn,
  countryCode,
  permission,
  domain,
  ...rest
}) => {
  const isSelectedRegion = !!countryCode && countryCode !== "";
  const defaultClientRoot = RedirectHelper.getDefaultClientRoot();
  return (
    <Route
      {...rest}
      render={(insideProps) => {
        if (
          !(
            window.location.href.includes("/regions") ||
            window.location.href.includes("/redirect-subdomain") ||
            window.location.href.includes("/exception") ||
            window.location.href.includes("/clear-state") ||
            window.location.href.includes("/privacy-policy") ||
            window.location.href.includes("/term-condition")
          )
        ) {
          // to test in redirect in localhost, use the if below
          // if ((!isSelectedRegion) && CommonService.isDev())
          if (!isSelectedRegion) {
            //if (currentDomain === mainSiteDomain || !isSelectedRegion) {
            return (
              <Redirect
                to={{
                  pathname: "/regions",
                  state: { from: insideProps.location },
                }}
              />
            );
          }
        }

        if (countryCode !== "" && !isSignIn) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: insideProps.location },
              }}
            />
          );
        }

        if (permission && !CommonService.isGrantPermission(permission)) {
          return (
            <Redirect
              to={{
                pathname: "/exception?type=401",
                state: { from: insideProps.location },
              }}
            />
          );
        }

        return Component ? (
          <Component {...insideProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/exception?type=401",
              state: { from: insideProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
