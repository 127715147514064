import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_BA } from "./ba/translations";
import { TRANSLATIONS_BG } from "./bg/translations";
import { TRANSLATIONS_DA_DK } from "./da-DK/translations";
import { TRANSLATIONS_DE_AT } from "./de-AT/translations";
import { TRANSLATIONS_DE_CH } from "./de-CH/translations";
import { TRANSLATIONS_DE_DE } from "./de-DE/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_EN_AU } from "./en-AU/translations";
import { TRANSLATIONS_EN_AT } from "./en-AT/translations";
import { TRANSLATIONS_EN_BA } from "./en-BA/translations";
import { TRANSLATIONS_EN_BE } from "./en-BE/translations";
import { TRANSLATIONS_EN_CH } from "./en-CH/translations";
import { TRANSLATIONS_EN_DE } from "./en-DE/translations";
import { TRANSLATIONS_EN_DK } from "./en-DK/translations";
import { TRANSLATIONS_EN_EE } from "./en-EE/translations";
import { TRANSLATIONS_EN_ES } from "./en-ES/translations";
import { TRANSLATIONS_EN_FI } from "./en-FI/translations";
import { TRANSLATIONS_EN_FR } from "./en-FR/translations";
import { TRANSLATIONS_EN_GB } from "./en-GB/translations";
import { TRANSLATIONS_EN_HR } from "./en-HR/translations";
import { TRANSLATIONS_EN_ID } from "./en-ID/translations";
import { TRANSLATIONS_EN_IE } from "./en-IE/translations";
import { TRANSLATIONS_EN_IT } from "./en-IT/translations";
import { TRANSLATIONS_EN_JP } from "./en-JP/translations";
import { TRANSLATIONS_EN_KR } from "./en-KR/translations";
import { TRANSLATIONS_EN_LT } from "./en-LT/translations";
import { TRANSLATIONS_EN_LU } from "./en-LU/translations";
import { TRANSLATIONS_EN_LV } from "./en-LV/translations";
import { TRANSLATIONS_EN_MY } from "./en-MY/translations";
import { TRANSLATIONS_EN_NL } from "./en-NL/translations";
import { TRANSLATIONS_EN_NZ } from "./en-NZ/translations";
import { TRANSLATIONS_EN_PT } from "./en-PT/translations";
import { TRANSLATIONS_EN_RO } from "./en-RO/translations";
import { TRANSLATIONS_EN_SL } from "./en-SL/translations";
import { TRANSLATIONS_EN_SK } from "./en-SK/translations";
import { TRANSLATIONS_EN_TH } from "./en-TH/translations";
import { TRANSLATIONS_EN_US } from "./en-US/translations";
import { TRANSLATIONS_EN_ZA } from "./en-ZA/translations";
import { TRANSLATIONS_ES_ES } from "./es-ES/translations";
import { TRANSLATIONS_ET_EE } from "./et-EE/translations";
import { TRANSLATIONS_FI } from "./fi/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
import { TRANSLATIONS_FR_BE } from "./fr-BE/translations";
import { TRANSLATIONS_FR_LU } from "./fr-LU/translations";
import { TRANSLATIONS_HI_IN } from "./hi-IN/translations";
import { TRANSLATIONS_HR } from "./hr/translations";
import { TRANSLATIONS_HU_HU } from "./hu-HU/translations";
import { TRANSLATIONS_ID } from "./id/translations";
import { TRANSLATIONS_IT_IT } from "./it-IT/translations";
import { TRANSLATIONS_JP } from "./jp/translations";
import { TRANSLATIONS_KR } from "./kr/translations";
import { TRANSLATIONS_LT } from "./lt/translations";
import { TRANSLATIONS_LV } from "./lv/translations";
import { TRANSLATIONS_MS_MY } from "./ms-MY/translations";
import { TRANSLATIONS_NB_NO } from "./nb-NO/translations";
import { TRANSLATIONS_NL_BE } from "./nl-BE/translations";
import { TRANSLATIONS_NL_NL } from "./nl-NL/translations";
import { TRANSLATIONS_PA_IN } from "./pa-IN/translations";
import { TRANSLATIONS_RO } from "./ro/translations";
import { TRANSLATIONS_SV_SE } from "./sv-SE/translations";
import { TRANSLATIONS_TH } from "./th/translations";
import { TRANSLATIONS_ZH_SG } from "./zh-SG/translations";
import { TRANSLATIONS_EN_BG } from "./en-BG/translations";
import { TRANSLATIONS_EN_NO } from "./en-NO/translations";
import { TRANSLATIONS_EN_SG } from "./en-SG/translations";
import { TRANSLATIONS_SL } from "./sl/translations";
import { TRANSLATIONS_EN_SE } from "./en-SE/translations";
import { TRANSLATIONS_CS_CZ } from "./cs-CZ/translations";
import { TRANSLATIONS_EN_CZ } from "./en-CZ/translations";
import { TRANSLATIONS_EN_IN } from "./en-IN/translations";
import { TRANSLATIONS_EN_PL } from "./en-PL/translations";
import { TRANSLATIONS_EN_RU } from "./en-RU/translations";
import { TRANSLATIONS_RU_RU } from "./ru-RU/translations";
import { TRANSLATIONS_ZH_CN } from "./zh-CN/translations";
import { TRANSLATIONS_PT_PT } from "./pt-PT/translations";
import { TRANSLATIONS_UK_UA } from "./uk-UA/translations";
import { TRANSLATIONS_PL_PL } from "./pl-PL/translations";
import { TRANSLATIONS_AF_ZA } from "./af-ZA/translations";
import { TRANSLATIONS_AR_EG } from "./ar-EG/translations";
import { TRANSLATIONS_EN_CN } from "./en-CN/translations";
import { TRANSLATIONS_EN_EG } from "./en-EG/translations";
import { TRANSLATIONS_EN_HU } from "./en-HU/translations";
import { TRANSLATIONS_EN_UA } from "./en-UA/translations";
import { TRANSLATIONS_EN_BR } from "./en-BR/translations";
import { TRANSLATIONS_PT_BR } from "./pt-BR/translations";
import { TRANSLATIONS_SK_SK } from "./sk-SK/translations";
import { TRANSLATIONS_ES_AR } from "./es-AR/translations";
import { TRANSLATIONS_EN_AR } from "./en-AR/translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      "af-ZA": { translation: TRANSLATIONS_AF_ZA },
      "ar-EG": { translation: TRANSLATIONS_AR_EG },
      "bs-BA": { translation: TRANSLATIONS_BA },
      "bg": { translation: TRANSLATIONS_BG },
      "cs-CZ": { translation: TRANSLATIONS_CS_CZ },
      "da-DK": { translation: TRANSLATIONS_DA_DK },
      "de-AT": { translation: TRANSLATIONS_DE_AT },
      "de-CH": { translation: TRANSLATIONS_DE_CH },
      "de-DE": { translation: TRANSLATIONS_DE_DE },
      "en": { translation: TRANSLATIONS_EN },
      "en-AT": { translation: TRANSLATIONS_EN_AT },
      "en-AU": { translation: TRANSLATIONS_EN_AU },
      "en-BA": { translation: TRANSLATIONS_EN_BA },
      "en-BE": { translation: TRANSLATIONS_EN_BE },
      "en-BG": { translation: TRANSLATIONS_EN_BG },
      "en-BR": { translation: TRANSLATIONS_EN_BR },
      "en-CH": { translation: TRANSLATIONS_EN_CH },
      "en-CN": { translation: TRANSLATIONS_EN_CN },
      "en-CZ": { translation: TRANSLATIONS_EN_CZ },
      "en-DE": { translation: TRANSLATIONS_EN_DE },
      "en-DK": { translation: TRANSLATIONS_EN_DK },
      "en-EE": { translation: TRANSLATIONS_EN_EE },
      "en-EG": { translation: TRANSLATIONS_EN_EG },
      "en-ES": { translation: TRANSLATIONS_EN_ES },
      "en-FI": { translation: TRANSLATIONS_EN_FI },
      "en-FR": { translation: TRANSLATIONS_EN_FR },
      "en-GB": { translation: TRANSLATIONS_EN_GB },
      "en-HR": { translation: TRANSLATIONS_EN_HR },
      "en-HU": { translation: TRANSLATIONS_EN_HU },
      "en-ID": { translation: TRANSLATIONS_EN_ID },
      "en-IE": { translation: TRANSLATIONS_EN_IE },
      "en-IN": { translation: TRANSLATIONS_EN_IN },
      "en-IT": { translation: TRANSLATIONS_EN_IT },
      "en-JP": { translation: TRANSLATIONS_EN_JP },
      "en-KR": { translation: TRANSLATIONS_EN_KR },
      "en-LT": { translation: TRANSLATIONS_EN_LT },
      "en-LU": { translation: TRANSLATIONS_EN_LU },
      "en-LV": { translation: TRANSLATIONS_EN_LV },
      "en-MY": { translation: TRANSLATIONS_EN_MY },
      "en-NL": { translation: TRANSLATIONS_EN_NL },
      "en-NO": { translation: TRANSLATIONS_EN_NO },
      "en-NZ": { translation: TRANSLATIONS_EN_NZ },
      "en-PL": { translation: TRANSLATIONS_EN_PL },
      "en-PT": { translation: TRANSLATIONS_EN_PT },
      "en-RO": { translation: TRANSLATIONS_EN_RO },
      "en-RU": { translation: TRANSLATIONS_EN_RU },
      "en-SE": { translation: TRANSLATIONS_EN_SE },
      "en-SG": { translation: TRANSLATIONS_EN_SG },
      "en-SL": { translation: TRANSLATIONS_EN_SL },
      "en-SK": { translation: TRANSLATIONS_EN_SK },
      "en-TH": { translation: TRANSLATIONS_EN_TH },
      "en-UA": { translation: TRANSLATIONS_EN_UA },
      "en-US": { translation: TRANSLATIONS_EN_US },
      "en-ZA": { translation: TRANSLATIONS_EN_ZA },
      "es-ES": { translation: TRANSLATIONS_ES_ES },
      "et-EE": { translation: TRANSLATIONS_ET_EE },
      "fi": { translation: TRANSLATIONS_FI },
      "fr-FR": { translation: TRANSLATIONS_FR },
      "fr-BE": { translation: TRANSLATIONS_FR_BE },
      "fr-LU": { translation: TRANSLATIONS_FR_LU },
      "hi-IN": { translation: TRANSLATIONS_HI_IN },
      "hr": { translation: TRANSLATIONS_HR },
      "hu-HU": { translation: TRANSLATIONS_HU_HU },
      "id": { translation: TRANSLATIONS_ID },
      "it-IT": { translation: TRANSLATIONS_IT_IT },
      "ja-JP": { translation: TRANSLATIONS_JP },
      "ko-KR": { translation: TRANSLATIONS_KR },
      "lt": { translation: TRANSLATIONS_LT },
      "lv": { translation: TRANSLATIONS_LV },
      "ms-MY": { translation: TRANSLATIONS_MS_MY },
      "nb-NO": { translation: TRANSLATIONS_NB_NO },
      "nl-BE": { translation: TRANSLATIONS_NL_BE },
      "nl-NL": { translation: TRANSLATIONS_NL_NL },
      "pa-IN": { translation: TRANSLATIONS_PA_IN },
      "pl-PL": { translation: TRANSLATIONS_PL_PL },
      "pt-PT": { translation: TRANSLATIONS_PT_PT },
      "ro": { translation: TRANSLATIONS_RO },
      "ru-RU": { translation: TRANSLATIONS_RU_RU },
      "sl": { translation: TRANSLATIONS_SL },
      "sv-SE": { translation: TRANSLATIONS_SV_SE },
      "th": { translation: TRANSLATIONS_TH },
      "uk-UA": { translation: TRANSLATIONS_UK_UA },
      "zh-CN": { translation: TRANSLATIONS_ZH_CN },
      "zh-SG": { translation: TRANSLATIONS_ZH_SG },
      "pt-BR": { translation: TRANSLATIONS_PT_BR },
      "sk-SK": { translation: TRANSLATIONS_SK_SK },
      "en-AR": { translation: TRANSLATIONS_EN_AR },
      "es-AR": { translation: TRANSLATIONS_ES_AR },

      //Here is correction language code
      "fi-FI": { translation: TRANSLATIONS_FI },
      "hr-HR": { translation: TRANSLATIONS_HR },
      "id-ID": { translation: TRANSLATIONS_ID },
      "lt-LT": { translation: TRANSLATIONS_LT },
      "lv-LV": { translation: TRANSLATIONS_LV },
      "ro-RO": { translation: TRANSLATIONS_RO },
      "sl-SL": { translation: TRANSLATIONS_SL },
      "hr-SL": { translation: TRANSLATIONS_SL },
      "th-TH": { translation: TRANSLATIONS_TH },
    },
    parseMissingKeyHandler: function (key: string) {
      let str = key as keyof typeof TRANSLATIONS_EN;
      let ret = TRANSLATIONS_EN[str];
      if (!ret) ret = key;
      return ret;
    },
  });
