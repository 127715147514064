import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Constants } from "../../helpers/Constants";
import { usePriceFormat } from "../../hooks/usePriceFormat";
import { BatsCreditPrice, Price } from "../../models/TrainingEvent";
import { selectDisplayCredit } from "../../redux-toolkit/accountReducer";
import { selectCountryCode } from "../../redux-toolkit/authReducer";
import Styles from "./PriceTag.module.css";

interface PriceTagProps {
  price: Price;
  creditPrice: BatsCreditPrice;
  isDisplayVATText?: boolean;
}

const PriceTag: FC<PriceTagProps> = ({
  price,
  creditPrice,
  isDisplayVATText,
}) => {
  const { t } = useTranslation();
  const formatPrice = usePriceFormat();
  const displayCredit = useSelector(selectDisplayCredit);
  const countryCode = useSelector(selectCountryCode);

  return (
    <Fragment>
      <span className={Styles.price_tag_wrapper}>
        {formatPrice(displayCredit, price, creditPrice, countryCode)}
      </span>
      {isDisplayVATText && (
        <>
          { price?.displayVAT?
          (
            <>
              {!!price?.price && (
                <p className={Styles.price_vat}>
                  {t("Common_IncludedVAT")}
                </p>
              )}
            </>
          ):
          (
            <>
              {!!price?.netPrice && (
                <p className={Styles.price_vat}>
                  {t("ShoppingCart_VatIsIncluded")}
                </p>
              )}
            </>
          )}
        </>
      )}
      {/* {isDisplayVATText && (
        <>
          {Constants.netPriceCountries.includes(countryCode) ? (
            <>
              {!!price?.netPrice && (
                <p className={Styles.price_vat}>
                  {t("ShoppingCart_VatIsIncluded")}
                </p>
              )}
            </>
          ) : (
            <>
              {!!price?.price && (
                <p className={Styles.price_vat}>
                  {t("Common_IncludedVAT")}
                </p>
              )}
            </>
          )}
        </>
      )} */}
    </Fragment>
  );
};
export default PriceTag;
