import { HTMLAttributes } from "react";
import { Icon } from "@bosch/react-frok";

type Props = HTMLAttributes<HTMLDivElement> & {
  label: string;
  icon?: string;
  isSelected?: boolean;
};

const RDSideNavigationItem = (props: Props) => {
  const { label, icon, isSelected, className, ...restProps } = props;

  function getAllClassNames() {
    const allClassNames: string[] = ["m-side-navigation__link"];

    if (!!isSelected) allClassNames.push(" -selected");
    if (!!className) allClassNames.push(className);

    return allClassNames.join(" ");
  }

  return (
    <li className="m-side-navigation__menuItem">
      <div {...restProps} className={getAllClassNames()}>
        {!!icon ? <Icon iconName={icon} /> : <></>}
        <span className="m-side-navigation__label">{label}</span>
      </div>
    </li>
  );
};

export default RDSideNavigationItem;
