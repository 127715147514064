import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { MaintenanceInfo } from '../models/maintenance/MaintenanceInfo.model';

export interface MaintenanceState {
  maintenanceInfo: string | null;
  maintenanceInformation: MaintenanceInfo | null;
  isForcedToHideNotificationBanner: boolean;
}

const initialState: MaintenanceState = {
  maintenanceInfo: "",
  maintenanceInformation: null,
  isForcedToHideNotificationBanner: false,
};

export const maintenanceSlice = createSlice({
  name: "maintenanceReducer",
  initialState,
  reducers: {
    updateForcedToHideNotificationBanner: (
      state,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        isForcedToHideNotificationBanner: action.payload,
      };
    },
  },
  extraReducers: () => {    
  },
});

export const selectMaintenanceState = (rootState: RootState) => rootState.maintenanceReducer;
export const selectIsForcedToHideNotificationBanner = (rootState: RootState) =>
rootState.maintenanceReducer.isForcedToHideNotificationBanner;

export const { updateForcedToHideNotificationBanner } =
  maintenanceSlice.actions;

export default maintenanceSlice.reducer;