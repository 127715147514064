import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Modal } from "semantic-ui-react";
import Styles from "./RDCustomAlert.module.css";
import Boschicon from "../BoschIcon/Boschicon";
import RDButton from "./RDButton";

interface RDCustomAlertProps {
  type?: "warning" | "success" | "error" | "info";
  isOpen: boolean;
  title?: string;
  AcceptButton?: React.ReactNode;
  CancelButton?: React.ReactNode;
  onCancel?: Function;
  size?: "small" | "mini" | "tiny" | "large" | "fullscreen" | undefined;
}

const RDCustomAlert: FC<RDCustomAlertProps> = ({
  type,
  isOpen,
  title,
  AcceptButton,
  CancelButton,
  onCancel,
  children,
  size,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      dimmer={{
        className: `${Styles.wrapper_modal} ${
          type === "warning" && Styles.warning
        } ${type === "error" && Styles.error} ${
          type === "success" && Styles.success
        } ${type === "info" && Styles.info}`,
      }}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={isOpen}
      size={size ? size : "small"}
      className={Styles.modal}
    >
      <Modal.Header className={Styles.header}>
        {type === "warning" && (
          <>
            <Boschicon name="bosch-ic-alert-warning" />
            <span className={Styles.title}>{t("Popup_Title_Warning")}</span>
          </>
        )}
        {type === "error" && (
          <>
            <Boschicon name="bosch-ic-alert-error" />
            <span className={Styles.title}>{t("Popup_Title_Error")}</span>
          </>
        )}
        {type === "success" && (
          <>
            <Boschicon name="bosch-ic-alert-success" />
            <span className={Styles.title}>{t("AlertSuccess_Title")}</span>
          </>
        )}
        {type === "info" && (
          <>
            <Boschicon name="alert-info" />
            <span className={Styles.title}>{t("Popup_Title_Info")}</span>
          </>
        )}

        {!!onCancel && (
          <RDButton
            className={Styles.close_button_header}
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
            }}
          >
            <Boschicon name="bosch-close" />
          </RDButton>
        )}
      </Modal.Header>
      <Modal.Content className={Styles.content}>
        <Grid className={`${Styles.ui} ${Styles.grid}`}>
          {!!title && (
            <Grid.Row className={`${Styles.row} ${Styles.content_header}`}>
              {title}
            </Grid.Row>
          )}
          {children}
        </Grid>
      </Modal.Content>
      <Modal.Actions className={Styles.actions}>
        {!!AcceptButton && AcceptButton}
        {!!CancelButton && CancelButton}
      </Modal.Actions>
    </Modal>
  );
};

export default RDCustomAlert;
