import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { impersonation } from "../../models/Account";
import style from "./SelectCompanyModal.module.css";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import RDButton from "../RDComponents/RDButton";
import RDDropdown from "../RDComponents/RDDropdown";
import { selectAccount, selectCiamId, selectImList, selectOriginComp } from "../../redux-toolkit/accountReducer";

interface SelectCompanyModalProps {
  continue: Function;
}

const SelectCompanyModal: FC<any> = (props: SelectCompanyModalProps) => {
  const { t } = useTranslation();
  const [isSelecting, setIsSelecting] = useState(false);
  const ciamId = useSelector(selectCiamId);

  const account = useSelector(selectAccount);
  const workshops = useSelector(selectImList);
  const [managerList, setManagerList] = useState<[] | impersonation[]>([]);
  const [companyCiamId, setCompanyCiamId] = useState<string>(ciamId);
  const origin = useSelector(selectOriginComp);
  const [disabledChange, setDisabledChange] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<number | undefined>();
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);

  const onHandleSelectCompanyModal = () => {
    setIsSelecting(true);
    props.continue(companyCiamId);
  };

  useEffect(() => {
    if (managerList.length === 1) {
      setCompanyCiamId(managerList[0].ciamId);
      setDisabledChange(false);
    } else {
      setDisabledChange(true);
    }
  }, [customerId]);

  useEffect(() => {
    if (!!workshops) {
      setManagerList(
        workshops.filter((x: impersonation) => x.customerId === account?.customerId)
      );
    }
  }, [workshops]);

  useEffect(() => {
    setCustomerId(account?.customerId);
  }, [account]);

  useEffect(() => {
    if (!!workshops) {
      setCompanyOptions(
        workshops
          .filter(
            (value: impersonation, index: any, self: any) =>
              index ===
              self.findIndex((t: any) => t.customerId === value.customerId)
          )
          .map((x: impersonation) => {
            return {
              key: x.customerId,
              text:
                !origin || origin.customerId !== x.customerId
                  ? `${x.workshopName1 || ""} ${x.workshopName2 || ""}`
                  : `${x.workshopName1 || ""} ${x.workshopName2 || ""} ${t(
                      "ChangeCompanyModal_HeadOffice"
                    )}`,
              value: x.customerId,
            };
          })
      );
    }
  }, [workshops, origin]);

  return (
    <RDCustomAlert
        type="info"
        isOpen
        title={t("SelectCompanyModal_Title")}
        AcceptButton={
          <RDButton
            primary
            // className={style.continue}
            onClick={onHandleSelectCompanyModal}
            disabled={isSelecting || disabledChange}
            loading={isSelecting}
          >
            {t("ContinueButton")}
          </RDButton>
        }
      >
        <Grid className={style.modal_content_grid}>
          <Grid.Row className={style.row}>
            <RDDropdown
              title={t("SelectCompanyModal_Content")}
              style={{ width: "100%" }}
              placeholder="-"
              options={companyOptions}
              search
              value={customerId}
              onChange={(event: any, data: any) => {
                if (!!workshops) {
                  setCustomerId(data.value);
                  setManagerList(
                    workshops.filter(
                      (x: impersonation) => x.customerId === data.value
                    )
                  );
                }
              }}
            />
          </Grid.Row>
          <Grid.Row className={style.row}>
            <RDDropdown
              title={t("ChangeManagerModal_Content")}
              placeholder="-"
              options={managerList.map((x: impersonation) => {
                return {
                  key: x.ciamId,
                  text: `${x.contactFirstName || ""} ${x.contactLastName || ""}`,
                  value: x.ciamId,
                };
              })}
              value={companyCiamId}
              defaultValue={companyCiamId}
              search
              onChange={(event: any, data: any) => {
                setCompanyCiamId(data.value);
                setDisabledChange(false);
              }}
            />
          </Grid.Row>
        </Grid>
      </RDCustomAlert>
  );
};

export default SelectCompanyModal;
