import { EventEnrollment } from "../models/EventDetail";
import { TrainingEvent } from "../models/TrainingEvent";
import { isElearning } from "./useIsELearning";

export const isEnrollmentValid = (event: TrainingEvent) => {
  return (
    (!!event && isElearning(event.courseId,event.eventId))||
    !event?.startDate?.toString()?.startsWith("0001-01-01")
  );
};

export const isEnrollmentValidByEventEnrollment = (
  enrollment: EventEnrollment
) => {
  return (
    enrollment?.isELearning ||
    !enrollment?.eventDetail?.startDateTime
      ?.toString()
      ?.startsWith("0001-01-01")
  );
};
