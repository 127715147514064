import { Icon, SideNavigationGroupProps } from "@bosch/react-frok";
import { useSelector } from "react-redux";
import { selectOpenSideNav } from "../../../redux-toolkit/authReducer";
import { useEffect, useState } from "react";
import { triggerSideNavigation } from "../../../utils/frok.util";
import style from "./RDSideNavigationGroup.module.scss";

type Props = SideNavigationGroupProps & {
  isDefaultSelected: boolean;
  subItemsCount: number;
};

const RDSideNavigationGroup = (props: Props) => {
  const isOpenSideNav = useSelector(selectOpenSideNav);
  const [isShowSubItems, setShowSubItems] = useState(props.isDefaultSelected);

  useEffect(() => {
    setShowSubItems(props.isDefaultSelected);
  }, [props.isDefaultSelected]);

  useEffect(() => {
    if (!isOpenSideNav) setShowSubItems(false);
  }, [isOpenSideNav]);

  const onToggleSubItems = () => {
    setShowSubItems((prev) => !prev);
    triggerSideNavigation("open");
  };

  return (
    <ul
      id={props.id}
      className={`m-side-navigation__menuItem ${style.container} ${
        isShowSubItems ? "-open" : ""
      }`}
    >
      <button
        className={`m-side-navigation__group ${style.trigger_button} ${
          !isShowSubItems && props.isDefaultSelected ? "-selected" : ""
        }`}
        onClick={onToggleSubItems}
      >
        <Icon iconName={props.icon} />
        <span className="m-side-navigation__label">{props.label}</span>
        <Icon
          iconName="down"
          className={`${isShowSubItems ? style.arrow_up : ""} ${
            !isOpenSideNav ? style.arrow_hidden : ""
          }`}
        />
      </button>
      <ul
        style={{
          height:
            !isShowSubItems || !isOpenSideNav
              ? "0px"
              : props.subItemsCount * 3 + "rem",
        }}
        className={`m-side-navigation__menuSubitems ${style.sub_items} ${
          isShowSubItems ? style.sub_items_open : ""
        }`}
      >
        {props.children}
      </ul>
    </ul>
  );
};

export default RDSideNavigationGroup;
